import React, { useEffect, useState } from 'react'
import { Table, TableHead, TableCell, TableRow, TableBody, Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import ModalSubmissionView from './parts/ModalSubmissionView';
import {AiFillStar} from "react-icons/ai"
import {fetchSubmission} from "../../../redux/actions"
import { useDispatch, useSelector } from 'react-redux';
import {Pagination} from "@mui/material";
import Loader from '../../Admin/helpers/loader';
import { useTranslation } from 'react-i18next';

const Submissions = ({search, sort, from, to}) => {
    const { id } = useParams();
    const dispatch = useDispatch()
    let [page, setPage] = useState(1)
    let [count, setCount] = useState(1)
    let [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const [modalData, setModalData] = useState({
        open: false,
        data: {}
    });
    
    useEffect(() => {
        setLoading(true)
        dispatch(fetchSubmission(id, page, search, sort, from, to))
    }, [page, search, sort, from, to]);

    const submissions = useSelector(state => state.getSubmission.submissions)
    useEffect(() => {
        if(submissions){
            setLoading(false)
            setCount(submissions.data.submissions.meta.last_page)
            setPage(submissions.data.submissions.meta.current_page)
        }
    }, [submissions])


    const handleChange = (e, value) => {
        setPage(value)
    }

    if (submissions === undefined || submissions.data === undefined) {
        return <div className='maxHeight-40' style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}><Loader absoluteFlex={true}/></div>
    }

    return (
        <div style={{paddingBottom: "20px", position: "relative"}}>
            {loading && <div className="logout-div" style={{position: "absolute", zIndex: "1000"}}><img src={"/admin-images/loading_ireview.gif"} alt="loader" /></div>}
            <div className="custom-scrollbar-vertical custom-scrollbar-horizontal" style={{width: "100%", overflow: "auto"}}>
                <Table sx={{ width: "100%" }} stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow  className="form-element-th">
                            <TableCell sx={{ width: "5%" }}>{t("Rating")}</TableCell>
                            <TableCell>{t("Location")}</TableCell>
                            <TableCell>{t("submitted at")}</TableCell>
                            <TableCell>{t("Ip Location")}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {submissions.data.submissions.data.map((sub, index) => (
                            <TableRow
                                className="form-element"
                                key={sub.id}
                                sx={{ padding: "0px", '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center"><div style={{display: "flex", alignItems: 'center'}}>{sub?.fields?.rating ? Math.round(sub.fields.rating * 10) / 10 : ""}<AiFillStar style={{color: "#F3B72E"}}/></div></TableCell>
                                <TableCell>{sub?.fields?.location ?? sub?.location ?? ""}</TableCell>
                                <TableCell>{sub?.created_at_human && sub.created_at_human}</TableCell>
                                <TableCell>{sub?.ip_location && sub.ip_location}
                                {sub?.ip_location && sub.lat && sub.lng && <a className="goToMap" href={`http://maps.google.com/maps?z=12&t=m&q=loc:${sub.lat}+${sub.lng}`} target="__blank">Map</a>}</TableCell>
                                <TableCell align="right">
                                    <a onClick={()=>{
                                        setModalData({
                                            open: true,
                                            data: sub
                                        })
                                    }} className="clickeable-text">{t("Show details")}</a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            {submissions.data.submissions.data?.length === 0 && <Box component="p" sx={{ textAlign: "center",fontFamily:"Poppins-Medium", color: "text.secondary" }}>Nothing to Show</Box>}
            <ModalSubmissionView modalData={modalData} setModalData={setModalData} />
            {submissions.data.submissions.data?.length > 0 && 
                <Pagination count={count} page={page} shape="rounded" style={{float: "right"}} variant="outlined" onChange={handleChange}
            />}
        </div>
    )
}

export default Submissions