import React, {useEffect, useState} from 'react';
import { Grid, IconButton } from '@mui/material';
import {MdAddBox, MdRemoveCircleOutline} from "react-icons/md"
import LightTooltip from '../../../helpers/lightTooltip';
import { useTranslation } from 'react-i18next';

const MiscCard = ({formData, setFormData}) => {
    const {t} = useTranslation()
    const [services, setServices] = useState([
        {
            id: 1,
            text: ''
        }
    ])
    const [products, setProducts] = useState([
        {
            id: 1,
            text: ''
        }
    ])

    useEffect(() => {
        if(formData && formData.services && formData.services.length > 0){
            let arrServices = formData.services.map((text, index) => ({id: index + 1, text}));
            setServices(arrServices)
        }
        if(formData && formData.products && formData.products.length > 0){
            let arrProducts = formData.products.map((text, index) => ({id: index + 1, text}));
            setProducts(arrProducts)
        }
    }, [formData])

    const addServices = () => {
        let newService = {id: services.length + 1 , text: ''}
        setServices([...services, newService])
    }

    const deleteServices = (id) => {
        let newService = services.filter(item => item.id !== id)
        setServices(newService)
        setFormData(prevObj => ({...prevObj, services: newService.map(service => service.text)}))
    }

    
    const deleteProduct = (id) => {
        let newProduct = products.filter(item => item.id !== id)
        setProducts(newProduct)
        setFormData(prevObj => ({...prevObj, products: newProduct.map(product => product.text)}))
    }

    const addProducts = () => {
        let newService = {id: products.length + 1, text: ''}
        setProducts([...products, newService])
    }

    const changeValueService = (e) => {
        const {id, value} = e.target
        const updatedService = services.map(service => {
            if(service.id == id){
                return {...service, text: value}
            }
            return service
        })
        setServices(updatedService)
        setFormData(prevObj => ({...prevObj, services: updatedService.map(service => service.text)}))
    }

    const changeValueProducts = (e) => {
        const {id, value} = e.target
        const updatedProducts = products.map(product => {
            if(product.id == id){
                return {...product, text: value}
            }
            return product
        })
        setProducts(updatedProducts)
        setFormData({...formData, products: updatedProducts.map(product => product.text)})
    }
    
    return (
        <div>
            <div className='misc location_settings_card'>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div>
                            <h5 className='title'>{t("Misc")}</h5>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <>
                            <div className='form-group'>
                                <label htmlFor="">{t("Services Offered")}</label>
                                {services.map((item, i) => {
                                   return(
                                    <div key={`service${i}`} className='d-flex align-items-center' style={{marginBottom: "15px"}} >
                                        <MdRemoveCircleOutline className="remove-new-item" onClick={(e) => deleteServices(item.id)}/>
                                        <input type="text" key={`service${item.id}`}  name="service" id={item.id}  value={item.text} onChange={(e) => changeValueService(e)}/>
                                    </div>
                                   )
                                })}
                            </div>
                            <LightTooltip title={t("Add New Service")}>
                                <IconButton className="add-new-item" onClick={() => addServices()}>
                                    <MdAddBox/>
                                </IconButton>
                            </LightTooltip>
                        </>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <>
                            <div className='form-group'>
                                <label htmlFor="">{t("Products or Brands Carried")}</label>
                                {products.map((item, i) => {
                                    return(
                                        <div key={`products${i}`} className='d-flex align-items-center' style={{marginBottom: "15px"}}> 
                                            <MdRemoveCircleOutline className="remove-new-item" onClick={(e) => deleteProduct(item.id)}/>
                                            <input type="text" key={`prod${item.id}`} name="products" id={item.id}  value={item.text}  onChange={(e) => changeValueProducts(e)}/>
                                        </div>
                                    )
                                })}
                            </div>
                            <LightTooltip title="Add New Product Brand">
                                 <IconButton className="add-new-item" onClick={() => addProducts()}>
                                     <MdAddBox/>
                                 </IconButton>
                            </LightTooltip>
                        </>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default MiscCard;