export const subscriptionsReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_SUBSCRIPTION_DETAILS':
            return{
                ...state,
                subscriptions: action.payload
            }
        case 'CLEAR_SUBSCRIPTIONS':
            return{
                ...state,
                subscriptions: undefined
            }
        default:
            return{
                ...state
            }
    }
}