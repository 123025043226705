import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { createTheme, ThemeProvider, Dialog, DialogTitle, FormControlLabel } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { clearReviewsCoupon, loadingTables, showYoutubeContent, fetchReviewsCoupon } from "../../../../../redux/actions/admin-index";
import AlertComponent from "../../../../Helpers/Alert";
import Loader from "../../../helpers/loader";
import Coupon from "../../ReviewDetails/coupon";
import Header from "../../../../Helpers/header";
import { AntSwitch3 } from "../../../../Helpers/AntSwitch2";
import SetUpModal from "./SetUpModal";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { baseUrlDev } from "../../../../Helpers/baseUrl";
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser";

const CouponListWrapper = ({showSetUp, setShowSetUp, activeStep, setActiveStep, location_details}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [id, setId] = useState()
  
    const [active, setActive] = useState(false)
    const [couponWorkSpaces, setCouponWorkSpaces] = useState([]);
    const [couponWorkSpacesLoading, setCouponWorkSpacesLoading] = useState(false);
    const [sellers, setSellers] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState(null);
    const selected = useSelector(state => state.groupLocationSelected.group_location)


    const { t } = useTranslation()

    const location = useSelector(state => state.locationSelected.location);


    const handleGetAllWorkSpaces = () => {
        setCouponWorkSpacesLoading(true);
        axios.get(`${baseUrlDev}reviews/coupon/feature-activate`, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setCouponWorkSpaces(res.data.data);
                setCouponWorkSpacesLoading(false);
            })
    }

    useEffect(() => {
        if(selected && (!location || location == "all")){
            dispatch(clearReviewsCoupon())
            dispatch(fetchReviewsCoupon(selected))
            handleGetAllWorkSpaces();
        }
    }, [selected, dispatch])

    useEffect(() => {
        if (location && location !== "all") {
            dispatch(clearReviewsCoupon())
            dispatch(fetchReviewsCoupon(location))
            handleGetAllWorkSpaces();
        }
    }, [location, dispatch])


    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ROTS4l8tqiM?autoplay=1&mute=0&rel=0'))
    }, [])

    const coupons = useSelector(state => state.getReviewsCoupon.coupons)
console.log(coupons)
    if (coupons === undefined) {
        return (
            <Loader absoluteFlex={true} />
        )
    }

    const getMuiTheme = () =>
        createTheme({
            components: {
            }
        });

    const MAX_ITEMS_TO_DISPLAY = 5;

    const columns = [
        {
            name: "Id",
            options: {
                filter: false,
                viewColumns: false,
                display: false,
                sort: false,
            }
        },
        {
            name: t("Name"),
            options: {
                filter: true,
                sort: true,
                viewColumns: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value[0]} - {value[1]}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Email"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Phone"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p className="custom-text-datatable">{value}</p>
                },
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Platform"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p className="custom-text-datatable coupon-platform-name"><img src={value[1]} alt={value[0]} />{value[0]}</p>
                },
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        // {
        //     name: "",
        //     options: {
        //         filter: true,
        //         viewColumns: false,
        //         sort: false,
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //             if (!value[0]) {
        //                 return <p className="custom-text-datatable" style={{ color: "green" }}>{value[2]}</p>
        //                 // return <p className="custom-text-datatable" style={{color: "green"}}>We cannot match this review for coupon!</p>

        //             } else if (value[0] && value[1] == 0) {
        //                 return (
        //                     <button className="btn-aslink" onClick={() => {
        //                         setId(tableMeta.rowData[0])
        //                         setOpen(true)
        //                     }}>
        //                         {/* Send Coupon */}
        //                         {value[2]}
        //                     </button>
        //                 )
        //             } else {
        //                 // return <p className="custom-text-datatable" style={{color: "green"}}>Coupon Sent!</p>
        //                 return <p className="custom-text-datatable" style={{ color: "green" }}>{value[2]}</p>
        //             }
        //         }
        //     }
        // },
        {
            name: t("Status"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <p className="custom-text-datatable">
                        {value === 1
                            ? "Success"
                            : value === 2
                                ? "Duplicated"
                                : value === 0
                                    ? "NotActivated"
                                    : ""
                        }
                    </p>
                },
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">Sendbat Status</h4>
                }
            }
        },
    ];

    const data = coupons.data.map(c => {
        // return [c.reviews?.review_id, [c.first_name, c.last_name], [c.email], [c.location.address, c.location.irevu_companyName], c.status]
        return [c.reviews?.review_id, [c.first_name, c.last_name], [c.email], c.phone,[c.platform_name, c.logo], c.status]

    }).reverse()

    const options = {
        search: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        download: true,
        print: true,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: "checkbox",
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        downloadOptions: {
            filterOptions: {
                useDisplayedColumnsOnly: true,
            },
        },
        textLabels: {
            body: {
                noMatch: t("Sorry, no matching records found"),
                toolTip: t("Sort"),
                columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
                next: t("Next Page"),
                previous: t("Previous Page"),
                rowsPerPage: t("Rows per page"),
                displayRows: t("of"),
            },
            toolbar: {
                search: t("Search"),
                downloadCsv: t("Download CSV"),
                print: t("Print"),
                viewColumns: t("View Columns"),
                filterTable: t("Filter Table"),
            },
            filter: {
                all: t("All"),
                title: t("Filtres"),
                reset: "Reset",
            },
            viewColumns: {
                title: t("Show Columns"),
                titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
                text: t("row(s) selected"),
                delete: t("Delete"),
                deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onRowsDelete: (rowsDeleted) => {
            const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex][0]); // array of all ids to to be deleted
            console.log(idsToDelete)
        }
    };

    return (
        <>
            <AlertComponent />
            <SetUpModal
                show={showSetUp}
                setShow={setShowSetUp}
                couponWorkSpaces={couponWorkSpaces}
                couponWorkSpacesLoading={couponWorkSpacesLoading}
                sellers={sellers}
                setSellers={setSellers}
                campaigns={campaigns}
                setCampaigns={setCampaigns}
                selectedWorkspace={selectedWorkspace}
                setSelectedWorkspace={setSelectedWorkspace}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                location_details={location_details}
            />
            <Coupon open={open} setOpen={setOpen} id={id} invoice={true} />
            <div className="new-datatable">
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
        </>
    )
}
export default CouponListWrapper