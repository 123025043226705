import React from "react"
import { Skeleton, Grid } from "@mui/material"

const ReputationSkeleton = () => {
    return(
        <div>
            <Grid container spacing={2} style={{marginTop: "20px"}}>
                <Grid item xs={12}>
                    <Skeleton style={{transform: "unset", height: "400px"}}/>
                </Grid>
            </Grid>
            <Grid container style={{marginTop: "20px"}}>
                <Grid item xs={12}>
                    <Skeleton style={{transform: "unset", height: "200px"}}/>
                </Grid>
            </Grid>
        </div>
    )
}
export default ReputationSkeleton