import React, {useEffect, useState} from "react"
import { BiExport } from 'react-icons/bi';
import { Grid } from '@mui/material';
import AnalysisBox from "../../Analysis_Report/AnalysisBox"
import ProgressBox from "./ProgressBox";
import ListingsDatatable from "./ListingsDatatable";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocationListing, clearLocationListing, getGroupSelected, getLocationSelected } from "../../../../../redux/actions/admin-index";
import ListingSkeleton from "../../../helpers/listingSkeleton";
import axios from "axios";
import { baseUrlDev } from "../../../../Helpers/baseUrl";
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser";
import "../../../../../assets/listing.css"
import Unlock from "../../../Unlock";
import Header from "../../../../Helpers/header";
import { useTranslation } from "react-i18next";
import { showYoutubeContent } from "../../../../../redux/actions/admin-index";

const ListingWrapper = () => {
    const dispatch = useDispatch()
    const [locked, setLocked] = useState(false)
    const {t} = useTranslation()
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const listing = useSelector(state => state.getLocationListing.listing)
    const user = useSelector(state => state.getUserInfo.user)
    const selected = useSelector(state => state.groupLocationSelected.group_location)


    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ROTS4l8tqiM?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        if(selected && (!location || location == "all")){
            dispatch(clearLocationListing())
            dispatch(fetchLocationListing("", selected))
        }
    }, [selected])

    useEffect(() => {
        if (location && location !== "all") {
            dispatch(clearLocationListing())
            dispatch(fetchLocationListing("", location))
        }
    }, [location])

    useEffect(() => {
        if(location){
            let location_details = user && user.user.locations.filter(loc => loc.id == location)
            if(location_details && location_details[0] && location_details[0].is_demo){
                setLocked(true)
            } else {
                setLocked(false)
            }
        }
    }, [location, user]);

    if(locked){
        return <Unlock/>
    }

    if(listing === undefined){
        return(
            <ListingSkeleton title={"Listings"} details={false}/>
        )
    }

    return (
        <div className='listing_page'>
            <div className="mt-40">
                <Grid container spacing={2}>
                    <AnalysisBox title="Listing score" xs={12} md={6} lg={4} tooltip="score-tooltip">
                        <div className='listing_score_box'>
                            <h3 className="title">{listing.data.listingScoresAvg}</h3>
                            <h6>Score</h6>
                        </div>
                    </AnalysisBox>

                    <AnalysisBox title="Accuracy" xs={12} md={6} lg={8}>
                        <ProgressBox listing={listing}/>
                    </AnalysisBox>
                </Grid>
            </div>
            <div>
                <ListingsDatatable listing={listing}/>
            </div>
        </div>
    )
}

export default ListingWrapper