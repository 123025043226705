import React, {useState } from "react"
import { Modal, Slide } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import {useSelector} from "react-redux"
import { formatDateLineTime } from "../../helpers/formatDate"
import axios from "axios"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { useTranslation } from "react-i18next"

const Note = ({open, setOpen, notes, id}) => {
    const [notesState, setNotes] = useState(notes ? [...notes] : [])
    const [note, setNote] = useState('')
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state.getUserInfo.user)
    const {t} = useTranslation()
    const handleClose = () => {
        setOpen(false)
        setNote('')
    }
    const submitSource = () => {
        setLoading(true)
        let newArray = [...notesState, {
            user: {
                first_name: user.user.first_name, 
                last_name: user.user.last_name,
            },
            note: note, 
            time: formatDateLineTime(new Date())
        }]
        axios.post(`${baseUrlDev}reviews/${id}/note`, {note: note}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            setNotes(newArray)
            setNote('')
            setLoading(false)
        })
    }

    const writeNote = (e) => {
        let {value} = e.target
        setNote(value)
    }
    return(
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-tag-title"
            aria-describedby="modal-tag-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className='custom-modal-dialog'>
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>{t("Add Note")}</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-note-container custom-scrollbar-vertical">
                                {notesState.length > 0 && notesState.map(notes => {
                                    return(
                                        <div key={`notes${notes.id}`}>
                                            <div>
                                                <h6>{notes.user.first_name} {notes.user.last_name}</h6>
                                                <p>{notes.note}</p>
                                                <span>{notes.time}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="text-note-container">
                                <textarea className="custom-scrollbar-vertical" onChange={(e) => writeNote(e)} value={note}></textarea>
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <LoadingButton
                                style={{paddingLeft: loading && "30px"}}
                                className="save-btn"
                                onClick={() => submitSource()}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                            <button className="close-btn" onClick={() => setOpen(false)}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        
        </Modal>
    )
}
export default Note