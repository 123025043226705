import React, {useState} from "react"
import { Modal, Fade} from "@mui/material"

import SenderWorkspace from "./senderWorkspace"
import SenderSetup from "./SenderSetup"

const SetUpModal = ({show, setShow, couponWorkSpaces, couponWorkSpacesLoading, sellers, setSellers, campaigns, setCampaigns, selectedWorkspace, setSelectedWorkspace, activeStep, setActiveStep, location_details }) => {
    const [search, setSearch] = useState("")
    const handleClose = () => {
        setShow(false)
        setActiveStep(1)
        setSelectedWorkspace('');
    }

    return(
        <Modal
            open={show}
            onClose={() => handleClose()}
            aria-labelledby="setup-modal-title"
            aria-describedby="setup-modal-description"
        >
            <div className="wrapper-setup">
                {activeStep === 1 ?
                <Fade in={activeStep === 1}>
                    <div className="w-100 h-100">
                        <div className="wrapper-setup-header">
                            <h3>New Feature</h3>
                            <img src={"/admin-icons/sendbat.svg"} alt="sender"/>
                        </div>
                        <div className="wrapper-setup-body custom-scrollbar-vertical">
                            <div className="wrapper-setup-body-video">
                                <iframe style={{ width: '100%', height: '100%' }} src="https://www.youtube.com/embed/XXafQu3Z8PU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                            <p>Sendbat.com is your go-to platform for achieving unparalleled success in email and SMS marketing. With our powerful tools and cutting-edge AI automation, you can effortlessly convert more customers into sales and grow your business like never before.</p>
                        </div>
                        <div className="wrapper-setup-footer">
                            <button onClick={() => handleClose()}>Cancel</button>
                            <button onClick={() => setActiveStep(2)}>Activate Now</button>
                        </div>
                    </div>
                </Fade>
                : 
                activeStep === 2 ?
                <Fade in={activeStep === 2}>
                    <div className="w-100 h-100">
                        <SenderWorkspace  
                            handleClose={handleClose} 
                            setActiveStep={setActiveStep} 
                            couponWorkSpaces={couponWorkSpaces} 
                            couponWorkSpacesLoading={couponWorkSpacesLoading} 
                            setSellers={setSellers} 
                            setCampaigns={setCampaigns}
                            selectedWorkspace={selectedWorkspace}
                            setSelectedWorkspace={setSelectedWorkspace}
                        />
                    </div>
                </Fade>
                :
                <Fade in={activeStep === 3}>
                    <div className="w-100 h-100">
                        <SenderSetup 
                            handleClose={handleClose} 
                            setSearch={setSearch} 
                            search={search}
                            sellers={sellers} 
                            setSellers={setSellers}
                            campaigns={campaigns} 
                            setCampaigns={setCampaigns}
                            selectedWorkspace={selectedWorkspace}
                            location_details={location_details}
                            setSelectedWorkspace={setSelectedWorkspace}
                        />
                    </div>
                </Fade>
                }
            </div>

        </Modal>
    )
}
export default SetUpModal