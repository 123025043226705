export const languages = [
    {
        name: "English",
        code2A: "US",
        code3A: "USA",
        lng_code: "en"
    },
    {
        name: "Spanish",
        code2A: "ES",
        code3A: "ESP",
        lng_code: "es"
    },
    {
        name: "Mandarin Chinese",
        code2A: "CN",
        code3A: "CHN",
        lng_code: "zh"
    },
    {
        name: "Hindi",
        code2A: "IN",
        code3A: "IND",
        lng_code: "hi"
    },
    {
        name: "Arabic",
        code2A: "SA",
        code3A: "SAU",
        lng_code: "ar"
    },
    {
        name: "French",
        code2A: "FR",
        code3A: "FRA",
        lng_code: "fr"
    },
    {
        name: "Russian",
        code2A: "RU",
        code3A: "RUS",
        lng_code: "ru"
    },
    {
        name: "Portuguese",
        code2A: "PT",
        code3A: "PRT",
        lng_code: "pt"
    },
    {
        name: "Bengali",
        code2A: "BD",
        code3A: "BGD",
        lng_code: "bn"
    },
    {
        name: "Urdu",
        code2A: "PK",
        code3A: "PAK",
        lng_code: "ur"
    },
    {
        name: "Japanese",
        code2A: "JP",
        code3A: "JPN",
        lng_code: "ja"
    },
    {
        name: "German",
        code2A: "DE",
        code3A: "DEU",
        lng_code: "de"
    },
    {
        name: "Korean",
        code2A: "KR",
        code3A: "KOR",
        lng_code: "ko"
    },
    {
        name: "Italian",
        code2A: "IT",
        code3A: "ITA",
        lng_code: "it"
    },
    {
        name: "Turkish ",
        code2A: "TR",
        code3A: "TUR",
        lng_code: "tr"
    },
    {
        name: "Dutch",
        code2A: "BE",
        code3A: "BEL",
        lng_code: "nl"
    },
    {
        name: "Polish",
        code2A: "PL",
        code3A: "POL",
        lng_code: "pl"
    },
    {
        name: "Vietnamese",
        code2A: "VN",
        code3A: "VNM",
        lng_code: "vi"
    },
    {
        name: "Ukrainian",
        code2A: "UA",
        code3A: "UKR",
        lng_code: "uk"
    },
    {
        name: "Punjabi",
        code2A: "PK",
        code3A: "PAK",
        lng_code: "pa"
    },
    {
        name: "Filipino",
        code2A: "PH",
        code3A: "PHL",
        lng_code: "tl"
    },
    {
        name: "Persian",
        code2A: "IR",
        code3A: "IRN",
        lng_code: "fa"
    },
    {
        name: "Thai",
        code2A: "TH",
        code3A: "THA",
        lng_code: "th"
    },
    {
        name: "Greek",
        code2A: "GR",
        code3A: "GRC",
        lng_code: "el"
    },
    {
        name: "Romanian",
        code2A: "RO",
        code3A: "ROU",
        lng_code: "ro"
    },
    {
        name: "Czech",
        code2A: "CZ",
        code3A: "CZE",
        lng_code: "cs"
    },
    {
        name: "Hungarian",
        code2A: "HU",
        code3A: "HUN",
        lng_code: "hu"
    },
    {
        name: "Swedish",
        code2A: "SE",
        code3A: "SWE",
        lng_code: "sv"
    },
    {
        name: "Danish",
        code2A: "DK",
        code3A: "DNK",
        lng_code: "da"
    },
    {
        name: "Norwegian",
        code2A: "NO",
        code3A: "NOR",
        lng_code: "no"
    },
    {
        name: "Finnish",
        code2A: "FI",
        code3A: "FIN",
        lng_code: "fi"
    },
    {
        name: "Icelandic",
        code2A: "IS",
        code3A: "ISL",
        lng_code: "is"
    },
    {
        name: "Malay",
        code2A: "MY",
        code3A: "MYS",
        lng_code: "ms"
    },
    {
        name: "Swahili",
        code2A: "KE",
        code3A: "KEN",
        lng_code: "sw"
    },
    {
        name: "Hebrew",
        code2A: "IL",
        code3A: "ISR",
        lng_code: "he"
    },
    {
        name: "Serbian",
        code2A: "RS",
        code3A: "SRB",
        lng_code: "sr"
    },
    {
        name: "Croatian",
        code2A: "HR",
        code3A: "HRV",
        lng_code: "hr"
    },
    {
        name: "Albanian",
        code2A: "AL",
        code3A: "ALB",
        lng_code: "al"
    },
    {
        name: "Slovak",
        code2A: "SI",
        code3A: "SVN",
        lng_code: "sk"
    },
    {
        name: "Lithuanian",
        code2A: "LT",
        code3A: "LTU",
        lng_code: "lt"
    },
    {
        name: "Latvian",
        code2A: "LV",
        code3A: "LVA",
        lng_code: "lv"
    },
    {
        name: "Estonian",
        code2A: "EE",
        code3A: "ESE",
        lng_code: "et"
    }
]
