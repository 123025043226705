import React from "react"
import { Skeleton, Grid } from "@mui/material"

export const  AutoPilotAdminSkeleton = () => {
   return(
    <div className="autopilot-wrapper">
        <div style={{width: "60%"}}>
            <Skeleton style={{width: "80%", transform: "unset", marginBottom: "15px", height: "30px"}}/>
            <Skeleton style={{width: "100%", transform: "unset", marginBottom: "5px", height: "10px"}}/>
            <Skeleton style={{width: "100%", transform: "unset", marginBottom: "5px", height: "10px"}}/>
            <Skeleton style={{width: "100%", transform: "unset", marginBottom: "5px", height: "10px"}}/>
            <Skeleton style={{width: "100%", transform: "unset", marginBottom: "5px", height: "10px"}}/>
        </div>
        <div className="autopilot-div-container">
            <Skeleton style={{width: "80px", transform: "unset", borderRadius: "20px", height: "40px"}}/>
        </div> 
        <Grid container spacing={2} style={{marginTop: "30px"}}>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{width: "100%", transform: "unset",height: "200px"}}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{width: "100%", transform: "unset",height: "200px"}}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{width: "100%", transform: "unset",height: "200px"}}/>
            </Grid>
        </Grid>
        <Grid container spacing={2} style={{marginTop: "30px"}}>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{width: "100%", transform: "unset",height: "200px"}}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{width: "100%", transform: "unset",height: "200px"}}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{width: "100%", transform: "unset",height: "200px"}}/>
            </Grid>
        </Grid>
    </div>
   )
}

export const  AutoPilotSettingsSkeleton = () => {
    return(
    <div className="ai-settings-container">
         <div style={{width: "60%"}}>
             <Skeleton style={{width: "30%", transform: "unset", marginBottom: "15px", height: "30px"}}/>
             <Skeleton style={{width: "100%", transform: "unset", marginBottom: "40px", height: "10px"}}/>
         </div>
         <Skeleton style={{width: "50%", transform: "unset",  marginBottom: "15px", height: "10px"}}/>
         <Skeleton style={{width: "100%", transform: "unset",  marginBottom: "5px", height: "50px"}}/>
         <Skeleton style={{width: "100%", transform: "unset",  marginBottom: "5px", height: "50px"}}/>
         <Skeleton style={{width: "100%", transform: "unset",  marginBottom: "5px", height: "50px"}}/>
         <Skeleton style={{width: "100%", transform: "unset",  marginBottom: "15px", height: "50px"}}/>
        <Grid container spacing={2} style={{marginTop: "30px"}}>
            <Grid item xs={12} md={6}>
                <Skeleton style={{width: "100%", transform: "unset",height: "200px"}}/>
            </Grid>
        </Grid>
        <Grid container spacing={2} style={{marginTop: "30px"}}>
            <Grid item xs={12} md={6}>
                <Skeleton style={{width: "100%", transform: "unset",height: "200px"}}/>
            </Grid>
        </Grid>
        <Grid container spacing={2} style={{marginTop: "30px"}}>
            <Grid item xs={12} md={6}>
                <Skeleton style={{width: "100%", transform: "unset",height: "200px"}}/>
            </Grid>
        </Grid>
     </div>
    )
 }