import React from 'react';
import { useTranslation } from 'react-i18next';

const PhrasesTable = ({title, phrases, setOpenModal, setKeyId}) => {
    const {t} = useTranslation()
    return (
        <div className='table_wrapper custom-horizontal-scrollbar custom-scrollbar-vertical'>
            <table className='general__table'>
                <thead>
                    <tr>
                        <th>
                            <span className={title.toLowerCase() === "positive" ?  "success_dot" : "red_dot"}></span>
                            {t(title)}
                        </th>
                        <th>{t("Total")}</th>
                    </tr>
                </thead>
                <tbody>
                    {phrases.length > 0 && phrases.map((phrase, i) => {
                        return(
                            <tr key={`${phrase}-p-${i}`} onClick={() => {
                                    setKeyId(phrase.phrase)
                                    setOpenModal(true)
                                }}>
                                <td className='cursor-pointer'>{phrase.phrase}</td>
                                <td>{phrase.count}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default PhrasesTable;
