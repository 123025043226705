import React from "react"
import { IconButton} from "@mui/material"
import LightTooltip from "../../helpers/lightTooltip"
import { IoMdInformationCircleOutline } from "react-icons/io"
import {AiFillStar} from "react-icons/ai"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const TopReviewSources = ({sources}) => {
    const [t] = useTranslation()
    return(
        <div className="dashboard-box-containers">
           <LightTooltip title={
                <>
                    <div>
                        -{t("Top review sources includes up to 5 of your review sources")}<br/> 
                        -{t("Reviews is the number total number of reviews at the source")}<br/> 
                        -{t("% is the review sources’ percentage of your total reviews")}<br/> 
                        -{t("Rating is the average rating for the selected review source")}
                    </div>
                </>
            }
            >
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                </IconButton>
            </LightTooltip>
            <h2>{t("Top Review Sources")}</h2>
            {Object.keys(sources) && Object.keys(sources).length > 0 ? <div className="scrollable-box-table custom-scrollbar-vertical">
                <table>
                    <thead>
                        <tr>
                            <th>{t("Source")}</th>
                            <th>{t("Rating")}</th>
                            <th>{t("Reviews")}</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(sources).map((key) => {
                            return(
                                <tr key={key}>
                                    <td>
                                        <img src={sources[key].logo} alt={key} style={{width: "32px", height: "32px", objectFit: "contain", borderRadius: "50%"}}/>
                                    </td>
                                    <td>
                                        <div className="star-title-container">{sources[key].rating} <AiFillStar/></div>
                                    </td>
                                    <td>
                                        {sources[key].reivews}
                                    </td>
                                    <td>
                                        {sources[key].percentage}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div> : <div className="nothing-to-show padding-20">{t("Nothing to show")}</div>}
            <Link to="/app/admin/reviews/list" className="a-primary">{t("Manage Reviews")}</Link>
        </div>
    )
}
export default TopReviewSources