import React, { Suspense } from "react"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Skeleton } from "@mui/material"
import { formatDateLine } from "../../helpers/formatDate";
import { AntSwitch } from "../../../Helpers/AntSwitch";
import { clearDashboardStats, fetchDashboardStats } from "../../../../redux/actions/admin-index";
import { useDispatch, useSelector } from "react-redux";

import DateRange from "../../helpers/DateRange"

const DashboardScreenshot = React.lazy(() => import("./DashboardScreenshot"))

const RightSideHeader = ({ setFrom, setTo, fromText, toText }) => {
    const dispatch = useDispatch()
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)


    const handleDateChange = (item) => {
        let itemSerialized = {
            startDate: formatDateLine(item.selection.startDate),
            endDate: formatDateLine(item.selection.endDate)
        }
        setFrom(item.selection.startDate ? formatDateLine(item.selection.startDate) : "")
        setTo(item.selection.endDate ? formatDateLine(item.selection.endDate) : "")

        if (item.selection.startDate !== item.selection.endDate) {
            dispatch(clearDashboardStats())
        }
        if (!item.selection.startDate) {
            dispatch(clearDashboardStats())
            dispatch(fetchDashboardStats(null, null, location, groupSelected))
        } else {
            dispatch(fetchDashboardStats(itemSerialized.startDate, itemSerialized.endDate, location, groupSelected))
        }
    }

    return (
        <div className="right-side-dashboard-elements">
            {/* <div>
                Feedback <AntSwitch onChange={(e) => handleChange(e)}/>
            </div> */}
            <DateRange handleDateChange={handleDateChange} />
            <Suspense fallback={<Skeleton style={{ transform: "unset", height: "41px", width: "100px", marginLeft: "10px" }} />}>
                <DashboardScreenshot fromText={fromText} toText={toText} location={location} groupSelected={groupSelected} />
            </Suspense>
        </div>
    )
}
export default RightSideHeader