import React, {useState, useMemo} from "react"
import { useSelector } from "react-redux"
import { Select, FormControl, ListSubheader, TextField, MenuItem } from "@mui/material";
import MySelect from "../../../helpers/mySelect";
import { useTranslation } from "react-i18next";

const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const LocationsGroupPage = ({locationGroup, setLocationGroup, edit, formData, setFormData}) => {
    const [search, setSearch] = useState("")
    const user = useSelector(state => state.getUserInfo.user)
    let locations = user && user.user.locations
    const displayedLocation = useMemo(() => locations  && locations.filter(el => el.is_owner === true).filter(loc => containsText(loc.full_name, search)), [search, user]);

    const handleChange = (e) => {
        let value = e.target.value
        setLocationGroup(value)
    }

    const {t} = useTranslation()

    return(
        <FormControl className="" fullWidth>
            <Select
                value={edit ? []  : locationGroup}
                onChange={handleChange}
                multiple
                onClose={() => setSearch("")}
                input={<MySelect/>}
                MenuProps={{
                    classes: {
                        paper: "breake-option-words custom-selected",
                    },
                    autoFocus: false, PaperProps: { sx: { maxHeight: 300} , 
                    style: {
                        maxWidth: 300,
                    }, 
                }}}
                renderValue={value => {
                    if(!value || value.length === 0){
                        return <em>Select Items</em>;
                    } else {
                        let arr = value.map(element => {
                            return locations && locations.find(el => el.id == element)
                        })
                        return arr.map(el => el && el.full_name).join(', ')
                    }
                }}
            >
                <ListSubheader>
                    <TextField
                        size="small"
                        autoFocus
                        placeholder={t("Search Location")}
                        fullWidth
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={(e) => {
                            if(e.key !== 'Escape'){
                                e.stopPropagation();
                            }
                        }}
                    />
                </ListSubheader>
                <MenuItem value="[]" disabled>
                    {t("Select an option")}
                </MenuItem>
                {displayedLocation && displayedLocation.map(loc => {
                    return(
                        <MenuItem key={loc.id} value={loc.id}>{loc.full_name}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
export default LocationsGroupPage
