import React, {useState, useMemo, useEffect} from "react"
import { Modal, Alert, Checkbox, ListSubheader, FormControl, useTheme, Select, MenuItem, TextField } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import MySelect from "../../../Helpers/MySelect";
import { LoadingButton } from "@mui/lab";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import axios from "axios";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { showAlert } from "../../../../redux/actions";
import ContactLaterDialog from "./contactLater";
import { useTranslation } from "react-i18next";

const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const UnSubscribe = ({open, setOpen}) => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false)
    const [selectedLocations, setSelectedLocations] = useState([])
    const [reason , setReason] = useState("")
    const [terms, setTerms] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [errors, setErrors] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [dialogText, setDialogText] = useState("")
    const {t} = useTranslation()
    const user = useSelector(state => state.getUserInfo.user)
    let locations = user && user.user.locations
    const theme = useTheme()

    const displayedLocation = useMemo(() => locations  && locations.filter(loc => containsText(loc.full_name, search)), [search, user]);


    const handleClose = () => {
        setOpen(false)
        setSearch("")
        setSelectedLocations([])
        setErrors([])
        setReason("")
        setTerms(false)
    }

    const handleChange = (e) => {
        let value = e.target.value
        setSelectedLocations(value)
    }

    useEffect(() => {
        if(!reason || !terms || reason.length < 160 || selectedLocations.length == 0){
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [reason, terms, selectedLocations])

    const submitForm = () => {
        setLoading(true)
        if(!terms){
            setErrors(prev => [...prev, "Please Allow Terms & Coniditions to continue"])
            setLoading(false)
            return
        } else if (!reason || reason.length < 160){
            setErrors(prev => [...prev, "Cancellation Reason needs to be minimum 160 chars"])
            setLoading(false)
            return
        }
        axios.post(`${baseUrlDev}subscription/cancel`, {locations: selectedLocations, reason}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then((res) => {
            setLoading(false)
            setDialogText(res.data.message)
            setOpenDialog(true)
            setTimeout(() => {
                setOpenDialog(false)
                handleClose()
            }, 5000)
        })
        .catch(err => {
            setLoading(false)
            Object.keys(err.response.data.errors).map(key => {
                setErrors([...errors, err.response.data.errors[key][0]])
            })
        })
    }

    return(
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-tag-title"
            aria-describedby="modal-tag-description"
        >
            <div className="wrapper-loc-unsubscribe" >
                <ContactLaterDialog openDialog={openDialog} setOpenDialog={setOpenDialog} text={dialogText}/>
                <div className="custom-scrollbar-vertical" style={{overflow: "auto"}}>
                    {errors && errors.map(err => {
                        return(
                            <div className="alert-inside-modal" key={err}>
                                <Alert severity="error" className="custom-error-alert">{t(err)}</Alert>
                            </div>
                        )
                    })}
                    <h6>{t("Please fill this form")}!</h6>
                    <FormControl className="" fullWidth>
                        <label>{t("Select Location")}</label>
                        <Select
                            value={selectedLocations}
                            multiple
                            onChange={handleChange}
                            onClose={() => setSearch("")}
                            input={<MySelect/>}
                            MenuProps={{
                                classes: {
                                    paper: "breake-option-words custom-selected",
                                },
                                autoFocus: false, PaperProps: { sx: { maxHeight: 300 } } 
                            }}
                            renderValue={value => {
                                if(value.length === 0){
                                    return <em>{t("Select Location")}</em>;
                                } else {
                                    let arr = value.map(element => {
                                        return locations && locations.find(el => el.id == element)
                                    })
                                    return arr.map(el => el && el.full_name).join(', ')
                                }
                            }}
                        >
                            <ListSubheader>
                                <TextField
                                    size="small"
                                    autoFocus
                                    placeholder={t("Search Location")}
                                    fullWidth
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={(e) => {
                                        if(e.key !== 'Escape'){
                                            e.stopPropagation();
                                        }
                                    }}
                                />
                            </ListSubheader>
                            {displayedLocation && displayedLocation.map(loc => {
                                return(
                                    <MenuItem key={loc.id} value={loc.id}>{loc.full_name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <div>
                        <label>{t("Cancellation Reason")}</label>
                        <textarea className="custom-scrollbar-vertical" onChange={(e) => setReason(e.target.value)} minLength={160} placeholder={t("Cancellation Reason")}></textarea>
                        <span className="span-information">{reason.length}/160</span>
                    </div>
                    <div className="agree-terms">
                        <Checkbox
                            checked={terms}
                            name="terms"
                            onChange={(e) => setTerms(e.target.checked)}
                            sx={{
                                color:"#ccc",
                                '&.Mui-checked': {
                                color: "#00B4E5",
                                },
                                '& .MuiSvgIcon-root': { fontSize: 20 }
                            }}
                        />
                        {t("I have read and agree to the")}  <a href="https://irevu.com/terms/"> {t("Terms & Conditions")}</a>
                    </div>
                </div>
                <div className="custom-modal-footer">
                    <LoadingButton
                        style={{paddingLeft: loading && "30px", opacity: disabled ? "0.5" : 1}}
                        onClick={() => submitForm()}
                        loading={loading}
                        disabled={disabled}
                        className="save-btn"
                    >
                        {t("Send")}
                    </LoadingButton>
                    <button className="close-btn" onClick={() => setOpen(false)}>{t("Cancel")}</button>
                </div>
            </div>
        </Modal>
    )
}
export default UnSubscribe