import React, {useState, useEffect} from "react"
import {TbDots} from "react-icons/tb"
import {BsCheck} from "react-icons/bs"
import {MdDelete} from "react-icons/md"
import { ClickAwayListener } from "@mui/material"
import { baseUrlDev } from "../../Helpers/baseUrl"
import { getLocalUser } from "../../TokenControl/parts/useLocalUser"
import axios from "axios"
import { useDispatch } from "react-redux"
import { getNotificationsFilter, fetchNotificationsCount } from "../../../redux/actions/admin-index"
import { Link } from "react-router-dom"

const NotyItem = ({notification}) => {
    const dispatch = useDispatch()
    const [showActions, setShowActions] = useState(false)
    const [noty, setNoty] = useState({...notification})


    useEffect(() => {
        if(notification){
            setNoty(notification)
        }
    }, [notification])

    const markNotificationAsRead = (id) => {
        axios.post(`${baseUrlDev}notifications/read`, {id}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(() => {
            dispatch(fetchNotificationsCount())
        })
        setNoty({...noty, read_at: new Date()})
        setNoty({...noty, read: 1})
        setShowActions(false)
    }

    const deleteNotification = (id) => {
        axios.post(`${baseUrlDev}notifications/delete`, {id}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        dispatch(getNotificationsFilter(id))
        setShowActions(false)
    }
    return(
        <div className={`noty-item ${noty.read === 0 ? "unread" : ""}`}>
            {notification?.review ? 
            <Link to={`/app/admin/reviews/list/${notification?.review?.id}`} className="noty-item-user" onClick={() => markNotificationAsRead(noty?.id)}>
                <img src={noty.user.avatar ? noty.user.avatar : "https://storage.googleapis.com/mydev-storage/accounts/621user.png"} alt={noty?.user?.first_name}/>
                <div>
                    <h4>{noty?.title}</h4>
                    <p>{noty?.description}</p>
                </div>
            </Link>
            :
            <div className="noty-item-user div-container-70 ">
                <img src={noty?.user?.avatar ? noty?.user?.avatar : "https://storage.googleapis.com/mydev-storage/accounts/621user.png"} alt={noty?.user?.first_name}/>
                <div>
                    <h4>{noty?.title}</h4>
                    <p>{noty?.description}</p>
                </div>
            </div>
            }
            <div className="right-description-notification">
                <span className="label-adm-info default-color-adm">{noty?.created_at_formatted}</span>
                <ClickAwayListener onClickAway={() => setShowActions(false)}>
                    <div>
                        <TbDots onClick={() => setShowActions(!showActions)}/>
                        <div className="adm-edit-buttons" style={{display: showActions ? "flex" : "none"}}>
                            <BsCheck onClick={() => markNotificationAsRead(noty.id)}/>
                            <MdDelete onClick={() => deleteNotification(noty.id)}/>
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
        </div>
    )
}
export default NotyItem