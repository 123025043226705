import React, {useState} from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider, Dialog, DialogTitle  } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchGamifyMembers } from '../../../../redux/actions/admin-index';
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";

const GamifyTable = ({memberAdded, setOpenEditModal, setMemberId, setOpenConfirmation, memberDeleted, setGamifyChartMembers, setGamifyChartMembersLoader}) => {
    const [userId, setUserId] = useState("")
    const [user, setUser] = useState()
    const [t] = useTranslation()
    const MAX_ITEMS_TO_DISPLAY = 5;
    const dispatch = useDispatch();
    const location = useSelector(state => state.locationSelected.location);
    const gamify_members = useSelector(state => state.getGamifyMembers.gamify_members);

    useEffect(() => {
        dispatch(fetchGamifyMembers(location))
    }, [location, dispatch, memberAdded, memberDeleted]);

    const columns = [
        { 
            name: "Id", 
            options: {
                filter: false,
                viewColumns: false,
                display: false,
                sort: false,
            }
        },
        { 
            name: t("Name"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Surname"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Reviews"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Role*"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: "", 
            options: {
                filter: false,
                viewColumns: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value){
                        return (
                            <div className="mydev-datatable-actions">
                                <button className="edit-lead-btn" onClick={() => {
                                    setUser(tableMeta.rowData);
                                    setOpenEditModal(true);
                                    setMemberId(tableMeta.rowData[0]);
                                }}>
                                    <span className="icon-mydevicon-edit-bordered"></span>
                                    {t("Edit")}
                                </button>
                                <button className="remove-lead" onClick={() => {
                                        setUserId(tableMeta.rowData[0]);
                                        setOpenConfirmation(true);
                                        setMemberId(tableMeta.rowData[0]);
                                    } }>
                                    <span className="icon-rounded-trash"></span>
                                    {t("Delete")}
                                </button>
                            </div>
                        )
                    }
                }
            }
        },
    ];
    // const data = [
    //     [1, "Blerina Pllana", "23",  'test,test', "employee"],
    //     [2, "Arjona Ramadani", "23",  'test,test', "employee"]
    // ]

    const data = gamify_members?.data?.map(c => {
        return [c.id,c.name, c.surname, c.reviewsNumber, c.role]
    }).reverse()

    const options = {
        search: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        download: true,
        print: true,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: "checkbox",     
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        downloadOptions: {
            filterOptions: {
              useDisplayedColumnsOnly: true,
            },
        },
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onRowsDelete: (rowsDeleted) => {
            const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex][0]); // array of all ids to to be deleted
           console.log(idsToDelete)
        },
        onRowClick: (rowData) => {
            const userId = rowData[0]; // first column holds the user ID
            setGamifyChartMembersLoader(true);

            axios.post(`${baseUrlDev}locations/analytics`, { location: location, gamify_id: userId }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setGamifyChartMembers(res.data);
                setGamifyChartMembersLoader(false);
            });
        }
    };
    return(
        <div className="new-datatable">
            <MUIDataTable
                data={data}
                columns={columns}
                options={options}
                className="gamify-mui-datatable"
            />
        </div>
    )
}
export default GamifyTable