import React, { useState, useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux";
import { Modal, Grid, Slide, Skeleton, FormControl, Select, TextField, MenuItem, ListSubheader, } from "@mui/material";
import { fetchQuickReply, clearQuickReplies } from "../../../../redux/actions/admin-index";
import { formatHex } from "../../helpers/formatHex";
import MySelect from "../../../Helpers/MySelect";
const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;


const QuickReplyModal = ({ open, setOpen, quickReplyText, source }) => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState("")
    const [replies, setReplies] = useState()
    const [filter, setFilter] = useState('all')
    const [locationSelected, setLocationSelected] = useState("all")

    const replies_redux = useSelector(state => state.getQuickReplies.replies)
    const user = useSelector(state => state.getUserInfo.user)
    let locations = user && user.user.locations
    const displayedLocation = useMemo(() => locations && locations.filter(loc => containsText(loc.full_name, search)), [search, user]);


    useEffect(() => {
        if (replies_redux) {
            setReplies([...replies_redux.data])
        }
    }, [replies_redux])


    const handleClose = () => {
        setOpen(false)
        setReplies(replies_redux && [...replies_redux.data])
    }

    const onChange = (text, key) => {
        quickReplyText(text)
        setOpen(false)
    }

    const filterReplies = (type) => {
        setFilter(type)
    }

    const handleChange = (e) => {
        setReplies(undefined)
        setLocationSelected(e.target.value)
    }

    useEffect(() => {
        if (open) {
            setReplies(undefined)
            dispatch(fetchQuickReply(filter === "all" ? "" : filter, "desc", "", "", locationSelected === "all" ? "" : locationSelected))
        }
    }, [open, filter, locationSelected])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-reply-quick-title"
            aria-describedby="modal-reply-quick-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className='custom-modal-dialog'>
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>Add Quick Reply</h5>
                        </div>
                        <div className="filter-reply-types">
                            <span onClick={() => filterReplies('all')} className={`all-replies ${filter === "all" ? 'active' : ''}`}>All Types</span>
                            <span onClick={() => filterReplies('positive')} className={`positive-filter ${filter === "positive" ? 'active' : ''}`}>Positive</span>
                            <span onClick={() => filterReplies('negative')} className={`negative-filter ${filter === "negative" ? 'active' : ''}`}>Negative</span>
                            {locations === undefined ? <Skeleton style={{ height: '41px', transform: 'unset' }} /> :
                                <FormControl className="location-list">
                                    <Select
                                        value={locationSelected}
                                        onChange={handleChange}
                                        onClose={() => setSearch("")}
                                        input={<MySelect />}
                                        MenuProps={{
                                            classes: {
                                                paper: "breake-option-words custom-selected",
                                            },
                                            autoFocus: false, PaperProps: {
                                                sx: { maxHeight: 300 },
                                                style: {
                                                    maxWidth: 300,
                                                },
                                            }
                                        }}
                                        renderValue={selected => {
                                            if (selected === "all") {
                                                return "All Locations"
                                            } else {
                                                return locations.filter(loc => loc.id == selected)[0].full_name
                                            }
                                        }
                                        }
                                    >
                                        <ListSubheader>
                                            <TextField
                                                size="small"
                                                autoFocus
                                                placeholder="Search Location"
                                                fullWidth
                                                onChange={(e) => setSearch(e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key !== 'Escape') {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            />
                                        </ListSubheader>
                                        <MenuItem value={"all"}>All Locations</MenuItem>
                                        {displayedLocation && displayedLocation.map(loc => {
                                            return (
                                                <MenuItem key={loc.id} value={loc.id}>{loc.full_name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            }
                        </div>
                        <div className="scrollable-div-container custom-scrollbar-vertical">
                            <div className="quick-reply-container">
                                {replies === undefined ?
                                    [...Array(6)].map((e, i) => {
                                        return (
                                            <div key={`quick-skeleton-${i}`}>
                                                <Skeleton height={45} width={'100%'} style={{ transform: "unset", marginBottom: "15px" }} />
                                            </div>
                                        )
                                    })
                                    :
                                    replies.map(reply => {
                                        if (source && source.name === "OptSpot" && reply.replies.content.length > 160) {
                                            return ""
                                        } else {
                                            return (
                                                <div className="quick-reply-container-item" key={`quick_reply${reply.replies.id}`}>
                                                    <input
                                                        type="radio"
                                                        name="reply"
                                                        id={reply.replies.content.toLocaleLowerCase()}
                                                        value={reply.replies.id}
                                                        onChange={() => onChange(reply.replies.content, `quick_reply${reply.replies.id}`)}
                                                    />

                                                    <label htmlFor={reply.replies.content.toLocaleLowerCase()}>
                                                        <div>
                                                            <span style={{ color: reply.replies.type_color, borderColor: reply.replies.type_color, backgroundColor: formatHex(reply.replies.type_color, 0.1) }}>{reply.replies.type}</span>
                                                        </div>
                                                        {reply.replies.content}
                                                    </label>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>

        </Modal>
    )
}
export default QuickReplyModal