import React, {useEffect, useState} from "react"
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Checkbox, Radio, InputLabel, Select, MenuItem } from "@mui/material"
import MySelect from "../../Helpers/MySelect"
import { WithContext as ReactTags } from 'react-tag-input';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fieldType, fieldDescription, fieldPlaceholder, fieldAsReview, fieldInputType, fieldSelectOptions, fieldInputName } from "../../../redux/actions";

const KeyCodes = {
    comma: 188,
    enter: 13
  };
  
const delimiters = [KeyCodes.comma, KeyCodes.enter];

const FieldDetails = ({form_fields, show}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [type, setType] = useState(form_fields && form_fields.data && form_fields.data.type)
    const [tags, setTags] = useState(form_fields && form_fields.data && form_fields.data.options)

    let fieldTypeState = useSelector(state => state.fieldTypeReducer.type)
    const sendAsReview = useSelector(state => state.FormFieldAdded.sendAsReview)

    const handleChange = (e) => {
        dispatch(fieldAsReview(e.target.checked === true ? true : false))
    }

    useEffect(() => {
        if(form_fields && form_fields.data){
            setType(form_fields.data.type)
            if(form_fields.data.options){
                setTags(form_fields.data.options)
            }
        } else {
            setType('text')
            setTags([])
        }
    }, [form_fields])

    const handleFieldTypeChange = (e) => {
        dispatch(fieldType(e.target.value))
        setTags([]);
    }
    const handleTypeChange = (e) => {
        setType(e.target.value)
        dispatch(fieldInputType(e.target.value))
    }
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
        dispatch(fieldSelectOptions(tags.filter((tag, index) => index !== i)))
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
        dispatch(fieldSelectOptions([...tags, tag]))
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();
    
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
    
        // re-render
        setTags(newTags);
        dispatch(fieldSelectOptions(newTags))
    };

    return(
        <div className="field-display-container">
            <div>
                <FormControl sx={{borderBottom: '1px solid rgb(231, 231, 231)'}}>
                    <FormLabel id="input-type">{t("Which one you want to add")}</FormLabel>
                    <RadioGroup
                        aria-labelledby="input-type"
                        value={fieldTypeState === undefined ? 'input' : fieldTypeState}
                        name="input-type"
                        onChange={(e) => handleFieldTypeChange(e)}
                    >
                        <FormControlLabel 
                            sx={{'.MuiFormControlLabel-label': {fontSize: 14,}}}
                            value="input" 
                            control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}} 
                            className="mui-radio-custom" />} 
                            label="Input" 
                        />
                        <FormControlLabel 
                            sx={{'.MuiFormControlLabel-label': {fontSize: 14,}}}
                            value="select" 
                            control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}} 
                            className="mui-radio-custom" />} 
                            label="Select" 
                        />
                        <FormControlLabel 
                            sx={{'.MuiFormControlLabel-label': {fontSize: 14,}}}
                            value="rating" 
                            control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}} 
                            className="mui-radio-custom" />} 
                            label={t("Rating")} 
                        />
                        <FormControlLabel 
                            sx={{'.MuiFormControlLabel-label': {fontSize: 14,}}}
                            value="location" 
                            control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}} 
                            className="mui-radio-custom" />} 
                            label={t("Locations")} 
                        />
                        <FormControlLabel 
                            sx={{'.MuiFormControlLabel-label': {fontSize: 14,}}}
                            value="date" 
                            control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}} 
                            className="mui-radio-custom" />} 
                            label={t("Date Picker")} 
                        />
                        <FormControlLabel 
                            sx={{'.MuiFormControlLabel-label': {fontSize: 14,}}}
                            value="barcode" 
                            control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 20,}}} 
                            className="mui-radio-custom" />} 
                            label={t("Barcode Scanner")} 
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            
                <div className="mt-30">
                    <div className="label-field-container">
                    {
                        fieldTypeState === 'rating' || fieldTypeState ===  'location' || fieldTypeState ===  'date' || fieldTypeState === 'barcode' ?
                            <div>
                                <label>{t("Description")}</label>
                                <input type="text" name="description" placeholder={t("Description")} maxLength={255} defaultValue={form_fields && form_fields.data && form_fields.data.description}  onChange={(e) => dispatch(fieldDescription(e.target.value))}/>
                                <span>{t("Give an optional description to the form field")}</span>
                            </div>
                        :
                        <>
                        <div>
                            <label>{t("Input Name")}</label>
                            <input type="text" name="input_name" placeholder={t("Enter input name")} maxLength={255} defaultValue={form_fields && form_fields.data && form_fields.data.field_input} onChange={(e) => dispatch(fieldInputName(e.target.value))}/>
                            <span>{t("Enter input name (To make grouped radio buttons add same radio button name)")}</span>
                        </div>
                        <div>
                            <label>{t("Description")}</label>
                            <input type="text" name="description" maxLength={255} placeholder={t("Description")} defaultValue={form_fields && form_fields.data && form_fields.data.description}  onChange={(e) => dispatch(fieldDescription(e.target.value))}/>
                            <span>{t("Give an optional description to the form field")}</span>
                        </div>
                            {fieldTypeState === 'input' || fieldTypeState === undefined ? 
                                    <>
                                        <div>
                                            <label>{t("Placeholder")}</label>
                                            <input type="text" name="placeholder" maxLength={255} placeholder={t("Placeholder")} defaultValue={form_fields && form_fields.data && form_fields.data.placeholder} onChange={(e) => dispatch(fieldPlaceholder(e.target.value))}/>
                                            <span>{t("Give an optional value for Placeholder")}</span>
                                        </div>
                                        <div>
                                            <FormControl fullWidth>
                                                <InputLabel id="input-type">{t("Input Type")}</InputLabel>
                                                <Select
                                                    labelId="input-type"
                                                    id="input-type-select"
                                                    value={type ? type : "text"}
                                                    label="position"
                                                    input={<MySelect/>}
                                                    onChange={handleTypeChange}
                                                >
                                                    <MenuItem value={'text'}>text</MenuItem>
                                                    <MenuItem value={'checkbox'}>checkbox</MenuItem>
                                                    <MenuItem value={'radio'}>radio</MenuItem>
                                                    <MenuItem value={'number'}>Number</MenuItem>
                                                    <MenuItem value={'email'}>Email</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <span>{t("Select input type")}</span>
                                        </div>
                                    </>
                                    :
                                    <div className="custom-react-tags">
                                    <label>{t("Add Options")}</label>
                                        <ReactTags
                                            placeholder={t("Add Options")}
                                            tags={tags ? tags  : []}
                                            inline={true}
                                            delimiters={delimiters}
                                            handleDrag={handleDrag}
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            inputFieldPosition="bottom"
                                            autocomplete
                                        />
                                    <span>{t("Give select options")}</span>
                                </div>
                            }
                        </>
                    }
                    {show ?
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    checked={sendAsReview} 
                                    name="required" 
                                    onChange={(e) => handleChange(e)}
                                    sx={{
                                        color: "#00B4E5!important",
                                        '&.Mui-checked': {
                                            color: '#00B4E5',
                                        },
                                    }}
                                />
                            }
                            label={t('Send as review')}
                        />
                        <span>{t("Send field response as review")}</span>
                    </>
                    :     
                    <>
                        <FormControlLabel style={{marginBottom: "0px"}}
                            control={
                                <Checkbox 
                                    checked={false} 
                                    name="required" 
                                    disabled={true}
                                    sx={{
                                        color: "#ccc!important",
                                        '&.Mui-checked': {
                                            color: '#00B4E5',
                                        },
                                    }}
                                />
                            }
                            label={'Send As Review Details'}
                        />
                        <span>{t("Send field response as review")}</span>
                        <span style={{display:"block", fontWeight: "600", color: "#000"}}>{t("Ensure you have a rating field in your form to send field responses as review data")}.</span>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}
export default FieldDetails