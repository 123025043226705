import React from 'react';
import { AiFillStar } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const KeywordsTable = ({ setOpenModal, title, keywords, setKeyId }) => {
    const {t} = useTranslation()
    return (
        <div className='table_wrapper custom-horizontal-scrollbar custom-scrollbar-vertical'>
            <table className='general__table'>
                <thead>
                    <tr>
                        <th>
                            <span className={title.toLowerCase() === "positive" ?  "success_dot" : "red_dot"}></span>
                            {t(title.toLowerCase())}
                        </th>
                        <th>{t("Total")}</th>
                        <th>{t("Rating")}</th>
                    </tr>
                </thead>
                <tbody>
                    {keywords.length > 0 && keywords.map((keyword, i) => {
                        return(
                            <tr key={`${keyword}-k-${i}`} onClick={() => {
                                setKeyId(keyword.keyword)
                                setOpenModal(true)
                            }}>
                                <td className="cursor-pointer">{keyword.keyword}</td>
                                <td>{keyword.count}</td>
                                <td>
                                    <div className="star_title_wrap">
                                     {keyword.rating}
                                        <AiFillStar/>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
              
                </tbody>
            </table>
        </div>
    )
}

export default KeywordsTable;
