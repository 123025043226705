import React, { useState } from "react"
import Header from "../../../Helpers/header"
import { Grid, Dialog, DialogTitle, Skeleton } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingButton } from "@mui/lab";
import RightSideGamify from "./RightSideGamify"
import GamifyTable from "./GamifyTable"
import DateRange from "../../helpers/DateRange"
import AddMember from "./addMember"
import GamifyChartWrapper from "./GamifyChartWrapper"
import SetupTimer from "./setupModal"
import AlertComponent from "../../../Helpers/Alert";
import { useEffect } from "react"
import EditMember from "./editMember"
import axios from "axios";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../redux/actions";
import { useSelector } from "react-redux";
import { fetchGamifyMembers, clearGamifyMembers } from "../../../../redux/actions/admin-index";
import { calculateCountdown } from '../../../Helpers/CountDown';
import Loader from "../../helpers/loader";
import { useTranslation } from "react-i18next";

const GamifyReviews = () => {
    const [active, setActive] = useState(1)
    const [open, setOpen] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openTimer, setOpenTimer] = useState(false)
    const [memberAdded, setMemberAdded] = useState(false); //when member is added, we should refresh table
    const [memberDeleted, setMemberDeleted] = useState(false); //when member is deleted, we should refresh table
    const [memberId, setMemberId] = useState('');
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [deleteLogging, setDeleteLogging] = useState(false);
    const [gamifyTargets, setGamifyTargets] = useState([]);
    const [gamifyChartMembers, setGamifyChartMembers] = useState([]);
    const [gamifyChartMembersLoader, setGamifyChartMembersLoader] = useState(false);
    const gamify_members = useSelector(state => state.getGamifyMembers.gamify_members);
    const loading = useSelector(state => state.getGamifyMembers.loading);

    const location = useSelector(state => state.locationSelected.location);
    const [handleEndGameLoading, setHandleEndLoading] = useState(false);
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const [targetDate, setTargetDate] = useState(-1);
    const [countDown, setCountDown] = useState({});

    const deleteMember = () => {
        setDeleteLogging(true);
        setMemberDeleted(false);
        axios.post(`${baseUrlDev}locations/delete-members/${memberId}`, {}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setDeleteLogging(false)
                setOpenConfirmation(false)
                setMemberDeleted(true);
                dispatch(showAlert(true, "Member deleted Succesfully!"))
                setTimeout(() => {
                    dispatch(showAlert(false, ""))
                }, 5000)
            })
    }

    const handleEndGame = () => {
        setHandleEndLoading(true);
        axios.post(`${baseUrlDev}locations/end-game/${location}`, {}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                dispatch(fetchGamifyMembers(location));
                dispatch(clearGamifyMembers());
                dispatch(showAlert(true, "Game ended Succesfully!"));
                setHandleEndLoading(false);
                setTargetDate(-1);
                setTimeout(() => {
                    dispatch(showAlert(false, ""))
                }, 5000)
            })
    }

    useEffect(() => {
        var timerId = null;
        setTargetDate(gamify_members?.targetDatetime);
        if (targetDate !== -1 && targetDate !== undefined) {
            // because we need every minute to check hours,days,minutes - 60000milliseconds = 1min.
            setCountDown(calculateCountdown(targetDate))
            timerId = setInterval(() => {
                setCountDown(calculateCountdown(targetDate))
            }, 60000)
        } else if (timerId !== null) {
            clearInterval(timerId);
        }

        return () => {
            if (timerId !== null) {
                clearInterval(timerId);
            }
        }
    }, [targetDate, gamify_members?.targetDatetime]);

    useEffect(() => {
        setGamifyChartMembersLoader(true);

        axios.post(`${baseUrlDev}locations/analytics`, { location: location }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setGamifyChartMembers(res.data);
                setGamifyChartMembersLoader(false);
            })
    }, [location]);

    useEffect(() => {
        dispatch(clearGamifyMembers());
        dispatch(fetchGamifyMembers(location));
    }, [dispatch, location]);

    if(deleteLogging) return <div className="logout-div"><img src={"/admin-images/loading_ireview.gif"} alt="loader" /></div>

    return (
        <div className="review_collection_wrapper">
            <AlertComponent />
            <SetupTimer open={openTimer} setOpen={setOpenTimer} setTargetDate={setTargetDate} setGamifyTargets={setGamifyTargets} />
            <AddMember open={open} setOpen={setOpen} setMemberAdded={setMemberAdded} />
            <EditMember open={openEditModal} setOpen={setOpenEditModal} setMemberAdded={setMemberAdded} memberId={memberId} />

            <Dialog onClose={() => setOpenConfirmation(false)} open={openConfirmation}>
                <div style={{ padding: "20px" }}>
                    <DialogTitle>{t("Are you sure you want to delete this member")}?</DialogTitle>
                </div>
                <div className="flex-btns">
                    <LoadingButton
                        style={{ paddingLeft: deleteLogging && "30px" }}
                        className="delete-btn"
                        onClick={() => deleteMember()}
                        loadingIndicator={t("Deleting") + "..."}
                        loading={deleteLogging}
                    >
                        {!deleteLogging && t("Delete")}
                    </LoadingButton>
                    <button onClick={() => {
                        setOpenConfirmation(false)
                        setDeleteLogging(false)
                    }}>{t("Cancel")}</button>
                </div>
            </Dialog>

            <div className="gamify-header-container">
                <Header title="Review Game" subtitle="Manage Review Game" />
                <div className="d-flex align-items-center gap-10 right_side">
                    <button onClick={() => setOpen(true)} className="blue-button-gamify">
                        <img src={"/admin-icons/plus.svg"} alt="edit" />
                        {t("Add New Member")}
                    </button>
                    <DateRange />
                </div>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={8} xl={9}>
                    <GamifyTable 
                        memberAdded={memberAdded} 
                        setOpenEditModal={setOpenEditModal} 
                        setMemberId={setMemberId} 
                        setOpenConfirmation={setOpenConfirmation} 
                        memberDeleted={memberDeleted} 
                        setGamifyChartMembers={setGamifyChartMembers} 
                        setGamifyChartMembersLoader={setGamifyChartMembersLoader}
                    />
                    <GamifyChartWrapper 
                        data={gamifyChartMembers} 
                        loading={gamifyChartMembersLoader} 
                        setGamifyChartMembers={setGamifyChartMembers}
                        setGamifyChartMembersLoader={setGamifyChartMembersLoader}
                        location={location}
                        gamify_members={gamify_members}
                    />
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                    <div className="review_collection_container" style={{ marginBottom: "15px", padding: "40px 0px" }}>
                        <div className="review_collection_body_container" style={{ paddingTop: "35px" }}>
                            <div className="review_collection_star_wrapper">
                                {targetDate !== undefined ? (
                                    <>
                                        <div className="review_collection-stopwatch">
                                            {targetDate === -1 ? '00:00' : (targetDate === null || countDown?.completed) ? '00:00:00' : <>
                                                {countDown?.days} {t("days")} {countDown?.hours} {t("hours")} {countDown?.minutes} {t("minutes")}
                                            </>}
                                        </div>

                                        {countDown?.completed ? <button className="blue-button-gamify" style={{ margin: "30px auto" }} onClick={() => setOpenTimer(true)}>{t("Start Timer")}</button> :
                                            <button className="red-alert-button" onClick={() => handleEndGame()}>
                                                {handleEndGameLoading && <CircularProgress value={20} size={20} sx={{ color: "white" }} />}
                                                {t("End Game")}
                                            </button>}
                                    </>

                                ) : (
                                    <div style={{ width: '70%', margin: '0 auto' }}>
                                        <Skeleton style={{ width: '100%', height: '40px', transform: 'unset' }} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="review_collection_container">
                        <div className="review_collection_header_container">
                            <div className={active === 1 ? "active" : ""} onClick={() => setActive(1)}>
                                {t("Daily")}
                            </div>
                            <div className={active === 2 ? "active" : ""} onClick={() => setActive(2)}>
                                {t("Weekly")}
                            </div>
                            <div className={active === 3 ? "active" : ""} onClick={() => setActive(3)}>
                                {t("Monthly")}
                            </div>
                        </div>
                        <div className="review_collection_body_container">
                            <RightSideGamify active={active} gamifyTargets={gamifyTargets} setGamifyTargets={setGamifyTargets} />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>

    )
}
export default GamifyReviews