import React, {useState} from "react"
import { Modal, ClickAwayListener, Collapse } from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import { VscCalendar } from "react-icons/vsc"
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { useSelector, useDispatch } from "react-redux"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { showAlert } from "../../../../redux/actions"
import { clearGamifyMembers, fetchGamifyMembers } from "../../../../redux/actions/admin-index"

const SetupTimer = ({open, setOpen, setTargetDate, setGamifyTargets}) => {
    const [t] = useTranslation()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [year, setYear] = useState(new Date().getFullYear())
    const [month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }))
    const [day, setDay] = useState(new Date().getDate())
    const [date, setDate] = useState(new Date())
    const [target, setTarget] = useState(null);
    const location = useSelector(state => state.locationSelected.location)
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false)
    }

    const getCalendarVal = (value) => {
        setYear(value.getFullYear())
        setMonth(value.toLocaleString('default', { month: 'long' }))
        setDay(('0' + value.getDate()).slice(-2))
        setShow(false)
    }

    const handleStartGame = () => {
        const dateStr = `${year}-${month}-${day}`;
        setTargetDate(-1);

        const postData = {
            target_datetime: dateStr,
            daily_target: target,
        };
        setLoading(true);

        axios.post(`${baseUrlDev}locations/start-game/${location}`, postData, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(res => {
            setTargetDate(dateStr);
            setOpen(false);
            setLoading(false);
            dispatch(clearGamifyMembers());
            dispatch(fetchGamifyMembers(location));
            axios.get(`${baseUrlDev}locations/data-target/${location}`, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(res => {
                setGamifyTargets(res?.data);
                setLoading(false);
            }).catch(err => {
                setGamifyTargets([]);
                setLoading(false);
            })
            dispatch(showAlert(true, "Game started successfully."))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        })
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-user-gamify-title"
            aria-describedby="modal-user-gamify-description"
        >
         <div className="wrapper-middle-modal" >
            <div className="custom-modal-header">
                <h5>Setup Timer</h5>
            </div>
            <div className="custom-modal-body-stp">
                <div className="end_date_wrapper" style={{marginBottom: "20px"}}>
                    <label className="input-date-label">End Date</label>
                    <ClickAwayListener onClickAway={() => setShow(false)}>
                        <div>
                            <div onClick={() => setShow(!show)} className="input-date-holder"> 
                                <VscCalendar/>
                                {month} {day}, {year}
                            </div>
                            <Collapse in={show}>
                                <div>
                                    <Calendar 
                                        className="custom-react-calendar"
                                        value={date} 
                                        onChange={(value) => getCalendarVal(value)} 
                                        minDate={new Date()}
                                    />
                                </div>
                            </Collapse>
                        </div>
                    </ClickAwayListener>
                </div>

                <div className="daily_target_wrapper">
                    <label className="input-date-label">Add Daily Target</label>
                    <input type="number" className="input-text-holder" value={target} onChange={(e) => setTarget(e.target.value)} />
                </div>
            </div>
            <div className="custom-modal-footer">
                <LoadingButton
                    style={{paddingLeft: loading && "30px"}}
                    loading={loading}
                    className="save-btn"
                    onClick={() => handleStartGame()}
                >
                    Save
                </LoadingButton>
                <button className="close-btn" onClick={() => setOpen(false)}>Cancel</button>
            </div>
         </div>
        </Modal>
    )
}
export default SetupTimer;