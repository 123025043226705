import React, {Suspense, useState} from "react"
import { IconButton, Skeleton, Grid, Collapse, ClickAwayListener} from "@mui/material"
import LightTooltip from "../../helpers/lightTooltip"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
const LinkVisitsChart = React.lazy(() => import("./link_visits_chart"))

const LinkVisited = ({linkVisits, linkVisitsNum, linkVisitsDays, linkVisitPhrases}) => {
    const [t] = useTranslation()
    const [show, setShow] = useState(false)
    const language = useSelector(state => state.getLanguage.language)

    return(
        <div className="dashboard-box-containers">
            <LightTooltip title={t("link-visit-tooltip")}
               
            >
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                </IconButton>
            </LightTooltip>
            <h2>{t("Link Visited")}</h2>
                <Suspense fallback={
                    <div>
                        <Skeleton style={{transform: "unset", width: "95%", height: "200px", margin: "auto"}}/>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: "10px"}}>
                            <Skeleton style={{width: "100px"}}/>
                        </div>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: "10px"}}>
                            <Skeleton style={{width: "100px"}}/>
                        </div>
                    </div>
                }>
                <LinkVisitsChart linkVisits={linkVisits} language={language}/>
                {linkVisitPhrases && linkVisitPhrases.length == 0 ? 
                    <div className="link-visited-container">
                        <div className="link-visited-wrapper">
                            <div>
                                <div className="analytics-visits">
                                    <div>
                                        <span>{linkVisitsNum}</span>
                                        <h6>{t("Total Visits")}</h6>
                                    </div>
                            
                                </div>
                            </div>
                            <div>
                                <div className="analytics-visits">
                                    <div>
                                        <span>{linkVisitsDays}</span>
                                        <h6>{t("Last 30 Days")}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 :
                    <div className="link-visited-container">
                        <div className="link-visited-wrapper link-visited-wrapper-phr"> 
                            <div>
                                <div className="analytics-visits">
                                    <div>
                                        <span>{linkVisitsNum}</span>
                                        <h6>{t("Total Visits")}</h6>
                                    </div>
                                    <div>
                                        <span>{linkVisitsDays}</span>
                                        <h6>{t("Last 30 Days")}</h6>
                                    </div>
                                </div>
                            </div>
                            {linkVisitPhrases && linkVisitPhrases.length > 0 &&
                                <div>
                                    <div className="analytics-phrases">
                                        <span>{t("Visits By Phrase")}</span>
                                        <div>
                                            {linkVisitPhrases.slice(0, 3).map(link => {
                                                return(
                                                    <div style={{marginBottom: "9px"}} key={link.phrase}>
                                                        <h6>{link.phrase}</h6>
                                                        <span>{link.views} visits</span>
                                                    </div> 
                                                )
                                            })}
                                        </div>
                                        <ClickAwayListener onClickAway={() => setShow(false)}>
                                            <div className="collapse-other-phrases">
                                                {linkVisitPhrases.length > 3  && <span onClick={() => setShow(!show)}>View More</span>}
                                                <Collapse in={show}>
                                                    <div className="analytics-phrases">
                                                        <div>
                                                            {linkVisitPhrases.slice(0, 3).map((link, i) => {
                                                                return(
                                                                    <div style={{marginBottom: "9px"}} key={`${i}-${link.phrase}`}>
                                                                        <h6>{link.phrase}</h6>
                                                                        <span>{link.views} visits</span>
                                                                    </div> 
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </ClickAwayListener>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </Suspense>
        </div>
    )
}
export default LinkVisited