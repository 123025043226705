import React, {useState, useEffect} from "react"
import { FormControlLabel, Checkbox } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
//our imports
import { fieldMaxLength, fieldMinLength, fieldRequired } from "../../../redux/actions"

const FieldValidationRules = ({form_fields}) => {
    const dispatch = useDispatch()
    const fieldType = useSelector(state => state.fieldTypeReducer.type)
    const min = useSelector(state => state.FormFieldAdded.min_length)
    const max = useSelector(state => state.FormFieldAdded.max_length)
    const req = useSelector(state => state.FormFieldAdded.required)
    const {t} = useTranslation()
    const handleChange = (e) => {
        dispatch(fieldRequired(e.target.checked === true ? true : false))
    }

    useEffect(() => {
        if(form_fields){
            dispatch(fieldMaxLength(form_fields.data.max_length))
            dispatch(fieldMinLength(form_fields.data.min_length))
            dispatch(fieldRequired(form_fields.data.required === 1 ? true : false))
        } else {
            dispatch(fieldMaxLength(''))
            dispatch(fieldMinLength(''))
            dispatch(fieldRequired(true))
        }
    }, [form_fields])


    return(
        <div className="field-display-container">
            <div className="label-field-container">
                {(fieldType === 'input' || fieldType === undefined) && 
                    <>
                        <div className="type-num"> 
                            <label>{t("Min Length")}</label>
                            <input 
                                type="number" 
                                defaultValue={form_fields && form_fields.data.min_length} 
                                name="min-length" placeholder={t("Min Length").toLocaleLowerCase()} 
                                onChange={(e) => {
                                    dispatch(fieldMinLength(e.target.value))
                                }}
                            />
                            <span>{t("Applies a min length validator to the field")}</span>
                        </div>
                        <div className="type-num">
                            <label>{t("Max Length")}</label>
                            <input 
                                type="number" 
                                defaultValue={form_fields && form_fields.data.max_length} 
                                name="max-length" placeholder={t("Max Length").toLocaleLowerCase()} 
                                onChange={(e) => {
                                    dispatch(fieldMaxLength(e.target.value))
                                }}
                            />
                            <span>{t("Applies a max length validator to the field")}</span>
                        </div>
                    </>
                }
                <FormControlLabel
                    control={
                        <Checkbox 
                            checked={req} 
                            onChange={(e) => handleChange(e)} name="required" 
                            sx={{
                                color: "#00B4E5!important",
                                '&.Mui-checked': {
                                    color: '#00B4E5',
                                },
                            }}
                        />
                    }
                    label={t('Required')}
                />
                <span>{t("Make input required or not")}</span>
            </div>
    </div>
    )
}
export default FieldValidationRules