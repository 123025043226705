import React, {useState, useEffect} from "react"
import Header from "../../../Helpers/header"
import { FormGroup, Select,  MenuItem, FormControlLabel, Rating, Collapse, Grid, IconButton, Typography, Alert} from "@mui/material"
import { AntSwitch2 } from "../../../Helpers/AntSwitch2"
import WhiteTooltip from "../../helpers/whiteTooltip"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { LoadingButton } from "@mui/lab"
import { Link } from "react-router-dom"
import axios from "axios"
import AlertComponent from "../../../Helpers/Alert"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { useDispatch, useSelector } from "react-redux"
import { showAlert } from "../../../../redux/actions"
import { fetchAutopilotSettings, fetchLocationConnections, clearAutopilotSettings, fetchUserInfo, showYoutubeContent } from "../../../../redux/actions/admin-index"
import { AutoPilotSettingsSkeleton } from "../../helpers/autoPilotSkeleton"
import { useNavigate } from "react-router-dom"
import AutoPilotToggle from "./AutoPilotToggle"
import { languages } from "../../../Helpers/languages"
import { FlagIcon } from "react-flag-kit"
import MySelectBlue from "../../helpers/mySelectBlue"
import { useTranslation } from "react-i18next"

const SettingsAutopilotWrapper = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [showWarning, setShowWarning] = useState(false)
    const [skeleton, showSkeleton] = useState(true)
    const {t} = useTranslation()

    const [settings, setSettings] = useState(
        {
            star_settings: [
                {rating: 1, autoReply: 1, ai_autoreply: 1,  autoreply_text: "", nc_autoreply: 1, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 1, rs_autoreply_text: ""},
                {rating: 2, autoReply: 1, ai_autoreply: 1,  autoreply_text: "", nc_autoreply: 1, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 1, rs_autoreply_text: ""},
                {rating: 3, autoReply: 1, ai_autoreply: 1,  autoreply_text: "", nc_autoreply: 1, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 1, rs_autoreply_text: ""},
                {rating: 4, autoReply: 1, ai_autoreply: 1,  autoreply_text: "", nc_autoreply: 1, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 1, rs_autoreply_text: ""},
                {rating: 5, autoReply: 1, ai_autoreply: 1,  autoreply_text: "", nc_autoreply: 1, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 1, rs_autoreply_text: ""}
            ],
            language: "auto",
            ai_response_confirm: 0
        }
    )
    const location = useSelector(state => state.locationSelected.location)
    const user = useSelector(state => state.getUserInfo.user)
    const autopilot_settings = useSelector(state => state.autoPilotSettings.autopilot_settings)
    const connections = useSelector(state => state.getLocationConnections.connections)
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    let location_details;
    if(selected && (!location || location == "all")){
        location_details = user && user.user.locations.filter(loc => loc.id == selected)
    }
    if (location && location !== "all") {
        location_details = user && user.user.locations.filter(loc => loc.id == location)
    }  

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/mLXFNq7-mrE?autoplay=1&mute=0&rel=0'))
    }, [])
    
    useEffect(() => {
        showSkeleton(true)
        dispatch(clearAutopilotSettings())
        dispatch(fetchUserInfo())
    }, [location])

    useEffect(() => {
        showSkeleton(true)
        dispatch(clearAutopilotSettings())
        dispatch(fetchUserInfo())
    }, [selected])

    useEffect(() => {
        if(autopilot_settings){
            if(autopilot_settings.toggle_ai_response){
                setChecked(true) 
                setShowWarning(false)
                setDisabled(false)
            } else {
                setChecked(false)
                setShowWarning(true)
                setDisabled(true)
            }
        }
    }, [autopilot_settings])

    useEffect(() => {
        if(location_details){
            if(location_details[0]?.plan == 3 || (location_details[0]?.plan == 4 && location_details[0]?.la_carte && JSON.parse(location_details[0].la_carte).includes("4"))){
                if(selected && (!location || location == "all")){
                    dispatch(fetchAutopilotSettings(selected))
                }
                if (location && location !== "all") {
                    dispatch(fetchAutopilotSettings(location))
                }
                setDisabled(false)
            }
            // else if(location_details && location_details[0]?.plan == 2){
            //     navigate("/app/admin")
            // }
            else if (( location_details[0]?.plan != 3 && (location_details[0]?.plan != 4 || (location_details[0]?.plan == 4 && !JSON.parse(location_details[0].la_carte).includes("4"))))){
                setDisabled(true)
                setShowWarning(false)
                setSettings((prevSettings) => {
                    const updatedSettings = {...prevSettings};
                    const starSettings = [
                        {rating: 1, autoReply: 0, ai_autoreply: 0, autoreply_text: "", nc_autoreply: 0, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 0, rs_autoreply_text: ""},
                        {rating: 2, autoReply: 0, ai_autoreply: 0, autoreply_text: "", nc_autoreply: 0, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 0, rs_autoreply_text: ""},
                        {rating: 3, autoReply: 0, ai_autoreply: 0, autoreply_text: "", nc_autoreply: 0, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 0, rs_autoreply_text: ""},
                        {rating: 4, autoReply: 0, ai_autoreply: 0, autoreply_text: "", nc_autoreply: 0, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 0, rs_autoreply_text: ""},
                        {rating: 5, autoReply: 0, ai_autoreply: 0, autoreply_text: "", nc_autoreply: 0, nc_autoreply_text: "Thank you for your feedback", rs_autoreply: 0, rs_autoreply_text: ""}
                    ]
                    return {...updatedSettings, star_settings: starSettings};
                });
                setTimeout(() => {
                    showSkeleton(false)
                }, 1000)
            }
        }
   
    }, [user])

    useEffect(() => {
        if(location && location !== "all"){
            dispatch(fetchLocationConnections(location))
        } 
    }, [location])

    useEffect(() => {
        if(autopilot_settings && (location_details[0]?.plan == 3 || (location_details[0]?.plan == 4 && location_details[0]?.la_carte && JSON.parse(location_details[0].la_carte).includes("4")))){
            showSkeleton(false)
            setSettings(autopilot_settings)
        }
    }, [autopilot_settings])

//HANDLE ON/OFF FOR AI REPLIES
    const handleChange = (index) => {
        setSettings((prevSettings) => {
            const updatedSettings = {...prevSettings};
            const starSettings = [...updatedSettings.star_settings];
            const setting = {...starSettings[index]};
            setting.autoReply = setting.autoReply == 1 ? 0 : 1;
            if (setting.autoReply == 0) {
                setting.ai_autoreply = 0;
                setting.autoreply_text = "";
                setting.nc_autoreply = 0;
                setting.nc_autoreply_text = "";
            } else {
                setting.ai_autoreply = 1;
                setting.autoreply_text = "";
                setting.nc_autoreply = 1;
                setting.nc_autoreply_text = "Thank you for your feedback";
            }
            starSettings[index] = setting;
            return {...updatedSettings, star_settings: starSettings};
        });
    }
//CHOOSE IF YOU WANT TO USE CHATGPT API FOR REPLIES, OR YOU WANT TO ADD YOUR TEXT AS AUTOMATIC REPLY BASED ON REVIEW RATE 
    const handleChangeAi = (index) => {
        setSettings((prevSettings) => {
            const updatedSettings = {...prevSettings};
            const starSettings = [...updatedSettings.star_settings];
            const setting = {...starSettings[index]};
            setting.ai_autoreply = setting.ai_autoreply == 1 ? 0 : 1;
            if (setting.ai_autoreply == 1) {
                setting.autoReply = 1;
                setting.autoreply_text = "";
            }
            starSettings[index] = setting;
            return {...updatedSettings, star_settings: starSettings};
        });
    }
//ADD YOUR TEXT AS AUTOMATIC REPLY BASED ON REVIEW RATE 
    const handleChangeText = (e, index) => {
        let value = e.target.value
        setSettings((prevSettings) => {
            const updatedSettings = {...prevSettings};
            const starSettings = [...updatedSettings.star_settings];
            const setting = {...starSettings[index]};
            setting.autoreply_text = value;
            starSettings[index] = setting;
            return {...updatedSettings, star_settings: starSettings};
        });
    }
//CHOOSE IF YOU WANT TO HAVE AN AUTOAMTIC REPLY IF YOUR REVIEW HAS ONLY RATE AND NOT CONTENT
    const handleChangeNc = (index) => {
        setSettings((prevSettings) => {
            const updatedSettings = {...prevSettings};
            const starSettings = [...updatedSettings.star_settings];
            const setting = {...starSettings[index]};
            setting.nc_autoreply = setting.nc_autoreply == 1 ? 0 : 1;
            starSettings[index] = setting;
            return {...updatedSettings, star_settings: starSettings};
        });
    }
//ADD YOUR TEXT AS AUTOMATIC REPLY BASED ON REVIEW RATE  IF YOUR REVIEW DONT HAVE CONTENT 
    const handleChangeTextNc = (e, index) => {
        let value = e.target.value
        setSettings((prevSettings) => {
            const updatedSettings = {...prevSettings};
            const starSettings = [...updatedSettings.star_settings];
            const setting = {...starSettings[index]};
            setting.nc_autoreply_text = value;
            starSettings[index] = setting;
            return {...updatedSettings, star_settings: starSettings};
        });
    }

//CHOOSE YOUR REVIEW SIGNATURE BASED ON STARS
    const handleChangeRs = (index) => {
        setSettings((prevSettings) => {
            const updatedSettings = {...prevSettings};
            const starSettings = [...updatedSettings.star_settings];
            const setting = {...starSettings[index]};
            setting.rs_autoreply = setting.rs_autoreply == 1 ? 0 : 1;
            starSettings[index] = setting;
            return {...updatedSettings, star_settings: starSettings};
        });
    }
//ADD YOUR TEXT FOR EACH REVIEW SIGNATURE
    const handleChangeTextRs = (e, index) => {
        let value = e.target.value
        setSettings((prevSettings) => {
            const updatedSettings = {...prevSettings};
            const starSettings = [...updatedSettings.star_settings];
            const setting = {...starSettings[index]};
            setting.rs_autoreply_text = value;
            starSettings[index] = setting;
            return {...updatedSettings, star_settings: starSettings};
        });
    }

    const handleLanguageChange = (e) => {
        let value = e.target.value
        setSettings(prevSettings => ({...prevSettings, language: value}));
    }

    const handleConfirmAiReply = (e) => {
        let value = e.target.checked
        setSettings(prevSettings => ({...prevSettings, ai_response_confirm: value ? 1 : 0}));
    }

    const updatedSettings = () => {
        const hasMissingText = settings.star_settings.filter(
            (setting) => setting.autoReply === 1 && setting.ai_autoreply === 0 && !setting.autoreply_text
        );
        if(hasMissingText && hasMissingText.length > 0){
            hasMissingText.map(missing => {
                setErrors(prev => [...prev, `Autoreply Text is required if AI is off and Autoreply is on, please provide text as response for ${missing.rating} star reviews`])
            })
            setTimeout(()=>{
                setErrors([])
            },5000);  
        } else {
            postRequest()
            setErrors([])
        }
    }

    const postRequest = () => {
        if(selected && (!location || location == "all")){
            apiRequest(selected)
        }
        if (location && location !== "all") {
            apiRequest(location)
        }
    }

    const apiRequest = (id) => {
        setLoading(true)
        axios.post(`${baseUrlDev}locations/store-ai-settings/${id}`, {settings}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            setLoading(false)
            dispatch(showAlert(true, "Settings are updated successfully!"))
            setTimeout(()=>{
                dispatch(showAlert(false,""));
            },5000);  
        })
        .catch(err => {
            setLoading(false)
        })
    }
    if(skeleton || (((location_details && location_details[0]?.plan == 3) || (location_details && (location_details[0]?.plan == 4 && location_details[0]?.la_carte && JSON.parse(location_details[0].la_carte).includes("4")))) && autopilot_settings === undefined) ){
        return(
            <AutoPilotSettingsSkeleton/>
        )
    }

    const notConnected = () => {
        const excludedKeys = ['tripAdvisor', 'booking.com']; // temporarily disregard these source
        const notConnectedCount = Object.entries(connections).filter(([key, subObj]) => 
            !excludedKeys.includes(key) && typeof subObj != "undefined" && !subObj.connected
        ).length;
        return notConnectedCount
    }
    const ConnectionComponent = ({ icon, name, isConnected }) => {
        if (isConnected) {
          return null;
        }
        return (
          <div>
            <div>
              <img src={`/admin-icons/${icon}`} />
              <h6>{ name }</h6>
            </div>
            <Link to={`/app/admin/reviews/connections/${name}`}>{t("Connect")}</Link>
          </div>
        );
    };
    const ConnectionsComponent = () => {
        const sources = [
            { icon: "google.svg", name: "Google", key: "google" },
            { icon: "facebook.svg", name: "Facebook", key: "facebook" },
            { icon: "yelp.svg", name: "Yelp", key: "yelp" },
            { icon: "openTable.svg", name: "OpenTable", key: "opentable" },
            // temporarily disregard these source
            // { icon: "tripAdvisor.svg", name: "TripAdvisor", key: "tripAdvisor" },
            // { icon: "booking.svg", name: "Booking.com", key: "booking.com" },
        ];
        return (<>
        {sources.map(source => (
          <ConnectionComponent
            key={source.key}
            icon={source.icon}
            name={source.name}
            isConnected={connections[source.key]?.connected}
          />
        ))}
      </>)
    }

    return(
        <div className="ai-settings-container">
            <AlertComponent/>
            {disabled && (location_details && location_details[0]?.plan != 3 && (location_details[0]?.plan != 4 || (location_details[0]?.plan == 4 && !JSON.parse(location_details[0].la_carte).includes("4")))) &&
                <Alert className="d-flex align-items-center" severity="warning" style={{marginTop: "20px"}} icon={<div style={{marginTop: "10px"}}><img src="/admin-icons/warning.svg"/></div>}>
                  <p className="warning-alert-p"
                    dangerouslySetInnerHTML={{__html: t("AI-Settings")}}
                  ></p>
              </Alert>
            }
            <div className="alert-inside-page">
                {errors && errors.map(err => {
                    return(
                        <Alert key={err} severity="error" className="custom-error-alert">{err}</Alert>
                    )
                })}
            </div>
            <div className="ai-settings-wrapper">
            {connections && notConnected() > 0 &&
                <Grid container>
                    <Grid item xs={12}>
                        <div className="settings-signature-wrapper">
                            <h6>Autopilot AI requires connecting with the following platforms:</h6>
                        </div>
                        <div className="connections-wrapper">
                            <ConnectionsComponent />
                        </div>
                    </Grid>
                </Grid>
                }
                <AutoPilotToggle 
                    checked={checked} 
                    setChecked={setChecked} 
                    from="settings" 
                    showWarning={showWarning} 
                    setShowWarning={setShowWarning}
                    setDisabled={setDisabled}
                />
                <div className="settings-div-container">
                    {settings.star_settings.map((setting, i) => {
                        let rateNumber = setting.rating == 1 ? "one" : setting.rating == 2 ? "two" : setting.rating == 3 ? "three" : setting.rating == 4 ? "four" : "five"
                        return(
                            <div className={`settings-div-wrapper ${setting.autoReply == 1 ? "active" : ""}`} key={`ai-settings-${setting.rating}`} style={{opacity: disabled ? "0.6" : "1"}}>
                                <h5>{t(`Manage replies for ${rateNumber}-star reviews`)} 
                                    <WhiteTooltip title={
                                        <>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: 
                                                    `
                                                    <div>
                                                        <p>-${t(`First-toggle-${rateNumber}`)}</p>
                                                        <p>-${t(`Second-toggle-${rateNumber}`)}</p>
                                                        <p> -${t(`Third-toggle-${rateNumber}`)}</p>
                                                        <p>-${t(`review-signature-tooltip-${rateNumber}`)}</p>
                                                    </div>  
                                                    `
                                                }}
                                            >

                                            </div>
                                        </>
                                    }>
                                        <IconButton style={{padding: "0px", marginLeft: "10px"}}>
                                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                        </IconButton>
                                    </WhiteTooltip>
                                </h5>
                                <div>
                                    <FormGroup style={{marginRight: "5px"}}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            control={
                                                <AntSwitch2 disabled={disabled} checked={setting.autoReply == 1 ? true : false} onChange={() => handleChange(i)} name="review_notification" />
                                            }
                                            label={
                                                <Typography variant="body1" style={{ fontFamily: 'Poppins-Regular', color: '#0c2640', fontSize: "10px" }}>
                                                    {t("ON")}/{t("OFF")}
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup style={{marginRight: "15px"}}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            style={{fontSize: "10px"}}
                                            control={
                                                <AntSwitch2 disabled={disabled} checked={setting.ai_autoreply == 1 ? true : false} onChange={(e) => handleChangeAi(i)} name="review_notification" />
                                            }
                                            label={
                                                <Typography variant="body1" style={{ fontFamily: 'Poppins-Regular', color: '#0c2640', fontSize: "10px" }}>
                                                    AI
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup style={{marginRight: "15px"}}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            style={{fontSize: "10px"}}
                                            control={
                                                <AntSwitch2 disabled={disabled} checked={setting.nc_autoreply == 1 ? true : false} onChange={(e) => handleChangeNc(i)} name="review_notification" />
                                            }
                                            label={
                                                <Typography variant="body1" style={{ fontFamily: 'Poppins-Regular', color: '#0c2640', fontSize: "10px" }}>
                                                    NC
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup style={{marginRight: "15px"}}>
                                        <FormControlLabel
                                            labelPlacement="top"
                                            style={{fontSize: "10px"}}
                                            control={
                                                <AntSwitch2 disabled={disabled} checked={setting.rs_autoreply == 1 ? true : false} onChange={(e) => handleChangeRs(i)} name="review_notification" />
                                            }
                                            label={
                                                <Typography variant="body1" style={{ fontFamily: 'Poppins-Regular', color: '#0c2640', fontSize: "10px" }}>
                                                    RS
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                    <div className="rating-settings-background">
                                        <Rating
                                            sx={{ '& .MuiRating-icon': { mr: 1 } }}
                                            icon={<img src={"/admin-images/filled-icon.svg"} className="custom-rating-sm-ic" />}
                                            emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-sm-ic" />}
                                            value={setting.rating}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <Collapse in={setting.ai_autoreply == 0 && setting.autoReply == 1}>
                                    <div className="settings-text-wrapper">
                                        <h6>{t("Add your customized response")}</h6>
                                        <textarea  value={setting.autoreply_text} rows={4} className="custom-scrollbar-vertical" onChange={(e) => handleChangeText(e, i)}/>
                                    </div>
                                </Collapse>
                                <Collapse in={setting.nc_autoreply == 1}>
                                    <div className="settings-text-wrapper">
                                        <h6>{t("Add your customized response for No-Content Replies")}</h6>
                                        <textarea  value={setting.nc_autoreply_text} rows={4} className="custom-scrollbar-vertical" onChange={(e) => handleChangeTextNc(e, i)}/>
                                    </div>
                                </Collapse>
                                <Collapse in={setting.rs_autoreply == 1}>
                                    <div className="settings-text-wrapper">
                                        <h6>{t("Add Review Signature")}</h6>
                                        <textarea  value={setting.rs_autoreply_text} rows={4} className="custom-scrollbar-vertical" onChange={(e) => handleChangeTextRs(e, i)}/>
                                    </div>
                                </Collapse>
                            </div>
                        )
                    })}
                </div>
                <Grid container>
                    <Grid item xs={12} md={6} xl={4}>
                        <div className="settings-div-wrapper" style={{opacity: disabled ? "0.6" : "1", marginTop: "30px"}}>
                            <h5>{t(`Toggle AI response confirm`)} 
                                <WhiteTooltip title={
                                    <>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: 
                                                `
                                                   <div>
                                                        <p>${t("AI first paragraph tooltip")}</p>
                                                        <p>-${t("AI second paragraph tooltip")}</p>
                                                        <p>${t("AI third paragraph tooltip")}</p>
                                                   </div>
                                                `
                                            }}
                                        >

                                        </div>
                                    </>
                                }>
                                    <IconButton style={{padding: "0px", marginLeft: "10px"}}>
                                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                    </IconButton>
                                </WhiteTooltip>
                            </h5>
                            <FormGroup style={{margin: "0px 10px", alignItems: "flex-start"}}>
                                <FormControlLabel
                                    labelPlacement="right"
                                    control={
                                        <AntSwitch2 
                                            disabled={disabled} 
                                            checked={settings.ai_response_confirm == 1 ? true : false} 
                                            onChange={(e) => handleConfirmAiReply(e)} 
                                            name="ai_response_confirm" 
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" style={{ marginLeft: "15px", fontFamily: 'Poppins-Regular', color: '#0c2640', fontSize: "12px" }}>
                                            {t("AI Response Confirm")}
                                        </Typography>
                                    }
                                />
                            </FormGroup>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={6} xl={4}>
                        <div className="settings-signature-wrapper">
                            <h6>
                                {t("Select Language for AI replies")}
                                <WhiteTooltip title={
                                    <>
                                        <div>
                                            {t("Select Language for AI replies")}.
                                        </div>
                                    </>
                                }>
                                    <IconButton style={{padding: "0px", marginLeft: "10px"}}>
                                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                    </IconButton>
                                </WhiteTooltip>
                            </h6>
                            <Select
                                labelId="select-state-label"
                                id="demo-simple-select-error"
                                style={{ width: '100%', height: '47px', borderColor: " rgba(0, 178, 227, 0.3)" }}
                                name='language'
                                value={settings.language ? settings.language : "auto"}
                                label={"Language"}
                                onChange={(e) => handleLanguageChange(e)}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                input={<MySelectBlue/>}
                            >
                                <MenuItem key={"auto-lng"} value="auto"><img src="/admin-icons/flags/language-icon-black.svg" style={{width: "20px", height: "20px", marginRight: "10px"}}/>Same Language As Review</MenuItem>
                                {languages.map(language => {
                                    return (
                                        <MenuItem key={language.lng_code} value={language.name.toLowerCase()}><FlagIcon code={language.code2A}  style={{width: "20px", height: "15px", marginRight: "10px"}}/>{language.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </div>
                    </Grid>
                </Grid>
                {/* <Grid container>
                    <Grid item xs={12}>
                        <div className="settings-signature-wrapper">
                            <h6>{t("Review Signature")}
                                <WhiteTooltip title={
                                    <>
                                        <div>
                                            {t('review-signature-tooltip')}
                                        </div>
                                    </>
                                }>
                                    <IconButton style={{padding: "0px", marginLeft: "10px"}}>
                                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                    </IconButton>
                                </WhiteTooltip>
                            </h6>
                            <textarea value={settings.review_signature} rows={4} className="custom-scrollbar-vertical" onChange={(e) => handleSignature(e)}> </textarea>
                        </div>
                    </Grid>
                </Grid> */}
                <div className='info-page-foot d-flex justify-content-end' style={{ opacity: disabled ? "0.7" : "1" }}>
                    <LoadingButton
                            style={{ paddingLeft: loading && "30px" }}
                            sx={{
                                '& .MuiLoadingButton-loadingIndicator': {
                                    color: '#fff',
                                }
                            }}
                            onClick={updatedSettings}
                            className="btn add_btn blue-loading-btn"
                            loading={loading}
                            disabled={disabled}
                        >
                            {t("Save")}
                    </LoadingButton>

                </div>
            </div>
        </div>
    )
}   
export default SettingsAutopilotWrapper