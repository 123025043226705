import React, { useState, useEffect } from "react"
import { Select, FormControl, MenuItem, Skeleton } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import MySelect from "../../../helpers/mySelect"
import axios from "axios"
import { baseUrlDev } from "../../../../Helpers/baseUrl"
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser"
import { useSelector, useDispatch } from "react-redux"
import { fetchUserInfo } from "../../../../../redux/actions/admin-index"

const SenderWorkspace = ({ handleClose, setActiveStep, couponWorkSpaces, couponWorkSpacesLoading, setSellers, setCampaigns, selectedWorkspace, setSelectedWorkspace }) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [identifier, setIdentifier] = useState('');
    const dispatch = useDispatch()
    const location = useSelector(state => state.locationSelected.location);

    const handleChange = (event) => {
        setSelectedWorkspace(event.target.value);
        setError(false);
    };

    const handleClickWorkSpace = (workspace) => {
        setIdentifier(workspace.identifier);
    }

    useEffect(() => {
        if (!selectedWorkspace) {
            setError(true);
            return;
        }
        axios.post(`${baseUrlDev}reviews/coupon/feature-workspace-details`, { workspace_id: selectedWorkspace }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setSellers(res.data.data.sellers);
                setCampaigns(res.data.data.campaigns);
            })
    }, [selectedWorkspace])

    const changeStep = () => {
        setLoading(true);
        axios.post(`${baseUrlDev}reviews/coupon/feature-create-workspace`, { workspace_id: selectedWorkspace, location: location, identifier: identifier }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
           .then(() => {
                setActiveStep(3);
                setLoading(false);
            })
    }

    return (
        <>
            <div className="wrapper-setup-header">
                <img src={"/admin-icons/sendbat.svg"} alt="sender" />
            </div>
            <div className="wrapper-setup-body custom-scrollbar-vertical sender_workspace_body">
                {
                    couponWorkSpaces?.length > 0 ? <div className="field-container" style={{ paddingLeft: "0", paddingRight: "0" }}>
                        <label>Choose Workspace</label>
                        <FormControl fullWidth>
                            {
                                couponWorkSpacesLoading ? <Skeleton style={{ transform: "unset", width: "100%", height: "40px" }} /> : <Select
                                    name="workspace"
                                    labelId="input-type-one"
                                    id="input-type-one-select"
                                    label="Workspace"
                                    input={<MySelect />}
                                    onChange={handleChange}
                                    value={selectedWorkspace ? selectedWorkspace : 'select'}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 300, maxWidth: '400px' } } }}
                                >
                                    <MenuItem disabled value="select">
                                        <em>Please Select Workspace</em>
                                    </MenuItem>
                                    {couponWorkSpaces?.map(workspace => {
                                        return (
                                            <MenuItem key={`workspace${workspace.workspace_id}`} value={workspace.workspace_id} onClick={() => handleClickWorkSpace(workspace)}>{workspace.workspace_name}</MenuItem>

                                        )
                                    })}
                                </Select>
                            }
                        </FormControl>
                        {
                            error && <span className="workspace_error">Please Select a workspace</span>
                        }
                    </div> :
                        <div className="no_workspace_wrapper">
                            <p>You don't have any workspace in Sendbat, please <a href="https://sendbat.com/launchpad" target="_blank">create one</a></p>
                        </div>
                }
            </div>
            {
                couponWorkSpaces?.length > 0 && <div className="custom-modal-footer">
                    <div>
                        <LoadingButton
                            style={{ paddingLeft: loading && "30px" }}
                            loading={loading}
                            className="save-btn"
                            onClick={changeStep}
                            disabled={!selectedWorkspace}
                        >
                            Save
                        </LoadingButton>
                        <button onClick={() => handleClose()} className="close-btn">Cancel</button>
                    </div>
                </div>
            }
        </>
    )
}
export default SenderWorkspace