import { useEffect } from 'react';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import { Grid, Skeleton } from "@mui/material";
import { FetchDataHook } from '../../helpers/fetchDataHook';
import './style.css';
import useHandleDisable from '../../helpers/disableSelect';
import { showYoutubeContent } from '../../../../redux/actions/admin-index';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const MyDevCrm = () => {
  const dispatch = useDispatch()
  const { data, loading } = FetchDataHook(`${baseUrlDev}platforms?goto=crm`, { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' });
  const handleDisable = useHandleDisable()
  const {t} = useTranslation()
  useEffect(() => {
    dispatch(showYoutubeContent('https://www.youtube.com/embed/T-I4HYCz9yE?autoplay=1&mute=0&rel=0'))
  }, [])

  useEffect(() => {
    handleDisable()
  }, [])

  return (
    <>
      {
        loading || data === null ? <Skeleton style={{ width: "100%", height: "100vh", marginRight: "10px", marginBottom: "10px", transform: "unset" }} /> : <>
          <div className="workspace-holder-container">
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <div className="workspace-holder-title">
                  <img src={data?.platform?.logo} alt="" />
                  <h3>{data?.platform?.title}</h3>
                </div>
                <div className="workspace-holder-description">
                  <p>{data?.platform?.description}</p>
                </div>
              </Grid>
            </Grid>
          </div>

          {
            data?.go_to_platform && <Grid container spacing={2}>
              <Grid item sm={12}>
                <div className="go-to-platform-btn">
                  <a href={data?.go_to_platform} className='link-platform-btn'>{t("Go to platform")}</a>
                </div>
              </Grid>
            </Grid>
          }
        </>
      }
    </>
  )
}

export default MyDevCrm
