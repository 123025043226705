export const countries = [
    {
        name: "Afghanista",
        code2A: "AF",
        code3A: "AFG",
        capital: 'Kabul'
    },
    {
        name: "Albania",
        code2A: "AL",
        code3A: "ALB",
        capital: 'Tirana'
    },
    {
        name: "Algeria",
        code2A: "DZ",
        code3A: "DZA",
        capital: 'Algiers'
    },
    {
        name: "Andorra",
        code2A: "AD",
        code3A: "AND",
        capital: 'Andorra la Vella'
    },
    {
        name: "Angola",
        code2A: "AO",
        code3A: "AGO",
        capital: 'Luanda'
    },
    {
        name: "Antigua and Barbuda",
        code2A: "AG",
        code3A: "ATG",
        capital: 'Saint Johns'
    },
    {
        name: "Argentina",
        code2A: "AR",
        code3A: "ARG",
        capital: 'Buenos Aires'
    },
    {
        name: "Armenia",
        code2A: "AM",
        code3A: "ARM",
        capital: 'Yerevan'
    },
    {
        name: "Australia",
        code2A: "AU",
        code3A: "AUS",
        capital: 'Canberra'
    },
    {
        name: "Austria",
        code2A: "AT",
        code3A: "AUT",
        capital: 'Vienna'
    },
    {
        name: "Azerbaijan",
        code2A: "AZ",
        code3A: "AZE",
        capital: 'Baku'
    },
    {
        name: "Bahamas",
        code2A: "BS",
        code3A: "BHS",
        capital: 'Nassau'
    },
    {
        name: "Bahrain",
        code2A: "BH",
        code3A: "BHR",
        capital: 'Manama'
    },
    {
        name: "Bangladesh",
        code2A: "BD",
        code3A: "BGD",
        capital: 'Dhaka'
    },
    {
        name: "Barbados",
        code2A: "BB",
        code3A: "BRB",
        capital: 'Bridgetown'
    },
    {
        name: "Belarus",
        code2A: "BY",
        code3A: "BLR",
        capital: 'Minsk'
    },
    {
        name: "Belgium",
        code2A: "BE",
        code3A: "BEL",
        capital: 'Brussels'
    },
    {
        name: "Belize",
        code2A: "BZ",
        code3A: "BLZ",
        capital: 'Brussels'
    },
    {
        name: "Benin",
        code2A: "BJ",
        code3A: "BEN",
        capital: 'Porto-Novo Cotonou'
    },
    {
        name: "Bhutan",
        code2A: "BT",
        code3A: "BTN",
        capital: 'Thimphu'
    },
    {
        name: "Bolivia",
        code2A: "BO",
        code3A: "BOL",
        capital: 'La Paz (administrative) Sucre (legislative/judiciary)'
    },
    {
        name: "Bosnia and Herzegovina",
        code2A: "BA",
        code3A: "BIH",
        capital: 'Sarajevo'
    },
    {
        name: "Botswana",
        code2A: "BW",
        code3A: "BWA",
        capital: 'Gaborone'
    },
    {
        name: "Brazil",
        code2A: "BR",
        code3A: "BRA",
        capital: 'Brasília'
    },
    {
        name: "Brunei",
        code2A: "BN",
        code3A: "BRN",
        capital: 'Bandar Seri Begawan'
    },
    {
        name: "Bulgaria",
        code2A: "BG",
        code3A: "BGR",
        capital: 'Sofia'
    },
    {
        name: "Burkina Faso",
        code2A: "BF",
        code3A: "BFA",
        capital: 'Ouagadougou'
    },
    {
        name: "Burma",
        code2A: "MM",
        code3A: "MMR",
        capital: 'Rangoon Nay Pyi Taw (administrative)'
    },
    {
        name: "Burundi",
        code2A: "BI",
        code3A: "BDI",
        capital: 'Bujumbura Gitega (political)'
    },
    {
        name: "Cabo Verde",
        code2A: "CV",
        code3A: "CPV",
        capital: 'Praia'
    },
    {
        name: "Cambodia",
        code2A: "KH",
        code3A: "KHM",
        capital: 'Phnom Penh'
    },
    {
        name: "Cameroon",
        code2A: "CM",
        code3A: "CMR",
        capital: 'Yaoundé'
    },
    {
        name: "Canada",
        code2A: "CA",
        code3A: "CAN",
        capital: 'Ottawa'
    },
    {
        name: "Central African Republic",
        code2A: "CF",
        code3A: "CAF",
        capital: 'Bangui'
    },
    {
        name: "Chad",
        code2A: "TD",
        code3A: "TCD",
        capital: "N'Djamena"
    },
    {
        name: "Chile",
        code2A: "CL",
        code3A: "CHL",
        capital: "Santiago"
    },
    {
        name: "China",
        code2A: "CN",
        code3A: "CHN",
        capital: "Beijing"
    },
    {
        name: "Colombia",
        code2A: "CO",
        code3A: "COL",
        capital: "Bogotá"
    },
    {
        name: "Comoros",
        code2A: "KM",
        code3A: "COM",
        capital: "Moroni"
    },
    {
        name: "Congo(Brazzaville)",
        code2A: "CG",
        code3A: "COG",
        capital: "Brazzaville"
    },
    {
        name: "Congo(Kinshasa)",
        code2A: "CD",
        code3A: "COD",
        capital: "Kinshasa"
    },
    {
        name: "Costa Rica",
        code2A: "CR",
        code3A: "CRI",
        capital: "San José"
    },
    {
        name: "Côte d’Ivoire",
        code2A: "CI",
        code3A: "CIV",
        capital: "Yamoussoukro"
    },
    {
        name: "Croatia",
        code2A: "HR",
        code3A: "HRV",
        capital: "Zagreb"
    },
    {
        name: "Cuba",
        code2A: "CU",
        code3A: "CUB",
        capital: "Havana"
    },
    {
        name: "Cyprus",
        code2A: "CY",
        code3A: "CYP",
        capital: "Nicosia"
    },
    {
        name: "Czechia",
        code2A: "CZ",
        code3A: "CZE",
        capital: "Prague"
    },
    {
        name: "Denmark",
        code2A: "DK",
        code3A: "DNK",
        capital: "Copenhagen"
    },
    {
        name: "Djibouti",
        code2A: "DJ",
        code3A: "DJI",
        capital: "Djibouti"
    },
    {
        name: "Dominica",
        code2A: "DM",
        code3A: "DMA",
        capital: "Roseau"
    },
    {
        name: "Dominican Republic",
        code2A: "DO",
        code3A: "DOM",
        capital: "Santo Domingo"
    },
    {
        name: "Ecuador",
        code2A: "EC",
        code3A: "ECU",
        capital: "Quito"
    },
    {
        name: "Egypt",
        code2A: "EG",
        code3A: "EGY",
        capital: "Cairo"
    },
    {
        name: "El Salvador",
        code2A: "SV",
        code3A: "SLV",
        capital: "San Salvador"
    },
    {
        name: "Equatorial Guinea",
        code2A: "GQ",
        code3A: "GNQ",
        capital: "Malabo"
    },
    {
        name: "Eritrea",
        code2A: "ER",
        code3A: "ERI",
        capital: "Asmara"
    },
    {
        name: "Estonia",
        code2A: "EE",
        code3A: "ESE",
        capital: "Tallinn"
    },
    {
        name: "Eswatini",
        code2A: "SZ",
        code3A: "SWZ",
        capital: "Mbabane (administrative) Lobamba (legislative)"
    },
    {
        name: "Ethiopia",
        code2A: "ET",
        code3A: "ETH",
        capital: "Addis Ababa"
    },
    {
        name: "Fiji",
        code2A: "FJ",
        code3A: "FJI",
        capital: "Suva"
    },
    {
        name: "Finland",
        code2A: "FI",
        code3A: "FIN",
        capital: "Helsinki"
    },
    {
        name: "France",
        code2A: "FR",
        code3A: "FRA",
        capital: "Paris"
    },
    {
        name: "Gabon",
        code2A: "GA",
        code3A: "GAB",
        capital: "Libreville"
    },
    {
        name: "Gambia",
        code2A: "GM",
        code3A: "GMB",
        capital: "Banjul"
    },
    {
        name: "Georgia",
        code2A: "GE",
        code3A: "GEO",
        capital: "Tbilisi"
    },
    {
        name: "Germany",
        code2A: "DE",
        code3A: "DEU",
        capital: "Berlin"
    },
    {
        name: "Ghana",
        code2A: "GH",
        code3A: "GHA",
        capital: "Accra"
    },
    {
        name: "Greece",
        code2A: "GR",
        code3A: "GRC",
        capital: "Athens"
    },
    {
        name: "Grenada",
        code2A: "GD",
        code3A: "GRD",
        capital: "Saint George's"
    },
    {
        name: "Guatemala",
        code2A: "GT",
        code3A: "GTM",
        capital: "Guatemala City"
    },
    {
        name: "Guinea",
        code2A: "GN",
        code3A: "GIN",
        capital: "Conakry"
    },
    {
        name: "Guinea-Bissau",
        code2A: "GW",
        code3A: "GNB",
        capital: "Bissau"
    },
    {
        name: "Guyana",
        code2A: "GY",
        code3A: "GUY",
        capital: "Georgetown"
    },
    {
        name: "Haiti",
        code2A: "HT",
        code3A: "HTI",
        capital: "Port-au-Prince"
    },
    {
        name: "Holy See",
        code2A: "VA",
        code3A: "VAT",
        capital: "Vatican City"
    },
    {
        name: "Honduras",
        code2A: "HN",
        code3A: "HND",
        capital: "Tegucigalpa"
    },
    {
        name: "Hungary",
        code2A: "HU",
        code3A: "HUN",
        capital: "Budapest"
    },
    {
        name: "Iceland",
        code2A: "IS",
        code3A: "ISL",
        capital: "Reykjavík"
    },
    {
        name: "India",
        code2A: "IN",
        code3A: "IND",
        capital: "New Delhi"
    },
    {
        name: "Indonesia",
        code2A: "ID",
        code3A: "IDN",
        capital: "Jakarta"
    },
    {
        name: "Iran",
        code2A: "IR",
        code3A: "IRN",
        capital: "Tehran"
    },
    {
        name: "Iraq",
        code2A: "IQ",
        code3A: "IRQ",
        capital: "Baghdad"
    },
    {
        name: "Ireland",
        code2A: "IE",
        code3A: "IRL",
        capital: "Dublin"
    },
    {
        name: "Israel",
        code2A: "IL",
        code3A: "ISR",
        capital: "Jerusalem"
    },
    {
        name: "Italy",
        code2A: "IT",
        code3A: "ITA",
        capital: "Rome"
    },
    {
        name: "Jamaica",
        code2A: "JM",
        code3A: "JAM",
        capital: "Kingston"
    },
    {
        name: "Japan",
        code2A: "JP",
        code3A: "JPN",
        capital: "Tokyo"
    },
    {
        name: "Jordan",
        code2A: "JO",
        code3A: "JOR",
        capital: "Amman"
    },
    {
        name: "Kazakhstan",
        code2A: "KZ",
        code3A: "KAZ",
        capital: "Nur-Sultan"
    },
    {
        name: "Kenya",
        code2A: "KE",
        code3A: "KEN",
        capital: "Nairobi"
    },
    {
        name: "Kiribati",
        code2A: "KI",
        code3A: "KIR",
        capital: "Tarawa"
    },
    {
        name: "Korea, North",
        code2A: "KP",
        code3A: "PRK",
        capital: "Pyongyang"
    },
    {
        name: "Korea, South",
        code2A: "KR",
        code3A: "KOR",
        capital: "Seoul"
    },
    {
        name: "Kosovo",
        code2A: "XK",
        code3A: "XKS",
        capital: "Pristina"
    },
    {
        name: "Kuwait",
        code2A: "KW",
        code3A: "KWT",
        capital: "Kuwait City"
    },
    {
        name: "Kyrgyzstan",
        code2A: "KG",
        code3A: "KGZ",
        capital: "Bishkek"
    },
    {
        name: "Laos",
        code2A: "LA",
        code3A: "LAO",
        capital: "Vientiane"
    },
    {
        name: "Latvia",
        code2A: "LV",
        code3A: "LVA",
        capital: "Riga"
    },
    {
        name: "Lebanon",
        code2A: "LB",
        code3A: "LBN",
        capital: "Beirut"
    },
    {
        name: "Lesotho",
        code2A: "LS",
        code3A: "LSO",
        capital: "Maseru"
    },
    {
        name: "Liberia",
        code2A: "LR",
        code3A: "LBR",
        capital: "Monrovia"
    },
    {
        name: "Libya",
        code2A: "LY",
        code3A: "LBY",
        capital: "Tripoli"
    },
    {
        name: "Liechtenstein",
        code2A: "LI",
        code3A: "LIE",
        capital: "Vaduz"
    },
    {
        name: "Lithuania",
        code2A: "LT",
        code3A: "LTU",
        capital: "Vilnius"
    },
    {
        name: "Luxembourg",
        code2A: "LU",
        code3A: "LUX",
        capital: "Luxembourg"
    },
    {
        name: "Madagascar",
        code2A: "MG",
        code3A: "MDG",
        capital: "Antananarivo"
    },
    {
        name: "Malawi",
        code2A: "MW",
        code3A: "MWI",
        capital: "Lilongwe"
    },
    {
        name: "Malaysia",
        code2A: "MY",
        code3A: "MYS",
        capital: "Kuala Lumpur"
    },
    {
        name: "Maldives",
        code2A: "MV",
        code3A: "MDV",
        capital: "Male"
    },
    {
        name: "Mali",
        code2A: "ML",
        code3A: "MLI",
        capital: "Bamako"
    },
    {
        name: "Malta",
        code2A: "MT",
        code3A: "MLT",
        capital: "Valletta"
    },
    {
        name: "Marshall Islands",
        code2A: "MH",
        code3A: "MHL",
        capital: "Majuro"
    },
    {
        name: "Mauritania",
        code2A: "MR",
        code3A: "MRT",
        capital: "Nouakchott"
    },
    {
        name: "Mauritius",
        code2A: "MU",
        code3A: "MUS",
        capital: "Port Louis"
    },
    {
        name: "Mexico",
        code2A: "MX",
        code3A: "MEX",
        capital: "Mexico City"
    },
    {
        name: "Micronesia, Federated States",
        code2A: "FM",
        code3A: "FSM",
        capital: "Palikir"
    },
    {
        name: "Moldova",
        code2A: "MD",
        code3A: "MDA",
        capital: "Chisinau"
    },
    {
        name: "Monaco",
        code2A: "MC",
        code3A: "MCO",
        capital: "Monaco"
    },
    {
        name: "Mongolia",
        code2A: "MN",
        code3A: "MNG",
        capital: "Ulaanbaatar"
    },
    {
        name: "Montenegro",
        code2A: "ME",
        code3A: "MNE",
        capital: "Podgorica"
    },
    {
        name: "Morocco",
        code2A: "MA",
        code3A: "MAR",
        capital: "Rabat"
    },
    {
        name: "Mozambique",
        code2A: "MZ",
        code3A: "MOZ",
        capital: "Maputo"
    },
    {
        name: "Namibia",
        code2A: "NA",
        code3A: "NAM",
        capital: "Windhoek"
    },
    {
        name: "Nauru",
        code2A: "NR",
        code3A: "NRU",
        capital: "Kathmandu"
    },
    {
        name: "Netherlands",
        code2A: "NL",
        code3A: "NLD",
        capital: "Amsterdam The Hague (seat of government)"
    },
    {
        name: "New Zealand",
        code2A: "NZ",
        code3A: "NZL",
        capital: "Wellington"
    },
    {
        name: "Nicaragua",
        code2A: "NI",
        code3A: "NIC",
        capital: "Managua"
    },
    {
        name: "Niger",
        code2A: "NE",
        code3A: "NER",
        capital: "Niamey"
    },
    {
        name: "Nigeria",
        code2A: "NG",
        code3A: "NGA",
        capital: "Abuja"
    },
    {
        name: "North Macedonia",
        code2A: "MK",
        code3A: "MKD",
        capital: "Skopje"
    },
    {
        name: "Norway",
        code2A: "NO",
        code3A: "NOR",
        capital: "Oslo"
    },
    {
        name: "Oman",
        code2A: "OM",
        code3A: "OMN",
        capital: "Muscat"
    },
    {
        name: "Pakistan",
        code2A: "PK",
        code3A: "PAK",
        capital: "Islamabad"
    },
    {
        name: "Palau",
        code2A: "PW",
        code3A: "PLW",
        capital: "Ngerulmud"
    },
    {
        name: "Panama",
        code2A: "PA",
        code3A: "PAN",
        capital: "Panama City"
    },
    {
        name: "Papua New Guinea",
        code2A: "PG",
        code3A: "PNG",
        capital: "Port Moresby"
    },
    {
        name: "Paraguay",
        code2A: "PY",
        code3A: "PRY",
        capital: "Asunción"
    },
    {
        name: "Peru",
        code2A: "PE",
        code3A: "PER",
        capital: "Lima"
    },
    {
        name: "Philippines",
        code2A: "PH",
        code3A: "PHL",
        capital: "Manila"
    },
    {
        name: "Poland",
        code2A: "PL",
        code3A: "POL",
        capital: "Warsaw"
    },
    {
        name: "Portugal",
        code2A: "PT",
        code3A: "PRT",
        capital: "Lisbon"
    },
    {
        name: "Qatar",
        code2A: "QA",
        code3A: "QAT",
        capital: "Doha"
    },
    {
        name: "Romania",
        code2A: "RO",
        code3A: "ROU",
        capital: "Bucharest"
    },
    {
        name: "Russia",
        code2A: "RU",
        code3A: "RUS",
        capital: "Moscow"
    },
    {
        name: "Rwanda",
        code2A: "RW",
        code3A: "RWA",
        capital: "Kigali"
    },
    {
        name: "Saint Kitts and Nevis",
        code2A: "KN",
        code3A: "KNA",
        capital: "Basseterre"
    },
    {
        name: "Saint Lucia",
        code2A: "LC",
        code3A: "LCA",
        capital: "Castries"
    },
    {
        name: "Saint Vincent and the Grenadines",
        code2A: "VC",
        code3A: "VCT",
        capital: "Kingstown"
    },
    {
        name: "Samoa",
        code2A: "WS",
        code3A: "WSM",
        capital: "Apia"
    },
    {
        name: "San Marino",
        code2A: "SM",
        code3A: "SMR",
        capital: "San Marino"
    },
    {
        name: "Sao Tome and Principe",
        code2A: "ST",
        code3A: "STP",
        capital: "São Tomé"
    },
    {
        name: "Saudi Arabia",
        code2A: "SA",
        code3A: "SAU",
        capital: "Riyadh"
    },
    {
        name: "Sengal",
        code2A: "SN",
        code3A: "SEN",
        capital: "Dakar"
    },
    {
        name: "Serbia",
        code2A: "RS",
        code3A: "SRB",
        capital: "Belgrade"
    },
    {
        name: "Seychelles",
        code2A: "SC",
        code3A: "SYC",
        capital: "Victoria"
    },
    {
        name: "Sierra Leone",
        code2A: "SL",
        code3A: "SLE",
        capital: "Freetown"
    },
    {
        name: "Singapore",
        code2A: "SG",
        code3A: "SGP",
        capital: "Singapore"
    },
    {
        name: "Slovakia",
        code2A: "SK",
        code3A: "SVK",
        capital: "Bratislava"
    },
    {
        name: "Slovenia",
        code2A: "SI",
        code3A: "SVN",
        capital: "Bratislava"
    },
    {
        name: "Solomon Islands",
        code2A: "SB",
        code3A: "SLB",
        capital: "Honiara"
    },
    {
        name: "Somalia",
        code2A: "SO",
        code3A: "SOM",
        capital: "Mogadishu"
    },
    {
        name: "South Africa",
        code2A: "ZA",
        code3A: "ZAF",
        capital: "Pretoria (administrative) Cape Town (legislative) Bloemfontein (judiciary)"
    },
    {
        name: "South Sudan",
        code2A: "SS",
        code3A: "SSD",
        capital: "Juba"
    },
    {
        name: "Spain",
        code2A: "ES",
        code3A: "ESP",
        capital: "Madrid"
    },
    {
        name: "Sri Lanka",
        code2A: "LK",
        code3A: "LKA",
        capital: "Colombo Sri Jayewardenepura Kotte (legislative)"
    },
    {
        name: "Sudan",
        code2A: "SD",
        code3A: "SDN",
        capital: "Khartoum"
    },
    {
        name: "Suriname",
        code2A: "SR",
        code3A: "SUR",
        capital: "Paramaribo"
    },
    {
        name: "Sweden",
        code2A: "SE",
        code3A: "SWE",
        capital: "Stockholm"
    },
    {
        name: "Switzerland",
        code2A: "CH",
        code3A: "CHE",
        capital: "Bern"
    },
    {
        name: "Syria",
        code2A: "SY",
        code3A: "SYR",
        capital: "Damascus"
    },
    {
        name: "Tajikistan",
        code2A: "TJ",
        code3A: "TJK",
        capital: "Dushanbe"
    },
    {
        name: "Tanzania",
        code2A: "TZ",
        code3A: "TZA",
        capital: "Dar es Salaam  Dodoma (legislative)"
    },
    {
        name: "Thailand",
        code2A: "TH",
        code3A: "THA",
        capital: "Bangkok"
    },
    {
        name: "Timor-Leste",
        code2A: "TL",
        code3A: "TLS",
        capital: "Dili"
    },
    {
        name: "Togo",
        code2A: "TG",
        code3A: "TGO",
        capital: "Lomé"
    },
    {
        name: "Tonga",
        code2A: "TO",
        code3A: "TON",
        capital: "Nuku'alofa"
    },
    {
        name: "Trinidad and Tobago",
        code2A: "TT",
        code3A: "TTO",
        capital: "Port of Spain"
    },
    {
        name: "Tunisia",
        code2A: "TN",
        code3A: "TUN",
        capital: "Tunis"
    },
    {
        name: "Turkey",
        code2A: "TR",
        code3A: "TUR",
        capital: "Ankara"
    },
    {
        name: "Turkmenistan",
        code2A: "TM",
        code3A: "TKM",
        capital: "Ashgabat"
    },
    {
        name: "Tuvalu",
        code2A: "TV",
        code3A: "TUV",
        capital: "Funafuti"
    },
    {
        name: "Uganda",
        code2A: "UG",
        code3A: "UGA",
        capital: "Kampala"
    },
    {
        name: "Ukraine",
        code2A: "UA",
        code3A: "UKR",
        capital: "Kyiv"
    },
    {
        name: "United Arab Emirates",
        code2A: "AE",
        code3A: "ARE",
        capital: "Abu Dhabi"
    },
    {
        name: "England",
        code2A: "GB",
        code3A: "GBR",
        capital: "London"
    },
    {
        name: "United States",
        code2A: "US",
        code3A: "USA",
        capital: "Washington, DC"
    },
    {
        name: "Uruguay",
        code2A: "UY",
        code3A: "URY",
        capital: "Montevideo"
    },
    {
        name: "Uzbekistan",
        code2A: "UZ",
        code3A: "UZB",
        capital: "Tashkent"
    },
    {
        name: "Vanuatu",
        code2A: "VU",
        code3A: "VUT",
        capital: "Port-Vila"
    },
    {
        name: "Venezuela",
        code2A: "VE",
        code3A: "VEN",
        capital: "Caracas"
    },
    {
        name: "Vietnam",
        code2A: "VN",
        code3A: "VNM",
        capital: "Hanoi"
    },
    {
        name: "Yemen",
        code2A: "YE",
        code3A: "YEM",
        capital: "Sanaa"
    },
    {
        name: "Zambia",
        code2A: "ZM",
        code3A: "ZMB",
        capital: "Lusaka"
    },
    {
        name: "Zimbabwe",
        code2A: "ZW",
        code3A: "ZWE",
        capital: "Harare"
    },

]
