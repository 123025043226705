import React from "react"
import { Skeleton, Grid } from "@mui/material"
import Header from "../../Helpers/header"

const style = {
    button: {
        transform: 'unset',
        width: "135px",
        height: "45px"
    },
    scoreBox: {
        transform: 'unset',
        height: "200px",
    },
    tablebox:{
        transform: 'unset',
        height: "400px"
    }
}

const ListingSkeleton = ({title, details}) => {
    return(
        <div className='listing_page' style={{marginTop: "20px"}}>
            <Grid container spacing={2}>
                {details ?      
                <Grid item xs={12}>
                    <Skeleton style={style.scoreBox}/>
                </Grid> 
                : 
                    <>
                        <Grid item xs={12} md={6} lg={4}>
                            <Skeleton style={style.scoreBox}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={8}>
                            <Skeleton style={style.scoreBox}/>
                        </Grid>
                    </>
                
                }
                <Grid item xs={12}>
                    <Skeleton style={style.tablebox}/>
                </Grid>
            </Grid>
        </div>

    )
}
export default ListingSkeleton