import React, {useEffect, useState} from "react"
import { Modal, Grid } from "@mui/material"
import { formatDateCard, formatCardNumber } from "../../helpers/formatCard"
import { LoadingButton } from "@mui/lab"
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { Alert } from "@mui/material"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { useTranslation } from "react-i18next"

const EdiCartModal = ({open, setOpen, cart, allCards, setAllCards}) => {
    const [loading, setLoading] = useState(false)
    const [focusCard, setFocusCard] = useState(false)
    const [cvvFocused, setCvvFocused] = useState(false)
    const [expiryFocused, setExpiryFocused] = useState(false)
    const [nameFocused, setNameFocused] = useState(false)
    const [lastNumbers, setLastNumber] = useState()
    const [errors, setErrors] = useState([])
    const {t} = useTranslation()
    const [cartDetails, setCartDetails] = useState({
        name: "",
        email: "",
        card_number: "",
        expiry: "",
        cvc: "",
        billing_zip: ""
    })

    useEffect(() => {
        setErrors([]
            
            
            )
        if(cart){
            setCartDetails({
                name: cart.name_on_card,
                email: "",
                card_number: "",
                expiry: `${cart.expiration_month}/${cart.expiration_year}`,
                cvc: "",
                billing_zip: ""
            })
            setLastNumber(cart.last_four_numbers)
        }
    }, [cart, open])

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (e) => {
        setErrors([])
        const {name, value} = e.target
        setCartDetails({...cartDetails, [name] : value})
    }

    const handleExpiryChange = (e) => {
        const {name, value} = e.target
        if (value.length <= 7) {
            setCartDetails({...cartDetails, [name] : value})
        }
        
    }

    const handleCardNumber = (e) => {
        const {name, value} = e.target
        if(value.length <= 19 && e.target.validity.valid){
            setCartDetails({...cartDetails, [name] : value})
        } else {
            return false
        }
    }

    const handleCvvNumber = (e) => {
        const {name, value} = e.target
        if(value.length <= 4 ){
            setCartDetails({...cartDetails, [name] : value})
        } else {
            return false
        }
    }

    const editCart = () => {
        setLoading(true)
        const containsNull = Object.values(cartDetails).some(val => val === "" || val === null);
        let data = {
            hash_id: cart.id,
            name_on_card: cartDetails.name,
            card_number: cartDetails.card_number,
            expiration_month: cartDetails.expiry.split("/")[0],
            expiration_year: cartDetails.expiry.split("/")[1],
            cvv: cartDetails.cvc,
            is_primary: cart.is_primary
        }
        if(containsNull){
            setLoading(false)
            setErrors(prev => [...prev, "All Fields are Required"])
            return
        } else {
            axios.post(`${baseUrlDev}subscription/update/card`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then((res) => {
                setLoading(false) 
                const updatedCarts = allCards.map(item => {
                    if(item.id === cart.id){
                        return{
                            ...item,
                            name_on_card: cartDetails.name,
                            last_four_numbers: cartDetails.card_number?.slice(15),
                            expiration_month: cartDetails.expiry.split("/")[0],
                            expiration_year: cartDetails.expiry.split("/")[1],
                        }
                    } else {
                        return item
                    }
                })
                setAllCards(updatedCarts)
                setOpen(false)
            })
            .catch(() => {
                setLoading(false)
            })
        }
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-edit-cart-title"
            aria-describedby="modal-edit-cart-description"
        >
            <div className="wrapper-loc-edit-cart">
                {errors && errors.map(err => {
                    return(
                        <div className="alert-inside-modal" key={err}>
                            <Alert  severity="error" className="custom-error-alert">{err}</Alert>
                        </div>
                    )
                })}
                <div className="cart-details-container">   
                    <div className="cart-details-body custom-scrollbar-vertical">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Name on card")}</label>
                                            <input 
                                                type="text" 
                                                name="name" 
                                                value={cartDetails.name} 
                                                onChange={(e) => handleChange(e)}
                                                onFocus={() => setNameFocused(true)}
                                                onBlur={() => setNameFocused(false)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Email")}</label>
                                            <input 
                                                type="email" 
                                                name="email" 
                                                value={cartDetails.email} 
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Card Number")}</label>
                                            <input 
                                                placeholder="1244 1244 1244 1244"
                                                maxLength="19"
                                                type="text" 
                                                name="card_number" 
                                                value={cartDetails.card_number && formatCardNumber(cartDetails.card_number)} 
                                                onChange={handleCardNumber}
                                                pattern="[0-9\s]*"
                                                onFocus={() => setFocusCard(true)}
                                                onBlur={() => setFocusCard(false)}
                                            />
                                            <h6>Card: <span>**** **** **** {lastNumbers}</span></h6>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Expiry")}</label>
                                            <input 
                                                type="text" 
                                                pattern="[0-9/]*"
                                                name="expiry" 
                                                placeholder="MM / YY" 
                                                value={cartDetails.expiry && formatDateCard(cartDetails.expiry)} 
                                                onChange={(e) => handleExpiryChange(e)}
                                                onFocus={() => setExpiryFocused(true)}
                                                onBlur={() => setExpiryFocused(false)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className="cart-form-inputs">
                                            <label>CVC</label>
                                            <input 
                                                type="number" 
                                                name="cvc" 
                                                maxLength={4} 
                                                value={cartDetails.cvc} 
                                                onChange={(e) => handleCvvNumber(e)}
                                                onFocus={() => setCvvFocused(true)}
                                                onBlur={() => setCvvFocused(false)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Billing Zip")}</label>
                                            <input 
                                                type="text" 
                                                name="billing_zip" 
                                                value={cartDetails.billing_zip} 
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="card-details-wrapper">
                                    <div className="jp-card-container">
                                        <div className={`jp-card ${cvvFocused ? "jp-card-flipped" : ""}`}>
                                            <div className="jp-card-front">
                                                <div className="jp-card-lower">
                                                    <div className="jp-card-shiny"></div>
                                                    <div className={`jp-card-number jp-card-display jp-card-invalid ${focusCard && 'jp-card-focused'}`}>{cartDetails.card_number ? cartDetails.card_number :  `**** **** **** ${lastNumbers}`}</div>
                                                    <div className={`jp-card-name jp-card-display jp-card-valid ${nameFocused && 'jp-card-focused'}`}>{cartDetails.name ? cartDetails.name : "NAME SURNAME"}</div>
                                                    <div className={`jp-card-expiry jp-card-display jp-card-invalid ${expiryFocused && 'jp-card-focused'}`} data-before="month/year" data-after="validthru">{cartDetails.expiry ? formatDateCard(cartDetails.expiry) : "**/****"}</div>
                                                </div>
                                            </div>
                                            <div className="jp-card-back">
                                                <div className="jp-card-bar"></div>
                                                <div className="jp-card-cvc jp-card-display jp-card-invalid">{cartDetails.cvc ? cartDetails.cvc : "***"}</div>
                                                <div className="jp-card-shiny"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="custom-modal-footer" style={{marginTop: "20px", padding: 0}}>
                        <LoadingButton
                            style={{paddingLeft: loading && "30px"}}
                            className="save-btn"
                            onClick={() => editCart()}
                            loading={loading}
                        >
                            {t("Save")}
                        </LoadingButton>
                        <button className="close-btn" onClick={() => setOpen(false)}>{t("Close")}</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default EdiCartModal