import React from "react"
import { Skeleton, IconButton, Rating } from "@mui/material"
import { IoMdInformationCircleOutline } from "react-icons/io"
import LightTooltip from "../../helpers/lightTooltip"
import { CustomLinearProgressAi } from "../../helpers/customLinearProgress"
import { useTranslation } from "react-i18next"

const RatingBreakdown = ({breakdown}) => {
    const {t} = useTranslation()
    return(
        <div className="dashboard-box-containers">
            <LightTooltip title={t("Rating breakdowns show you the number of ratings for each star")}>
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{ opacity: "0.4" }} />
                </IconButton>
            </LightTooltip>
            <h2>{t("Rating Breakdown")}</h2>
            <div className="rating-breakdown-ai-header">
                <h5>{t("Rating")}</h5>
                <h5>{t("Reviews")}</h5>
            </div>
            <div className="rating-breakdown-ai">
                <div>
                    <span>5 <img src={"/admin-images/filled-icon.svg"} /></span>
                    <div style={{flex: "auto"}}>
                        <CustomLinearProgressAi variant="determinate" value={parseInt(Object.entries(breakdown)[0][1].percentage)}/>
                    </div>
                    <h6>{Object.entries(breakdown)[0][1].count}</h6>
                </div>
                <div>
                    <span>4 <img src={"/admin-images/filled-icon.svg"} /></span>
                    <div style={{flex: "auto"}}>
                        <CustomLinearProgressAi variant="determinate" value={parseInt(Object.entries(breakdown)[1][1].percentage)}/>
                    </div>
                     <h6>{Object.entries(breakdown)[1][1].count}</h6>
                </div>
                <div>
                    <span>3 <img src={"/admin-images/filled-icon.svg"} /></span>
                    <div style={{flex: "auto"}}>
                        <CustomLinearProgressAi variant="determinate" value={parseInt(Object.entries(breakdown)[2][1].percentage)}/>
                    </div>
                     <h6>{Object.entries(breakdown)[2][1].count}</h6>
                </div>
                <div>
                    <span>2 <img src={"/admin-images/filled-icon.svg"} /></span>
                    <div style={{flex: "auto"}}>
                        <CustomLinearProgressAi variant="determinate" value={parseInt(Object.entries(breakdown)[3][1].percentage)}/>
                    </div>
                     <h6>{Object.entries(breakdown)[3][1].count}</h6>
                </div>
                <div>
                    <span>1 <img src={"/admin-images/filled-icon.svg"} /></span>
                    <div style={{flex: "auto"}}>
                        <CustomLinearProgressAi variant="determinate" value={parseInt(Object.entries(breakdown)[4][1].percentage)}/>
                    </div>
                     <h6>{Object.entries(breakdown)[4][1].count}</h6>
                </div>
            </div>
        </div>
    )
}
export default RatingBreakdown