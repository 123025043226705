import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";
import { useSelector } from 'react-redux';

const Preview = ({ formData, logoPrev, location_details }) => {
    const [t] = useTranslation()
    let {logo_align, background_color, border_color, logo_size } = formData
    return (
        <div id="qr_code">
            <h5 className='preview__title'>{t("Preview")}</h5>
            <div id="qr-code-preview" className="qr-code-preview" style={{ backgroundColor: `${background_color}` }}>
                <div style={{ textAlign: `${logo_align}` }}>
                    <img src={logoPrev  ? logoPrev : "/admin-images/tools/images/qr-code-logo.jpg"} className="qr-code-logo" style={{ height: `${logo_size}px` }} alt="" />
                </div>
                <div className="share-love">
                    <h2
                        id="preview_title"
                        dangerouslySetInnerHTML={{
                            __html: formData?.title.content
                        }}
                    ></h2>
                    <p
                        id="preview_subtitle"
                        dangerouslySetInnerHTML={{
                            __html: formData?.subtitle?.content
                        }}></p>
                </div>

                <div className="qr-code" id="qr-code">
                    {location_details && location_details[0] && location_details[0].landing_url ? <QRCode
                        size={50}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={location_details[0] && location_details[0].landing_url}
                        viewBox={`0 0 50 50`}
                    /> : "Create Review Page"}
                </div>

                <div className="your-experience" style={{ borderTop: `3px solid ${border_color}`, borderBottom: `3px solid ${border_color}` }}>
                    <p
                        id='preview_description'
                        dangerouslySetInnerHTML={{
                            __html: formData?.description?.content
                        }}
                    >
                    </p>
                </div>

                <div style={{ wordBreak: 'break-all' }}>
                    <p className='irevu-mesasales'>{location_details && location_details[0] && location_details[0].landing_url && location_details[0] && location_details[0].landing_url}/</p>
                </div>
                <div className="qr-code-images">
                    {
                        formData?.images?.map((file, index) => (
                            <img key={index} style={{ maxHeight: '200px', maxWidth: '25%', paddingLeft: '10px' }} src={URL.createObjectURL(file)} />
                        ))
                    }
                </div>

                <div className="powered-by">
                    <p>{t("Powered By")}</p>
                    <img src={"/admin-images/tools/images/irevu-logo-sm.svg"} style={{ maxHeight: '18px', background: '#fff', borderRadius: '3px', padding: '3px' }} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Preview;
