import React, {useState, useEffect} from "react"
import { Collapse, Skeleton, Grid } from "@mui/material"
import Button from "./button.js"
import ContactUsButton from "./contact_us_btn.js";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {MdDragIndicator} from "react-icons/md"
import { useTranslation } from "react-i18next";

const ReviewButton = ({expanded, review_page, handleChangeAccordion, buttons, reviewPageFields, setReviewPageFields}) => {
    const { only_feedback } = reviewPageFields
    const [hideReviewButtonsCheck, setHideReviewButtonsCheck] = useState(false);
    const [reviewButtons, setReviewButtons] = useState([]);
    const {t} = useTranslation()
    const sources = useSelector(state => state.getLocationAvaliableSources.sources)
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    let initialItem = sources && sources.data.filter(s => s.name ==  "Google Maps")
    const location = useSelector(state => state.locationSelected.location)

    let locationItem;
    if(selected && (!location || location == "all")){
        locationItem = selected
    }
    if (location && location !== "all") {
        locationItem = location
    } 

    const obj = {
        id: reviewButtons.length + 1, 
        platform: sources && initialItem[0]?.name, 
        source_id: sources && initialItem[0]?.id,
        location: parseInt(locationItem), 
        label: "Review Us On Google", 
        // url: review_page && `http://search.google.com/local/writereview?placeid=${review_page.data?.location?.placeid}`, 
        url: review_page && review_page?.data?.location ? `http://search.google.com/local/writereview?placeid=${review_page.data?.location?.placeid}` : review_page && `http://search.google.com/local/writereview?placeid=${review_page.data?.placeid}`, 
        color: '#0087FF', 
        source: {
            logo_src: sources && initialItem[0]?.logo
        } 
    };

    const objOnAdd = {
        id: reviewButtons.length + 1, 
        platform: sources && initialItem[0]?.name, 
        source_id: sources && initialItem[0]?.id,
        location: parseInt(locationItem), 
        label: "Review Us On Google", 
        url: "", 
        color: '#0087FF', 
        source: {
            logo_src: sources && initialItem[0]?.logo
        } 
    };

    const addReviewButton = () => {
        setReviewButtons([objOnAdd, ...reviewButtons]);
        setReviewPageFields({...reviewPageFields, "buttons": [obj, ...reviewButtons]})
    }

    useEffect(() => {
        only_feedback == 0 ? setHideReviewButtonsCheck(false) : setHideReviewButtonsCheck(true)
    }, [only_feedback])

    useEffect(() => {
      if(sources){
        if(review_page.data && review_page.data.buttons?.length > 0){
            setReviewButtons([...review_page.data.buttons])
        } else {
            setReviewButtons([obj]);
        }
      }
    }, [review_page, sources])

    useEffect(() => {
        if(reviewButtons && reviewButtons.length > 0){
            setReviewPageFields({...reviewPageFields, "buttons": [...reviewButtons]})
        }
    }, [reviewButtons])

    const updateReviewFieldsCheckbox = (e) => {
        const {checked, name} = e.target
        setReviewPageFields({...reviewPageFields, [name]: checked ? 1 : 0})
    }

    const handleDragEnd = result => {
        if (!result.destination) {
          return;
        }
    
        const items = Array.from(reviewButtons);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setReviewButtons(items);
        setReviewPageFields({...reviewPageFields, "buttons": items})
      };
    return(
        <>
            <div className="content-card-header" onClick={() => handleChangeAccordion("panel2")} >
                <div className='d-flex justify-content-between align-items-center'>
                    <div className="d-flex align-items-center">
                    <div>
                        <img src={"/admin-images/tools/review-button-small-logo.svg"} alt="" />
                        </div>
                        <h3 className="title">{t("Review Button")}</h3>
                        </div>
                    <div>
                        <img src={"/admin-images/tools/small-down-arrow.svg"} className={`cursor-pointer arrow ${expanded?.find(e => e === 'panel2') ? 'rotate' : ''}`}  alt="" />
                    </div>
                </div>
            </div>

            <Collapse in={expanded?.find(e => e === 'panel2') ? true : false}>
                <div id='reviews_button' className='collapse_card_review'>
                    <div className="content-card-review">
                        <div className='top d-flex align-items-center justify-content-between flex-wrap gap-10'>
                            <button type='button' className='btn add_review-btn' onClick={addReviewButton}>
                                <img src={"/admin-icons/plus.svg"} alt="edit" />
                                {t("Add Review Button")}
                            </button>
                            <label className="custom-checkbox hide-buttons-checkbox mb-0">
                                {t("Hide all Review Buttons")}
                                <input type="checkbox" name="only_feedback" className="only_feedback" checked={hideReviewButtonsCheck} onChange={(e) => {
                                    setHideReviewButtonsCheck(!hideReviewButtonsCheck)
                                    updateReviewFieldsCheckbox(e)
                                }} />
                                <span className={`checkmark ${hideReviewButtonsCheck ? 'show' : ''}`}></span>
                            </label>
                        </div>
                        {sources ? 
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="button-list">
                                {(provided) => (
                                <ul
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {reviewButtons?.length > 0 && reviewButtons.map((reviewButton, index) => {
                                        return(
                                            <Draggable key={reviewButton.id} draggableId={reviewButton.id.toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <MdDragIndicator/>
                                                        <Button  key={reviewButton?.id} reviewButton={reviewButton} setReviewButtons={setReviewButtons} reviewPageFields={reviewPageFields} setReviewPageFields={setReviewPageFields} reviewButtons={reviewButtons}/>
                                                    </li>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                        :
                        <div className="mt-20">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Skeleton style={{transform: "unset", width: "100%", height: "41px"}}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Skeleton style={{transform: "unset", width: "100%", height: "41px"}}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Skeleton style={{transform: "unset", width: "100%", height: "41px"}}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Skeleton style={{transform: "unset", width: "100%", height: "41px"}}/>
                                </Grid>
                            </Grid>
                         </div>
                         }
                        {/* {
                            reviewButtons?.map(reviewButton => (
                                <Button  key={reviewButton?.id} reviewButton={reviewButton} setReviewButtons={setReviewButtons} reviewPageFields={reviewPageFields} setReviewPageFields={setReviewPageFields} reviewButtons={reviewButtons}/>
                            ))
                        } */}
                        <ContactUsButton  reviewPageFields={reviewPageFields} setReviewPageFields={setReviewPageFields}/>
                    </div>
                </div>
            </Collapse>
        </>
    )
}
export default ReviewButton