import React, { Suspense, useState } from "react"
import { Skeleton, ClickAwayListener, Collapse } from "@mui/material"
import axios from "axios";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { useTranslation } from "react-i18next";
const GamifyChart = React.lazy(() => import("./GamifyChart"))


const GamifyChartWrapper = ({ data, loading, setGamifyChartMembers, setGamifyChartMembersLoader, location, gamify_members }) => {
    const [show, setShow] = useState(false);
    const [searchName, setSearchName] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const {t} = useTranslation()
    const handleOnChange = (e) => {
        setSearchName(e.target.value);
        setFilteredUsers(gamify_members?.data?.filter(data =>
            data?.name.toLowerCase().includes(searchName.toLowerCase()),
        ));
        setShow(true);
    }

    const handleFilter = (name) => {
        setGamifyChartMembersLoader(true);

        axios.post(`${baseUrlDev}locations/analytics`, { location: location, gamify_search: name }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setGamifyChartMembers(res.data);
                setGamifyChartMembersLoader(false);
                setShow(false);
            });
    }

    return (
        <Suspense fallback={
            <div>
                <Skeleton style={{ transform: "unset", width: "95%", height: "200px", margin: "auto" }} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <Skeleton style={{ width: "100px" }} />
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                    <Skeleton style={{ width: "100px" }} />
                </div>
            </div>
        }>
            <>
                <div className="gamify-chart-header">
                    <h6>{t("Analytics")}</h6>
                    <div className="position-relative">
                        <ClickAwayListener onClickAway={() => setShow(false)}>
                            <div>
                                <div className="position-relative">
                                    <form>
                                        <input
                                            type="text"
                                            placeholder={t("Search User")}
                                            // onChange={() => setShow(true)}
                                            value={searchName}
                                            onChange={handleOnChange}
                                        />
                                        <img src={"/admin-icons/reply-search-icon.svg"} alt="search" className="search-right-icon" />
                                    </form>
                                </div>
                                <Collapse in={show && searchName?.length > 0}>
                                    <div className="gamify-reulst-wrapper custom-scrollbar-vertical">
                                        {
                                            filteredUsers?.map((data, index) => (
                                                <h6 key={index} onClick={() => handleFilter(data?.name)}>{data?.name}</h6>
                                            ))
                                        }
                                        {/* <h6 onClick={() => handleFilter()}>Blerina Pllana</h6>
                                        <h6 onClick={() => handleFilter()}>Blerina Pllana2</h6> */}
                                    </div>
                                </Collapse>
                            </div>
                        </ClickAwayListener>
                    </div>
                </div>
                {loading ? <Skeleton style={{ transform: "unset", width: "100%", height: "300px" }} /> : <GamifyChart data={data} />}
            </>
        </Suspense>
    )
}
export default GamifyChartWrapper