export const hours = [ 
    {value: "00:00", display: "00:00 AM"},
    {value: "00:30", display: "00:30 AM"},
    {value: "01:00", display: "01:00 AM"},
    {value: "01:30", display: "01:30 AM"},
    {value: "02:00", display: "02:00 AM"},
    {value: "02:30", display: "02:30 AM"},
    {value: "03:00", display: "03:00 AM"},
    {value: "03:30", display: "03:30 AM"},
    {value: "04:00", display: "04:00 AM"},
    {value: "04:30", display: "04:30 AM"},
    {value: "05:00", display: "05:00 AM"},
    {value: "05:30", display: "05:30 AM"},
    {value: "06:00", display: "06:00 AM"},
    {value: "06:30", display: "06:30 AM"},
    {value: "07:00", display: "07:00 AM"},
    {value: "07:30", display: "07:30 AM"},
    {value: "08:00", display: "08:00 AM"},
    {value: "08:30", display: "08:30 AM"},
    {value: "09:00", display: "09:00 AM"},
    {value: "09:30", display: "09:30 AM"},
    {value: "10:00", display: "10:00 AM"},
    {value: "10:30", display: "10:30 AM"},
    {value: "11:00", display: "11:00 AM"},
    {value: "11:30", display: "11:30 AM"},
    {value: "12:00", display: "12:00 PM"},
    {value: "12:30", display: "12:30 PM"},
    {value: "13:00", display: "13:00 PM"},
    {value: "13:30", display: "13:30 PM"},
    {value: "14:00", display: "14:00 PM"},
    {value: "14:30", display: "14:30 PM"},
    {value: "15:00", display: "15:00 PM"},
    {value: "15:30", display: "15:30 PM"},
    {value: "16:00", display: "16:00 PM"},
    {value: "16:30", display: "16:30 PM"},
    {value: "17:00", display: "17:00 PM"},
    {value: "17:30", display: "17:30 PM"},
    {value: "18:00", display: "18:00 PM"},
    {value: "18:30", display: "18:30 PM"},
    {value: "19:00", display: "19:00 PM"},
    {value: "19:30", display: "19:30 PM"},
    {value: "20:00", display: "20:00 PM"},
    {value: "20:30", display: "20:30 PM"},
    {value: "21:00", display: "21:00 PM"},
    {value: "21:30", display: "21:30 PM"},
    {value: "22:00", display: "22:00 PM"},
    {value: "22:30", display: "22:30 PM"},
    {value: "23:00", display: "23:00 PM"},
    {value: "23:30", display: "23:30 PM"}
]