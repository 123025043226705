import React from 'react';
import { useTranslation } from 'react-i18next';

const StarRating = ({ handleActiveStar, activeRating }) => {
    const {t} = useTranslation()
    return (
        <div className="star_rating">
            <label htmlFor="" className='label_widget_setup'>{t("Star Rating")}</label>
            <div> 
                <img src={activeRating?.includes('1') ? "/admin-images/star1_full.svg" : "/admin-images/empty_star1.svg"} alt="" onClick={() => handleActiveStar('1')}  />
                <img src={activeRating?.includes('2') ? "/admin-images/star2_full.svg" : "/admin-images/empty_star2.svg"} alt="" onClick={() => handleActiveStar('2')}  />
                <img src={activeRating?.includes('3')? "/admin-images/star3_full.svg" : "/admin-images/empty_star3.svg"} alt="" onClick={() => handleActiveStar('3')}  />
                <img src={activeRating?.includes('4') ? "/admin-images/star4_full.svg" : "/admin-images/empty_star4.svg"} alt="" onClick={() => handleActiveStar('4')}  />
                <img src={activeRating?.includes('5') ? "/admin-images/star5_full.svg" : "/admin-images/empty_star5.svg"} alt="" onClick={() => handleActiveStar('5')}  />
            </div>
        </div>
    )
}

export default StarRating;
