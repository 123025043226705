import React from "react"
import { Grid, IconButton, Skeleton } from "@mui/material"
import CountUp from 'react-countup';
import { Link } from "react-router-dom";
import LightTooltip from "../../helpers/lightTooltip"
import { IoMdInformationCircleOutline } from "react-icons/io"
import {AiFillStar} from "react-icons/ai"
import { useTranslation } from "react-i18next";

const DashboardBoxes = ({reviews, rating, feedback, linkVisits, sub}) => {
  const [t] = useTranslation()
    return(
        <Grid container spacing={2} className="box-containers">
            <Grid item xs={12} md={3}>
                <div>
                    <LightTooltip title={t("The total amount of reviews for your business")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span><CountUp end={reviews && reviews} duration={1}/></span>
                    <h6>{t("Reviews")}</h6>
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                <div>
                    <LightTooltip title={t("The average star rating for your business incorporating all monitored review sites")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span className="rating-box">
                        {rating && rating % 1 === 0 ? 
                            <CountUp 
                                end={rating} 
                                duration={1}
                            />
                            : <CountUp
                                end={rating} 
                                duration={1}
                                decimals={1}
                                decimal="."
                            />}
                            <AiFillStar/>
                        </span>
                    <h6>{t("Rating")}</h6>
                </div>
            </Grid>
            <Grid item xs={12} md={3}>
                <div>
                    <LightTooltip title={t("The number of private feedback forms that have been collected by irevu")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span><CountUp end={feedback && feedback} duration={1}/></span>
                    <h6>iReview</h6>
                </div>
            </Grid>
            {sub ? 
            <Grid item xs={12} md={3}>
                <div className="upgrade-box-container">
                   <div>{t("Online Reputation")}</div>
                   <Link to="/app/admin/subscription">{t("Upgrade")}</Link>
                   <p>{t("Upgrade your account today to start using all iReview Features")}</p>
                </div>
            </Grid>
            :
            <Grid item xs={12} md={3}>
               <div>
                   <LightTooltip title={t("The total number of Link Visited.")}>
                       <IconButton className="tooltip-btn-holder">
                           <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                       </IconButton>
                   </LightTooltip>
                   {linkVisits !== undefined ? <span><CountUp end={linkVisits} duration={1}/></span> :
                   <Skeleton style={{width: "90px"}}/> }
                   <h6>{t("Link Visits")}</h6>
               </div>
           </Grid>
            }
        </Grid>
    )
}
export default DashboardBoxes