import React, { useEffect, useState, useRef } from 'react';
import { Alert, Grow, IconButton } from "@mui/material";
import { useSelector } from 'react-redux';
import { IoMdInformationCircleOutline } from "react-icons/io";
import LightTooltip from '../../../helpers/lightTooltip';
import { Link } from 'react-router-dom';
import CustomAlertIcon from '../../../helpers/CustomAlertIcon';
import { useTranslation } from 'react-i18next';

const WidgetCode = ({ formData, activePlatform, activeRating, loc }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');
    const [iframeNoEdit, setIframeNoEdit] = useState("") // when we copy or preview widget we use this link for iframe because we should remove this param from url: &preview_editing=1
    const [notInclude, setNotInclude] = useState([])
    const iframeRef = useRef(null);

    const {t} = useTranslation()

    let timeId = '';
    const ratingQueryString = activeRating.map(r => `&sr[]=${r}`).join('');
    const paltformQueryString = activePlatform.map(r => {
        if (r === 'iRevU') {
          return `&p[]=Feedback`;
        } else if (r === 'Google Maps') {
          return `&p[]=Google%20Maps&p[]=Google`;
        } else {
          return `&p[]=${r}`;
        }
      }).join('');

      const location = useSelector(state => state.locationSelected.location);
      const selected = useSelector(state => state.groupLocationSelected.group_location)
    
      const notIncluded = notInclude.map(i => `&notInclude[]=${i}`).join('');


    const copyWidgetCode = () => {
        // if (iframeUrl) {
        //     navigator.clipboard.writeText(iframeNoEdit);
        //     setShowAlert(true);
        // }

        const iframe = iframeRef.current;
        const iframeTag = `<iframe src="${iframe.src}" style="width:100%;height:1000px;border:0;" frameborder="0" />`;
        navigator.clipboard.writeText(iframeTag)
            .then(() => {
                setShowAlert(true);
            })
            .catch((error) => {
                console.error('Failed to copy the iframe tag: ', error);
            });

        timeId = setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    useEffect(() => {
        const urlParamsEdit = `?a=${encodeURIComponent(loc)}&count=${encodeURIComponent(formData.limit)}&fontcol=${encodeURIComponent(formData.text_color)}&font=${encodeURIComponent(formData.text_font)}&bgcol=${encodeURIComponent(formData.background_color)}&incl_no_text=${encodeURIComponent(formData.without_text)}&multiple=${formData?.multiple_location}${ratingQueryString}${paltformQueryString}${notIncluded}`;
        const urlNoEdit = `https://irevu.com/${formData.display === 1 ? 'widget_reviews.php' : 'reviewsbottom.php'}` + urlParamsEdit;

        setIframeNoEdit(urlNoEdit)
        return () => {
            clearTimeout(timeId)
        }
    }, [formData, notInclude, loc]);

    useEffect(() => {
        const urlParams = `?a=${encodeURIComponent(loc)}&count=${encodeURIComponent(formData.limit)}&fontcol=${encodeURIComponent(formData.text_color)}&font=${encodeURIComponent(formData.text_font)}&bgcol=${encodeURIComponent(formData.background_color)}&incl_no_text=${encodeURIComponent(formData.without_text)}&multiple=${formData?.multiple_location}${ratingQueryString}${paltformQueryString}&preview_editing=1${notIncluded}`;
        // const url = `https://irevu.com/${formData.display === 1 ? 'widget_reviews.php' : 'reviewsbottom.php'}` + urlParams;
        const url = `https://irevu.com/${formData.display === 1 ? 'widget_reviews.php' : 'reviewsbottom.php'}` + urlParams;

        setIframeUrl(url);
        return () => {
            clearTimeout(timeId)
        }
    }, [formData,loc]);

    useEffect(() => {
        const handleMessage = event => {
            if (event.origin !== 'https://irevu.com') {
                return;
            }
            setNotInclude(event.data);
        };
        window.addEventListener("message", handleMessage); //listening from other project if -> event.origin !== 'http://irevu.com')
        return () => window.removeEventListener('message', handleMessage);
    }, [])

    return (
        <div className='widget_code_wrapper'>
            <div className="widget_code_header">
                <h4 className='title'>{t("Widget Code")}</h4>
            </div>
            <Link to={{
                pathname: '/app/admin/reviews/list',
                state: { selected: 'true' }
            }} style={{ textDecoration: 'none' }}>
                <div className='widet_code-desc-box'>
                    <div className="wrapper">
                        <h5>{t("Select Reviews to Not display")}</h5>
                        <div>
                            <LightTooltip
                                title={
                                    <>
                                        <div>
                                            {t("Selected reviews won’t appear on the widget")}!
                                        </div>
                                    </>
                                }
                            >
                                <IconButton>
                                    <IoMdInformationCircleOutline style={{ color: "#fff" }} />
                                </IconButton>
                            </LightTooltip>
                        </div>
                    </div>
                </div>
            </Link>
            <div className="widget_code_content">
                {loc ? 
                    <iframe src={iframeUrl} ref={iframeRef} style={{ width: '100%', height: '100%', border: '0' }} frameBorder="0"></iframe>
                : <h1 className='choose-loc-text'>Choose one or more locations to view the review list.</h1>}
            </div>

            <div className="widget_code_foot">
                <div className="d-flex align-items-center flex-wrap">
                    <button className='submit-btn copy_code_btn' onClick={copyWidgetCode}>{t("Copy Code")}</button>
                    <a href={iframeNoEdit} target="_blank" className='submit-btn'>{t("Preview Widget")}</a>
                </div>
            </div>
            <Grow in={showAlert}><Alert severity="success" className="fixed-alert-right" iconMapping={{ success: <CustomAlertIcon /> }}>Copied to Clipboard</Alert></Grow>
        </div>
    )
}

export default WidgetCode;
