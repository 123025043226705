export const getLocationSources = (state = [], action) => {
    switch(action.type){
        case 'GET_LOCATION_SOURCES':
            return{
                ...state,
                sources: action.payload,
                loading: false
            }
        case 'LOADING_TABLES':
            return{
                ...state,
                loading: true
            }
        default:
            return{
                ...state
            }
    }
}

export const getLocationAvaliableSources = (state = [], action) => {
    switch(action.type){
        case 'GET_LOCATION_AVALIABLE_SOURCES':
            return{
                ...state,
                sources: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}