import React, { useState } from "react"
import { Modal, Skeleton, Slide } from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import axios from 'axios';
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { useSelector, useDispatch } from "react-redux";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { showAlert } from "../../../../redux/actions/index";
import { Alert } from "@mui/material";
import { useEffect } from "react";

const EditMember = ({ open, setOpen, setMemberAdded, memberId }) => {
    const [t] = useTranslation()
    const [loading, setLoading] = useState(false)
    const [loadingAtributes, setLoadingAtributes] = useState(false)
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [role, setRole] = useState("")
    const [errors, setErrors] = useState([]);
    const location = useSelector(state => state.locationSelected.location)
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        setName('');
        setSurname('');
        setRole('');
    }

    useEffect(() => {
        if (memberId && open) {
            setLoadingAtributes(true);
            axios.get(`${baseUrlDev}locations/get-data/${memberId}`, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(res => {
                setName(res?.data?.data?.name);
                setSurname(res?.data?.data?.surname);
                setRole(res?.data?.data?.role);
                setLoadingAtributes(false);
            });
        }
    }, [memberId, open]);

    const handleSubmit = () => {
        setLoading(true);
        setMemberAdded(false);
        axios.post(`${baseUrlDev}locations/update-members/${memberId}`, { name, surname, role }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setLoading(false);
                setOpen(false);
                setMemberAdded(true);
                setName('');
                setSurname('');
                setRole('');
                dispatch(showAlert(true, "Gamify updated successfully."))
                setTimeout(() => {
                    dispatch(showAlert(false, ""))
                }, 5000)
            }).catch(err => {
                setLoading(false);
                Object.keys(err.response.data.errors).map(key => {
                    setErrors([...errors, err.response.data.errors[key][0]])
                })
            });
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-user-gamify-title"
            aria-describedby="modal-user-gamify-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className={`custom-modal-dialog`}>
                    {errors && errors.map(err => {
                        return (
                            <div className="alert-inside-modal" key={err}>
                                <Alert severity="error" className="custom-error-alert">{err}</Alert>
                            </div>
                        )
                    })}
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>{t("Edit Member")}</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-div-container custom-scrollbar-vertical">
                                <form>
                                    <div className="field-container">
                                        <label>{t("Name")}</label>
                                        {loadingAtributes ? <Skeleton style={{ height: "37px", transform: "unset" }} />
                                            : <input value={name} minLength={4} maxLength={35} type="text" placeholder={t("Add Name")} onChange={(e) => {
                                                setName(e.target.value)
                                            }} />
                                        }
                                    </div>
                                    <div className="field-container">
                                        <label>{t("Surname")}</label>
                                        {loadingAtributes ? <Skeleton style={{ height: "37px", transform: "unset" }} />
                                            : <input value={surname} minLength={4} maxLength={35} type="text" placeholder={t("Add Surname")} onChange={(e) => {
                                                setSurname(e.target.value)
                                            }} />
                                        }
                                    </div>
                                    <div className="field-container">
                                        <label>{t("Role")}</label>
                                        {loadingAtributes ? <Skeleton style={{ height: "37px", transform: "unset" }} />
                                            : <input value={role} minLength={4} maxLength={35} type="text" placeholder={t("Add Role")} onChange={(e) => {
                                                setRole(e.target.value)
                                            }} />
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <LoadingButton
                                style={{ paddingLeft: loading && "30px" }}
                                className="save-btn"
                                loading={loading}
                                onClick={() => handleSubmit()}
                            >
                                {t("Save")}
                            </LoadingButton>
                            <button type="submit" className="close-btn" onClick={() => setOpen(false)}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default EditMember