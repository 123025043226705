import React, {useEffect, useState} from "react"
import { Grid, Collapse } from "@mui/material"
import { useTranslation } from "react-i18next"

const ReviewCoupon = ({expanded, handleChangeAccordion, reviewPageFields, setReviewPageFields, review_page}) => {
    const {coupon, coupon_text, coupon_code} = reviewPageFields
    // const {coupon, coupon_text} = review_page
    const [couponDiscountCheck, setCouponDiscountCheck] = useState(false);
    const {t} = useTranslation()

    useEffect(() => {
        coupon == 0 ? setCouponDiscountCheck(false) : setCouponDiscountCheck(true)
    }, [coupon])

    const updateReviewFields = (e) => {
        const {value, name} = e.target
        setReviewPageFields({...reviewPageFields, [name]: value})
    }

    const updateReviewFieldsCheckbox = (e) => {
        const {checked, name} = e.target
        setReviewPageFields({...reviewPageFields, [name]: checked ? 1 : 0})
    }

    return(
        <>
            <div className="content-card-header"  onClick={() => handleChangeAccordion("panel3")}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className="d-flex align-items-center">
                        <div>
                            <img src={"/admin-images/tools/images/coupon-logo-small.svg"} alt="" />
                        </div>
                        <h3 className="title">{t("Coupon")}</h3>
                    </div>
                    <div>
                        <img src={"/admin-images/tools/small-down-arrow.svg"} className={`cursor-pointer arrow ${expanded?.find(e => e === 'panel3') ? 'rotate' : ''}`}  alt="" />
                    </div>
                </div>
            </div>
            {review_page && review_page.data && !review_page.data.location?.google_acc_loc ? 
            <div className="connect-google-account">
                {t("To activate Discount Coupon you should connect Google Account")}. <a target="_blank" href="https://irevu.com/gconnect.php">{t("Click Here to Connect")}</a>
            </div>
            :
            <Collapse in={expanded?.find(e => e === 'panel3') ? true : false}>
                <div id="reviews_coupon" className='collapse_card_review'>
                    <div className="content-card-review">
                        <Grid container spacing={3}>
                            <Grid item md={4} xs={12}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>{t("Coupon Text")}</label>
                                    <div className="input-icon-wrapper">
                                        <img src={"/admin-images/tools/images/coupon-dollar.svg"} alt="" />
                                        <input name="coupon_text" type="text" className="form-control" value={coupon_text} id="discount_text" placeholder="You will get 20% discount" onChange={(e) => updateReviewFields(e)}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>{t("Coupon Code")}</label>
                                    <div className="input-icon-wrapper">
                                        <input name="coupon_code" type="text" className="form-control" value={coupon_code} id="discount_text" placeholder="Add Coupon Code" onChange={(e) => updateReviewFields(e)}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <div className="form-group mb-0 coupon__wrap">
                                    <label className="custom-checkbox coupon-checkbox">
                                        {t("Enable Coupon Discount")}
                                        <input name="coupon" type="checkbox" className="coupon" checked={couponDiscountCheck} onChange={(e) => {
                                            setCouponDiscountCheck(!couponDiscountCheck)
                                            updateReviewFieldsCheckbox(e)
                                        }}/>
                                        <span className={`checkmark ${couponDiscountCheck ? 'show' : ''}`}></span>
                                    </label>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Collapse>
            }
        </>
    )
}
export default ReviewCoupon