import React from "react"
import OnlyLocationWrapper from "../../../parts/OnlyLocationWrapper"
import ReviewPageWrapper from "./ReviewPageWrapper"
import InnerDropdownContainer from "../../../parts/InnerDropdownContainer"

const ReviewPage = () => {
    return(
        <OnlyLocationWrapper>
            <InnerDropdownContainer/>
            <ReviewPageWrapper/>
        </OnlyLocationWrapper>
    )
}
export default ReviewPage