import React, {useEffect, useState} from 'react';
import MUIDataTable from "mui-datatables";
import { IconButton, Pagination } from '@mui/material';
import LightTooltip from '../../../helpers/lightTooltip';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from '../../../helpers/loader';
import { fetchLocationListing } from '../../../../../redux/actions/admin-index';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ListingsDatatable = ({listing}) => {
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const location = useSelector(state => state.locationSelected.location)
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    const {t} = useTranslation()
    const columns = [
        {
            name: t("Source Name"),
            options: {
                filter: true,
                viewColumns: true,
                display: false,
                sort: false,
            }
        },
        // { 
        //     name: "Source Name", 
        //     options: { 
        //         filter: true,
        //         sort: true,
        //         viewColumns: false,
        //         customBodyRender: (value, tableMeta, updateValue) => (
        //             <p className="custom-text-datatable">{value}</p>
        //         ),
        //     } 
        // },
        {
            name: t("Source Name"),
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Link to={`/app/admin/locations/listing/${tableMeta.rowData[0].replace(/\s/g, '')}`} className='view_btn_title'>
                        <div className='sources-container'>
                            <img src={value[1]} alt={value[1]} />
                            <p className='custom-text-datatable'>{value[0]}</p>
                        </div>
                    </Link>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        
        {
            name: t("Listing score"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className='custom-text-datatable'>{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Max Score"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Accurate"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Inaccurate"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Not Found"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                viewColumns: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="mydev-datatable-actions">
                            <Link to={`${tableMeta.rowData[0].replace(/\s/g, '')}`} className='view_btn'>
                                <LightTooltip
                                    title={
                                        <div>{t("view more")}</div>
                                    }
                                >
                                    <IconButton className="tooltip-btn-holder">
                                        <IoMdInformationCircleOutline style={{ opacity: "0.4", fontSize: "14px" }} />
                                    </IconButton>
                                </LightTooltip>
                            </Link>
                        </div>
                    )
                }
            }
        },
    ];
    
    const handleChange = (e, value) => {
        setPage(value)
        setLoading(true)
    
        if(selected && (!location || location == "all")){
            dispatch(fetchLocationListing(value, selected))
        }
        if (location && location !== "all") {
            dispatch(fetchLocationListing(value, location))
        }
    }

    useEffect(() => {
        setLoading(false)
    }, [listing])

    const options = {
        search: true,
        download: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        print: true,
        delete: false,
        viewColumns: true,
        pagination: false,
        filter: true,
        filterType: "checkbox",
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onRowsDelete: (rowsDeleted) => {
            const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex][0]); // array of all ids to to be deleted
            console.log(idsToDelete)
        }
    };

    const data = listing.data.listingScores.data.map(item => {
        return [item.name, [item.name, item.icon], item.listing_score, item.maximum_score, item.Accurate, item.Inaccurate, item.NotFound, ""]
    });

    return (
        <div className='new_datatable listing_datatable'>
            <div style={{position: "relative"}}>
                {loading && <Loader absoluteFlex={true}/>}
                <MUIDataTable
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
            <Pagination style={{marginTop: "20px"}} count={listing.data.listingScores.last_page} page={page} shape="rounded" onChange={handleChange}/>
        </div>
    )
}

export default ListingsDatatable;