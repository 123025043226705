import { styled, InputBase} from "@mui/material";

const MySelect = styled(InputBase)(({ theme }) => ({
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#fff",
        border: `1px solid`,
        borderColor: "#ced4da",
        fontSize: 16,
        padding: '8px 26px 8px 12px',
        color: "#25313C",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
    "& .MuiSelect-select[aria-expanded='true']": {
        borderColor: '#18AEE3'
    },
    '& .MuiSvgIcon-root' :{
        color: "#25313C"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    }
}));
export default MySelect