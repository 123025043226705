import React, {useEffect, useState, useRef}  from "react"
import { useSelector } from "react-redux"
import { LinearProgress } from "@mui/material"
import { useTranslation } from "react-i18next"

const DashboardProgress = () => {
    const [countDown, setCountDown] = useState(40)
    const intervalRef = useRef();
    const groupSelected = useSelector(state => state.groupSelected.group)
    const location = useSelector(state => state.locationSelected.location)
    const user = useSelector(state => state.getUserInfo.user)
    const { t } = useTranslation();
    
    let group_details = user && user.user.groups.filter(group => group.id == groupSelected)
  

    useEffect(() => {
        setCountDown(40)
     }, [location, groupSelected])

    useEffect(() => {
        intervalRef.current = setInterval(() => {
          if (countDown > 0) {
            setCountDown((prevCount) => prevCount - 1);
          }
        }, 1000);
    
        return () => clearInterval(intervalRef.current);
      }, [countDown]);

    return(
        <>
            {(location == "all" || (group_details && group_details[0]?.locations?.length > 20)) && <div className="loading-spinner-dashboard">
                <p>
                    {countDown > 0 ? `${t("Please wait...We're loading reviews for all of your locations. Cache update might take up to")} ${countDown} ${t("seconds")}.` : 
                    `${("Your reviews cache update is taking a little longer than expected. Please wait")}...`
                    }
                </p>
                <LinearProgress  sx={{
                        backgroundColor: 'rgb(201 28 37 / 52%)',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: 'rgb(201, 28, 37)'
                    }
                }}/>
            </div>}
        </>

    )
}
export default DashboardProgress