import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Skeleton, Alert, Grow, Tooltip, IconButton, Fade } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Select, FormControl, ListSubheader, TextField, MenuItem } from "@mui/material";
import { useSelector } from 'react-redux';
import { fetchGroupPage, clearGroupPage } from '../../../../../redux/actions/admin-index';
import GroupModal from './GroupModal';
import { IoCopyOutline } from "react-icons/io5"
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { baseUrlDev } from '../../../../Helpers/baseUrl';
import { getLocalUser } from '../../../../TokenControl/parts/useLocalUser';
import CreateGroupPage from './CreateGroupPage';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ExistingGroup from './ExistingGroups';
import { useParams } from 'react-router-dom';
import LocationsGroupPage from './locationsGroupPage';
import CustomAlertIcon from '../../../helpers/CustomAlertIcon';
import useHandleDisable from '../../../helpers/disableSelect';
import { useTranslation } from 'react-i18next';
import GroupLocations from './groupLocations';

const GroupDetails = () => {
    const [formData, setFormData] = useState({
        url: '',
        company_name: '',
        name: '',
        description: '',
        heading: 'How was your experience?',
        sub_heading: 'Your feedback is highly appreciated and responses help us to serve you better.',
        phone: '',
        website: '',
        email: '',
        logo: null,
        short_url: '',
        path: '',
    });
    const {t} = useTranslation()
    const [locationGroup, setLocationGroup] = useState([])

    const [columns, setColumns] = useState([]);
    const [hasGroupUrlChanged, setHasGroupUrlChanged] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertCopy, setShowAlertCopy] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [open, setOpen] = useState(false)
    const [openExisting, setOpenExisting] = useState(false)
    const { id } = useParams();
    const handleDisable = useHandleDisable()
    let timeAlertId = '';

    const dispatch = useDispatch();
    const user = useSelector(state => state.getUserInfo.user)
    const location = useSelector(state => state.locationSelected.location);
    const groups = useSelector(state => state.getGroups.groups)

    let location_details = user && user.user.locations.filter(loc => loc.id === location);

    const groupPage = useSelector(state => state.getGroupPage.groupPage);

    const copyReviewUrl = (url) => {
        navigator.clipboard.writeText(`https://irevu.com/company/${url}`);
        setShowAlertCopy(true)
        setTimeout(() => {
            setShowAlertCopy(false)
        }, 1000)
    }
    useEffect(() => {
        handleDisable()
    }, [])
    useEffect(() => {
        dispatch(clearGroupPage());
        if (id) {
            dispatch(fetchGroupPage(id));
        }

        return () => {
            clearTimeout(timeAlertId)
        }
    }, [id, dispatch, timeAlertId])

    useEffect(() => {
        if (groupPage && groupPage.data) {
            setFormData({
                url: groupPage.data?.group_details?.url,
                company_name: groupPage.data?.group_details?.company_name,
                name: groupPage.data?.name,
                description: groupPage.data?.description,
                heading: groupPage.data?.group_details?.heading,
                sub_heading: groupPage.data?.group_details?.sub_heading,
                phone: groupPage.data?.group_details?.phone,
                website: groupPage.data?.group_details?.website,
                email: groupPage.data?.group_details?.email,
                logo: groupPage.data?.group_details?.logo,
                short_url: groupPage.data?.group_details?.short_url,
                path: "",
            });
            setFile(groupPage.data?.group_details?.logo);
            setLocationGroup(groupPage?.data?.locations?.map(el => el.id));
        }
    }, [location, groupPage]);

    if (groupPage && !groupPage.status && location_details && location_details[0] && !location_details[0].is_owner) {
        return (
            <div className="flex-header-container-title"><h1>{groupPage.message}</h1></div>
        )
    } else if (groupPage && !groupPage.status && location_details && location_details[0] && location_details[0].is_owner) {
        return (
            <>
                {groups && groups.data && groups.data.length > 0 ?
                    <Grid container spacing={2}>
                        <CreateGroupPage open={open} setOpen={setOpen} />
                        <ExistingGroup open={openExisting} setOpen={setOpenExisting} />
                        <Grid item xs={12} md={6}>
                            <div onClick={() => setOpen(true)} className="add-group-wrapper">
                                <div><img src="/admin-icons/add-group.svg" alt="add group" /></div>
                                Create  Group
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div onClick={() => setOpenExisting(true)} className="add-group-wrapper">
                                <div><img src="/admin-icons/add-group.svg" alt="add group" /></div>
                                Use Existing Group
                            </div>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2}>
                        <CreateGroupPage open={open} setOpen={setOpen} />
                        <Grid item xs={12} md={7} style={{ margin: "auto" }}>
                            <div onClick={() => setOpen(true)} className="add-group-wrapper">
                                <div><img src="/admin-icons/add-group.svg" alt="add group" /></div>
                                Create  Group
                            </div>
                        </Grid>
                    </Grid>
                }
            </>

        )
    }

    const handleOnChange = (name, value) => {
        if (name === 'url') {
            setHasGroupUrlChanged(true);
        }
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    }

    const handleFileChange = (event) => {
        const fileLink = event.target.files[0];
        setFormData((prevFormData) => ({ ...prevFormData, logo: fileLink }));
        setFile(URL.createObjectURL(fileLink));
        setErrors({ ...errors, logo: '' });
    }

    const submitFormData = () => {
        const errors = {};

        if (!formData.url) {
            errors.groupUrl = t('Group Url is required');
        }

        if (!formData.company_name) {
            errors.companyName = t('Company Name is required');
        }

        if (!formData.heading) {
            errors.heading = t('Heading is required');
        }

        if (!formData.sub_heading) {
            errors.subHeading = t('Sub Heading is required');
        }

        if (!formData.phone) {
            errors.phone = t('Phone Number is required');
        }

        if (!formData.website) {
            errors.website = t('Website is required');
        }

        if (!formData.email) {
            errors.email = t('Email is required');
        } else if (!/\S+@\S+\.\S+/.test(formData?.email)) {
            errors.email = t("Email is invalid");
        }
        if (locationGroup.length < 1) {
            errors.locations = t('Minimum one location is required to edit group');
        }

        setErrors(errors);
        let postData = Object.assign(formData, {location_ids: columns?.allowed?.items.map(el => el.id), business_id: id})
        let formDataFormatted = new FormData();

        Object.keys(postData).map(key => {
            if(key === "location_ids"){
                postData[key].forEach((id, index) => {
                    formDataFormatted.append(`location_ids[${index}]`, id);
                })
            } else {
                formDataFormatted.append(key, postData[key]);
            }
		})

        if (Object.keys(errors).length === 0) {
            setLoading(true)
            axios.post(`${baseUrlDev}group/${id}/details/update`, formDataFormatted,
                { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
                .then(res => {
                    if (res.status === 200) {
                        setShowModal(false);
                        setShowAlert(true);
                        setLoading(false);
                        setAlertText(res.data.message);
                    }
                }).catch(err => {
                    setLoading(false);
                })

            timeAlertId = setTimeout(() => {
                setShowAlert(false);
            }, 5000);
        }
    }

    const handleSubmit = () => {
        submitFormData();
    }
    return (
        <>
            <div className='group-details-wrap'>
                <Grow in={showAlert}><Alert severity="success" className="fixed-alert-right" iconMapping={{ success: <CustomAlertIcon /> }}>{alertText}</Alert></Grow>
                {/* <GroupModal open={showModal} setOpen={setShowModal} formData={formData} handleOnChange={handleOnChange} handleSubmit={handleModalSubmit} loading={loading} /> */}
                {
                    groupPage === undefined ? <Skeleton style={{ height: "605px", width: "100%", transform: 'unset' }} /> : <form>
                        <div className='wrapper-shadow'>
                            <label htmlFor="" className='box-title'>{t("Group Details")}</label>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Short URL")} <span>{formData.url}</span></label>
                                        <div className='short_url_holder'>
                                            <div>
                                                <span> https://irevu.com/company/</span>
                                                <Tooltip title="Copy">
                                                    <IconButton className="copy-review-url" onClick={() => copyReviewUrl(formData.url)}>
                                                        <IoCopyOutline />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <input
                                                name="url"
                                                type="text"
                                                className='form-control'
                                                value={formData.url}
                                                onChange={(e) => handleOnChange('url', e.target.value)}
                                            />
                                            <Fade in={showAlertCopy}>
                                                <div style={{ position: "absolute", top: "-55px", right: 0 }}>
                                                    <Alert severity="success">{t("Copied")}!</Alert>
                                                </div>
                                            </Fade>
                                        </div>
                                        <span className='span-error'>{errors?.groupUrl}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Group Name")}</label>
                                        <input
                                            name="name"
                                            type="text"
                                            className='form-control'
                                            placeholder='Group Name'
                                            maxLength={70}
                                            value={formData.name}
                                            onChange={(e) => handleOnChange('name', e.target.value)}
                                        />
                                        <span className='span-error'>{errors?.name}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Group Description")}</label>
                                        <textarea
                                            name="description"
                                            type="text"
                                            className='form-control'
                                            placeholder='Group Description'
                                            maxLength={255}
                                            value={formData.description}
                                            onChange={(e) => handleOnChange('description', e.target.value)}
                                        />
                                        <span className='span-error'>{errors?.description}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Company Name")}</label>
                                        <input
                                            name="company_name"
                                            type="text"
                                            className='form-control'
                                            placeholder='Company Name'
                                            maxLength={70}
                                            value={formData.company_name}
                                            onChange={(e) => handleOnChange('company_name', e.target.value)}
                                        />
                                        <span className='span-error'>{errors?.companyName}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Heading")}</label>
                                        <input
                                            name="heading"
                                            type="text"
                                            className='form-control'
                                            placeholder={t("Heading")}
                                            maxLength={70}
                                            value={formData.heading}
                                            onChange={(e) => handleOnChange('heading', e.target.value)}
                                        />
                                        <span className='span-error'>{errors?.heading}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Sub-Heading")}</label>
                                        <input
                                            name="sub_heading"
                                            type="text"
                                            className='form-control'
                                            maxLength={70}
                                            placeholder={t("Sub-Heading")}
                                            value={formData.sub_heading}
                                            onChange={(e) => handleOnChange('sub_heading', e.target.value)}
                                        />
                                        <span className='span-error'>{errors?.subHeading}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group phone-group-wrap">
                                        <label htmlFor="">{t("Phone")}</label>
                                        {/* <input
                                            name="phone"
                                            type="number"
                                            className='form-control'
                                            placeholder='Phone'
                                            value={formData.phone}
                                            onChange={(e) => handleOnChange('phone', e.target.value)}
                                        /> */}
                                        <PhoneInput
                                            inputProps={{
                                                name: 'phone',
                                            }}
                                            enableSearch
                                            value={formData.phone}
                                            onChange={(e) => handleOnChange('phone', e)}
                                        />
                                        <span className='span-error'>{errors?.phone}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Website")}</label>
                                        <input
                                            name="website"
                                            type="text"
                                            className='form-control'
                                            placeholder={t("Website")}
                                            value={formData.website}
                                            onChange={(e) => handleOnChange('website', e.target.value)}
                                        />
                                        <span className='span-error'>{errors?.website}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Email")}</label>
                                        <input
                                            name="email"
                                            type="text"
                                            className='form-control'
                                            placeholder={t("Email")}
                                            value={formData.email}
                                            onChange={(e) => handleOnChange('email', e.target.value)}
                                        />
                                        <span className='span-error'>{errors?.email}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Logo")}</label>
                                        <div className="d-flex align-items-center flex-wrap gap-10">
                                            <div className="image-uploaded">
                                                <img src={file ? file : "/admin-images/tools/upload-image.svg"} id="imgLogoPreview" alt="" />
                                            </div>
                                            <div className="position-relative">
                                                <input type="file" id="logo-img" name="logo_image" onChange={handleFileChange} />
                                                <button className="btn upload-btn">{t("Upload Logo")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                    <div className="form-group">
                                        <label htmlFor="">{t("Locations")}</label>
                                        <LocationsGroupPage locationGroup={locationGroup} setLocationGroup={setLocationGroup} setErrors={setErrors} />
                                        <span className='span-error'>{errors?.locations}</span>
                                    </div>
                                </Grid> */}
                                 <Grid item xs={12}>
                                    <label style={{marginTop: "20px"}} className='box-title'>{t("Group Locations")}</label>
                                    <GroupLocations columns={columns} setColumns={setColumns} edit={true}/>
                                 </Grid>
                            </Grid>
                        </div>
                        <div>
                            <LoadingButton
                                style={{ paddingLeft: loading && "30px" }}
                                sx={{
                                    '& .MuiLoadingButton-loadingIndicator': {
                                        color: '#fff',
                                    }
                                }}
                                className="save-btn"
                                onClick={handleSubmit}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                        </div>
                    </form>
                }
            </div>
        </>
    )
}

export default GroupDetails
