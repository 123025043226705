import React, {useState, useEffect} from "react"
import { Alert} from "@mui/material"
import { fetchDashboardAutopilot, clearDashboardAutopilot, fetchUserInfo, showYoutubeContent } from "../../../../redux/actions/admin-index";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch} from "react-redux";
import TopBoxes from "./TopBoxes";
import BottomBoxes from "./BottomBoxes";
import { AutoPilotAdminSkeleton } from "../../helpers/autoPilotSkeleton";
import { useNavigate } from "react-router-dom";
import AutoPilotToggle from "./AutoPilotToggle";
import LatestResponses from "./LatestResponses";
import ReplyTime from "./ReplyTime";

const AutopilotAiWrapper = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    let [checked, setChecked] = useState(false)
    let [showWarning, setShowWarning] = useState(false)
    let [errors, setErrors] = useState([])
    let [skeleton, showSkeleton] = useState(true)

    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const user = useSelector(state => state.getUserInfo.user)
    const dashboard_autopilot = useSelector(state => state.autoPilot.autopilot_dashboard)
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    let location_details;
    if(selected && (!location || location == "all")){
        location_details = user && user.user.locations.filter(loc => loc.id == selected)
    }
    if (location && location !== "all") {
        location_details = user && user.user.locations.filter(loc => loc.id == location)
    }    
    const [t] = useTranslation()

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/mLXFNq7-mrE?autoplay=1&mute=0&rel=0'))
    }, [])
    
    useEffect(() => {
        showSkeleton(true)
        dispatch(clearDashboardAutopilot())
        dispatch(fetchUserInfo())
    }, [selected])

    useEffect(() => {
        showSkeleton(true)
        dispatch(clearDashboardAutopilot())
        dispatch(fetchUserInfo())
    }, [location])

    useEffect(() => {
        let location_details;
        if(selected && (!location || location == "all")){
            location_details = user && user.user.locations.filter(loc => loc.id == selected)
        }
        if (location && location !== "all") {
            location_details = user && user.user.locations.filter(loc => loc.id == location)
        }
        if(location_details){
            if((location_details[0]?.plan == 3) || (location_details[0]?.plan == 4 )){
                if(selected && (!location || location == "all")){
                    dispatch(fetchDashboardAutopilot(selected, groupSelected))
                }
                if (location && location !== "all") {
                    dispatch(fetchDashboardAutopilot(location, groupSelected))
                }
            }
            // else if(location_details && location_details[0]?.plan == 2){
            //     navigate("/app/admin")
            // }
            else if (location_details[0]?.plan != 3){
                setChecked(false)
                setShowWarning(false)
                setTimeout(() => {
                    showSkeleton(false)
                }, 1000)
            }
        }

    }, [user])

    useEffect(() => {
        if(dashboard_autopilot && (location_details[0]?.plan == 3 || location_details[0]?.plan == 4)){
            showSkeleton(false)
            if(dashboard_autopilot.data.toggle_ai_response){
                setChecked(true) 
                setShowWarning(false)
            } else {
                setChecked(false)
                setShowWarning(true)
            }
        } 
    }, [dashboard_autopilot])

    if(skeleton || ((location_details && (location_details[0]?.plan == 3 || location_details[0]?.plan == 4 )) && dashboard_autopilot === undefined)){
        return(
            <AutoPilotAdminSkeleton/>
        )
    }

    return(
        <div>
            <div className="alert-inside-page">
                {errors && errors.map(err => {
                    return(
                        <Alert key={err} severity="error" className="custom-error-alert">{err}</Alert>
                    )
                })}
            </div>
            <AutoPilotToggle checked={checked} setChecked={setChecked} from="details"/>
            {dashboard_autopilot && 
                <>
                    <TopBoxes reviews={dashboard_autopilot.data.reviews} rating={dashboard_autopilot.data.rating} responseCount={dashboard_autopilot.data.aiResponsesCount}/>
                    <BottomBoxes breakdown={dashboard_autopilot.data.ratingBreakdown} breakdown_p={dashboard_autopilot.data.ratingPercentage} latest_responses={dashboard_autopilot.data.latestReview} analytics={dashboard_autopilot.data.analytics}/>
                    <LatestResponses latest_responses={dashboard_autopilot.data.latestReview}/>
                    <ReplyTime data={dashboard_autopilot.data}/>
                </>
             }
        </div>
    )
}
export default AutopilotAiWrapper