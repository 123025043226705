import React, {Suspense} from "react"
import { IconButton, Skeleton} from "@mui/material"
import LightTooltip from "../../helpers/lightTooltip"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { useTranslation } from "react-i18next"

const Pie = React.lazy(() => import("./analytics_pie"))

const Analytics = ({analytics}) => {
    const [t] = useTranslation()
    return(
        <div className="dashboard-box-containers">
            <LightTooltip title={
                <>
                    <div>
                        1 & 2 {t("Stars")} = {t("negative")} <br/>
                        3 {t("Stars")} = {t("neutral")} <br/>
                        4 & 5 {t("Stars")} = {t("positive")}
                    </div>
                </>
            }
            >
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                </IconButton>
            </LightTooltip>
            <h2>{t("Review Sentiment")}</h2>
            <Suspense fallback={
                <div>
                    <Skeleton style={{transform: "unset", width: "200px", height: "200px", borderRadius: "100%", margin: "auto"}}/>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: "10px"}}>
                        <Skeleton style={{width: "100px", marginRight:"10px"}}/>
                        <Skeleton style={{width: "100px", marginRight:"10px"}}/>
                        <Skeleton style={{width: "100px"}}/>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", margin: "10px"}}>
                        <Skeleton style={{width: "100px"}}/>
                        <Skeleton style={{width: "10px"}}/>
                        <Skeleton style={{width: "100px"}}/>
                    </div>
                </div>
            }>
                <Pie analytics={analytics}/>
                <div style={{marginTop: "15px"}}>
                    <div className="analytics-infos-positive">
                        <span>{analytics.positive}</span> {t("positive")} {t("Reviews")}
                    </div>
                    <div className="analytics-infos-total" style={{textTransform: "lowercase"}}>
                        of
                    </div>
                    <div className="analytics-infos-total">
                        <span>{analytics.negative + analytics.positive + analytics.needs_work}</span> {t("Total")} {t("Reviews")}
                    </div>
                </div>
            </Suspense>
        </div>
    )
}
export default Analytics