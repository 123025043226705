import React from "react";
import { Dialog, DialogTitle } from "@mui/material";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../Helpers/baseUrl";
import { fetchForms } from "../../../redux/actions";
import {getHeaderLocalUser} from "../../TokenControl/parts/useLocalUser";

const DeleteFormDialog = ({open, setOpen, form_id}) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        setOpen(false)
    }
    const deleteForm = () => {
        axios.delete(`${baseUrl}forms/form/${form_id}`,{
            headers: {...getHeaderLocalUser()}
        }).then(res => {
            dispatch(fetchForms())
            setOpen(false)
        })
    }
    return(
        <Dialog onClose={handleClose} open={open}>
            <div style={{padding: "20px"}}>
                <DialogTitle>Are you sure you want to delete this form?</DialogTitle>
            </div>
            <div className="flex-btns">
                <button onClick={() => deleteForm()}>Delete</button>
                <button onClick={() => handleClose()}>Cancel</button>
            </div>
        </Dialog>
    )
}

export default DeleteFormDialog