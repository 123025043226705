export function calculateCountdown(targetDate) {
    const targetTime = new Date(targetDate).getTime();
    const now = new Date().getTime();
    
    if (isNaN(targetTime)) {
      throw new Error('Invalid date format. Please provide a valid date string.');
    }
  
    if (targetTime < now) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        completed: true
      };
    }
  
    const timeLeft = targetTime - now;
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  
    return {
      days,
      hours,
      minutes,
      completed: false
    };
  }