import React from 'react';
import { useTranslation } from 'react-i18next';

const ProgressBox = ({listing}) => {
    let accurate = listing.data.accuracy.accuratePercentage.replace(/\s/g, '')
    let inaaccurate = listing.data.accuracy.inaccuratePercentage.replace(/\s/g, '')
    let notfound = `${100 - (parseFloat(accurate) + parseFloat(inaaccurate))}%`
    const {t}  = useTranslation()
    return (
        <div className='listing_score_box2 progress_box'>
            <div className='bar-three-values'>
                <div style={{width: accurate}} className="bar bar-colorPrimary"></div>
                <div style={{width: inaaccurate}} className="bar bar-colorSecondary"></div>
                <div style={{width: notfound}} className="bar bar-colorThird"></div>
            </div>
            <div className="wrapper">
                <div>
                    <h6 className="text_success">
                        {listing.data.accuracy.accurate && listing.data.accuracy.accurate}
                        <span>({listing.data.accuracy.accuratePercentage && listing.data.accuracy.accuratePercentage})</span>
                    </h6>
                    <span className='small_text'>{t("Accurate")}</span>
                </div>
                <div>
                    <h6 className="text_warning">
                        {listing.data.accuracy.inaccurate && listing.data.accuracy.inaccurate}
                        <span>({listing.data.accuracy.inaccuratePercentage && listing.data.accuracy.inaccuratePercentage})</span>
                    </h6>
                    <span className='small_text'>{t("Inaccurate")}</span>
                </div>
            </div>
        </div>
    )
}

export default ProgressBox;