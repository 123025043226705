import React, {useState, useMemo, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
import { ClickAwayListener, FormControl, Collapse } from "@mui/material"
import { useTranslation } from "react-i18next"
import { getGroupLocation } from "../../../redux/actions/admin-index"

const containsTextGroups = (group, search) => {
    const groupNameMatches = group.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
  
    if (groupNameMatches) {
      return {...group};
    }
  
    const matchingLocations = group.locations.filter(location =>
      Object.values(location).some(value =>
        typeof value === 'string' && value.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    );
  
    return matchingLocations.length > 0 ? { ...group, locations: matchingLocations } : null;
};
const AllGroupLocations = ({disabled}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [searchGroups, setSearchGroups] = useState("");
    const [locationsOfGroup, setLocationsOfGroup] = useState()

    const user = useSelector(state => state.getUserInfo.user)
    let groupsArray = user && user.user.groups
    const groupSelected = useSelector(state => state.groupSelected.group)   
    const location = useSelector(state => state.locationSelected.location) 
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    useEffect(() => {
        if(groupsArray){
            setLocationsOfGroup(groupsArray)
        }
    }, [groupSelected, user])

    const {t} = useTranslation()
    const displayedGroup = useMemo(() => groupsArray && groupsArray.map(group => containsTextGroups(group, searchGroups)).filter(Boolean), [searchGroups, user]);


    // useEffect(() => {
    //     if(!selected && displayedGroup){
    //         dispatch(getGroupLocation(displayedGroup[0]?.locations[0].id))
    //     }
    // }, [locationsOfGroup, groupSelected])


    useEffect(() => {
        if(!localStorage.getItem("location_of_group") && displayedGroup){
            dispatch(getGroupLocation(displayedGroup[0]?.locations[0].id))
            localStorage.setItem("location_of_group", displayedGroup[0]?.locations[0].id)
        }
        if(localStorage.getItem("location_of_group")){
            dispatch(getGroupLocation(localStorage.getItem("location_of_group")))
        }
    }, [locationsOfGroup])

    const onLocationSelect = (id) => {
        dispatch(getGroupLocation(id))
        localStorage.setItem("location_of_group", id)
        setShow(false)
    }
   if(displayedGroup){
        return(
            <div style={{marginBottom: "35px"}} className={disabled ? "disable-inner-dropdown" : ""}>
                <h6 style={{fontFamily: "Poppins-Regular", fontSize: "16px", marginBottom :"10px", marginTop: "0px"}}>Select Location</h6>
                <ClickAwayListener onClickAway={() => setShow(false)}>
                    <div style={{position: "relative"}}>
                        <FormControl className="select-search-loc">
                            <div onClick={() => setShow(!show)} className='select-search-header' style={{borderColor: show ? "#18AEE3" : "rgba(37, 49, 60, 0.15)"}}>
                                {groupsArray.flatMap(group => group.locations).filter(loc => loc.id == selected)[0] ? 
                                groupsArray.flatMap(group => group.locations).filter(loc => loc.id == selected)[0].full_name : " "}
                            </div>
                        </FormControl>
                        <Collapse in={show}>
                            <div className='loc-group-container-wrapper'>
                                <div className="loc-group-tabs"></div>
                                <input type="text" placeholder={t("Search Location")} className='search-inside-dropdown' 
                                    onChange={(e) => setSearchGroups(e.target.value)}
                                    onKeyDown={(e) => {
                                        if(e.key !== 'Escape'){
                                            e.stopPropagation();
                                        }
                                    }}
                                />
                                <div className='loc-group-container custom-scrollbar-vertical'>
                                    {displayedGroup && displayedGroup.map(group => (
                                        <div key={`${group.id}-allgroupcontainer`}>
                                            <span style={{pointerEvents: "none"}} className="group-name-span">{t("Group")} - {group.name}</span>
                                            {
                                                    group.locations.map(loc => (
                                                    <div key={`${loc.id}-allgroup`} className="left-loc-icon element-container">
                                                        <input type="radio" name="location_of_group" value={loc.id} id={`locOfGr-${loc.id}`} checked={loc.id == selected} onChange={() => {
                                                            onLocationSelect(loc.id)
                                                        }}/>
                                                        <label htmlFor={`locOfGr-${loc.id}`}>
                                                            {loc.full_name}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))} 
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </ClickAwayListener>
            </div>
        )
   }
}
export default AllGroupLocations