import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { createTheme, ThemeProvider, Dialog, DialogTitle  } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchLocationSources, loadingTables } from "../../../redux/actions/admin-index";
import AlertComponent from "../../Helpers/Alert";
import { getLocalUser } from "../../TokenControl/parts/useLocalUser";
import axios from "axios";
import { baseUrlDev } from "../../Helpers/baseUrl";
import { showAlert } from "../../../redux/actions";
import AddSource from "../modals/sources/add-source";
import Loader from "../helpers/loader";
import { showYoutubeContent } from "../../../redux/actions/admin-index";
import Header from "../../Helpers/header";
import { BiExport } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const SourcesTable = ({open, setOpen}) => {
    const dispatch = useDispatch()
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [sourceId, setSourceId] = useState()
    const [deleteLogging, setDeleteLogging] = useState(false)
    const [t] = useTranslation()
    const location = useSelector(state => state.locationSelected.location)
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/cgxCjiUwhw4?autoplay=1&mute=0&rel=0'))
    }, [])
    
    useEffect(() => {
        dispatch(loadingTables())
        if(selected && (!location || location == "all")){
            dispatch(fetchLocationSources(selected))
        }
    }, [selected])

    useEffect(() => {
        dispatch(loadingTables())
        if (location && location !== "all") {
            dispatch(fetchLocationSources(location))
        }
    }, [location])


    const sources = useSelector(state => state.getLocationSources.sources)
    const loading = useSelector(state => state.getLocationSources.loading)

    if(sources === undefined){
        return(
            <Loader absoluteFlex={true}/>
        )
    }

    const getMuiTheme = () =>
        createTheme({
        components: {
        }
    });

    const deleteSource = () => {
        if(selected && (!location || location == "all")){
            apiRequestDelete(selected)
        }
        if (location && location !== "all") {
            apiRequestDelete(location)
        }
    }

    const apiRequestDelete = (id) => {
        setDeleteLogging(true)
        axios.post(`${baseUrlDev}locations/${id}/sources/${sourceId}/remove`, {}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            setDeleteLogging(false)
            setOpenConfirmation(false)
            dispatch(loadingTables())
            dispatch(fetchLocationSources(id))
            dispatch(showAlert(true, "Source Deleted Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        })
    }

    const columns = [
        { 
            name: "Id", 
            options: {
                filter: false,
                viewColumns: false,
                display: false,
                sort: false,
                disableExport: true
            }
        },
        // { 
        //     name: t("Status"), 
        //     options: { 
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (value, tableMeta, updateValue) => (
        //             <p className="custom-text-datatable">{value}</p>
        //         ),
        //         customHeadLabelRender: (columnMeta) => {
        //             return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
        //         }
        //     } 
        // },
        { 
            name: t("Review Sources"), 
            options: { 
                filter: true,
                sort: true,
                viewColumns: false,
                display: false,
            } 
        },
        { 
            name: t("Review Sources"), 
            options: { 
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className="sources-container">
                        <img src={value[1]} alt={value[0]}/>
                        <p className="custom-text-datatable">{value[0]}</p>
                    </div>
                ),
                customHeadLabelRender: (columnMeta) => {
                   return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Name"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Address"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Phone"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Source"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <a href={value} style={{textDecoration: "none"}} target="_blank"><p className="custom-text-datatable" style={{maxWidth: "300px", whiteSpace: "normal"}}>{value}</p></a>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: "", 
            options: {
                filter: false,
                viewColumns: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="mydev-datatable-actions">
                            {tableMeta.rowData[7] ? 
                            <button className="remove-lead" onClick={() => {
                                    setSourceId(tableMeta.rowData[7]) 
                                    setOpenConfirmation(true)
                                }}>
                                <span className="icon-rounded-trash"></span>
                                Delete
                            </button>
                            : "Processing..."}
                        </div>
                    )
                }
            }
        },
    ];

    const data = sources.data.map(source => {
        return [source.source.id, source.source.name, [source.source.name, source.source.logo], source.name, source.address, source.phone, source.url, source.listingId]
    }).reverse()

    const options = {
        search: true,
        download: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        print: true,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: "checkbox",     
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        downloadOptions: {
            filterOptions: {
              useDisplayedColumnsOnly: true,
              useDisplayedRowsOnly: true
            }
        },
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onRowsDelete: (rowsDeleted) => {
           const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex][5]); // array of all ids to to be deleted
           console.log(idsToDelete)
        }
    };

    const exportTable = () => {
        
    }


    return(
        <>
            <Dialog onClose={() => setOpenConfirmation(false)} open={openConfirmation}>
                <div style={{padding: "20px"}}>
                    <DialogTitle>{t("Are you sure you want to delete this source")}?</DialogTitle>
                </div>
                <div className="flex-btns">
                    <LoadingButton
                        style={{paddingLeft: loading && "30px"}}
                        className="delete-btn"
                        onClick={() => deleteSource()}
                        loadingIndicator={t("Deleting") + "..."}
                        loading={deleteLogging}
                    >
                        {!deleteLogging && t("Delete")}
                    </LoadingButton>
                    <button onClick={() => {
                        setOpenConfirmation(false)
                        setDeleteLogging(false)
                    }}>{t("Cancel")}</button>
                </div>
            </Dialog>
            <AlertComponent/>
            <AddSource open={open} setOpen={setOpen} location={location}/>
            <div className="new-datatable">
                {loading && <Loader/>}
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
        </>
      )
}
export default SourcesTable