import React, {useState, useEffect} from "react"
import { SketchPicker } from 'react-color';
import {Fade, ClickAwayListener} from "@mui/material"

const Colorpicker = ({setColor, color}) => {
    const [show, setShow] = useState(false)
    const [background, setBackground] = useState()

    useEffect(() => {
        if(color){
            setBackground(color)
        } else {
            setBackground("#fff")
        }
    }, [color])

    const handleChangeComplete = (c) => {
        setBackground(c.hex)
        setColor(c.hex)
    };

    return(
        <ClickAwayListener onClickAway={() => setShow(false)}>
            <div> 
                <div className="colorpicker-trigger">
                    <div style={{backgroundColor: background}} onClick={() => setShow(!show)}></div>
                    <input className="colorpicker-trigger-input" style={{width: `${color && color.length + 5 + 'ch'}`}} value={color} onChange={(e) => handleChangeComplete({
                        hex: e.target.value
                    })}/>
                </div>
                <Fade in={show} style={{height: show ? "auto" : 0}}>
                    <div>
                        <SketchPicker
                            className="custom-sketchpicker"
                            color={background}
                            onChangeComplete={handleChangeComplete}
                        />
                    </div>
                </Fade>
            </div>
        </ClickAwayListener>
    )
}
export default Colorpicker