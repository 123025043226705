import React, {useState} from "react"
import OnlyLocationWrapper from "../../parts/OnlyLocationWrapper"
import AutopilotAiWrapper from "./AutopilotAiWrapper"
import InnerDropdownContainer from "../../parts/InnerDropdownContainer"
import { useTranslation } from "react-i18next"
import Header from "../../../Helpers/header"

const AutopilotAi = () => {
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()

    return(
        <OnlyLocationWrapper>
            <InnerDropdownContainer/>
            <AutopilotAiWrapper open={open} setOpen={setOpen}/>
        </OnlyLocationWrapper>
    )
}
export default AutopilotAi