import React, {useState} from "react"
import OnlyLocationWrapper from "../../../parts/OnlyLocationWrapper"
import InnerDropdownContainer from "../../../parts/InnerDropdownContainer"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import PageVisitsHeader from "./pageVisitsHeader"
import PageVisitsWrapper from "./PageVisitsWrapper"

const Tags = () => {
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const page_visits = useSelector(state => state.PageVisits.page_visits)

    return(
        <OnlyLocationWrapper>
            <PageVisitsHeader setFrom={setFrom} setTo={setTo}  to={to} from={from}/>
            <InnerDropdownContainer/>
            <PageVisitsWrapper from={from} to={to}/>
        </OnlyLocationWrapper>
    )
}
export default Tags