import { useEffect, useState, useRef } from 'react';
import { websocketUrl } from "./Helpers/baseUrl"
import { getLocalUser } from "./TokenControl/parts/useLocalUser"

export function useWebSocket() {
	const [ws, setWs] = useState(null);
	const [shouldReconnect, setShouldReconnect] = useState(true);
	const isMountedRef = useRef(true);

	function connect() {
		const token = getLocalUser().token;
		const connection = new WebSocket(websocketUrl, token);

		connection.onopen = () => {
			if (isMountedRef.current) {
				setShouldReconnect(true);
			}
		};

		connection.onerror = (error) => {
			console.error('WebSocket error:', error);
		};

		connection.onclose = () => {
			if (shouldReconnect && isMountedRef.current) {
				connect();
			}
		};

		setWs(connection);
	}

	useEffect(() => {
		connect();

		return () => {
			isMountedRef.current = false;
			setShouldReconnect(false);

			if (ws) {
				ws.close();
			}
		};
	}, [websocketUrl]);

	return ws;
}