import React, {useState, useEffect} from 'react';
import { Grid, Select, FormControl, InputLabel, MenuItem, FormControlLabel, Typography} from '@mui/material';
import BusinessCategoriesSelect from './BusinessCategoriesSelect';
import { AntSwitch2 } from '../../../../Helpers/AntSwitch2';
import TimeSelect from '../../../../Helpers/timeSelect';
import { hours } from '../../../../Helpers/hours';
import { useTranslation } from 'react-i18next';

const BusinessInformation = ({ formData, setFormData, handleInputChange, location }) => {
    const [elements, setElements] = useState([])
    const [t] = useTranslation()
    const selectElement = (item) => {
        if(!elements.includes(item)){
            let newArr = [...elements, item]
            setElements(newArr)
            setFormData(prevObj => ({ ...prevObj, listing_categories: newArr}));
        }
    }

    const removeElement = (item) => {
        setElements(elements.filter(el => el !== item))
        setFormData(prevObj => ({ ...prevObj, listing_categories: elements.filter(el => el !== item)}));
    }

    useEffect(() => {
        if(formData){
            setElements(formData.listing_categories)
        }
    }, [formData])


    useEffect(() => {
        if(formData){
            setElements(formData.listing_categories)
        }
    }, [formData])

    const handleHoursActive = (e, day) => {
       let checked = e.target.checked
       let workingHoursItems = [...formData.working_hours]
       let itemChanged = workingHoursItems.find(el => el.day == day)
       itemChanged.not_working = checked ? 0 : 1
       setFormData(prevObj => ({...prevObj, working_hours: workingHoursItems}))
    }

    const handleHoursOperationFrom = (e, day) => {
        let value = e.target.value
        let workingHoursItems = [...formData.working_hours]
        let itemChanged = workingHoursItems.find(el => el.day == day)
        itemChanged.from = value
        itemChanged.full_day = 0
        setFormData(prevObj => ({...prevObj, working_hours: workingHoursItems}))
    }

    const handleHoursOperationTo = (e, day) => {
        let value = e.target.value
        let workingHoursItems = [...formData.working_hours]
        let itemChanged = workingHoursItems.find(el => el.day == day)
        itemChanged.to = value
        itemChanged.full_day = 0
        setFormData(prevObj => ({...prevObj, working_hours: workingHoursItems}))
    }

    const handleHoursOperation24 = (e, day) => {
        let workingHoursItems = [...formData.working_hours]
        let itemChanged = workingHoursItems.find(el => el.day == day)
        if(itemChanged.full_day == 1){
            itemChanged.full_day = 0
            itemChanged.to = "00:00"
            itemChanged.from = "00:00"
        } else {
            itemChanged.full_day = 1
            itemChanged.to = ""
            itemChanged.from = ""
        }
        setFormData(prevObj => ({...prevObj, working_hours: workingHoursItems}))
    }

    return (
        <div>
            <div className='business-information location_settings_card'>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div>
                            <h5 className='title'>{t("Business Information")}</h5>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Company Name")}</label>
                            <input
                                name="companyName"
                                type="text"
                                placeholder=''
                                value={formData?.companyName}
                                onChange={(e) => handleInputChange('companyName', e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Common Business Name")}</label>
                            <input
                                name="common_business_name"
                                type="text"
                                placeholder=''
                                value={formData?.common_business_name}
                                onChange={(e) => handleInputChange('common_business_name', e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Listings Business Categories")}</label>
                            <BusinessCategoriesSelect location={location} categories={location && location.data.listing_business_categories}  elements={elements} removeElement={removeElement} selectElement={selectElement}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Number of Employees")}</label>
                            <input
                                name="employees_number"
                                type="number"
                                placeholder=''
                                value={formData?.employees_number}
                                onChange={(e) => handleInputChange('employees_number', e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Year of Formation")}</label>
                            <input
                                name="year_of_formation"
                                type="text"
                                placeholder=''
                                value={formData?.year_of_formation}
                                onChange={(e) => handleInputChange('year_of_formation', e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {/* <div className='form-group'>
                            <label htmlFor="">Hours of Operation</label>
                            <input
                                name="hours"
                                type="number"
                                placeholder=''
                                value={formData?.hours}
                                onChange={(e) => handleInputChange('hours', e.target.value)}
                            />
                        </div> */}
                        <div className='form-group'>
                            <label htmlFor="">{t("Hours of Operation")}</label>
                        </div>
                        <div className='hours-of-operation-wrapper'>
                            {formData.working_hours.map((el, i) => {
                                return(
                                    <div key={`${el.day_name} - ${el.day}`}>
                                        <h6>{t(el.day_name)}</h6>
                                        <FormControlLabel
                                            className='hours-small-label'
                                            control={<AntSwitch2 checked={el.not_working == 1 ? false : true} onChange={(e) => handleHoursActive(e, el.day)}/>}
                                            label={el.not_working == 1 ? t("Closed") : t("Open")}
                                        />
                                        {el.not_working != 1 && 
                                            <>
                                            <FormControl>
                                                <InputLabel id="demo-simple-select-label">{t("From")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={el.from}
                                                    input={<TimeSelect/>}
                                                    label={t("From")}
                                                    onChange={(e) => handleHoursOperationFrom(e, el.day)}
                                                    MenuProps={{autoFocus: false, PaperProps: { sx: { maxHeight: 300 }}  }}
                                                >
                                                {hours.map(hour => {
                                                    return(
                                                        <MenuItem key={`time-${hour.value}`} value={hour.value}>{hour.display}</MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel id="demo-simple-select-label">{t("To")}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={el.to}
                                                    input={<TimeSelect/>}
                                                    label={t("To")}
                                                    onChange={(e) => handleHoursOperationTo(e, el.day)}
                                                    MenuProps={{autoFocus: false, PaperProps: { sx: { maxHeight: 300 }}  }}
                                                >
                                                {hours.map(hour => {
                                                    return(
                                                        <MenuItem key={`date-${hour.value}`} disabled={new Date(`2000-01-01T${el.from}:00`) >= new Date(`2000-01-01T${hour.value}:00`) ? true : false} value={hour.value}>{hour.display}</MenuItem>
                                                    )
                                                })}
                                                </Select>
                                            </FormControl>
                                            <FormControlLabel
                                                style={{marginLeft: "20px"}}
                                                className='hours-small-label'
                                                control={<AntSwitch2 checked={el.full_day == 1 ? true : false} onChange={(e) => handleHoursOperation24(e, el.day)}/>}
                                                label={
                                                    <Typography variant="body1" style={{ fontFamily: 'Poppins-Regular', color: '#0c2640', fontSize: "10px", whiteSpace: "nowrap" }}>
                                                        24 {t("hours")}
                                                    </Typography>
                                                }
                                            />
                                            </>
                                        }
                                    </div>
                                )
                            })}
                       
                        </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">Payment Methods</label>
                            <input
                                name="paymentMethods"
                                type="text"
                                placeholder=''
                                value={formData?.paymentMethods}
                                onChange={(e) => handleInputChange('paymentMethods', e.target.value)}
                            />
                        </div>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Landmark")}</label>
                            <input
                                name="landmark"
                                type="text"
                                placeholder=''
                                value={formData?.landmark}
                                onChange={(e) => handleInputChange('landmark', e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default BusinessInformation;