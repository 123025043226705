import React from "react";
import Box from '@mui/material/Box';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";

const SetupSuggestionsChart = ({ completed }) => {
    const [t] = useTranslation()
    return (
        <div className="position-relative">
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: '#ECECEC'
                    }}
                    size={180}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    value={parseInt(completed)}
                    sx={{
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                        color: '#01ba98'
                    }}
                    size={180}
                    thickness={4}
                >
                </CircularProgress>
                <div className="label_text">
                    <div className="total_text">{t("Total")}</div>
                    <div className="value_number">{completed}%</div>
                </div>
            </Box> 
        </div>

    )
}
export default SetupSuggestionsChart