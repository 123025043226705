import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWidgetCode, fetchWidgetCodeLoading, showYoutubeContent } from '../../../../../redux/actions/admin-index';
import Colorpicker from '../../../modals/tags/Colorpicker';
import { Grid, FormControl, MenuItem, Select, Skeleton, Alert, Grow, FormGroup, FormControlLabel, ListSubheader, TextField } from '@mui/material';
import MySelect from '../../../helpers/mySelect';
import StarRating from './StarRating';
import Platforms from './Platforms';
import { useTranslation } from 'react-i18next';

import './style.css';
import './onofswitch.css';
import WidgetCode from './WidgetCode';
import { baseUrlDev } from '../../../../Helpers/baseUrl';
import axios from 'axios';
import { getLocalUser } from '../../../../TokenControl/parts/useLocalUser';
import Header from '../../../../Helpers/header';
import { AntSwitch } from '../../../../Helpers/AntSwitch';
import { LoadingButton } from '@mui/lab';
import CustomAlertIcon from '../../../helpers/CustomAlertIcon';

const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const RevuShareWrapper = () => {
    const dispatch = useDispatch();
    const [displayWidget, setDisplayWidget] = useState('1'); //1 - Display Table, 2 - Display Bottom
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [locationArray, setLocationArray] = useState([])
    const [activeRating, setActiveRating] = useState(['5']);
    const [errors, setErrors] = useState([]);
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false);
    const [activePlatform, setActivePlatform] = useState(['Google Maps', 'Facebook', 'Yelp', 'iRevU', 'OptSpot']);
    const [loc, setLoc] = useState()

    const {t} = useTranslation()
    const [formData, setFormData] = useState({
        display: 1,
        background_color: '#fff',
        text_color: '#000',
        text_font: 'Poppins',
        platform: [],
        rating: [],
        without_text: 1,
        multiple_location: 0,
        // without_text: false,
        // multiple_location: null,
        limit: 30
    });

    const location = useSelector(state => state.locationSelected.location);
    const user = useSelector(state => state.getUserInfo.user);
    const { widget } = useSelector(state => state.widgetCodeShareReducer);
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    let locations = user && user.user.locations;

    let timeAlertId = '';

    const displayedLocation = useMemo(() => locations  && locations.filter(loc => containsText(loc.full_name, search)), [search, user]);

    const handleActiveButton = (button, setActiveButton) => {
        // Create a copy of the current formData state
        const updatedFormData = { ...formData };

        // Update the active button in the copied state
        updatedFormData.display = button;

        // Update the formData state with the new values
        setFormData(updatedFormData);

        // Set the active button state
        setActiveButton(button);
    }

    const handleCheckBoxChange = (property, value) => {
        const updatedFormData = { ...formData };
        // Update the property value in the updatedFormData object
        updatedFormData[property] = value ? 1 : 0;
        if(property === 'without_text') {
            updatedFormData[property] = value ? 0 : 1;
        }
        // Update the formData state with the new values
        setFormData(updatedFormData);
    };


    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const toggleActivePlatform = (platform) => {
        if (activePlatform.includes(platform)) {
            setActivePlatform(activePlatform.filter((p) => p !== platform));
            setFormData({ ...formData, platform: activePlatform.filter((r) => r !== platform) });
        } else {
            setActivePlatform([...activePlatform, platform]);
            setFormData({ ...formData, platform });
        }
    };

    const toggleActiveRating = (rating) => {
        if (activeRating.includes(rating)) {
            setActiveRating(activeRating.filter((r) => r !== rating));
            setFormData({ ...formData, rating: activeRating.filter((r) => r !== rating) });
        } else {
            setActiveRating([...activeRating, rating]);
            setFormData({ ...formData, rating });
        }
    };

    const handleSubmitFunc = (id, e) => {
        e.preventDefault();
        let allData = Object.assign(formData, { location: id });
        let platformString = activePlatform;
        let ratingString = activeRating.join(',');

        if(locationArray.length == 0){
            setErrors(errors => [...errors,"Please Choose a Location"])
            setTimeout(() => {
                setErrors([])
            }, 5000)
            return false;
        }

        if (platformString.includes('Google Maps')) {
            platformString.push('Google');
        } else {
            platformString = platformString.filter(platform => platform !== 'Google')
        }
        setLoading(true);

        axios.post(`${baseUrlDev}locations/widget`, { ...allData, platform: platformString.join(','), rating: ratingString },
        { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(res => {
            if (res.status === 200) {
                setShowAlert(true);
                setAlertText(res.data.message)
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            Object.keys(err.response.data.errors).map(key => {
                setErrors(errors => [...errors, err.response.data.errors[key][0]])
            })
            setTimeout(() => {
                setErrors([])
            }, 5000)
        })
}

    const handleSubmit = (e) => {
        if(selected && (!location || location == "all")){
            handleSubmitFunc(selected, e)
        }
        if (location && location !== "all") {
            handleSubmitFunc(location, e)
        }
        timeAlertId = setTimeout(() => {
            setShowAlert(false);
        }, 4000);
    }
    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/CukCJlAhAgA?autoplay=1&mute=0&rel=0'))
    }, [])
    
    useEffect(() => {
        dispatch(fetchWidgetCodeLoading());
        if(selected && (!location || location == "all")){
            dispatch(fetchWidgetCode(selected))
        }
        return () => {
            clearTimeout(timeAlertId)
        }
    }, [selected])

    useEffect(() => {
        dispatch(fetchWidgetCodeLoading());
        if (location && location !== "all") {
            dispatch(fetchWidgetCode(location))
        }
        return () => {
            clearTimeout(timeAlertId)
        }
    }, [location])


    useEffect(() => {
        if(locationArray.length > 0){
            setLoc(locationArray[0])
        } else {
            setLoc("")
        }
    }, [locationArray])

    useEffect(() => {
        if (widget?.widget) {
            setFormData({
                location: location,
                display: widget.widget.display,
                background_color: widget.widget.background_color,
                text_color: widget.widget.text_color,
                text_font: widget.widget.text_font,
                platform: widget?.widget?.platform.split(','),
                rating: widget?.widget?.rating.split(','),
                without_text: widget.widget.without_text,
                multiple_location: widget.widget.multiple_location && widget.widget.multiple_location,
                limit: widget.widget.limit
            });
            widget.widget.multiple_location  && setLocationArray(widget.widget.multiple_location)
            setActivePlatform(widget?.widget?.platform.split(','));
            setActiveRating(widget?.widget?.rating.split(','));
        }

    }, [widget?.widget]);

    const handleChangeLocations = (e) => {
        let value = e.target.value
        if(value.includes("all")){
            if(locationArray.length === (locations && locations.length)){
                setLocationArray([])
                setFormData({ ...formData, multiple_location: [] });
            } else {
                setLocationArray(locations && locations.map(el => el.id))
                setFormData({ ...formData, multiple_location: locations && locations.map(el => el.id) });
            }
        } else {
            setLocationArray(value.filter(el => el !== "all"))
            setFormData({ ...formData, multiple_location: value.filter(el => el !== "all") });
        }  
    }

    return (
        <div className='revushareWeb'>
            <>
                <Grow in={showAlert}><Alert severity="success" className="fixed-alert-right" iconMapping={{ success: <CustomAlertIcon /> }}>{alertText}</Alert></Grow>
                <div className="alert-inside-page" style={{top: '0'}}>
                    {errors && errors.map(err => {
                        return(
                            <Alert key={err} severity="error" className='custom-error-alert'>{err}</Alert>
                        )
                    })}
                </div>
                <div className="mt-20">
                    <Grid container spacing={3}>
                        <Grid item lg={7} md={7} sm={12} xs={12} className="d-flex align-items-stretch">
                            <form action="" className='w-100' onSubmit={handleSubmit}>
                                <div className="widget_setup_card">
                                    <h2 className="title">{t("Widget Setup")}</h2>
                                    {
                                        widget === undefined ? <Skeleton style={{ width: "100%", height: "100vh", marginRight: "10px", marginBottom: "10px", transform: "unset" }} /> :
                                            <div>
                                                <div className='display_widget_btn_wrapper'>
                                                    <label htmlFor="" className='label_widget_setup'>{t("Display")}</label>
                                                    <div className="d-flex align-items-center flex-wrap gap-10">
                                                        <button type='button' className={`display_widget_btn ${formData?.display === 1 ? 'active' : ''}`} value="1" onClick={() => handleActiveButton(1, setDisplayWidget)}>{t("Table")}</button>
                                                        <button type='button' className={`display_widget_btn ${formData?.display === 2 ? 'active' : ''}`} value="2" onClick={() => handleActiveButton(2, setDisplayWidget)}>{t("Bottom")}</button>
                                                    </div>
                                                </div>

                                                <div>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <div className="select_color_wrapp">
                                                                <label htmlFor="">{t("Background Color")}</label>
                                                                <Colorpicker color={formData?.background_color} setColor={(e) => handleChange("background_color", e)} />
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <div className="select_color_wrapp">
                                                                <label htmlFor="">{t("Primary Text Color")}</label>
                                                                <Colorpicker color={formData?.text_color} setColor={(e) => handleChange("text_color", e)} />
                                                            </div>
                                                        </Grid>

                                                        <Grid item xs={12} xl={12} md={12} sm={12} className="mb-25">
                                                            <label htmlFor="" className='label_widget_setup'>{t("Text Font")}</label>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    id="text_font-select"
                                                                    label="position"
                                                                    name="text_font"
                                                                    value={formData?.text_font}
                                                                    onChange={(e) => handleChange('text_font', e.target.value)}
                                                                    input={<MySelect />}
                                                                >
                                                                    <MenuItem value={'Roboto'}>Roboto</MenuItem>
                                                                    <MenuItem value={'Poppins'}>Poppins</MenuItem>
                                                                    <MenuItem value={'Lato'}>Lato</MenuItem>
                                                                    <MenuItem value={'Montserrat'}>Montserrat</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                <div>
                                                    <Platforms handleActiveButton={toggleActivePlatform} platforms={widget?.sources} activePlatform={activePlatform} />
                                                </div>

                                                <div>
                                                    <StarRating handleActiveStar={toggleActiveRating} activeRating={activeRating} />
                                                </div>

                                                <div className='reviews_include_wrapper'>
                                                    <h5>{t("Include Reviews Without Text")}</h5>
                                                    <div className="onoffswitch">
                                                        {/* <input
                                                            type="checkbox"
                                                            className="onoffswitch-checkbox active"
                                                            id="review_notification"
                                                            value={formData?.without_text}
                                                            checked={formData?.without_text}
                                                            onChange={(event) => handleCheckBoxChange('without_text', event.target.checked)}
                                                        /> */}
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                labelPlacement="start"
                                                                control={
                                                                    <AntSwitch checked={!formData?.without_text} onChange={(event) => handleCheckBoxChange('without_text', event.target.checked)} name="without_text" />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>

                                                {/* <div className="all_locations_wrapper d-flex align-items-center justify-content-between">
                                                    <h5>{t("All of your Locations")}</h5>
                                                    <div className="onoffswitch">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                labelPlacement="start"
                                                                control={
                                                                    <AntSwitch 
                                                                        value={formData?.multiple_location !== null && formData?.multiple_location}
                                                                        checked={formData?.multiple_location !== null && formData?.multiple_location}
                                                                        name="without_text" 
                                                                        onChange={(event) => handleCheckBoxChange('multiple_location', event.target.checked)}
                                                                    />
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div> */}
                                                <div className='choose-location-select'>
                                                    <label>{t("Choose Locations")}</label>
                                                    {locations === undefined ? <Skeleton style={{height: '41px', transform: 'unset'}}/> :
                                                        <FormControl className="" fullWidth>
                                                            <Select
                                                                displayEmpty
                                                                value={locationArray}
                                                                multiple
                                                                onChange={handleChangeLocations}
                                                                onClose={() => setSearch("")}
                                                                input={<MySelect/>}
                                                                MenuProps={{
                                                                    classes: {
                                                                        paper: "breake-option-words custom-selected",
                                                                    },
                                                                    autoFocus: false, PaperProps: { sx: { maxHeight: 300} , 
                                                                    style: {
                                                                        maxWidth: 300,
                                                                        borderColor: '#F3F3F3'
                                                                    }, 
                                                                }}}
                                                                renderValue={value => {
                                                                    if(value.length === 0){
                                                                        return t("Select Locations");
                                                                    } else if (value.length === (locations && locations.length)){
                                                                        return t("All Locations")
                                                                    } else {
                                                                        let arr = value.map(element => {
                                                                            return locations && locations.find(el => el.id == element)
                                                                        })
                                                                        return arr.map(el => el && el.full_name).join(', ')
                                                                    }
                                                                }}
                                                            >
                                                                <ListSubheader>
                                                                    <TextField
                                                                        size="small"
                                                                        autoFocus
                                                                        placeholder={t("Search Location")}
                                                                        fullWidth
                                                                        onChange={(e) => setSearch(e.target.value)}
                                                                        onKeyDown={(e) => {
                                                                            if(e.key !== 'Escape'){
                                                                                e.stopPropagation();
                                                                            }
                                                                        }}
                                                                    />
                                                                </ListSubheader>
                                                                <MenuItem value={"all"}>{t("All Locations")}</MenuItem>
                                                                {displayedLocation && displayedLocation.map(loc => {
                                                                    return(
                                                                        <MenuItem key={loc.id} value={loc.id}>{loc.full_name}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    }
                                                </div>
                                                <div>
                                                    <label htmlFor="" className='label_widget_setup'>{t("Number of Reviews")}</label>
                                                    <div>
                                                        <input name="limit" type="number" placeholder='30' value={formData?.limit} className='number_reviews_inp' onChange={(e) => handleChange('limit', e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                    {/* <button type='submit' className='widget_setup_btn'>Save</button> */}
                                    <LoadingButton
                                        type='submit'
                                        className='widget_setup_btn'
                                        style={{paddingLeft: loading && "30px"}}
                                        loading={loading}
                                        onClick={() => handleSubmit()}
                                    >
                                        {t("Save")}
                                    </LoadingButton>
                                </div>
                            </form>
                        </Grid>

                        <Grid item lg={5} md={5} sm={12} xs={12} className="d-flex align-items-stretch">
                            {(widget === undefined || !locations) ? <Skeleton style={{ width: "100%", height: "100vh", marginRight: "10px", marginBottom: "10px", transform: "unset" }} /> :
                                <WidgetCode formData={formData} activePlatform={activePlatform} activeRating={activeRating} loc={loc}/>
                            }
                        </Grid>
                    </Grid>
                </div>
            </>
        </div>
    )
}

export default RevuShareWrapper;
