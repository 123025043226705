import React, {useState} from "react"
import { FormControl, Select, MenuItem, Collapse } from "@mui/material"
import MySelect from "../../../Helpers/MySelect"
import { useTranslation } from "react-i18next"

const SubscriptionQuestion = ({handleStep, subscriptions, service, setService, plan, plans}) => {
    const [show, setShow] = useState(false)
    const {t} = useTranslation()
    let plan_selected = plans.filter(el => el.plan_id === plan)
    return(
        <div className="sub-question-body">
            <h4>{t("Do you want to use your Existing Subscription")}?</h4>
            <div className="mb-50">
                <button onClick={() => setShow(true)}>{t("Yes")}</button>
                <button onClick={() => {
                    setShow(false)
                    handleStep(2)
                    setService(" ")
                }}>{t("No")}</button>
            </div>
            <Collapse in={show}>
                <div style={{minWidth: "30vw"}}>
                    <FormControl fullWidth>
                        <Select
                            value={service}
                            id="input-type-one-select"
                            input={<MySelect/>}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                            onChange={(e) => {
                                setService(e.target.value)
                                handleStep(2)
                            }}
                        >
                            <MenuItem value=" " disabled>{t("Select Subscription")}</MenuItem>
                            {
                            plan_selected[0] && plan_selected[0].name === "Full Service" ? 
                                subscriptions && subscriptions.full_service && 
                                    subscriptions.full_service.map(el => (
                                        <MenuItem key={el.subscription_id} value={el.subscription_id} className="custom-flex-direction">
                                            {el.reference_no ? el.reference_no : el.irevu_companyName}
                                            {el.last_four_number && <span style={{display: "block", opacity: "0.7", fontSize: "13px"}}><b>Card Number:</b> {el.last_four_number[0]}</span>}
                                        </MenuItem>
                                    ))
                            : 
                            plan_selected[0] && plan_selected[0].name === "Self Service" ? 
                                subscriptions && subscriptions.self_service && 
                                    subscriptions.self_service.map(el => (
                                        <MenuItem key={el.subscription_id} value={el.subscription_id} className="custom-flex-direction">
                                            {el.reference_no ? el.reference_no : el.irevu_companyName}
                                            {el.last_four_number && <span style={{display: "block", opacity: "0.7", fontSize: "13px"}}><b>Card Number:</b> {el.last_four_number[0]}</span>}
                                        </MenuItem>
                                    ))
                            : 
                            plan_selected[0] && plan_selected[0].name === "AI Service" ? 
                                subscriptions && subscriptions.ai_service && 
                                subscriptions.ai_service.map(el => (
                                    <MenuItem key={el.subscription_id} value={el.subscription_id} className="custom-flex-direction">
                                        {el.reference_no ? el.reference_no : el.irevu_companyName}
                                        {el.last_four_number && <span style={{display: "block", opacity: "0.7", fontSize: "13px"}}><b>Card Number:</b> {el.last_four_number[0]}</span>}
                                    </MenuItem>
                                ))
                            : 
                                subscriptions && subscriptions.la_carte && 
                                subscriptions.la_carte.map(el => (
                                    <MenuItem key={el.subscription_id} value={el.subscription_id} className="custom-flex-direction">
                                        {el.reference_no ? el.reference_no : el.irevu_companyName}
                                        {el.last_four_number && <span style={{display: "block", opacity: "0.7", fontSize: "13px"}}><b>Card Number:</b> {el.last_four_number[0]}</span>}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </Collapse>
        </div>
    )
}

export default SubscriptionQuestion