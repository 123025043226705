import React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import {Skeleton, Checkbox, ListItemText} from "@mui/material"

const GroupLocationWrapper = ({columns, setColumns}) => {
    const {t} = useTranslation()

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];

            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];

            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    };
    return(
        <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
        {Object.entries(columns).map(([columnId, column], index) => {
            return(
        
            <div key={columnId} className="location-box"
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    key={columnId}
                >
                    {columnId === "allowed" ? <h6>{t("Included")} <span>({t("Locations")})</span></h6> : <h6>{t("Not included")} <span>({t("Locations")})</span></h6>}
                    <div className="location-box-item">
                        <Droppable droppableId={columnId} key={columnId}>
                            {(provided) => {
                                return (
                                    <div>
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{

                                                width: "100%",
                                                minHeight: 500
                                            }}
                                        >
                                            {columns ?
                                                <div>
                                                    {column.items?.map((item, index) => {
                                                        return (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={item.id.toString()}
                                                                index={index}
                                                            >
                                                                {(provided) => {
                                                                    return (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={{
                                                                                userSelect: "none",
                                                                                minHeight: "50px",
                                                                                ...provided.draggableProps.style
                                                                            }}
                                                                        >
                                                                            <div className="location-holder-box">
                                                                                <div className="shrink-0 align-self-center">
                                                                                    <img src={"/admin-icons/dragIcon.svg"} />
                                                                                    <label style={{width: "100%", marginBottom: "0px"}}>{item.full_name}{item.group_name !== "Ungrouped Locations" && <h5 className="location-of-groupname"> - <span>(Group:  {item.group_name})</span></h5>}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                                : <Skeleton style={{ height: "100px", width: "100%" }} />}
                                        </div>

                                    </div>
                                );
                            }}
                        </Droppable>
                    </div>
                </div>
            </div>
            )

        })}

    </DragDropContext>
    )
}
export default GroupLocationWrapper