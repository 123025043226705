import React from "react"
import OnlyLocationWrapper from "../../parts/OnlyLocationWrapper"
import CompetitorWrapper from "./competitorWrapper"
import InnerDropdownContainer from "../../parts/InnerDropdownContainer"

const Competitor = () => {
    return(
        <OnlyLocationWrapper>
            <InnerDropdownContainer/>
            <CompetitorWrapper/>
        </OnlyLocationWrapper>
    )
}
export default Competitor