import React from "react"
import OnlyLocationWrapper from "../../../parts/OnlyLocationWrapper"
import QrCodeWrapper from "./QrCodeWrapper"
import InnerDropdownContainer from "../../../parts/InnerDropdownContainer"
import Header from "../../../../Helpers/header"

const RevuShare = () => {
    return(
        <OnlyLocationWrapper>
            <div className="flex-header-container table-header-container">
                <Header title="Tools" subtitle="QR Code" />
            </div>
            <InnerDropdownContainer/>
            <QrCodeWrapper/>
        </OnlyLocationWrapper>
    )
}
export default RevuShare