import React from "react"
import {  Grid, IconButton} from "@mui/material"
import LightTooltip from "../../helpers/lightTooltip"
import {AiFillStar} from "react-icons/ai"
import { useTranslation } from "react-i18next";
import CountUp from 'react-countup';
import { IoMdInformationCircleOutline } from "react-icons/io"


const TopBoxes = ({reviews, rating, responseCount}) => {
    const {t} = useTranslation()
    return(
        <Grid container spacing={2} className="box-containers">
            <Grid item xs={12} md={4}>
                <div>
                    <LightTooltip title={t("The total amount of reviews for your business")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span><CountUp end={reviews} duration={1}/></span>
                    <h6>{t("Reviews")}</h6>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div>
                    <LightTooltip title={t("The average star rating for your business incorporating all monitored review sites")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span className="rating-box">
                        {5 && 5 % 1 === 0 ? 
                            <CountUp 
                                end={rating} 
                                duration={1}
                            />
                            : <CountUp
                                end={rating} 
                                duration={1}
                                decimals={1}
                                decimal="."
                            />}
                            <AiFillStar/>
                        </span>
                    <h6>{t("Rating")}</h6>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div>
                    <LightTooltip title={t("Number of review responses provided by AI")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span><CountUp end={responseCount} duration={1}/></span>
                    <h6>{t("AI Responses")}</h6>
                </div>
            </Grid>
        </Grid>
    )
}
export default TopBoxes