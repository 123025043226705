import React, { useState } from "react"
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import { formatDate } from '../../../helpers/formatDate';
import { BiExport } from 'react-icons/bi';
import { Grid, ClickAwayListener, CircularProgress } from '@mui/material';
import { formatDateLine } from "../../../helpers/formatDate";
import { fetchReputation, clearReputationPage, openExportedAlert } from "../../../../../redux/actions/admin-index";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser";
import { baseUrlDev } from "../../../../Helpers/baseUrl";
import DateRange from "../../../helpers/DateRange";
import Header from "../../../../Helpers/header";
import { useTranslation } from "react-i18next";
import { AiOutlineFilePdf } from "react-icons/ai";
import { exportTable } from "../../../helpers/export";

const ReputationHeader = ({ setFrom, setTo, from, to }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [loadingExcel, setLoadingExcel] = useState(false)
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)

    const { t } = useTranslation()
    const handleDateChange = (item) => {
        let itemSerialized = {
            startDate: formatDateLine(item.selection.startDate),
            endDate: formatDateLine(item.selection.endDate)
        }
        setFrom(item.selection.startDate ? formatDateLine(item.selection.startDate) : "")
        setTo(item.selection.endDate ? formatDateLine(item.selection.endDate) : "")

        if (item.selection.startDate !== item.selection.endDate) {
            dispatch(clearReputationPage())
        }
        if (!item.selection.startDate) {
            dispatch(clearReputationPage())
            dispatch(fetchReputation("", "", location === "all" ? "" : location, groupSelected))
        } else {
            dispatch(fetchReputation(itemSerialized.startDate, itemSerialized.endDate, location === "all" ? "" : location, groupSelected))
        }
    }
    const exportExcel = () => {
        setLoadingExcel(true)
        exportTable(impersonateReducer, `${baseUrlDev}locations/reputations/export`, { 
            location: location !== "all" ? location : "", 
            from, 
            to, 
            group_id: 
            groupSelected 
        }, 
        (resData) => {
            dispatch(openExportedAlert(true, resData.message));
            setLoadingExcel(false)
        }, 
        (error) => {
            setLoadingExcel(false)
        });
    }
    const exportPdf = () => {
        setLoading(true)
        exportTable(impersonateReducer, `${baseUrlDev}locations/reputations/export-to-pdf`, { 
            location: location !== "all" ? location : "", 
            from, 
            to, 
            group_id: 
            groupSelected 
        }, 
        (resData) => {
            dispatch(openExportedAlert(true, resData.message));
            setLoading(false)
        }, 
        (error) => {
            setLoading(false)
        });
    }

    return (
        <div className="reputation_header">
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Header title="Locations" subtitle="Location Reputations" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <div className="d-flex align-items-center justify-content-end gap-10 data_wrapper">
                        <DateRange handleDateChange={handleDateChange} />
                        <div className="flex-header-actions">
                            <button 
                                className="btn competitor-export"
                                onClick={() => exportExcel()}
                                disabled={loadingExcel}
                            >
                                {loadingExcel ?
                                    <>
                                    <CircularProgress
                                        size={15}
                                        style={{ marginRight: "10px" }}
                                        thickness={4}
                                    />
                                    Export to Excel</>
                                : <> <BiExport style={{ marginRight: "10px" }} /> {t("Export to Excel")}</>}
                            </button>
                        </div>
                        <div>
                            <button
                                style={{ marginLeft: "auto" }} className="competitor-export"
                                onClick={() => exportPdf()}
                                disabled={loading}
                            >
                                {loading ?
                                    <>
                                        <CircularProgress
                                            size={15}
                                            style={{ marginRight: "10px" }}
                                            thickness={4}
                                        />
                                        Export to PDF</>
                                    : <> <AiOutlineFilePdf style={{ marginRight: "10px" }} /> {t("Export to PDF")}</>}
                            </button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default ReputationHeader