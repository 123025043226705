import React, { useEffect, useState } from "react"
import {  useParams } from "react-router-dom"
import { Link } from "react-router-dom";
import ReviewItem from "./reviewItem";
import { Skeleton } from "@mui/material";
import {MdArrowBackIosNew, MdArrowForwardIos} from "react-icons/md"
import AlertComponent from "../../../Helpers/Alert";
import { useDispatch, useSelector } from "react-redux";
import { fetchReview, clearReview, fetchAllLocationSources, memorizeLastReview, handle404ErrorReset, paginationReviews } from "../../../../redux/actions/admin-index";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WhiteTooltip from "../../helpers/whiteTooltip";

const Review = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const dispatch = useDispatch()
    const [t] = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams();

    const tools = useSelector(state => state.allLocationSources.tools)


    const groupSelected = searchParams.get("group_id")

    const location = searchParams.get("location")
    const reviews = useSelector(state => state.getReviews.reviews);
    const keyword = searchParams.get("keyword")
    const sourcesFilter = searchParams.get("sources")
    const status = searchParams.get("status")
    const rating = searchParams.get("rating")
    const orderState = searchParams.get("order")
    const tagsFilter = searchParams.get("tags")
    const from = searchParams.get("from")
    const to = searchParams.get("to")
    const page = searchParams.get("page")

    const queryParams = {
        location,
        group_id: groupSelected,
        keyword,
        "sources": sourcesFilter,
        status,
        rating,
        "order": orderState,
        "tags" : tagsFilter,
        from,
        to
    };
    const filteredParams = Object.fromEntries(
        Object.entries(queryParams).filter(([_, value]) => value)
    );

    useEffect(() => {
        dispatch(clearReview())
        dispatch(fetchReview(id, groupSelected, location, keyword, sourcesFilter?.split(","), status?.split(","), rating?.split(","), orderState, tagsFilter?.split(","), from, to,page))
        dispatch(memorizeLastReview(id))
    }, [id])

    useEffect(() => {
        dispatch(handle404ErrorReset())
    }, [])

    useEffect(() => {
        if(!tools){
            if((location && location !== "all") || groupSelected){
                dispatch(fetchAllLocationSources(location, groupSelected))
            } else {
                dispatch(fetchAllLocationSources())
    
            }
        }
    }, [location, groupSelected])

    const review = useSelector(state => state.getReview.review)
    const notFound = useSelector(state => state.notFound.notFound);

    const queryString = Object.keys(filteredParams)
    .map(key => `${key}=${filteredParams[key]}`)
    .join('&');

    if (notFound) {
      return(
        <div>
            <div className="bradcrumb-back-to">
                <Link to="/app/admin/reviews/list?backTo=true"><MdArrowBackIosNew/>{t("Back to reviews")}</Link>
            </div>
            <div className="review-notfound">
                <img src="/admin-images/small-404.svg" alt="review not found"/>
                <p>Review Not Found</p>
            </div>
        </div>

      )
    }

    return(
        <div>
            <AlertComponent/>
            <div className="d-flex align-items-center justify-content-between">
                <div className="bradcrumb-back-to">
                    <Link to="/app/admin/reviews/list?backTo=true"><MdArrowBackIosNew/>{t("Back to reviews")}</Link>
                </div>
                {review === undefined ? 
                    <div className="bradcrumb-next-to">
                        <Skeleton style={{height: "40px", marginRight: "10px",  width: "100px", transform: "unset"}}/>
                        <Skeleton style={{height: "40px", width: "100px", transform: "unset"}}/>
                </div>
                :
                <div className="bradcrumb-next-to">
                    {review.next_review && 
                         <WhiteTooltip title={t("Newer")}>
                            <Link to={`/app/admin/reviews/list/${review.next_review}?${queryString}&page=${review.newer_page_parameter}`}
                            onClick={() => {
                                    dispatch(paginationReviews(review.newer_page_parameter))
                            }}>
                                <MdArrowBackIosNew/></Link>
                        </WhiteTooltip>
                    }
                    {review.previous_review && 
                        <WhiteTooltip title={t("Older")}>
                            <Link to={`/app/admin/reviews/list/${review.previous_review}?${queryString}&page=${review.older_page_parameter}`} 
                            onClick={() => {
                                 dispatch(paginationReviews(review.older_page_parameter))
                            }}><MdArrowForwardIos/></Link>
                        </WhiteTooltip>
                    }
                </div>
                }
            </div>
            {review === undefined ? 
              <div>
                <div className="rev-action-buttons justify-content-between">
                    <div className="rev-action-buttons">
                        <Skeleton style={{height: "40px", marginRight: "10px", marginBottom: "10px", width: "100px", transform: "unset"}}/>
                        <Skeleton style={{height: "40px", marginRight: "10px", marginBottom: "10px",  width: "100px", transform: "unset"}}/>
                        <Skeleton style={{height: "40px", marginRight: "10px", marginBottom: "10px",  width: "100px", transform: "unset"}}/>
                        <Skeleton style={{height: "40px", marginRight: "10px", marginBottom: "10px",  width: "100px", transform: "unset"}}/>
                    </div>
                    <div className="rev-action-buttons">
                        <Skeleton style={{height: "40px", marginRight: "10px", marginBottom: "10px",  width: "100px", transform: "unset"}}/>
                        <Skeleton style={{height: "40px", marginRight: "10px", marginBottom: "10px",  width: "100px", transform: "unset"}}/>
                    </div>
                </div>
                <Skeleton style={{height: "150px", transform: "unset"}}/>
            </div>
            
            : <ReviewItem review={review.current_review}/>}
        </div>
      
    )
   
}
export default Review