import React, {useEffect, useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchAccountInvoices, showYoutubeContent } from "../../../../../redux/actions/admin-index"
import Loader from "../../../helpers/loader"
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider, Select, FormControl, MenuItem } from "@mui/material";
import UnSubscribe from "../../Subscription/unSubscribe";
import NextBilling from "./NextBilling";
import Header from "../../../../Helpers/header";
import OnlyOwner from "../../../OnlyOwner";
import axios from "axios";
import { baseUrlDev } from "../../../../Helpers/baseUrl";
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser";
import useHandleDisable from "../../../helpers/disableSelect";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import Agreements from "./agreements";

const Invoices = () => {
    const handleDisable = useHandleDisable()
    const dispatch = useDispatch()
    const [openA, setOpenA] = useState(false)
    const [sortingColumnName, setSortingColumnName] = useState("Due Date");
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [open, setOpen] = useState(false)
    const [showPage, setShowPage] = useState(false)
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ROTS4l8tqiM?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        dispatch(fetchAccountInvoices())
        handleDisable()
    }, [])

    const invoices = useSelector(state => state.getInvoices.invoices)
    const user = useSelector(state => state.getUserInfo.user)
    const location = useSelector(state => state.locationSelected.location)
    const {t} = useTranslation()

    useEffect(() => {
        if(location){
            let location_details = user && user.user.locations.filter(loc => loc.id == location)
            if(location_details && location_details[0] && !location_details[0].is_owner){
                setShowPage(true)
            } else {
                setShowPage(false)
            }
        }
    }, [location, user])

    // if(showPage){
    //     return(
    //         <OnlyOwner/>
    //     )
    // }

    if(invoices === undefined){
        return(
            <Loader absoluteFlex={true}/>
        )
    }
        
    const handleSortingChange = (event) => {
        const selectedIndex = event.target.value;
        setSortingColumnName(selectedIndex);
    }

    const handleSortingDirectionChange = (event) => {
        const selectedDirection = event.target.value;
        setSortingDirection(selectedDirection);
    }

    const getMuiTheme = () =>
        createTheme({
            components: {
        }
    });

    const handleDownloadPdf = (url) => {
        setLoading(true);
        axios.post(`${baseUrlDev}invoice/pdf`, {url}, { responseType: 'arraybuffer', headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
          })
          .catch(error => {
            // Handle error
          });
      };

    const columns = [
        { 
            name: t("invoice number"), 
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        { 
            name: t("Plan Name"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("reference no"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Amount due"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">${value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Amount paid"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">${value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Paid"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Due Date"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: ("Details"), 
            options: {  
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    // <a href={value} target="_blank" className="redirect-link" onClick={() => handleDownloadPdf(value)} rel="noreferrer">Invoice Details</a>
                    <div>
                        <a href={value} target="_blank" className="redirect-link" rel="noreferrer">
                            <img src="/admin-images/view.svg" width="16" alt="" />
                        </a>
                        {
                            loading ?  <CircularProgress size={16} sx={{ color: '#18AEE3', marginLeft: '10px' }} /> : <button onClick={() => handleDownloadPdf(value)} style={{cursor: 'pointer', border: 'none', background: 'unset'}}>
                                <img src="/admin-images/download-pdf.svg" width="16" alt="" />
                            </button>
                        }
                    </div>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
    ];
    
    const data = invoices.data.length > 0 ? invoices.data.map(invoice => {
        return [invoice.invoice_no,  invoice.plan, invoice.reference_no, invoice.amount_due,  invoice.amount_paid, invoice.is_paid, invoice.paid_at, invoice.url]
    }) : []

    const options = {
        search: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        download: true,
        print: true,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: "checkbox",     
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        // customToolbar: () => (
        //     <>
        //       <FormControl>
        //         <Select
        //           value={sortingColumnName !== null ? sortingColumnName : ''}
        //           onChange={handleSortingChange}
        //           displayEmpty
        //         >
        //           <MenuItem value='' disabled>Sort By</MenuItem>
        //           {columns.map((column, index) => (
        //             <MenuItem key={column.name} value={column.name}>{column.name}</MenuItem>
        //           ))}
        //         </Select>
        //       </FormControl>
        //       <FormControl>
        //         <Select
        //           value={sortingDirection}
        //           onChange={handleSortingDirectionChange}
        //         >
        //           <MenuItem value='asc'>Ascending</MenuItem>
        //           <MenuItem value='desc'>Descending</MenuItem>
        //         </Select>
        //       </FormControl>
        //     </>
        //   ),
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onTableChange: (action, tableState) => {
            if (action === 'sort') {
              const { dataIndex, sortOrder } = tableState;
              setSortingColumnName(dataIndex);
              setSortingDirection(sortOrder);
            }
          },
        sortOrder: {
            name: sortingColumnName,
            direction: sortingDirection
        },
        onRowsDelete: (rowsDeleted) => {
  
        }
    };
    if(user && user.user && user.user.locations.length > 0 && user.user.locations.every(loc => loc.is_owner == false)) {
        return <OnlyOwner/>
    }
    return(
       <>
            <Agreements open={openA} setOpen={setOpenA}/>
            <UnSubscribe open={open} setOpen={setOpen}/>
            <div className="table-header-container">
                <Header title={t("Account")} subtitle={t("Invoices")} />
                <button onClick={() => setOpenA(true)}>
                    {t("See Agreements")}
                </button>
            </div>
            <NextBilling subscriptions={invoices.subscription_details}/>
            <div className="new-datatable nowrap-table position-relative">
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
                <button className="unsubscribe-btn" onClick={() => setOpen(true)}>{t("Unsubscribe")}</button>
            </div>
        </>
    )
}
export default Invoices