import React from 'react';

const WelcomeSection = ({ data }) => {
    return (
        <div className='welcome_section_wrap'>
            <h2 className='title'>{data?.title}</h2>
            <p>{data?.sub_title}</p>
            <p className='green_color'>{data?.sub_title2}</p>
            <p>{data?.description}</p>
        </div>
    )
}

export default WelcomeSection;
