import React, {useState} from "react"
import OnlyLocationWrapper from "../../parts/OnlyLocationWrapper"
import SettingsAutopilotWrapper from "./SettingsAutopilotWrapper"
import InnerDropdownContainer from "../../parts/InnerDropdownContainer"
import { useTranslation } from "react-i18next"
import Header from "../../../Helpers/header"

const SettingsAutopilot = () => {
    return(
        <OnlyLocationWrapper>
            <div className="table-header-container">
                <Header title="Autopilot AI" subtitle="Autopilot AI Settings"/>
            </div>
            <InnerDropdownContainer/>
            <SettingsAutopilotWrapper/>
        </OnlyLocationWrapper>
    )
}
export default SettingsAutopilot