export const reviewKeyword = (state = null, action) => {
    switch(action.type){
        case 'SEARCH_REVIEW_KEYWORD':
            return{
                ...state,
                keywords: action.payload,
            }
        case 'CLEAR_FILTER':
            return{
                ...state,
                keywords: ""
            }
        default:
            return{
                ...state
            }
    }
}


export const reviewSourceFilter = (state = [] ,action) => {
    switch(action.type){
        case 'REVIEW_SOURCES_FILTER':
            return{
                ...state,
                source: action.payload,
            }
        case 'CLEAR_FILTER':
            return{
                ...state,
                source: ""
            }
        default:
            return{
                ...state
            }
    }
}

const initialState = {
    pagination: 1,
};

export const paginationReviews = (state = initialState ,action) => {
    switch(action.type){
        case 'PAGINATION_REVIEWS':
            return{
                ...state,
                pagination: action.payload,
            }
        case 'CLEAR_FILTER':
            return{
                ...state,
                pagination: 1
            }
        default:
            return{
                ...state
            }
    }
}

export const reviewStatusFilter = (state = [] ,action) => {
    switch(action.type){
        case 'REVIEW_STATUS_FILTER':
            return{
                ...state,
                status: action.payload,
            }
        case 'CLEAR_FILTER':
            return{
                ...state,
                status: ""
            }
        default:
            return{
                ...state
            }
    }
}
export const reviewRatingFilter = (state = [] ,action) => {
    switch(action.type){
        case 'REVIEW_RATINGS_FILTER':
            return{
                ...state,
                rating: action.payload,
            }
        case 'CLEAR_FILTER':
            return{
                ...state,
                rating: ""
            }
        default:
            return{
                ...state
            }
    }
}

export const reviewRangeFilter = (state = {} ,action) => {
    switch(action.type){
        case 'REVIEW_RANGE_FILTER':
            return{
                ...state,
                dateFrom: action.payload.startDate,
                dateTo: action.payload.endDate,
            }
        case 'CLEAR_FILTER':
            return{
                ...state,
                dateFrom: '',
                dateTo: '',
            }
        default:
            return{
                ...state
            }
    }
}

export const reviewTagsFilter = (state = [] ,action) => {
    switch(action.type){
        case 'REVIEW_TAGS_FILTER':
            return{
                ...state,
                tags: action.payload
            }
        case 'CLEAR_FILTER':
            return{
                ...state,
                tags: ""
            }
        default:
            return{
                ...state
            }
    }
}

export const reviewSortOrder = (state = [] ,action) => {
    switch(action.type){
        case 'ORDER_REVIEWS':
            return{
                ...state,
                order: action.payload
            }
        case 'CLEAR_FILTER':
            return{
                ...state,
                order: ""
            }
        default:
            return{
                ...state
            }
    }
}

export const allLocationSources = (state = [] ,action) => {
    switch(action.type){
        case 'GET_LOCATIONS_SOURCES':
            return{
                ...state,
                tools: action.payload
            }
        default:
            return{
                ...state
            }
    }
}

export const memorizeLastReviewReducer = (state = "" ,action) => {
    switch(action.type){
        case 'MEMORIZE_LAST_REVIEW_EDITED':
            return{
                ...state,
                id: action.payload
            }
        default:
            return{
                ...state
            }
    }
}