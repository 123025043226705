import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchReviewsHistory, reviewHistoryLoading } from "../../../../redux/actions/admin-index"
import { Skeleton } from "@mui/material"
import {BiCommentCheck} from "react-icons/bi"
import Loader from "../../helpers/loader"
import LightTooltip from "../../helpers/lightTooltip"
import axios from "axios"
import EditableReply from "./editableReply"
import { useParams } from "react-router-dom";
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import AlertComponent from "../../../Helpers/Alert"
import { useTranslation } from "react-i18next"

const ReviewHistory = () => {
    let { id } = useParams()
    const [logs, setLogs] = useState([])
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(reviewHistoryLoading())
        axios.get(`${baseUrlDev}reviews/${id}/replies/google`, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then((res) => {
                dispatch(fetchReviewsHistory(id))
            }).catch(err => {
                dispatch(fetchReviewsHistory(id))
            })
    }, [])

    const history = useSelector(state => state.getReviewsHistory.history)
    const loading = useSelector(state => state.getReviewsHistory.loading)
    const user = useSelector(state => state.getUserInfo.user)

    useEffect(() => {
        if (history && history.data) {
            setLogs(history.data)
        }
    }, [history])

    if (!history) {
        return (
            <div className="review-history" style={{ width: "100%" }}>
                <Skeleton style={{ width: "50%", height: "65px", transform: "unset", marginBottom: "15px" }} />
                <Skeleton style={{ width: "300px", height: "35px", transform: "unset", marginBottom: "10px", borderRadius: "20px", marginLeft: "auto", marginRight: "auto" }} />
                <Skeleton style={{ width: "450px", height: "35px", transform: "unset", marginBottom: "10px", borderRadius: "20px", marginLeft: "auto", marginRight: "auto" }} />
                <Skeleton style={{ width: "350px", height: "35px", transform: "unset", marginBottom: "10px", borderRadius: "20px", marginLeft: "auto", marginRight: "auto" }} />
                <Skeleton style={{ width: "50%", height: "80px", transform: "unset", marginBottom: "10px" }} />
                <Skeleton style={{ width: "450px", height: "35px", transform: "unset", marginBottom: "10px", borderRadius: "20px", marginLeft: "auto", marginRight: "auto" }} />
                <Skeleton style={{ width: "350px", height: "35px", transform: "unset", marginBottom: "10px", borderRadius: "20px", marginLeft: "auto", marginRight: "auto" }} />
                <Skeleton style={{ width: "50%", height: "100px", transform: "unset", marginBottom: "10px" }} />
                <Skeleton style={{ width: "300px", height: "35px", transform: "unset", marginBottom: "10px", borderRadius: "20px", marginLeft: "auto", marginRight: "auto" }} />
                <Skeleton style={{ width: "450px", height: "35px", transform: "unset", marginBottom: "10px", borderRadius: "20px", marginLeft: "auto", marginRight: "auto" }} />
                <Skeleton style={{ width: "50%", height: "45px", transform: "unset", marginBottom: "10px" }} />
            </div>
        )
    }

    if (logs.length > 0) {
        const replyItems = logs.filter(rev => rev.type === "reply")
        return (
            <div className="review-history" style={{ position: 'relative' }}>
                <h1>{t("History")}</h1>
                <AlertComponent />
                {loading && <Loader absoluteFlex={true} />}
                <div className="custom-scrollbar-vertical">
                    {logs.map((rev, index) => {
                        if (rev.type === "reply") {
                            return (
                                <div key={`history${rev.id}`}>
                                    <div className="d-flex reply-history" style={{ position: 'relative' }}>
                                        <div className={`d-flex space-nowrap ${rev.user?.name === "Owner From AI" ? "align-items-end" : "align-items-center"}`}>
                                            {rev.user ?
                                                <img src={rev.user.avatar ? rev.user.avatar :
                                                    rev.user.name !== "Owner From AI" ? "https://irevu.com/images/avatar_default.svg" :
                                                        "/admin-icons/robot-ai.svg"}
                                                    alt="user"
                                                />
                                                :
                                                <img src="https://irevu.com/images/avatar_default.svg" alt="user" />
                                            }
                                            <h6>{rev.user ? rev.user.name : 'User'}</h6>
                                        </div>
                                        <span>-</span>
                                        <div>
                                            {rev.status &&
                                                <LightTooltip title={rev.status == 'SUCCESS' ? "Review has been responded" : "Response has not been posted yet. Please wait."}>
                                                    <span style={{ position: "absolute", right: "10px", top: "10px", padding: "0", margin: "0" }}>
                                                        <BiCommentCheck style={{ fontSize: "20px", color: (rev.status == 'SUCCESS' ? "#70C996" : "#fad63b") }} />
                                                    </span>
                                                </LightTooltip>
                                            }
                                            <p className="reply-p-title"> {replyItems[replyItems.length - 1].id === rev.id ? "replied" : "updated"} at <b>{rev.date}</b></p>
                                            <p>{rev.content}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else if (rev.type === "history") {
                            return (
                                <div key={`history${rev.id}`}>
                                    <div className="d-flex other-actions-history">
                                        <div className="d-flex align-items-center">
                                            <h6>{rev.user ? rev.user.name : 'User'}</h6>
                                        </div>
                                        <span>-</span>
                                        <p className="mt-0">{rev.content} at <b>{rev.date}</b></p>
                                    </div>
                                </div>
                            )
                        } else if (rev.type === "reply_confirm" && !rev.confirmed_by && !rev.confirmed) {
                            return (
                                <EditableReply rev={rev} logs={logs} setLogs={setLogs} id={id} />
                            )
                        }
                    })}
                </div>
            </div>
        )
    }
}
export default ReviewHistory