import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const FetchDataHook = (url, headers) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const language = useSelector(state => state.getLanguage.language)

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(url, { headers });
            setData(response?.data?.data);
          } catch (err) {
            setError(err);
          } finally {
            setLoading(false);
          }
        };
        fetchData();
    }, [language]);

    return { data, error, loading };
}