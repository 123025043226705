import React, { useState } from 'react'
import { Modal, Slide, Alert } from "@mui/material";
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { showAlert } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import axios from 'axios';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import AlertComponent from "../../../Helpers/Alert"

const SourceLocation = ({ open, setOpen, id, url, setUrl}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])

    const handleClose = () => {
        setOpen(false);
        setLoading(false)
    }
    const submitUrl = () => {
        setErrors([])
        if(!url){
            setErrors([...[], "Please Fill input"])
            return
        }
        setLoading(true)
        axios.post(`${baseUrlDev}locations/listing-score/${id}`, {url}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            setLoading(false)
            setOpen(false)
            dispatch(showAlert(true, "Url Updated Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch((err) => {
            setLoading(false)
            Object.keys(err.response.data.errors).map(key => {
                setErrors([...errors, err.response.data.errors[key][0]])
            })
        })
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-keyword-title"
            aria-describedby="modal-keyword-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className="custom-modal-dialog">
                    <AlertComponent/>
                    {errors && errors.map(err => {
                        return(
                            <div className="alert-inside-modal" key={err}>
                                <Alert severity="error" className='custom-error-alert'>{err}</Alert>
                            </div>
                        )
                    })}
                    <div className="custom-modal-header">
                        <h5>Request Accurate</h5>
                    </div>

                    <div className="custom-modal-content source_location_modal">
                        <div className='scrollable-loc-container custom-scrollbar-vertical'>
                            <Grid container spacing={3} className="grid_wrap">
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="field-container">
                                        <label htmlFor="">URL</label>
                                        <input type="text" placeholder='Enter url' value={url} onChange={(e) => setUrl(e.target.value)}/>
                                        <span>Please submit your Google link.</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className="custom-modal-footer">
                        <LoadingButton
                            style={{paddingLeft: loading && "30px"}}
                            className="save-btn"
                            onClick={() => submitUrl()}
                            loading={loading}
                        >
                            Save
                        </LoadingButton>
                        <button className="close-btn" onClick={() => handleClose()}>Close</button>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}

export default SourceLocation;