import React from "react"
import { Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { openExportedAlert } from "../../../redux/actions/admin-index"

const style = {
    modal_wrapper: {
        background: '#ffff',
        width: '500px',
        maxWidth: '100%',
        margin: 'auto',
        height: 'fit-content',
        padding: '50px 30px',
        borderRadius: '5px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: 0,
        outline: "none"
    },
    modal_paragraph: {
        fontFamily: "Poppins-Regular",
        fontWeight: "400",
        margin: 0,
        textAlign: "center",
        color: "#25313C",
        fontSize: 18,
        marginBottom: 3
    },
    modal_span: {
        fontFamily: "Poppins-Bold",
        fontWeight: "600",
        marginLeft: "3px",
        display: "block",
        marginTop: "10px",
        textAlign: "center",
        fontSize: 20
    },
    modal_close: {
        position: "absolute",
        top: 7,
        right: 10,
        padding: 5,
        cursor: "pointer",
        opacity: "7"
    }
}

const ExportedAlert = () => {
    const dispatch = useDispatch()
    const open = useSelector(state => state.exportedAlertReducer.open)
    const message = useSelector(state => state.exportedAlertReducer.message)

    const user = useSelector(state => state.getUserInfo.user)

    const handleClose = () => {
        dispatch(openExportedAlert(false))
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div style={style.modal_wrapper}>
                <img style={style.modal_close} src={"/admin-icons/close-circle.svg"} alt="close" onClick={handleClose}/>
                <p style={style.modal_paragraph}>{message}</p>
                <span style={style.modal_span}>{user?.user?.email}</span>
            </div>
        </Modal>
    )
   
}
export default ExportedAlert
