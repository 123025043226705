import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Unlock from '../Unlock';
import useHandleDisable from '../helpers/disableSelect';
import { showYoutubeContent } from '../../../redux/actions/admin-index';

const Social = () => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const handleDisable = useHandleDisable()
    const [locked, setLocked] = useState(false)

    const location = useSelector(state => state.locationSelected.location)
    const user = useSelector(state => state.getUserInfo.user)

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ROTS4l8tqiM?autoplay=1&mute=0&rel=0'))
    }, [])
    
    useEffect(() => {
        if(location){
            let location_details = user && user.user.locations.filter(loc => loc.id == location)
            if(location_details && location_details[0] && location_details[0].is_demo){
                setLocked(true)
            } else {
                setLocked(false)
            }
        }
    }, [location, user])

    useEffect(() => {
        handleDisable()
    }, [])

    if(locked){
        return <Unlock/>
    }

    return (
        <div className='social_ifram-wrapper'>
            {id === "dashboard" ?   <iframe id="form__iframe" src={`https://socialirevu.irevu.com/`} ></iframe> :
                <iframe id="form__iframe" src={`https://socialirevu.irevu.com/${id}`} ></iframe>
            }
        </div>
    )
}

export default Social
