export const getReviews = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEWS':
            return{
                ...state,
                reviews: action.payload,
                loading: false
            }
        case 'LOADING_TABLES':
            return{
                ...state,
                loading: true
            }
        case 'CLEAR_REVIEWS':
            return{
                ...state,
                reviews: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getReview = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEW':
            return{
                ...state,
                review: action.payload,
            }
        case 'CLEAR_REVIEW':
            return{
                ...state,
                review: undefined,
            }
        default:
            return{
                ...state
            }
    }
}

export const getReviewsBoxes = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEWS_TOP_BOXES':
            return{
                ...state,
                reviews_boxes: action.payload,
            }
            case 'CLEAR_TOP_BOXES':
                return{
                    ...state,
                    reviews_boxes: undefined
                }
        default:
            return{
                ...state
            }
    }
}

export const getReviewsHistory = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEWS_HISTORY':
            return{
                ...state,
                history: action.payload,
                loading: false
            }
        case 'LOADING_HISTORY':
            return{
                history: undefined,
                loading: true
            }
        default:
            return{
                ...state
            }
    }
}

export const getQuickReplies = (state = [], action) => {
    switch(action.type){
        case 'GET_QUICK_REPLY':
            return{
                ...state,
                replies: action.payload,
            }
        default:
            return{
                ...state
            }
    }
}
export const getQuickReply = (state = [], action) => {
    switch(action.type){
        case 'GET_QUICK_REPLY_DETAILS':
            return{
                ...state,
                reply: action.payload,
            }
            case 'CLEAR_QUICK_REPLY_DETAILS':
                return{
                    ...state,
                    reply: undefined
                }
        default:
            return{
                ...state
            }
    }
}

export const getReviewsCoupon = (state = [], action) => {
    switch(action.type){
        case 'GET_REVIEWS_COUPON':
            return{
                ...state,
                coupons: action.payload,
            }
        case 'CLEAR_REVIEWS':
            return{
                ...state,
                coupons: undefined
            }
        default:
            return{
                ...state
            }
    }
}