import React from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const steps = ['Text', 'Image', 'Qr Code'];

const StepperComponent = ({ activeStep, completed, allStepsCompleted, handleReset }) => {
    const {t} = useTranslation()
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit">
                            {t(label)}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

export default StepperComponent;
