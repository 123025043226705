import React, { Suspense, useState } from "react"
import { Modal, Slide, Autocomplete, TextField, Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MyAutocomplete from "../../../Helpers/MyAutcomplete";
import axios from "axios";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { fetchLocationTags, loadingTables } from "../../../../redux/actions/admin-index";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../redux/actions";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const SketchPicker = React.lazy(() => import("./Colorpicker"));

const AddTag = ({ open, setOpen, location }) => {
    const dispatch = useDispatch()
    const [name, setName] = useState("")
    const [color, setColor] = useState("")
    const [keywords, setKeywords] = useState([])
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [t] = useTranslation()
    const selected = useSelector(state => state.groupLocationSelected.group_location)


    const handleClose = () => {
        setOpen(false)
        setLoading(false)
        setErrors([])
        setColor("")
        setName("")
        setKeywords([])
    }

    const handleKeywords = (event, values) => {
        setKeywords(values);
    };

    const submitTag = () => {
        if(selected && (!location || location == "all")){
            apiRequest(selected)
        }
        if (location && location !== "all") {
            apiRequest(location)
        }
    }

    const apiRequest = (id) => {
        if (name.length < 4 || name.length > 35) {
            setErrors([...[], "Tag name must be between 4-35 chars"])
            return
        }
        setLoading(true)
        let data = { name, color, keywords: typeof(keywords) == "string" ? [] :  keywords}
        axios.post(`${baseUrlDev}locations/${id}/tags/create`, data, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(res => {
            setTimeout(() => {
                dispatch(loadingTables())
            }, 500)
            dispatch(fetchLocationTags(id))
        }).then(res => {
            handleClose()
            dispatch(showAlert(true, "Tag Created Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => {
            setLoading(false)
            if(err.response.data.errors) {
                Object.keys(err.response.data.errors).map(key => {
                    setErrors([...errors, err.response.data.errors[key][0]])
                })
            } else {
                setErrors([...errors, err?.message])
            }
        })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-tag-title"
            aria-describedby="modal-tag-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className={`custom-modal-dialog`}>
                    {errors && errors.map(err => {
                        return (
                            <div className="alert-inside-modal" key={err}>
                                <Alert severity="error" className="custom-error-alert">{err}</Alert>
                            </div>
                        )
                    })}
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>{t("Add Tag")}</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-div-container custom-scrollbar-vertical">
                                <div className="field-container">
                                    <label>{t("Tag Name")}</label>
                                    <input minLength={4} maxLength={35} type="text" placeholder={t("Add Tag Name")} onChange={(e) => {
                                        setName(e.target.value)
                                        setErrors([])
                                    }} />
                                </div>
                                <div className="field-container">
                                    <label>{t("Keywords")}</label>
                                    <Autocomplete
                                        multiple
                                        id="tags-filled"
                                        options={[]}
                                        defaultValue={[]}
                                        freeSolo
                                        input={<MyAutocomplete />}
                                        onInputChange={handleKeywords}
                                        onChange={handleKeywords}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                            
                                                placeholder={t("Enter to submit keywords")}
                                                className="custom-autocomplete"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    maxLength: 100
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="field-container fit-content-w">
                                    <label>{t("Background Color")}</label>
                                    <Suspense fallback={"Loading"}>
                                        <SketchPicker setColor={setColor} color={color} />
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <LoadingButton
                                style={{ paddingLeft: loading && "30px" }}
                                className="save-btn"
                                onClick={() => submitTag()}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                            <button className="close-btn" onClick={() => handleClose()}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default AddTag