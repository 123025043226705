import React from 'react';
import { TiTick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SetupConnectionCard = ({ title, complete, link, handleClick, apps }) => {
  const [t] = useTranslation()
  return (
    <div className='setup_connection_card' onClick={() => handleClick(apps)}>
      <div className='d-flex align-items-center'>
        <span className={`circle ${complete ? 'active' : ''}`}>
          {complete ? <TiTick /> : ''}
        </span>
        <Link to={link} className='connection_link'>
          <h4 className='title'>{t(title)}</h4>
        </Link>
      </div>
    </div>
  )
}

export default SetupConnectionCard;