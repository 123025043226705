import React, { useEffect, Suspense, useState } from 'react';
import '../../../../../assets/reputation.css'
import { Grid, Skeleton, IconButton, LinearProgress } from '@mui/material';
import { Container } from '@mui/system';
import { IoMdInformationCircleOutline } from "react-icons/io"
import LightTooltip from '../../../helpers/lightTooltip';
import ReputationTable from './ReputationTable';
import ReputationHeader from './ReputationHeader';
import ReputationSkeleton from '../../../helpers/reputationSkeleton';
import {useDispatch, useSelector} from "react-redux"
import { fetchReputation, clearReputationPage, showYoutubeContent } from '../../../../../redux/actions/admin-index';
import { useTranslation } from 'react-i18next';
import ExportedAlert from "../../../modals/exportedAlert"

const ReputationChart = React.lazy(() => import("./ReputationChart"))

const Reputation = () => {
    const dispatch = useDispatch()
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ROTS4l8tqiM?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        dispatch(clearReputationPage())
        if((location && location!== "all") || groupSelected){
            dispatch(fetchReputation(from, to, location, groupSelected))
        } else {
            dispatch(fetchReputation(from, to))
    }
    }, [location, groupSelected])

    const reputation = useSelector(state => state.getReputation.reputation)

    return (
        <>
            <ExportedAlert/>
            <div className='reputation_page'>
                <>
                    <ReputationHeader setFrom={setFrom} setTo={setTo} from={from} to={to}/>
                    {reputation === undefined ?   <ReputationSkeleton/>
                    :
                    <>
                       <div className="repuatation_content">
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <Suspense fallback={<Skeleton style={{transform: "unset", width: "200px", height: "200px", borderRadius: "100%", margin: "auto"}}/>}>
                                    <ReputationChart analytics={reputation.analytics}/>
                                </Suspense>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <div className="reputation_card">
                                    <div className="reputation_card_header">
                                        <div className='text-right'>

                                            <LightTooltip title="The average star rating for your all location business incorporating all monitored review sites">
                                                <IconButton className="tooltip-btn-holder">
                                                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                                </IconButton>
                                            </LightTooltip>
                                        </div>
                                    </div>

                                    <div className="reputation_card_content">
                                        <Grid container spacing={3}>
                                            <Grid item lg={5} md={4} sm={12} xs={12}>
                                                <div className="left">
                                                    <img src={"/admin-images/reputation_star.svg"} alt="star" />
                                                    <h2 className='big_number'>{reputation.reviewRating}</h2>
                                                    <h3 className='subtitle'>{t("Review Rating")}</h3>
                                                </div>
                                            </Grid>

                                            <Grid item lg={7} md={8} sm={12} xs={12}>
                                                <div className="right">
                                                    <div className="d-flex align-items-center wrapper">
                                                        <div className='mr-11' style={{ maxWidth: '90px', width: '100%' }}>
                                                            <h3 className='big_text'>({reputation.totalReviews.respondedTo})</h3>
                                                            <span className='medium_text'>{t("Responded to")}</span>
                                                        </div>
                                                        <div className='w-100'>
                                                            <LinearProgress variant="determinate" value={70} />
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-center mt-27 wrapper">
                                                        <div className='mr-11' style={{ maxWidth: '90px', width: '100%' }}>
                                                            <h3 className='big_text'>{reputation.totalReviews.totalReviews}</h3>
                                                            <span className='medium_text'>{t("Total reviews")}</span>
                                                        </div>
                                                        <div className='w-100'>
                                                            <LinearProgress variant="determinate" value={70} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <div className='table_wrapper custom-horizontal-scrollbar'>
                                <ReputationTable locationReviews={reputation.locationReviews}/>
                            </div>
                        </Grid>
                    </Grid>
                    </>
                    }
                </>

            </div>
        </>
    )
}

export default Reputation;
