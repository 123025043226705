import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import EditLocation from '../../modals/locations/edit_location';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import { useDispatch } from 'react-redux';
import { fetchUserInfo, fetchDashboardStats, clearDashboardStats} from '../../../../redux/actions/admin-index';

const NotValid = ({setAddressNotValid, addressSuggestion}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const user = useSelector(state => state.getUserInfo.user)

    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)

    let location_details = user && user.user.locations.filter(loc => loc.id == location)

    const changeLocation = (id) => {
        let refresh_cache = true
        setLoading(true)
        axios.post(`${baseUrlDev}locations/${id}/suggestion/confirm`, {}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(() => {
            dispatch(fetchUserInfo())
            dispatch(clearDashboardStats())
        })
        .then(() => {
            dispatch(fetchDashboardStats("", "", location,  groupSelected, refresh_cache))
            setLoading(false)
        })
        .catch((err) => {
            setLoading(false)
        })
    }

    return (
        <>
            <EditLocation open={open} setOpen={setOpen}/>
            <div className='welcome_section_wrap not-valid-flex'>
                <Grid container spacing={2} style={{height: "100%"}}>
                    <Grid item xs={12} xl={6}>
                        <div>
                            <p>
                                {t("Address provided")} "<b>{location_details && location_details[0] && location_details[0].full_name}</b>" {t("does")} <span style={{color: "rgb(201, 28, 37)", fontWeight: "bold"}}>{t("NOT")}</span> {t("seem to be valid")}. {t("Please provide us with a")} <span style={{color: "#01ba98",  fontWeight: "bold"}}>{t("VALID")}</span> {t("business location address")}, {t("verified by Google")}.
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        <div className='not-valid-buttons'>
                            <button className='find-business' onClick={() => setOpen(true)}>{t("Find Your Valid Business Address Here")}</button>
                            <span style={{transform: "uppercase"}}>{t("Or")}</span>
                            <Link to="/app/admin/reviews/connections/google" className='connect-google-b'>
                                <img src="/admin-icons/google.svg" alt="google"/>
                                {t("Connect Your Google Business Account")}
                            </Link>
                        </div>
                    </Grid>
                    {addressSuggestion && 
                    <Grid item xs={12}>
                        <div className='did_you_mean'>
                            <p>{t("Did you mean to select this location")} "<span>{addressSuggestion?.address}</span>" ?</p>
                            <div className='did_you_mean_buttons'>
                                {loading ? 
                                <button style={{cursor: "initial", padding: "14px 24px 14px 15px"}}>
                                    <span>{t("Yes")}</span> <div className="dot-flashing-sm"></div>
                                </button>
                                : <button onClick={() => changeLocation(addressSuggestion?.id)}>{t("Yes")}</button>}
                                <button onClick={() => setAddressNotValid(false)}>{t("No")}</button>
                            </div>
                        </div>
                    </Grid>
                    }
                    <Grid item xs={12}>
                        <div style={{textAlign: "right", display: "grid", height: "100%"}}>
                            <button className="ignore-notvalid"  onClick={() => setAddressNotValid(false)}>{t("Ignore this Warning")}</button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default NotValid;
