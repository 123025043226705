import React from "react"
import { Breadcrumbs } from "@mui/material"
import { Link } from "react-router-dom"
import { Container } from "@mui/system"
import {AiOutlineArrowLeft} from "react-icons/ai"
import { useTranslation } from "react-i18next"

const FormMain = (props) => {
    let oldIrevu = localStorage.getItem('oldIrevu')
    let hostname = window.location.hostname
    const {t} = useTranslation()
    const breadcrumbs = [
        <Link to={JSON.parse(oldIrevu) ? "/app/admin/forms" : '/app/admin/tools/forms'} key="forms" className="link-back">
            <AiOutlineArrowLeft/>
            {t("Back to Forms")}
        </Link>
    ]
    return(
        <Container maxWidth="lg" className="mt-100">
            <Breadcrumbs seperator="/" aria-label="form-breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            {props.children}
        </Container>
    )

}
export default FormMain