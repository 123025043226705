import React, {useState} from "react"
import { ClickAwayListener } from "@mui/material";
import { DateRangePicker, defaultStaticRanges, createStaticRanges, defaultInputRanges  } from 'react-date-range';
import { formatDate } from "./formatDate";
import { useTranslation } from "react-i18next";
import { es, it, de, fr, enUS } from "date-fns/locale";
import { useSelector } from "react-redux";

const DateRange = ({handleDateChange}) => {
    const [t] = useTranslation()
    const [showRange, setShowRange] = useState(false)
    const [selectionRange, setSelectionRange] =  useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);

    const handleChange = (item) => {
        if(item){
            setSelectionRange([item.selection])
            handleDateChange(item)
        }
    }
    let  currentDate = new Date();

    let translateOptions = defaultStaticRanges.map(el => {
        return {...el, label: t(el.label)}
    })
    let translatedInputs = defaultInputRanges.map(el => {
        return {...el, label: t(el.label)}
    })
    const staticRanges = [
        ...createStaticRanges([
            {
                label: t("All time"),
                range: () => ({
                    startDate: null,
                    endDate: null,
                }),
                isSelected : (range) => {
                    return true
                }
            },
        ]),
        ...translateOptions,
        ...createStaticRanges([
            {
                label: t("This Year"),
                range: () => ({
                    startDate: new Date(currentDate.getFullYear(), 0, 1),
                    endDate: currentDate,
                }),
            },
            {
                label: t("Last Year"),
                range: () => ({
                    startDate: new Date(currentDate.getFullYear() - 1 , 0, 1),
                    endDate: new Date(currentDate.getFullYear() - 1 , 11, 31),
                }),
            },
        ])
    ];
    const language = useSelector(state => state.getLanguage.language)

    return(
        <ClickAwayListener onClickAway={() => setShowRange(false)}>
            <div className="date-range-container">
                <div className="datepicker-dashboard-dates" onClick={() => setShowRange(!showRange)}>
                    {
                        selectionRange[0].startDate ?
                        <>
                            <div>{formatDate(selectionRange[0].startDate.toString())}</div>
                            <div>{selectionRange[0].endDate  ? formatDate(selectionRange[0].endDate.toString()) : 'to'}</div>
                        </>
                        : <span style={{minWidth: "100px"}}>{t("All time")}</span>
                    }
                    
                </div>
                {showRange &&
                    <div className="datepicker-container datepicker-dashboard-container">
                        <DateRangePicker  
                            startDatePlaceholder={t("From")}
                            endDatePlaceholder={t("To")}
                            editableDateInputs={true}
                            ranges={selectionRange}
                            onChange={handleChange}
                            moveRangeOnFirstSelection={false}
                            direction={"vertical"}
                            inputRanges={translatedInputs}
                            staticRanges={staticRanges}
                            locale={language == "es" ? es : language == "it" ? it : language == "fr" ? fr : language == "de" ? de : enUS}
                        />
                    </div>
                }
            </div>
        </ClickAwayListener>
    )
}
export default DateRange