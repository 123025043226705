import "../../../../assets/admin.css";
import { useEffect, useState } from "react";
import { Grid, Rating, Collapse, Alert, Checkbox, FormControlLabel, FormGroup, Skeleton, Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationSettings, clearNotificationAccount, showYoutubeContent } from "../../../../redux/actions/admin-index";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import axios from "axios";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { AntSwitch } from "../../../Helpers/AntSwitch";
import { showAlert } from "../../../../redux/actions";
import AlertComponent from "../../../Helpers/Alert";
import Header from "../../../Helpers/header";
import { useTranslation } from "react-i18next";

let controller = new AbortController()

const NotificationsWrapper = () => {
    const dispatch = useDispatch()
    const [mounted, setMounted] = useState(false);
    const location = useSelector(state => state.locationSelected.location)
    const notifications = useSelector(state => state.getNotificationSettings.notifications_settings)
    const { t } = useTranslation()
    const [checkSwitch, setCheckSwitch] = useState({
        daily_summery: false,
        weekly_summary: false,
        feedback_notif: false,
        notes_notif: false,
        review_notification: false,
        sms_notif: false,
        sms_notif_review: [],
        notification_rating: [],
        sms_daily_summary: notifications?.notificationSettings?.sms_daily_summary,
    });
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/fmqCUI9XjOQ?autoplay=1&mute=0&rel=0'))
    }, [])


    useEffect(() => {
        setMounted(false)
        if(selected && (!location || location == "all")){
            dispatch(clearNotificationAccount())
            dispatch(fetchNotificationSettings(selected))
        }
    }, [selected])

    useEffect(() => {
        setMounted(false)
        if (location && location !== "all") {
            dispatch(clearNotificationAccount())
            dispatch(fetchNotificationSettings(location))
        }
    }, [location])

    useEffect(() => {
        let result = {};
        if (notifications) {
            Object.keys(checkSwitch).map((item) => {
                if (item === "notification_rating") {
                    result[item] = notifications.notificationSettings.notificationRatingArray ? notifications.notificationSettings.notificationRatingArray : []
                } else if (item === 'sms_notif_review') {
                    result[item] = notifications.notificationSettings.smsNotificationRatingArray ? notifications.notificationSettings.smsNotificationRatingArray : []
                } else {
                    result[item] = notifications.notificationSettings[item] ? true : false
                }
            })

            setCheckSwitch({ ...result })
        }
    }, [notifications])


    const handleSubmit = () => {
        if(selected && (!location || location == "all")){
            apiRequest(selected)
        }
        if (location && location !== "all") {
            apiRequest(location)
        }
    }
    const apiRequest = (id) => {
        let data = Object.assign(checkSwitch);
        var formData = new FormData();
        Object.keys(data).map((key) => {
            if (key === "notification_rating") {
                checkSwitch[key] && checkSwitch[key].length > 0 ? checkSwitch[key].forEach((el) => {
                    formData.append("notification_rating[]", el);
                }) : formData.append("notification_rating[]", []);
            } else if (key === 'sms_notif_review') {
                checkSwitch[key] && checkSwitch[key].length > 0 ? checkSwitch[key].forEach((el) => {
                    formData.append("sms_notif_review[]", el);
                }) : formData.append("sms_notif_review[]", []);
            } else {
                formData.append(key, checkSwitch[key] ? 1 : 0);
            }
        });
        // formData.append('sms_daily_summary', checkSwitch.sms_daily_summary ? 1 : 0);
        formData.append("location", id)
        controller.abort();
        controller = new AbortController()
        axios.post(`${baseUrlDev}notifications/update-settings`, formData, { signal: controller.signal, headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(() => {
            dispatch(showAlert(true, "Notification Settings Updated Successfully"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch((err) => {
            console.log(err?.response?.data?.errors)
        })
    }

    const handleChange = (event) => {
        setMounted(true)
        const { name, checked } = event.target;
        setCheckSwitch({
            ...checkSwitch,
            [name]: checked ? true : false,
        });

        if (name === "review_notification" && checked && checkSwitch.notification_rating.length == 0) {
            setCheckSwitch(prevState => ({
                ...prevState,
                notification_rating: ["1", "2", "3", "4", "5"]
            }));
        }

        // if (name === "review_notification" && !checked) {
        //     setCheckSwitch(prevState => ({
        //         ...prevState,
        //         notification_rating: []
        //     }));
        // }

        if (name === "sms_notif" && checked && checkSwitch.sms_notif_review.length == 0) {
            setCheckSwitch(prevState => ({
                ...prevState,
                sms_notif_review: ["1", "2", "3", "4", "5"]
            }));
        }

        // if (name === "sms_notif" && !checked) {
        //     setCheckSwitch(prevState => ({
        //         ...prevState,
        //         sms_notif_review: []
        //     }));
        // }
    };

    const changeRatingNotif = (value) => {
        setMounted(true);
        if (checkSwitch.notification_rating.includes(value)) {
            setCheckSwitch(prevState => ({
                ...prevState,
                notification_rating: prevState.notification_rating.filter(el => el !== value)
            }));
        } else {
            setCheckSwitch(prevState => ({
                ...prevState,
                notification_rating: [...prevState.notification_rating, value]
            }));
        }
    }

    const handleDailySummaryChange = (event) => {
        setMounted(true);
        const value = event.target.checked ? 1 : 0;

        setCheckSwitch(prevState => ({
            ...prevState,
            sms_daily_summary: value ? 1 : 0
        }));

    };

    const changeChangeSmsNotif = (value) => {
        setMounted(true);
        if (checkSwitch?.sms_notif_review?.includes(value)) {
            setCheckSwitch(prevState => ({
                ...prevState,
                sms_notif_review: prevState.sms_notif_review.filter(el => el !== value)
            }));
        } else {
            setCheckSwitch(prevState => ({
                ...prevState,
                sms_notif_review: [...prevState.sms_notif_review, value]
            }));
        }
    }

    useEffect(() => {
        if (mounted) {
            handleSubmit()
        }
    }, [checkSwitch])

    useEffect(() => {
        if (checkSwitch.review_notification == 0) {
            setCheckSwitch({
                ...checkSwitch,
                ["review_notification"]: false,
            });
        }
        if (checkSwitch.sms_notif == 0) {
            setCheckSwitch({
                ...checkSwitch,
                ["sms_notif"]: false,
            });
        }
    }, [checkSwitch.notification_rating, checkSwitch.sms_notif_review])

    return (
        <div className="account-settings-container">
            <Grid container>
                <Grid item xs={12} md={5} xl={3}>
                    <AlertComponent />
                    <div className="notification-div-wrapper mt-20">
                        {notifications ?
                            <FormGroup >
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <AntSwitch checked={checkSwitch.daily_summery} onChange={handleChange} name="daily_summery" />
                                    }
                                    label={t("Daily Summary")}
                                />
                            </FormGroup>
                            : <Skeleton style={{ height: "55px", width: "100%" }} />}
                    </div>
                    <div className="notification-div-wrapper">
                        {notifications ?
                            <FormGroup>
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <AntSwitch checked={checkSwitch.weekly_summary} onChange={handleChange} name="weekly_summary" />
                                    }
                                    label={t("Weekly Summary")}
                                />

                            </FormGroup>
                            : <Skeleton style={{ height: "55px", width: "100%" }} />}
                    </div>
                    <div className="notification-div-wrapper">
                        {notifications ?
                            <FormGroup>
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <AntSwitch checked={checkSwitch.feedback_notif} onChange={handleChange} name="feedback_notif" />
                                    }
                                    label={t("Feedback")}
                                />

                            </FormGroup>
                            : <Skeleton style={{ height: "55px", width: "100%" }} />}
                    </div>
                    <div className="notification-div-wrapper">
                        {notifications ?
                            <FormGroup>
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={
                                        <AntSwitch checked={checkSwitch.notes_notif} onChange={handleChange} name="notes_notif" />
                                    }
                                    label={t("Notes")}
                                />
                            </FormGroup>
                            : <Skeleton style={{ height: "55px", width: "100%" }} />}
                    </div>
                    <div className="notification-div-wrapper">
                        {notifications ?
                            <>
                                <FormGroup>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        control={
                                            <AntSwitch checked={checkSwitch.review_notification} onChange={handleChange} name="review_notification" />
                                        }
                                        label={t("Review Notification")}
                                    />
                                </FormGroup>
                                <Collapse in={checkSwitch.review_notification}>
                                    <div className="rating-list-background">
                                        {["1", "2", "3", "4", "5"].map(item => {
                                            return (
                                                <div key={`notif-email-${item}`}>
                                                    <Checkbox
                                                        id={`notification_rating_${item}`}
                                                        value={parseInt(item)}
                                                        checked={checkSwitch.notification_rating.includes(item)}
                                                        name="rating"
                                                        onChange={(e) => changeRatingNotif(e.target.value)}
                                                        sx={{
                                                            color: "#ccc",
                                                            '&.Mui-checked': {
                                                                color: "#00B4E5",
                                                            },
                                                            '& .MuiSvgIcon-root': { fontSize: 20 }
                                                        }}
                                                    />
                                                    <label htmlFor={`notification_rating_${item}`}>
                                                        <Rating
                                                            sx={{ '& .MuiRating-icon': { mr: 1 } }}
                                                            icon={<img src={"/admin-images/filled-icon.svg"} className="custom-rating-sm-ic" />}
                                                            emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-sm-ic" />}
                                                            value={item}
                                                            readOnly
                                                        />
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Collapse>
                            </>
                            : <Skeleton style={{ height: "55px", width: "100%" }} />}
                    </div>

                    <div className="notification-div-wrapper">
                        {notifications ?
                            <>
                                <FormGroup>
                                    <FormControlLabel
                                        // disabled={notifications.notificationSettings.sms_notify_toggle == 0 ? true : false}
                                        labelPlacement="start"
                                        control={
                                            notifications.notificationSettings.sms_notify_toggle == 0 ?
                                                <AntSwitch checked={false} name="sms_notif" />
                                                :
                                                <AntSwitch checked={checkSwitch.sms_notif} onChange={handleChange} name="sms_notif"/>
                                        }
                                        label="SMS Review Notification"
                                    />
                                </FormGroup>
                                <Collapse in={checkSwitch.sms_notif}>
                                    <div className="rating-list-background">
                                        {["1", "2", "3", "4", "5"].map(item => {
                                            return (
                                                <div key={`sms_notif-email-${item}`}>
                                                    <Checkbox
                                                        id={`sms_notif_review_${item}`}
                                                        value={parseInt(item)}
                                                        checked={checkSwitch?.sms_notif_review?.includes(item)}
                                                        name="sms_notif_rating"
                                                        onChange={(e) => changeChangeSmsNotif(e.target.value)}
                                                        sx={{
                                                            color: "#ccc",
                                                            '&.Mui-checked': {
                                                                color: "#00B4E5",
                                                            },
                                                            '& .MuiSvgIcon-root': { fontSize: 20 }
                                                        }}
                                                    />
                                                    <label htmlFor={`sms_notif_review_${item}`}>
                                                        <Rating
                                                            sx={{ '& .MuiRating-icon': { mr: 1 } }}
                                                            icon={<img src={"/admin-images/filled-icon.svg"} className="custom-rating-sm-ic" />}
                                                            emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-sm-ic" />}
                                                            value={item}
                                                            readOnly
                                                        />
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
{/* 
                                    <div className="daily_summary_box">
                                        <div className="box">
                                            <Checkbox
                                                id={`sms_daily_summary`}
                                                name="sms_daily_summary"
                                                value={checkSwitch.sms_daily_summary}
                                                checked={checkSwitch.sms_daily_summary == 1 ? true : false}
                                                onChange={handleDailySummaryChange}
                                                sx={{
                                                    color: "#ccc",
                                                    '&.Mui-checked': {
                                                        color: "#00B4E5",
                                                    },
                                                    '& .MuiSvgIcon-root': { fontSize: 20 }
                                                }}
                                            />

                                            <div>
                                                <label htmlFor={`sms_daily_summary`}>
                                                    <p>SMS Daily Summary</p>
                                                </label>
                                            </div>
                                        </div>
                                    </div> */}
                                </Collapse>
                                {notifications.notificationSettings.sms_notify_toggle == 0 && <Alert style={{ marginTop: "15px" }} severity="warning">Insufficient Funds in Your Wallet!</Alert>}
                            </>
                            : <Skeleton style={{ height: "55px", width: "100%" }} />}
                    </div>

                </Grid>
            </Grid>
        </div>
    )
}
export default NotificationsWrapper;