import React, {useEffect} from "react"
import EachGroupLocations from "./EachGroupLocations"
import { useSelector, useDispatch } from "react-redux"
import AllGroupLocations from "./AllGroupLocations"
import { getGroupLocation } from "../../../redux/actions/admin-index"

const InnerDropdownContainer = ({disabled}) => {
    const dispatch = useDispatch()
    const location = useSelector(state => state.locationSelected.location)

    // useEffect(() => {
    //     if(location && location !== "all"){
    //         dispatch(getGroupLocation(""))
    //     }
    // }, [location])

    if(!location && location != "all"){
        return <EachGroupLocations disabled={disabled}/>
    } else if(location == "all"){
        return <AllGroupLocations disabled={disabled}/>
    }

}
export default InnerDropdownContainer