import React from "react"
import { Skeleton, Grid } from "@mui/material"

const KeywordSkeleton = () => {
    return(
        <Grid container spacing={2} style={{paddingTop: '10px'}}>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{width: "100%", height: "400px", transform: "unset"}} />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <Skeleton style={{width: "100%", height: "80vh", transform: "unset"}} />
            </Grid>
        </Grid>
    )
}
export default KeywordSkeleton