import React from 'react';
import {HiOutlineLocationMarker} from "react-icons/hi"
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { getLocationSelected, getGroupSelected } from '../../../../../redux/actions/admin-index';
import {AiFillStar} from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const ReputationTable = ({locationReviews}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t}  = useTranslation()
    const navigateTo = (id) => {
        dispatch(getLocationSelected(id))
        localStorage.setItem('location', id);
        dispatch(getGroupSelected(""))
        localStorage.setItem('group', "");
        navigate("/app/admin/reviews/list")
    }

    return (
        <table className='general__table reputation__table'>
            <thead>
                <tr>
                    <th>{t("Location name")}</th>
                    <th style={{ textAlign: 'center' }}>{t("Total reviews")}</th>
                    <th style={{ textAlign: 'center' }}>{t("Responded to")}</th>
                    <th style={{ textAlign: 'center' }}>{t("Review Rating")}</th>
                    <th style={{ textAlign: 'center' }}>{t("iReview Reviews")}</th>
                    <th style={{ textAlign: 'center' }}>{t("Responded %")}</th>
                    <th style={{ textAlign: 'center' }}>5 {t("Stars")}</th>
                    <th style={{ textAlign: 'center' }}>4 {t("Stars")}</th>
                    <th style={{ textAlign: 'center' }}>3 {t("Stars")}</th>
                    <th style={{ textAlign: 'center' }}>2 {t("Stars")}</th>
                    <th style={{ textAlign: 'center' }}>1 {t("Stars")}</th>
                </tr>
            </thead>
            <tbody>
                {locationReviews && Object.keys(locationReviews).map(key => {
                    return(
                        <tr key={key}>
                            <td data-label="LocationName" className='location_name'>
                                <span onClick={() => navigateTo(locationReviews[key].id)} style={{width: "400px", whiteSpace:"pre-wrap"}}>
                                    {locationReviews[key].full_name}
                                </span>
                            </td>
                            <td style={{ textAlign: 'center' }} data-label="TotalReviews">{locationReviews[key].reviews}</td>
                            <td style={{ textAlign: 'center' }} data-label="RespondedTo">{locationReviews[key].responses}</td>
                            <td style={{ textAlign: 'center' }} data-label="ReviewRating" className='review-rating'>{locationReviews[key].reviewRating} <AiFillStar /> </td>
                            <td style={{ textAlign: 'center' }} data-label="RespondedTo">{locationReviews[key].ireview_reviews_count}</td>
                            <td style={{ textAlign: 'center' }} data-label="RespondedTo">{locationReviews[key].responses_in_percentage}</td>
                            <td style={{ textAlign: 'center' }} data-label="5Stars">{locationReviews[key]["stars5"]}</td>
                            <td style={{ textAlign: 'center' }} data-label="4Stars">{locationReviews[key]["stars4"]}</td>
                            <td style={{ textAlign: 'center' }} data-label="3Stars">{locationReviews[key]["stars3"]}</td>
                            <td style={{ textAlign: 'center' }} data-label="2Stars">{locationReviews[key]["stars2"]}</td>
                            <td style={{ textAlign: 'center' }} data-label="1Stars">{locationReviews[key]["stars1"]}</td> 
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default ReputationTable;
