import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';

import { Alert, Grid, FormControl, MenuItem, Select, Skeleton, Typography, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import MySelect from '../../../Helpers/MySelect';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import { capitalize } from '../../../Helpers/strings';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import { showAlert } from '../../../../redux/actions';
import { fetchConnectionSource, clearConnectionSource, getGroupLocation, getLocationSelected, getGroupSelected } from '../../../../redux/actions/admin-index';
import WebSocketPrompts from "../../WebSocketPrompts";

const SourceSettings = ({ source }) => {
	const dispatch = useDispatch();
	const [loginForm, setLoginForm] = useState(false);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [inUse, setInUse] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [alerts, setAlerts] = useState([]);
	const [selected, setSelected] = useState(" ");
	const [t] = useTranslation();

	const navigate = useNavigate();
	const location = useSelector(state => state.locationSelected.location);
	const selectedloc = useSelector(state => state.groupLocationSelected.group_location)
	const pages = useSelector(state => state.getConnectionSource.connection_source);
	let code = searchParams.get("code");

	const getSourceFunc = (id) => {
		if (code) {
			axios.post(`${baseUrlDev}locations/${id}/connect/${source}`, { token: code }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(() => {
				dispatch(fetchConnectionSource(location, source))
				searchParams.delete('code');
				searchParams.delete('state');
				setSearchParams(searchParams);
			})
		} else {
			dispatch(fetchConnectionSource(id, source))
		}
	}

	useEffect(() => {
		let paramloc = searchParams.get("location")
        if(paramloc){

            dispatch(getLocationSelected("all"))
            dispatch(getGroupSelected(""))
            dispatch(getGroupLocation(paramloc))
    
            localStorage.setItem('group', "");
            localStorage.setItem('location', "all");
            localStorage.setItem("location_of_group", paramloc)
        }
    }, [])

	useEffect(() => {
		dispatch(clearConnectionSource())
        if(selectedloc && (!location || location == "all")){
            getSourceFunc(selectedloc)
        }
        if(location && location !== "all"){
            getSourceFunc(location)
        }
	}, [location, selectedloc])

	useEffect(() => {
		if (pages && pages.data.length > 0) {
			setInUse(pages.data.some(el => el.connected === true || el.connected === 1))
			const connectedPage = pages && pages.data.find(el => el.selected === true || el.selected === 1);
			setSelected(connectedPage ? connectedPage.id : " ");
			setLoginForm(false);
		} else if (pages) {
			setLoginForm(true);
		}
	}, [pages])

	useEffect(() => {
		if (email) setEmailError(false);
		if (password) setPasswordError(false);
	}, [email, password]);

	useEffect(() => {
		setLoginForm(false)
	}, []);

	if (pages === undefined || pages === null) {
		return (
			<Skeleton width={"50%"} height={200} />
		)
	}

	const saveSourceSettings = () => {
		if(selectedloc && (!location || location == "all")){
            saveSourceSettingsFunc(selectedloc)
        }
        if(location && location !== "all"){
            saveSourceSettingsFunc(location)
        }
	}

	const saveSourceSettingsFunc = (location_id) => {
		setLoading(true)
		let id = selected
		if (id) {
			axios.post(`${baseUrlDev}locations/${location_id}/update/${source}`, { id }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then((res) => {
				setLoading(false)
				updatePages()
				cancelConnect(true)
				dispatch(showAlert(true, capitalize(source) + ' Account Connected Successfully.'));
				setTimeout(() => {
					dispatch(showAlert(false, ""));
				}, 5000);
			}).catch((err) => {
				addAlert('error', err.response?.data?.message ?? 'Unexpected Error!')
				setLoading(false)
			})
		} else {
			!id && addAlert('error', 'Please Select A Page.')
		}
	}

	const connectSource = () => {
		if(selectedloc && (!location || location == "all")){
            connectSourceFunc(selectedloc)
        }
        if(location && location !== "all"){
            connectSourceFunc(location)
        }
	}

	const connectSourceFunc = (id) => {
		if (!validateForm()) return;
		setLoading(true)
		const source_email = document.getElementById('emailInput').value;
		const source_password = document.getElementById('passwordInput').value;
		axios.post(`${baseUrlDev}locations/${id}/connect/${source}`, { source_email, source_password }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then((res) => {
			setLoading(false)
			updatePages()
			let action_result = ' Accounts Added';
			if (res.data.connected) {
				cancelConnect(true)
				action_result = ' Account Connected'
			}
			dispatch(showAlert(true, capitalize(source) + action_result + ' Successfully.'));
			setTimeout(() => {
				dispatch(showAlert(false, ""));
			}, 5000);
		}).catch((err) => {
			addAlert('error', err.response?.data?.message ?? 'Unexpected Error!')
			setLoading(false)
		})
	}

	const updatePages = () => {
		dispatch(clearConnectionSource(source));
		dispatch(fetchConnectionSource(location, source))
	}

	const setPage = (value) => {
		setSelected(value)
	}

	const validateForm = () => {
		let isValid = true;
		if (!email) {
			setEmailError(true);
			isValid = false;
		}
		if (!password) {
			setPasswordError(true);
			isValid = false;
		}
		return isValid;
	};

	const cancelConnect = (back = false) => {
		if (pages?.data?.length && !back) {
			setLoginForm(false)
		} else {
			navigate("/app/admin/reviews/connections")
		}
	}

	const addAlert = (type, message) => {
		const newAlert = {
			id: Date.now(),
			type: type,
			message: message
		};
		setAlerts(prev => [...prev, newAlert]);
		setTimeout(() => {
			setAlerts(alerts => alerts.filter(alert => alert.id !== newAlert.id));
		}, 5000);
	}

	const handleCloseAlert = (id) => {
		setAlerts(alerts => alerts.filter(alert => alert.id !== id));
	}

	const footerClass = { marginTop: '10px', textAlign: 'left', color: '#666', fontSize: '12px' };

	return (
		<>
			{alerts && alerts.map((alert_row) => (
				<div className="alert-inside-modal" key={alert_row.id}>
					<Alert
						severity={alert_row.type}
						onClick={() => handleCloseAlert(alert_row.id)}
					>
						{alert_row.message}
					</Alert>
				</div>
			))}
			{!loginForm ? (pages?.data?.length && (
				<div className='account_settings_page'>
					<form action="">
						<Grid container>
							<Grid item xs={10} sm={6} md={5} lg={5} className='flex-header-container-title connections_page'>
								<h1>
									Select from Added Pages:
								</h1>
								<FormControl fullWidth sx={{ marginTop: '16px' }}>
									<Select
										id="input-type-one-select"
										label="position"
										value={selected}
										onChange={(e) => setPage(e.target.value)}
										input={<MySelect />}
									>
										<MenuItem disabled value=" ">
											<em>{t("Select Page")}</em>
										</MenuItem>
										{pages.data.map(page => (
											<MenuItem key={page.id} value={page.id}>{page.name}</MenuItem>
										))}
									</Select>
								</FormControl>
								<Grid container spacing={1} sx={{ marginTop: '6px' }} justifyContent="space-between" alignItems="center">
									<Grid item>
										<Button onClick={() => { cancelConnect(true) }} variant="outlined">
											Cancel
										</Button>
									</Grid>
									<Grid item>
										<LoadingButton
											onClick={() => saveSourceSettings()}
											loading={loading}
											color="primary"
											variant="contained"
											sx={{ color: 'white' }}
										>{t("Save Changes")}
										</LoadingButton>
									</Grid>
								</Grid>
								<Button
									variant="text"
									style={{ textDecoration: 'underline', padding: 0, background: 'none', textTransform: 'none', marginTop: '10px' }}
									onClick={() => { setLoginForm(true) }}>
									Connect A New Page
								</Button>
								{(inUse && pages.data.length > 1) &&
									<div className='foot'>
										<p style={footerClass}>
											* Page(s) marked with asterisk are already connected.
										</p>
									</div>
								}
							</Grid>
						</Grid>
					</form>
				</div>
			)) : (
				<Grid container>
					<Grid item xs={10} sm={6} md={5} lg={5} className='flex-header-container-title connections_page'>
						<h1>
							Login to Your {capitalize(source)} Account
						</h1>
						<TextField
							fullWidth
							label="Email or Username"
							variant="outlined"
							type="text"
							margin="normal"
							id="emailInput"
							value={email}
							onChange={e => setEmail(e.target.value)}
							error={emailError}
							helperText={emailError ? "Email or Username is required" : ""}
						/>
						<TextField
							fullWidth
							label="Password"
							variant="outlined"
							type="password"
							margin="normal"
							id="passwordInput"
							value={password}
							onChange={e => setPassword(e.target.value)}
							error={passwordError}
							helperText={passwordError ? "Password is required" : ""}
						/>
						<p className='nothing-to-show' style={{ marginTop: '10px' }}>{loading ? 'Connecting... This may take a moment.' : ''}</p>
						<Grid container spacing={1} sx={{ marginTop: '6px' }} justifyContent="space-between" alignItems="center">
							<Grid item>
								<Button onClick={cancelConnect} variant="outlined">
									Cancel
								</Button>
							</Grid>
							<Grid item>
								<LoadingButton
									onClick={connectSource}
									loading={loading}
									color="primary"
									variant="contained"
									sx={{ color: 'white' }}
								>
									{t('Connect')}
								</LoadingButton>
							</Grid>
						</Grid>
						{pages?.data?.length ? (
							<Button
								variant="text"
								style={{ textDecoration: 'underline', padding: 0, background: 'none', textTransform: 'none', marginTop: '10px' }}
								onClick={() => { setLoginForm(false) }}>
								Connect A Previously Added Page
							</Button>
						) : <></>}
						<div className='foot'>
							<p style={footerClass}>
								* Your data is encrypted and securely stored. We value your privacy.
							</p>
						</div>
					</Grid>
				</Grid>
			)}
            <WebSocketPrompts />
		</>
	);
}

export default SourceSettings
