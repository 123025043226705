import React, {useState} from 'react';
import MUIDataTable from "mui-datatables";
import Loader from '../../../helpers/loader';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { InputLabel,FormControl,Select,MenuItem,Checkbox, ListItemText } from '@mui/material';

const LocationDatatable = ({locations}) => {
    const [t] = useTranslation()

    if(locations === undefined){
        return(
            <Loader absoluteFlex={true}/>
        )
    }
	const generateUrlPath = (sourceName, location) => {
		const sourcePaths = {
			"Google": "google",
			"Yelp": "yelp",
			"Facebook": "facebook",
			"Tripadvisor": "tripAdvisor",
			"OpenTable": "openTable",
			"Booking.com": "booking.com",
		};

		return `/app/admin/reviews/connections/${sourcePaths[sourceName] || "booking.com"}?location=${location}`;
	};
	const getImageStyle = (sourceName) => {
		return {
			background: sourceName === "Google" || sourceName === "OpenTable" ? "grey" : "",
			borderRadius: "100%",
			marginRight: "10px"
		};
	};
    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTableToolbar: {
                        styleOverrides: {
                            filterPaper: {
                                width: "450px",
                                marginTop: "20px",
                                marginRight: "20px"
                            },
                    },
                },
                MuiList: {
                    styleOverrides: {
                      root: {
                        maxHeight: "300px"
                      },
                    },
                  },
            },
        });
    const columns = [
        {
            name: "Id",
            options: {
                filter: false,
                viewColumns: false,
                display: false,
                sort: false,
            }
        },
        {
            name: t("Company Name"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className='sources-container'>
                        <p className='custom-text-datatable'>{value}</p>
                    </div>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Address"),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className='custom-text-datatable'>{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Group Name"),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className='custom-text-datatable'>{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Connections"),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return(
                        <div style={{display: "flex", alignItems: "flex-start"}}>
                            {value && value.length > 0 && value.map(source => {
                                if(source.isConnected){
                                    return(
                                        <img className="connections-logo-loc" src={source.icon} alt={source.name} style={{marginRight: "10px"}}/>
                                    )
                                } else {
                                    return(
										<Link style={{lineHeight: 0}} to={generateUrlPath(source.name, tableMeta.rowData[0])} className='not-connected-filter'>
											<img className="connections-logo-loc" src={source.icon} alt={source.name} style={getImageStyle(source.name)} />
										</Link>
                                    )
                                }
                            })}

                        </div>
                    )
                },
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            label: 'Connection',
            name: 'connection',
            options: {
              filter: true,
              sort: 'false',
              viewColumns: false,
              sort: false,
              filterType: 'custom',
              customFilterListOptions: {
                render: v => v.map(l => l),
              },
              filterOptions: {
                logic: (location, filters, row) => {
                    const elements = {
                        "Google": row[4].find(item => item.name === "Google"),
                        "Facebook": row[4].find(item => item.name === "Facebook"),
                        "Yelp": row[4].find(item => item.name === "Yelp"),
                        "OpenTable": row[4].find(item => item.name === "OpenTable"),
                        "Tripadvisor": row[4].find(item => item.name === "Tripadvisor"),
                        "Booking.com":  row[4].find(item => item.name === "Booking.com")
                    };
                    
                    if(filters.length == 0){
                        return false
                    }

                    let connectedCount = 0;

                    for (const filter of filters) {
                        const element = elements[filter];
                       
                        if (element && element.isConnected) {
                            connectedCount++;
                        }
                    }
                    if(connectedCount === filters.length){
                        return false
                    }
            
                    return true;
                },
                display: (filterList, onChange, index, column) => {
                  const optionValues = ['Facebook', 'Google', 'Yelp', 'OpenTable', 'TripAdvisor', 'Booking.com'];
                  return (
                    <FormControl variant="standard" >
                      <InputLabel htmlFor='select-multiple-chip'>
                        Connections
                      </InputLabel>
                      <Select
                        multiple
                        value={filterList[index]}
                        renderValue={selected => selected.join(', ')}
                        onChange={event => {
                          filterList[index] = event.target.value;
                          onChange(filterList[index], index, column);
                        }}
                      >
                        {optionValues.map(item => (
                          <MenuItem key={item} value={item}>
                            <Checkbox
                              color='primary'
                              checked={filterList[index].indexOf(item) > -1}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }
              },
              display: false,
            }
          },
        {
            name: t("Review URL"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <a href={value} style={{ textDecoration: "none", color: "#00B4E5" }} target="_blank"><p className="custom-text-datatable" style={{ color: "#00B4E5" }}>{value}</p></a>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Edit"),
            options: {
                filter: false,
                viewColumns: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="mydev-datatable-actions" style={{ justifyContent: 'flex-start' }}>
                            <Link to={`/app/admin/locations/information/${tableMeta.rowData[0]}`} className='edit-btn'>
                                <span className="icon-mydevicon-edit-bordered"></span>
                                {t("Edit")}
                            </Link>
                        </div>
                    )
                }
            }
        },
    ];

    const options = {
        search: true,
        download: true,
        print: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: 'multiselect',
        responsive: "simple",
        rowsPerPageOptions: [25, 50, 100, 200],
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onRowsDelete: (rowsDeleted) => {
            const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex][0]); // array of all ids to to be deleted
            console.log(idsToDelete)
        }
    };

    const data = locations.data.map(item => {
        return [item.id, item.company_name, item.address, item.group, item.platforms, [], item.review_url];
    });

    return (
        <div className='new-datatable listing_datatable'>
             <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={data}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
        </div>
    )
}

export default LocationDatatable;