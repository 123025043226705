import React, { useState, useEffect } from 'react';
import { Alert, FormControl, Grid, Grow, MenuItem, Select } from '@mui/material';
import MySelect from '../../helpers/mySelect';
import { LoadingButton } from '@mui/lab';
import HandleFileUpload from '../../helpers/handleFileUpload';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import axios from 'axios';
import CustomAlertIcon from '../../helpers/CustomAlertIcon'
import { useTranslation } from 'react-i18next';


const SupportForm = () => {
    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        description: '',
        priority: '',
        attachments: null
    });
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [subjectError, setSubjectError] = useState('');
    const {t} = useTranslation()
    let timeAlertId = '';

    const handelOnChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormData((prevFormData) => ({ ...prevFormData, attachments: file }));
        setFile(URL.createObjectURL(file));
    }

    const handleSubmit = () => {
        setLoading(true);
        const dataForm = new FormData();
        dataForm.append('email', formData?.email);
        dataForm.append('subject', formData?.subject);
        dataForm.append('description', formData?.description);
        dataForm.append('priority', formData?.priority);
        dataForm.append('attachments', formData?.attachments);

        if(formData?.subject?.length > 255) {
            setSubjectError('Subject cannot exceed 255 characters');
            return;
        }
        axios.post(`${baseUrlDev}support`, dataForm,
            { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                if (res.status === 200) {
                    setShowAlert(true);
                    setLoading(false);
                    setAlertText(res.data.message);
                    setFormData({ email: '', subject: '', priority: '', attachments: null, description: '' });
                    setFile(null);
                    setTimeout(() => {
                        setShowAlert(false)
                    }, 5000)
                }
            }).catch(err => {
                setLoading(false);
            });

        timeAlertId = setTimeout(() => {
            setShowAlert(false);
        }, 5000);
    }

    useEffect(() => {
        return () => {
            clearTimeout(timeAlertId)
        }
    }, [])

    return (
        <div>
            <Grow in={showAlert}><Alert severity="success" className="fixed-alert-right" iconMapping={{ success: <CustomAlertIcon /> }}>{alertText}</Alert></Grow>
            <form encType="multipart/form-data">
                <div className="form-wrapper">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <div className="form-group">
                                <label>{t("Email")}</label>
                                <input
                                    name="email"
                                    type="text"
                                    className='form-control'
                                    placeholder={t("Email")}
                                    value={formData?.email}
                                    onChange={(e) => handelOnChange('email', e.target.value)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="form-group">
                                <label>{t('Subject')}</label>
                                <input
                                    name="subject"
                                    type="text"
                                    className='form-control'
                                    maxLength={255}
                                    placeholder={t('Subject')}
                                    value={formData?.subject}
                                    onChange={(e) => handelOnChange('subject', e.target.value)}
                                />
                                {subjectError && <div className="error-message">{subjectError}</div>}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label htmlFor="" className='label_widget_setup'>{t("Priority")}</label>
                            <FormControl fullWidth>
                                <Select
                                    id="text_font-select"
                                    label="position"
                                    name="priority"
                                    value={formData?.priority}
                                    onChange={(e) => handelOnChange('priority', e.target.value)}
                                    input={<MySelect />}
                                    sx={{
                                        '& .MuiSelect-select': {
                                            border: '1px solid #F3F3F3',
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <MenuItem value={'low'}>{t("Low")}</MenuItem>
                                    <MenuItem value={'normal'}>{t("Normal")}</MenuItem>
                                    <MenuItem value={'high'}>{t("High")}</MenuItem>
                                    <MenuItem value={'urgent'}>{t("Urgent")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div className="form-group">
                                <label htmlFor="">{t("Attachments")}</label>
                                <HandleFileUpload file={file} handleFileChange={handleFileChange} />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <div className="form-group">
                                <label>{t("Description")}</label>
                                <textarea
                                    name="description"
                                    className='form-control'
                                    value={formData?.description}
                                    onChange={(e) => handelOnChange('description', e.target.value)}
                                ></textarea>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <LoadingButton
                        style={{ paddingLeft: loading && "30px" }}
                        sx={{
                            '& .MuiLoadingButton-loadingIndicator': {
                                color: '#fff',
                            }
                        }}
                        className="save-btn"
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        {t("Save")}
                    </LoadingButton>
                </div>
            </form>
        </div>
    )
}

export default SupportForm;
