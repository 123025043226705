import React, {useEffect, useState} from "react";
import { Modal, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import Alert from '@mui/material/Alert';
import {getHeaderLocalUser} from "../../TokenControl/parts/useLocalUser";
import { useTranslation } from "react-i18next";

//Our imports
import MySelect from "../../Helpers/MySelect"
import { baseUrl } from "../../Helpers/baseUrl";
import { fetchForms, formDetailsClear, showAlert} from "../../../redux/actions";
import { RoundedSwitch } from "../helpers/switch";
import { useSelector } from "react-redux";
import Loader from "../../Admin/helpers/loader";


const EditForm = ({showEdit, setShowEdit, form_id}) => {
    const dispatch = useDispatch()
    let [name, setName] = useState('')
    let [type, setType] = useState('thank you')
    let [redirect, setRedirect] = useState('')
    let [email, setEmail] = useState('')
    let [error, setError] = useState(false)
    let [message, setMessage] = useState('')
    let [active, setActive] = useState(1)
    let [logo, setLogo] = useState('')
    let [logoPrev, setLogoPrev] = useState('')
    let [footer, setFooter] = useState('')
    let [webhook, setWebhook] = useState('')
    let [loading, setLoading] = useState(false)
    const form_details = useSelector(state => state.getFormDetails.form)
    const {t} = useTranslation()

    useEffect(() => {
        if(form_details && form_details.data){
            setName(form_details.data.name ? form_details.data.name : '')
            setType(form_details.data.submission_action ? form_details.data.submission_action : "thank you")
            setRedirect(form_details.data.form_redirect_link ? form_details.data.form_redirect_link : '')
            setEmail(form_details.data.accept_email ? form_details.data.accept_email : '')
            setActive(form_details.data.status !== undefined ? form_details.data.status : 1)
            setMessage(form_details.data.message ? form_details.data.message : '')
            setLogo(form_details.data.logo ? form_details.data.logo : '')
            setLogoPrev(form_details.data.logo ? form_details.data.logo : '')
            setFooter(form_details.data.footer ? form_details.data.footer : '')
            setWebhook(form_details.data.webhook ? form_details.data.webhook : '')
            setLoading(false)
        } else {
            setLoading(true)
            setName('')
            setType("thank you")
            setRedirect('')
            setEmail('')
            setActive(1)
            setMessage('')
            setLogo('')
            setLogoPrev('')
            setWebhook('')
            setFooter('')
        }
    }, [form_details])

    const handleCloseEdit = () => {
        setShowEdit(false)
    }

    const handleTypeChange = (e) => {
        setType(e.target.value)
    }

    const logoChange = (e) => {
        const files = Array.from(e.target.files)
        setLogoPrev(URL.createObjectURL(files[0]))
        setLogo(files[0])
  }

    const editForm = () => {
        var formData = new FormData();
        formData.append("identifier", 'test')
        formData.append("name", name)
        formData.append("submission_action", type)
        formData.append("email", email)
        formData.append("form_redirect_link", redirect)
        formData.append("message", message)
        formData.append("status", active ? 1  : 0)
        formData.append("logo", logo)
        formData.append("footer", footer)
        formData.append("webhook", webhook)
        if(!name || !email){
            setError(true)
        } else {
            setError(false)
            axios.post(`${baseUrl}forms/form/${form_id}`, formData,{
                headers: {...getHeaderLocalUser()}
            }).then(res => {
                setShowEdit(false)
                dispatch(fetchForms())
                dispatch(showAlert(true, "Form Edited Successfully"))
                setTimeout(() => {
                    dispatch(showAlert(false, ""))
                }, 5000)
            })
        }
   
    }

    return(
        <Modal 
            open={showEdit}
            onClose={handleCloseEdit}
        >
            <div className="manage-inputs">
                {loading &&  <Loader absoluteFlex={true}/>}
                <div className="manage-inputs-header">
                    <h6>{t("Edit Form")}</h6>
                </div>
                {error ? <Alert severity="error" className="custom-error-alert">{t("Please fill required fields")}</Alert> : ''}
                <div className="manage-inputs-body manage-inputs-body-form custom-scrollbar-vertical">
                    <div className="label-field-container">
                        <div>
                            <label>{t("Form Logo")}</label>
                            <div className="form-logo-container">
                                <img src={logoPrev ? logoPrev : "/admin-images/no-data.svg"} />
                                <div>
                                    <Button
                                        component="label"
                                        >
                                        {t("Upload File")}
                                        <input
                                            onChange={(e) => logoChange(e)}
                                            type="file"
                                            accept="image/png, image/svg, image/jpeg, image/jpg"
                                            hidden
                                        />
                                    </Button>                            
                                </div>
                            </div>
                            <span>{t("Add your form logo")}</span>
                        </div>
                        <div>
                            <label>{t("Form Name")}</label>
                            <input type="text" name="name" placeholder={t("Add Form Name")} value={name} onChange={(e) => setName(e.target.value)}/>
                            <span>{t("Give your form a name")}</span>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="form-method">{t("Form Submission Method")}</InputLabel>
                                <Select
                                    labelId="form-method"
                                    id="form-method-select"
                                    value={type}
                                    label="position"
                                    input={<MySelect/>}
                                    onChange={handleTypeChange}
                                >
                                    <MenuItem value={'thank you'}>{t("Thank You Page")}</MenuItem>
                                    <MenuItem value={'redirect'}>{t("Redirect")}</MenuItem>
                                </Select>
                            </FormControl>
                            <span>{t("Select which action to happen after submit")}</span>
                        </div>
                        {type === "redirect" ? 
                            <div>
                                <label>{t("Redirect")}</label>
                                <input value={redirect} type="text" name="redirect" placeholder={t("Redirect Link")} onChange={(e) => setRedirect(e.target.value)}/>
                                <span>{t("Provide a URL path for redirecting")}</span>
                            </div>
                            :
                            <div>
                                <label>{t("Thank you message")}</label>
                                <input value={message} type="text" name="thankyou" placeholder={t("Enter Thank you Message")} onChange={(e) => setMessage(e.target.value)}/>
                                <span>{t("Enter Thank you Message")}</span>
                            </div>
                        }
                        <div>
                            <label>{t("Email")}</label>
                            <input value={email} type="email" name="email" placeholder={t("Enter Email")} onChange={(e) => setEmail(e.target.value)}/>
                            <span>{t("Enter a valid email")}</span>
                        </div>
                        <div>
                            <label>{t("Footer")}</label>
                            <input value={footer} type="text" name="footer" placeholder={t("Enter Footer Text")} onChange={(e) => setFooter(e.target.value)}/>
                            <span>{t("Enter Footer Text")}</span>
                        </div>
                        <div>
                            <label>Webhook</label>
                            <input value={webhook} type="text" name="webhook" placeholder={t("Add Webhook")} onChange={(e) => setWebhook(e.target.value)}/>
                            <span>{t("Enter full web URL (including https)")}</span>
                        </div>
                        <div>
                            <label>{t("Form Status")}</label>
                            <RoundedSwitch
                                checked={active === 1 ? true : false}
                                onChange={(e) =>  setActive(e.target.checked ?  1  : 0)}
                            />
                            <span>{t("Make form active or unactive")}</span>
                        </div>
                   
                    </div>
                </div>
                <div className="manage-inputs-footer">
                    <button className="button-cancel-grey" onClick={() => handleCloseEdit()}>{t("Cancel")}</button>
                    <button className="button-submit-blue" onClick={() => editForm()}>{t("Save Changes")}</button>
                </div>
            </div>
        </Modal>
    )
}
export default EditForm