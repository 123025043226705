import React, {Suspense} from "react"
import { Grid, IconButton } from "@mui/material"
import Colorpicker from "../../../modals/tags/Colorpicker";
import { IoMdInformationCircleOutline } from "react-icons/io"
import LightTooltip from "../../../helpers/lightTooltip";
import { useTranslation } from "react-i18next";
const ReactQuill = React.lazy(() => import("./reactQuillComponent"))

const Step1 = ({ handleNext, formData, handleQuillChange, errors, setErrors, setFormData }) => {
    const {t} = useTranslation()
    const changeBackgroundColor = (color) => {
        setFormData({...formData, "background_color": color});
        setErrors({ ...errors, 'background_color': '' })
    }

    const changeBorderColor = (color) => {
        setFormData({...formData, "border_color": color});
        setErrors({ ...errors, 'border_color': '' });
    }

    return(
        <div className="form-step">
            <div className="steps-content">
                <div className="steps-content-details">
                    <div className="icon_label_wrapper">
                        <label htmlFor="">{t("Title")}</label>
                        <LightTooltip title={t("Title content character limit: 255")}>
                            <IconButton className="qr-cood-tool-tip">
                                <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                            </IconButton>
                        </LightTooltip>
                    </div>

                    <Suspense fallback="test">
                        <ReactQuill formData={formData} handleQuillChange={handleQuillChange} name="title"/>
                    </Suspense>
                    <p className='step_error_text'>{errors?.title}</p>
                </div>

                <div className="steps-content-details">
                    <div className="icon_label_wrapper">
                        <label htmlFor="">{t("Subtitle")}</label>
                        <LightTooltip title={t("Subtitle content character limit: 255")}>
                            <IconButton className="qr-cood-tool-tip">
                                <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                            </IconButton>
                        </LightTooltip>
                    </div>
                    <Suspense fallback="test">
                        <ReactQuill formData={formData} handleQuillChange={handleQuillChange} name="subtitle"/>
                    </Suspense>
                    <p className='step_error_text'>{errors?.subtitle}</p>
                </div>

                <div className="steps-content-details">
                    <div className="icon_label_wrapper">
                        <label htmlFor="">{t("Description")}</label>
                        <LightTooltip title={t("Description content character limit: 255")}>
                            <IconButton className="qr-cood-tool-tip">
                                <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                            </IconButton>
                        </LightTooltip>
                    </div>
                    <Suspense fallback="test">
                        <ReactQuill formData={formData} handleQuillChange={handleQuillChange} name="description"/>
                    </Suspense>
                    <p className='step_error_text'>{errors?.description}</p>
                </div>
            </div>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <div className="steps-content-details">
                        <label htmlFor="">{t("Background Color")}</label>
                        <Colorpicker color={formData.background_color} setColor={changeBackgroundColor} />
                        <p className='step_error_text'>{errors?.background_color}</p>
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <div className="steps-content-details">
                        <label htmlFor="">{t("Border Color")}</label>
                        <Colorpicker color={formData.border_color} setColor={changeBorderColor} />
                        <p className='step_error_text'>{errors?.border_color}</p>
                    </div>
                </Grid>
            </Grid>

            <div className="btns-group d-flex align-items-center justify-content-end">
                <a className='btn btn-next' onClick={handleNext}>{t("Next")}</a>
            </div>

        </div>
    )
}
export default Step1