import React from 'react';
import ConnectionAlert from './ConnectionAlert';
import FacebookSettings from './FacebookSettings';
import GoogleSettings from './GoogleSettings';
import SourceSettings from './SourceSettings';
import { useParams } from 'react-router-dom';
import Header from '../../../Helpers/header';
import InnerDropdownContainer from '../../parts/InnerDropdownContainer';

const ConnectionsSettings = () => {
  const { id } = useParams();
  let subtitle, settingsComponent;

  switch (id) {
    case "google":
      subtitle = "Connect Google Account";
      settingsComponent = <GoogleSettings />;
      break;
    case "facebook":
      subtitle = "Connect Facebook Account";
      settingsComponent = <FacebookSettings />;
      break;
    case "yelp":
      subtitle = "Connect Yelp Account";
      settingsComponent = <SourceSettings source={id} />;
      break;
    case "openTable":
      subtitle = "Connect OpenTable Account";
      settingsComponent = <SourceSettings source={id} />;
      break;
    case "tripAdvisor":
      subtitle = "Connect TripAdvisor Account";
      settingsComponent = <SourceSettings source={id} />;
      break;
    case "booking.com":
    default:
      subtitle = "Connect Booking.com Account";
      settingsComponent = <SourceSettings source={id} />;
      break;
  }

  return (
    <div className='connections_settings_page'>
      <div className='table-header-container'>
        <Header title="Reviews" subtitle={subtitle} />
      </div>
      <InnerDropdownContainer disabled={true} />
      <div>
        <ConnectionAlert />
      </div>
      <div>
        {settingsComponent}
      </div>
    </div>
  )
}

export default ConnectionsSettings;
