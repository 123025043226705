import React from "react"
import { Grid, IconButton, Skeleton } from "@mui/material"
import LightTooltip from "../../helpers/lightTooltip"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { AiFillStar } from "react-icons/ai"
import CountUp from 'react-countup';
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const SourcesWidget  = ({sourcesWidget}) => {
    const [t] = useTranslation()

    if(!sourcesWidget){
        return(
            <Grid container spacing={2} style={{marginTop: "20px", marginBottom: "20px"}}>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
            </Grid>
        )
    }

    return(
        <Grid container spacing={2} className="box-mt">
            {sourcesWidget && Object.keys(sourcesWidget).map((key) => {
                if(sourcesWidget[key].blur){
                    return(
                        <Grid item xs={12} md={6} xl={3} key={`widget${key}`}>
                            <div className="dashboard-box-containers-bottom">
                                <LightTooltip title={t(`${key}-total-amount`)}>
                                    <IconButton className="tooltip-btn-holder">
                                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                    </IconButton>
                                </LightTooltip>
                                <div className="source-widget-top">
                                    <img src={sourcesWidget[key].logo} alt={key} />
                                </div>
                                <div className="source-widget-infos source-widget-infos-blured">
                                    <div>
                                        <CountUp 
                                            end={0} 
                                            duration={1}
                                        />
                                        <h6>{t("Reviews")}</h6>
                                    </div>
                                    <div>
                                        <div className="star-title-container">
                                            <CountUp 
                                                end={0} 
                                                duration={0}
                                            />
                                        <AiFillStar/>
                                        </div>
                                        <h6>{t("Rating")}</h6>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    )
                } else {
                    return(
                        <Grid item xs={12} md={6} xl={3} key={`widget${key}`}>
                            <div className="dashboard-box-containers-bottom">
                                <LightTooltip title={t(`${key}-total-amount`)}>
                                    <IconButton className="tooltip-btn-holder">
                                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                    </IconButton>
                                </LightTooltip>
                                <div className="source-widget-top">
                                    <img src={sourcesWidget[key].logo} alt={key} />
                                    <h6>{key}</h6>
                                </div>
                                {sourcesWidget[key].connect ? 
                                <div>
                                    <div className="source-widget-infos">
                                        <div>
                                            <CountUp 
                                                end={sourcesWidget[key].reviews} 
                                                duration={1}
                                            />
                                            <h6>{t("Reviews")}</h6>
                                        </div>
                                        <div>
                                            <div className="star-title-container">
                                                {sourcesWidget[key].rating % 1 === 0 ? 
                                                <CountUp 
                                                    end={sourcesWidget[key].rating} 
                                                    duration={1}
                                                />
                                                : <CountUp
                                                    end={sourcesWidget[key].rating} 
                                                    duration={1}
                                                    decimals={1}
                                                    decimal="."
                                                />}
                                            <AiFillStar/>
                                            </div>
                                            <h6>{t("Rating")}</h6>
                                        </div>
                                    </div>
                                    <Link to="/app/admin/reviews/connections" className="connect-to-platform">{t(`Connect to ${key}`)}</Link>
                                </div>
                                :
                                <div className="source-widget-infos">
                                    <div>
                                        <CountUp 
                                            end={sourcesWidget[key].reviews} 
                                            duration={1}
                                        />
                                        <h6>{t("Reviews")}</h6>
                                    </div>
                                    <div>
                                        <div className="star-title-container">
                                            {sourcesWidget[key].rating % 1 === 0 ? 
                                            <CountUp 
                                                end={sourcesWidget[key].rating} 
                                                duration={1}
                                            />
                                            : <CountUp
                                                end={sourcesWidget[key].rating} 
                                                duration={1}
                                                decimals={1}
                                                decimal="."
                                            />}
                                        <AiFillStar/>
                                        </div>
                                        <h6>{t("Rating")}</h6>
                                    </div>
                                </div>
                                }
                            </div>
                        </Grid>
                    )
                }
                
            })}
    
        </Grid>
    )
}
export default SourcesWidget