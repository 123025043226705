import React, {useState} from "react"
import { Collapse } from "@mui/material"
import {IoIosCheckmark} from "react-icons/io"
import {MdOutlineKeyboardArrowDown} from "react-icons/md"
import {AiFillEdit, AiOutlineEdit} from "react-icons/ai"
import { useTranslation } from "react-i18next"

const LocationItem = ({loc, sub, checked, setChecked, open, setOpen, details, setDetails, setSubscriptionId, aLaCarteItems, setIsPrimary, setLocId, setHashId}) => {
    const [locCollapse, setLocCollapse] = useState(false)
    const {t} = useTranslation()
    const selectLocations = (loc) => {
        let value = loc.id
        if(!checked.some(el => el.id == value)){
             setChecked(prev => [...prev, loc])
        } else {
             setChecked(checked.filter(el => el.id !== loc.id))
        }
     }

    const collapseDetails = () => {
        setLocCollapse(!locCollapse)
    }

    const changeCardInfo = (loc) => {
        setDetails({...details, ["card_number"] : loc.card})
        setSubscriptionId(loc.subscription_id)
        setLocId(loc.id)
        setHashId(loc.card_id)
        setOpen(true)
    }

    return(
        <li key={`${loc.irevu_companyName}-${loc.id}`} className={loc.type === "Demo" ? "blueCheckbox" : "greenCheckbox"}>
            <div className={locCollapse ? "rotate-span" : ""}>
                <div>
                    {loc.type === "Full Service" ?
                    <input type="checkbox" value={loc.id}  id={`${loc.irevu_companyName}-${loc.id}`} checked={true} disabled={true}/>
                    : <input type="checkbox" value={loc.id}  id={`${loc.irevu_companyName}-${loc.id}`} onChange={(e) => selectLocations(loc)} checked={checked.some(el => el.id == parseInt(loc.id))}/>
                    }
                    <label htmlFor={`${loc.irevu_companyName}-${loc.id}`}>
                        <span><IoIosCheckmark/></span>
                        <p>{loc.irevu_companyName}-{loc.address}</p>
                    </label> 
                    {loc.type !== "Demo" &&  
                        <span className="collapse-details-sub" onClick={() => collapseDetails()}><MdOutlineKeyboardArrowDown/></span> 
                    }
                </div>
                {loc.type && <h6 className={loc.type === "Demo" ? "redSpan" : "greenSpan"}>{t(loc.type)}</h6> }
            </div>
            <Collapse in={locCollapse}>
                <div className="subs-details-wrapper">
                    {loc.type !== "Demo" &&
                        !loc.reference_no && !loc.card && !loc.next_payment ? "Nothing To Show" : 
                        <>
                            {loc.reference_no && <div><span>Ref:</span>{loc.reference_no}</div>}
                            {loc.card && <div className="edit-card-wrapper"><span>Card Number:</span>{loc.card}<AiOutlineEdit onClick={() => changeCardInfo(loc)}/></div>}
                            {/* {loc.card && <div className="edit-card-wrapper"><span>Card Number:</span>{loc.card}</div>} */}
                            {loc.next_payment && <div><span>Next Payment:</span> {loc.next_payment}</div>}
                            {(loc.la_carte && loc.type == "À La Carte") && <div><span>A La Carte items:</span>
                            {
                                JSON.parse(loc.la_carte).map(el => {
                                    let filteredItem = aLaCarteItems.filter(item => item.value == el);
                                    return filteredItem[0].name
                                }).join(" , ")
                            }
                            </div>}
                        </>
                    }
                </div>
            </Collapse>
        </li>
    )
}
export default LocationItem