import React from 'react';
import { useTranslation } from 'react-i18next';

const ProgressBoxDetail = ({locList}) => {
    let accurate = locList.accuracy.accurate_percentage.replace(/\s/g, '')
    let inaaccurate = locList.accuracy.inaccurate_percentage.replace(/\s/g, '')
    let notfound = `${100 - (parseFloat(accurate) + parseFloat(inaaccurate))}%`
    const {t} = useTranslation()
    return (
        <div className='listing_score_box2 progress_box'>
            <div className='bar-three-values'>
                <div style={{width: accurate}} className="bar bar-colorPrimary"></div>
                <div style={{width: inaaccurate}} className="bar bar-colorSecondary"></div>
                <div style={{width: notfound}} className="bar bar-colorThird"></div>
            </div>
            <div className="wrapper">
                <div>
                    <h6 className="text_success">
                        {locList.accuracy.accurate && locList.accuracy.accurate}
                        <span>({locList.accuracy.accurate_percentage && locList.accuracy.accurate_percentage})</span>
                    </h6>
                    <span className='small_text'>{t("Accurate")}</span>
                </div>
                <div>
                    <h6 className="text_warning">
                        {locList.accuracy.inaccurate && locList.accuracy.inaccurate}
                        <span>({locList.accuracy.inaccurate_percentage && locList.accuracy.inaccurate_percentage})</span>
                    </h6>
                    <span className='small_text'>{t("Inaccurate")}</span>
                </div>
            </div>
        </div>
    )
}

export default ProgressBoxDetail;