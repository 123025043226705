import React, {useEffect, useState} from "react"
import { FormControl, Select, MenuItem, ClickAwayListener, Grid } from "@mui/material"
import MySelect from "../../helpers/mySelect"
import { useTranslation } from "react-i18next";
import { es, it, de, fr, enUS } from "date-fns/locale";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { DateRangePicker, defaultStaticRanges, defaultInputRanges  } from 'react-date-range';
import { formatDate, formatDateLine } from "../../helpers/formatDate";
import {useDispatch, useSelector} from "react-redux"
import { useSearchParams } from 'react-router-dom';

import { clearFilters, clearReviews, memorizeLastReview, fetchAllLocationSources, searchReviewKeyword, reviewSourcesFilter, paginationReviews, reviewRatingsFilter, reviewStatusFilter, reviewRangeFilter, fetchLocationSources } from "../../../../redux/actions/admin-index";

const FilterBoxes = ({setShowCheckboxes, showCheckboxes}) => {
    const dispatch = useDispatch()
    const [disableButton, setDisableButton] = useState(true)
    const [keywordState, setKeyword] = useState('')
    const [showRange, setShowRange] = useState(false)
    const [reviews, setReviews] = useState(["all sources"])
    const [ratings, setRatings] = useState(["all ratings"])
    const [statuses, setStatuses] = useState(["all statuses"])
    const [selectionRange, setSelectionRange] =  useState([
        {
            startDate: null,
            endDate: null,
            key: 'selection'
        }
    ]);
    const [searchParams, setSearchParams] = useSearchParams();
    let backTo = searchParams.get("backTo")
    const [t] = useTranslation()
    const groupSelected = useSelector(state => state.groupSelected.group)
    const location = useSelector(state => state.locationSelected.location)
    const reviewsList = useSelector(state => state.getReviews.reviews)

    const keyword = useSelector(state => state.reviewKeyword.keywords)
    const sourcesFilter = useSelector(state => state.reviewSourceFilter.source)
    const status = useSelector(state => state.reviewStatusFilter.status)
    const rating = useSelector(state => state.reviewRatingFilter.rating)
    const from = useSelector(state => state.reviewRangeFilter.dateFrom)
    const to = useSelector(state => state.reviewRangeFilter.dateTo)
    const tools = useSelector(state => state.allLocationSources.tools)
    const sources = tools && tools.sources

    const language = useSelector(state => state.getLanguage.language)

    const removeMemoriezedReviewOnFilter = () => {
        dispatch(memorizeLastReview(""))
    }

    useEffect(() => {
        if((location && location !== "all") || groupSelected){
            dispatch(fetchAllLocationSources(location, groupSelected))
        } else {
            dispatch(fetchAllLocationSources())

        }
    }, [location])

    useEffect(() => {
        if(reviewsList){
            setDisableButton(false)
        }
    }, [reviewsList])

    useEffect(() => {
        if(!backTo){
            dispatch(clearFilters())
            dispatch(clearReviews())
            setRatings(['all ratings']);
            setStatuses(['all statuses']);
            setReviews(['all sources']);
            setSelectionRange([  {
                startDate: null,
                endDate: null,
                key: 'selection'
            }]);
            setKeyword("") 
        }
    }, [backTo])

    useEffect(() => {
        if(backTo){
            if(!rating){
                setRatings(['all ratings']);
            } else {
                setRatings(rating);
            }
            if(!status){
                setStatuses(['all statuses']);
            } else {
                setStatuses(status);
            }
            if(!sourcesFilter){
                setReviews(['all sources']);
            } else {
                setReviews(sourcesFilter);
            }

            if(!from){
                setSelectionRange([  {
                    startDate: null,
                    endDate: null,
                    key: 'selection'
                }]);
            } else {
                setSelectionRange([  {
                    startDate: new Date(from),
                    endDate: new Date(to),
                    key: 'selection'
                }]);
            }
 
            if(!keyword){
                setKeyword("")
            } else {
                setKeyword(keyword)
            }
        }
 
    }, [backTo, rating, status, sourcesFilter, from, to , keyword])



    const handleChangeReviews = (e) => {
        dispatch(paginationReviews(1))
        const value = e.target.value
        if(value[value.length - 1] === "all sources"){
            setReviews(['all sources']);
            dispatch(reviewSourcesFilter(''))
        } else {
            setReviews(value.filter(el => el !== "all sources"))
            dispatch(reviewSourcesFilter(value.filter(el => el !== "all sources")))
        }
        removeMemoriezedReviewOnFilter()
    }

    const handleChangeRatings = (e) => {
        dispatch(paginationReviews(1))
        const value = e.target.value
        if(value[value.length - 1] === "all ratings"){
            setRatings(['all ratings']);
            dispatch(reviewRatingsFilter(''))
        } else {
            setRatings(value.filter(el => el !== "all ratings"))
            dispatch(reviewRatingsFilter(value.filter(el => el !== "all ratings")))
        }
        removeMemoriezedReviewOnFilter()
    }

    const handleChangeStatuses = (e) => {
        dispatch(paginationReviews(1))
        const value = e.target.value
        if(value[value.length - 1] === "all statuses"){
            setStatuses(['all statuses']);
            dispatch(reviewStatusFilter(''))
        } else {
            setStatuses(value.filter(el => el !== "all statuses"))
            dispatch(reviewStatusFilter(value.filter(el => el !== "all statuses")))
        }
        removeMemoriezedReviewOnFilter()
    }
    const handleDateChange = (item) => {
        dispatch(paginationReviews(1))
        setSelectionRange([item.selection])
        let itemSerialized = {
            startDate: formatDateLine(item.selection.startDate),
            endDate: formatDateLine(item.selection.endDate)
        }
        dispatch(reviewRangeFilter(itemSerialized))
        removeMemoriezedReviewOnFilter()
    }

    const handleKeywordChange = (e) => {
        let {value} = e.target
        setKeyword(value)
        if(value.length > 3){
            dispatch(paginationReviews(''))
            dispatch(searchReviewKeyword(value))
        } else if(value.length == 0){
            dispatch(searchReviewKeyword(''))
        }
        removeMemoriezedReviewOnFilter()
    }    
    return(
        <Grid container spacing={2} className="filter-container">
            <Grid item xs={12} md={4} lg={12/6}>
                <div>
                    <input value={keywordState} type="text" placeholder={t("Search Keywords")} onChange={(e) => handleKeywordChange(e)}/>
                </div>
            </Grid>
            <Grid item xs={12} md={4} lg={12/6}>
                <div>
                    <FormControl fullWidth>
                        <Select
                            name="one"
                            multiple
                            id="input-type-one-select"
                            value={statuses}
                            label="position"
                            input={<MySelect/>}
                            onChange={handleChangeStatuses}
                            MenuProps={{ autoFocus: false, PaperProps: { sx: { maxHeight: 300 } } }}

                        >
                            <MenuItem value={"all statuses"}>{t("All Statuses")}</MenuItem>
                            <MenuItem value={'responded'}>{t("Responded")}</MenuItem>
                            <MenuItem value={'not-responded'}>{t("Not")} {t("Responded")}</MenuItem>
                            <MenuItem value={'pinned'}>{t("Pinned")}</MenuItem>
                            <MenuItem value={'hidden'}>{t("Hidden")}</MenuItem>
                            <MenuItem value={'pending '}>{t("Pending")}</MenuItem>
                        </Select>
                    </FormControl> 
                </div>
            </Grid>
            <Grid item xs={12} md={4} lg={12/6}>
                <div>
                    <FormControl fullWidth>
                        <Select
                            name="one"
                            multiple
                            id="input-type-one-select"
                            value={reviews}
                            label="position"
                            input={<MySelect/>}
                            onChange={handleChangeReviews}
                            MenuProps={{ autoFocus: false, PaperProps: { sx: { maxHeight: 300 } } }}

                        >
                            <MenuItem value={"all sources"}>{t("All Sources")}</MenuItem>
                            {sources && sources.map(source => {
                                return(
                                    <MenuItem value={source.name} key={`filter-source${source.id}`}>{source.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl> 
                </div>
            </Grid>
            {/* <Grid item xs={12} md={4} lg={12/6}>
                <div>
                    <FormControl fullWidth>
                        <Select
                            name="sort"
                            id="input-type-one-select"
                            value={order}
                            label="position"
                            input={<MySelect/>}
                            onChange={handleOrderChange}
                            MenuProps={{ autoFocus: false, PaperProps: { sx: { maxHeight: 300 } } }}

                        >
                            <MenuItem value={"desc"}>Latest</MenuItem>
                            <MenuItem value={"asc"}>Oldest</MenuItem>
                        </Select>
                    </FormControl> 
                </div>
            </Grid> */}
            <Grid item xs={12} md={4} lg={12/6}>
                <div>
                    <FormControl fullWidth>
                        <Select
                            name="one"
                            multiple
                            id="input-type-one-select"
                            value={ratings}
                            label="position"
                            input={<MySelect/>}
                            onChange={handleChangeRatings}
                            MenuProps={{ autoFocus: false, PaperProps: { sx: { maxHeight: 300 } } }}
                            >
                            <MenuItem value={"all ratings"}>{t("All Ratings")}</MenuItem>
                            <MenuItem value={'5'}>5 {t("Stars")}</MenuItem>
                            <MenuItem value={'4'}>4 {t("Stars")}</MenuItem>
                            <MenuItem value={'3'}>3 {t("Stars")}</MenuItem>
                            <MenuItem value={'2'}>2 {t("Stars")}</MenuItem>
                            <MenuItem value={'1'}>1 {t("Star")}</MenuItem>
                        </Select>
                    </FormControl> 
                </div>
            </Grid>
            <Grid item xs={12} md={4} lg={12/6}>
                <ClickAwayListener onClickAway={() => setShowRange(false)}>
                    <div className="date-range-container">
                        <div onClick={() => setShowRange(!showRange)}>
                            <div>{selectionRange[0].startDate ? formatDate(selectionRange[0].startDate) : t("From")}</div>
                            <div>{selectionRange[0].endDate  ? formatDate(selectionRange[0].endDate.toString()) : t("To")}</div>
                        </div>
                        {showRange &&
                            <div className="datepicker-container">
                                <DateRangePicker   
                                    startDatePlaceholder="from"
                                    endDatePlaceholder="to"
                                    editableDateInputs={true}
                                    ranges={selectionRange}
                                    onChange={handleDateChange}
                                    moveRangeOnFirstSelection={false}
                                    direction={"vertical"}
                                    staticRanges={defaultStaticRanges.map(el => {
                                        return {...el, label: t(el.label)}
                                    })}
                                    inputRanges={defaultInputRanges.map(el => {
                                        return {...el, label: t(el.label)}
                                    })}
                                    locale={language == "es" ? es : language == "it" ? it : language == "fr" ? fr : language == "de" ? de : enUS}

                                />
                            </div>
                            }
                    </div>
                </ClickAwayListener>
            </Grid>
            <Grid item xs={12} md={4} lg={12/6}>
                <button disabled={disableButton} className="button-submit-blue w-100 h-100 button-disabled" onClick={() => setShowCheckboxes(!showCheckboxes)}>{t("Deselect reviews to Not display")}</button>
            </Grid>
        </Grid>
    )
}
export default FilterBoxes