import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';


export default function ThankYouMsg({open, setOpen}) {
  let message = useSelector(state => state.getThankYouMessage.thankyou);
  const handleClose = () => {
    setOpen(false);
    window.location.reload()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='survey_thanks_wrapper'>
          <div className="wrapper">
            <div className="image">
              <img src="/admin-icons/checked-icon-blue.svg" alt="checked icon" />
            </div>
            <div className="content">
              <h3 className='title'>Thanks for submitting!</h3>
              <p>{message ? message : 'Thank You For Your Submission!'}</p>
              <button className="close_btn" onClick={handleClose}>Got it</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}