import React, {useState, useEffect} from "react"
import { Grid, Select, MenuItem, FormControl } from "@mui/material"
import MySelect from "../../../helpers/mySelect"
import Colorpicker from "../../../modals/tags/Colorpicker"
import { useTranslation } from "react-i18next"

const ContactUsButton = ({reviewPageFields, setReviewPageFields}) => {
    const {button_text, button_logo, button_color, hide_contact_button} = reviewPageFields
    const [color, setColor] = useState();
    const [hideContactUsButtonsCheck, setHideContactUsButtonsCheck] = useState(false);
    const {t} = useTranslation()
    const logos = [
        {id: 1 ,name: "Star"},
        {id: 2, name: "unlike"}
    ]
    useEffect(() => {
        if(hide_contact_button){
            setHideContactUsButtonsCheck(hide_contact_button === 1 ? true : false)
        }
    }, [reviewPageFields])

    useEffect(() => {
        if(button_color){
            setColor(button_color)
        }
    }, [button_color])

    const updateReviewFieldsCheckbox = (e) => {
        const {checked, name} = e.target
        setReviewPageFields({...reviewPageFields, [name]: checked ? 1 : 0})
    }

    const updateReviewFields = (e) => {
        const {value, name} = e.target
        setReviewPageFields({...reviewPageFields, [name]: value})
    }

    const changeColor = (color) => {
        setColor(color)
        let element = {
        target: {
            name: "button_color",
            value: color
        }}
        updateReviewFields(element)
    }

    return(
        <div className="contact_us_btn_section">
            <h6>{t("Contact Us Button")}</h6>
            <Grid container spacing={2}>
                <Grid item xl={3} md={6} xs={12}>
                    <div className="form-group">
                        <label htmlFor="" className='review_page_label'>{t("Button Text")}</label>
                        <input name="button_text" value={button_text} type="text" className='review-input button-platform-input' onChange={(e) => updateReviewFields(e)}/>
                    </div>
                </Grid>
                <Grid item xl={3} md={6} xs={12}>
                    <label htmlFor="" className='review_page_label'>{t("Button Logo")}</label>
                    <div className="d-flex align-items-center">
                        <div className="w-100">
                            <FormControl fullWidth>
                            <Select
                                name="button_logo"
                                id="input-type-one-select"
                                label="position"
                                input={<MySelect />}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } }}}
                                value={button_logo}
                                onChange={(e) => updateReviewFields(e)}
                            >
                                {logos.map(logo => {
                                    return(
                                        <MenuItem key={`logo-${logo.id}`} value={logo.name.toLowerCase()}>{t(logo.name)}</MenuItem>
                                    )
                                })}
                            </Select>
                            </FormControl>
                        </div>
                    </div>
                </Grid>
                <Grid item xl={3} md={6} xs={12}>
                    <div className="d-flex align-items-center position-relative">
                        <div style={{ marginRight: '8px'}}>
                            <label htmlFor="" className='review_page_label'>{t("Color")}</label>
                            <Colorpicker setColor={changeColor} color={color}/>
                        </div>
                    </div>
                </Grid>
                <Grid item xl={3} md={6} xs={12}>
                    <div className='top d-flex align-items-center justify-content-between flex-wrap gap-10 h-100'>
                        <label className="custom-checkbox hide-buttons-checkbox mb-0">
                            {t("Hide Contact Us Button")}
                            <input type="checkbox" className="only_feedback" name="hide_contact_button" checked={hideContactUsButtonsCheck} onChange={(e) => {
                                setHideContactUsButtonsCheck(!hideContactUsButtonsCheck)
                                updateReviewFieldsCheckbox(e)
                            }}/>
                            <span className={`checkmark ${hideContactUsButtonsCheck ? 'show' : ''}`}></span>
                        </label>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default ContactUsButton