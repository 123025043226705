import { useEffect } from 'react';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import { Grid, Skeleton } from "@mui/material";
import { FetchDataHook } from '../../helpers/fetchDataHook';
import Workspaces from './Workspaces';
import useHandleDisable from '../../helpers/disableSelect';
import './style.css';
import AddPlatformBox from './AddPlatformBox';
import { useDispatch } from 'react-redux';
import { showYoutubeContent } from '../../../../redux/actions/admin-index';
import { useTranslation } from 'react-i18next';

const Convosio = () => {
  const dispatch = useDispatch()
  const { data, loading } = FetchDataHook(`${baseUrlDev}platforms?goto=convosio`, { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' });
  const handleDisable = useHandleDisable()
  const {t} = useTranslation()
  useEffect(() => {
    dispatch(showYoutubeContent("https://www.youtube.com/embed/fw5Xz3h9Clg?autoplay=1&mute=0&rel=0"))
  }, [])

  useEffect(() => {
    handleDisable()
  }, [])

  return (
    <>
      {
        loading || data === null ? <Skeleton style={{ width: "100%", height: "100vh", marginRight: "10px", marginBottom: "10px", transform: "unset" }} /> : <>
          <div className="workspace-holder-container">
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <div className="workspace-holder-title">
                  <img src={data?.platform?.logo} alt="" />
                  <h3>{data?.platform?.title}</h3>
                </div>
                <div className="workspace-holder-description">
                  <p>{data?.platform?.description}</p>
                </div>

                {
                  data?.platform?.video_url && <div className="workspace-holder-video">
                    <object className="workspace-holder-video-player" data={`${data?.platform?.video_url}?controls=0&amp;rel=0`}></object>
                  </div>
                }
              </Grid>
            </Grid>
          </div>
          {
            data?.workspaces?.length > 0 ? <Workspaces data={data} /> : <Grid container style={{ justifyContent: 'center' }}>
              <Grid item xs={12} md={6} xl={3}>
                <AddPlatformBox redirectLink={data?.platform?.workspace_create} />
              </Grid>
            </Grid>
          }

          {
            data?.go_to_platform && <Grid container spacing={2}>
              <Grid item sm={12}>
                <div className="go-to-platform-btn">
                  <a href={data?.go_to_platform} className='link-platform-btn'>{t("Go to platform")}</a>
                </div>
              </Grid>
            </Grid>
          }
        </>
      }
    </>
  )
}

export default Convosio;
