import React, {useState, useEffect} from "react"
import { MenuItem, FormControl, Select, Checkbox, ListItemText, Skeleton, Grid  } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import {MySelectBorderless, MySelectBgArrow} from "../../../../Helpers/MySelectBorderless"
import { fetchQuickReply } from "../../../../../redux/actions/admin-index"
import { useTranslation } from "react-i18next"
const types = [
    {value: "Positive", name: "positive"},
    {value: "Negative", name: "negative"}
]

const show = [
    {value: "10", name: "10"},
    {value: "20", name: "20"},
    {value: "50", name: "50"},
    {value: "100", name: "100"},
]

const QuickRepliesFilter = ({added}) => {
    const dispatch = useDispatch()
    const [filterType, setFilterType] = useState("All")
    const [filterTime, setFilterTime] = useState("desc")
    const [filterLocation, setFilterLocation] = useState([])
    const [filterShow, setFilterShow] = useState("all")
    const [search, setSearch] = useState("")
    const {t} = useTranslation()
    const user = useSelector(state => state.getUserInfo.user)
    let locations = user && user.user.locations

    const handleTimeChange = (e) => {
        let value = e.target.value
        setFilterTime(value);
    }

    const handleShowChange = (e) => {
        let value = e.target.value
        setFilterShow(value);
    }

    const handleTypeChange = (e) => {
        const value = e.target.value
        setFilterType(value)
    }

    const handleLocationChange = (e) => {
        let value = e.target.value
        if(value.includes("all")){
            setFilterLocation(locations && locations.map(el => el.id))
        } else {
            setFilterLocation(value)
        }
    }

    
    useEffect(() => {
        let type = filterType !== "All" ? filterType.toLowerCase() : ""
        let location_id = filterLocation.length  === (locations && locations.length)  ?  "" : filterLocation
        let show = filterShow !== "all" ? filterShow : ""
        dispatch(fetchQuickReply(type, filterTime, search, show, location_id))
        
    }, [filterType, filterLocation, filterTime, filterShow, search])

    useEffect(() => {
        let type = filterType !== "All" ? filterType.toLowerCase() : ""
        let location_id = filterLocation.length === (locations && locations.length)  ?  "" : filterLocation
        let show = filterShow !== "all" ? filterShow : ""
        if(added){
            dispatch(fetchQuickReply(type, filterTime, search, show, location_id))
        }
    }, [added])

    const mystyle = {
        ':before':{
            content: `${t("Show")}`
        }
    };

    return(
        <div className="mb-20">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} lg={2} xl={1}>
                    <FormControl fullWidth>
                        <Select
                            value={filterType}
                            onChange={handleTypeChange}
                            renderValue={(selected) => selected === "All" ? t("All types") : selected}
                            input={<MySelectBorderless/>}
                        >
                            <MenuItem value="All">
                                <Checkbox checked={filterType === "All"}/>
                                <ListItemText primary={"All"} />
                            </MenuItem>
                            {types.map(type => {
                                return(
                                    <MenuItem value={type.value} key={`${type.value}-filter-type`}>
                                        <Checkbox checked={filterType.indexOf(type.value) > -1}/>
                                        <ListItemText primary={t(type.name)}/>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} lg={2} xl={1}>
                    <FormControl fullWidth>
                        <Select
                            value={filterTime}
                            onChange={handleTimeChange}
                            renderValue={(selected) => selected === "asc" ? t("Oldest") : t("Latest")}
                            input={<MySelectBorderless/>}
                        >
                            <MenuItem value="desc">
                                <Checkbox checked={filterTime === "desc"}/>
                                <ListItemText primary={t("Latest")} />
                            </MenuItem>
                            <MenuItem value="asc">
                                <Checkbox checked={filterTime === "asc"}/>
                                <ListItemText primary={t("Oldest")} />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} lg={3} xl={2}>
                    <FormControl fullWidth>
                        {locations && locations.length > 0 ? 
                        <Select
                            displayEmpty
                            value={filterLocation}
                            multiple
                            onChange={handleLocationChange}
                            input={<MySelectBorderless/>}
                            MenuProps={{
                                autoFocus: false, PaperProps: { sx: { maxHeight: 300} , 
                                style: {
                                    maxWidth: 300,
                                }, 
                            }}}
                            renderValue={value => {
                                if(value.length === 0 || !value){
                                    return t("Select Location");
                                } else if (value.length === (locations && locations.length)){
                                    return t("All Locations")
                                } else {
                                    let arr = value.map(element => {
                                        return locations && locations.find(el => el.id == element)
                                    })
                                    return arr.map(el => el && el.full_name).join(', ')
                                }
                            }}
                        >
                            {locations && locations.map(loc => {
                                return(
                                    <MenuItem key={`${loc.id}-filter-q`} value={loc.id}>
                                        <Checkbox checked={filterLocation.includes(loc.id)}/>
                                        <ListItemText primary={loc.full_name} style={{whiteSpace: "break-spaces"}}/>
                                    </MenuItem>
                                )
                            })}
                        </Select> 
                        :
                        ""
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} lg={2} xl={2} style={{marginLeft: "auto"}}>
                    <FormControl style={{width: "fit-content", display: "flex", justifyContent: "flex-end", marginLeft: "auto"}}>
                        <Select
                        style={mystyle}
                            id="show-number"
                            value={filterShow}
                            onChange={handleShowChange}
                            input={<MySelectBgArrow/>}
                            renderValue={value => {
                                if (value === "all"){
                                    return "All"
                                } else {
                                    return value
                                }
                            }}
                        >
                            <MenuItem value={"all"}>
                                <Checkbox checked={filterShow === "all"}/>
                                <ListItemText primary={"All"} />
                            </MenuItem>
                            {show.map(el => {
                                return(
                                    <MenuItem value={el.value} key={`show-${el.value}`}>
                                        <Checkbox checked={filterShow === el.value}/>
                                        <ListItemText primary={el.name} />
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl={2}>
                    <FormControl fullWidth>
                        <input type="search" value={search} placeholder={t("Search Replies")} className="search-input-replies" onChange={(e) => setSearch(e.target.value)}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    )
}
export default QuickRepliesFilter