import React from 'react';
import { Grid, Autocomplete, TextField, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MyAutocomplete from '../../../../Helpers/MyAutcomplete';

const Keywords = ({ formData, handleInputChange }) => {
    const [t] = useTranslation()
    const handleKeywords = (event, values) => {
        handleInputChange('keywords', values)
    }
    return (
        <div>
            <div className='keywords-card'>
            {/* <div className='keywords-card location_settings_card'>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div>
                            <h5 className='title'>Important Keywords</h5>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <input
                                name="keywords"
                                type="text"
                                placeholder=''
                                value={formData?.keywords}
                                onChange={(e) => handleInputChange('keywords', e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div> */}
            <h5 className='title'>{t("Important Keywords")}</h5>
            <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                value={formData?.keywords}
                freeSolo
                input={<MyAutocomplete />}
                onChange={handleKeywords}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                    
                        placeholder={t("Enter to submit keywords")}
                        className="custom-autocomplete"
                        inputProps={{
                            ...params.inputProps,
                            maxLength: 100
                        }}
                    />
                )}
            />
            </div>
        </div>
    )
}

export default Keywords