import React, {useState, useMemo, useEffect} from "react"
import { Modal, Slide } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import axios from "axios"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../Helpers/baseUrl"

const Email = ({openEmail, setOpenEmail, id}) => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setEmail('')
        setMessage('')
        setLoading(false)
        setOpenEmail(false)
    }
    const submitSource = () => {
        setLoading(true)
        let data = {email, message}
        axios.post(`${baseUrlDev}reviews/share/${id}/email`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            handleClose()
            setLoading(false)
        })
    }
    return(
        <Modal 
            open={openEmail}
            onClose={handleClose}
            aria-labelledby="modal-tag-title"
            aria-describedby="modal-tag-description"
        >
            <Slide direction="left" in={openEmail} {...({ timeout: 500 })}>
                <div className='custom-modal-dialog'>
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>Share By Email</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-div-container custom-scrollbar-vertical">
                                <div className="field-container">
                                    <label>Email address</label>
                                    <input type="email" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                <div className="text-note-container field-container">
                                    <label>Message</label>
                                    <textarea className="custom-scrollbar-vertical" onChange={(e) => setMessage(e.target.value)} value={message}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <LoadingButton
                                style={{paddingLeft: loading && "30px"}}
                                className="save-btn"
                                onClick={() => submitSource()}
                                loading={loading}
                            >
                                Send
                            </LoadingButton>
                            <button className="close-btn" onClick={() => setOpenEmail(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Slide>
        
        </Modal>
    )
}
export default Email