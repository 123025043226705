import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from "@mui/material"
import { fetchGamifyTarget } from "../../../../redux/actions/admin-index";
import axios from 'axios';
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { useTranslation } from "react-i18next";

const renderWinners = (winners, reviewKey, not) => {
    return winners?.map((member, index) => (
        <div className="gamify_winners_wrap" key={index}>
            {
                member?.mentioned?.map(m => (
                    <div key={index}>
                        <h4>{m?.name}</h4>
                        <span>{m?.mentioned_count}</span>
                    </div>
                ))
            }
            {
                member?.not_mentioned?.map((m, index) => (
                    <div key={index}>
                        <h5 className="not_mentioned_title">{not}</h5>
                        <span>{m?.not_mentioned_count}</span>
                    </div>
                ))
            }
        </div>
    ));
};

const RightSideGamify = ({ active, gamifyTargets, setGamifyTargets }) => {
    // const { loading, gamify_targets } = useSelector(state => state.getGamifyMembers);
    // const [gamifyTargets, setGamifyTargets] = useState([]);

    // console.log(gamifyTargets);

    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(false);
    const location = useSelector(state => state.locationSelected.location)

    const [progress, setProgress] = useState(0)
    const fillHeight = (289 / 100) * progress;
    const {t} = useTranslation()
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(fetchGamifyTarget(location))
        setLoading(true);
        setLoadingProgress(true);
        axios.get(`${baseUrlDev}locations/data-target/${location}`, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(res => {
            setGamifyTargets(res?.data);
            setLoading(false);
        }).catch(err => {
            setGamifyTargets([]);
            setLoading(false);
        })
    }, [location, dispatch]);

    useEffect(() => {
        if (active === 1 && gamifyTargets?.dailyTargetPercentage) {
            setProgress(gamifyTargets.dailyTargetPercentage);
            setLoadingProgress(false);
        } else if (active === 2 && gamifyTargets?.weeklyTargetPercentage) {
            setProgress(gamifyTargets.weeklyTargetPercentage);
            setLoadingProgress(false);
        } else if (active === 3 && gamifyTargets?.monthlyTargetPercentage) {
            setProgress(gamifyTargets.monthlyTargetPercentage);
            setLoadingProgress(false);
        } else {
            setProgress(0);
            setLoadingProgress(false);
        }

    }, [active, gamifyTargets]);

    return (
        <>
            <div className="review_collection_star_wrapper">
                <div className="star-progress-filler" style={{paddingTop: "30px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="289" height="289" viewBox="0 0 289 289">
                        <path d="M134.406 13.0786C138.124 4.06384 150.892 4.06381 154.61 13.0786L185.693 88.4461C187.23 92.173 190.694 94.7509 194.705 95.1528L273.406 103.038C282.767 103.976 286.646 115.518 279.751 121.919L219.724 177.648C216.909 180.262 215.673 184.162 216.469 187.921L233.748 269.489C235.742 278.904 225.472 286.113 217.295 281.037L150.271 239.432C146.742 237.241 142.275 237.241 138.745 239.432L71.7218 281.037C63.5449 286.113 53.2742 278.904 55.2687 269.489L72.5476 187.921C73.3438 184.162 72.1078 180.262 69.2923 177.648L9.26526 121.919C2.37061 115.518 6.24954 103.976 15.6106 103.038L94.3115 95.1528C98.3228 94.7509 101.787 92.173 103.324 88.4461L134.406 13.0786Z" fill="none" strokeLinejoin="round" stroke="#FBBC04" strokeWidth="12" />
                        <path d="M134.406 13.0786C138.124 4.06384 150.892 4.06381 154.61 13.0786L185.693 88.4461C187.23 92.173 190.694 94.7509 194.705 95.1528L273.406 103.038C282.767 103.976 286.646 115.518 279.751 121.919L219.724 177.648C216.909 180.262 215.673 184.162 216.469 187.921L233.748 269.489C235.742 278.904 225.472 286.113 217.295 281.037L150.271 239.432C146.742 237.241 142.275 237.241 138.745 239.432L71.7218 281.037C63.5449 286.113 53.2742 278.904 55.2687 269.489L72.5476 187.921C73.3438 184.162 72.1078 180.262 69.2923 177.648L9.26526 121.919C2.37061 115.518 6.24954 103.976 15.6106 103.038L94.3115 95.1528C98.3228 94.7509 101.787 92.173 103.324 88.4461L134.406 13.0786Z" fill="#FBBC04" mask="url(#star-mask)" />
                        <defs>
                            <mask id="star-mask">
                                <rect x="0" y={`${289 - fillHeight + 12}px`} width="100%" height={`${fillHeight - 12}px`} fill="white" />
                            </mask>
                        </defs>
                    </svg>
                    <div>
                        {progress === undefined || progress === null || loadingProgress ? <Skeleton style={{ width: '50px', height: '30px', transform: 'unset' }} /> : <span>{progress}%</span>}
                        <span>{t("Today")}</span>
                    </div>
                </div>
                <div className="review_collection-top">
                    <div>
                        <h5>Top 3</h5>
                        <h6>{t("Review")}</h6>
                    </div>
                    <div className="review_collection-list">
                        {loading ? [...Array(3)].map((el, i) => {
                            return (
                                <Skeleton style={{ height: "37px", transform: "unset", marginBottom: '15px' }} key={`skeleton-gamify${i}`} />
                            )
                        }) :
                            <>
                                {active === 1 && renderWinners(gamifyTargets?.dailyWinners, 'daily_review_count', t("Not Mentioned"))}
                                {active === 2 && renderWinners(gamifyTargets?.weeklyWinners, 'weekly_review_count', t("Not Mentioned"))}
                                {active === 3 && renderWinners(gamifyTargets?.monthlyWinners, 'monthly_review_count', t("Not Mentioned"))}
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default RightSideGamify