import React, {useState, useEffect, useMemo} from "react"
import { Collapse  } from "@mui/material";
import { ClickAwayListener } from '@mui/base';
import {MdOutlineClear} from "react-icons/md"


const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const areEqual = (prevProps, nextProps) => {
    return prevProps.location === nextProps.location && prevProps.categories === nextProps.categories && prevProps.elements === nextProps.elements;
};

const BusinessCategoriesSelect = React.memo(({categories, selectElement, removeElement, elements, location}) => {
    const [search, setSearch] = useState("")
    const [showDropdown, setShowDropdown] = useState(false)

    const displayedCategories = useMemo(() => categories && categories.filter(category => containsText(category, search)), [search, location]);

    const closeDropdown = () => {
        setShowDropdown(false)
        setSearch("")
    }

    return(
        <ClickAwayListener onClickAway={() => closeDropdown()}>
            <div className="custom-select-large-data">
                <div className={`${showDropdown && "active"} custom-scrollbar-vertical`} onClick={() => setShowDropdown(true)}>{elements && elements.length == 0 ? "No data Selected" : 
                    elements && elements.map((el, i) => {
                       return(
                        <span key={`teim-sel${el}${i}`}>
                            {el}
                            <MdOutlineClear onClick={() => removeElement(el)}/>
                        </span>
                       )
                    })
                }</div>
                <Collapse  in={showDropdown}>
                    <div className="custom-menu-large-data">
                        <input type="text" onKeyUp={(e) => setSearch(e.target.value)}/>
                        <div className="custom-scrollbar-vertical">
                            <ul>
                                {displayedCategories && displayedCategories.map((item, i) => {
                                    return(
                                        <li key={`menu-item${item}${i}`} onClick={() => selectElement(item)} className={elements.includes(item) ? "selected" : ""}>
                                            {item}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </Collapse >
            </div>
        </ClickAwayListener>
    )
}, areEqual)
export default BusinessCategoriesSelect