import React, {useEffect, useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal, Slide, Alert, Skeleton, Collapse } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import axios from "axios"
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../../Helpers/baseUrl"
import { showAlert } from "../../../../../redux/actions"
import { fetchGroups, fetchGroupPage } from "../../../../../redux/actions/admin-index"
import {MdOutlineKeyboardArrowDown} from "react-icons/md"

const ExistingGroup = ({open, setOpen}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [collapsedLocations, setCollapsedLocations] = useState([])
    const [data, setData] = useState()

    useEffect(() => {
        dispatch(fetchGroups())
    }, [])

    const groups = useSelector(state => state.getGroups.groups)    
    const location = useSelector(state => state.locationSelected.location);


    const handleClose = () => {
        setOpen(false)
        setLoading(false)
        setErrors([])
    }
    const submitGroup = () => {
        if(!data){
            setErrors([...[], "Please choose group."])
            return
        }
        let location_ids = data.locations.map(el => el.id)
        location_ids.push(parseInt(location))
        let formattedData = {
            name: data.name,
            descriptions: data.description,
            location_ids
        }
        setLoading(true)
        axios.post(`${baseUrlDev}group/${data.id}/update`, formattedData, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            dispatch(showAlert(true, "Group updated with new location succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
            dispatch(fetchGroupPage(location))
            dispatch(fetchGroups())
        }).then(res => {
            setLoading(false)
            setOpen(false)
        }).catch(err => {
            setLoading(false)
            Object.keys(err.response.data.errors).map(key => {
                setErrors([...errors, err.response.data.errors[key][0]])
            })
        })
    }

    const handleCollapseToggle = (id) => {
        setCollapsedLocations((prev) => ({
            ...prev,
            [id]: !prev[id]
        }))
    }

    return(
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-group-title"
            aria-describedby="modal-group-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className={`custom-modal-dialog`}>
                    {errors && errors.map(err => {
                        return(
                            <div className="alert-inside-modal" key={err}>
                                <Alert severity="error" className="custom-error-alert">{err}</Alert>
                            </div>
                        )
                    })}
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>Choose Existing Group</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-div-container custom-scrollbar-vertical">
                               {groups === undefined ? 
                                <div>
                                    <Skeleton style={{width: "100%", height: "50px"}} />
                                </div>
                                : 
                                groups && groups.data && groups.data.length > 0 && groups.data.map(el => {
                                    return(
                                        <div key={`group_list_${el.id}`} className="group-wrapper-existing">
                                            <div>
                                                <input type="radio" name="group" id={`group-${el.id}`} onClick={() => setData(el)}/>
                                                <label htmlFor={`group-${el.id}`}>
                                                    {el.name}
                                                </label>
                                                <MdOutlineKeyboardArrowDown onClick={() => handleCollapseToggle(el.id)}/>
                                            </div>
                                            <Collapse in={collapsedLocations[el.id] ? true : false}>
                                                {el.locations && el.locations.length > 0 ? 
                                                    el.locations.map(item => {
                                                        return <h6 key={`${item.id}=${item.irevu_companyName}`}>{item.irevu_companyName}-{item.address}</h6>
                                                    })
                                                : ""}
                                            </Collapse>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                        <LoadingButton
                                style={{paddingLeft: loading && "30px"}}
                                className="save-btn"
                                onClick={() => submitGroup()}
                                loading={loading}
                            >
                                Save
                            </LoadingButton>
                            <button className="close-btn" onClick={() => setOpen(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Slide>
    </Modal>
    )

}
export default ExistingGroup