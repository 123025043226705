import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Skeleton, Alert } from "@mui/material"
import axios from "axios"
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../../Helpers/baseUrl"
import { showAlert } from "../../../../../redux/actions"
import { LoadingButton } from "@mui/lab"
import EditQuickReply from "./EditQuickReply"
import { CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"

const QuickRepliesList = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [errors, setErrors] = useState([])
    const [list, setList] = useState([])
    const [id, setId] = useState("")
    const [loading, setLoading] = useState([])
    const {t} = useTranslation()
    const replies_redux = useSelector(state => state.getQuickReplies.replies)

    useEffect(() => {
        if (replies_redux && replies_redux.data) {
            setList(replies_redux.data)
        }
    }, [replies_redux])

    const handleDeleteReply = (id) => {
        setLoading(prev => [...prev, id])
        axios.post(`${baseUrlDev}reviews/quick-replies/${id}/delete`, {}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setOpen(false)
                setList(list.filter(l => l.quick_reply_id != id))
                dispatch(showAlert(true, "Reply Deleted Succesfully!"))
                setTimeout(() => {
                    dispatch(showAlert(false, ""))
                }, 5000)
            }).catch(err => {
                Object.keys(err.response.data.errors).map(key => {
                    setErrors([...errors, err.response.data.errors[key][0]])
                })
            })
    }

    return (
        <div className="quick-reply-list-wrapper">
            <EditQuickReply open={open} setOpen={setOpen} setList={setList} id={id} list={list} />
            {replies_redux === undefined ?
                <>
                    <Skeleton style={{ width: "100%", height: "100px", transform: "unset", marginBottom: "10px" }} />
                    <Skeleton style={{ width: "100%", height: "100px", transform: "unset", marginBottom: "10px" }} />
                    <Skeleton style={{ width: "100%", height: "100px", transform: "unset", marginBottom: "10px" }} />

                </>
                :
                list.length > 0 ? list.map(l => {
                    return (
                        <div className="quick-reply-item-wrapper" key={`${l.replies.id}-${l.replies.type}`}>
                            <div>
                                <span className={`${l.replies.type === "positive" ? "positive" : "negative"}`}>{l.replies.type === "positive" ? t("positive") : t("negative")}</span>
                                <div className="d-flex align-items-start">
                                    <img src="/admin-icons/reply-icon.svg" alt="reply" />
                                    <div>
                                        <p>{l.replies.content}</p>
                                        {l.replies && l.replies.user && <h6>{t("Created By")}: <span>{l.replies.user.first_name} {l.replies.user.last_name}</span></h6>}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                {/* <button onClick={() => {
                                    setOpen(true)
                                    setId(l.quick_reply_id)
                                }}><img src="/admin-icons/edit-reply.svg" alt="edit" /></button> */}
                                <button className="edit-lead-btn form_list_edit" onClick={() => {
                                    setOpen(true)
                                    setId(l.quick_reply_id)
                                }}>
                                    <span className="icon-mydevicon-edit-bordered"></span>
                                </button>

                                {loading.includes(l.replies.id) ?
                                    <button style={{ cursor: "initial" }}>
                                        <CircularProgress color="inherit" size={18} style={{ opacity: 0.5 }} />
                                    </button>
                                    :
                                    // <button>
                                    //     <img src="/admin-icons/delete-quick-reply.svg" alt="delete" onClick={() => handleDeleteReply(l.replies.id)}/>
                                    // </button>
                                    <button className="remove-lead form_list_remove" onClick={() => handleDeleteReply(l.replies.id)} >
                                        <span className="icon-rounded-trash"></span>
                                    </button>
                                }
                            </div>
                        </div>
                    )
                }) :
                    <div className="nothing-to-show mt-20">No Quick Replies Added Yet</div>
            }
        </div>
    )
}
export default QuickRepliesList