import React, { useState, useMemo, useEffect } from 'react';
import { Modal, Slide, Skeleton, Grid} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector, useDispatch } from 'react-redux';
import {AiOutlineDelete} from "react-icons/ai"
import {FiSearch} from "react-icons/fi"
import { fetchAddedKeywords, clearAddedKeywords } from '../../../../redux/actions/admin-index';
import axios from 'axios';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import { useTranslation } from 'react-i18next';
const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const AddKeyword = ({ open, setOpen, setOpenKeywordsModal, setKeyId }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState()
    const [search, setSearch] = useState("")
    const [keywords, setKeywords] = useState([])
    const [newKeyword, setNewKeyword] = useState("")
    
    const account_id = useSelector(state => state.locationSelected.location)
    const keywordsState = useSelector(state => state.keywordAddedReducer.keywords)
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(clearAddedKeywords())
        if(account_id && account_id !== "all" && open){
            dispatch(fetchAddedKeywords(account_id))
        }
    }, [account_id, open])

    useEffect(() => {
        dispatch(clearAddedKeywords())
        if(selected && (!account_id || account_id == "all" ) && open){
            dispatch(fetchAddedKeywords(selected))
        }
    }, [selected, open])

    useEffect(() => {
        if(keywordsState){
            setKeywords(keywordsState.data)
        }
    }, [keywordsState])

    useEffect(() => {
        if(keywords){
            setLoadingDelete(Array(keywords.length).fill(false))
        }
    }, [keywords])

    const handleClose = () => {
        setOpen(false);
    }

    const addNewKeywordsFunc = (id) => {
        if(newKeyword.length > 3){
            setLoading(true)
            axios.post(`${baseUrlDev}keyword/store`, {location: id, keyword: newKeyword }, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
            .then((res) => {
                setKeywords([{id: res.data.data.id, location: id, name: newKeyword}, ...keywords])
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
        }
        setNewKeyword("")
    }

    const addNewKeywords = () => {
        if(account_id && account_id !== "all" && open){
            addNewKeywordsFunc(account_id)
        }
        if(selected && (!account_id || account_id == "all")){
            addNewKeywordsFunc(selected)
        }
    }

    const deleteKeyword = (id, i) => { 
        setLoadingDelete((prevLoading) => {
            const newLoading = [...prevLoading];
            newLoading[i] = true;
            return newLoading;
        });
        axios.post(`${baseUrlDev}keyword/destroy/${id}`, {id}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(() => {
            setKeywords(oldValues => oldValues.filter(el => el.id !== id))
            setLoadingDelete((prevLoading) => {
                const newLoading = [...prevLoading];
                newLoading[i] = false;
                return newLoading;
            });
        }).catch(err => {
            setLoadingDelete((prevLoading) => {
                const newLoading = [...prevLoading];
                newLoading[i] = false;
                return newLoading;
            });
        })
    }

    
    const displayedKeywords = useMemo(() => keywords && keywords.filter(key => containsText(key.name, search)), [search, keywords]);


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-keyword-title"
            aria-describedby="modal-keyword-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className="custom-modal-dialog custom-modal-keyword">
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>{t("Added Keywords")}</h5>
                        </div>

                        <div className="custom-modal-content add_keyword_modal">
                            <div className='scrollable-loc-container custom-scrollbar-vertical'>
                                <Grid container  className="grid_wrap">
                                    <Grid item xs={12} sm={12} md={6} lg={5} style={{ borderRight: '1px solid #f3f3f3' }}>
                                        <div className="left">
                                            <div className="search_keyword">
                                                <div className='d-flex align-items-center justify-content-between' style={{ padding: '5px 0' }}>
                                                    <input type="text" value={search} className='form-control search_keyword_input' placeholder={t('Search')} onChange={(e) => setSearch(e.target.value)}/>
                                                    <div className="search_icon_wrap">
                                                        <FiSearch/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="boxes">
                                                {keywordsState === undefined ? 
                                                <div>
                                                    <Skeleton style={{transform: "unset", height: "30px", marginBottom: "10px"}}/>
                                                    <Skeleton style={{transform: "unset", height: "30px", marginBottom: "10px"}}/>
                                                    <Skeleton style={{transform: "unset", height: "30px"}}/>
                                                </div>
                                                :
                                                displayedKeywords.map((keyword, i) => {
                                                    return(
                                                        <div className="d-flex justify-content-between align-items-center box__item-wrap" key={`${keyword.name}-${keyword.id}`}>
                                                            <div className="box_item" onClick={() => {
                                                                setKeyId(keyword.name)
                                                                setOpenKeywordsModal(true)
                                                            }}>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <h5 className='keyword_item_val'>{keyword.name}</h5>
                                                                </div>
                                                            </div>
                                                            <LoadingButton
                                                                className="delete_item"
                                                                onClick={() => deleteKeyword(keyword.id, i)}
                                                                loading={loadingDelete[i]}
                                                            >
                                                                {!loadingDelete[i] && <span className="icon-rounded-trash"></span>}
                                                            </LoadingButton>
                                                        </div>
                                                    )
                                                })}
                                      
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={7}>
                                        <div className="right">
                                            <div className="top">
                                                <div className="input-wrap">
                                                    <div className="input-item">
                                                        <input type="text" value={newKeyword} className="form-control add_title-inp" placeholder={`${t("Add keyword")}...`}  onChange={(e) => setNewKeyword(e.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-center  justify-content-end'>
                                                    <LoadingButton
                                                        style={{paddingLeft: loading && "30px"}}
                                                        className="btn add_btn blue-loading-btn"
                                                        onClick={() => addNewKeywords()}
                                                        loading={loading}
                                                    >
                                                        {!loading && ''} <img src="/admin-icons/plus.svg" alt="edit" /> {t("Add")}
                                                    </LoadingButton>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <button className="close-btn" onClick={() => setOpen(false)}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}

export default AddKeyword;