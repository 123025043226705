import React from "react"
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import {MdKeyboardArrowDown} from "react-icons/md"

export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    color: '#00B4E5',
    border: `0px`,
    borderBottom: 0,
    '&:before': {
      display: 'none',
    },
}));

export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<MdKeyboardArrowDown sx={{ fontSize: '0.9rem', color: "#00B4E5" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    border: 0,
    fontFamily: "Poppins-Regular",
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
      color: '#00B4E5',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: 'rgba(24, 174, 227, 0.08)',
    fontFamily: "Poppins-Regular",
    color: '#221f1f'
}));