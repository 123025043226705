import React, {useState} from "react"
import { Grid, Select, MenuItem, FormControl, Collapse } from "@mui/material"
import MySelect from "../../../helpers/mySelect"
import Colorpicker from "../../../modals/tags/Colorpicker"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const Button = ({reviewButton, setReviewButtons, reviewPageFields, setReviewPageFields, reviewButtons}) => {
    const [color, setColor] = useState(reviewButton?.color);
    const {t} = useTranslation()
    const deleteReviewButton = (id) => {
        setReviewButtons((current) => current?.filter((item) => item.id !== id));
        setReviewPageFields({...reviewPageFields, "buttons": reviewButtons.filter((item) => item.id !== id)})
    }
    const sources = useSelector(state => state.getLocationAvaliableSources.sources)
    let indexedEl = reviewButtons.findIndex(obj => obj.id === reviewButton.id)

    const updateReviewFields = (e) => {
        const {value, name} = e.target
        const updatedButtons = [...reviewButtons]
        updatedButtons[indexedEl][name] = value
        setReviewPageFields({...reviewPageFields, "buttons": updatedButtons})
    }

    const updateReviewFieldsSource = (e) => {
        const {value} = e.target
        const updatedButtons = [...reviewButtons]
        updatedButtons[indexedEl]["source_id"] = value
        updatedButtons[indexedEl]["source"]["logo_src"] = sources && sources.data.filter(el => el.id == value)[0].logo
        updatedButtons[indexedEl]["platform"] = sources && sources.data.filter(el => el.id == value)[0].name
        setReviewPageFields({...reviewPageFields, "buttons": updatedButtons})
    }

    const changeColor = (color) => {
        setColor(color)
        let element = {
        target: {
            name: "color",
            value: color
        }}
        updateReviewFields(element)
    }
    return(
        <div className="buttons-review-cards">
            <div className="buttons-review-card">
                <Grid container spacing={2}>
                    <Grid item xl={3} md={6} xs={12}>
                        <label htmlFor="" className='review_page_label'>{t("Review Button")}</label>
                        <div className="d-flex align-items-center">
                            <div className="w-100">
                                <FormControl fullWidth>
                                <Select
                                    name="platform"
                                    id="input-type-one-select"
                                    label="position"
                                    input={<MySelect />}
                                    onChange={(e) => updateReviewFieldsSource(e)}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } }}}
                                    value={reviewButton.source_id}
                                    renderValue={value => {
                                        const item = sources && sources.data.find((el) => el.id == value ); 
                                        return <div className="d-flex align-items-center">{item && item.logo && <img style={{width: "25px", objectFit: "contain", marginRight: "10px"}} src={item.logo}/>}{item && item.name}</div>
                                    }}
                                >
                                    {sources && sources.data.map(source => {
                                        return(
                                            <MenuItem key={`source${source.name}`} value={source.id}>{source && source.logo && <img style={{width: "25px", objectFit: "contain", marginRight: "10px"}} src={source.logo}/>}{source.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                                </FormControl>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={3} md={6} xs={12}>
                        <div className="form-group">
                            <label htmlFor="" className='review_page_label'>{t("Platform")}</label>
                            <input name="label" type="text" className='review-input button-platform-input' value={reviewButton?.label} onChange={(e) => updateReviewFields(e)}/>
                        </div>
                    </Grid>
                    <Grid item xl={3} md={6} xs={12}>
                        <div className="form-group">
                            <label htmlFor="" className='review_page_label'>URL</label>
                            <input name="url" type="text" className='review-input button-platform-input' value={reviewButton?.url} onChange={(e) => updateReviewFields(e)}/>
                        </div>
                    </Grid>
                    <Grid item xl={3} md={6} xs={12}>
                        <div className="d-flex align-items-center position-relative">
                            <div style={{ marginRight: '8px' }}>
                                <label htmlFor="" className='review_page_label'>{t("Color")}</label>
                                <Colorpicker setColor={changeColor} color={color}/>
                            </div>
                            <div className="d-flex flex-column align-items-center delete-platform-wrap">
                                <label htmlFor="" className="review_page_label" style={{ visibility: 'hidden' }}>Icon</label>
                                <a className='delete-platform cursor-pointer' onClick={() => deleteReviewButton(reviewButton?.id)}>
                                    <img src={"/admin-images/tools/delete-icon.svg"} alt="remove icon" />
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
export default Button