import React, { useEffect, useState } from "react"
import { Grid, Collapse, FormControl, IconButton, Alert, Fade, Grow } from "@mui/material"
import MaskedInput from 'react-text-mask'
import { ValidationTextFieldNoLabel } from "../../../../Helpers/ValidationTextField"
import LightTooltip from "../../../helpers/lightTooltip"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const ReviewInformation = ({ expanded, handleChangeAccordion, setReviewPageFields, reviewPageFields, setLogoPrev, reviewData }) => {
    const { phone, email, cover_image, logo } = reviewPageFields;
  
    const [logoFile, setLogoFile] = useState();
    const [coverFile, setCoverFile] = useState();
    const [fileSizeLimitExceeds, setFileSizeLimitExceeds] = useState(false);

    const location = useSelector(state => state.locationSelected.location)
    const user = useSelector(state => state.getUserInfo.user)
    let location_details = user && user.user.locations.filter(loc => loc.id == location)

    const {t} = useTranslation()
    const dotAfterAtRegex = /@.*\./;

    useEffect(() => {
        if (cover_image && typeof (cover_image) === "string") {
            setCoverFile(cover_image)
        }
    }, [cover_image])

    useEffect(() => {
        if(location_details){
            setLogoPrev(location_details[0]?.logo)
            setLogoFile(location_details[0]?.logo)
        }
    }, [location])

    const updateReviewFields = (e) => {
        const { value, name } = e.target
        setReviewPageFields({ ...reviewPageFields, [name]: value })
    }

    const updateReviewFieldsFile = (e) => {
        const { name } = e.target
        const file = e.target.files[0];
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSizeInBytes) {
            setFileSizeLimitExceeds(true);
            return;
        }
        setReviewPageFields({ ...reviewPageFields, [name]: file })
    }

    const handleFileChange = (event, setImage) => {
        updateReviewFieldsFile(event)
        const file = event.target.files[0];
        setImage(URL.createObjectURL(file));
        if(event.target.name == "logo"){
            setLogoPrev(URL.createObjectURL(file));
        }
    }
    return (
        <>
            <div className="content-card-header" onClick={() => handleChangeAccordion("panel4")}>
                <Grow in={fileSizeLimitExceeds}><Alert severity="error" className="alert-file-limit-exceeds custom-error-alert">File size exceeds the limit. Please select a smaller image.</Alert></Grow>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className="d-flex align-items-center">
                        <div>
                            <img src={"/admin-images/tools/information-small-logo.svg"} alt="" />
                        </div>
                        <h3 className="title">{t("Information")}</h3>
                    </div>
                    <div>
                        <img src={"/admin-images/tools/small-down-arrow.svg"} className={`cursor-pointer arrow ${expanded?.find(e => e === 'panel4') ? 'rotate' : ''}`} alt="" />
                    </div>
                </div>
            </div>
            <Collapse in={expanded?.find(e => e === 'panel4') ? true : false}>
                <div id="reviews_information" className='collapse_card_review'>
                    <div className="content-card-review">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>{t("Phone")}</label>
                                    {/* <input type="text" name="phone" className='form-control review-input' value={phone} onChange={(e) => updateReviewFields(e)}/> */}
                                    <MaskedInput
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        className="form-control review-input"
                                        placeholder="Enter a phone number"
                                        guide={false}
                                        name="phone"
                                        value={phone}
                                        id="my-input-id"
                                        onBlur={() => { }}
                                        onChange={(e) => updateReviewFields(e)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>{t("Email")}</label>
                                    <FormControl sx={{ width: '100%' }}>
                                        <ValidationTextFieldNoLabel
                                            type="email"
                                            onChange={(e) => updateReviewFields(e)}
                                            name="email"
                                            value={email}
                                            InputProps={{
                                                inputProps: {
                                                    pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
                                                },
                                            }}
                                            error={email && !email.includes("@") || !dotAfterAtRegex.test(email)}
                                            helperText={email && (!email.includes("@") || !dotAfterAtRegex.test(email) ? "Invalid Email" : "")}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>{t("Logo")}</label>
                                    <LightTooltip title="This logo is used on landing page and about page">
                                        <IconButton className="">
                                            <IoMdInformationCircleOutline style={{ opacity: "0.4" }} />
                                        </IconButton>
                                    </LightTooltip>
                                    <div className='d-flex align-items-center flex-wrap gap-10'>
                                        <div className="image-uploaded">

                                            <img src={logoFile ? logoFile : "/admin-images/tools/upload-image.svg"} id="imgLogoPreview" alt="" />
                                        </div>
                                        <div className="position-relative">
                                            <input type="file" id="logo-img" name="logo" onChange={(e) => handleFileChange(e, setLogoFile)} accept=".jpg, .jpeg, .png, .svg" />
                                            <button className="btn upload-btn upld-btn1">{t("Upload Logo")}</button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>{t("Cover")}</label>
                                    <LightTooltip title="This logo is used as cover on about page">
                                        <IconButton className="">
                                            <IoMdInformationCircleOutline style={{ opacity: "0.4" }} />
                                        </IconButton>
                                    </LightTooltip>
                                    <div className='d-flex align-items-center flex-wrap gap-10'>
                                        <div className="image-uploaded">
                                            <img src={coverFile ? coverFile : "/admin-images/tools/upload-image.svg"} id="imgLogoPreview" alt="" />
                                        </div>
                                        <div className="position-relative">
                                            <input type="file" id="cover-img" name="cover_image" onChange={(e) => handleFileChange(e, setCoverFile)} accept=".jpg, .jpeg, .png, .svg" />
                                            <button className="btn upload-btn upld-btn2">{t("Upload Cover")}</button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Collapse>
        </>
    )
}
export default ReviewInformation