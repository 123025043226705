import React from "react"
import { IconButton } from "@mui/material"
import { Link } from "react-router-dom"
import LightTooltip from "../../helpers/lightTooltip"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { AiFillStar } from "react-icons/ai"
import { MdOutlineReviews } from "react-icons/md"
import { useTranslation } from "react-i18next"
import { CustomLinearProgress } from "../../helpers/customLinearProgress"

const RatingByBreakDown = ({ breakdown, facebookRecommendations, connected }) => {
    const [t] = useTranslation()
    return (
        <div className="dashboard-box-containers">
            <LightTooltip title={t("Rating breakdowns show you the number of ratings for each star")}>
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{ opacity: "0.4" }} />
                </IconButton>
            </LightTooltip>
            <h2>{t("Rating Breakdown")}</h2>
            <div style={{display: "flex", flexDirection: "column", height: "calc(100% - 45px)", justifyContent: "space-between"}}>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>{t("Rating")}</th>
                                <th>{t("Reviews")}</th>
                                <th>%</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><div className="star-title-container">5 <AiFillStar /></div></td>
                                <td>{Object.entries(breakdown)[0][1].count}</td>
                                <td>{Object.entries(breakdown)[0][1].percentage}</td>
                            </tr>
                            <tr>
                                <td><div className="star-title-container">4 <AiFillStar /></div></td>
                                <td>{Object.entries(breakdown)[1][1].count}</td>
                                <td>{Object.entries(breakdown)[1][1].percentage}</td>
                            </tr>
                            <tr>
                                <td><div className="star-title-container">3 <AiFillStar /></div></td>
                                <td>{Object.entries(breakdown)[2][1].count}</td>
                                <td>{Object.entries(breakdown)[2][1].percentage}</td>
                            </tr>
                            <tr>
                                <td><div className="star-title-container">2 <AiFillStar /></div></td>
                                <td>{Object.entries(breakdown)[3][1].count}</td>
                                <td>{Object.entries(breakdown)[3][1].percentage}</td>
                            </tr>
                            <tr>
                                <td><div className="star-title-container">1 <AiFillStar /></div></td>
                                <td>{Object.entries(breakdown)[4][1].count}</td>
                                <td>{Object.entries(breakdown)[4][1].percentage}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {facebookRecommendations && 
                    <div className="facebook-recommendations">
                        {connected && 
                            <div className="breakdown-connect">
                                {connected.google != 1 && <Link to="/app/admin/reviews/connections">{t("Connect to google")}</Link>}
                                {connected.facebook != 1 && <Link to="/app/admin/reviews/connections">{t("Connect to facebook")}</Link>}
                            </div>
                        }
                        {connected.facebook == 1 && 
                            <>
                                <h5><MdOutlineReviews /> {t("Facebook Recommendations")}</h5>
                                <div className="facebook-recommendations-title">
                                    <div>{t("Recommended")}</div>
                                    <div>{t("Not Recommended")}</div>
                                </div>
                                <div style={{ position: "relative" }}>
                                    <CustomLinearProgress
                                        variant="determinate"
                                        value={facebookRecommendations.positive && parseInt(facebookRecommendations.positive.percentage.replace('%', ""))}
                                    />
                                    <div className="recommended-num">{facebookRecommendations.positive ? facebookRecommendations.positive.count : 0}</div>
                                    <div className="not-recommended-num">{facebookRecommendations.negative ? facebookRecommendations.negative.count : 0}</div>
                                </div>
                                <div className="facebook-recommendations-percentage">
                                    <div>{facebookRecommendations.positive ? facebookRecommendations.positive.percentage : '0%'}</div>
                                    <div>{facebookRecommendations.negative ? facebookRecommendations.negative.percentage : '0%'}</div>
                                </div>
                            </>
                        }
                    </div>
                }
            </div>
        </div>
    )
}
export default RatingByBreakDown