import React, {useEffect, useState} from 'react';
import { Grid, FormControl, MenuItem, Select } from '@mui/material';
import MySelect from '../../../helpers/mySelect';
import { countries } from '../../../../Helpers/countries';
import { americaStates, canadaStates, mexicoStates } from '../../../../Helpers/states';
import MaskedInput from 'react-text-mask'
import { FlagIcon } from "react-flag-kit";
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';

const BusinessLocationInformation = ({ formData, setFormData, handleInputChange }) => {
    const [mask, setMask] = useState(['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/])

    const countryChange = (value) => {
        setFormData({ ...formData, ['country']: value });            
    }

    const [t] = useTranslation()

    return (
        <div>
            <div className='business-location location_settings_card'>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div>
                            <h5 className='title'>{t("Business Location Information")}</h5>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Street Address")}</label>
                            <input
                                name='street'
                                type="text"
                                placeholder=''
                                value={formData?.address}
                                onChange={(e) => handleInputChange('address', e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Zip")}</label>
                            <input
                                name="zipCode"
                                type="text"
                                placeholder=''
                                value={formData?.zip}
                                onChange={(e) => handleInputChange('zip', e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Country")}</label>
                            <FormControl fullWidth>
                                <Select
                                    id="input-type-one-select"
                                    label="position"
                                    value={formData.country ? formData.country : "US"}
                                    onChange={(e) => countryChange(e.target.value)}
                                    input={<MySelect />}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                >
                                <MenuItem value={undefined}><em>None</em></MenuItem>
                              	{countries.map(country => {
                                        return (
                                            <MenuItem key={country.code3A} value={country.code2A}><FlagIcon code={country.code2A}  style={{width: "20px", height: "15px", marginRight: "10px"}}/>{country.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("State")}</label>
                            {formData.country !== "US" && formData.country !== "CA" && formData.country !== "MX" ? 
                              <input
                                name="state"
                                type="text"
                                placeholder='state'
                                value={formData?.state}
                                onChange={(e) => handleInputChange('state', e.target.value)}
                            />
                            :   
                            <FormControl fullWidth>
                                <Select
                                    id="input-type-one-select"
                                    label="position"
                                    value={formData.state ? formData.state : americaStates[0].code2A}
                                    onChange={(e) => handleInputChange('state', e.target.value)}
                                    input={<MySelect />}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                >
                                {formData.country === "US" ? 
                                    americaStates.map(state => {
                                        return(
                                            <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                        )
                                    })
                                    : formData.country === "CA" ?
                                    canadaStates.map(state => {
                                        return(
                                            <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                        )
                                    })
                                    : formData.country === "MX" && 
                                    mexicoStates.map(state => {
                                        return(
                                            <MenuItem key={`state${state.name}`} value={state.code2A}>{state.name}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl> 
                            }
                        
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("City")}</label>
                            <input
                                name='city'
                                type="text"
                                placeholder=''
                                value={formData?.city}
                                onChange={(e) => handleInputChange('city', e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group phone-group-wrap'>
                            <label htmlFor="">{t("Phone")}</label>
                            {/* <MaskedInput
                                mask={mask}
                                className="form-control"
                                placeholder="Enter a phone number"
                                guide={false}
                                id="my-input-id"
                                onBlur={() => {}}
                                value={formData?.phone}
                                onChange={(e) => handleInputChange('phone', e.target.value)}
                            /> */}
                            <PhoneInput
                                inputProps={{
                                    name: 'phone',
                                }}
                                enableSearch
                                country={"us"}
                                value={formData?.phone}
                                onChange={(val) => handleInputChange("phone", val)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Website")}</label>
                            <input
                                name="website"
                                type="text"
                                placeholder=''
                                value={formData?.website}
                                onChange={(e) => handleInputChange('website', e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className='form-group'>
                            <label htmlFor="">{t("Review Link")}</label>
                            <input
                                name="landing_url"
                                type="text"
                                placeholder=''
                                value={formData?.landing_url}
                                onChange={(e) => handleInputChange('landing_url', e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default BusinessLocationInformation;