import React from "react"
import { Skeleton, Grid } from "@mui/material"

const DashboardSkeleton = () => {
    return(
        <div>
            <Grid container spacing={2} style={{marginTop: "20px"}}>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: "10px"}}>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "400px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "400px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "400px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "400px"}}/>
                </Grid>
            </Grid>
            <Grid container style={{marginTop: "20px"}}>
                <Grid item xs={12}>
                    <Skeleton style={{transform: "unset", height: "400px"}}/>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: "20px", marginBottom: "20px"}}>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Skeleton style={{transform: "unset", height: "185px"}}/>
                </Grid>
            </Grid>
        </div>
    )
}
export default DashboardSkeleton