import React, { useEffect, useState } from "react"
import { formatHex } from "../../helpers/formatHex"
import { useSelector } from "react-redux"
import { Skeleton } from "@mui/material"
import { reviewTagsFilter } from "../../../../redux/actions/admin-index"
import {useDispatch} from "react-redux"
import { BsCheck2Circle } from "react-icons/bs"
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next"

const Tags = () => {
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(["all"])
    const [searchParams, setSearchParams] = useSearchParams();
    const [t] = useTranslation()
    let backTo = searchParams.get("backTo")

    const handleClick = (e) => {
        let id = e.target.value
        if(id === "all" || selected.length == 0){
            setSelected(["all"])
            dispatch(reviewTagsFilter(''))
        } else {
            let newArr = [...selected, id].filter(el => el !== "all")
            if(selected.filter(el => el === id).length > 0){
                setSelected(newArr.filter(el => el !== id))
                dispatch(reviewTagsFilter(newArr.filter(el => el !== id)))
            } else {
                setSelected(newArr)
                dispatch(reviewTagsFilter(newArr))
            }
           
        }
    }

    const tagsFilter = useSelector(state => state.reviewTagsFilter.tags)
    const tools = useSelector(state => state.allLocationSources.tools)

    const tags = tools && Array.prototype.concat(...Object.values(tools.tags))

    useEffect(() => {
        if(selected.length == 0){
            setSelected(["all"])
        }
    }, [selected])

    useEffect(() => {
        if(!backTo){
            setSelected(["all"])
        }
    }, [backTo])

    useEffect(() => {
        if(tagsFilter){
            setSelected(tagsFilter)
        } else {
            setSelected(["all"]) 
        }
    }, [tagsFilter])


    return(
        <div className="filter-manage-tags">
            <div>
                <input type="checkbox" id="all" value={'all'} onChange={(e) => handleClick(e)}/>
                {selected.includes("all") ? 
                        <label htmlFor="all" 
                            style={{color: "#18aee3", borderColor: "#18aee3", backgroundColor: formatHex("#18aee3", 0.1)}}>
                               <BsCheck2Circle/>
                                {t("All Tags")}
                        </label>
                        :
                        <label htmlFor="all" 
                            style={{color: "#18aee3", borderColor: "#18aee3", backgroundColor: formatHex("#18aee3", 0.1)}}>
                                {t("All Tags")}
                        </label>
                    }
            </div>
       
            {tags === undefined ? 
            [...Array(6)].map((el, i) => {
                return(
                    <Skeleton key={`tag-sekeleton${i}`} style={{marginRight: "10px", marginBottom: "10px", width: "86px", height: "35px", borderRadius: "20px", transform: "unset"}}/>
                )
            })
            :
            tags.map(tag => {
                return(
                <div  key={tag.id} >
                    <input type="checkbox" id={`tag${tag.id}`} value={tag.id} onChange={(e) => handleClick(e)}/>
                    {selected.includes(tag.id.toString()) ? 
                        <label htmlFor={`tag${tag.id}`} 
                            style={{color: tag.color, borderColor: tag.color, backgroundColor: formatHex(tag.color, 0.1)}}>
                                <BsCheck2Circle/>
                                {tag.name}
                        </label>
                        :
                        <label htmlFor={`tag${tag.id}`} 
                            style={{color: tag.color, borderColor: tag.color, backgroundColor: formatHex(tag.color, 0.1)}}>
                                {tag.name}
                        </label>
                    }
                </div>
                )
            })}
        </div>

    )
}
export default Tags