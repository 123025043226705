import React, { useState, useEffect} from 'react';
import { Grid, FormControl, MenuItem, Select, Skeleton} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import MySelect from '../../../Helpers/MySelect';
import { useSelector, useDispatch } from 'react-redux';
import { fetchConnectionGoogle ,clearConnectionGoogle, getGroupLocation, getGroupSelected, getLocationSelected } from '../../../../redux/actions/admin-index';
import { showAlert } from '../../../../redux/actions';
import AlertComponent from "../../../Helpers/Alert"
import {Alert} from "@mui/material";
import axios from 'axios';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const GoogleSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [account, setAccount] = useState(" ");
    const [locations, setLocations] = useState("");
    const [loc, setLocation] = useState(" ");
    const [t] = useTranslation()
    const location = useSelector(state => state.locationSelected.location)
    const connection = useSelector(state => state.getConnectionGoogle.connection_google)
    const selected = useSelector(state => state.groupLocationSelected.group_location)

     useEffect(() => {
        if(selected && (!location || location == "all")){
            dispatch(clearConnectionGoogle())
            setLocations([])
            dispatch(fetchConnectionGoogle(selected, searchParams.get("code")))
        }
    }, [selected])

    useEffect(() => {
        let paramloc = searchParams.get("location")
        if(paramloc){

            dispatch(getLocationSelected("all"))
            dispatch(getGroupSelected(""))
            dispatch(getGroupLocation(paramloc))
    
            localStorage.setItem('group', "");
            localStorage.setItem('location', "all");
            localStorage.setItem("location_of_group", paramloc)
        }
    }, [])

    useEffect(() => {
        if(location && location !== "all"){
            dispatch(clearConnectionGoogle())
            setLocations([])
            dispatch(fetchConnectionGoogle(location, searchParams.get("code")))
        }
    }, [location])

    useEffect(() => {
        if(connection && connection.link){
            window.location.href = connection.link
        } else {
            let selectedAcc = connection && connection.find(con => con.accounts.selected) 
            setAccount(selectedAcc ? selectedAcc.accounts.name : " ")
            selectedAcc && onAccountChange(selectedAcc.accounts.name)
            selectedAcc && setLocation(selectedAcc.locations.find(loc => loc.selected)  ? selectedAcc.locations.find(loc => loc.selected).name : " ") 
        }
    }, [connection])


    if(connection === undefined){
        return(
            <div>
                <Skeleton style={{height: "150px", width: "70%", transform: "none"}}/>
            </div>
        )
    }

    if(connection && connection.link){
        return(
            <div className='header-container'>
                <h6>{t("You need to connect")}</h6>
            </div>
        )
    }

    const onAccountChange = (value) => {
        setAccount(value)
        let findLoc = connection && connection.filter(el => el.accounts.name === value)[0].locations
        setLocations(findLoc)
    }

    const PostGoogleSettings = () => {
        if(selected && (!location || location == "all")){
            apiRequest(selected)
        }
        if(location && location !== "all"){
            apiRequest(location)
        }
    }

    const apiRequest = (id) => {
        setLoading(true)
        let google_acc_name = account
        let google_acc_loc = `${account}/${loc}`

        if(google_acc_name && google_acc_loc){
            axios.post(`${baseUrlDev}locations/${id}/connections/google`, {google_acc_name, google_acc_loc}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then((res) => {
                setLoading(false)
                setErrors([])
                dispatch(showAlert(true, "Successful Connection!"));
                setTimeout(()=>{
                  dispatch(showAlert(false,""));
                },5000);  
                navigate("/app/admin/reviews/connections")
            }).catch((err) => {
                setLoading(false)
                err?.response?.data?.errors ?
                Object.keys(err.response.data.errors).map(key => {
                    setErrors(errors => [...errors, err.response.data.errors[key][0]])
                })
                :
                setErrors(["Something Went Wrong"])
                setTimeout(() => {
                    setErrors([])
                }, 5000)
            })
        } else {
            !google_acc_name && setErrors(prev => [...prev, "Please Select Account"])
            !google_acc_loc && setErrors(prev => [...prev, "Please Select Location"])
        }
    }

    return (
        <div className='account_settings_page'>
            <AlertComponent/>
            {errors && errors.map(err => {
                return(
                    <div className="alert-inside-modal" key={err}>
                        <Alert severity="error" className='custom-error-alert'>{err}</Alert>
                    </div>
                )
            })}
            <form action="">
                <Grid container>
                    <Grid item xs={8}>
                        <div className="accounts_settings_wrap">
                            <div>
                                <h2 className="title">{t("Google Settings")}</h2>
                            </div>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <label htmlFor="">{t("Account")}</label>
                                    <FormControl fullWidth>
                                        <Select
                                            id="input-type-one-select"
                                            label="position"
                                            value={account}
                                            onChange={(e) => onAccountChange(e.target.value)}
                                            input={<MySelect />}
                                        >
                                            <MenuItem disabled value=" ">
                                                <em>{t("Select Account")}</em>
                                            </MenuItem>
                                            {connection && connection.map(con => {
                                                return(
                                                    <MenuItem key={con.accounts.name} value={con.accounts.name}>{con.accounts.accountName}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <label htmlFor="">{t("Locations")}</label>
                                    <FormControl fullWidth>
                                        <Select
                                            id="input-type-one-select"
                                            label="position"
                                            value={loc}
                                            onChange={(e) => setLocation(e.target.value)}
                                            input={<MySelect />}
                                        >
                                            <MenuItem disabled value=" ">
                                                <em>{t("Select Location")}</em>
                                            </MenuItem>
                                            {locations && locations.map(loc => {
                                                return(
                                                    <MenuItem key={loc.name} value={loc.name}>{loc.title} - {loc.storefrontAddress?.addressLines[0] }</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </div>
                        <div className='info-page-foot'>
                            <LoadingButton
                                    style={{paddingLeft: loading && "30px"}}
                                    className="btn add_btn blue-loading-btn"
                                    onClick={() => PostGoogleSettings()}
                                    loading={loading}
                                >
                                    {t("Save Changes")}
                            </LoadingButton>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default GoogleSettings
