import React from 'react';
import { useTranslation } from 'react-i18next';

const HandleFileUpload = ({ file, handleFileChange }) => {
    const {t} = useTranslation()
    return (
        <div className='handle-file-upload'>
            <div className="d-flex align-items-center flex-wrap gap-10">
                <div className="image-uploaded">
                    <img src={file ? file : "/admin-images/tools/upload-image.svg"} id="imgLogoPreview" alt="" />
                </div>
                <div className="position-relative">
                    <input type="file" id="logo-img" name="logo_image" onChange={handleFileChange} />
                    <button className="btn upload-btn">{t('Upload Logo')}</button>
                </div>
            </div>
        </div>
    )
}

export default HandleFileUpload;
