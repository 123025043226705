/* eslint-disable */
import  React,{useState,useEffect, useRef} from 'react';
import axios from 'axios';

import { useSelector,useDispatch } from 'react-redux';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import {AiOutlineClose} from 'react-icons/ai'
import { baseUrl } from '../../Helpers/baseUrl';
import { fetchCompanieData, showAlert } from '../../../redux/actions';
import { useParams } from 'react-router-dom';
import AlertComponent from '../../Helpers/Alert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius:"8px"
};

export default function WriteReview({companyName,setSortby, irevu}) {

//////////////
  const [open, setOpen] = React.useState(false);
  const {name} = useParams();

  const companyDataSelect = useSelector(state => state.companieAboutReducer.companie);
  const initFormState = {
    company_id: null,
    first_name: "",
    last_name:"",
    rating: 0,
    phone_number:"",
    content:"",
    email:"",
    employee_name: "",
  };
  const [formData,setFormData] = useState(initFormState);
  const dispatch = useDispatch();

  const [status,setStatus] = useState({
    type: null, // ["error","warning","info","success",null]
    message:""
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setFormData({...formData, rating: 0});
  };

  useEffect(() => {
    if(!companyDataSelect) return;

    initFormState.company_id = companyDataSelect?.company?.id;
    setFormData((pState)=>{
        return {
            ...pState,
            company_id:companyDataSelect?.company?.id
        }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companyDataSelect]);


  function submitTReview(){
    setStatus({type:null});
    let whoIsEmptyInside = areEmptyOrNull(["first_name","last_name","content","email"],["First Name","Last Name","Content","Email"]);
    if(whoIsEmptyInside){
        setStatus({type:"error",message: whoIsEmptyInside+" is required to be filled!"});
        return;
    }
    if(formData.rating  === 0){
        setStatus({type:"error",message:"None of rating was not selected!"});
        return;
    }
    if(!formData.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
        setStatus({type:"error",message:"Email format incorrect!"});
        return;
    }
    sendTReviewRequest();
  }

  function areEmptyOrNull(array,arrayFormal){
    for(var i=0;i<array.length;i++){
        if(formData[array[i]] === "" || formData[array[i]] === null){
            return arrayFormal[i] ? arrayFormal[i] : array[i]
        }
    }
    return null
  }

  function sendTReviewRequest(){
    let fd = new FormData();
    for(let [key,value] of Object.entries(formData)){
        fd.append(key,value);
    }
    axios({
        method:"POST",
        url: irevu ? baseUrl+"reviews/irevu" :  baseUrl+"reviews",
        data:fd
    }).then((response)=>{
    
        setStatus({
            type:"success",
            message:"Review submited!"
        });
        dispatch(showAlert(true, "Review submited Succesfully!"));
        
        setTimeout(()=>{
          dispatch(showAlert(false,""));
        },5000);  

        setFormData({...initFormState,rating:formData.rating});
        
        setSortby("latest "); 
        handleClose();
        //dispatch(fetchCompanieData(name,0,'latest')); 
    }).catch((error)=>{
        setStatus({
          type:"error",
          message:"Something failed check network!"
        })
    });
  }
  return (
    <div>
      <AlertComponent/>
      <Rating icon={<img src={"/admin-images/filled-icon.svg"} className="custom-rating-box"/>} emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-box"/>} name="half-rating-read" value={Number(formData.rating)} onChange={(e)=>{ 
        handleOpen()
        setFormData({...formData,rating:e.target.value}) }}/>
      <button className='add-rev-btn' variant="contained"  disabled={!companyName} onClick={handleOpen}>Write a review</button>
      <Modal
        disableRestoreFocus 
        id="write-review"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      
        <Box sx={style} className='about-section'>
          <span className='close-button-modal c-pointer' onClick={handleClose}><AiOutlineClose size="1.2em"/></span>
            <h1>Contact Us Directly</h1>
            <Box sx={{mb:2,textAlign:"center"}}>
                <div className='subtitle-rev'>Overall rating</div>
                <Rating icon={<img src={"/admin-images/filled-icon.svg"} className="custom-rating-ic-big"/>} emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-ic-big"/>} sx={{fontSize:"40px"}} value={Number(formData.rating)} onChange={(e)=>{ setFormData({...formData,rating:e.target.value}) }} name="size-large"  size="large" />
            </Box>

            {formData.rating !== 0 &&  
            <div className='custom-scrollbar-vertical max-height-review'>
              <Box sx={{mb:2}}>
                  <label>Name</label>
                  <input type="text" value={formData.first_name} onChange={(e)=>{setFormData({...formData,first_name:e.target.value})}}   label="First Name" variant="outlined" />
              </Box>
              <Box sx={{mb:2}}>
                  <label>Last Name</label>
                  <input type="text" value={formData.last_name} onChange={(e)=>{setFormData({...formData,last_name:e.target.value})}}   label="Last Name" variant="outlined" />
              </Box>
              <Box sx={{mb:2}}>
                  <label>Email Address</label>
                  <input type="email" value={formData.email} onChange={(e)=>{setFormData({...formData,email:e.target.value})}}   label="Email" variant="outlined" />
              </Box>
              <Box sx={{mb:2}}>
                  <label>Phone Number (Optional)</label>
                  <input type="phone" value={formData.phone_number} onChange={(e)=>{setFormData({...formData,phone_number:e.target.value})}}   label="Phone Number (Optional)" variant="outlined" />
              </Box>
              <Box sx={{mb:2}}>
                  <label>Employee Name (Optional)</label>
                  <input type="text" value={formData.employee_name} onChange={(e)=>{setFormData({...formData,employee_name:e.target.value})}}  label="Employee Name (Optional)" variant="outlined" />
              </Box>
              <Box sx={{mb:2}}>
                  <textarea value={formData.content} onChange={(e)=>{setFormData({...formData,content:e.target.value})}}  rows={4} label="Your review" placeholder='Share details of your own experience at this place' />
              </Box>
              <Box sx={{mb:1}}>
                  {status.type !== null && <Alert severity={status.type}>{status.message}</Alert>}
              </Box>
      
            </div>
            }
            {formData.rating !== 0 &&        
              <Box>
                  <button onClick={submitTReview} className="submit-review">Submit</button>
              </Box>
              }
        </Box>
      </Modal>
    </div>
  );
}