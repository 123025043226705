import React from "react"
import { Link } from "react-router-dom"
import {FiEyeOff} from "react-icons/fi"

const OnlyOwner = () => {
    return(
        <div className='locked-page'>
            <div>
                <div className="slash-eye-wrapper"><FiEyeOff/></div>
                <h1>Access Denied</h1>
                <p>Access Restricted to Owner Only.</p>
            </div>
        </div>
    )
}
export default OnlyOwner