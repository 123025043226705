import React from "react"
import { Skeleton, Grid } from "@mui/material"

const ReviewPageSkeleton = () => {
    return(
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
                <Skeleton style={{width: "100%", height: "100px", transform: "unset"}} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Skeleton style={{width: "100%", height: "100px", transform: "unset"}} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Skeleton style={{width: "100%", height: "100px", transform: "unset"}} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Skeleton style={{width: "100%", height: "100px", transform: "unset"}} />
            </Grid>
            <Grid item xs={12} md={8}>
                <Skeleton style={{width: "100%", height: "200px", transform: "unset", marginBottom: "10px"}} />
                <Skeleton style={{width: "100%", height: "200px", transform: "unset", marginBottom: "10px"}} />
                <Skeleton style={{width: "100%", height: "200px", transform: "unset"}} />
            </Grid>
            <Grid item xs={12} md={4}>
                <Skeleton style={{width: "100%", height: "620px", transform: "unset"}} />
            </Grid>
        </Grid>
    )
}
export default ReviewPageSkeleton