import React, {useState, useEffect} from "react"
import { Rating, Collapse } from "@mui/material"
import { formatHex } from "../../helpers/formatHex"
import { Link } from "react-router-dom"
import {BsPinAngle} from "react-icons/bs"
import AssignTagModal from "./assignTagsModal"
import { AiFillStar, AiOutlinedStar } from "react-icons/ai"
import axios from "axios"
import { showAlert } from "../../../../redux/actions"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { useDispatch } from "react-redux"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import {IoIosCheckmark} from "react-icons/io"
import LightTooltip from "../../helpers/lightTooltip"
import {BiCommentCheck} from "react-icons/bi"
import { useSelector } from "react-redux"

const ReviewItemList = ({showCheckboxes, review, checked, setChecked}) => {
    const dispatch = useDispatch()
    const [animate, setAnimate] = useState(false)
    const [rev, setRev] = useState({...review})
 
    
    const updateTags = (tags) => {
        setRev({...rev, tags: tags})
    }

    useEffect(() => {
        setRev({...review})
    }, [review])

    useEffect(() => {
        setAnimate(true)
    }, [])

    const redirect = (e, href) => {
        e.preventDefault()
        e.stopPropagation()
        window.open(href ? href : 'https://irevu.com', '_blank')
    }

    let ratingNum = 5 - (rev.rating ? rev.rating : 0)

    const pinReview = () => {
        setRev({...rev, is_pinned: 0})
        axios.post(`${baseUrlDev}reviews/${review.id}/pin`, {pin: 0}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(res => {
            dispatch(showAlert(true, "Review is unpinned successfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => console.log(err))
    }


    const handleChecked = (e) => {
        const {value} = e.target
        if(checked.includes(parseInt(value))){
            setChecked(checked.filter(el => el != value))
        } else {
            setChecked(prev => [...prev, parseInt(value)])
        }
    }

    const groupSelected = useSelector(state => state.groupSelected.group)
    const location = useSelector(state => state.locationSelected.location)
    const keyword = useSelector(state => state.reviewKeyword.keywords)
    const sourcesFilter = useSelector(state => state.reviewSourceFilter.source)
    const status = useSelector(state => state.reviewStatusFilter.status)
    const rating = useSelector(state => state.reviewRatingFilter.rating)
    const orderState = useSelector(state => state.reviewSortOrder.order)
    const tagsFilter = useSelector(state => state.reviewTagsFilter.tags)
    const from = useSelector(state => state.reviewRangeFilter.dateFrom)
    const to = useSelector(state => state.reviewRangeFilter.dateTo)
    const page = useSelector(state => state.paginationReviews.pagination)


    const queryParams = {
        location,
        group_id: groupSelected,
        keyword,
        "sources": sourcesFilter,
        status,
        rating,
        "order": orderState,
        "tags" : tagsFilter,
        from,
        to,
        page,
    };

    const filteredParams = Object.fromEntries(
        Object.entries(queryParams).filter(([_, value]) => value)
    );

    const queryString = Object.keys(filteredParams)
    .map(key => `${key}=${filteredParams[key]}`)
    .join('&');

    return(
        <div className="review-item-wrapper" id={rev.id}>
            {showCheckboxes && 
                <>
                   <input id={`checkbox-${rev.id}`} type="checkbox" name="review" value={rev.id} checked={checked.includes(rev.id)} onChange={(e) => handleChecked(e)}/>
                   <label htmlFor={`checkbox-${rev.id}`} className="review-item-checkbox"><IoIosCheckmark/></label>
                </>
            }
            <Link to={`/app/admin/reviews/list/${rev.id}?${queryString}`}>
                <div className="review-item-container">
                    <div className="review-item-container-head">
                        <div className="review-item-container-user">
                            <img src={rev.source ? (rev.source.logo ? rev.source.logo : "/admin-icons/feedback-irevu.svg") : "/admin-icons/feedback-irevu.svg"} platform="irevu" alt={"logo"}/>
                            <div>
                                <div className="review-info-holder">
                                    <div style={{display: "block"}}>
                                        <h4>{rev.author.name} - <span onClick={(e) => redirect(e, rev.source && rev.source.domain)}>
                                                {rev.source ? rev.source.name : 'Irevu'}
                                            </span>
                                        </h4>
                                        <span className="span-date">{rev.published_at_formatted}</span>
                                        {/* <Rating icon={<img src={filledIcon} alt="filled-star" className="custom-rating-box-sm"/>} emptyIcon={<img src={emptyIcon} alt="empty-star" className="custom-rating-box-sm"/>} value={review.rating} readOnly /> */}
                                        <div className="rating">
                                            {
                                                [...Array(5 - ratingNum)].map((el, i) => {
                                                    return(
                                                        <AiFillStar key={`start-filled-${i}`} className={`star ${animate ? 'animate' : 'default'}`}/>
                                                    )
                                                })

                                            }
                                                    {
                                                [...Array(ratingNum)].map((el, i) => {
                                                    return(
                                                        <AiFillStar key={`start-default-${i}`} className='star default'/>
                                                    )
                                                })

                                            }
                                        </div>
                                    </div>
                                    {rev.tags && rev.tags.length > 0 && 
                                        <div>
                                            {rev.tags.map(tag => {
                                                return(
                                                    <label key={`tag-inside-review${tag.id}`} 
                                                        style={{color: tag.color, borderColor: tag.color, backgroundColor: formatHex(tag.color, 0.1)}}>
                                                        {tag.name}
                                                    </label>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {rev.is_pinned == 1 && 
                            <BsPinAngle className="pinned-item" onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                pinReview()
                            }}/>
                        }
                        <div className="review-item-container-actions">
                            <AssignTagModal 
                                reviewLoc={rev.location.id} 
                                reviewId={rev.id} 
                                tagsSelectedProp={rev.tags && rev.tags.length > 0 && rev.tags.map(tag => {return tag.id})}
                                updateTags={updateTags}
                            />
                        </div>
                    </div>
                    <div className={`review-item-container-body ${rev.responded && "flag-review-item"}`}>
                        {rev.content}
                        {rev.responded && 
                            <LightTooltip title={"Review has been responded"}>
                                <span style={{position: "absolute", right: "0", top: "0", padding: " 0 15px"}}>
                                    <BiCommentCheck style={{fontSize: "20px", color: "#70C996"}}/>
                                </span>
                            </LightTooltip>
                        }
                    </div>
                    <div className="review-item-container-footer">
                        <div className="location-information"><img src={"/admin-icons/search-icon.svg"} alt="location"/>{rev.location.name} - {rev.location.address}</div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
export default ReviewItemList