import React, {useEffect} from "react"
import { Modal, Slide, Skeleton} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccountAgreements } from "../../../../../redux/actions/admin-index";

const Agreements = ({open, setOpen}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        dispatch(fetchAccountAgreements())
    }, [])

    const agreements = useSelector(state => state.getAgreements.agreements)
    
    const exportAgreementPdf = (url, date) => {
        window.open(url, '_blank');
    }
    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-tag-title"
            aria-describedby="modal-tag-description"
        >
        <Slide direction="left" in={open} {...({ timeout: 500 })}>
            <div className={`custom-modal-dialog`}>
         
                <div className="notification-modal-body">
                    <div className="custom-modal-header">
                        <h5>{t("Agreements")}</h5>
                    </div>
                    <div className="custom-modal-content">
                        <div className="scrollable-div-container custom-scrollbar-vertical">
                            {agreements === undefined ? 
                                <div style={{padding: "20px"}}>
                                    <Skeleton style={{width: "100%", height: "30px", transform: "unset", marginBottom: "10px"}}/>
                                    <Skeleton style={{width: "100%", height: "30px", transform: "unset", marginBottom: "10px"}}/>
                                    <Skeleton style={{width: "100%", height: "30px", transform: "unset", marginBottom: "10px"}}/>
                                    <Skeleton style={{width: "100%", height: "30px", transform: "unset", marginBottom: "10px"}}/>
                                    <Skeleton style={{width: "100%", height: "30px", transform: "unset", marginBottom: "10px"}}/>
                                    <Skeleton style={{width: "100%", height: "30px", transform: "unset", marginBottom: "10px"}}/>
                                </div>
                            :
                            agreements.result && agreements.result.length > 0 ?
                                <ul className="agreements-list">
                                    {agreements.result.map((agr, i) => {
                                        return(
                                            <li key={`agr-${i}`} className="d-flex" onClick={() => exportAgreementPdf(agr.contract, agr.accepted_contract)}>
                                                <span>{t("Agreements signed")} <b>{agr.accepted_contract}</b>  -</span>
                                                <span><img src="/admin-images/download-pdf.svg" width="16" alt="" /></span>
                                            </li>
                                        )
                                    })}
                              
                                </ul>
                            :
                            <div className="agreements-list">
                                 <span> {t("No Agreements yet")}</span>
                            </div>   
                            }
                        </div>
                    </div>
                    <div className="custom-modal-footer">
                        <button className="close-btn" onClick={() => handleClose()}>{t("Close")}</button>
                    </div>
                </div>
            </div>
        </Slide>
    </Modal>
    )
}
export default Agreements