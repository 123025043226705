import React from "react";
import { Modal, Slide} from "@mui/material";

const SetupSuggestionModal = ({open, setOpen, modalProps}) => {
    const handleClose = () => {
        setOpen(false)
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-location-title"
            aria-describedby="modal-location-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className={`custom-modal-dialog`}>
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>Download and Login to Mobile App</h5>
                        </div>
                    </div>
                    <div className="custom-modal-content setupsuggestion-modal">
                        <div className="scrollable-loc-container custom-scrollbar-vertical">
                            <div className="wrapper">
                                <div className="ios-wrap">
                                    <h4 className="title">{modalProps?.ios?.name}</h4>
                                    <img src={modalProps?.ios?.image} width="200" alt="google play" />
                                </div>
                                <div className="android-wrap">
                                    <h4 className="title">{modalProps?.android?.name}</h4>
                                    <img src={modalProps?.android?.image} width="200" alt="app store" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-modal-footer">
                        <button className="close-btn" onClick={() => handleClose()}>Close</button>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default SetupSuggestionModal