import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import AnalysisBox from "../../Analysis_Report/AnalysisBox"
import ProgressBoxDetail from './ProgressBoxDetail';
import SourceLocation from "../../../modals/listings/SourceLocation"
import {useDispatch, useSelector} from "react-redux"
import ListingSkeleton from '../../../helpers/listingSkeleton';
import { fetchLocationList, clearLocationList } from '../../../../../redux/actions/admin-index';
import Header from '../../../../Helpers/header';
import useHandleDisable from '../../../helpers/disableSelect';
import { useTranslation } from 'react-i18next';

const SourceScore = () => {
  const handleDisable = useHandleDisable()
  const dispatch = useDispatch()
  const {id} = useParams()
  const [url, setUrl] = useState()
  const [s_id, setId] = useState()
  const [open, setOpen] = useState(false);
  const {t} = useTranslation()
  useEffect(() => {
    dispatch(clearLocationList())
    dispatch(fetchLocationList(id))
    handleDisable()
  }, [])

  const locList = useSelector(state => state.getLocationList.loc_list)

  if(locList === undefined){
    return(
      <ListingSkeleton title={'Multi-Location Listing'} details={true} />
    )
  }

  return (
    <div className="source_score_page">
        <div className="flex-header-container">
          <Header title="Location Listings"  subtitle="Multi-Location Listings"/>
        </div>
  
        <div className='mt-20'>
          <Grid container spacing={2}>
            <AnalysisBox title="Listing score" xs={12} md={12} xl={12}>
              <ProgressBoxDetail locList={locList}/>
            </AnalysisBox>
  
            <Grid item xs={12} md={12} xl={12}>
              <div className="table_wrapper custom-horizontal-scrollbar">
                <table className="general__table">
                  <thead>
                    <tr>
                      <th>{t("Company Name")}</th>
                      <th>{t("Source Name")}</th>
                      <th>{t("Listing score")}</th>
                      <th>{t("Max Score")}</th>
                      <th>{t("Status")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {locList.score.map(sc => {
                      return(
                        <tr>
                          <td>{sc.location?.companyName}</td>
                          <td>{sc.name && sc.name}</td>
                          <td>{sc.listing_score && sc.listing_score}</td>
                          <td>{sc.maximum_score && sc.maximum_score}</td>
                          <td>{sc.sync_status && sc.sync_status}</td>
                          <td>
                            <div className="mydev-datatable-actions">
                              <button
                                className="edit-lead-btn"
                                  onClick={() => {
                                    setId(sc.id)
                                    setUrl(sc.url)
                                    setOpen(true);
                                  }}
                                >
                                <span className="icon-mydevicon-edit-bordered"></span>
                                {t("Edit")}
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
            
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          <SourceLocation open={open} setOpen={setOpen} id={s_id} url={url} setUrl={setUrl}/>
        </div>
    </div>
  );
  
}

export default SourceScore;