import React, {useEffect, useState} from "react"
import { Modal, Grid } from "@mui/material"
import { formatDateCard, formatCardNumber } from "../../helpers/formatCard"
import { LoadingButton } from "@mui/lab"
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { Alert } from "@mui/material"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { clearWallet } from "../../../../redux/actions/admin-index"

const CreateCard = ({setOpen, customerId, setHasWallet}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [focusCard, setFocusCard] = useState(false)
    const [cvvFocused, setCvvFocused] = useState(false)
    const [expiryFocused, setExpiryFocused] = useState(false)
    const [nameFocused, setNameFocused] = useState(false)
    const [lastNumbers, setLastNumber] = useState()
    const [errors, setErrors] = useState([])
    const {t} = useTranslation()
    const [cartDetails, setCartDetails] = useState({
        name: "",
        email: "",
        card_number: "",
        expiry: "",
        cvc: "",
       
    })

    const handleClose = () => {
        setOpen(false)
        let resetDetails = {
            name: "",
            email: "",
            card_number: "",
            expiry: "",
            cvc: "",
          
        }
        setCartDetails(resetDetails)
    }

    const handleChange = (e) => {
        setErrors([])
        const {name, value} = e.target
        setCartDetails({...cartDetails, [name] : value})
    }

    const handleExpiryChange = (e) => {
        const {name, value} = e.target
        if (value.length <= 7) {
            setCartDetails({...cartDetails, [name] : value})
        }
        
    }

    const handleCardNumber = (e) => {
        const {name, value} = e.target
        if(value.length <= 19 && e.target.validity.valid){
            setCartDetails({...cartDetails, [name] : value})
        } else {
            return false
        }
    }

    const handleCvvNumber = (e) => {
        const {name, value} = e.target
        if(value.length <= 4 ){
            setCartDetails({...cartDetails, [name] : value})
        } else {
            return false
        }
    }

    const create = () => {
        setLoading(true)
        let data = {
            name: cartDetails.name,
            number: cartDetails.card_number,
            expiry: cartDetails.expiry,
            cvv: cartDetails.cvc
        }
        axios.post(`${baseUrlDev}wallet/save-card`, data, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(() => {
            setHasWallet(true)
            setLoading(false)
        })
        .catch(err => {
            setLoading(false)
            setErrors(errors => [...errors, "Something Went Wrong, Please Try Again!"])
            setTimeout(() => {
                setErrors([])
            }, 5000)
        })
    }

    return(
        <div className="wrapper-loc-edit-cart">
            {errors && errors.map(err => {
                return(
                    <div className="alert-inside-modal" key={err}>
                        <Alert  severity="error" className="custom-error-alert">{err}</Alert>
                    </div>
                )
            })}
            <div className="cart-details-container">   
                <h4 className="card-title">{t("Card")}</h4>
                <div className="cart-details-body custom-scrollbar-vertical">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <div className="cart-form-inputs">
                                        <label>{t("Name on card")}</label>
                                        <input 
                                            type="text" 
                                            name="name" 
                                            value={cartDetails.name} 
                                            onChange={(e) => handleChange(e)}
                                            onFocus={() => setNameFocused(true)}
                                            onBlur={() => setNameFocused(false)}
                                        />
                                    </div>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                                    <div className="cart-form-inputs">
                                        <label>Email</label>
                                        <input 
                                            type="email" 
                                            name="email" 
                                            value={cartDetails.email} 
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <div className="cart-form-inputs">
                                        <label>{t("Card Number")}</label>
                                        <input 
                                            placeholder="1244 1244 1244 1244"
                                            maxLength="19"
                                            type="text" 
                                            name="card_number" 
                                            value={cartDetails.card_number && formatCardNumber(cartDetails.card_number)} 
                                            onChange={handleCardNumber}
                                            pattern="[0-9\s]*"
                                            onFocus={() => setFocusCard(true)}
                                            onBlur={() => setFocusCard(false)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="cart-form-inputs">
                                        <label>{t("Expiry")}</label>
                                        <input 
                                            type="text" 
                                            pattern="[0-9/]*"
                                            name="expiry" 
                                            placeholder="MM / YY" 
                                            value={cartDetails.expiry && formatDateCard(cartDetails.expiry)} 
                                            onChange={(e) => handleExpiryChange(e)}
                                            onFocus={() => setExpiryFocused(true)}
                                            onBlur={() => setExpiryFocused(false)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="cart-form-inputs">
                                        <label>CVC</label>
                                        <input 
                                            type="number" 
                                            name="cvc" 
                                            maxLength={4} 
                                            value={cartDetails.cvc} 
                                            onChange={(e) => handleCvvNumber(e)}
                                            onFocus={() => setCvvFocused(true)}
                                            onBlur={() => setCvvFocused(false)}
                                        />
                                    </div>
                                </Grid>
                                {/* <Grid item xs={12} md={4}>
                                    <div className="cart-form-inputs">
                                        <label>Billing Zip</label>
                                        <input 
                                            type="text" 
                                            name="billing_zip" 
                                            value={cartDetails.billing_zip} 
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="card-details-wrapper">
                                <div className="jp-card-container">
                                    <div className={`jp-card ${cvvFocused ? "jp-card-flipped" : ""}`}>
                                        <div className="jp-card-front">
                                            <div className="jp-card-lower">
                                                <div className="jp-card-shiny"></div>
                                                <div className={`jp-card-number jp-card-display jp-card-invalid ${focusCard && 'jp-card-focused'}`}>{cartDetails.card_number ? cartDetails.card_number :  `**** **** **** ****`}</div>
                                                <div className={`jp-card-name jp-card-display jp-card-valid ${nameFocused && 'jp-card-focused'}`}>{cartDetails.name ? cartDetails.name : "NAME SURNAME"}</div>
                                                <div className={`jp-card-expiry jp-card-display jp-card-invalid ${expiryFocused && 'jp-card-focused'}`} data-before="month/year" data-after="validthru">{cartDetails.expiry ? formatDateCard(cartDetails.expiry) : "**/****"}</div>
                                            </div>
                                        </div>
                                        <div className="jp-card-back">
                                            <div className="jp-card-bar"></div>
                                            <div className="jp-card-cvc jp-card-display jp-card-invalid">{cartDetails.cvc ? cartDetails.cvc : "***"}</div>
                                            <div className="jp-card-shiny"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="custom-modal-footer" style={{marginTop: "20px", padding: 0}}>
                    <LoadingButton
                        style={{paddingLeft: loading && "30px"}}
                        className="save-btn"
                        onClick={create}
                        loading={loading}
                    >
                        {t("Save")}
                    </LoadingButton>
                    <button className="close-btn" onClick={() => handleClose()}>{t("Close")}</button>
                </div>
            </div>
        </div>
    )
}
export default CreateCard