import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { LoadingButton } from "@mui/lab";
import {Alert} from "@mui/material"
import BusinessLocationInformation from './BusinessLocationInformation';
import AdditionalBusinessInfo from './AdditionalBusinessInfo';
import BusinessInformation from './BusinessInformation';
import Social from './Social';
import MiscCard from './MiscCard';
import Keywords from './Keywords';
import { fetchLocationInformation, clearLocationInformation, showYoutubeContent, handle404ErrorReset} from '../../../../../redux/actions/admin-index';
import axios from 'axios';
import { getLocalUser } from '../../../../TokenControl/parts/useLocalUser';
import { baseUrlDev } from '../../../../Helpers/baseUrl';
import { showAlert } from '../../../../../redux/actions';
import LocationInformationSkeleton from '../../../helpers/LocationInformationSkeleton';
import AlertComponent from '../../../../Helpers/Alert';
import '../../../../../assets/information.css';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import useHandleDisable from '../../../helpers/disableSelect';


const intialWorkingHours = [
  {
      day: 1,
      day_name: "Monday",
      from: "00:00",
      to: "00:00",
      not_working: 0,
      full_day: false
  },
  {
      day: 2,
      day_name: "Tuesday ",
      from: "00:00",
      to: "00:00",
      not_working: 0,
      full_day: false
  },
  {
      day: 3,
      day_name: "Wednesday",
      from: "00:00",
      to: "00:00",
      not_working: 0,
      full_day: false
  },
  {
      day: 4,
      day_name: "Thursday",
      from: "00:00",
      to: "00:00",
      not_working: 0,
      full_day: false
  },
  {
      day: 5,
      day_name: "Friday",
      from: "00:00",
      to: "00:00",
      not_working: 0,
      full_day: false
  },
  {
      day: 6,
      day_name: "Saturday",
      from: "00:00",
      to: "00:00",
      not_working: 0,
      full_day: false
  },
  {
      day: 7,
      day_name: "Sunday",
      from: "00:00",
      to: "00:00",
      not_working: 0,
      full_day: false
  }
]
const resetData = {
  address: '',
  zip: '',
  country: '',
  state: '',
  city: '',
  phone: '',
  website: '',
  landing_url: '',
  logo: null,
  business_photos: [],
  companyName: '',
  common_business_name: '',
  listing_categories: [],
  employees_number: '',
  year_of_formation: '',
  working_hours: intialWorkingHours,
  hours: '',
  paymentMethods: '',
  landmark: '',
  facebook: '',
  instagram: '',
  twitter: '',
  youtube: '',
  linkedin: '',
  pinterest: '',
  keywords: '',
  landing_url: '',
  irevu_companyName: '',
  services: [],
  products: [],
};
const LocationSettingsEdit = () => {
   const navigate = useNavigate()
   const handleDisable = useHandleDisable()
    const dispatch = useDispatch()
    const {id} = useParams()
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        address: '',
        zip: '',
        country: '',
        state: '',
        city: '',
        phone: '',
        website: '',
        landing_url: '',
        logo: null,
        business_photos: [],
        companyName: '',
        common_business_name: '',
        listing_categories: [],
        employees_number: '',
        year_of_formation: '',
        working_hours: intialWorkingHours,
        paymentMethods: '',
        landmark: '',
        facebook: '',
        instagram: '',
        twitter: '',
        youtube: '',
        linkedin: '',
        pinterest: '',
        keywords: '',
        landing_url: '',
        irevu_companyName: '',
        services: [],
        products: [],
  });
  const [t] = useTranslation()
  const location = useSelector(state => state.getLocationInformation.location_informations)
  const notFound = useSelector(state => state.notFound.notFound);

  useEffect(() => {
      dispatch(handle404ErrorReset())
  }, [])

  if (notFound) {
    navigate("/404")
  }

  useEffect(() => {
      dispatch(showYoutubeContent('https://www.youtube.com/embed/gW477mY0p4M?autoplay=1&mute=0&rel=0'))
  }, [])

  useEffect(() => {
    dispatch(fetchLocationInformation(id))
    handleDisable()
    return () => dispatch(clearLocationInformation());
  }, [])

  useEffect(() => {
    let result = {};
      if(location){
        Object.keys(formData).map((item) => {
            if (item === "business_photos"){
              result["business_photos"] =  location.data.business_photos ? location.data.business_photos : []
            } else if (item === "facebook" || item === "linkedin" ){
              result[item] = location.data[item] ? location.data[item].substring("21") : ""
            } else if (item === "pinterest" || item === "instagram" ){
              result[item] = location.data[item] ? location.data[item].substring("22") : ""
            } else if (item === "youtube" || item === "twitter"){
              result[item] = location.data[item] ? location.data[item].substring("20") : ""
            } else if (item === "working_hours"){
              result[item] = location.data[item].length > 0 ? location.data[item] : intialWorkingHours
            } else if (item === "keywords"){
              result[item] = location.data[item] ? location.data[item].split(",") : []
            } else {
                result[item] = location.data[item] ? location.data[item]: ""
            }
          
        })
        setFormData({...result})
      } else {
        setFormData({...resetData})
      }
  }, [location])

  console.log(formData)
 
  const handleFileChange = (event, setImage, key) => {
    const file = event.target.files[0];
    setImage(prevState => ({ ...prevState, [key]: file }));
  }

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit =  () => {
      let data = new FormData();
      Object.keys(formData).map(key => {
          if(key === "services" || key === "products"){
            data.append(key, formData[key] ? formData[key].join(",") : formData[key])
          } else if (key === "business_photos"){
            formData[key].forEach(f => {
              data.append("business_photos[]", f)
            }) 
          }else if (key === "working_hours"){
            formData[key].forEach((f, index) => {
              data.append(`working_hours[${index}][day]`, f.day)
              data.append(`working_hours[${index}][from]`, f.from)
              data.append(`working_hours[${index}][to]`, f.to)
              data.append(`working_hours[${index}][not_working]`, f.not_working)
              data.append(`working_hours[${index}][full_day]`, f.full_day)
          }) 
          } else if (key === "keywords"){
            data.append(key, formData[key]?.toString())
          } else if (key === "facebook" || key === "instagram" || key === "youtube" || key === "pinterest" || key === "linkedin" || key === "twitter"){
            data.append(key, `https://${key}.com/${formData[key]}`);
          } else {
            data.append(key, formData[key]);
          }  
      })

      setLoading(true)
      axios.post(`${baseUrlDev}locations/settings/${id}/update`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
          setErrors([])
          setLoading(false)
          dispatch(showAlert(true, "Location Information updated successfully"))
          setTimeout(() => {
              dispatch(showAlert(false, ""))
        }, 5000)
      }).catch((err) => {
          setLoading(false)
          Object.keys(err.response?.data.errors).map(key => {
              setErrors(errors => [...errors, err.response.data.errors[key][0]])
          })
          setTimeout(() => {
              setErrors([])
          }, 5000)
      })
  }

  if(location === undefined){
    return(
      <LocationInformationSkeleton/>
    )
  }
  return (
    <div className='location_settings_edit'>
      <AlertComponent/>
      <div className="alert-inside-page">
          {errors && errors.map(err => {
              return(
                  <Alert key={err} severity="error" className='custom-error-alert'>{err}</Alert>
              )
          })}
      </div>
      <div className='flex-header-container'>
        <div className="flex-header-container-title">
          <h1>{t("Locations")}</h1>
          <h6>{t("Edit Location")}</h6>
        </div>
      </div>
        <BusinessLocationInformation formData={formData} handleInputChange={handleInputChange} setFormData={setFormData}/>
        <AdditionalBusinessInfo formData={formData} setFormData={setFormData} handleFileChange={handleFileChange} />
        <BusinessInformation formData={formData} setFormData={setFormData} handleInputChange={handleInputChange} location={location}/>
        <Social formData={formData} handleInputChange={handleInputChange} />
        <MiscCard formData={formData} setFormData={setFormData}/>
        <Keywords formData={formData} handleInputChange={handleInputChange} />
        <div className="info-page-foot">
          <LoadingButton
                type='submit'
                style={{paddingLeft: loading && "30px"}}
                loading={loading}
                onClick={() => handleSubmit()}
            >
                {t("Save")}
            </LoadingButton>
        </div>
    </div>
  )
}

export default LocationSettingsEdit;