import React, {useEffect, useState} from "react"
import {IoIosCheckmark} from "react-icons/io"
import { useTranslation } from "react-i18next"
import { FormControlLabel, Checkbox, Collapse, Skeleton } from "@mui/material"

const SubscriptionServices = ({checked, plan, setPlan, plans, locked, setLocked, aLaCarte, setAlaCarte, aLaCarteItems, aLaCarteAmount}) => {
    const {t} = useTranslation()
    const [lacarte_amount, setLacarteAmount] = useState(60)

    useEffect(() => {
        if(aLaCarteAmount || aLaCarteAmount == 0){
            setLacarteAmount(aLaCarteAmount.toString())
        }
    }, [aLaCarteAmount])

    const lock_type_self = () => {
        setLocked({...locked, self: true, ai: false, aLaCarte: true, subscribed: "Self Service"})
        let SelfServiceid = plans.filter(pl => (pl.name == "Self Service"))[0].plan_id
        let ALacarteId = plans.filter(pl => (pl.name == "À La Carte"))[0].plan_id
        if((plan == SelfServiceid) || (plan == ALacarteId)){
            setPlan("")
        }
    }
    const lock_type_ai = () => {
        setLocked({...locked, self: true, ai: true, aLaCarte: true,  subscribed: "AI Service"})   
        let SelfServiceid = plans.filter(pl => (pl.name == "Self Service"))[0].plan_id
        let ALacarteId = plans.filter(pl => (pl.name == "À La Carte"))[0].plan_id
        let AIcServiceId = plans.filter(pl => (pl.name == "AI Service"))[0].plan_id
        if((plan == SelfServiceid) || (plan == ALacarteId) || (plan == AIcServiceId)){
            setPlan("")
        }
    }

    const lock_type_aLaCarte = () => {
        setLocked({...locked, self: false, ai: false, full: false, aLaCarte: false,  subscribed: "À La Carte"})  
        // if(plan == (plans && plans.filter(pl => (pl.name == "À La Carte"))[0].plan_id)){
        //     setPlan(plans && plans.filter(pl => pl.name == "Self Service")[0].plan_id)
        // }
    }


    useEffect(() => {
        checked.length > 0 ? 
        (
            checked.filter(el => el.type === "Self Service").length > 0 ? lock_type_self()
            : 
            checked.filter(el => el.type === "AI Service").length > 0 ? lock_type_ai()
            :
            checked.filter(el => el.type === "À La Carte").length > 0 ? lock_type_aLaCarte()
            :
            checked.every(el => el.type !== "Demo") ?
            setLocked({...locked,  aLaCarte: true})  
            :
            setLocked({...locked, self: false, ai: false, aLaCarte: false, subscribed: ""})
        ) :  
        setLocked({full: false, self: false, ai: false})
    }, [plan, checked])

    const selectPlan = (e) => {
        const {value} = e.target
        setPlan(value)
    }

    const handleAlaCarteChange = (e) => {
        setLacarteAmount("")
        let {value} = e.target
        if(aLaCarte.filter(el => el == value).length == 0){
            setAlaCarte(prev => [...prev, value])
        } else {
            setAlaCarte(aLaCarte.filter(el => el != value))
        }
    }

    return(
        <div className="sub_plan_container">
            <div className="count-locations">{checked.length} {checked.length > 1 ? "Locations" : "Location"}</div>
            <div className="sub_plan_list">
                {plans && plans.length > 0 && plans.map(pl => {
                    if(pl.name !== 'À La Carte'){
                        return(
                            <div key={pl.plan_id} 
                                className={`sub_plan_list_item ${(locked.self && pl.name === "Self Service") ? "locked" : (locked.full && pl.name === "Full Service") ? "locked" : (locked.ai && pl.name === "AI Service") ? "locked" : ""}`}
                            >
                                <input type="radio" name="plan" id={pl.name} value={pl.plan_id} checked={plan === pl.plan_id} onChange={(e) => selectPlan(e)}/>
                                <label htmlFor={pl.name}>
                                    <div>
                                        <span><IoIosCheckmark/></span>
                                        <h6>{t(pl.name)}</h6>
                                    </div>
                                    <div>
                                        {locked.subscribed === pl.name && <h6>Subscribed</h6>}
                                        <h5>${pl.amount}/<span>{t("Monthly Per Location")}</span></h5>
                                    </div>
                                </label>
                            </div> 
                        )
                    } else if (pl.name == 'À La Carte') {
                        return(
                            <div key={pl.plan_id} 
                                    className={`
                                    sub_plan_list_item_children ${
                                        (locked.aLaCarte && pl.name === "À La Carte") ? "locked" : 
                                        (locked.full && pl.name === "Full Service") ? "locked" : 
                                        (locked.full && pl.name === "Self Service") ? "locked" : 
                                        (locked.ai && pl.name === "AI Service") ? 
                                        "locked" : 
                                    ""}`
                                }
                                >
                                <input type="radio" name="plan" id={pl.name} value={pl.plan_id} checked={plan === pl.plan_id} onChange={(e) => selectPlan(e)}/>
                                <label htmlFor={pl.name}>
                                    <div>
                                        <div>
                                            <span><IoIosCheckmark/></span>
                                            <h6>{t(pl.name)}</h6>
                                        </div>
                                        <div>
                                            {locked.subscribed === pl.name && <h6>Subscribed</h6>}
                                            {!lacarte_amount ? <Skeleton style={{transform: "unset", width: "50px", borderRadius: "3px", backgroundColor: "#C5CCD3", borderColor: "#C5CCD3"}}/> : 
                                                <h5>${lacarte_amount}/<span>{t("Monthly Per Location")}</span></h5>
                                            }
                                        </div>
                                    </div>
                                    <Collapse in={plan === pl.plan_id}>
                                        <div className="a-la-carte-items">
                                            {aLaCarteItems?.map((item, index) => {
                                                return(
                                                    <FormControlLabel 
                                                        key={`${item.id}-${index}`}
                                                        control={
                                                            <Checkbox 
                                                                value={item.value}
                                                                checked={aLaCarte.filter(el => el == item.value).length > 0}  
                                                                onChange={(e) => handleAlaCarteChange(e)}
                                                                size="small"
                                                            />
                                                        } 
                                                        label={t(item.name)} 
                                                    />
                                                )
                                            })}
                                            {(aLaCarte.length == 0 || aLaCarte.every(el => el == "6")) && <span className="alert-inside-plan">{t("aLaCarte-alert")}</span>}
                                        </div>
                                    </Collapse>
                                </label>
                            </div> 
                        )
                    }
                })}
                  
            </div>
        </div>
    )
}

export default SubscriptionServices