import React, {useState} from "react"
import { BiExport } from 'react-icons/bi';
import DateRange from "../../helpers/DateRange";
import { formatDateLine } from "../../helpers/formatDate";
import { fetchLocationAnalysis, clearLocationAnalysis, openExportedAlert } from "../../../../redux/actions/admin-index";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import Header from "../../../Helpers/header"
import { useTranslation } from "react-i18next";
import { exportTable } from "../../helpers/export";
import ExportedAlert from "../../modals/exportedAlert";

const AnalysisHeader = ({setOpenModal, setFrom, setTo}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const location = useSelector(state => state.locationSelected.location)
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)


    const {t} = useTranslation()
    const handleDateChange = (item) => {
        let itemSerialized = {
            startDate: formatDateLine(item.selection.startDate),
            endDate: formatDateLine(item.selection.endDate)
        }
        if(item.selection.startDate !== item.selection.endDate){
            dispatch(clearLocationAnalysis())
        }
        if(!item.selection.startDate){
            dispatch(clearLocationAnalysis())
            if(selected && (!location || location == "all")){
                dispatch(fetchLocationAnalysis(selected, "", ""))
            } else if (location && location !== "all") {
                dispatch(fetchLocationAnalysis(location, "", ""))
            }
            setFrom(null)
            setTo(null)
        } else {
            if(selected && (!location || location == "all")){
                dispatch(fetchLocationAnalysis(selected, itemSerialized.startDate, itemSerialized.endDate))
            } else if (location && location !== "all") {
                dispatch(fetchLocationAnalysis(location, itemSerialized.startDate, itemSerialized.endDate))
            }
            setFrom(itemSerialized.startDate)
            setTo(itemSerialized.endDate)
        }
    }

    const exportTableFunc = () => {
        if(selected && (!location || location == "all")){
            apiRequest(selected)
        } else if (location && location !== "all") {
            apiRequest(location)
        }
    }
    const apiRequest = (id) => {
        setLoading(true)
        exportTable(impersonateReducer, `${baseUrlDev}locations/${id}/analysis/export`, {}, 
        (resData) => {
            dispatch(openExportedAlert(true, resData.message));
            setLoading(false)
        }, 
        (error) => {
            setLoading(false)
        });
    }
    return(
        <div className="analysis_page_header">
            <ExportedAlert/>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div>
                    <Header title="Analysis Report" subtitle="Analysis Report" />
                </div>
                <div className="d-flex align-items-center gap-10 right_side">
                    <button className='add_new_keyword_btn' onClick={() => setOpenModal(true)}><img src={"/admin-icons/plus.svg"} alt="edit"/>{t("New Keyword")}</button>
                    <DateRange handleDateChange={handleDateChange}/>
                    <div className="flex-header-actions">
                        <button
                            style={{ marginLeft: "auto" }} className="competitor-export"
                            onClick={() => exportTableFunc()}
                            disabled={loading}
                        >
                            {loading ?
                                <>
                                <CircularProgress
                                    size={15}
                                    style={{ marginRight: "10px" }}
                                    thickness={4}
                                />
                                {t("Export to Excel")}</>
                            : <> <BiExport /> {t("Export to Excel")}</>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AnalysisHeader