import React, {useState} from "react"
import OnlyLocationWrapper from "../parts/OnlyLocationWrapper"
import TagsTable from "./tagsTable"
import InnerDropdownContainer from "../parts/InnerDropdownContainer"
import { useTranslation } from "react-i18next"
import Header from "../../Helpers/header"

const Tags = () => {
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()

    return(
        <OnlyLocationWrapper>
            <div className="table-header-container">
                <Header title="Review Tags" subtitle="Manage Review Tags"/>
                <button onClick={() => setOpen(true)}>
                    <img src={"/admin-icons/plus.svg"} alt="edit"/>
                    {t("Add New Tag")}
                </button>
            </div>
            <InnerDropdownContainer/>
            <TagsTable open={open} setOpen={setOpen}/>
        </OnlyLocationWrapper>
    )
}
export default Tags