import React from "react"
import LightTooltip from "../../helpers/lightTooltip"
import { IconButton } from "@mui/material"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { AiFillStar } from "react-icons/ai"
import { useTranslation } from "react-i18next"

const TrendingKeywords = ({keywords}) => {
    const [t] = useTranslation()
    let words = keywords.keywords.length > 0 ? keywords.keywords : []
    return(
        <div className="dashboard-box-containers">
            <h2>{t("Trending Keywords")}</h2>
            <LightTooltip title={t("Trending Keywords")}>
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                </IconButton>
            </LightTooltip>
            {words.length > 0 ? 
            <div className="scrollable-box-sm-table custom-scrollbar-vertical d-flex">
                <table className="trending-keywords-table">
                    <thead>
                        <tr>
                            <th>{t("Trending Keywords")}</th>
                            <th>{t("Phrase")}(+/-)</th>
                            <th>{t("Rating")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(words).map((key) => {
                            return(
                                <tr key={`trending${key}`}>
                                    <td>{words[key].name} <span className={words[key].phrase == "Negative"  ? "negative" : words[key].phrase == "Neutral" ? "neutral" : "positive"}></span></td>
                                    <td>{words[key].phrase}</td>
                                    <td><div>{words[key].rating} <AiFillStar/></div></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div> : <div className="nothing-to-show padding-20"> {t("Nothing to Show")} </div>}
        </div>
    )
}
export default TrendingKeywords