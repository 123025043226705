import React, {useState} from "react"
import { Container } from "@mui/system"
import CardList from "./cardList"
import { Grid } from "@mui/material"
import { formatDateCard, formatCardNumber } from "../../helpers/formatCard"
import { useTranslation } from "react-i18next"

const CartDetails = ({cartDetails, setCartDetails, total, plan}) => {
    const [focusCard, setFocusCard] = useState(false)
    const [cvvFocused, setCvvFocused] = useState(false)
    const [expiryFocused, setExpiryFocused] = useState(false)
    const [nameFocused, setNameFocused] = useState(false)
    const {t} = useTranslation()
    const handleChange = (e) => {
        const {name, value} = e.target
        setCartDetails({...cartDetails, [name] : value})
    }

    const handleExpiryChange = (e) => {
        const {name, value} = e.target
        if (value.length <= 7) {
            setCartDetails({...cartDetails, [name] : value})
        }
        
    }

    const handleCardNumber = (e) => {
        const {name, value} = e.target
        if(value.length <= 19 && e.target.validity.valid){
            setCartDetails({...cartDetails, [name] : value})
        } else {
            return false
        }
    }

    const handleCvvNumber = (e) => {
        const {name, value} = e.target
        if(value.length <= 4 ){
            setCartDetails({...cartDetails, [name] : value})
        } else {
            return false
        }
    }

    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div className="cart-details-container">
                    <div className="cart-details-header">
                        <h3>{t("Credit Card Information")}</h3>
                    </div>
                    <div className="cart-details-body">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className="cart-details-body-top">
                                    <div>
                                        <h5>{t("Total Amount")}</h5>
                                        <span>${total}</span>
                                    </div>
                                    <div>
                                        <img src="/admin-icons/visa.svg" alt="visa"/>
                                        <img src="/admin-icons/mastercard.svg" alt="mastercard"/>
                                        <img src="/admin-icons/amex.svg" alt="amex"/>
                                        <img src="/admin-icons/maes.svg" alt="maes"/>
                                        <img src="/admin-icons/discover.svg" alt="discover"/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Name on card")}</label>
                                            <input 
                                                type="text" 
                                                name="name" 
                                                value={cartDetails.name} 
                                                onChange={(e) => handleChange(e)}
                                                onFocus={() => setNameFocused(true)}
                                                onBlur={() => setNameFocused(false)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Email")}</label>
                                            <input type="email" name="email" value={cartDetails.email} onChange={(e) => handleChange(e)}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Card Number")}</label>
                                            <input 
                                                placeholder="1244 1244 1244 1244"
                                                maxLength="19"
                                                type="text" 
                                                name="card_number" 
                                                value={cartDetails.card_number && formatCardNumber(cartDetails.card_number)} 
                                                onChange={handleCardNumber}
                                                pattern="[0-9\s]*"
                                                onFocus={() => setFocusCard(true)}
                                                onBlur={() => setFocusCard(false)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Expiry")}</label>
                                            <input 
                                                type="text" 
                                                pattern="[0-9/]*"
                                                name="expiry" 
                                                placeholder="MM / YY" 
                                                value={cartDetails.expiry && formatDateCard(cartDetails.expiry)} 
                                                onChange={(e) => handleExpiryChange(e)}
                                                onFocus={() => setExpiryFocused(true)}
                                                onBlur={() => setExpiryFocused(false)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className="cart-form-inputs">
                                            <label>CVC</label>
                                            <input 
                                                type="number" 
                                                name="cvc" 
                                                maxLength={4} 
                                                value={cartDetails.cvc} 
                                                onChange={(e) => handleCvvNumber(e)}
                                                onFocus={() => setCvvFocused(true)}
                                                onBlur={() => setCvvFocused(false)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div className="cart-form-inputs">
                                            <label>{t("Billing Zip")}</label>
                                            <input type="text" name="billing_zip" value={cartDetails.billing_zip} onChange={(e) => handleChange(e)}/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} xl={6}>
                                <div className="card-details-wrapper">
                                    <div className="jp-card-container">
                                        <div className={`jp-card ${cvvFocused ? "jp-card-flipped" : ""}`}>
                                            <div className="jp-card-front">
                                                <div className="jp-card-lower">
                                                    <div className="jp-card-shiny"></div>
                                                    <div className={`jp-card-number jp-card-display jp-card-invalid ${focusCard && 'jp-card-focused'}`}>{cartDetails.card_number ? cartDetails.card_number : "**** **** **** ****"}</div>
                                                    <div className={`jp-card-name jp-card-display jp-card-valid ${nameFocused && 'jp-card-focused'}`}>{cartDetails.name ? cartDetails.name : "NAME SURNAME"}</div>
                                                    <div className={`jp-card-expiry jp-card-display jp-card-invalid ${expiryFocused && 'jp-card-focused'}`} data-before="month/year" data-after="validthru">{cartDetails.expiry ? formatDateCard(cartDetails.expiry) : "**/****"}</div>
                                                </div>
                                            </div>
                                            <div className="jp-card-back">
                                                <div className="jp-card-bar"></div>
                                                <div className="jp-card-cvc jp-card-display jp-card-invalid">{cartDetails.cvc ? cartDetails.cvc : "***"}</div>
                                                <div className="jp-card-shiny"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}
export default CartDetails