import React from 'react';
import GroupDetails from './GroupDetails';
import Header from '../../../../Helpers/header';
import './style.css';

const GroupPage = () => {
  return (
    <div className='group-page'>
      <Header title="Tools" subtitle="Group Page" />
      <div className="title_header mt-20">
        <GroupDetails />
      </div>
    </div>
  )
}

export default GroupPage;
