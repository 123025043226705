import React, {useEffect} from 'react';
import Header from '../../../Helpers/header';
import './style.css';
import SupportForm from './SupportForm';
import useHandleDisable from '../../helpers/disableSelect';

const Support = () => {
    const handleDisable = useHandleDisable()
    useEffect(() => {
        handleDisable()
    }, [])
    return (
        <div className='support_page'>
            <Header title="Support" subtitle="Support Request"/>
            <SupportForm />
        </div>
    )
}

export default Support;
