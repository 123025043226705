import React from "react"
import {DialogTitle, Dialog} from '@mui/material';
import { BsSendCheck } from "react-icons/bs"
import { BsFillPatchCheckFill } from "react-icons/bs";

const ContactLaterDialog = ({openDialog, setOpenDialog, text}) => {
    return(
        <Dialog onClose={() => setOpenDialog(false)} open={openDialog}>
            <div className="dialog-popup">
                <BsFillPatchCheckFill/>
                <p>{text}</p>
            </div>
        </Dialog>
    )
}
export default ContactLaterDialog