import React from "react"
import { styled } from "@mui/system";
import { LinearProgress, linearProgressClasses } from "@mui/material";


export const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 35,
    borderRadius: 3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#00b4e559',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 3,
      backgroundColor: "#00B4E5"
    },
}));

export const CustomLinearProgressGreen = styled(LinearProgress)(({ theme }) => ({
  height: 35,
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#ffc10778',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: "#06d49d82"
  },
}));

export const CustomLinearProgressAi = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#EDF2F7',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: "#FBBC04"
  },
}));

export const CustomLinearProgressAiBlue = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#EDF2F7',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: "#00B4E5"
  },
}));