import React, {useState} from "react"
import { FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput, TextField  } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordInput = ({name, label, value, handleChangePassword}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = (e) => {
		setShowPassword(!showPassword);
	}
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

    return(
        <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <TextField
                variant="outlined"
                label={label}
                name={name}
                defaultValue={value}
                type={showPassword ? 'text' : 'password'}
                onChange={handleChangePassword}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            id={name}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )


}
export default PasswordInput