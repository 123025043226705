import React, {useState, useMemo, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
import { ClickAwayListener, FormControl, Collapse } from "@mui/material"
import { useTranslation } from "react-i18next"
import { getLocationSelected, getGroupLocation } from "../../../redux/actions/admin-index"

const containsTextLocations = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const EachGroupLocations = ({disabled}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [searchLocations, setSearchLocations] = useState("");
    const [locationsOfGroup, setLocationsOfGroup] = useState()

    const user = useSelector(state => state.getUserInfo.user)
    let groupsArray = user && user.user.groups
    const groupSelected = useSelector(state => state.groupSelected.group)   
    const location = useSelector(state => state.locationSelected.location) 
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    useEffect(() => {
        if(groupsArray && !location){
            setLocationsOfGroup(groupsArray.filter(group => group.id == groupSelected)[0]?.locations)
        }
    }, [groupSelected, user])

    const {t} = useTranslation()
    const displayedLocation = useMemo(() => locationsOfGroup  && locationsOfGroup.filter(loc => containsTextLocations(loc.irevu_companyName, searchLocations)), [searchLocations, user, locationsOfGroup]);


    useEffect(() => {
        if(!localStorage.getItem("location_of_group") && displayedLocation){
            dispatch(getGroupLocation(displayedLocation[0].id))
            localStorage.setItem("location_of_group", displayedLocation[0].id)
        }
        if(localStorage.getItem("location_of_group")){
            dispatch(getGroupLocation(localStorage.getItem("location_of_group")))
        }
    }, [locationsOfGroup])


    const onLocationSelect = (id) => {
        dispatch(getLocationSelected(""))
        localStorage.setItem('location', "");
        dispatch(getGroupLocation(id))
        localStorage.setItem("location_of_group", id)
        setShow(false)
    }

   if(displayedLocation){
        return(
            <div style={{marginBottom: "35px"}} className={disabled ? "disable-inner-dropdown" : ""}>
                <h6 style={{fontFamily: "Poppins-Regular", fontSize: "16px", marginBottom :"10px", marginTop: "0px"}}>Select Location</h6>
                <ClickAwayListener onClickAway={() => setShow(false)}>
                    <div style={{position: "relative"}}>
                        <FormControl className="select-search-loc">
                            <div onClick={() => setShow(!show)} className='select-search-header' style={{borderColor: show ? "#18AEE3" : "rgba(37, 49, 60, 0.15)"}}>
                                {locationsOfGroup?.filter(loc => loc.id == selected)[0] ? locationsOfGroup.filter(loc => loc.id == selected)[0].full_name : " "}
                            </div>
                        </FormControl>
                        <Collapse in={show}>
                            <div className='loc-group-container-wrapper'>
                                <div className="loc-group-tabs"></div>
                                <input type="text" placeholder={t("Search Location")} className='search-inside-dropdown' 
                                    onChange={(e) => setSearchLocations(e.target.value)}
                                    onKeyDown={(e) => {
                                        if(e.key !== 'Escape'){
                                            e.stopPropagation();
                                        }
                                    }}
                                />
                                <div className='loc-group-container custom-scrollbar-vertical'>
                                    {displayedLocation && displayedLocation.map(loc => (
                                        <div key={`${loc.id}-ofgroup`} className="left-loc-icon element-container">
                                            <input type="radio" name="location_of_group" value={loc.id} id={`locOfGr-${loc.id}`} checked={loc.id == selected} onChange={() => {
                                                onLocationSelect(loc.id)
                                            }}/>
                                            <label htmlFor={`locOfGr-${loc.id}`}>
                                                {loc.full_name}
                                            </label>
                                        </div>
                                    ))} 
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </ClickAwayListener>
            </div>
        )
   }
}
export default EachGroupLocations