import React, {useEffect, useState} from "react"
import { Modal } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { fetchDashboardReviewCollection } from "../../../../redux/actions/admin-index"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

const SetTargetModal = ({open, setOpen, modalUse, dailyTarget, setDailyTarget, setDailyTargetValue}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const [loading, setLoading] = useState(false)
    const [target, setTarget] = useState(0)
   
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)

    useEffect(() => {
        setTarget(dailyTarget)
    }, [open])

    const handleClose = () => {
        setOpen(false)
        setTarget("")
    }

    const handleTarget = () => {
        let loc = location == "all" ?  "" : location
        const data = {
            target,
            location: loc,
            group_id: groupSelected 
        }
        setLoading(true)
        axios.post(`${baseUrlDev}dashboard/store-target`, data, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(() => {
            setLoading(false)
            setDailyTargetValue(target)
            dispatch(fetchDashboardReviewCollection(location, groupSelected))
            handleClose()
        }).catch(err => {
            setLoading(false)
            handleClose()
        })
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-user-gamify-title"
            aria-describedby="modal-user-gamify-description"
        >
        <div className="wrapper-review-collection" >
            <div className="custom-modal-header">
                <h5>{modalUse == "set" ? t("Set Target") : t("Edit Target")}</h5>
            </div>
            <div className="custom-modal-body-stp">
                <div className="daily_target_wrapper">
                    <label className="input-date-label">{modalUse === "set" ? "Add" : "Edit"} {t("Daily Target")}</label>
                    <input type="number" className="input-text-holder" value={target} onChange={(e) => {
                        setTarget(e.target.value)
                        setDailyTarget(e.target.value)
                    }} />
                </div>
            </div>
            <div className="custom-modal-footer">
                <button className="close-btn" onClick={() => handleClose()}>{t("Cancel")}</button>
                <LoadingButton
                    style={{paddingLeft: loading && "30px"}}
                    loading={loading}
                    className="save-btn"
                    onClick={() => handleTarget()}
                    disabled={target == 0}
                >
                    {t("Save")}
                </LoadingButton>
            </div>
        </div>
        </Modal>
    )
}
export default SetTargetModal