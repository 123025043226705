import React, {useState, useEffect} from "react"
import { Grid, FormControl, Select, MenuItem, Collapse, Modal, Slide} from '@mui/material';
import ReviewsBox from "../../Components/Analysis_Report/ReviewsBox"
import { useDispatch, useSelector } from "react-redux";
import { fetchKeywordDetails, clearKeywordDetails } from "../../../../redux/actions/admin-index";
import KeywordSkeleton from "../../helpers/keywordSkeleton";
import KeywordChart from "./keywordChart";
import MySelect from "../../helpers/mySelect";
import { useTranslation } from "react-i18next";

const KeywordStatsModal = ({open, setOpen, id, from, to, loc}) => {
    const dispatch = useDispatch()
    const [openBoxFilter, setOpenBoxFilter] = useState(true);
    const [keywordState, setKeyword] = useState("")
    const [ratingState, setRatingState] = useState(["all ratings"])
    const [rating, setRating] = useState(["1", "2", "3", "4", "5"])

    const location = useSelector(state => state.locationSelected.location)
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    const {t} = useTranslation()

    useEffect(() => {
        dispatch(clearKeywordDetails())
        if(loc && open){
            dispatch(fetchKeywordDetails(id, loc, rating, from, to))
        } else {
            if(selected && (!location || location == "all") && open){
                dispatch(fetchKeywordDetails(id, selected, rating, from, to))
            }
            if (location && location !== "all" && open) {
                dispatch(fetchKeywordDetails(id, location, rating, from, to))
            }
        }
    }, [location, selected,open, from, to, loc])

    const keyword = useSelector(state => state.keywordDetailsReducer.keyword)
    
    const handleClose = () => {
        setOpen(false);
    }

    const filterKeywordDetails = () => {
        dispatch(clearKeywordDetails())
        if(loc){
            dispatch(fetchKeywordDetails(keywordState, loc, rating, from, to))
        } else {
            if(location && location !== "all"){
                dispatch(fetchKeywordDetails(keywordState, location, rating, from, to))
            }
        }
       
    }

    const fetchOtherKeywords = (key) => {
        dispatch(clearKeywordDetails())
        if(loc){
            dispatch(fetchKeywordDetails(key, loc, rating, from, to))
            setKeyword(key)
        } else {
            if(location && location !== "all"){
                dispatch(fetchKeywordDetails(key, location, rating, from, to))
                setKeyword(key)
            }
        }
    }
    
    useEffect(() => {
        if(keyword){
            setKeyword(keyword.keyword[0])
        }
    }, [keyword])

    
    const handleChangeRatings = (e) => {
        const value = e.target.value
        if(value[value.length - 1] === "all ratings"){
            setRatingState(['all ratings']);
            setRating(["1", "2", "3", "4", "5"])
        } else {
            setRatingState(value.filter(el => el !== "all ratings"))
            setRating(value.filter(el => el !== "all ratings"))
        }
    }

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-keyword-title"
            aria-describedby="modal-keyword-description"
        >
        <Slide direction="left" in={open} {...({ timeout: 500 })}>
            <div className="custom-modal-dialog keyword_stats_dialog">
                <div className="notification-modal-body">
                    <div className="custom-modal-header">
                        <h5>{t("Keywords Stats")}</h5>
                    </div>

                    <div className="custom-modal-content keywords_stats_modal">
                        <div className='scrollable-loc-container custom-scrollbar-vertical'>
                            {keyword === undefined ? <KeywordSkeleton/> :
                                <Grid container spacing={2} className="grid_wrap">
                                    <Grid item xs={12} sm={12} md={12} lg={4}>
                                        <div className="left">
                                            <button className='btn filter_btn' onClick={() => setOpenBoxFilter(!openBoxFilter)}>{t("Filter")}</button>
                                            <Collapse in={openBoxFilter}>
                                                <div className={`box_filter`}>
                                                    <div>
                                                        <h5>{t("Keyword")}</h5>
                                                        <div className="form-group">
                                                            <input type="text" value={keywordState} className='keyword_search' onChange={(e) => setKeyword(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5>{t("Rating")}</h5>
                                                    </div>
                                                    <div style={{marginBottom: "10px"}}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                name="zindex-dropdown"
                                                                multiple
                                                                id="input-type-one-select"
                                                                value={ratingState}
                                                                label="position"
                                                                input={<MySelect/>}
                                                                onChange={handleChangeRatings}
                                                                MenuProps={{ autoFocus: false, PaperProps: { sx: { maxHeight: 300 } } }}
                                                                >
                                                                <MenuItem value={"all ratings"}>{t("All Ratings")}</MenuItem>
                                                                <MenuItem value={'5'}>5 {t("Stars")}</MenuItem>
                                                                <MenuItem value={'4'}>4 {t("Stars")}</MenuItem>
                                                                <MenuItem value={'3'}>3 {t("Stars")}</MenuItem>
                                                                <MenuItem value={'2'}>2 {t("Stars")}</MenuItem>
                                                                <MenuItem value={'1'}>1 {t("Star")}</MenuItem>
                                                            </Select>
                                                        </FormControl> 
                                                    </div>
                                                    <button className='btn apply_btn' onClick={() => filterKeywordDetails()}>{t("Apply")}</button>
                                                </div>
                                            </Collapse>
                                            <div className='box box_general__table custom-scrollbar-vertical'>
                                                <h2 className='title'>{t("Customers described")} <span>{keyword.keyword}</span> {t("as")}</h2>
                                                <table className='general__table'>
                                                    <thead>
                                                        <tr>
                                                            <th>{t("Keyword")}</th>
                                                            <th>{t("Used")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {keyword.data.keyword.following_keywords.length > 0 && keyword.data.keyword.following_keywords.map(keyword => {
                                                            return(
                                                                <tr key={keyword.keyword} onClick={() => fetchOtherKeywords(keyword.keyword)}>
                                                                    <td className='cursor-pointer'>{keyword.keyword}</td>
                                                                    <td>{keyword.count}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={8}>
                                        <div className="right">
                                            <div className="box">
                                                <div className="d-flex align-items-center justify-content-between flex-wrap gap-10">
                                                    <div className='left_wrapper'>
                                                        <div>
                                                            <h5 className='subtitle'>{t("Sentiment")}</h5>
                                                            <h2 className='title'>{keyword.keyword[0]}</h2>
                                                            <div className="percentage_wrapper">
                                                                <div className='mr-2'>
                                                                    <span className='mr-5'>{keyword.data.reviews_statistics.positive}</span>
                                                                    <span className='text_success'>{t("positive")}</span>
                                                                </div>
                                                                <div className='mr-2'>
                                                                    <span className='mr-5'>{keyword.data.reviews_statistics.negative}</span>
                                                                    <span className='text_danger'>{t("negative")}</span>
                                                                </div>
                                                                <div className='mr-2'>
                                                                    <span className='mr-5'>{keyword.data.reviews_statistics.neutral}</span>
                                                                    <span className='text_warning'>{t("neutral")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-center">
                                                        <div className="vl border_right">
                                                            <div className="text-center">
                                                                <h5 className='title'>{t("Mentions")}</h5>
                                                                <h3>{keyword.data.keyword.mentions}</h3>
                                                            </div>
                                                        </div>
                                                        <div className="vl">
                                                            <div className="text-center">
                                                                <h5 className='title'>{t("Reviews")}</h5>
                                                                <h3>{keyword.data.reviews_statistics.count}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <KeywordChart data={keyword.data.charts}/>
                                                {
                                                    keyword.data.reviews && keyword.data.reviews.length  > 0 && keyword.data.reviews.map((el, i) => {
                                                        return  <ReviewsBox item={el} key={`${el.source}-${i}`}/>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            }
                        </div>
                    </div>
                    <div className="custom-modal-footer">
                        <button className='close-btn' onClick={() => setOpen(false)}>{t("Close")}</button>
                    </div>
                </div>
            </div>
        </Slide>
    </Modal>
    )
}
export default KeywordStatsModal