import React, { useState, useEffect } from "react"
import { Modal} from "@mui/material"
import WalletBody from "./wallet_body"
import CustomerIdPopup from "./customer_id_popup"
import { useSelector } from "react-redux"

const Wallet = ({open, setOpen}) =>{
    const [customerId, setCustomerId] = useState()

    const user = useSelector(state => state.getUserInfo.user)

    useEffect(() => {
        if(user?.user?.ipaymer_id){
            setCustomerId(user?.user?.ipaymer_id)
        } else {
            setCustomerId("")
        }
    }, [user])

    const handleClose = () => {
        setOpen(false)
    }

    return(
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-wallet-title"
            aria-describedby="modal-wallet-description"
        >
            {
                user && customerId ? 
                <>
                    <WalletBody setOpen={setOpen} customerId={customerId} />
                </>
                :
                <>
                    <CustomerIdPopup setCustomerId={setCustomerId}/>
                </>
            }
        </Modal>
    )
}
export default Wallet