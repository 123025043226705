import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useQueryParamsHook from '../Survey/parts/useQueryParamsHook';
import UnAuthorize from '../UnAuthorized';
import Loading from './parts/Loading';
import useLocalUser from './parts/useLocalUser';
import { apiFetchToken } from './request/apiFetchToken';
import { apiFetchTokenLogin } from './request/apiFetchTokenLogin';
import Loader from '../Admin/helpers/loader';
import { useDispatch } from 'react-redux';
import { getLocationSelected, checkImpersonate } from '../../redux/actions/admin-index';

const TokenControl = ({ }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState("");
  const objectQueryParams = useQueryParamsHook();
  const [localUser, setLocalUser] = useLocalUser(onSetLocalUser);
  const navigate = useNavigate();
  let path = location.pathname

  useEffect(() => {
    if (objectQueryParams.token) {
      dispatch(getLocationSelected(""))

      objectQueryParams.ssoLogin ?
        apiFetchTokenLogin(objectQueryParams.token, onTokenResponse, path)
        : apiFetchToken(objectQueryParams.token, onTokenResponse, path)
    }

    if (objectQueryParams.oldIrevu) {
      localStorage.setItem('oldIrevu', true)
    } else {
      localStorage.setItem('oldIrevu', false)
    }

    if (objectQueryParams.impersonate) {
      localStorage.setItem('impersonate', 'true')
      dispatch(checkImpersonate(true))
    } else {
      localStorage.setItem('impersonate', 'false')
      dispatch(checkImpersonate(false))
    }
  }, [objectQueryParams?.token]);

  function onTokenResponse(error, data) {
    console.log(error)
    console.log(data)
    if (error || !data.status) {
      setHasError("Token is not valid");
    } else {
      setLocalUser(data);
    }
    setIsLoading(false);
  }

  function onSetLocalUser() {
    if (location) {
      if (location.pathname !== "/admin/login") {
        navigate(`/app${location.pathname.substring(6)}`);
      } else {
        navigate("/app/admin/forms");
      }
    }
  }

  if (isLoading)
    return <Loader fixed={true} />

  if (hasError !== "")
    return (
      <UnAuthorize message={hasError} />
    )
}

export default TokenControl