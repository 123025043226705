import React from "react"
import OnlyLocationWrapper from "../../parts/OnlyLocationWrapper"
import NotificationsWrapper from "./notificationsWrapper"
import InnerDropdownContainer from "../../parts/InnerDropdownContainer"
import Header from "../../../Helpers/header"

const Notifications = () => {
    return(
        <OnlyLocationWrapper>
            <div className="flex-header-container table-header-container">
                <Header title="Account" subtitle="Manage Account Notifications" />
            </div>
            <InnerDropdownContainer/>
            <NotificationsWrapper/>
        </OnlyLocationWrapper>
    )
}
export default Notifications