import React, {useState} from "react";
import DateRange from "../../../helpers/DateRange";
import Header from "../../../../Helpers/header";
import { useTranslation } from "react-i18next";
import {BiExport} from "react-icons/bi"
import { formatDateLine } from "../../../helpers/formatDate";
import axios from "axios";
import { baseUrlDev } from "../../../../Helpers/baseUrl";
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser";
import { useSelector } from "react-redux";
import { openExportedAlert } from "../../../../../redux/actions/admin-index";
import { exportTable } from "../../../helpers/export";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

const PageVisitsHeader = ({setFrom, setTo, to, from}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const location = useSelector(state => state.locationSelected.location)
    const page_visits = useSelector(state => state.PageVisits.page_visits)
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)


    const handleDateChange = (item) => {
        let itemSerialized = {
            startDate: formatDateLine(item.selection.startDate),
            endDate: formatDateLine(item.selection.endDate)
        }
        setFrom(item.selection.startDate ? formatDateLine(item.selection.startDate) : "")
        setTo(item.selection.endDate ? formatDateLine(item.selection.endDate) : "")
        
        // if(item.selection.startDate !== item.selection.endDate){
        //     dispatch(clearDashboardStats())
        // }
        // if(!item.selection.startDate){
        //     dispatch(clearDashboardStats())
        //     dispatch(fetchDashboardStats(null, null, location, groupSelected))
        // } else {
        //     dispatch(fetchDashboardStats(itemSerialized.startDate, itemSerialized.endDate, location, groupSelected))
        // }
    }

    const exportTableFunc = () => {
        if(selected && (!location || location == "all")){
            apiRequest(selected)
        } else if (location && location !== "all"){
            apiRequest(location)
        }
    }
    const apiRequest = (id) => {
        setLoading(true)
        exportTable(impersonateReducer,`${baseUrlDev}page-visits/export`, {
            location: id,
            from,
            to
        }, 
        (resData) => {
            dispatch(openExportedAlert(true, resData.message));
            setLoading(false)
        }, 
        (error) => {
            setLoading(false)
        });
    }

    return(
        <div className="d-flex align-items-center justify-content-between table-header-container">
            <Header title="Page Visits" subtitle="Page Visits Report"/>
            <div className="flex-header-actions" style={{marginLeft: '10px'}}>
                <DateRange handleDateChange={handleDateChange}/>
                <button 
                    className="btn button-disabled competitor-export" 
                    onClick={() => exportTableFunc()}
                    style={{opacity: loading ? 0.5 : 1, cursor: loading ? 'initial' : 'pointer'}}
                >
                    {loading ? 
                        <>
                            <CircularProgress
                                size={15}
                                style={{ marginRight: "10px" }}
                                thickness={4}
                            />
                            Export to Excel
                        </>    
                    : <><BiExport /> {t("Export to Excel")}</>}
                </button>
            </div>
        </div>
    )
}
export default PageVisitsHeader