import React from "react"
import { Skeleton, Grid } from "@mui/material"

const CompetitorSkeleton = () => {
    return(
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{transform: "unset", height: "400px"}}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{transform: "unset", height: "400px"}}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{transform: "unset", height: "400px"}}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <Skeleton style={{transform: "unset", height: "400px"}}/>
            </Grid>
        </Grid>
    )
}
export default CompetitorSkeleton