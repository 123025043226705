import React, { useEffect, useRef, useState } from "react"
import { Modal, Slide } from "@mui/material";
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import NotificationBody from "./NotificationBody";
import { VscChromeClose } from "react-icons/vsc"
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationsCount, readAllNotifications, clearNotifications, fetchNotifications, fetchNotificationsLoadMore, setLoadingMoreNotification } from "../../../redux/actions/admin-index";
import { baseUrlDev } from "../../Helpers/baseUrl";
import { getLocalUser } from "../../TokenControl/parts/useLocalUser";
import axios from "axios";
import { useLocation } from "react-router-dom"
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const Notification = ({ open, setOpen }) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [value, setValue] = useState("notification");
    const notifications = useSelector(state => state.getNotifications?.notifications)
    const [notificationCount, setNotificationCount] = useState(2); //because first time we make request by default with page1, second time when we scroll we should start from counter 2, to request for page=2
    const loading = useSelector((state) => state.getNotifications.loading);

    const handleClose = () => {
        setOpen(false)
        setNotificationCount(2);
    }
    
    const handleChange = (e, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        if (open) {
            dispatch(clearNotifications())
            dispatch(fetchNotifications())
        }
    }, [open, dispatch]);

    useEffect(() => {
        setOpen(false)
        
    }, [location.pathname])

    const handleLoadMore = () => {
        // if (!loading) {
        //     console.log('loading is here counter inc.....')
        //     // setNotificationCount((prevCount) => prevCount + 1);
        // }

        dispatch(fetchNotificationsLoadMore(notificationCount, () => setNotificationCount((prevCount) => prevCount + 1)));
    };

    useEffect(() => {
        if (notificationCount !== 1) {
            dispatch(setLoadingMoreNotification())
        }
    }, [notificationCount])

    const readAll = () => {
        axios.post(`${baseUrlDev}notifications/read/all`, {}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(() => {
            dispatch(readAllNotifications())
            dispatch(fetchNotifications())
            dispatch(fetchNotificationsCount())
        })
    }
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })} >
                <div className={`custom-modal-dialog`}>
                    <div className="notification-modal-body">
                        <div className="custom-modal-content">
                            <TabContext value={value}>
                                <div className="notification-mui-tab-container">
                                    <TabList onChange={handleChange} className="notification-mui-tab" aria-label="lab API tabs example">
                                        <Tab label={t("Notifications")} value="notification" />
                                        {/* <Tab label="News" value="news" /> */}
                                    </TabList>
                                    <button onClick={() => readAll()}>{t("Mark all as Read")}</button>
                                    <VscChromeClose className="close-modal" onClick={() => setOpen(false)} />
                                </div>
                                <TabPanel value="notification" style={{ padding: "0px" }}>
                                    <NotificationBody notifications={notifications} handleLoadMore={handleLoadMore} />
                                </TabPanel>
                                {/* <TabPanel value="news" style={{padding: "0px"}}>
                                <div>News</div>
                                </TabPanel> */}
                            </TabContext>
                        </div>
                        <div className="custom-modal-footer">
                            <button className="close-btn" onClick={() => setOpen(false)}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default Notification