import React from "react"
import { useTranslation } from "react-i18next";

const AddPlatformBox = ({ redirectLink }) => {
    const {t} = useTranslation()
    return (
        <div className="create-new-workspace-holder">
            <a href={redirectLink} className="create-new-workspace" target="_blank" rel="noreferrer">
                <span>+</span>
                <p>{t("Create new workspace")}</p>
            </a>
        </div>
    )
}

export default AddPlatformBox;
