import React from 'react';
import { Grid, FormControl, MenuItem, Select } from '@mui/material';


const Social = ({ formData, handleInputChange }) => {
    return (
        <div>
            <div className='social location_social_card'>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div>
                            <h5 className='title'>Social Media Links</h5>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div>
                            <label htmlFor="">Facebook</label>
                            <div className="location-social-holder facebook">
                                <div>
                                    <img src={"/admin-icons/fb.svg"} alt="facebook"/>
                                    <span>https://facebook.com/</span>
                                </div>
                                <input
                                    name="facebook"
                                    type="text"
                                    placeholder=''
                                    value={formData?.facebook}
                                    onChange={(e) => handleInputChange('facebook', e.target.value)}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div>
                            <label htmlFor="">Instagram</label>
                            <div className="location-social-holder instagram">
                                <div>
                                    <img src={"/admin-icons/insta.svg"} alt="instagram"/>
                                    <span>https://instagram.com/</span>
                                </div>
                                <input
                                    name="instagram"
                                    type="text"
                                    placeholder=''
                                    value={formData?.instagram}
                                    onChange={(e) => handleInputChange('instagram', e.target.value)}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div>
                            <label htmlFor="">Twitter</label>
                            <div className="location-social-holder pinterest">
                                <div>
                                    <img src={"/admin-icons/twitter.svg"} alt="twitter"/>
                                    <span>https://twitter.com/</span>
                                </div>
                                <input
                                    name="twitter"
                                    type="text"
                                    placeholder=''
                                    value={formData?.twitter}
                                    onChange={(e) => handleInputChange('twitter', e.target.value)}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div>
                            <label htmlFor="">Youtube</label>
                            <div className="location-social-holder youtube">
                                <div>
                                    <img src={"/admin-icons/youtube.svg"} alt="youtube"/>
                                    <span>https://youtube.com/</span>
                                </div>
                                <input
                                    name="youtube"
                                    type="text"
                                    placeholder=''
                                    value={formData?.youtube}
                                    onChange={(e) => handleInputChange('youtube', e.target.value)}
                                />
                            </div> 
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div>
                            <label htmlFor="">LinkedIn</label>
                            <div className="location-social-holder linkedin">
                                <div>
                                    <img src={"/admin-icons/linkedin.svg"} alt="linkedin"/>
                                    <span>https://linkedin.com/</span>
                                </div>
                                <input
                                    name="linkedin"
                                    type="text"
                                    placeholder=''
                                    value={formData?.linkedin}
                                    onChange={(e) => handleInputChange('linkedin', e.target.value)}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div>
                            <label htmlFor="">Pinterest</label>
                            <div className="location-social-holder pinterest">
                                <div>
                                    <img src={"/admin-icons/pinterest.svg"} alt="pinterest"/>
                                    <span>https://pinterest.com/</span>
                                </div>
                                <input
                                    name="pinterest"
                                    type="text"
                                    placeholder=''
                                    value={formData?.pinterest}
                                    onChange={(e) => handleInputChange('pinterest', e.target.value)}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Social;