import React, { useEffect, useState } from 'react';

import { Alert, Grid } from '@mui/material';
import StepperComponent from './StepperComponent';
import Preview from './Preview';
import { Container } from '@mui/system';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseUrlDev } from '../../../../Helpers/baseUrl';
import { useDispatch } from 'react-redux';
import { getLocalUser } from '../../../../TokenControl/parts/useLocalUser';
import { showAlert } from '../../../../../redux/actions';
import AlertComponent from '../../../../Helpers/Alert';
import Header from '../../../../Helpers/header';
import "../../../../../assets/qrCode.css"
import "../../../../../assets/reviews.css"
import { useTranslation } from 'react-i18next';
import { showYoutubeContent } from '../../../../../redux/actions/admin-index';

const steps = ['Text', 'Image', 'Qr Code'];
const MAX_IMAGES = 3;

const resetState = {
    title: { content: '', text: '' },
    subtitle: { content: '', text: '' },
    description: { content: '', text: '' },
    logo: null,
    images: [],
    background_color: '',
    border_color: '',
    logo_align: 'center',
    logo_size: '150',
}

const QrCodeWrapper = () => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        title: { content: '', text: '' },
        subtitle: { content: '', text: '' },
        description: { content: '', text: '' },
        logo: null,
        images: [],
        background_color: '',
        border_color: '',
        logo_align: 'center',
        logo_size: '150',
    })
    const {t} = useTranslation()
    const [logoPrev, setLogoPrev] = useState('')
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [errors, setErrors] = useState([]);
    const [formError, setFormError] = useState('');
    const selected = useSelector(state => state.groupLocationSelected.group_location)


    const location = useSelector(state => state.locationSelected.location)
    const user = useSelector(state => state.getUserInfo.user)
    let location_details;
    if(selected && (!location || location == "all")){
        location_details = user && user.user.locations.filter(loc => loc.id == selected)
    }
    if (location && location !== "all") {
        location_details = user && user.user.locations.filter(loc => loc.id == location)
    } 

    useEffect(() => {
        setActiveStep(0)
        setFormData(resetState)
    }, [location, selected])

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ZQ_PkH2KKzA?autoplay=1&mute=0&rel=0'))
    }, [])
    
    const handleQuillChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' })
    };

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const errors = {}
        if (formData?.title?.text === '') {
            errors.title = 'Title is required';
        }

        if (formData?.subtitle?.text === '') {
            errors.subtitle = 'Subtitle is required';
        }

        if (formData?.description?.text === '') {
            errors.description = 'Description is required';
        }

        if (activeStep === 1) {
            if (!formData?.logo) {
                errors.image = 'Please select an image';
            }
        }
        setErrors(errors);
        // validation here before continue to next step

        if (Object.keys(errors).length === 0) {
            // form is valid, move to next step.
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            setActiveStep(newActiveStep);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const handleFileChange = (event) => {
        if(event.target.files[0]?.size / 1024 < 9000){
            setFormData({ ...formData, logo: event.target.files[0] });
            setLogoPrev(URL.createObjectURL(event.target.files[0]))
        } else if (event.target.files[0]?.size / 1024 >= 9000) {
            event.target.value = null;
            setFormError(t("qr_code_alert"))
            setTimeout(() => {
                setFormError("")
            }, 5000)
        }
    };


    const handleFilesChange = (e) => {
        let files = [...e.target.files]
        e.target.value = ''
        Object.keys(files).forEach(file => {
            setFormData(prevState => ({ ...prevState, images: [...prevState.images, files[file]].slice(0, MAX_IMAGES)}));
        });
    }

    const handleDeleteFile = (index) => {
        let newFiles = [...formData?.images];
        newFiles.splice(index, 1);
        setFormData({ ...formData, images: newFiles });
    }

    const submitFormFunc = (id) => {
        let allData = Object.assign(formData, { location: id });
        let data = new FormData();
        Object.keys(allData).map(key => {
            if(key === "description" || key === "title" || key === "subtitle"){
                data.append(key, formData[key].content);
            } else if (key === "images"){
                formData[key].forEach(f => {
                    data.append(key, f)
                })  
            } else {
                data.append(key, formData[key]);
            }  
        })
        setLoading(true)
        axios.post(`${baseUrlDev}locations/qrcode`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            setErrors([])
            setLoading(false)
            dispatch(showAlert(true, "QrCode Page created successfully"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch((err) => {
            setLoading(false)
            // Object.keys(err.response.data.errors).map(key => {
            //     setErrors(errors => [...errors, err.response.data.errors[key][0]])
            // })
            if(err?.message) {
                setFormError(err?.message);
            } else {
                const allErrorsFromBack = Object.values(err.response.data.errors)
                setFormError(allErrorsFromBack.join(''))
                setTimeout(() => {
                    setErrors([])
                }, 5000)
            }
        })
    }

    const submitForm = () => {
        if(selected && (!location || location == "all")){
            submitFormFunc(selected)
        }
        if (location && location !== "all") {
            submitFormFunc(location)
        }
    }

    function _renderStepContent(step) {
        switch (step) {
            case 0:
                return <Step1
                        handleNext={handleNext}
                        handleQuillChange={handleQuillChange}
                        formData={formData}
                        setFormData={setFormData}
                        errors={errors}
                        setErrors={setErrors}
                    />
            case 1:
                return <Step2
                    handleNext={handleNext}
                    handleBack={handleBack}
                    activeStep={activeStep}
                    setFormData={setFormData}
                    handleFileChange={handleFileChange}
                    formData={formData}
                    handleFilesChange={handleFilesChange}
                    handleDeleteFile={handleDeleteFile}
                    errors={errors}
                    logoPrev={logoPrev}
                />;
            case 2:
                return <Step3 handleBack={handleBack} activeStep={activeStep} submitForm={submitForm} loading={loading}/>;
            default:
                return <div>{t("Not Found")}</div>;
        }
    }

    return (
        <div>
            <AlertComponent/>
            {formError && <div className="alert-inside-page"> <Alert severity="error" className="mb-50 custom-error-alert">{formError}</Alert> </div>}
            <div className="qr-code-page-wrapper">
                <>
                    <Grid container spacing={5}>
                        <Grid item xl={6} xs={12}>
                            <div>
                                <StepperComponent
                                    activeStep={activeStep}
                                    completed={completed}
                                    setCompleted={setCompleted}
                                    handleNext={handleNext}
                                    handleBack={handleBack}
                                    totalSteps={totalSteps}
                                    completedSteps={completedSteps}
                                    isLastStep={isLastStep}
                                    allStepsCompleted={allStepsCompleted}
                                    handleStep={handleStep}
                                    handleComplete={handleComplete}
                                    handleReset={handleReset}
                                />

                                <div className="steps-wrapper h-100">
                                    {_renderStepContent(activeStep)}
                                </div>
                            </div>
                        </Grid>

                        <Grid item xl={6} xs={12}>
                            <Preview
                                formData={formData} logoPrev={logoPrev} location_details={location_details}
                            />

                        </Grid>
                    </Grid>
                </>
            </div>
        </div >
    )
}

export default QrCodeWrapper;
