import React from "react"
import { Grid } from "@mui/material"
import {BsClipboardCheck} from "react-icons/bs"

const NextBilling = ({subscriptions}) => {
    return(
        <Grid container spacing={2}>
            {subscriptions && subscriptions.length > 0 && subscriptions.map(sub => {
                return(
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                        <div className="next-billing-wrapper">
                            <div>
                                <div>
                                    <BsClipboardCheck/>
                                    <h5>{sub.reference_no}</h5>
                                </div>
                                <div>
                                    <h6>Plan</h6>
                                    <span className="plan-span">{sub.plan.name}</span>
                                </div>
                                <div>
                                    <h6>Total Locations</h6>
                                    <span className="plan-span">{sub.quantity}</span>
                                </div>
                                <div>
                                    <h6>Last Billied On</h6>
                                    <span className="last-billed-span">{sub.last_billed_on}</span>
                                </div>
                                <div>
                                    <h6>Next Billing Date</h6>
                                    <span className="next-billing-span">{sub.next_billing_date} (${sub.unit_price})</span>
                                </div>
                            </div>
                        </div>
                    </Grid>
                )
            })}
        </Grid>
    )
}
export default NextBilling