import { styled, InputBase} from "@mui/material";

export const MySelectBorderless = styled(InputBase)(({ theme }) => ({
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#fff",
        border: `0px`,
        fontSize: 12,
        fontFamily: "Poppins-Regular",
        padding: '6px 26px 6px 12px',
        color: "#333333",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
    '& .MuiSelect-icon': {
        backgroundImage: 'url(/admin-icons/drop-down-icon.svg)',
        backgroundSize: '22px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingRight: '20px'
    },
    '& .MuiSvgIcon-root' :{
        color: "transparent"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    }
}));

export const MySelectBgArrow = styled(InputBase)(({ theme }) => ({
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#fff",
        border: `0px`,
        fontSize: 12,
        fontFamily: "Poppins-Regular",
        padding: '6px 26px 6px 12px',
        color: "#333333",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        boxShadow: '0px 0px 5px rgba(37, 49, 60, 0.05)',
        borderRadius: '3px'
    },
    '& .MuiSelect-icon': {
        backgroundImage: 'url(/admin-icons/reply-arrow-down.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        paddingRight: '20px'
    },
    '& .MuiSvgIcon-root' :{
        color: "transparent"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    }
}));
