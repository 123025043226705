import React, {useState} from "react"
import { Alert, Fade } from "@mui/material"
import { MdCheckCircleOutline } from "react-icons/md"
import { useTranslation } from "react-i18next"
const url = process.env.REACT_APP_FORMS_URL

const Embedded = ({id}) => {
    const [copiedIframe, setCopiedIframe] = useState(false)
    const [copiedLink, setCopiedLink] = useState(false)
    const {t} = useTranslation()
    const copyIframe = (text) => {
        navigator.clipboard.writeText(text)
        setCopiedIframe(true)
        setTimeout(() => {
            setCopiedIframe(false)
        }, 1500)
    }
    const copyLink = (text) => {
        navigator.clipboard.writeText(text)
        setCopiedLink(true)
        setTimeout(() => {
            setCopiedLink(false)
        }, 1500)
    }
return(
        <>
        <div className="embed-div">
            <h4>{t("Public URL")}</h4>
            <p>{t("Copy this link")}</p>
            <div>            
                <div className="link-div" onClick={() => copyLink(`${url}/forms/${id && id}`)}>{`${url}/forms/${id}`}</div>
                <Fade in={copiedLink}>
                    <Alert
                    className="succes-Copying"
                        iconMapping={{
                            success: <MdCheckCircleOutline fontSize="inherit" />,
                            }}
                        >
                        {t("Copied")}
                    </Alert>
                </Fade>
            </div>
        </div>
        <div className="embed-div">
            <h4>{t("Embed iFrame")}</h4>
            <p>{t("Copy and paste this iframe tag inside your page")}.</p>
            <div>            
                <code onClick={() => copyIframe(`<iframe src="${url}/forms/${id && id}"></iframe>`)}>{`<iframe src="${url}/forms/${id}"></iframe>`}</code>
                <Fade in={copiedIframe}>
                    <Alert
                    className="succes-Copying"
                        iconMapping={{
                            success: <MdCheckCircleOutline fontSize="inherit" />,
                            }}
                        >
                        {t("Copied")}
                    </Alert>
                </Fade>
            </div>
        </div>
        </>
    )
}
export default Embedded