import React, {Suspense} from "react"
import { Skeleton, IconButton } from "@mui/material"
import { IoMdInformationCircleOutline } from "react-icons/io"
import LightTooltip from "../../helpers/lightTooltip"
import RatingBreakdownProgress from "./RatingBreakdownProgress"
import { useTranslation } from "react-i18next"
import NewRatingBreakdownPercentage from "./newRatingBreakdownPercentage"
const RatingBreakdownPercentageChart = React.lazy(() => import("./RatingBreakdownPercentageChart"))

const RatingBreakdownPercentage = ({breakdown}) => {
    const {t} = useTranslation()
    return(
        <Suspense fallback={
            <div>
                <Skeleton style={{width: "100%", height: "100%"}}/>
            </div>
        }>
        <div className="dashboard-box-containers">
                <LightTooltip title={t("Number of AI responses broken down into ratings")}>
                    <IconButton className="tooltip-btn-holder">
                        <IoMdInformationCircleOutline style={{ opacity: "0.4" }} />
                    </IconButton>
                </LightTooltip>
                <h2>{t("AI Respond Breakdown")}</h2>
                <div className="rating-breakdown-ai-header">
                    <h5>{t("Rating")}</h5>
                    <h5>{t("Reviews")}</h5>
                </div>
                {/* <span className="percentage-span">%</span> */}
                {/* <RatingBreakdownProgress breakdown={breakdown}/> */}
                {/* <RatingBreakdownPercentageChart breakdown={breakdown}/> */}
                <NewRatingBreakdownPercentage breakdown={breakdown} />
            </div>
        </Suspense>
    )
}
export default RatingBreakdownPercentage