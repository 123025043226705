import React, { useState, useEffect, Suspense} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "../../../../assets/analysis.css"
import 'react-calendar/dist/Calendar.css';
import { Grid, Skeleton } from '@mui/material';
import {fetchLocationAnalysis, clearLocationAnalysis, showYoutubeContent} from "../../../../redux/actions/admin-index"
import AnalysisHeader from './analysisHeader';
import AddKeyword from '../../modals/analysis/AddKeyword';
import KeywordStatsModal from '../../modals/analysis/KeywordsModal';
import AnalysisBox from './AnalysisBox';
import KeywordsTable from './KeywordsTable';
import PhrasesTable from './PhrasesTable';
import ReviewPageSkeleton from '../../helpers/reviewPageSkeleton';
import TrendingKeywords from '../Dashboard/trending_keywords';
import Phrases from '../Dashboard/phrases';
import Unlock from '../../Unlock';
import { useTranslation } from 'react-i18next';

const AnalyticsPie = React.lazy(() => import("./analysisPie"))
const AnalyticsBar = React.lazy(() => import("./analysisBar"))


const AnalysisWrapper = ({from, to, openModal, setOpenModal}) => {
    const dispatch = useDispatch()
    const [openKeywordsModal, setOpenKeywordsModal] = useState(false);
    const [keyId, setKeyId] = useState()
    const [locked, setLocked] = useState(false)
    const {t} = useTranslation()
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    const user = useSelector(state => state.getUserInfo.user)

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/l8XUaZM-giA?autoplay=1&mute=0&rel=0'))
    }, [])
    

    useEffect(() => {
        if(selected && (!location || location == "all")){
            dispatch(clearLocationAnalysis())
            dispatch(fetchLocationAnalysis(selected, from ,to, groupSelected))
        }
    }, [selected])

    useEffect(() => {
        if (location && location !== "all") {
            dispatch(clearLocationAnalysis())
            dispatch(fetchLocationAnalysis(location, from ,to, groupSelected))
        }
    }, [location])


    const analytics = useSelector(state => state.analyticsReducer.analytics)

    useEffect(() => {
        if(location){
            let location_details = user && user.user.locations.filter(loc => loc.id == location)
            if(location_details && location_details[0] && location_details[0].is_demo){
                setLocked(true)
            } else {
                setLocked(false)
            }
        }
    }, [location, user])

    if(locked){
        return <Unlock/>
    }

    return (
        <div className='analysis_page'>
            <AddKeyword open={openModal} setOpen={setOpenModal} setOpenKeywordsModal={setOpenKeywordsModal} setKeyId={setKeyId}/>
            <KeywordStatsModal open={openKeywordsModal} setOpen={setOpenKeywordsModal} id={keyId} to={to} from={from}/>
            {analytics === undefined ? <ReviewPageSkeleton/> :
            <>
                <Grid container spacing={2}>
                    {analytics.data.positiveNegativePhrases &&
                        <Grid item xs={12} md={6}>
                            <TrendingKeywords keywords={analytics.data.positiveNegativePhrases}/>
                        </Grid>
                    } 
                    {analytics.data.positiveNegativePhrases &&
                        <Grid item xs={12} md={6}>
                            <Phrases phrases={analytics.data.positiveNegativePhrases} analysis={true}/>
                        </Grid>
                    }
                </Grid>
                <div className="mt-20">
                    <Grid container spacing={2}>
                        <AnalysisBox xs={12} md={6} xl={6} tooltip="Count most used keywords of positive and negative reviews">
                            <Suspense fallback={
                                <div>
                                    <Skeleton style={{transform: "unset", width: "200px", height: "200px", borderRadius: "100%", margin: "auto"}}/>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: "10px"}}>
                                        <Skeleton style={{width: "100px", marginRight:"10px"}}/>
                                        <Skeleton style={{width: "100px", marginRight:"10px"}}/>
                                        <Skeleton style={{width: "100px"}}/>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", margin: "10px"}}>
                                        <Skeleton style={{width: "100px"}}/>
                                        <Skeleton style={{width: "10px"}}/>
                                        <Skeleton style={{width: "100px"}}/>
                                    </div>
                                </div>
                            }>
                                <AnalyticsPie title="Keywords" positive={analytics.data.positiveKeywords.list} negative={analytics.data.negativeKeywords.list}/>
                            </Suspense>
                        </AnalysisBox>
                        <AnalysisBox xs={12} md={6} xl={6} tooltip="Count most used phrases of positive and negative reviews">
                            <Suspense fallback={
                                <div>
                                    <Skeleton style={{transform: "unset", width: "200px", height: "200px", borderRadius: "100%", margin: "auto"}}/>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: "10px"}}>
                                        <Skeleton style={{width: "100px", marginRight:"10px"}}/>
                                        <Skeleton style={{width: "100px", marginRight:"10px"}}/>
                                        <Skeleton style={{width: "100px"}}/>
                                    </div>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", margin: "10px"}}>
                                        <Skeleton style={{width: "100px"}}/>
                                        <Skeleton style={{width: "10px"}}/>
                                        <Skeleton style={{width: "100px"}}/>
                                    </div>
                                </div>
                            }>
                                <AnalyticsPie title="Phrases"  positive={analytics.data.positivePhrases.list} negative={analytics.data.negativePhrases.list}/>
                            </Suspense>
                        </AnalysisBox>
                    </Grid>
                </div>
                <div className="mt-20">
                    <Grid container spacing={2}>
                        <AnalysisBox title="Keywords analytics by month" xs={12} md={12} xl={12} tooltip="Count keywords by month">
                            <Suspense fallback={
                                <div>
                                    <Skeleton style={{transform: "unset", width: "95%", height: "400px", margin: "auto"}}/>
                                </div>
                            }>
                                <AnalyticsBar positive={analytics.data.positiveKeywords.chart} negative={analytics.data.negativeKeywords.chart}/>
                            </Suspense>
                        </AnalysisBox>

                        <AnalysisBox title="Keywords" xs={12} md={6} xl={3} tooltip="Most used keywords on positive reviews">
                            <KeywordsTable setKeyId={setKeyId} setOpenModal={setOpenKeywordsModal} keywords={analytics.data.positiveKeywords.list} title="Positive"/>
                        </AnalysisBox>

                        <AnalysisBox title="Keywords" xs={12} md={6} xl={3} tooltip="Most used keywords on negative reviews">
                            <KeywordsTable setKeyId={setKeyId} setOpenModal={setOpenKeywordsModal} keywords={analytics.data.negativeKeywords.list} title="Negative"/>
                        </AnalysisBox>

                        <AnalysisBox title="Phrases" xs={12} md={6} xl={3} tooltip="Most used phrases on positive reviews">
                            <PhrasesTable setKeyId={setKeyId} setOpenModal={setOpenKeywordsModal} phrases={analytics.data.positivePhrases.list} title="Positive"/>
                        </AnalysisBox>

                        <AnalysisBox title="Phrases" xs={12} md={6} xl={3} tooltip="Most used phrases on negative reviews">
                            <PhrasesTable setKeyId={setKeyId} setOpenModal={setOpenKeywordsModal} phrases={analytics.data.negativePhrases.list} title="Negative"/>
                        </AnalysisBox>
                    </Grid>
                </div>
            </>
            }
        </div>
    )
}

export default AnalysisWrapper;
