import React,{useEffect, useRef, useState, memo} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import ReviewRow from './ReviewRow';
import {  useParams } from "react-router-dom";
import { fetchCompanieData } from '../../../redux/actions';
import { categoryDetailClear } from '../../../redux/actions';

const ReviewList = memo(({sort}) => {
  const { name } = useParams(); // name of company
  const dispatch = useDispatch(); // for redux 
  const refLoadMore = useRef(null); // load more
  const [list,setList] = useState([]); // list of reviews
  const [page,setPage]= useState({page:0,fetching:false,isDone:false}); // page 

  
  const companyDataSelect = useSelector(state => state.companieAboutReducer.companie); // redux back and now here

  useEffect(()=>{
    setList([]); // clear list
    setPage({page:0,fetching:false,isDone:false});
    dispatch(fetchCompanieData(name,0,sort.trim()));  // latest
    return () => {
        dispatch(categoryDetailClear())
    }
  },[sort]);

  useEffect(() => {
    initEventListener();
    return ()=>{
      removeEventScroll(); // clear event
    }
  }, []);

  useEffect(()=>{
    if(!companyDataSelect) return; // do nothing if undefied
    if(companyDataSelect.place_id === "" || companyDataSelect.place_id) return;

    setList((pList)=>{
      const appendList = companyDataSelect.reviews.data;
      for(var i = 0 ; i < appendList.length;i++){
        if(!pList.map(i=>i.id).includes(appendList[i].id)){
          pList.push(appendList[i])
        }
      }
      return [...pList];
    });
    
    setPage({
      page:companyDataSelect.reviews.meta.current_page,
      fetching: false,
      isDone: companyDataSelect.reviews.data.length === 0
    });
  },[companyDataSelect]);

    

  function scrollEvent(event){
      setPage((pState)=>{
        if(checkVisible(refLoadMore.current) && pState.fetching === false){
          dispatch(fetchCompanieData(name,pState.page + 1,sort));
          return {
            ...pState,
            page: pState.page + 1,
            fetching:true
          }
        }else{
          return pState; // return default 
        }
      });
  }

  function removeEventScroll(){
    window.removeEventListener("scroll",scrollEvent);
  }
  function initEventListener(){
    window.addEventListener('scroll',scrollEvent);
  }

  function checkVisible(elm) {
    const rect = elm.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  
  var listCondition = [...list];
  if(page.fetching && !page.isDone){
    listCondition.push(null);
    listCondition.push(null);
  }

  return (
    <>
        {listCondition.map((item,index)=>{
            return <ReviewRow item={item} key={index}/>
        })}
        <div ref={refLoadMore}>
          <button hidden >Load More [hidden]</button>
        </div>

    </>
)
})

export default ReviewList