import React, { useState, Suspense, useEffect} from "react"
import AssignTagModal from "../ManageReviews/assignTagsModal";
import { formatHex } from "../../helpers/formatHex";
import axios from "axios";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { ClickAwayListener, Rating, Fade, Skeleton, Grow } from "@mui/material";
import {AiOutlineInstagram,  AiOutlineMail} from "react-icons/ai"
import {BiHide, BiShareAlt, BiNotepad} from "react-icons/bi"
import {BsPinAngle, BsReply} from "react-icons/bs"
import {IoCheckmarkDoneCircleOutline} from "react-icons/io5"
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { showAlert } from "../../../../redux/actions";
import {fetchReviewsHistory, reviewHistoryLoading} from "../../../../redux/actions/admin-index";
import { useDispatch } from "react-redux";
import Note from "./note";
import Email from "./email";
import ReviewHistory from "./reviewHistory";
import Reply from "./reply";
import { AiFillStar} from "react-icons/ai";
import {RiCoupon2Line} from "react-icons/ri"
import Coupon from "./coupon";
import { useTranslation } from "react-i18next";

const FacebookShare = React.lazy(() => import("../../helpers/facebookShare"));

const ReviewItem = ({review}) => {
    const dispatch = useDispatch()
    const [animate, setAnimate] = useState(false)
    const [open, setOpen] = useState(false)
    const [coupon_open, setCouponOpen] = useState(false)
    const [openNote, setOpenNote] = useState(false)
    const [openEmail, setOpenEmail] = useState(false)
    const [show, setShow] = useState(false)
    const [rev, setRev] = useState({...review})
    const [share, setShare] = useState(false)
    const tagsSelected = rev.tags && rev.tags.length > 0 && rev.tags.map(tag => {return tag.id})
    const [t] = useTranslation()

    const updateTags = (tags) => {
        setRev({...rev, tags: tags})
    }

    useEffect(() => {
        setTimeout(() => {
            setAnimate(true)
        },200)
    }, [])

   const hideReview = () => {
        let hide = rev.is_hidden === 0 ? 1 : 0
        setRev({...rev, is_hidden: hide})
        axios.post(`${baseUrlDev}reviews/${review.id}/hide`, {hide}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(res => {
            dispatch(showAlert(true, "Review is hidden successfully!"))
            dispatch(reviewHistoryLoading())
            dispatch(fetchReviewsHistory(review.id))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => console.log(err))
    }    

    const pinReview = () => {
        let pin = rev.is_pinned === 0 ? 1 : 0
        setRev({...rev, is_pinned: pin})
        axios.post(`${baseUrlDev}reviews/${review.id}/pin`, {pin}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(res => {
            dispatch(showAlert(true, "Review is pinned successfully!"))
            dispatch(reviewHistoryLoading())
            dispatch(fetchReviewsHistory(review.id))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => console.log(err))
    }

    const repliedReview = () => {
        let replied = !rev.responded
        setRev({...rev, responded: replied})
        axios.post(`${baseUrlDev}reviews/${review.id}/replied`, {replied}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(res => {
            dispatch(showAlert(true, "Review is marked as replied successfully!"))
            dispatch(reviewHistoryLoading())
            dispatch(fetchReviewsHistory(review.id))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => console.log(err))
    }

    const shareInstagramImage = () => {
        setShare(false)
        axios.get(`${baseUrlDev}reviews/share/${review.id}/instagram`, { headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then((response) => {
            const link = document.createElement('a');
            link.href = response.data.url;
            link.setAttribute('download', 'ireview.jpg');
            document.body.appendChild(link);
            link.click();
         });
    }
    let rating = 5 - (review.rating ? review.rating : 0)
    return (
        <>
            <Note open={openNote} setOpen={setOpenNote} notes={rev.notes} id={rev.id}/>
            <Email openEmail={openEmail} setOpenEmail={setOpenEmail} id={review.id}/>
            <Coupon open={coupon_open} setOpen={setCouponOpen} id={review.id}/>
            <div className="rev-action-buttons justify-content-between">
                <div className="rev-action-buttons">
                    {
                        rev.is_pinned  === 1 ? 
                        <button className="dark-bg-action" onClick={() => pinReview()}>
                            <BsPinAngle/>
                            {t("Pinned")}
                        </button>
                        :
                        <button  onClick={() => pinReview()}>
                            <BsPinAngle/>
                            {t("Pin")}
                        </button>
                    }
                    <button onClick={() => setOpenNote(true)}>
                        <BiNotepad/>
                        {t("Notes")}
                    </button>
                    <ClickAwayListener onClickAway={() => setShare(false)}>
                        <div style={{position: 'relative'}}>
                            <button onClick={() => setShare(!share)}>
                                <BiShareAlt/>
                                {t("Share")}
                            </button>
                            {share &&  
                             <Fade in={share}>
                                <div className="social-share">
                                    <Suspense fallback={      
                                        <div>
                                            <Skeleton style={{height: "15px", width: "100%"}}/>
                                        </div>
                                    }>
                                        <FacebookShare setShare={setShare} id={review.id}/>
                                    </Suspense>
                                    <div onClick={() => shareInstagramImage()}>
                                        <AiOutlineInstagram/> Instagram
                                    </div>
                                    <div onClick={() => {
                                        setShare(false)
                                        setOpenEmail(true)
                                    }}>
                                        <AiOutlineMail/> Email
                                    </div>
                                </div>
                            </Fade>
                            }
                        </div>
                    </ClickAwayListener>
                    {rev.has_coupon && 
                        <button onClick={() => setCouponOpen(true)}>
                            <RiCoupon2Line/>
                            {t("Share Coupon")}
                        </button>
                    }
                    {
                        rev.is_hidden === 1 ?   
                        <button className="dark-bg-action" onClick={() => hideReview()}>
                            <BiHide/>
                            {t("Hidden")}
                        </button> :   
                        <button onClick={() => hideReview()}>
                            <BiHide/>
                            {t("Hide")}
                        </button>
                    }
                </div>
                <div className="rev-action-buttons">
                    {
                        rev.responded ? 
                        <>
                            <button style={{pointerEvents: "none"}}>
                                <IoCheckmarkDoneCircleOutline/>
                                {t("Replied")}
                            </button>
                            <button onClick={() => setShow(!show)} style={{marginRight: '0px'}}>
                                <BsReply/>
                                {t("Post Another Reply")}
                            </button>
                        </>
                        :
                        <>
                        <button onClick={() => setShow(!show)}>
                            <BsReply/>
                            {t("Reply")}
                        </button>
                        <button onClick={() => repliedReview()} style={{marginRight: '0px'}}>
                            <IoCheckmarkDoneCircleOutline/>
                           {t("Mark as Replied")}
                        </button>
                        </>
                    }
                
                </div>
            </div>
            <div className="review-item-container">
                <div className="review-item-container-head">
                    <div className="review-item-container-user">
                        <img src={review.source ? (review.source.logo ? review.source.logo : "/admin-icons/feedback-irevu.svg") : "/admin-icons/feedback-irevu.svg"} platform="irevu" alt={"logo"}/>
                        <div>
                            <div className="review-info-holder">
                                <div style={{display: "block"}}>
                                    <h4>{review.author.name} - <a target="_blank" rel="noreferrer" href={review.source ? (review.source.domain ? review.source.domain : "https://irevu.com") : "https://irevu.com"}>
                                        {review.source ? (review.source.name ? review.source.name : "Irevu") : "Irevu"}</a>
                                    </h4>
                                    <span className="span-date">{review.published_at_formatted}</span>
                                    <div className="rating">
                                        {
                                            [...Array(5 - rating)].map((el, i) => {
                                                return(
                                                    <AiFillStar key={`starFilled-${i}`} className={`star ${animate ? 'animate' : 'default'}`}/>
                                                )
                                            })

                                        }
                                             {
                                            [...Array(rating)].map((el, i) => {
                                                return(
                                                    <AiFillStar key={`starEmpty-${i}`} className='star default'/>
                                                )
                                            })

                                        }
                                    </div>
                                    {/* <Rating icon={<img src={filledIcon} alt="filled-star" className="custom-rating-box-sm"/>} emptyIcon={<img src={emptyIcon} alt="empty-star" className="custom-rating-box-sm"/>} value={review.rating} readOnly /> */}
                                </div>
                                {rev.tags && rev.tags.length > 0 && 
                                    <div>
                                        {rev.tags.map(tag => {
                                            return(
                                                <label key={`tag-inside-review${tag.id}`} 
                                                    style={{color: tag.color, borderColor: tag.color, backgroundColor: formatHex(tag.color, 0.1)}}>
                                                    {tag.name}
                                                </label>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="review-item-container-actions">
                        <AssignTagModal  reviewLoc={review.location.id}  reviewId={review.id} tagsSelectedProp={tagsSelected} updateTags={updateTags}/>
                    </div>
                </div>
                <div className="review-item-container-body">
                    {review.content}
                </div>
                <div className="review-item-container-footer">
                    <div className="location-information">
                        <img src={"/admin-icons/search-icon.svg"} alt="location"/>{review.location.name} - {review.location.address}
                    </div>
                    {review.author && 
                        <div className="customer-information">
                            <h6>{t("Customer Information")}</h6>
                            <div>
                                {review.author.name && <div><span>{t("Name")}:</span>{review.author.name}</div>}
                                {review.author.phone && <div><span>{t("Phone")}:</span> {review.author.phone}</div>}
                                {review.author.email && <div><span>{t("Email")}:</span> {review.author.email}</div>}
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Reply show={show} setShow={setShow} id={review.id} source={review.source} rev={review}/>
            <ReviewHistory id={review.id}/>
        </>
      )
}
export default ReviewItem