import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux"
import { Grid, FormControl, MenuItem, Select, Skeleton } from '@mui/material';
import MySelect from '../../../Helpers/MySelect';
import { useSearchParams } from 'react-router-dom';
import { fetchConnectionFacebook, clearConnectionFacebook } from '../../../../redux/actions/admin-index';
import axios from 'axios';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import { showAlert } from '../../../../redux/actions';
import AlertComponent from "../../../Helpers/Alert"
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getGroupLocation, getGroupSelected, getLocationSelected } from '../../../../redux/actions/admin-index';

const FacebookSettings = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [selectedAcc, setSelectedAcc] = useState(" ")
    const [t] = useTranslation()
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    const location = useSelector(state => state.locationSelected.location)
    const pages = useSelector(state => state.getConnectionFacebook.connection_facebook)
    let code = searchParams.get("code")
    const navigate = useNavigate()

    const getFacebook = (id) => {
        if(code){
            axios.post(`${baseUrlDev}locations/${id}/connect/facebook`, {token: code}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(() => {
                dispatch(fetchConnectionFacebook(id))
                searchParams.delete('code');
                searchParams.delete('state');
                setSearchParams(searchParams);
            })
        } else {
            dispatch(fetchConnectionFacebook(id))
        }
    }

    useEffect(() => {
        let paramloc = searchParams.get("location")
        if(paramloc){

            dispatch(getLocationSelected("all"))
            dispatch(getGroupSelected(""))
            dispatch(getGroupLocation(paramloc))
    
            localStorage.setItem('group', "");
            localStorage.setItem('location', "all");
            localStorage.setItem("location_of_group", paramloc)
        }
    }, [])

    useEffect(() => {
        dispatch(clearConnectionFacebook())
        if(selected && (!location || location == "all")){
            getFacebook(selected)
        }
        if(location && location !== "all"){
            getFacebook(location)
        }
    }, [location, selected])
    

    useEffect(() => {
        if(pages && pages.link){
            window.location.href = pages.link
        } else {
           setSelectedAcc(pages && pages.data.find(el => el.selected === true) ? pages && pages.data.find(el => el.selected === true).fb_page_id : " ")
        }
    }, [pages])

    if(pages === undefined){
        return(
            <Skeleton style={{width: "35%", height: "180px", transform: "none"}}/>
        )
    }

    if(pages && pages.link){
        return(
            <div className='header-container'>
                <h6>{t("You need to connect")}</h6>
            </div>
        )
    }

    const setPage = (value) => {
        setSelectedAcc(value)
    }

    const saveFacebookSettings = () => {
        if(selected && (!location || location == "all")){
            apiRequest(selected)
        }
        if(location && location !== "all"){
            apiRequest(location)
        }
    }

    const apiRequest = (id) => {
        setLoading(true)
        let fb_page_id = selectedAcc

        if(fb_page_id){
            axios.post(`${baseUrlDev}locations/${id}/connections/facebook`, {fb_page_id}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then((res) => {
                setLoading(false)
                setErrors([])
                dispatch(showAlert(true, "Successful Connection!"));
                navigate("/app/admin/reviews/connections")
                setTimeout(()=>{
                  dispatch(showAlert(false,""));
                },5000);  
            }).catch((err) => {
                setLoading(false)
                err?.response?.data?.errors ?
                Object.keys(err.response.data.errors).map(key => {
                    setErrors(errors => [...errors, err.response.data.errors[key][0]])
                })
                :
                setErrors(["Something Went Wrong"])
                setTimeout(() => {
                    setErrors([])
                }, 5000)
            })
        } else {
            !fb_page_id && setErrors(prev => [...prev, "Please Select Page"])
        }
    }

    return (
        <div className='account_settings_page'>
             <AlertComponent/>
            <form action="">
                <Grid container>
                    <Grid item xs={4}>
                        <div className="accounts_settings_wrap">
                            <div>
                                <h2 className="title">{t("Facebook Settings")}</h2>
                            </div>

                            <label htmlFor="">{t("Facebook Page")}</label>
                            <FormControl fullWidth>
                                <Select
                                    id="input-type-one-select"
                                    label="position"
                                    value={selectedAcc}
                                    onChange={(e) => setPage(e.target.value)}
                                    input={<MySelect />}
                                >
                                    <MenuItem disabled value=" ">
                                        <em>{t("Select Page")}</em>
                                    </MenuItem>
                                    {pages.data.map(page => {
                                        return  <MenuItem value={page.fb_page_id}>{page.page_name}</MenuItem>
                                    })}
                                   
                                </Select>
                            </FormControl>
                        </div>
                        <div className='info-page-foot'>
                            <LoadingButton
                                    style={{paddingLeft: loading && "30px"}}
                                    className="btn add_btn blue-loading-btn"
                                    onClick={() => saveFacebookSettings()}
                                    loading={loading}
                                >
                                    {t("Save Changes")}
                            </LoadingButton>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default FacebookSettings;
