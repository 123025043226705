import React, {useState, useEffect} from "react"
import {AiOutlineEdit, AiOutlineDelete} from "react-icons/ai"
import { Dialog, DialogTitle, Alert} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import WhiteTooltip from "../../helpers/whiteTooltip"
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import EdiCartModal from "./EditCartModal"
import { useTranslation } from "react-i18next"

const CardList = ({cards, total, setCardId, card_id}) => {
    const [allCards, setAllCards] = useState()
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [loading, setLoading] = useState(false)
    const [id, setId] = useState()
    const [errors, setErrors] = useState([])
    const [open, setOpen] = useState(false)
    const [cartDetails, setCardDetails] = useState()
    const {t} = useTranslation()
    useEffect(() => {
        if(cards){
            setAllCards(cards.data)
        }
    }, [cards])

    const setCard = (card) => {
        setCardId(card.id)
    }

    const deleteCard = () => {
        setLoading(true)
        axios.post(`${baseUrlDev}subscription/delete/card`, {card_id: id}, {headers: {'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }} )
        .then((res) => {
            if(res.data.status){
                setAllCards(cards.filter(card => card.id !== id))  
            } else {
                setErrors(prev => [...prev, res.data.message])
                setTimeout(() => {
                    setErrors([])
                }, 5000)
            }
            setOpenConfirmation(false)
            setLoading(false)
            setId("")
        })
        .catch((err) => {
            setOpenConfirmation(false)
            setLoading(false)
            setId("")
            Object.keys(err.response.data.errors).map(key => {
                setErrors([...errors, err.response?.data?.errors[key][0]])
            })
            setTimeout(() => {
                setErrors([])
            }, 5000)
        })
    }

    return(
        <>
        <EdiCartModal open={open} setOpen={setOpen} cart={cartDetails} allCards={allCards} setAllCards={setAllCards}/>
            <div className="alert-inside-page">
                {errors && errors.map(err => {
                    return(
                        <Alert key={err} severity="error" className="custom-error-alert">{err}</Alert>
                    )
                })}
            </div>
            <Dialog onClose={() => setOpenConfirmation(false)} open={openConfirmation}>
                <div style={{padding: "20px"}}>
                    <DialogTitle>{t("Are you sure you want to delete this card")}</DialogTitle>
                </div>
                <div className="flex-btns">
                    <LoadingButton
                        style={{paddingLeft: loading && "30px"}}
                        className="delete-btn"
                        onClick={() => deleteCard()}
                        loadingIndicator={t("Deleting") + "..."}
                        loading={loading}
                    >
                        {!loading && t("Delete")}
                    </LoadingButton>
                    <button onClick={() => {
                        setOpenConfirmation(false)
                        setLoading(false)
                    }}>{t("Cancel")}</button>
                </div>
            </Dialog>
            <div className="cart-details-body-top">
                <div>
                    <h5>{t("Total Amount")}</h5>
                    <span>${total}</span>
                </div>
                <div>
                    <img src="/admin-icons/visa.svg" alt="visa"/>
                    <img src="/admin-icons/mastercard.svg" alt="mastercard"/>
                    <img src="/admin-icons/amex.svg" alt="amex"/>
                    <img src="/admin-icons/maes.svg" alt="maes"/>
                    <img src="/admin-icons/discover.svg" alt="discover"/>
                </div>
            </div>
            <div className="card-list-sub custom-scrollbar-vertical custom-scrollbar-horizontal">
            {allCards && allCards.length > 0 && 
                allCards.map(card => {
                    return(
                        <div key={card.id}>
                            <div className="card-action-holder">
                                <WhiteTooltip title={t("Edit Card")}>
                                    <button onClick={() => {
                                        setCardDetails(card)
                                        setOpen(true)
                                    }}>
                                        <AiOutlineEdit/>
                                    </button>
                                </WhiteTooltip>
                                {!card.is_primary && <WhiteTooltip title={t("Delete Card")}>
                                    <button onClick={() => {
                                        setId(card.id)
                                        setOpenConfirmation(true)
                                    }}><AiOutlineDelete/></button>
                                </WhiteTooltip>}
                            </div>
                            <input type="radio" name="card" id={card.id} onChange={() => setCard(card)} checked={card.id === card_id}/>
                            <label className="card-list-item" htmlFor={card.id}>
                                <div>
                                    <h6>{t("Card Name")}</h6>
                                    <span>{card.name_on_card}</span>
                                </div>
                                <div>
                                    <h6>{t("Card Number")}</h6>
                                    <p>**** **** **** {card.last_four_numbers}</p>
                                </div>
                                <div>
                                    <h6>{t("Expiration")}</h6>
                                    <p>{t("Valid Thru")} { '>'} <span>{card.expiration_month}/{card.expiration_year}</span></p>
                                </div>
                            </label>
                        </div>
               
                    )
                })
            }
        </div>
    </>
      
    )
}
export default CardList