import React, {useState, useEffect} from "react";
import { Grid, ClickAwayListener, Skeleton, FormControl, Select, MenuItem, Fade} from "@mui/material";
import {FiEdit3} from "react-icons/fi"
import {BsCheck2} from "react-icons/bs"
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../Helpers/baseUrl";
import { Link } from "react-router-dom";
import { BiExport } from 'react-icons/bi';
//Out imports
import FormMain from "./form-main";
import AddFields from "./modal/add-fields-modal";
import EditForm from "./editForm";
import { fetchFormDetails, formDetailsClear, formModal, formBuilderAction, fetchSubmission } from "../../redux/actions";
import { showYoutubeContent } from "../../redux/actions/admin-index";
import Submissions from "./submissions/Submissions";
import Embedded from "./embedded";
import { getLocalUser } from "../TokenControl/parts/useLocalUser";
import DateRange from "../Admin/helpers/DateRange";
import { formatDateLine } from "../Admin/helpers/formatDate";
import { MySelectBgArrow } from "../Helpers/MySelectBorderless";
import useHandleDisable from "../Admin/helpers/disableSelect";
import { useTranslation } from "react-i18next";

const FormDetails = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const [read, setRead] = useState(true)
    const [value, setValue] = useState('2');
    const [show, setShow] = useState(false)
    const [name, setName] = useState('')
    const [sort, setSort] = useState("desc")
    const [inputWidth, setInputWidth] = useState('')
    const [search, setSearch] = useState("")
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const handleDisable = useHandleDisable()
    const {t} = useTranslation()
    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/dL6Rx1nr3MI?autoplay=1&mute=0&rel=0'))
        handleDisable()
    }, [])
    
    useEffect(() => {
        dispatch(fetchFormDetails(id))
        return () => {
            dispatch(formDetailsClear())
        }
    }, [id])

    const form = useSelector(state => state.getFormDetails.form)

    useEffect(() => {
        if(form && form.data.name){
            setInputWidth(form.data.name.length)
        }
    }, [form])

    const updateName = (e) => {
        setRead(true)
    }
    const changeFormName = () => {
        if(name){
            axios.put(`${baseUrl}form/${id}`, {name}).then(res => {
                dispatch(fetchFormDetails(id))
           })
        }
        setRead(true)
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleChangeSort = (event) => {
        setSort(event.target.value);
    }

    const exportSubmissions = () => {
        axios.post(`${baseUrl}forms/form/${id}/export`, {}, { responseType: 'arraybuffer', headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'form_submissions.csv');
            document.body.appendChild(link);
            link.click();
         });
    }

    const handleDateChange = (item) => {
        let itemSerialized = {
            startDate: formatDateLine(item.selection.startDate),
            endDate: formatDateLine(item.selection.endDate)
        }
        if(item.selection.startDate !== item.selection.endDate){
            
        }
        if(!item.selection.startDate){
            setFrom(null)
            setTo(null)
        } else {
            setFrom(itemSerialized.startDate)
            setTo(itemSerialized.endDate)
        }
    }

    return(
        <FormMain>
            <AddFields show={show} setShow={setShow}/>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <ClickAwayListener onClickAway={() => updateName(true)}>
                        <div className="forms-details-header mb-50">
                            {form === undefined ? <Skeleton width={100} height={20} /> : <input type="text" style={{width: inputWidth + `ch`}} defaultValue={form.data && form.data.name} readOnly={read} onChange={(e) => setName(e.target.value)}/>}
                            {/* {read == true ? <FiEdit3 onClick={() => setRead(false)} /> : <BsCheck2 onClick={() => changeFormName()}/>}    */}
                            {read == true ? <span className="icon-mydevicon-edit-bordered" style={{cursor: 'pointer'}} onClick={() => setRead(false)}></span> : <BsCheck2 onClick={() => changeFormName()}/>}   
                        </div>
                    </ClickAwayListener>
                </Grid>
                <Grid item xs={12} md={6}>
                    {form && form.data && 
                        <div className="form-details-container-actions">
                            <a className="preview-form" href={`/forms/${form.data.identifier}`} target="_blank">{t("Preview Form")}</a>
                        </div>
                    }
                </Grid>
            </Grid>
            <Fade in={value === "2"}>
                <Grid container>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <input type="search" value={search}  placeholder={t("Search Submission")} className="search-input-replies" onChange={(e) => setSearch(e.target.value)}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <div className="form-details-container-actions">
                            <FormControl style={{width: '150px', marginRight: "10px"}}>
                                <Select
                                    value={sort}
                                    input={<MySelectBgArrow/>}
                                    onChange={handleChangeSort}
                                >
                                    <MenuItem value="desc">{t("Latest")}</MenuItem>
                                    <MenuItem value="asc">{t("Oldest")}</MenuItem>
                                </Select>
                            </FormControl>
                            <DateRange handleDateChange={handleDateChange}/>
                            <button disabled={!form} onClick={() => exportSubmissions()} className="btn"><BiExport/>{t("Export to Excel")}</button>
                        </div>
                    </Grid>
                </Grid>
            </Fade>
            <Grid container className="form-details-container" sx={{padding: "0px"}}>
                <Grid item xs={12}>
                    <TabContext value={value}>
                    <TabList
                      
                        onChange={handleChange}  
                        aria-label="Form Details"
                        className="my-color"
                    >
                        <Tab label={t("Edit Form")} value="1" />
                        <Tab label={t("submission")} value="2" />
                        <Tab label={t("Share")} value="3" />
                    </TabList>
                    <TabPanel value="1" index={0} style={{padding: "0px"}}>
                        <EditForm form={form} id={id} />
                    </TabPanel>
                    <TabPanel value="2" index={1}>
                        <Submissions search={search} sort={sort} from={from} to={to}/>
                    </TabPanel>
                    <TabPanel value="3" index={1}>
                        <Embedded id={form && form.data.identifier}/>
                    </TabPanel>
                   </TabContext>
                 
                </Grid>

                {value === "1" && <div className="bottom-fixed bottom-blue-button">
                    <button onClick={() => {
                        setShow(true)
                        dispatch(formBuilderAction("create"))
                    }}><img src="/admin-icons/plus.svg" alt="add" />{t("Add Field")}</button>
                </div>}
            </Grid>
       
        </FormMain>
       
    )
}
export default FormDetails