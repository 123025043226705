import React, {useState, useMemo, useEffect} from "react"
import { Fade, CircularProgress  } from "@mui/material"
import {useSelector, useDispatch} from "react-redux"
import { fetchLocationTags, fetchReviews, loadingTables } from "../../../../redux/actions/admin-index"
import { showAlert } from "../../../../redux/actions"
import axios from "axios"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { formatHex } from "../../helpers/formatHex"
import { BsCheck2Circle } from "react-icons/bs"
import { useTranslation } from "react-i18next"

let controllerTags = new AbortController();

const AssignTagModal = ({reviewLoc, reviewId, tagsSelectedProp, updateTags}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [tagsSelected, setTagsSelected] = useState([])
    const [open, setOpen] = useState(false)
    const [t] = useTranslation()

    const location = useSelector(state => state.locationSelected.location)
    const tools = useSelector(state => state.allLocationSources.tools)

    const tagsFiltered = tools && tools.tags
    const tags = tagsFiltered && tagsFiltered[reviewLoc] ? tagsFiltered.system.concat(tagsFiltered[reviewLoc]) : tagsFiltered && tagsFiltered.system

    useEffect(() => {
        if(tagsSelectedProp && tagsSelectedProp.length > 0){
            setTagsSelected(tagsSelectedProp)
        } else {
            setTagsSelected([])
        }
    }, [tagsSelectedProp])

    const submitSource = (arr) => {
        setLoading(true)
        
        controllerTags.abort();
        controllerTags = new AbortController()

        axios.post(`${baseUrlDev}reviews/${reviewId}/tags/sync`, {tags: arr}, {signal: controllerTags.signal , headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
       .then(res => {
            setLoading(false)
            setOpen(false)
            setTagsSelected([])
            dispatch(showAlert(true, "Tag Added Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
            if(updateTags){
                const result = arr.map(tag => {
                    return tags.filter(it => it.id === tag)[0]
                });
                updateTags(result)
            }
        })
 
    }

    const handleTags =  (e, tagId) => {
        e.stopPropagation()
        e.preventDefault()
        if(tagsSelected.filter(el => el === tagId).length > 0){
            setTagsSelected(tagsSelected.filter(el => el !== tagId))
            submitSource(tagsSelected.filter(el => el !== tagId))
        } else {
            setTagsSelected([...tagsSelected, tagId])
            submitSource([...tagsSelected, tagId])
        }
    }
    
    return(
        <div onMouseEnter={(e) => setOpen(true)} onMouseLeave={(e) => setOpen(false)}>
            <button onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
            }}>{loading && <CircularProgress />}{t("Assign Tags")}</button>
            <Fade in={open}>
                <div className="tags-checkboxes custom-scrollbar-vertical">
                    {
                        tags && tags.map(tag => {
                            return(
                                <div key={`assignTag${`${reviewId}` + tag.id}`}>
                                    <label 
                                        onClick={(e) => handleTags(e, tag.id)}
                                        style={{backgroundColor: formatHex(tag.color, 0.4), color: tag.color}}>
                                        {tagsSelected && tagsSelected.includes(tag.id) && <BsCheck2Circle/>}
                                        {tag.name}
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
            </Fade>
        </div>
    )
}
export default AssignTagModal