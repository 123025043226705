import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { fetchLocationConnections, clearLocationConnections } from '../../../../redux/actions/admin-index';
import axios from 'axios';
import { showAlert } from '../../../../redux/actions';
import AlertComponent from '../../../Helpers/Alert';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import LightTooltip from '../../helpers/lightTooltip';
import { MdBlock } from "react-icons/md";


const ConnectionsTable = () => {
    const dispatch = useDispatch()
    const [stateConnections, setStateConnections] = useState()
    const [loadingDelete, setLoadingDelete] = useState()
    const user = useSelector(state => state.getUserInfo.user)
    const location = useSelector(state => state.locationSelected.location)
    const connections = useSelector(state => state.getLocationConnections.connections)
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    const [t] = useTranslation()
    useEffect(() => {
        if (connections) {
            setStateConnections({ ...connections })
            setLoadingDelete(Array(3).fill(false))
        }
    }, [connections])

    useEffect(() => {
        if(selected && (!location || location === "all")){
            dispatch(clearLocationConnections())
            dispatch(fetchLocationConnections(selected))
        }
    }, [selected])

    useEffect(() => {
        if (location && location !== "all") {
            dispatch(clearLocationConnections())
            dispatch(fetchLocationConnections(location))
        }
    }, [location])

    const connectionTypes = [
        { key: 'facebook', label: 'Facebook Connect', index: 0 },
        { key: 'google', label: 'Google Connect', index: 1 },
        { key: 'yelp', label: 'Yelp Connect', index: 2 },
        // { key: 'tripAdvisor', label: 'TripAdvisor Connect', index: 3 },
        { key: 'openTable', label: 'OpenTable Connect', index: 4 },
        { key: 'booking.com', label: 'Booking.com Connect', index: 5 },
    ];

    const skeletonStyle = {
        width: "100%",
        height: "50px",
        transform: "unset",
        marginBottom: "15px"
    };

    if (!connections || !stateConnections) {
        return (
            <div style={{ marginTop: "30px" }}>
                {Array.from({ length: connectionTypes.length }).map((_, index) => (
                    <Skeleton
                        key={index}
                        style={index === connectionTypes.length - 1 ? { ...skeletonStyle, marginBottom: "0" } : skeletonStyle}
                    />
                ))}
            </div>
        )
    }

    const disconnect = (platform, index) => {
        if(selected && (!location || location === "all")){
            apiRequest(platform, index, selected)
        }
        if (location && location !== "all") {
            apiRequest(platform, index, location)
        }
    }

    const apiRequest = (platform, index, id) => {
        setLoadingDelete((prevLoading) => {
            const newLoading = [...prevLoading];
            newLoading[index] = true;
            return newLoading;
        });
        axios.get(`${baseUrlDev}locations/${id}/disconnect/${platform}`, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then((res) => {
            if (platform === "facebook") {
                setStateConnections(prevState => ({ ...prevState, facebook: { ...prevState.facebook, connected: false } }))
            } else if (platform === "google") {
                setStateConnections(prevState => ({ ...prevState, google: { ...prevState.google, connected: false } }))
            } else if (platform === "yelp") {
                setStateConnections(prevState => ({ ...prevState, yelp: { ...prevState.yelp, connected: false } }))
            } else if (platform === "openTable") {
                setStateConnections(prevState => ({ ...prevState, openTable: { ...prevState.openTable, connected: false } }))
            } else if (platform === "tripAdvisor") {
                setStateConnections(prevState => ({ ...prevState, tripAdvisor: { ...prevState.tripAdvisor, connected: false } }))
            } else if (platform === "booking.com") {
                setStateConnections(prevState => ({ ...prevState, booking: { ...prevState.booking, connected: false } }))
            }
            setLoadingDelete((prevLoading) => {
                const newLoading = [...prevLoading];
                newLoading[index] = false;
                return newLoading;
            });
            dispatch(showAlert(true, `${platform} disconnected successfully!`));
            setTimeout(() => {
                dispatch(showAlert(false, ""));
            }, 5000);
        })
            .catch(err => {
                setLoadingDelete((prevLoading) => {
                    const newLoading = [...prevLoading];
                    newLoading[index] = false;
                    return newLoading;
                });
            })
    }

    return (
        <div className='table_wrapper custom-horizontal-scrollbar'>
            <AlertComponent />
            <table className='general__table'>
                <thead>
                    <tr>
                        <th>{t("SOURCE & ACCOUNT NAME")}</th>
                        <th>{t("Connected")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {connectionTypes.map(connection => (     
                        <tr key={connection.key}>
                            <td><div style={{display: "flex", alignItems: "center"}}>
                                <img style={{marginRight: "10px", borderRadius: "100%", boxShadow: `${(connection.key === 'google' || connection.key === 'openTable') ? 'rgb(37 49 60 / 11%) 0px 6px 15px' : ''}`}} src={stateConnections[connection.key]?.icon} alt="icon"/>
                                    {t(connection.label)}
                                </div>
                            </td>
                            <td>{stateConnections[connection.key]?.connected ? "Yes" : "No"}</td>
                            <td className='connect_btns_wrapper'>
                                {(stateConnections[connection.key]?.permissions) ?
                                    stateConnections[connection.key]?.connected ?
                                        <>
                                            <Link to={`/app/admin/reviews/connections/${connection.key}`} className='btn connect_btn' state={connections[connection.key]}>{t("Settings")}</Link>
                                            <LoadingButton
                                                style={{ marginLeft: '10px', textTransform: 'none' }}
                                                onClick={() => { disconnect(connection.key, connection.index) }}
                                                variant="outlined" color="error"
                                                loading={loadingDelete[connection.index]}
                                            >
                                                {t("Disconnect")}
                                            </LoadingButton>
                                        </>
                                        :
                                        <Link to={connection.key} type='button' className='btn connect_btn'>{t("Connect")}</Link>
                                    : 
                                    <LightTooltip title={<p>{t("Connection_permission_first")} <span style={{textTransform: "capitalize"}}>{connection.key}</span>. {t("Connection_permission_second")} <span style={{textTransform: "capitalize"}}>{connection.key}</span> {t("Connection_permission_third")}.</p>}>
                                        <button className='button-disabled-connections' disable={true}><MdBlock />Connect</button>
                                    </LightTooltip>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ConnectionsTable;