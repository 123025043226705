import React, {useState, useEffect} from "react"
import ReviewCollection from "./reviewCollection"
import { useTranslation } from "react-i18next"
import SetTargetModal from "./setTargetModal"
import { useDispatch, useSelector } from "react-redux"
import { fetchDashboardReviewCollection, clearDashboardReviewCollection } from "../../../../redux/actions/admin-index"
import {IoIosSettings} from "react-icons/io"
import { Skeleton, IconButton } from "@mui/material"
import LightTooltip from "../../helpers/lightTooltip"
import { IoMdInformationCircleOutline } from "react-icons/io"

const ReviewCollectionContainer = () => {
    const [active, setActive] = useState(2)
    const [open, setOpen] = useState(false)
    const [progress, setProgress] = useState(0)
    const [reviewTarget, setReviewTarget] = useState(0)
    const [dailyTarget, setDailyTarget] = useState(false)
    const [dailyTargetValue, setDailyTargetValue] = useState(false)
    const [modalUse, setModalUse] = useState("set")
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)

    useEffect(() => {
        dispatch(fetchDashboardReviewCollection(location, groupSelected))
    }, [location, groupSelected])

    const review_collection = useSelector(state => state.getReviewCollectionDashboard.review_collection)

    
    useEffect(() => {
        if(review_collection){
            if(active === 1){
                setProgress(review_collection.dailyTargetPercentage)
                setReviewTarget(review_collection.countDailyTarget)
            } else if (active === 2){
                setProgress(review_collection.weeklyTargetPercentage)
                setReviewTarget(review_collection.countWeeklyTarget)
            } else {
                setProgress(review_collection.monthlyTargetPercentage)
                setReviewTarget(review_collection.countMonthlyTarget)
            }
            setDailyTarget(review_collection.dailyTarget)
            setDailyTargetValue(review_collection.dailyTarget)
        }   
    }, [review_collection, active])

    return(
      <>
        <SetTargetModal open={open} setOpen={setOpen} modalUse={modalUse} dailyTarget={dailyTarget} setDailyTarget={setDailyTarget} setDailyTargetValue={setDailyTargetValue}/>
        {review_collection != undefined ? 
          <div className="review_collection_container_dashboard dashboard-box-containers">
                <LightTooltip title={
                    <>{
                        t("Track your daily/weekly/monthly progress towards your set target with precision")}<br/>
                        <hr/>
                        {t("review collection tooltip")}
                    </>}
                >
                    <IconButton className="tooltip-btn-holder">
                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                    </IconButton>
                </LightTooltip>
                <h2>{t("Target Collection")}</h2>
                <div className="review_collection_header_container">
                    <div className={active === 1 ? "active" : ""} onClick={() => setActive(1)}>
                        {t("Daily")}
                    </div>
                    <div className={active === 2 ? "active" : ""} onClick={() => setActive(2)}>
                        {t("Weekly")}
                    </div>
                    <div className={active === 3 ? "active" : ""} onClick={() => setActive(3)}>
                        {t("Monthly")}
                    </div>
                </div>
                {(location && location !== "all") && !groupSelected &&
                    <IoIosSettings onClick={() => {
                        setOpen(true)
                        setModalUse("edit")
                    }}/>
                }
                <div className="review_collection_body_container review_collection_dashboard">
                    <ReviewCollection percentage={progress} reviewTarget={reviewTarget} active={active} dailyTarget={dailyTargetValue}/>
                </div>  
                {(location && location !== "all") && !groupSelected && 
                    (!dailyTargetValue || dailyTargetValue == 0) &&
                    <div className="blured-review-collection">
                        <p>{t("Define Your Daily Review Target")}</p>
                        <button onClick={() => {
                            setOpen(true)
                            setModalUse("set")
                        }}>{t("Set Target")}</button>
                    </div>   
                }
            </div>
            : <Skeleton style={{width: "100%", transform: "unset", height: "300px"}}/>
        }
      </>
    )
}
export default ReviewCollectionContainer