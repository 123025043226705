import React, { useEffect,useState } from 'react'
import { AiFillStar } from 'react-icons/ai';

const ReviewProgressBar = ({label,value,total = 0}) => {
  

  const [percent,setPercent] = useState(0);
  useEffect(()=>{
    if(total !== 0) setPercent(value/total*100);
  },[]);


  return (
    <div className="d-flex align-items-center" style={{marginBottom: '5px'}}>
        <span className='star-alt-num' style={{"width": label.length+"ch",textAlign:"center"}}>{label}</span>
        <AiFillStar fontSize="0.7em" color="#FFBE1D" />
        <span style={{"--progress-percent": percent+"%"}} className="isu-progress">
        </span>
        <span className='review-alt-num' style={{minWidth: "4ch",textAlign:"right"}}>{value}</span>
    </div>
  )
}

export default ReviewProgressBar