const initialState = {
    notifications: [], // Initialize as an empty array
};


export const getNotifications = (state = [], action) => {
    switch (action.type) {
        case 'GET_NOTIFICATIONS_LOAD_MORE':
            return {
                ...state,
                notifications: [...state.notifications, ...action.payload.notifications],
                    loading_more: action.payload.load_more,
                    loading: false
            };
        case 'SET_LOADING_NOTIFICATIONS':
            return {
                ...state,
                loading: true,
            };
        case 'GET_NOTIFICATIONS':
            return {
                ...state,
                notifications: action.payload.notifications,
                    loading_more: action.payload.load_more
            };
        case 'GET_NOTIFICATIONS_FILTER':
            return {
                ...state,
                notifications: state.notifications.filter(noty => noty.id !== action.payload)
            }
            case 'READ_ALL_NOTIFICATIONS':
                return {
                    ...state,
                    notifications: state.notifications.map(notification => ({
                        ...notification,
                        read: 1,
                    })),
                };
            case 'CLEAR_NOTIFICATIONS_FILTER':
                return {
                    ...state,
                    notifications: undefined
                }
                default:
                    return {
                        ...state
                    }
    }
}

export const getNotificationsCount = (state = {}, action) => {
    switch (action.type) {
        case 'GET_NOTIFICATIONS_COUNT':
            return {
                ...state,
                notifications_count: action.payload,
            }
            default:
                return {
                    ...state
                }
    }
}