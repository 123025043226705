import React, { useState } from "react"
import { Modal, Slide, Skeleton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { baseUrlDev } from "../../../../Helpers/baseUrl";
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser";
import { fetchGroupPage, fetchGroups } from "../../../../../redux/actions/admin-index";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../../../../../redux/actions";
import { Alert } from "@mui/material";
import LocationsGroupPage from "./locationsGroupPage";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";

const initialFormData = {
    url: '',
    name: '',
    description: '',
    company_name: '',
    heading: '',
    sub_heading: '',
    phone: '',
    website: '',
    email: '',
    logo: null,
    short_url: '',
    path: '',
};

const CreateGroupPage = ({ open, setOpen }) => {
    const dispatch = useDispatch()
    const [locationGroup, setLocationGroup] = useState([])
    const [formData, setFormData] = useState(initialFormData);
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])

    const location = useSelector(state => state.locationSelected.location);
    const user = useSelector(state => state.getUserInfo.user)
    let locations = user && user.user.locations

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    }

    const handleClose = () => {
        setOpen(false)
        setLoading(false)
        setErrors([])
        setFormData(initialFormData);
        setLocationGroup([])
    }

    const submitGroup = () => {
        if (formData.url.length < 1) {
            setErrors([...[], "Url is required"])
            return
        } else if (formData.name.length < 4 || formData.name.length > 35) {
            setErrors([...[], "Group name must be between 4-35 chars"])
            return
        } else if (formData.description.length < 1) {
            setErrors([...[], "Group Description is required"]);
            return
        } else if (formData.company_name.length < 1) {
            setErrors([...[], "Company Name is required"]);
            return
        } else if (formData.heading.length < 1) {
            setErrors([...[], "Heading is required"]);
            return
        } else if (formData.sub_heading.length < 1) {
            setErrors([...[], "Sub Heading is required"]);
            return
        } else if (formData.phone.length < 1) {
            setErrors([...[], "Phone is required"]);
            return
        } else if (formData.website.length < 1) {
            setErrors([...[], "Website is required"]);
            return
        } else if (formData.email.length < 1) {
            setErrors([...[], "Email is required"]);
            return
        } else if (locationGroup.length < 1) {
            setErrors([...[], "Minimum one location is required to create group"])
            return
        }
        setLoading(true)
        // let data = { name, location_ids: locationGroup, description }
        let data = { ...formData, location_ids: locationGroup }
        axios.post(`${baseUrlDev}group/create`, data, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                dispatch(fetchGroupPage(location))
                dispatch(fetchGroups())
            }).then(res => {
                handleClose()
                dispatch(showAlert(true, "Group Created Succesfully!"))
                setTimeout(() => {
                    dispatch(showAlert(false, ""))
                }, 5000)
            }).catch(err => {
                setLoading(false);
                Object.keys(err.response.data.errors).map(key => {
                    setErrors([...errors, err.response.data.errors[key][0]])
                })
            })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-group-title"
            aria-describedby="modal-group-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className={`custom-modal-dialog`}>
                    {errors && errors.map(err => {
                        return (
                            <div className="alert-inside-modal" key={err}>
                                <Alert severity="error" className="custom-error-alert">{err}</Alert>
                            </div>
                        )
                    })}
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>{t("Add Group")}</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-div-container custom-scrollbar-vertical">
                                <div className="field-container">
                                    <label>Url</label>
                                    <input value={formData?.url} minLength={4} maxLength={35} type="text" placeholder="Url" onChange={(e) => handleChange('url', e.target.value)} />
                                </div>
                                <div className="field-container">
                                    <label>{t("Group Name")}</label>
                                    <input value={formData?.name} minLength={4} maxLength={35} type="text" placeholder={t("Add group name")} onChange={(e) => handleChange('name', e.target.value)} />
                                </div>
                                <div className="field-container">
                                    <label>{t("Group Description")}</label>
                                    <textarea value={formData?.description} type="text" maxLength={255} placeholder={t("Add group description")} onChange={(e) => handleChange('description', e.target.value)} />
                                </div>
                                <div className="field-container">
                                    <label>{t("Company Name")}</label>
                                    <input value={formData?.company_name} type="text" placeholder={t("Add company name")} onChange={(e) => handleChange('company_name', e.target.value)} />
                                </div>
                                <div className="field-container">
                                    <label>{t("Heading")}</label>
                                    <input value={formData?.heading} type="text" placeholder={t("Add heading")} onChange={(e) => handleChange('heading', e.target.value)} />
                                </div>
                                <div className="field-container">
                                    <label>{t("Sub-Heading")}</label>
                                    <input value={formData?.sub_heading} type="text" placeholder={t("Add sub heading")} onChange={(e) => handleChange('sub_heading', e.target.value)} />
                                </div>
                                <div className="field-container">
                                    <label>{t("Phone")}</label>
                                    <PhoneInput
                                        inputProps={{
                                            name: t('Phone'),
                                        }}
                                        placeholder={t("Enter Phone")}
                                        enableSearch
                                        value={formData?.phone}
                                        onChange={(e) => handleChange('phone', e)}
                                        country='us'
                                    />
                                </div>
                                <div className="field-container">
                                    <label>{t("Website")}</label>
                                    <input value={formData?.website} type="text" placeholder={t("Add website")} onChange={(e) => handleChange('website', e.target.value)} />
                                </div>
                                <div className="field-container">
                                    <label>{t("Email")}</label>
                                    <input value={formData?.email} type="text" placeholder={t("Add email")} onChange={(e) => handleChange('email', e.target.value)} />
                                </div>
                                <div className="field-container mb-3">
                                    <label>{t("Locations")}</label>
                                    {(user && locations && locations.length > 0) ?
                                        <LocationsGroupPage locationGroup={locationGroup} setLocationGroup={setLocationGroup} setErrors={setErrors} />
                                        : <Skeleton style={{ height: '37px', transform: 'unset' }} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <LoadingButton
                                style={{ paddingLeft: loading && "30px" }}
                                className="save-btn"
                                onClick={() => submitGroup()}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                            <button className="close-btn" onClick={() => handleClose()}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default CreateGroupPage