import React, { useState, useRef, useEffect, Suspense } from "react"
import { useParams } from "react-router";
import "../../../../assets/admin.css";
import Grid from '@mui/material/Grid';
import { Skeleton, Box, FormControl, TextField, Select, MenuItem, Button, Alert, IconButton, OutlinedInput, InputLabel, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { HiUsers, HiOutlineMail } from "react-icons/hi";
import { FiCreditCard } from "react-icons/fi";
import PhoneInput from 'react-phone-input-2';
import { countries } from "../../../Helpers/countries";
import axios from "axios";
import { ValidationTextField, ValidationSelectField } from "../../../Helpers/ValidationTextField";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import {showAlert}  from "../../../../redux/actions"
import AlertComponent from "../../../Helpers/Alert"
import { LoadingButton } from "@mui/lab";
import { americaStates, canadaStates, mexicoStates } from "../../../Helpers/states";
import { FlagIcon } from "react-flag-kit";
import useHandleDisable from "../../helpers/disableSelect";
import Header from "../../../Helpers/header"
import { useTranslation } from "react-i18next";
const LocationPermissions = React.lazy(() => import("./locationPermissions"))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AddUser = () => {
    const handleDisable = useHandleDisable()
    const { id } = useParams()
    const phoneInputRef = useRef(null);
    const dispatch = useDispatch()
    const {t} = useTranslation()
    //form inputs
    const [formFields, setFormFields] = useState({
        first_name: '',
        last_name: '',
        title: '',
        email: '',
        street: '',
        city: '',
        state: ' ',
        zip: '',
        country: 'US',
        phone: '',
        password: '',
        allowed_locations: [],
        is_subuser: 1,
        not_allowed_locations: [],
    });
    const [formFieldsChanged, setFormFieldsChanged] = useState({
        first_name: false,
        last_name: false,
        title: false,
        email: false,
        street: false,
        city: false,
        state: false,
        zip: false,
        country: false,
        phone: false,
        password: false,
        is_subuser: false
    });
    const [showPassword, setShowPassword] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [file, setFile] = useState('');
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false)
    const [submit_errors, setSubmitErrors] = useState([])

    const [errors, setErrors] = useState([])
    const user = useSelector(state => state.getUserInfo.user);
    const locations = user && user.user.locations;


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
        setFormFieldsChanged({ ...formFieldsChanged, [name]: true });
    };


    const handleChangeImg = (e) => {
        const files = Array.from(e.target.files)
        setAvatar(URL.createObjectURL(files[0]))
        setFile(files[0])
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {event.preventDefault();};

    // useEffect(() => {
    //     handleDisable()
    // }, [])

    useEffect(() => {
        if(locations){
            const newColumns = {
                ...columns,
                notAllowed: {
                    items: locations.map(loc => {
                        return{
                            id: loc.id,
                            companyName: loc.full_name
                        }
                    })
                },
                allowed: {
                    items: []
                },
            }
            setColumns(newColumns)
        }
    },[locations])

    const handleSubmit = async (e) => {
        if(columns.allowed.items.length == 0){
            setSubmitErrors(prev => [...prev, "At least one allowed location is required"])
            setTimeout(() => {
                setSubmitErrors([])
            }, 5000)
            return
        }
        setLoading(true)
        var formData = new FormData();
        formData.append('photo', file);

        let formatAllowed = columns.allowed.items.map(el => {
            return{
                [el.id]: {
                    permissions: el.permissions ? el.permissions : [],
                    notifications: el.notifications ? el.notifications : []
                }
            }
        })

        let formatNotAllowed = columns.notAllowed.items.map(el => el.id)
        const formattedPhoneNumber = phoneInputRef.current.state.formattedNumber

        Object.keys(formFields).map(key => {
            if(key === "allowed_locations"){
                formData.append("allowed", JSON.stringify(formatAllowed))
            } else if (key === "not_allowed_locations"){
                formatNotAllowed.forEach(p => {
                    formData.append("not_allowed[]", p)
                }) 
            } else if (key === "phone"){
                formData.append(key, formattedPhoneNumber);
            } else {
                formData.append(key, formFields[key]);
            }
        })
        axios.post(`${baseUrlDev}users/create`, formData, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(() => {
            setLoading(false)
            dispatch(showAlert(true, "User created Succesfully!"));
            setTimeout(()=>{
                dispatch(showAlert(false,""));
            },5000);  
        }).catch((err) => {
            setLoading(false)
            Object.keys(err.response.data.errors).map(key => {
                setSubmitErrors(prev => [...prev, err.response.data.errors[key][0]])
            })
            setTimeout(() => {
                setSubmitErrors([])
            }, 5000)
        })
    }
    return (
        <div>
            <AlertComponent/>
            <div className="alert-inside-page">                 
                {submit_errors && submit_errors.map(err => {
                    return(
                        <Alert key={err} severity="error" className="custom-error-alert">{err}</Alert>
                    )
                })}
            </div>
            <div className="account-settings-container">
                <div className="flex-header-container">
                    <Header title={t('Account')} subtitle={t("User Management / Add User")}/>
                </div>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <div className="account-image user-profile-photo">
                            <div className="image-user">
                                <div>
                                    <label htmlFor="avatar">
                                        <img src={"/admin-icons/pencil-rounded-bg.svg"} className="editIcon" alt="" />
                                    </label>
                                    <img src={avatar ? avatar : "/admin-icons/uploadIcon.svg"} alt={avatar} />
                                    <input type="file" id="avatar" onChange={(e) => handleChangeImg(e)} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3} className="account-inputs-section">
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">                    
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <ValidationTextField
                                    type="text"
                                    onChange={handleChange}
                                    name='first_name'
                                    label={t("First Name")}
                                    variant="outlined"
                                    defaultValue={formFields.first_name}
                                    required={formFieldsChanged.first_name}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><HiUsers /></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <ValidationTextField
                                    type="text"
                                    onChange={handleChange}
                                    name='last_name'
                                    label={t("Last Name")}
                                    variant="outlined"
                                    value={formFields.last_name}
                                    required={formFieldsChanged.last_name}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><HiUsers /></InputAdornment>,
                                    }}
                                />
                            </FormControl>  
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">  
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <ValidationTextField
                                    type="text"
                                    onChange={handleChange}
                                    name='title'
                                    label={t("Title")}
                                    variant="outlined"
                                    value={formFields.title}
                                    required={formFieldsChanged.title}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><FiCreditCard /></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">                          
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <ValidationTextField
                                    type="email"
                                    onChange={handleChange}
                                    name='email'
                                    label={t("Email Address")}
                                    variant="outlined"
                                    value={formFields.email}
                                    required={formFieldsChanged.email}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><HiOutlineMail /></InputAdornment>,
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">  
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">{t("Password")}</InputLabel>
                                <OutlinedInput
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={formFields.password}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label={t("Password")}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                {formFields.country !== "US" && formFields.country !== "CA" && formFields.country !== "MX" ?
                                    <ValidationTextField
                                        type="text"
                                        onChange={handleChange}
                                        name='state'
                                        label={t("State")}
                                        variant="outlined"
                                        placeholder={t("State")}
                                        value={formFields.state}
                                    />
                                    : 
                                    <Select
                                        labelId="select-state-label"
                                        id="demo-simple-select-error"
                                        style={{ width: '100%', height: '47px', marginBottom: "6px" }}
                                        name='is_subuser'
                                        value={formFields.is_subuser}
                                        label={t("Role")}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                        onChange={handleChange}
                                        input={<ValidationSelectField touched={formFieldsChanged.is_subuser ? formFieldsChanged.is_subuser : undefined} />}
                                    >
                                        <MenuItem className="mym" value=" " disabled><em>{t("Select Role")}</em></MenuItem>
                                        <MenuItem value="0">{t("Manager")}</MenuItem>
                                        <MenuItem value="1">{t("Sub User")}</MenuItem>
                                    </Select>
                                }
                            </FormControl>
                        </Box>
                    </Grid>   
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <PhoneInput
                                    inputProps={{
                                        name: 'phone',
                                    }}
                                    specialLabel={t("Phone")}
                                    ref= {phoneInputRef}
                                    enableSearch
                                    value={formFields.phone}
                                    onChange={(val) => handleChange({ target: { name: "phone", value: val } })}
                                    required
                                    country={"us"}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <ValidationTextField
                                    type="text"
                                    onChange={handleChange}
                                    name='street'
                                    label={t("Street")}
                                    variant="outlined"
                                    value={formFields.street}
                                    required={formFieldsChanged.street}/>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <ValidationTextField
                                    type="text"
                                    onChange={handleChange}
                                    name='city'
                                    label={t("City")}
                                    variant="outlined"
                                    value={formFields.city}
                                    required={formFieldsChanged.city} />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                {formFields.country !== "US" && formFields.country !== "CA" && formFields.country !== "MX" ?
                                    <ValidationTextField
                                        type="text"
                                        onChange={handleChange}
                                        name='state'
                                        label={t("State")}
                                        variant="outlined"
                                        placeholder={t("State")}
                                        value={formFields.state}
                                    />
                                    : 
                                    <Select
                                        labelId="select-state-label"
                                        id="demo-simple-select-error"
                                        style={{ width: '100%', height: '47px', marginBottom: "6px" }}
                                        name='state'
                                        value={formFields.state}
                                        label={t("State")}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                        onChange={handleChange}
                                        input={<ValidationSelectField touched={formFieldsChanged.state ? formFieldsChanged.state : undefined} />}
                                    >
                                        <MenuItem className="mym" value=" "><em>{t("State")}</em></MenuItem>
                                        {formFields.country === "US" ? 
                                            americaStates.map(state => {
                                                return (
                                                    <MenuItem key={state.code2A} value={state.code2A}>{state.name}</MenuItem>
                                                )
                                            })
                                            : 
                                            formFields.country === "CA" ? 
                                            canadaStates.map(state => {
                                                return (
                                                    <MenuItem key={state.code2A} value={state.code2A}>{state.name}</MenuItem>
                                                )
                                            })
                                            : formFields.country === "MX" && 
                                            mexicoStates.map(state => {
                                                return (
                                                    <MenuItem key={state.code2A} value={state.code2A}>{state.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                }
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">       
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <ValidationTextField
                                    type="text"
                                    onChange={handleChange}
                                    name='zip'
                                    label={t("Zip Code")}
                                    variant="outlined"
                                    value={formFields.zip}
                                    required={formFieldsChanged.zip} />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  autoComplete="off">
                            <FormControl className={formFields.country.length > 0 ? 'required-select-filled' : "required-select"} required style={{ width: '100%' }}>
                                <InputLabel className="bg-white" id="select-country-label">{t("Country")}</InputLabel>
                                <Select
                                    labelId="select-state-label"
                                    id="demo-simple-select-error"
                                    style={{ width: '100%', height: '50px' }}
                                    name='country'
                                    value={formFields.country ? formFields.country : "US"}
                                    label={t("Country")}
                                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                    onChange={handleChange}
                                    input={<ValidationSelectField />}
                                    required={formFieldsChanged.country}

                                >
                                    <MenuItem className="mym" value=" "><em>{t("None")}</em></MenuItem>
                                    {countries.map(country => {
                                        return (
                                            <MenuItem key={country.code3A} value={country.code2A}><FlagIcon code={country.code2A}  style={{width: "20px", height: "15px", marginRight: "10px"}}/>{country.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <div
                style={{ display: "flex", margin: "24px 0px",  width: "100%", justifyContent: "space-between", columnGap: "30px", height: "100%" }}>
                    <Suspense fallback={<Skeleton style={{ height: "400px", width: "100%", transform: "unset", display: "block" }} />}>
                        <LocationPermissions  columns={columns} setColumns={setColumns} errors={errors} setErrors={setErrors}/>
                    </Suspense>
            </div>
            <div className="save-changes-section mt-30">
                <div className='info-page-foot'>
                    <LoadingButton
                            style={{paddingLeft: loading && "30px"}}
                            className="btn add_btn blue-loading-btn"
                            onClick={() => handleSubmit()}
                            loading={loading}
                        >
                            {t("Save Changes")}
                    </LoadingButton>
                </div>
            </div>
        </div >
    )
}
export default AddUser;