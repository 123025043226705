import React, { useEffect, useState } from "react"
import ReviewItemList from "./reviewItemList"
import AssignTagModal from "./assignTagsModal"
import { clearFilters, fetchReviews, clearReviews, memorizeLastReview, paginationReviews, orderReviews, reviewTagsFilter, searchReviewKeyword, reviewRatingsFilter, loadingTables, reviewRangeFilter, reviewStatusFilter, reviewSourcesFilter } from "../../../../redux/actions/admin-index"

import { Skeleton, Alert, Pagination, IconButton, Collapse } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import Loader from "../../helpers/loader"
import { LoadingButton } from "@mui/lab"
import {IoIosCheckmark} from "react-icons/io"
import {BiUndo} from "react-icons/bi"
import AlertComponent from "../../../Helpers/Alert"
import {AiFillInfoCircle} from "react-icons/ai"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import axios from "axios"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import WhiteTooltip from "../../helpers/whiteTooltip"
import { showAlert } from "../../../../redux/actions"
import {BsSortUp,BsSortDown} from "react-icons/bs"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

const ReviewList = ({showCheckboxes, setShowCheckboxes}) => {
    const dispatch = useDispatch()
    let [pagee, setPage] = useState(1)
    const [tags, setTagModal] = useState("")
    const [t] = useTranslation()

    const [initialChecked, setInitialChecked] = useState([]);
    const [checked, setChecked] = useState([])
    const [notChecked, setNotChecked] = useState([])

    const [loadingDeselect, setLoadingDeselect] = useState(false)
    const [errors, setErrors] = useState([])

    const reviews = useSelector(state => state.getReviews.reviews)
    const loading = useSelector(state => state.getReviews.loading)

    const [searchParams, setSearchParams] = useSearchParams();
    let backTo = searchParams.get("backTo")

    const [order, setOrder] = useState("desc")

    //filter 
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)

    const keyword = useSelector(state => state.reviewKeyword.keywords)
    const sources = useSelector(state => state.reviewSourceFilter.source)
    const status = useSelector(state => state.reviewStatusFilter.status)
    const rating = useSelector(state => state.reviewRatingFilter.rating)
    const from = useSelector(state => state.reviewRangeFilter.dateFrom)
    const to = useSelector(state => state.reviewRangeFilter.dateTo)
    const page = useSelector(state => state.paginationReviews.pagination)
    const orderState = useSelector(state => state.reviewSortOrder.order)
    const tagsFilter = useSelector(state => state.reviewTagsFilter.tags)

    const lastReview = useSelector(state => state.memorizeLastReviewReducer.id)

    useEffect(() => {
        if (lastReview && backTo) {
          const lastReviewClicked = document.getElementById(lastReview);
          if (lastReviewClicked) {
            let { top } = lastReviewClicked.getBoundingClientRect();
            top -= 170
            window.scrollTo({ top });
          }
        }
      }, [lastReview, reviews]);

    useEffect(() => {
        if(!orderState){
            setOrder("desc")
        }
    }, [orderState])

    useEffect(() => {
        if(reviews){
            const initialCheckedArray = reviews.data.map(el => {
                if(el.show_on_widget == 1){
                    return el.id
                }
            });
            setInitialChecked(initialCheckedArray);
            setChecked(initialCheckedArray);
        }
    }, [reviews])

    useEffect(() => {
        setChecked(initialChecked);
    }, [showCheckboxes])

    useEffect(() => {
        if(reviews && checked && showCheckboxes){
            let firstArray = reviews.data.map(el => el.id)
            setNotChecked(firstArray.filter(el => !checked.includes(el)))
        }
    }, [reviews, checked])

    useEffect(() => {
        dispatch(loadingTables())
        dispatch(fetchReviews(location, keyword, sources, status, rating, from, to, page, tagsFilter, orderState, groupSelected))
    }, [location, keyword, sources, status, rating, to, page, tagsFilter, orderState, groupSelected])

    let pagination = reviews && reviews.meta

    const changePagination = (e, value) => {
        window.scrollTo(0, 0);
        dispatch(paginationReviews(value))
    }

    useEffect(() => {
        if(page){
          setPage(page)
        } else {
            setPage(1)
        }
    }, [page])

    useEffect(() => {
       if(backTo){
           if(!page){
               setPage(1);
            } else {
               setPage(page);
            }
       } else if(!backTo){
         dispatch(memorizeLastReview(""))
       }
    }, [backTo])

    const undoAction = () => {
        setChecked(initialChecked);
    }

    const deselectItems = () => {
        setLoadingDeselect(true)
        let data = {
            allowed: checked,
            notAllowed: notChecked
        }
        axios.post(`${baseUrlDev}reviews/widget/status`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            setInitialChecked(checked);
            setShowCheckboxes(false)
            setLoadingDeselect(false)
            dispatch(showAlert(true, "Reviews visibility changed successfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
            setChecked(data.allowed)
        })
        .catch(err => {
            setShowCheckboxes(false)
            setLoadingDeselect(false)
            setErrors(prev => [...errors, "Something went wrong"])
            setTimeout(() => {
                setErrors([])
            }, 5000)
        })

    }
    const removeMemoriezedReviewOnFilter = () => {
        dispatch(memorizeLastReview(""))
    }
    const handleOrderChange = () => {
        let value = order === "desc" ? "asc" : "desc"
        setOrder(value)
        dispatch(orderReviews(value))
        removeMemoriezedReviewOnFilter()
    }

    return (
        <>
            <AlertComponent/>
            <div className="alert-inside-page">
                {errors && errors.map(err => {
                    return(
                        <Alert key={err} severity="error" className="custom-error-alert">{err}</Alert>
                    )
                })}
            </div>
            <p style={{
                display:
                    ((keyword && keyword.length > 3) ||
                        (sources && sources.length > 0) ||
                        (tagsFilter && tagsFilter.length > 0 && tagsFilter[0] !== "all") ||
                        (status && status.length > 0) ||
                        (rating && rating.length) > 0 || from) ? "block" : "none"
            }}
                className="reset-filter-reviews">
                Showing filtered results! <span onClick={() => dispatch(clearFilters())}>Clear all filters</span>
            </p>
            <div className="review-item-list-container">
                <div className="sort-reviews-date" onClick={() => handleOrderChange()}>{order === "desc" ? <BsSortDown/> : <BsSortUp/>}{t("Sort by")}: <span>{order === "desc" ? t("Newest") : t("Oldest")}</span></div>
                {loading && <Loader absoluteFlex={true} />}
                {reviews !== undefined ?
                    reviews.data.map(rev => {
                        return (
                            <ReviewItemList 
                                checked={checked} 
                                setChecked={setChecked} 
                                showCheckboxes={showCheckboxes} 
                                review={rev} 
                                key={rev.id} 
                                setTagModal={setTagModal}
                            />
                        )
                    })
                    :
                    [...Array(6)].map((el, i) => {
                        return (
                            <Skeleton style={{ height: "200px", transform: "unset", marginBottom: "20px" }} key={`skeleton-review${i}`} />
                        )
                    })
                }
                {reviews && reviews.data.length == 0 && !loading && <h5 className="nothing-to-show">Nothing to show</h5>}
                {reviews && reviews.data.length > 0 && <Pagination page={pagee} count={pagination && pagination.last_page} variant="outlined" shape="rounded" onChange={changePagination} />}
            </div>
            <Collapse in={showCheckboxes}>
                <div className="selected-reviews-wrapper">
                    <WhiteTooltip title="UnSelected reviews won’t appear on the widget!" arrow>
                        <IconButton className="tooltip-reviews-holder">
                            <AiFillInfoCircle/>
                        </IconButton>
                    </WhiteTooltip>
                    <h5>{checked.length} Reviews selected</h5>
                    <div>
                        <LoadingButton
                            loading={loadingDeselect}
                            style={{paddingLeft: loadingDeselect && "30px"}}
                            onClick={() => deselectItems()}
                        >
                           {!loadingDeselect && <IoIosCheckmark/>} Done
                        </LoadingButton>
                        <button onClick={() => undoAction()}><BiUndo/>Undo</button>
                    </div>
                </div>
            </Collapse>
        </>
    )
}
export default ReviewList