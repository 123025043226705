import React, {useState} from "react"
import OnlyLocationWrapper from "../parts/OnlyLocationWrapper"
import SourcesTable from "./sourcesTable"
import InnerDropdownContainer from "../parts/InnerDropdownContainer"
import Header from "../../Helpers/header"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const Source = () => {
    const [open, setOpen] = useState()
    const {t} = useTranslation()
    const sources = useSelector(state => state.getLocationSources.sources)
    return(
        <OnlyLocationWrapper>
            <div className="table-header-container">
                <Header title="Review Sources" subtitle="Manage Review Sources"/>
                <div className="flex-header-actions">
                    {sources?.can_add_new &&
                        <button onClick={() => setOpen(true)}>
                            <img src={"/admin-icons/plus.svg"} alt="add"/>
                            {t("Add New Source")}
                        </button>
                    }
                </div>
            </div>
            <InnerDropdownContainer/>
            <SourcesTable open={open} setOpen={setOpen}/>
        </OnlyLocationWrapper>
    )
}
export default Source