import React from 'react';
import {AiOutlineFilePdf, AiOutlineQrcode} from "react-icons/ai"
import {BiImage} from "react-icons/bi"
import { LoadingButton } from '@mui/lab';
import html2canvas from "html2canvas";
import {SiImgur} from "react-icons/si"
import axios from 'axios';
import { baseUrlDev } from '../../../../Helpers/baseUrl';
import { getLocalUser } from '../../../../TokenControl/parts/useLocalUser';
import { useTranslation } from 'react-i18next';

const Step3 = ({ activeStep, handleBack, submitForm, loading }) => {
    const {t} = useTranslation()
    const exportImage = (type) => {
        const div = document.getElementById('qr-code-preview')
        html2canvas(div).then(canvas => {
            if(canvas){
                var link = document.createElement("a");
                link.download = `preview-qrcode.${type}`;
                link.href = canvas.toDataURL();
                link.click();
            }
        })
    }
    const exportQrCode = () => {
        const div = document.getElementById('qr-code')
        html2canvas(div).then(canvas => {
            if(canvas){
                var link = document.createElement("a");
                link.download = `qrcode.png`;
                link.href = canvas.toDataURL();
                link.click();
            }
        })
    }

    const exportImgur = () => {
        const div = document.getElementById('qr-code-preview')
        html2canvas(div).then(canvas => {
            if(canvas){
                canvas.toBlob((blob) => {
                    let formData = new FormData();
                    formData.append("type", "file");
                    formData.append("image", blob)
                    axios.post(`https://api.imgur.com/3/upload.json`, formData, {headers:{'Accept': "application/json", 'Authorization': `Client-ID dc708f3823b7756`}})
                    .then((res) => {
                        if(res.data && res.data.data){
                            window.open(res.data.data.link, "_blank");
                        } else {
                            window.open(res.data.link, "_blank");
                        }
                    })
                })
            }
        })
    }

    const exportPdf = () => {
        const div = document.getElementById('qr-code-preview')
        html2canvas(div).then(canvas => {
            if(canvas){
                let formData = new FormData();
                let image = canvas.toDataURL();
                // formData.append("type", "file");
                formData.append("image", image);
                axios.post(`${baseUrlDev}locations/qrcode/export`, formData, { responseType: 'arraybuffer', headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'qrcode.pdf');
                    document.body.appendChild(link);
                    link.click();
                })
            }
        })
    }

    return (
        <div className='form-step'>
            <div className="steps-content">
                <label htmlFor="">{t("Export type")}</label>
                <div className="type-mids-share">
                    <div className="d-flex buttons-qr-container">
                        <div>
                            <span>PNG</span>
                            <button className="btn export-type d-flex align-items-center" onClick={() => exportImage('png')}>
                                <BiImage/>PNG
                            </button>
                        </div>
                        <div>
                            <span>JPG</span>
                            <button className="btn export-type d-flex align-items-center" onClick={() => exportImage('jpg')}>
                                <BiImage/>JPG
                            </button>
                        </div>
                        <div>
                            <span>PDF</span>
                            <button className="btn export-type d-flex align-items-center" onClick={() => exportPdf()}>
                                <AiOutlineFilePdf/>PDF
                            </button>
                        </div>
                        <div>
                            <span>Imgur</span>
                            <button className='btn export-type d-flex align-items-center' onClick={() => exportImgur()}>
                                <SiImgur style={{fontSize: "16px"}} /> Imgur
                            </button>
                        </div>
                        <div>
                            <span>QrCode</span>
                            <button className="btn export-type d-flex align-items-center" onClick={() => exportQrCode()}>
                                <AiOutlineQrcode/>QrCode
                            </button>
                        </div>
                    </div>
                    <div>
                        <a className="paragraph-mids">({t("Your QR code will be generated automatically")})</a>
                    </div>
                </div>
            </div>

            <div className="btns-group d-flex justify-content-between">
                <button
                    type="button"
                    className='btn btn-prev'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    {t("Back")}
                </button>
                {/* <div className="submit-loading-btn">
                    <LoadingButton
                        style={{paddingLeft: loading && "30px"}}
                        onClick={() => submitForm()}
                        loading={loading}
                    >
                        {t("Save")}
                    </LoadingButton>
                </div> */}
            </div>
        </div>
    )
}

export default Step3;
