import React from "react"
import {useLocation} from 'react-router-dom';


const Frame = () => {
    const location = useLocation();
    return(
        <div style={{textAlign: "center"}}>
            <img 
                src={localStorage.getItem('dashboard')} 
                alt="frame" 
                style={{ width: "80%", margin: "auto"}}
            />
        </div>
    )
}

export default Frame