import { styled, InputBase} from "@mui/material";

const MyAutocomplete = styled(InputBase)(({ theme }) => ({
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-root': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#fff",
        border: `1px solid rgba(166,178,205,.25)`,
        fontSize: 16,
        padding: '6px 26px 6px 12px',
        color: "#25313C",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
    '& .MuiSvgIcon-root' :{
        color: "#25313C"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    }
}));
export default MyAutocomplete