import React, {useState} from "react"
import { Dialog } from "@mui/material"
import {IoClose} from "react-icons/io5"
import { Link } from "react-router-dom"

const FullServiceRequired = ({open, setOpen}) => {
    const handleClose = () => {
        setOpen(false)
    }
    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className="dialog-information-wrapper">
                <div>
                    <button onClick={handleClose}><IoClose/></button>
                </div>
                <div>
                    <img src="/admin-icons/dialog_mark.png" alt="dialog alert"/>
                    <h6>We're sorry, but this feature is only available for our Full Service Plan subscribers.</h6>
                    <p>Upgrade to our Full Service Plan to unlock this feature.</p>
                    <Link to="/app/admin/subscription"><img src="/admin-icons/stars.svg" alt="stars"/>Upgrade</Link> 
                </div>
            </div>
        </Dialog>
    )
}
export default FullServiceRequired