import { Grid } from "@mui/material";
import AddPlatformBox from "./AddPlatformBox";
import { useTranslation } from "react-i18next";

const Workspaces = ({ data, redirectLink }) => {
    const {t} = useTranslation()
    return (
        <>
            <Grid container spacing={2}>
                {
                    data?.workspaces?.map((workspace, index) => <Grid item xs={12} md={6} xl={3} key={index}>
                        <a className="workspace-item" href={workspace?.workspace_redirect}>
                            <div className="workspace-box">
                                <div className="workspace-header">
                                    <h5>{workspace?.workspace_name}</h5>
                                </div>
                                <div className="workspace-body">
                                    <p>{t("Workspace")}</p>
                                    <span className='date'>{workspace?.created_at}</span>
                                </div>
                            </div>
                        </a>
                    </Grid>)
                }
                <Grid item xs={12} md={6} xl={3}>
                    <AddPlatformBox redirectLink={data?.platform?.workspace_create} />
                </Grid>
            </Grid>
        </>
    )
}

export default Workspaces;
