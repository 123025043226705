import React, {useMemo, useState, useEffect} from "react"
import LocationItem from "./location_item";
import EditCardSubscription from "./EditCartSubscription";
import { useTranslation } from "react-i18next";

const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const SubscriptionLocations = ({checked, setChecked, subscriptions, sub, aLaCarteItems}) => {
    const {t} = useTranslation()
    const [subLocations, setSubLocations] = useState()
    const [open, setOpen] = useState(false)
    const [details, setDetails] = useState({card_number: ""})
    const [subscriptionId, setSubscriptionId] = useState()
    const [hash_id, setHashId] = useState()
    const [is_primary, setIsPrimary] = useState()
    const [loc_id, setLocId] = useState()
    const [search, setSearch] = useState("")

    useEffect(() => {
        if(subscriptions){
            setSubLocations(subscriptions)
        }
    }, [subscriptions])

    const updateCardNumberInLocation = (newCardNumber) => {
      
        // Update the card number in the specific location
        const updatedSubscriptions = subLocations.map((location) => {

          if (location.id == loc_id) {
            return { ...location, card: newCardNumber.split(' ')[3] };
          }
          return location;
        });
    
        // Update the state with the modified subscriptions
        setSubLocations(updatedSubscriptions);
      };

    const displayedLocations = useMemo(() => subLocations && subLocations.filter(location => containsText(location.irevu_companyName, search)), [search, subLocations]);

    return(
        <div className="sub_container">
            <EditCardSubscription 
                open={open} 
                setOpen={setOpen} 
                details={details} 
                subscriptionId={subscriptionId} 
                hash_id={hash_id}
                is_primary={is_primary} 
                updateCardNumberInLocation={updateCardNumberInLocation}
            />
            <h4>{t("Choose your plan")}</h4>
            <span>{t("Upgrade-sub")}.</span>
            <div className="sub_container_div">
                <div className="sub_search_container">
                    <input type="text" value={search} placeholder={t("Search Location")} onChange={(e) => setSearch(e.target.value)}/>
                </div>
                <div className="sub_locations_results custom-scrollbar-vertical">
                    <ul>
                        {displayedLocations?.map(loc => {
                            return(
                                <LocationItem 
                                    loc={loc} 
                                    sub={sub} 
                                    checked={checked} 
                                    setChecked={setChecked}
                                    open={open} 
                                    setOpen={setOpen} 
                                    details={details} 
                                    setDetails={setDetails}
                                    setSubscriptionId={setSubscriptionId}
                                    aLaCarteItems={aLaCarteItems}
                                    setIsPrimary={setIsPrimary}
                                    setLocId={setLocId}
                                    setHashId={setHashId}
                                />
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionLocations