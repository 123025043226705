import React, {Suspense} from "react"
import { Skeleton, IconButton } from "@mui/material"
import LightTooltip from "../../helpers/lightTooltip"
import {IoMdInformationCircleOutline} from "react-icons/io"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const AverageRatingChart = React.lazy(() => import("./average_rating_chart"))

const AverageRatings = ({averageRatings}) => {
    const [t] = useTranslation()
    const language = useSelector(state => state.getLanguage.language)

    return(
        <div className="dashboard-box-containers" style={{paddingRight: "10px"}}>
           <LightTooltip title={t("Reviews and average ratings for your business")}
           >
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                </IconButton>
            </LightTooltip>
            <h2>{t("Reviews And Average Ratings")}</h2>
                <Suspense fallback={
                    <div>
                        <Skeleton style={{transform: "unset", width: "95%", height: "400px", margin: "auto"}}/>
                    </div>
                }>
                <AverageRatingChart averageRatings={averageRatings} language={language}/>
            </Suspense>
        </div>
    )
}
export default AverageRatings