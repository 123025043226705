import React, {useEffect, useState, useMemo, useRef} from "react"
import {useSelector, useDispatch} from "react-redux"
import { Grid, Skeleton, LinearProgress } from "@mui/material"
import "../../../../assets/admin.css"
import { fetchDashboardStats, fetchDashboardCharts,showYoutubeContent,clearDashboardStats, fetchDashboardVisits } from "../../../../redux/actions/admin-index"
import { getAccreditationDetails } from "../../../../redux/actions"
import DashboardSkeleton from "../../helpers/dashboardSkeleton"
import DashboardBoxes from "./dashboard_boxes"
import RatingByBreakDown from "./breakdown"
import Analytics from "./analytics"
import TopReviewSources from "./review_sources"
import LinkVisited from "./link_visited"
import AverageRatings from "./average_ratings"
import SourcesWidget from "./sources_widgets"
import RightSideHeader from "./RightSideHeader"
import Phrases from "./phrases"
import TrendingKeywords from "./trending_keywords"
import SetupSuggestions from "./SetupSuggestions"
import WelcomeSection from "./WelcomeSection"
import { useTranslation } from "react-i18next"
import ReviewCollectionContainer from "./reviewCollectionContainer"
import AccreditatedCompany from "./accreditated-company_dashboard"
import DashboardProgress from "./dashboard_progress"
import ExportAlert from "../../modals/exportedAlert"
import AverageRatingsCompare from "./average_raiting_compare"

const LinkVisitedMemoized = React.memo(({ linkVisits, linkVisitsNum, linkVisitsDays, linkVisitPhrases }) => {
    return (
        <LinkVisited linkVisits={linkVisits} linkVisitsNum={linkVisitsNum} linkVisitsDays={linkVisitsDays} linkVisitPhrases={linkVisitPhrases} />
    );
});

const Admin = () => {
    const { t } = useTranslation();
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const dispatch = useDispatch()
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const dashboard = useSelector(state => state.getDashboardStats.dashboard)
    const dashboard_visits = useSelector(state => state.getDashboardStatsVisitsReducer.dashboard_visits)
    const dashboard_charts = useSelector(state => state.getDashboardChartsReducer.dashboard_charts)
    const user = useSelector(state => state.getUserInfo.user)
 
    let location_details = user && user.user.locations.filter(loc => loc.id == location)

    useEffect(() => {
        dispatch(fetchDashboardStats(from, to, location,  groupSelected))
        return () => dispatch(clearDashboardStats());
    }, [location, groupSelected])

    useEffect(() => {
        if(dashboard){
            dispatch(fetchDashboardVisits(from, to, location,  groupSelected))
        }
    }, [dashboard])

    useEffect(() => {
        if(dashboard_visits){
            dispatch(fetchDashboardCharts(from, to, location,  groupSelected))
        }
    }, [dashboard_visits])

    useEffect(() => {
       if(location_details && location_details[0]?.placeid){
            dispatch(getAccreditationDetails(location_details && location_details[0]?.placeid))
       }
    }, [location, user])


    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/b_QT2UqjsTM?autoplay=1&mute=0&rel=0'))
    }, [])


    return(
        <div>
            <ExportAlert/>
            <div className="dashboard-container-header">
                <div className="flex-header-container-title">
                    <h1>{t('Dashboard')}</h1>
                    <h6 style={{display: "flex", alignItems: "center"}}>{t('Welcome Back')}, {user !== undefined ? user.user.first_name : <Skeleton style={{width: "80px", height: "30px", marginLeft: "10px"}}/>} </h6>
                </div>
                <RightSideHeader setFrom={setFrom} setTo={setTo} fromText={from} toText={to} />
            </div>
            {dashboard === undefined ?  
            <>
                <DashboardProgress/>
                <DashboardSkeleton/>
            </>
            : 
            <div id="dashboard-snapshot">
                <div className="mt-20">
                    <SetupSuggestions data={dashboard?.data} location={location} />
                </div>
                {location !== "all" && (dashboard.data.onlyAccreditation != 1) &&
                    <AccreditatedCompany showMsg={dashboard?.data.newLocationMessage.show} collapsed={dashboard?.data.accreditationBoxDisplay}/>
                }
                <DashboardBoxes reviews={dashboard.data.reviews} rating={dashboard.data.rating} feedback={dashboard.data.feedback} linkVisits={dashboard_visits?.totalVisits} sub={dashboard.data.canUpgradeSubscription}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <RatingByBreakDown breakdown={dashboard.data.ratingBreakdown} facebookRecommendations={dashboard.data.facebookRecommendations} connected={dashboard.data.connected}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Analytics analytics={dashboard.data.analytics}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <TopReviewSources sources={dashboard.data.topReviewSources}/>
                    </Grid>
        
                    {/* <Grid item xs={12} md={6} xl={3}>
                        <Analytics analytics={dashboard.data.analytics}/>
                    </Grid> */}
                </Grid>
                <Grid container spacing={2} className="box-mt">
                    <Grid item xs={12} md={6} xl={6}>
                            {/* <LinkVisited linkVisits={dashboard.data.linkVisits} linkVisitsNum={dashboard.data.totalVisits} linkVisitsDays={dashboard.data.linkVisits30Days} linkVisitPhrases={dashboard.data.linkVisitPhrases}/> */}
                            {dashboard_visits ?
                                <LinkVisitedMemoized
                                    linkVisits={dashboard_visits.linkVisits}
                                    linkVisitsNum={dashboard_visits.totalVisits}
                                    linkVisitsDays={dashboard_visits.linkVisits30Days}
                                    linkVisitPhrases={dashboard_visits.linkVisitPhrases}
                                />
                                : <Skeleton style={{transform: "unset", height: "400px"}}/>
                            }
                        </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                        <ReviewCollectionContainer/>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="box-mt">
                    <Grid item xs={12} md={12} xl={12}>
                        {dashboard_charts ? <AverageRatings averageRatings={dashboard_charts.average_ratings}/> : <Skeleton style={{transform: "unset", height: "400px"}}/>}
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="box-mt">
                    <Grid item xs={12} md={12} xl={12}>
                        {dashboard_charts ? <AverageRatingsCompare averageRatings={dashboard_charts.average_ratings_within_2_years}/> : <Skeleton style={{transform: "unset", height: "400px"}}/>}
                    </Grid>
                </Grid>
                <SourcesWidget sourcesWidget={dashboard_visits?.sourcesWidget}/>
                <Grid container spacing={2}>
                    {dashboard.data.positiveNegativePhrases &&
                        <Grid item xs={12}  lg={6}>
                            <Phrases phrases={dashboard.data.positiveNegativePhrases}/>
                        </Grid>
                    } 
                    {dashboard.data.positiveNegativePhrases &&
                        <Grid item xs={12}  lg={6}>
                            <TrendingKeywords keywords={dashboard.data.positiveNegativePhrases}/>
                        </Grid>
                    }
                </Grid>
            </div>
            }
        </div>
    )
}
export default Admin