import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { fetchSubscriptions, clearSubscriptions, fetchSubscriptionValues, clearSubscriptionValues } from "../../../../redux/actions/admin-index"
import { SubscriptionStepper, SubscriptionStepIcon } from "./subscription-stepper"
import { Stepper, Step, StepLabel, Grid, Skeleton, Collapse, Alert, Select, FormControl, MenuItem} from "@mui/material"
import SubscriptionLocations from "./locations"
import SubscriptionServices from "./services"
import SubscriptionQuestion from "./subscriptions_question"
import CardList from "./cardList"
import CartDetails from "./cart_details"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import axios from "axios"
import { LoadingButton } from "@mui/lab"
import { HiCheckCircle, HiExclamationCircle } from "react-icons/hi"
import UnSubscribe from "./unSubscribe"
import AlertComponent from "../../../Helpers/Alert";
import useHandleDisable from "../../helpers/disableSelect"
import { useSearchParams } from "react-router-dom"
import SubscriptionContract from "./SubscriptionContract"
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next"
import MySelect from "../../../Helpers/MySelect"
import OnlyOwner from "../../OnlyOwner"


const Subscription = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleDisable = useHandleDisable()
    const [searchParams] = useSearchParams()
    const steps = ['Plan Selection', 'Payment']; //steps
    const [isChecked, setIsChecked] = useState(false);
    const user = useSelector(state => state.getUserInfo.user)

    const [activeAccordion, setActiveAccordion] = useState("cards") // last step => accordion active
    const [activeStep, setActiveStep] = useState(0);  // step active
    const [subTotal, setSubTotal] = useState() // subtotal of sub
    const [discount, setDiscount] = useState(0) // discount of sub
    const [total, setTotal] = useState() // total of sub
    const [periodDiscount, setPeriodDiscount] = useState(0)
    const [discountPlan, setDiscountPlan] = useState() // discount plan based on plan selected
    const [service_locations, setServiceLocations] = useState([]) // array to hold locations based on which plan we are. If full we concat arrays

    const [plan, setPlan] = useState() // plan selected
    const [aLaCarte, setAlaCarte] = useState(["1", "2","3", "4", "5", "6"])
    const [card_id, setCardId] = useState() // card_id if we use existing card
    const [checked, setChecked] = useState([]) // array of locations selected
    const [period, setPeriod] = useState("1")
    const [service, setService] = useState(" ") //service => hold subscription_id

    const [loading, setLoading] = useState(false) // show loading on submit
    const [errors, setErrors] = useState([])   // show Errors if we have any
    const [succesful, setSuccesful] = useState(true) // last step determine if we have success subscription or no 

    const [openModal, setOpenModal] = useState(false);
    const [prorate, setProrate] = useState(0)
    const {t} = useTranslation()
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const [locked, setLocked] = useState({
        self: false,
        full: false,
        ai: false,
        aLaCarte: false,
        subscribed: ""
    })

    const [cartDetails, setCartDetails] = useState({
        name: "",
        email: "",
        card_number: "",
        expiry: "",
        cvc: "",
        billing_zip: ""
    })

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const subscriptions = useSelector(state => state.subscriptionsReducer.subscriptions);
    const subscriptionsData = useSelector(state => state.getSubscriptionValues.subscription_values);

    //Fetching Subscriptions
    useEffect(() => {
        dispatch(clearSubscriptions())
        dispatch(fetchSubscriptions())
        handleDisable()
    }, [])

    useEffect(() => {
        let planName = subscriptions && subscriptions.plans.filter(pl => pl.plan_id === plan)
        dispatch(clearSubscriptionValues())
        let locations = checked.map(el => el.id)
        if(checked && plan && period){
            dispatch(fetchSubscriptionValues(locations, plan, period, aLaCarte))
        }
        if(checked.length == 0){
            setSubTotal(0)
            setDiscount(0)
            setTotal(0)
            setPeriodDiscount(0)
        }
        if(planName && planName[0]?.name == "À La Carte"){
            setPeriod("1")
        }
    }, [checked, plan, period, aLaCarte])

    useEffect(() => {
        if (subscriptions) {
            if (searchParams.get("ai")) {
                let findAiPlan = subscriptions.plans.filter(pl => pl.name == "AI Service")
                setPlan(findAiPlan[0].plan_id)
            } else {
                setPlan("")
            }
        }
    }, [searchParams, subscriptions])

    useEffect(() => {
        if(subscriptionsData){
            setSubTotal(subscriptionsData?.subTotal)
            setDiscount(subscriptionsData?.discount)
            setTotal(subscriptionsData?.totalAmount)
            setPeriodDiscount(subscriptionsData?.periodDiscount)
            setProrate(subscriptionsData?.prorate)
        }
    }, [subscriptionsData])


    useEffect(() => {
        //On subscriptions fetch set plan  to first plan
        if (subscriptions && subscriptions.plans) {

            //Format not_paid array to add new obj prop type
            let notPaidArrayNewItem = subscriptions.locations.all && subscriptions.locations.all.length > 0 ?
                subscriptions.locations.all.filter(el => el.plan == 0).map(el => {
                    return { ...el, type: "Demo" }
                }) : subscriptions.locations.all

            //Concat two arrays
            let array = subscriptions.locations.all && subscriptions.locations.all.length > 0 ? subscriptions.locations.all.map(el => {
                if(el.plan == 0){
                    return { ...el, type: "Demo" }
                } else if (el.plan == 1){
                    return { ...el, type: "Self Service" }
                } else if (el.plan == 2){
                    return { ...el, type: "Full Service" }
                } else if (el.plan == 3){
                    return { ...el, type: "AI Service" }
                } else {
                    return { ...el, type: "À La Carte" }
                }
            }) : subscriptions.locations.all
            setServiceLocations(array)
            setChecked(notPaidArrayNewItem)
        }



        //If no card avaliable make addCard accorions Active
        if ((subscriptions && subscriptions.cards && !subscriptions.cards.data == 0) || (subscriptions && subscriptions.cards && subscriptions.cards.data && subscriptions.cards.data.length == 0)) {
            setActiveAccordion("addCard")
        }

    }, [subscriptions])


    //Change Step
    const handleStep = (step) => {
        setActiveStep(step);
    };


    //On Step change if self service plan is selected but we dont have self service subscriptions , skip step 2 
    //On step change if full service plan is selected but we dont have full service subscriptions, skip step 2
    //Prevent step change from 1 to (2 or 3) if no location is selected
    const checkForSubs = () => {

        let fullS = subscriptions && subscriptions.locations.all && subscriptions.locations.all.filter(el => el.plan == 2)
        let selfS = subscriptions && subscriptions.locations.all && subscriptions.locations.all.filter(el => el.plan == 1)
        let ai = subscriptions && subscriptions.locations.all && subscriptions.locations.all.filter(el => el.plan == 3)
        let aLaCarteS = subscriptions && subscriptions.locations.all && subscriptions.locations.all.filter(el => el.plan == 4)

        let full = subscriptions && subscriptions.plans.filter(pl => pl.plan_id === plan)
        let selfService = selfS.length > 0
        let fullService = fullS.length > 0
        let aiService = ai.length > 0
        let aLaCarteService = aLaCarteS.length > 0

        if(!plan){
            let locError = ["Please select plan"]
            setErrors(locError)
            setTimeout(() => {
                setErrors([])
            }, 3000)
            return false
        }

        if (isChecked) {
            if (checked.length > 0 &&  full[0] && full[0].name === "Self Service" && !locked.self) {
                selfService ?  handleStep(1) :  handleStep(2)
            } else if (checked.length > 0 && full[0] && full[0].name === "Full Service") {
                fullService ?  handleStep(1) :  handleStep(2)
            }else if (checked.length > 0 && full[0] && full[0].name === "AI Service") {
                aiService ?  handleStep(1) :  handleStep(2)
            } else if (checked.length > 0 && full[0] && full[0].name === "À La Carte" && aLaCarte.length > 0 && !aLaCarte.every(el => el == "4")) {
                aLaCarteService ?  handleStep(1) :  handleStep(2)
            } else if (checked.length > 0 && full[0] && full[0].name === "Self Service" && locked.self) {
                let locError = [t("already_subscribed_location")]
                setErrors(locError)
                setTimeout(() => {
                    setErrors([])
                }, 3000)
            } else if (full && full[0]?.name === "À La Carte" && aLaCarte.length == 0){
                let locError = [t("aLaCarte-alert")]
                setErrors(locError)
                setTimeout(() => {
                    setErrors([])
                }, 3000)
            } else if (full && full[0]?.name === "À La Carte" && aLaCarte.every(el => el == "4")){
                let locError = [t("aLaCarte-alert")]
                setErrors(locError)
                setTimeout(() => {
                    setErrors([])
                }, 3000)
            } else {
                let locError = [t("Location Select Required")]
                setErrors(locError)
                setTimeout(() => {
                    setErrors([])
                }, 3000)
            }
        } else {
            let locError = [t("checkbox-alert")]
            setErrors(locError)
            setTimeout(() => {
                setErrors([])
            }, 3000)
        }
    }

    //Submit subscriptions
    //We have a conidition on request to know if user is using an existing card or is making the subscription with new card.

    const finishSubscription = () => {
        let full = subscriptions && subscriptions.plans.filter(pl => pl.plan_id === plan)
        let ifSelfPlanLocations = checked.filter(loc => (loc.type === "Demo" || loc.type === "À La Carte" )).map(el => el.id)

        let data = { 
            plan_id: plan, 
            locations: full && full[0] && full[0].name === "Self Service" ? ifSelfPlanLocations : checked.map(el => el.id), 
            subscription_id: service, 
            card_id: card_id,  
            laCarte_amount: aLaCarte,
            totalAmount: total,
            prorate: prorate,
            period: period
        } 
        let dataNew = {
            plan_id: plan,
            laCarte_amount: aLaCarte,
            locations: full && full[0] && full[0].name === "Self Service" ? ifSelfPlanLocations : checked.map(el => el.id),
            subscription_id: service,
            name_on_card: cartDetails.name,
            card_number: cartDetails.card_number.replace(/\s/g, ""),
            expiration_month: cartDetails.expiry.split("/")[0],
            expiration_year: cartDetails.expiry.split("/")[1],
            cvv: cartDetails.cvc,
            zip_code: cartDetails.billing_zip,
            totalAmount: total,
            prorate: prorate,
            period: period
        }
        if (card_id) {
            setLoading(true)
            axios.post(`${baseUrlDev}subscription/new`, data, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then((res) => {
                setLoading(false)
                if (res.data.status) {
                    setSuccesful(true)
                    setActiveStep(3)
                    setTimeout(() => {
                        navigate("/app/admin")
                    }, 3000)
                } else {
                    setSuccesful(false)
                    setActiveStep(3)
                }
            })
                .catch(() => {
                    setLoading(false)
                })

        } else {
            const values = Object.values(cartDetails);
            const isAnyValueFilled = values.some((value) => value !== "" && value !== null);

            if (subscriptions.cards.data && subscriptions.cards.data.length > 0 && !isAnyValueFilled) {
                setErrors(["Use an existing Card Or Create New One"])
            } else {
                Object.keys(cartDetails).map((i) => {
                    if (!cartDetails[i]) {
                        if (i === "billing_zip") {
                            setErrors(prev => [...prev, "Zip is required"])
                        } else if (i === "card_number") {
                            setErrors(prev => [...prev, "Card Number is required"])

                        } else {
                            setErrors(prev => [...prev, `${i} is required`])
                        }
                    }
                })
            }
            setTimeout(() => {
                setErrors([])
            }, 3000)

            if (dataNew.name_on_card && dataNew.card_number && dataNew.expiration_month && dataNew.expiration_year && dataNew.cvv && dataNew.zip_code) {
                setLoading(true)
                axios.post(`${baseUrlDev}subscription/new`, dataNew, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then((res) => {
                    setLoading(false)
                    if (res.data.status) {
                        setSuccesful(true)
                        setActiveStep(3)
                        setTimeout(() => {
                            navigate("/app/admin")
                        }, 3000)
                    } else {
                        setSuccesful(false)
                        setActiveStep(3)
                    }
                })
                    .catch(() => {
                        setLoading(false)
                    })
            }
        }
    }
    let planName =  subscriptions && subscriptions.plans.filter(pl => pl.plan_id === plan)
    if(user && user.user && user.user.locations.length > 0 && user.user.locations.every(loc => loc.is_owner == false)) {
        return <OnlyOwner/>
    }
    return (
        <div>

            <SubscriptionContract 
                open={openModal} 
                setOpen={setOpenModal} 
                handleOpen={handleOpen} 
                handleClose={handleClose} 
                total={total}
                plan={plan}
                period={period}
            />

            <div className="alert-inside-page">
                {errors && errors.map(err => {
                    return (
                        <Alert key={err} severity="error" className="custom-error-alert">{err}</Alert>
                    )
                })}
            </div>
            <Stepper alternativeLabel activeStep={activeStep} connector={<SubscriptionStepper />} style={{ width: "50%", margin: "auto" }}>
                {steps.map((label, index) => {
                    return (
                        <Step key={label} onClick={() => {
                            if (index == 1) {
                                checkForSubs()
                            } else {
                                handleStep(index)
                            }
                        }}>
                            <StepLabel StepIconComponent={SubscriptionStepIcon}>{t(label)}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            {subscriptions === undefined ?
                <div className="stepper-body-sub">
                    <Grid container spacing={4} >
                        <Grid item xs={12} lg={6}>
                            <Skeleton style={{ transform: "unset", height: "400px", width: "100%" }} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Skeleton style={{ transform: "unset", height: "400px", width: "100%" }} />
                        </Grid>
                    </Grid>
                </div>
                :
                <div className="stepper-body-sub">
                    {activeStep === 0 ?
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                                <SubscriptionLocations 
                                    checked={checked} 
                                    setChecked={setChecked} 
                                    subscriptions={service_locations} 
                                    sub={subscriptions.subscriptions} 
                                    aLaCarteItems={subscriptions.laCarteItems} 
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <SubscriptionServices 
                                    locked={locked} 
                                    setLocked={setLocked} 
                                    checked={checked} 
                                    plan={plan} 
                                    setPlan={setPlan} 
                                    plans={subscriptions.plans} 
                                    aLaCarte={aLaCarte} 
                                    setAlaCarte={setAlaCarte} 
                                    aLaCarteItems={subscriptions.laCarteItems} 
                                    aLaCarteAmount={subscriptionsData?.laCarteAmount}
                                />
                                <FormControl className="period-wrapper" style={planName && planName[0]?.name === "À La Carte" ? {minWidth: "200px", pointerEvents: "none", opacity: 0.5} : {minWidth: "200px"}}>
                                    <label className="sub-period-wrapper">{("Subscription Period")}</label>
                                    <Select
                                        value={period}
                                        onChange={(e) => setPeriod(e.target.value)}
                                        input={<MySelect/>}
                                    >
                                        <MenuItem value={"1"}>1 {t("Year")}</MenuItem>
                                        <MenuItem value={"2"}>2 {t("Years")} (5% OFF)</MenuItem>
                                        <MenuItem value={"3"}>3 {t("Years")} (10% OFF)</MenuItem>
                                    </Select>
                                </FormControl>
                                <div className="sub-bottom-right">
                                    {(discount || periodDiscount) && ((discount > 0 || periodDiscount > 0) && subTotal > 0) ?
                                        <div className="sub_total_div">
                                            <h4>{t("Sub Total")}</h4>
                                            <span>{subscriptionsData === undefined ? <Skeleton style={{transform: "unset", width: "50px", height: "15px"}}/> : `$${parseInt(subTotal) > 0 ? total : "0"}`}</span>
                                        </div>
                                        : ''}
                                    {discount && (discount > 0 && subTotal > 0) ?
                                        <div className="discount_div">
                                            <h4>{t("Discount")}</h4>
                                            <span>{subscriptionsData === undefined ? <Skeleton style={{transform: "unset", width: "50px", height: "15px"}}/> : `$${discount}`}</span>
                                        </div>
                                        : ''}
                                    {/* {(period !== 1 && periodDiscount) ?
                                    <div className="discount_div">
                                        <h4>{t("Period Discount")}</h4>
                                        <span>{subscriptionsData === undefined ? <Skeleton style={{transform: "unset", width: "50px", height: "15px"}}/> : `$${periodDiscount}`}</span>
                                    </div>
                                    : ''} */}
                                    <div className="total_div">
                                        <h4>{t("Total Amount")}</h4>
                                        <span>{subscriptionsData === undefined ? <Skeleton style={{transform: "unset", width: "50px", height: "15px"}}/> : `$${parseInt(total) > 0 ? total : "0"}`}</span>
                                    </div>
                                    <div className="contract_wrapper">
                                        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                                        <p className="des_text">{t("By subscribing here, you agree with")} <button onClick={handleOpen}>{t("Terms of the Contract")}</button></p>
                                    </div>
                                    {subscriptionsData === undefined && plan ? 
                                        <button className="blue-btn-sub" style={{cursor: "initial"}}>
                                            <div className="dot-flashing"></div>
                                        </button>
                                    : 
                                        <button className="blue-btn-sub" onClick={checkForSubs}>
                                            {t("Upgrade")}
                                        </button>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        : activeStep === 1 ?
                            <SubscriptionQuestion
                                handleStep={handleStep}
                                subscriptions={subscriptions.subscriptions}
                                service={service}
                                plan={plan}
                                plans={subscriptions.plans}
                                setService={setService}
                            />
                            : activeStep === 2 ?
                                <div className="accordion-container">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4} xl={3}>
                                            <div className="accordion-container-items">
                                                {subscriptions.cards && subscriptions.cards.data && subscriptions.cards.data.length > 0 &&
                                                    <button className={activeAccordion === "cards" ? "active" : ""} onClick={() => setActiveAccordion("cards")}>{t("Use existing Card")}</button>
                                                }
                                                {/* {(!service || service.length == 1) && 
                                                <button className={activeAccordion === "addCard" ? "active" : ""} onClick={() => {
                                                    setActiveAccordion("addCard")
                                                    setCardId("")
                                                }}>Add New Card</button>
                                            } */}
                                                <button className={activeAccordion === "addCard" ? "active" : ""} onClick={() => {
                                                    setActiveAccordion("addCard")
                                                    setCardId("")
                                                }}>{t("Add new Card")}</button>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={8} xl={9}>
                                            <div>
                                                <Collapse in={activeAccordion === "cards"}>
                                                    <div>
                                                        <CardList cards={subscriptions.cards} total={total} card_id={card_id} setCardId={setCardId} />
                                                    </div>
                                                </Collapse>
                                                <Collapse in={activeAccordion === "addCard"}>
                                                    <div>
                                                        <CartDetails plan={plan} cartDetails={cartDetails} setCartDetails={setCartDetails} total={total} cards={subscriptions.cards} />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div className="info-page-foot d-flex justify-content-end">
                                        <LoadingButton
                                            style={{ paddingLeft: loading && "30px" }}
                                            loading={loading}
                                            onClick={() => finishSubscription()}
                                        >
                                            Finish
                                        </LoadingButton>
                                    </div>
                                </div>
                                :
                                <div className="last-step-subscriptions">
                                    {succesful ?
                                        <div className="success-step-subscriptions">
                                            <HiCheckCircle />
                                            <h5>Payment completed Successfully.</h5>
                                            <span>Subscription successful! Redirecting you to your dashboard...</span>
                                        </div>
                                        :
                                        <div className="failed-step-subscriptions">
                                            <HiExclamationCircle />
                                            <h5>Payment unsuccessful!</h5>
                                            <span>There was an issue processing your subscription. Please try again later or contact our customer support team for assistance.</span>
                                        </div>
                                    }
                                </div>
                    }
                </div>
            }
        </div>
    )
}
export default Subscription