import React, { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"
import { fetchFormClient, showAlert, thankYouMessage } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Box, Fade, ClickAwayListener, Alert, Grow } from "@mui/material";
import { Container } from "@mui/system"
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import axios from "axios";
import { baseUrl } from "../../Helpers/baseUrl";
import { useNavigate } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai"
import useQueryParamsHook from "../../Survey/parts/useQueryParamsHook";
import { useSearchParams } from "react-router-dom";
import Loader from "../../Admin/helpers/loader";
import { VscCalendar } from "react-icons/vsc"
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import BarcodeReaderComponent from "../../Helpers/Scaner2";
import Rating from "./Rating";
import ThankYouMsg from "../../Helpers/ThankYouMsg";
import CustomAlertIcon from "../../Admin/helpers/CustomAlertIcon";
let ireview = "https://irevu.com/assets/images/cover-irevu.png"
let avg = []

const Form = () => {
    let { id } = useParams();
    const optionSelected = useRef();
    const bottomRef = useRef(null);
    const inputRef = useRef(null)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [scan, showScan] = useState(false)
    const [fieldsAsReview, setFieldsAsReview] = useState([])
    const [bardcodeValue, setBarcodeValue] = useState('')
    const [year, setYear] = useState(new Date().getFullYear())
    const [day, setdDay] = useState(('0' + new Date().getDate()).slice(-2))
    const [month, setMonth] = useState(('0' + (new Date().getMonth() + 1)).slice(-2))
    const [cValue, setcValue] = useState(new Date(`${month} ${day} ${year}`))
    const [showCalendar, setShowCalendar] = useState(false)

    const [selectedLoc, setSelectedLoc] = useState(searchParams.get("location") ? searchParams.get("location") : '0')
    const [google, setGoogle] = useState('')
    const [yelp, setYelp] = useState('')
    const [facebook, setFacebook] = useState('')
    const [date, setDate] = useState(`${year}-${month}-${day}`)
    const { register, getValues } = useForm()
    const [rating, setRating] = useState(0)
    const [ratingAvg, setRatingAvg] = useState(0)
    const [question, setQuestion] = useState('')
    const [description, setDescription] = useState('')
    const [hover, setHover] = useState(0);
    const [loading, setLoading] = useState(false)
    const querySerachParams = useQueryParamsHook();
    const [showAlert, setShowAlert] = useState(false);
    const [openThankYouAlert, setOpenThankYouAlert] = useState(false);

    useEffect(() => {
        dispatch(fetchFormClient(id))
    }, [])

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [showCalendar])

    useEffect(() => {
        inputRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [scan])

    const form = useSelector(state => state.FormClient.form);

    useEffect(() => {
        let option = optionSelected.current && optionSelected.current[optionSelected.current.selectedIndex]
        setGoogle(option && option.getAttribute("google"))
        setYelp(option && option.getAttribute("yelp"))
    }, [form])

    const handleAvg = (index, name) => {
        if (avg.length > 0) {
            let checkKey = avg.some(obj => obj.name === name)
            if (checkKey) {
                let ind = avg.findIndex(object => {
                    return object.name === name
                })
                if (ind !== -1) {
                    avg[ind].index = index
                }
            } else {
                avg.push({ name, index })
            }
        } else {
            avg.push({ name, index })
        }
        findAverage()
    }

    const findAverage = () => {
        let averageFound = 0
        avg.map(avg => {
            averageFound = averageFound + parseInt(avg.index)
        })
        setRatingAvg((averageFound) / (avg.length))
    }
    const handleChange = (e) => {
        setRating(e.target.value)
        if (e.target.value === 5) {
            setQuestion('')
        } else if (e.target.value === 4) {
            setQuestion("We strive for 5's here at WOW CARWASH.  How can we earn a big fat 5 next time?")
        } else {
            setQuestion("What could we have done better?")
        }
    }

    const handleLocation = (e) => {
        setGoogle(e.target[e.target.selectedIndex].getAttribute('google'))
        setYelp(e.target[e.target.selectedIndex].getAttribute('yelp'))
        setFacebook(e.target[e.target.selectedIndex].getAttribute('facebook'))
        setSelectedLoc(e.target.value)
    }
    const onDetected = result => {
        setBarcodeValue(result);
    };
    const changeDate = (type, e) => {
        if (type === "day") {
            if (e.target.value > 31) {
                setdDay(1)
            } else {
                setdDay(e.target.value)
            }
        } else if (type === "month") {
            if (e.target.value > 12) {
                setMonth(1)
            } else {
                setMonth(e.target.value)
            }
        } else if (type === "year") {
            if (e.target.value.length > 4) {
                setYear(new Date().getFullYear())
            } else {
                setYear(e.target.value)
            }
        }
    }
    useEffect(() => {
        setcValue(new Date(`${month} ${day} ${year}`))
        setDate(`${year}-${month}-${day}`)
    }, [day, month, year])

    const getCalendarVal = (value) => {
        setYear(value.getFullYear())
        setMonth(('0' + (value.getMonth() + 1)).slice(-2))
        setdDay(('0' + value.getDate()).slice(-2))
        setShowCalendar(false)
    }
    const afterSubmitDefault = () => {
        if (form && form.form && form.form.submission_action === "thank you") {
            dispatch(thankYouMessage(form && form.form && form.form.message))
            setOpenThankYouAlert(true)
            // navigate("/thank-you")
        } else  {
            window.location.href = form.form.form_redirect_link
        }
    }

    const afterSubmit = (rate) => {
        let ratingInputObject
        if (form && form.form && form.form.formBuilderInputs) {
            ratingInputObject = form.form.formBuilderInputs.filter(inp => inp.field_type === "rating")
        }
        if (ratingInputObject && ratingInputObject[0] && ratingInputObject[0].redirect_link) {
            if (ratingInputObject[0].redirect_link[rate].type !== "yelp-reviews" && ratingInputObject[0].redirect_link[rate].type !== "google-reviews") {
                if (ratingInputObject[0].redirect_link[rate] && ratingInputObject[0].redirect_link[rate].type === "redirect") {
                    ratingInputObject[0].redirect_link[rate].input ? window.location.href = ratingInputObject[0].redirect_link[rate].input : afterSubmitDefault()
                } else if (ratingInputObject[0].redirect_link[rate] && ratingInputObject[0].redirect_link[rate].type === "thank you") {
                    dispatch(thankYouMessage(ratingInputObject[0].redirect_link[rate].input))
                    setOpenThankYouAlert(true);
                    // navigate("/thank-you")
                } 
            }
            else if (!ratingInputObject[0].redirect_link[rate].input && (ratingInputObject[0].redirect_link[rate].type === "yelp-reviews" || ratingInputObject[0].redirect_link[rate].type === "google-reviews")) {
                if (ratingInputObject[0].redirect_link[rate] && ratingInputObject[0].redirect_link[rate].type === "google-reviews") {
                    if (google) {
                        window.location.href = google
                    } else {
                        afterSubmitDefault()
                    }
                }
                else if (ratingInputObject[0].redirect_link[rate] && ratingInputObject[0].redirect_link[rate].type === "yelp-reviews") {
                    if (yelp) {
                        window.location.href = yelp
                    } else {
                        afterSubmitDefault()
                    }
                }
            } else {
                afterSubmitDefault()
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const values = getValues();
        let data = Object.assign(values,
            { form_id: form && form.form && form.form.id, question, description, rating: rating, location: selectedLoc, date: date, barcode: bardcodeValue }
        )
        data = Object.assign(querySerachParams, data)
        axios.post(`${baseUrl}form-submit`, data).then(res => {
            if (rating === 1) {
                afterSubmit(0)
            } else if (rating === 2) {
                afterSubmit(1)
            } else if (rating === 3) {
                afterSubmit(2)
            } else if (rating === 4) {
                afterSubmit(3)
            } else if (rating === 5) {
                afterSubmit(4)
            } else {
                afterSubmitDefault()
            }
            setLoading(false);
        })
    }
    // let ratingss = form && form.form && form.form.formBuilderInputs && form.form.formBuilderInputs.filter(inp => inp.field_type === "rating")

    return (
        <div className="form-fullsc">
            <Grow in={showAlert}><Alert severity="success" className="fixed-alert-right" iconMapping={{ success: <CustomAlertIcon /> }}>Form Submitted Successfully</Alert></Grow>
            <ThankYouMsg open={openThankYouAlert} setOpen={setOpenThankYouAlert} />
            <Helmet>
                <title>{form ? form.form && form.form.name : 'iReview'}</title>
                <meta property="og:type" content="website" />
                <meta property="og:title" content={form && form.form && form.form.name} />
                <meta property="og:url" content={`https://irevu.co/forms/${form && form.form && form.form.identifier}`} />
                <meta name="description" content={form && form.form && form.form.name} />
                <meta property="og:publisher" content="iReview" />
                <meta property="og:description" content={form ? form.form && form.form.name + ' Reviews' : 'iReview Form Reviews'} />
                <meta property="og:image:width" content="588" />
                <meta property="og:image:height" content="358" />
                {form && form.form && form.form.logo ?
                    <meta property="og:image" content={form.form.logo} /> :
                    <meta property="og:image" content={ireview} />
                }
                {form && form.form && form.form.logo ?
                    <meta property="og:image" content={form.form.logo} /> :
                    <meta property="og:image" content={ireview} />
                }
            </Helmet>
            {loading && <Loader absoluteFlex={true} />}
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="form-client">

                    {form === undefined ?
                        <div className="custom-scrollbar-vertical">
                            <Skeleton width={"100%"} height={50} />
                            <Skeleton width={"100%"} height={50} />
                            <Skeleton width={"100%"} height={50} />
                            <Skeleton width={"100%"} height={50} />
                            <Skeleton width={"100%"} height={50} />
                            <Skeleton width={"100%"} height={50} />
                        </div>
                        : form.form && form.form.formBuilderInputs && form.form && form.form.formBuilderInputs.length > 0 ?
                            <>
                                <div className="custom-scrollbar-vertical form-client-holder">
                                    {form?.form?.name && <Box sx={{ textAlign: "center" }} component="h2" >{form?.form?.name}</Box>}
                                    <div className="form-client-logo">
                                        {form.form && form.form.logo && <img src={form.form.logo} alt={"logo"} />}
                                    </div>
                                    {form.form && form.form.formBuilderInputs.map(input => {
                                        if (input.field_type === 'input') {
                                            if (input.sendAsReview && !fieldsAsReview.includes(input.id)) {
                                                setFieldsAsReview(prev => [...prev, input.id])
                                            }
                                            return (
                                                <div key={`inp${input.id}`} className={input.type === "radio" ? 'flex-radios' : input.type === "rating" ? "flex-rating" : ''}>
                                                    {input.visibility === 1 && <label style={{ display: "block" }}>{input.name && input.name}</label>}
                                                    {input.type === "radio" ?
                                                        <input
                                                            {...register(input.field_input ? input.field_input : input.name && input.name.toLowerCase().replace(/ /g, "_"))}
                                                            value={input.name}
                                                            name={input.field_input ? input.field_input : input.name && input.name.toLowerCase().replace(/ /g, "_")}
                                                            type={input.type}
                                                            placeholder={input.placeholder && input.placeholder}
                                                            minLength={input.min_length && input.min_length < input.max_length ?  input.min_length : ""}
                                                            maxLength={input.max_length && input.max_length > input.min_length ?  input.max_length : ""}
                                                            required={input.required}
                                                            onChange={(e) => handleChange(e, input.field_input)}
                                                        />
                                                        :
                                                        <input
                                                            {...register(input.field_input ? input.field_input : input.name && input.name.toLowerCase().replace(/ /g, "_"))}
                                                            name={input.field_input ? input.field_input : input.name && input.name.toLowerCase().replace(/ /g, "_")}
                                                            type={input.type}
                                                            placeholder={input.placeholder && input.placeholder}
                                                            minLength={input.min_length && input.min_length < input.max_length ?  input.min_length : ""}
                                                            maxLength={input.max_length && input.max_length > input.min_length ?  input.max_length : ""}
                                                            required={input.required}
                                                        />}

                                                    <p>{input.description && input.description}</p>
                                                </div>
                                            )
                                        } else if (input.field_type === 'select') {
                                            return (
                                                <div key={`select${input.id}`}>
                                                    {input.visibility === 1 && <label>{input.name && input.name}</label>}
                                                    <select
                                                        {...register(input.field_input ? input.field_input : input.name && input.name.toLowerCase().replace(/ /g, "_"))}
                                                        required={input.required}
                                                        name={input.field_input ? input.field_input : input.name && input.name.toLowerCase().replace(/ /g, "_")}>
                                                        {input.options && input.options.map(option => {
                                                            return (
                                                                <option value={option.id}>{option.text}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <p>{input.description && input.description}</p>
                                                </div>
                                            )
                                        } else if (input.field_type === 'rating' && input.field_input === "rating") {
                                            return (
                                                <div key={`rating${input.id}`} className="rating-stars-form">
                                                    {input.visibility === 1 && <label style={{ display: "block" }}>{input.name && input.name}</label>}
                                                    <div>
                                                        {[...Array(5)].map((star, index) => {
                                                            index += 1;
                                                            return (
                                                                <button
                                                                    style={{ backgroundColor: "transparent" }}
                                                                    type="button"
                                                                    key={index}
                                                                    className={index <= (hover || rating) ? "on" : "off"}
                                                                    onClick={() => {
                                                                        setRating(index)
                                                                        handleAvg(index, "rating")
                                                                    }}
                                                                    onMouseEnter={() => setHover(index)}
                                                                    onMouseLeave={() => setHover(rating)}
                                                                    onDoubleClick={() => {
                                                                        setRating(0);
                                                                        setHover(0);
                                                                    }}
                                                                >
                                                                    <span>{index <= (hover || rating) ? <AiFillStar style={{ color: "rgba(241 170 9 / 85%)" }} /> : <AiOutlineStar style={{ color: "#80808070" }} />}</span>
                                                                </button>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        } else if (input.field_type === 'rating' && input.field_input !== "rating") {
                                            let name = input.field_input ? input.field_input + '_rating' : input.name.replace(/\s/g, '').substring(0, 12).toLowerCase() + '_rating'
                                            return (
                                                <div key={`rating${input.id}`} className="rating-stars-form">
                                                    {input.visibility === 1 && <label style={{ display: "block" }}>{input.name && input.name}</label>}
                                                    {[...Array(5)].map((star, index) => {
                                                        index += 1;
                                                        return (
                                                            <input key={name + 'inp' + index} style={{ display: "none" }} type="radio" id={name + index} value={index} name={name} {...register(name)} />
                                                        )
                                                    })}
                                                    <Rating inputName={name} handleAvg={handleAvg} />
                                                </div>
                                            )

                                        } else if (input.field_type === "location") {
                                            return (
                                                <div key={`select-location-type`}>
                                                    {input.visibility === 1 && <label style={{ display: "block" }}>{input.name && input.name}</label>}
                                                    <select ref={optionSelected} name="location" defaultValue={selectedLoc} onChange={(e) => handleLocation(e)}>
                                                        <option value="0" disabled>Select a location</option>
                                                        {form && form.locations && Object.keys(form.locations).map((el, i) => {
                                                            return (
                                                                <option
                                                                    value={el}
                                                                    key={el}
                                                                    google={Object.values(form.locations)[i].sources && Object.values(form.locations)[i].sources.google}
                                                                    yelp={Object.values(form.locations)[i].sources && Object.values(form.locations)[i].sources.yelp}
                                                                    facebook={Object.values(form.locations)[i].sources && Object.values(form.locations)[i].sources.facebook}
                                                                >
                                                                    {Object.values(form.locations)[i].name}
                                                                </option>
                                                            )
                                                        })}

                                                    </select>
                                                </div>
                                            )
                                        } else if (input.field_type === "date") {
                                            return (
                                                <div>
                                                    {input.visibility === 1 && <label style={{ display: "block" }}>{input.name && input.name}</label>}
                                                    <div className="date-container">
                                                        <div className="date-container-inputs">
                                                            <div><input className="no-spin" type="number" value={day} onChange={(e) => changeDate("day", e)} /></div>
                                                            <div><input className="no-spin" type="number" value={month} onChange={(e) => changeDate("month", e)} /></div>
                                                            <div><input className="no-spin" type="number" value={year} onChange={(e) => changeDate("year", e)} /></div>
                                                        </div>
                                                        <ClickAwayListener onClickAway={() => setShowCalendar(false)}>
                                                            <div>
                                                                <VscCalendar onClick={() => setShowCalendar(!showCalendar)} />
                                                                <Fade in={showCalendar} unmountOnExit>
                                                                    <div><Calendar value={cValue} onChange={(value) => getCalendarVal(value)} /></div>
                                                                </Fade>
                                                            </div>
                                                        </ClickAwayListener>
                                                    </div>
                                                </div>
                                            )
                                        } else if (input.field_type === "barcode") {
                                            return (
                                                <>
                                                    <div className="add-barcode-reader">
                                                        <div>
                                                            {input.visibility === 1 && <label style={{ display: "block" }}>{input.name && input.name}</label>}
                                                            <input
                                                                ref={inputRef}
                                                                type="text"
                                                                name="barcode"
                                                                placeholder="------"
                                                                value={bardcodeValue}
                                                                onChange={(e) => setBarcodeValue(e.target.value)}
                                                            />
                                                        </div>
                                                        <button type="button" onClick={() => showScan(true)}>Scan</button>
                                                    </div>
                                                    {scan && <BarcodeReaderComponent onDetected={onDetected} inputRef={inputRef} setScan={showScan} />}
                                                    <div ref={bottomRef}></div>
                                                </>
                                            )
                                        }
                                    })}
                                    {(ratingAvg == 3 || ratingAvg == 2 || ratingAvg == 1) &&
                                        <div>
                                            <label className="form-questions">What could we have done better?</label>
                                            <input type="text" onChange={(e) => setDescription(e.target.value)} />
                                        </div>
                                    }
                                    {ratingAvg == 4 &&
                                        <div>
                                            <label className="form-questions">We strive for 5's here.  How can we earn a big fat 5 next time?</label>
                                            <input type="text" onChange={(e) => setDescription(e.target.value)} />
                                        </div>
                                    }
                                </div>
                                <button type="submit">Submit</button>
                                <p className="footer-privacy" dangerouslySetInnerHTML={{ __html: form.form && form.form.footer ? form.form.footer : '' }}></p>
                            </>
                            : <div className="nothing-to-prev">Nothing to show</div>
                    }
                </div>
            </form>
        </div>
    )
}
export default Form