import "../../../../assets/admin.css";
import React from "react";
import Box from '@mui/material/Box';
import { Select, MenuItem, Skeleton, Alert } from "@mui/material";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import axios from "axios";
import { showAlert } from "../../../../redux/actions";
import { countries } from "../../../Helpers/countries";
import { ValidationTextField, ValidationSelectField } from "../../../Helpers/ValidationTextField";
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector } from "react-redux";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from "react-redux/es/exports";
import AlertComponent from "../../../Helpers/Alert";
import PasswordInput from "./passwordInput";
import { americaStates, canadaStates, mexicoStates } from "../../../Helpers/states";
import { FlagIcon } from "react-flag-kit";
import Header from "../../../Helpers/header"
import { fetchUserInfo, showYoutubeContent } from "../../../../redux/actions/admin-index";
import { LoadingButton } from "@mui/lab";
import useHandleDisable from "../../helpers/disableSelect";
import { useTranslation } from "react-i18next";

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

const passdefaultFields = {
	current_password: '',
	new_password: '',
	confirm_new_password: ''
}
const Account = () => {
	const handleDisable = useHandleDisable()
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false);
	//avatar
	const [loading, setLoading] = useState(false)
	const [avatar, setAvatar] = useState('');
	const [file, setFile] = useState('');
	let [error, setError] = useState('');
	let [errorPassword, setErrorPassword] = useState([]);
	const [isFocused, setIsFocused] = useState(false); //for country select
	const [isValidPhone, setIsValidPhone] = useState(false); //for phone
	const [phoneVal, setPhoneVal] = useState(false); //for phone
	const [passFields, setPassField] = useState(passdefaultFields);
	const {t} = useTranslation()
	//form inputs
	const [formFields, setFormFields] = useState({
		first_name: '',
		last_name: '',
		title: '',
		email: '',
		street: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		phone: ''
	});

	const [formFieldChanged, setFormFieldChanged] = useState({
		first_name: false,
		last_name: false,
		title: false,
		email: false,
		street: false,
		city: false,
		state: false,
		zip: false,
		phone: false
	});

	const { first_name, last_name, title, email, street, city, state, zip, phone, country } = formFields;
	const { current_password, new_password, confirm_new_password } = passFields;

	const user = useSelector(state => state.getUserInfo.user);
	const location = useSelector(state => state.locationSelected.location)
	let location_details = user && user.user.locations.filter(loc => loc.id == location)[0]

	useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/KZJZEaxmaiU?autoplay=1&mute=0&rel=0'))
    }, [])

	useEffect(() => {
		dispatch(fetchUserInfo())
		handleDisable()
	}, [])

	useEffect(() => {
		let result = {};
		if (user) {
			Object.keys(formFields).map((item) => {
				result[item] = user.user[item]
			})
			setAvatar(user.user.avatar)
			setFormFields({ ...result })
		}

	}, [user])


	const handleChange = (event) => {
		var { name, value, valid } = event.target;

		setFormFields({ ...formFields, [name]: value });
		setFormFieldChanged({ ...formFieldChanged, [name]: true });

		if(name === "country"){
			setFormFields(prev => ({ ...prev, "state": "" }));
		}
	};

	// for country select to change border color
	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};
	// for country select to change border color

	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false)
		setLoading(false)
		setPassField({ ...passdefaultFields });
	};

	const handleChangeImg = (e) => {
		const files = Array.from(e.target.files)
		setAvatar(URL.createObjectURL(files[0]))
		setFile(files[0])
	}
	const dotAfterAtRegex = /@.*\./;

	const handleSubmit = async (e) => {
		let fieldRequired = (!first_name || !last_name || !email || !street || !city || !state || !zip || !country  )
		let fieldRequiredWithoutState = (!first_name || !last_name || !email || !street || !city || !zip || !country  )
		
		// if ((country === "US" || country === "CA" || country === "MX") ? fieldRequired : fieldRequiredWithoutState) {	
		// 	// setError("Please fill required fields");
		// 	console.log('return false');
		// 	return false
		// } 
		if (!email.includes("@") || !dotAfterAtRegex.test(email)){
			if(!dotAfterAtRegex.test(email)){
				setError("Email field is not valid");
			} else {
				setError("Email field is not valid");
			}
		} else {
			setError("");
			let data = Object.assign(formFields);
			var formData = new FormData();
			formData.append('photo', file)
			Object.keys(data).map(key => {
				formData.append(key, formFields[key]);
			})
			axios.post(`${baseUrlDev}update-user-web`, formData, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(() => {
				dispatch(showAlert(true, "Profile updated successfully"))
				setTimeout(() => {
					dispatch(showAlert(false, ""))
				}, 5000)
			}).catch((err) => {
				const allErrorsFromBack = Object.values(err.response.data.errors)
				setError(allErrorsFromBack.join(''))
				// Object.keys(err.response.data.errors).map(key => {
				// 	setError([...error, err.response.data.errors[key][0]])
				// })
			})
		}
	};
	const handleChangePassword = (event) => {
		const { name, value } = event.target;
		setPassField({ ...passFields, [name]: value });
	}
	const handleSubmitPassword = () => {
		setLoading(true)
		if (new_password !== confirm_new_password) {
			alert('New Password and Confrim New Password do not match');
			return;
		}
		let data = Object.assign(passFields);
		var formData = new FormData();
		Object.keys(data).map(key => {
			formData.append(key, passFields[key]);
		})
		axios.post(`${baseUrlDev}change-password`, formData, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } }).then(() => {
			dispatch(showAlert(true, "Password changes successfully!"))
			setTimeout(() => {
				dispatch(showAlert(false, ""))
			}, 5000)
			setLoading(false)
			setOpen(false)
		}).catch((err) => {
			setLoading(false)
			Object.keys(err.response.data.errors).map(key => {
				setErrorPassword([...error, err?.response?.data?.errors[key][0]])
			})
			setTimeout(() => {
				setErrorPassword([])
			}, 5000)
			setOpen(false)
		})
	}

	useEffect(() => {
		if(user && user.user && location_details){
			let conuntryFound = user.user.country ? 
			(countries.find(c => c.name.toLowerCase() == user.user.country.toLowerCase()) ? countries.find(c => c.name.toLowerCase() == user.user.country.toLowerCase()).code2A : user.user.country.toUpperCase()) :
			(countries.find(c => c.name.toLowerCase() == location_details?.country.toLowerCase()) ? countries.find(c => c.name.toLowerCase() == location_details?.country.toLowerCase()).code2A : location_details.country.toUpperCase())
			setFormFields(prev => ({ ...prev, "country": conuntryFound }));
		}
	}, [user, location_details])



	return (
		<div className="account-settings-container">
			<AlertComponent />
			{error ? <div className="alert-inside-page"> <Alert severity="error" className="mb-50 custom-error-alert">{error}</Alert> </div> : ''}
			<div className="flex-header-container">
				<Header title="My Account" subtitle="Manage your Account"/>
			</div>
			<Grid container spacing={2} className="image-user-info">
				<Grid item xs={12} md={3}>
					<div className="account-image">

						<div className="image-user">
							{user ?
								<div>
									<label htmlFor="avatar">
										<img src={"/admin-icons/pencil-rounded-bg.svg"} className="editIcon" />
									</label>
									<img src={avatar ? avatar : "/admin-icons/uploadIcon.svg"} alt={avatar} />
									<input type="file" id="avatar" onChange={(e) => handleChangeImg(e)} />
								</div>
								: <Skeleton style={{ width: "100%", height: "100%", transform: 'unset' }} variant="circular" />}
						</div>

					</div>
				</Grid>
				<Grid item xs={12} md={9}  >
					<Grid container spacing={2} >
						<Grid item xs={12} md={6}>
							<div className="account-inputs-section">
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
									{user ?
										<FormControl sx={{ width: '100%' }} variant="outlined">
											<ValidationTextField
												type="text"
												onChange={handleChange}
												name='first_name'
												label={t("First Name")}
												variant="outlined"
												// defaultValue={user && user.user.first_name}
												value={formFields.first_name}
												required={formFieldChanged.first_name}
											/>
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
									{user ?
										<FormControl sx={{ width: '100%' }} variant="outlined">
											<ValidationTextField
												type="text"
												onChange={handleChange}
												name='last_name'
												label={t("Last Name")}
												variant="outlined"
												// defaultValue={user && user.user.last_name}
												value={formFields.last_name}
												required={formFieldChanged.last_name}
											/>
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
									{user ?
										<FormControl sx={{ width: '100%' }} variant="outlined">
											<ValidationTextField
												type="text"
												onChange={handleChange}
												name='title'
												label={t("Title")}
												variant="outlined"
												// defaultValue={user && user.user.title}
												value={formFields.title}
												required={formFieldChanged.title}
											/>
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
									{user ?
										<FormControl sx={{ width: '100%' }} variant="outlined">
											<ValidationTextField
												type="email"
												onChange={!formFields.email && handleChange}
												name='email'
												label={t("Email Address")}
												variant="outlined"
												disabled={formFields.email ? true : false}
												// defaultValue={user && user.user.email}
												value={formFields.email}
												required={formFieldChanged.email}
												InputProps={{
													inputProps: {
													  pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
													},
												}}
											/>
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
									{user ?
										<FormControl sx={{ width: '100%' }} variant="outlined">
											<PhoneInput
												inputProps={{
													name: t('Phone'),
												}}
												specialLabel={t("Phone")}
												inputStyle={{
													borderColor: isValidPhone ? "#198754" : phoneVal ? "#f44336" : "#ced4da",
												}}
												enableSearch
												country={"us"}
												value={user && user.user.phone}
												onChange={(val) => handleChange({ target: { name: "phone", value: val } }, setIsValidPhone(true), setPhoneVal(val))}
											/>
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
							</div>
						</Grid>
						<Grid item xs={12} md={6}>
							<div className="account-inputs-section">
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
									{user ?
										<FormControl sx={{ width: '100%' }} variant="outlined">
											<ValidationTextField
												type="text"
												onChange={handleChange}
												name='street'
												label={t("Street")}
												variant="outlined"
												// defaultValue={user && user.user.street}
												value={formFields.street}
												required={formFieldChanged.street}
											/>
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
									{user ?
										<FormControl sx={{ width: '100%' }} variant="outlined">
											<ValidationTextField
												type="text"
												onChange={handleChange}
												name='city'
												label={t("City")}
												variant="outlined"
												// defaultValue={user && user.user.city}
												value={formFields.city}
												required={formFieldChanged.city}
											/>
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
									{user ?
										<FormControl sx={{ width: '100%' }} variant="outlined">
											{country !== "US" && country !== "CA" && country !== "MX" ?
											<ValidationTextField
												type="text"
												onChange={handleChange}
												name='state'
												label={t("State")}
												variant="outlined"
												value={formFields.state}
											/>
										:
											<Select
												labelId="select-state-label"
												id="demo-simple-select-error"
												style={{ width: '100%', height: '47px', marginBottom: "6px" }}
												name='state'
												value={formFields.state}
												label={t("State")}
												MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
												onChange={handleChange}
												onFocus={handleFocus}
												onBlur={handleBlur}
												input={<ValidationSelectField touched={formFieldChanged.state ? formFieldChanged : undefined} isfocused={isFocused ? isFocused : undefined} />}
											>
												<MenuItem className="mym" value=""><em>None</em></MenuItem>
												{country === "US" ? 
													americaStates.map(state => {
														return (
															<MenuItem key={state.code2A} value={state.code2A}>{state.name}</MenuItem>
														)
													})
													: 
													country === "CA" ? 
													canadaStates.map(state => {
														return (
															<MenuItem key={state.code2A} value={state.code2A}>{state.name}</MenuItem>
														)
													})
													: country === "MX" && 
													mexicoStates.map(state => {
														return (
															<MenuItem key={state.code2A} value={state.code2A}>{state.name}</MenuItem>
														)
													})
												}
											</Select>
										}
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} noValidate autoComplete="off">
									{user ?
										<FormControl sx={{ width: '100%' }} variant="outlined">
											<ValidationTextField
												type="text"
												onChange={handleChange}
												name='zip'
												label={t("Zip Code")}
												variant="outlined"
												// defaultValue={user && user.user.zip}
												value={formFields.zip}
												required={formFieldChanged.zip}
											/>
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
								<Box component="form" sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} autoComplete="off">
									{user ?
										<FormControl className={`country-select-wrap `}  style={{ width: '100%' }}>
											<InputLabel className="bg-white" id="select-country-label">{t("Country")}</InputLabel>
											<Select
												labelId="select-state-label"
												id="demo-simple-select-error"
												style={{ width: '100%', height: '47px' }}
												name='country'
												value={formFields.country}
												label={t("Country")}
												MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
												onChange={handleChange}
												onFocus={handleFocus}
												onBlur={handleBlur}
												input={<ValidationSelectField touched={formFieldChanged.country ? formFieldChanged : undefined} isfocused={isFocused ? isFocused : undefined} />}
											>
												<MenuItem className="mym" value=""><em>None</em></MenuItem>
												{countries.map(country => {
													return (
														<MenuItem key={country.code3A} value={country.code2A}><FlagIcon code={country.code2A}  style={{width: "20px", height: "15px", marginRight: "10px"}}/>{country.name}</MenuItem>
													)
												})}
											</Select>
										</FormControl>
										: <Skeleton style={{ height: "45px", width: "100%" }} />}
								</Box>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container className="password-section">
				<Grid item xs={12} sm={6} md={6}>
					<h4 style={{margin: 0}}>{t("Password")}</h4>
				</Grid>
				<Grid item xs={12} sm={6} md={6} textAlign={'right'}>
					<Button variant="outlined" onClick={handleOpen}>{t("Change Password")}</Button>
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={{ ...style, width: 500, border: 0 }}>
							{errorPassword && errorPassword.map(err => {
								return(
									<div className="alert-inside-modal" key={err}>
										<Alert severity="error" className="custom-error-alert">{err}</Alert>
									</div>
								)
							})}
							<Typography id="modal-modal-title" variant="h6" component="h2">
								{t("Change Password")}
							</Typography>
							<div className="account-inputs-section">
								<PasswordInput name="current_password" label={t("Current Password")} handleChangePassword={handleChangePassword} value={current_password} />
								<PasswordInput name="new_password" label={t("New Password")} handleChangePassword={handleChangePassword} value={new_password} />
								<PasswordInput name="confirm_new_password" label={t("Confirm New Password")} handleChangePassword={handleChangePassword} value={confirm_new_password} />
								<div className="custom-modal-footer" style={{paddingRight: 0}}>
									<LoadingButton
											style={{paddingLeft: loading && "30px"}}
											className="save-btn"
											onClick={() => handleSubmitPassword()}
											loading={loading}
											disabled={!current_password || !new_password || !confirm_new_password}
										>
											{t("Save")}
										</LoadingButton>
									<button className="close-btn" onClick={() => handleClose()}>{t("Close")}</button>
								</div>
							</div>
						</Box>
					</Modal>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} md={12} className="save-changes-section">
					<Button onClick={() => handleSubmit()} type="submit" variant="contained">{t("Save Changes")}</Button>
				</Grid>
			</Grid>
		</div>


	)

}

export default Account;