import React, {useState} from "react"
import { useSelector } from "react-redux";
import {BiDislike} from "react-icons/bi"
import {AiFillStar} from "react-icons/ai"
import LightTooltip from "../../../helpers/lightTooltip";
import { useTranslation } from "react-i18next";

const ReviewPagePreview = ({reviewPageFields, location_details, logoPrev, review_page}) => {
    const {t} = useTranslation()
    return(
        <div className="card-position-sticky">
          <div className='card adm-prod-prodimg-div right-card-col h-100'>
            <div className='card-header adm-dropzone-header'>{t("Preview of Review Page")}</div>
                <div className={`card__address-content ${location_details?.length > 0 && location_details[0]?.logo ? "hasImg" : ""}`}>
                    <div className={`card__address-content ${logoPrev ? "hasImg" : ""}`}>
                        {logoPrev && <img src={logoPrev} alt="logo"/>}
                    </div>
                    <div>
                        <div className="top">
                            <h3 className="head">{location_details?.length > 0 && location_details[0].name}</h3>
                            <h6 className="sub-head">{location_details?.length > 0 && location_details[0].address}</h6>
                        </div>

                        <div className="d-flex align-items-center bottom">
                            <div>
                                <img src={"/admin-images/tools/images/star.svg"} alt="star" />
                                <img src={"/admin-images/tools/images/star.svg"} alt="star" />
                                <img src={"/admin-images/tools/images/star.svg"} alt="star" />
                                <img src={"/admin-images/tools/images/star.svg"} alt="star" />
                                <img src={"/admin-images/tools/images/star.svg"} alt="empty-star" />
                            </div>
                            <span>{review_page?.rating} - {t("Based On")} {review_page?.reviews} {t("Reviews")}</span>
                        </div>
                    </div>
                </div>
                <div className="content__wrap">
                    <div className="experience-card">
                        <h2 className="title">{reviewPageFields.heading}</h2>
                        <p className='mb-0'>{reviewPageFields.sub_heading}</p>
                        {reviewPageFields.only_feedback === 0 &&   
                            <div className="buttons">
                                {reviewPageFields.buttons && reviewPageFields.buttons.map(button => {
                                    return(
                                        <button className="btn review-btn-platform" key={`button${button.id}`} style={{backgroundColor: button.color, paddingLeft: button.source && button.source.logo_src ? "50px" : ""}}>
                                            {button.source && button.source.logo_src  && <img  src={button.source.logo_src} id="platform-button-img420"/>}
                                            {button.label}
                                        </button>
                                    )
                                })}
                            </div>
                        }
                        {reviewPageFields.hide_contact_button === 0 && 
                            <div className="contact-us-section">
                                <h6>{reviewPageFields.irevu_button_text}</h6>
                                {<button style={{background: reviewPageFields.button_color}}>{reviewPageFields.button_logo === "unlike" ? <BiDislike/> : reviewPageFields.button_logo === "star" ? <AiFillStar style={{color: "#F9D93D"}}/> : ''}{reviewPageFields.button_text}</button>}
                                {reviewPageFields.phone && <a href={`tel:${reviewPageFields.phone}`}>| {reviewPageFields.phone}</a>}
                            </div>
                        }
                    </div>
                </div>
         
            </div>
        </div>
    )
}
export default ReviewPagePreview
