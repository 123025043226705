import React from 'react';
import { styled } from "@mui/material";
import { LoadingButton } from '@mui/lab';

// Define a custom styled component
export const  CustomLoadingButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#0c2640',
  fontSize: "0.775em",
  boxShadow: "0px 29.9688px 79.9167px rgb(0 0 0 / 10%)",
  borderRadius: "3px",
  border: "1px solid #00c1f724",
  cursor: "pointer",
  textTransform: "capitalize",
  fontFamily: "Poppins-Regular",
  '&:hover': {
    boxShadow: "0px 29.9688px 79.9167px rgb(0 0 0 / 22%)",
    backgroundColor: '#fff',
  },
  '&.MuiLoadingButton-loading': {
    color: '#0c2640',
  },
  '& .MuiLoadingButton-loadingIndicator':{
    left: "15px"
  }
}));

export const  CustomLoadingButtonBlue = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: '#00B2E3',
  color: '#fff',
  fontSize: "0.775em",
  boxShadow: "0px 29.9688px 79.9167px rgb(0 0 0 / 10%)",
  borderRadius: "3px",
  border: "1px solid #00c1f724",
  cursor: "pointer",
  textTransform: "capitalize",
  width: "100%",
  fontFamily: "Poppins-Regular",
  '&:hover': {
    backgroundColor: '#00B2E3',
  },
  '&.MuiLoadingButton-loading': {
    color: '#fff',
  },
  '& .MuiLoadingButton-loadingIndicator':{
    left: "43%",
    color: '#fff'
  }
}));
