import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useWebSocket } from '../useWebSocket'

const WebSocketPrompts = () => {
	const [showTwoFACode, setShowTwoFACode] = useState(false);
	const [twoFACode, setTwoFACode] = useState('');
	const [loading, setLoading] = useState(false);
	const [canSend, setCanSend] = useState(false);
	const [message, setMessage] = useState('');
	const messageQueue = useRef([]);
	const requestID = useRef(null);

	const ws = useWebSocket();

	const handleClose = () => {
		setShowTwoFACode(false);
		setLoading(false);
	};

	useEffect(() => {
		if (ws) {
			ws.onmessage = (event) => {
				const data = JSON.parse(event.data);
				if (data.action === '2fa-request') {
					setMessage(`Please enter the code sent from ${data.source} to your email address or mobile number.`);
					setCanSend(true);
					requestID.current = data.requestID;
					setShowTwoFACode(true);
				} else if (data.action === '2fa-expired' && requestID.current === data.requestID) {
					setMessage('⚠️ Two-factor authentication code expired.');
					setCanSend(false);
					setTimeout(() => {
						handleClose()
					}, 2000);
				} else if (data.action === '2fa-received') {
					setMessage('✅ Two-factor authentication code received.');
					setCanSend(false);
					setTimeout(() => {
						handleClose()
					}, 2000);
				}
			};
			if (ws.readyState === WebSocket.OPEN && messageQueue.current.length > 0) {
				messageQueue.current.forEach((queuedMessage) => {
					ws.send(queuedMessage);
				});
				messageQueue.current = [];
			}
		}
	}, [ws]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		let message = JSON.stringify({ action: '2fa-response', value: twoFACode, requestID: requestID.current });
		if (ws.readyState === WebSocket.OPEN) {
			ws.send(message);
		} else {
			messageQueue.current.push(message);
		}
		setCanSend(false);
		setLoading(true);
	};

	return (
		<>
			<Dialog open={showTwoFACode} onClose={handleClose}>
				<DialogTitle style={{ marginBottom: '6px', paddingBottom: 0, maxWidth: '360px' }}>
					Two-factor authentication code requested
				</DialogTitle>
				<form onSubmit={handleSubmit}>
					<DialogContent style={{ maxWidth: '360px' }}>
						<DialogContentText>
							{message}
						</DialogContentText>
						<TextField
							autoFocus
							margin="dense"
							id="code"
							label="Code"
							type="text"
							fullWidth
							variant="standard"
							disabled={!canSend}
							onChange={(e) => setTwoFACode(e.target.value)}
						/>
					</DialogContent>
					<DialogActions style={{ padding: '0 20px 20px' }}>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							sx={{ color: 'white' }}
							loading={loading}
							disabled={!canSend}
						>
							Submit
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default WebSocketPrompts;
