import React, { useState, useRef, useEffect, Suspense } from "react"
import { useParams } from "react-router";
import "../../../../assets/admin.css";
import {Skeleton, Box, FormControl, Select, Alert, MenuItem, Grid, IconButton, OutlinedInput, InputLabel, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { HiUsers, HiOutlineMail } from "react-icons/hi";
import { FiCreditCard } from "react-icons/fi";
import PhoneInput from 'react-phone-input-2';
import { countries } from "../../../Helpers/countries";
import axios from "axios";
import { ValidationTextField, ValidationSelectField } from "../../../Helpers/ValidationTextField";
import { fetchUserManagement, clearUserManagement, handle404ErrorReset } from "../../../../redux/actions/admin-index";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import {showAlert}  from "../../../../redux/actions"
import AlertComponent from "../../../Helpers/Alert"
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";
import { americaStates, canadaStates, mexicoStates } from "../../../Helpers/states";
import { FlagIcon } from "react-flag-kit";
import Header from "../../../Helpers/header";
import useHandleDisable from "../../helpers/disableSelect";
import { useTranslation } from "react-i18next";
const LocationPermissions = React.lazy(() => import("./locationPermissions"))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AccountAddUser = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const phoneInputRef = useRef(null);
    const dispatch = useDispatch()
    const handleDisable = useHandleDisable()
    const {t} = useTranslation()
    //form inputs
    const [formFields, setFormFields] = useState({
        first_name: '',
        last_name: '',
        title: '',
        email: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone: '',
        password: '',
        is_subuser: ' ',
        allowed_locations: [],
        not_allowed_locations: [],
    });
    const [formFieldsChanged, setFormFieldsChanged] = useState({
        first_name: false,
        last_name: false,
        title: false,
        email: false,
        street: false,
        city: false,
        state: false,
        zip: false,
        country: false,
        phone: false,
        password: false,
        is_subuser: false
    });
    const [showPassword, setShowPassword] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [file, setFile] = useState('');
    const [columns, setColumns] = useState([]);
    const [errors, setErrors] = useState([])
    const [submit_errors, setSubmitErrors] = useState([])
    const [loading, setLoading] = useState(false)

    const user = useSelector(state => state.getUserInfo.user);
    const user_management =  useSelector(state => state.getUserManagement.user_management);

    const locations = user && user.user.locations;
    
    const notFound = useSelector(state => state.notFound.notFound);

    useEffect(() => {
        dispatch(handle404ErrorReset())
    }, [])

    if (notFound) {
      navigate("/404")
    }
  
  
    useEffect(() => {
        dispatch(clearUserManagement())
        dispatch(fetchUserManagement(id))
        // handleDisable()
    }, [])

    
    useEffect(() => {
        let result = {};
        if (user_management && user_management.data) {
            Object.keys(formFields).map((item) => {
                result[item] = user_management.data[item] ? user_management.data[item] : ""
            })
            setFormFields({ ...result })
            setAvatar(user_management.data.avatar)
            setColumns({ ...columns, notAllowed: { ...columns.notAllowed, items: user_management.data.not_allowed_locations }, allowed: { ...columns.allowed, items: user_management.data.allowed_locations } })
        }
    }, [user_management, locations])

    useEffect(() => {
		if(user_management && user_management.data){
			let conuntryFound =user_management.data.country ? 
			(countries.find(c => c.name.toLowerCase() == user_management.data.country.toLowerCase()) ? countries.find(c => c.name.toLowerCase() == user_management.data.country.toLowerCase()).code2A : user_management.data.country.toUpperCase()) : "US"
			setFormFields(prev => ({ ...prev, "country": conuntryFound }));
		}
	}, [user_management])


    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(value)
        setFormFields({ ...formFields, [name]: value });
        setFormFieldsChanged({ ...formFieldsChanged, [name]: true });
    };


    const handleChangeImg = (e) => {
        const files = Array.from(e.target.files)
        setAvatar(URL.createObjectURL(files[0]))
        setFile(files[0])
    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {event.preventDefault();};
   
    const handleSubmit = async (e) => {
        if(columns.allowed.items.length == 0){
            setSubmitErrors(prev => [...prev, "At least one allowed location is required"])
            setTimeout(() => {
                setSubmitErrors([])
            }, 5000)
            return
        }
        setLoading(true)
        var formData = new FormData();
        formData.append('photo', file);
       
        let formatAllowed = columns.allowed.items.map(el => {
            return{
                [el.id]: {
                    permissions: el.permissions ? el.permissions : [],
                    notifications: el.notifications ? el.notifications : []
                }
            }
        })

        let formatNotAllowed = columns.notAllowed.items.map(el => el.id)
        const formattedPhoneNumber = phoneInputRef.current.state.formattedNumber
        Object.keys(formFields).map(key => {
            if(key === "allowed_locations"){
                formData.append("allowed", JSON.stringify(formatAllowed))
            }else if (key === "not_allowed_locations"){
                formatNotAllowed.forEach(p => {
                    formData.append("not_allowed[]",p)
                }) 
            } else if (key === "phone"){
                formData.append(key, formattedPhoneNumber);
            } else {
                formData.append(key, formFields[key]);
            }
        })

        axios.post(`${baseUrlDev}users/${id}/update`, formData, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(() => {
            setLoading(false)
            dispatch(showAlert(true, "User updated Succesfully!"));
            setTimeout(()=>{
                dispatch(showAlert(false,""));
            },5000);  
        }).catch((err) => {
            setLoading(false)
            Object.keys(err.response.data.errors).map(key => {
                setSubmitErrors(prev => [...prev, err.response.data.errors[key][0]])
            })
            setTimeout(() => {
                setSubmitErrors([])
            }, 5000)
        })
    }
 
    return (
        <div>
            <AlertComponent/>
            <div className="alert-inside-page">
                {submit_errors && submit_errors.map(err => {
                    return(
                        <Alert key={err} severity="error" className="custom-error-alert">{err}</Alert>
                    )
                })}
            </div>
            <div className="account-settings-container">
                <div className="flex-header-container">
                    <Header title={t("Account")} subtitle={t("User Management / Edit User")}/>
                </div>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <div className="account-image user-profile-photo">

                            <div className="image-user">
                                {user_management ?
                                    <div>
                                        <label htmlFor="avatar">
                                            <img src={"/admin-icons/pencil-rounded-bg.svg"} className="editIcon" />
                                        </label>
                                        <img src={avatar ? avatar : "/admin-icons/uploadIcon.svg"} alt={avatar} />
                                        <input type="file" id="avatar" onChange={(e) => handleChangeImg(e)} />
                                    </div>
                                    : <Skeleton style={{ width: "100%", height: "100%", transform: 'unset' }} variant="circular" />}
                            </div>

                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={3} className="account-inputs-section">
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            {user_management ?
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <ValidationTextField
                                        type="text"
                                        onChange={handleChange}
                                        name='first_name'
                                        label={t("First Name")}
                                        variant="outlined"
                                        value={formFields.first_name}
                                        required={formFieldsChanged.first_name}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><HiUsers /></InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            {user_management ?
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <ValidationTextField
                                        type="text"
                                        onChange={handleChange}
                                        name='last_name'
                                        label={t("Last Name")}
                                        variant="outlined"
                                        value={formFields.last_name}
                                        required={formFieldsChanged.last_name}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><HiUsers /></InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            {user_management ?
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <ValidationTextField
                                        type="text"
                                        onChange={handleChange}
                                        name='title'
                                        label={t("Title")}
                                        variant="outlined"
                                        value={formFields.title}
                                        required={formFieldsChanged.title}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><FiCreditCard /></InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            {user_management ?
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <ValidationTextField
                                        type="email"
                                        onChange={handleChange}
                                        name='email'
                                        label={t("Email Address")}
                                        variant="outlined"
                                        value={formFields.email}
                                        required={formFieldsChanged.email}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><HiOutlineMail /></InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                        {user_management ?
                            <FormControl sx={{width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">{t("Password")}</InputLabel>
                                <OutlinedInput
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={formFields.password}
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                            : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                        {user_management ?
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                {formFields.country !== "US" && formFields.country !== "CA" && formFields.country !== "MX" ?
                                    <ValidationTextField
                                        type="text"
                                        onChange={handleChange}
                                        name='state'
                                        label={t("State")}
                                        variant="outlined"
                                        placeholder={t("State")}
                                        value={formFields.state}
                                    />
                                    : 
                                    <Select
                                        labelId="select-state-label"
                                        id="demo-simple-select-error"
                                        style={{ width: '100%', height: '47px', marginBottom: "6px" }}
                                        name='is_subuser'
                                        value={(formFields.is_subuser === "Manager" || formFields.is_subuser == 0) ? 0 : 1}
                                        label="Role"
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                        onChange={handleChange}
                                        input={<ValidationSelectField touched={formFieldsChanged.is_subuser ? formFieldsChanged.is_subuser : undefined} />}
                                    >
                                        <MenuItem className="mym" value=" " disabled><em>{t("Select Role")}</em></MenuItem>
                                        <MenuItem value="0">{t("Manager")}</MenuItem>
                                        <MenuItem value="1">{t("Sub User")}</MenuItem>
                                    </Select>
                                }
                            </FormControl>
                            : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid> 
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            {user_management ?
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <PhoneInput
                                        inputProps={{
                                            name: 'phone',
                                            
                                        }}
                                        specialLabel={t("Phone")}
                                        ref= {phoneInputRef}
                                        country={'us'}
                                        enableSearch
                                        value={formFields.phone}
                                        onChange={(val) => handleChange({ target: { name: "phone", value: val } })}
                                    />
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            {user_management ?
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <ValidationTextField
                                        type="text"
                                        onChange={handleChange}
                                        name='street'
                                        label={t("Street")}
                                        variant="outlined"
                                        value={formFields.street}
                                        required={formFieldsChanged.street} />
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            {user_management ?
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <ValidationTextField
                                        type="text"
                                        onChange={handleChange}
                                        name='city'
                                        label={t("City")}
                                        variant="outlined"
                                        value={formFields.city}
                                        required={formFieldsChanged.city}/>
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            {user_management ?
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    {formFields.country !== "US" && formFields.country !== "CA" && formFields.country !== "MX" ?
                                        <ValidationTextField
                                            type="text"
                                            onChange={handleChange}
                                            name='state'
                                            label={t("State")}
                                            placeholder={t("State")}
                                            variant="outlined"
                                            value={formFields.state}
                                        />
                                        : 
                                        <Select
                                            labelId="select-state-label"
                                            id="demo-simple-select-error"
                                            style={{ width: '100%', height: '47px', marginBottom: "6px" }}
                                            name='state'
                                            value={formFields.state}
                                            label={t("State")}
                                            MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                            onChange={handleChange}
                                            input={<ValidationSelectField touched={formFieldsChanged.state ? formFieldsChanged.state : undefined} />}
                                        >
                                            <MenuItem className="mym" value=" "><em>{t("State")}</em></MenuItem>
                                            {formFields.country === "US" ? 
                                                americaStates.map(state => {
                                                    return (
                                                        <MenuItem key={state.code2A} value={state.code2A}>{state.name}</MenuItem>
                                                    )
                                                })
                                                : 
                                                formFields.country === "CA" ? 
                                                canadaStates.map(state => {
                                                    return (
                                                        <MenuItem key={state.code2A} value={state.code2A}>{state.name}</MenuItem>
                                                    )
                                                })
                                                : formFields.country === "MX" && 
                                                mexicoStates.map(state => {
                                                    return (
                                                        <MenuItem key={state.code2A} value={state.code2A}>{state.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>}
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  noValidate autoComplete="off" className="input-icon">
                            {user_management ?
                                <FormControl sx={{ width: '100%' }} variant="outlined">
                                    <ValidationTextField
                                        type="text"
                                        onChange={handleChange}
                                        name='zip'
                                        label={t("Zip Code")}
                                        variant="outlined"
                                        value={formFields.zip}
                                        required={formFieldsChanged.zip} />
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box component="form"  autoComplete="off">
                            {user_management ?
                                <FormControl className={formFields.country.length > 0 ? 'required-select-filled' : "required-select"} required style={{ width: '100%' }}>
                                    <InputLabel className="bg-white" id="select-country-label">{t("Country")}</InputLabel>
                                    <Select
                                        labelId="select-state-label"
                                        id="demo-simple-select-error"
                                        style={{ width: '100%', height: '50px' }}
                                        name='country'
                                        value={formFields.country ? formFields.country : "US"}
                                        label={t("Country")}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                                        onChange={handleChange}
                                        input={<ValidationSelectField />}
                                        required={formFieldsChanged.country}
                                    >
                                        <MenuItem className="mym" value=" "><em>None</em></MenuItem>
                                        {countries.map(country => {
                                            return (
                                                <MenuItem key={country.code3A} value={country.code2A}><FlagIcon code={country.code2A}  style={{width: "20px", height: "15px", marginRight: "10px"}}/>{country.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                : <Skeleton style={{ height: "45px", width: "100%", transform: "unset"}} />}
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <div
                style={{ display: "flex", margin: "24px 0px",  width: "100%", justifyContent: "space-between", columnGap: "30px", height: "100%" }}
            >
                {user_management ?
                    <Suspense fallback={<Skeleton style={{ height: "400px", width: "100%", transform: "unset" }} />}>
                        <LocationPermissions user_management={user_management} columns={columns} setColumns={setColumns} errors={errors} setErrors={setErrors}/>
                    </Suspense>
                        : 
                    <Skeleton style={{ height: "400px", width: "100%", transform: "unset" }} />
                }
            </div>
            <div className="save-changes-section mt-30">
                <div className='info-page-foot'>
                    <LoadingButton
                            style={{paddingLeft: loading && "30px"}}
                            className="btn add_btn blue-loading-btn"
                            onClick={() => handleSubmit()}
                            loading={loading}
                        >
                            {t("Save Changes")}
                    </LoadingButton>
                </div>
            </div>
        </div>

    )
}
export default AccountAddUser;