import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { StyledLoadingButton } from "../../Helpers/customLoadingButton";
import { useTranslation } from "react-i18next";
//My imports
import { Accordion, AccordionSummary, AccordionDetails} from "../accordion/accordion";
import FieldLabel from "./fieldLabel";
import FieldDetails from "./fieldDisplay";
import FieldValidationRules from "./fieldsValidation";
import FieldRedirect from "./FieldRedirecting";
import {addFormId, fetchFormDetails, fieldAsReview, fetchFormDetailsFields, formDetailsClearFields, fieldType, fieldDescription, fieldPlaceholder, fieldInputType, fieldSelectOptions, fieldInputName } from "../../../redux/actions";
import { baseUrl } from "../../Helpers/baseUrl";
import useQueryParamsHook from "../parts/useQueryParamsHook";
import { getHeaderLocalUser } from "../../TokenControl/parts/useLocalUser";
import Loader from "../../Admin/helpers/loader";

const AddFields = ({show, setShow, detailsId, system}) => {
    const {id} = useParams()
    const dispatch  = useDispatch()
    const [value, setValue] = useState('1')
    const [loading, setLoading] = useState(true)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [showReviewCheck, setShowReviewCheck] = useState(false)
    const [requiredInputs, setRequiredInputs] = useState([])
    //startline queryParms
    // used to get the query params from the url EX: ?type=radio ?phome=442
    const objectQueryParams = useQueryParamsHook();
    //endline queryParams
    const [errors, setErrors] = useState([]);
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(addFormId(id))
    }, [id])

    let form = useSelector(state => state.FormFieldAdded)
    let action = useSelector(state => state.FormActionBuilder.formAction)

    //form obj for post
    let form_fields = useSelector(state => state.getFormDetailsFields.form)
    const formItems = useSelector(state => state.getFormDetails.form)

    const input_field_type = useSelector(state => state.FormFieldAdded.field_type)

    useEffect(() => {
        if(form_fields){
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, [form_fields])

    const fillInputsonEdit = () => {
        dispatch(fieldType(form_fields.data.field_type))
        dispatch(fieldInputType(form_fields.data.type))
        dispatch(fieldDescription(form_fields.data.description))
        dispatch(fieldPlaceholder(form_fields.data.placeholder))
        dispatch(fieldInputName(form_fields.data.field_input))
        dispatch(fieldAsReview(form_fields.data.sendAsReview))
    }

    const emptyInputsonEdit = () => {
        dispatch(fieldType('input'))
        dispatch(fieldInputType('text'))
        dispatch(fieldDescription(''))
        dispatch(fieldPlaceholder(''))
        dispatch(fieldInputName(''))
        dispatch(fieldAsReview(false))
    }

    useEffect(() => {
         if(formItems && formItems.data?.formBuilderInputs && formItems.data.formBuilderInputs.some(el => el.field_type === "rating")){
            setShowReviewCheck(true)
         } else{
            setShowReviewCheck(false)
         }

        if(form_fields && form_fields.data){
            fillInputsonEdit()
        } else {
            emptyInputsonEdit()
        }
    }, [form_fields, show])

    const handleClose = () => {
        setShow(false)
        setValue('1')
        dispatch(fieldType('input'))
        setLoading(false)
        setErrors([])
    }

    const handleChange = (panel) => (event, newExpanded) => {
        setValue(newExpanded ? panel : false);
    };
    
    const buildForm = () => {
        setLoadingBtn(true)
        var formWithParams = includeQueryParams(form);// append query params to the form object
        if(formWithParams?.min_length && formWithParams?.max_length && (parseInt(formWithParams.min_length) > parseInt(formWithParams.max_length))){
            setErrors(["Please check your min and max length validation, max should be equal or greater than min"])
            setLoadingBtn(false)
        } else {
            setErrors([])
            axios.post(`${baseUrl}form-builder/${id}`, formWithParams,{
                headers: {...getHeaderLocalUser()}
            }).then(res => {
                dispatch(fetchFormDetails(id))
                setShow(false)
                setValue('1')
                setLoadingBtn(false)
            }).catch((err) => {
                dispatch(formDetailsClearFields());
                setLoadingBtn(false);
                Object.keys(err.response.data.errors).map(key => {
                    setErrors(errors => [...errors, err.response.data.errors[key][0]])
                })
                setTimeout(() => {
                    setErrors([])
                }, 5000)
            });
        }

        // return () => {
        //     dispatch(formDetailsClearFields());
        //     setLoadingBtn(false)
        // }
    }
  
    const editForm = () => {
        setLoadingBtn(true)
        var formWithParams = includeQueryParams(form); // append query params to the form object
        let emptyInputError = []
        form.redirect_link.filter(el => el.type === "redirect" || el.type === "thank you")?.map(el => {
            if(!el.input){
                if(!emptyInputError.includes(el.rating)){
                    emptyInputError = [...emptyInputError, el.rating]
                    setRequiredInputs(emptyInputError)
                } else {
                    emptyInputError = emptyInputError.filter(i => i !== el.rating)
                    setRequiredInputs(emptyInputError)
                } 
            }
        })
        //Array above holds the empty inputs, is not used anywhere but if we need it later we can access it from here
      
        if(formWithParams?.min_length && formWithParams?.max_length && (parseInt(formWithParams.min_length) > parseInt(formWithParams.max_length))){
            setErrors(["Please check your min and max length validation, max should be equal or greater than min"])
            setLoadingBtn(false)
        } else {
            setErrors([])
            axios.put(`${baseUrl}form-builder/${form_fields && form_fields.data.id}`, formWithParams,{
                headers: {...getHeaderLocalUser()}
            }).then(res => {
                dispatch(fetchFormDetails(id))
                dispatch(fetchFormDetailsFields(detailsId))
                setShow(false)
                setValue('1')
                setLoadingBtn(false)
            }).catch((err) => {
                setLoadingBtn(false);
                Object.keys(err.response.data.errors).map(key => {
                    setErrors(errors => [...errors, err.response.data.errors[key][0]])
                })
                setTimeout(() => {
                    setErrors([])
                }, 5000)
            }) 
        }
    }

    function includeQueryParams(copyFrom){
        var cloneForm = {...copyFrom};
        for (const [key, value] of Object.entries(objectQueryParams)) {
            cloneForm[key] = value;
        }
        return cloneForm;
    }
    return(
        <Modal 
            open={show}
            onClose={handleClose}
        >
            <div className="manage-inputs">
            {(loading && action === "edit") && <Loader absoluteFlex={true}/>}
                <div className="manage-inputs-header">
                    <h6>{t("Edit Field")}</h6>
                    {errors && errors.map(err => {
                        return (
                            <div className="alert-inside-modal" key={err}>
                                <Alert  severity="error" className="custom-error-alert">{err}</Alert>
                            </div>
                        )
                    })}
                </div>
                <div className="manage-inputs-body custom-scrollbar-vertical">
                  <Accordion expanded={value === '1'} onChange={handleChange('1')}>
                    <AccordionSummary>
                        {t("Form Field Label")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <FieldLabel form_fields={action === "edit" ? form_fields : undefined}/>
                    </AccordionDetails>
                  </Accordion>
                  {system !== true  &&
                     <Accordion expanded={value === '2'} onChange={handleChange('2')}>
                     <AccordionSummary>
                         {t("Form Field Display Options")}
                     </AccordionSummary>
                     <AccordionDetails>
                         <FieldDetails form_fields={action === "edit" ? form_fields : undefined} show={showReviewCheck}/>
                     </AccordionDetails>
                   </Accordion>
                  }
                  {system !== true &&
                    <Accordion expanded={value === '3'} onChange={handleChange('3')}>
                        <AccordionSummary>
                            {t("Form Fields Validation Rules")}
                        </AccordionSummary>
                        <AccordionDetails>
                            <FieldValidationRules form_fields={action === "edit" ? form_fields : undefined}/>
                        </AccordionDetails>
                    </Accordion>
                  }
                  
                {system !== true && input_field_type === 'rating' && 
                    <Accordion expanded={value === '4'} onChange={handleChange('4')}>
                        <AccordionSummary>
                            {t("Redirect Rules")}
                        </AccordionSummary>
                        <AccordionDetails>
                            <FieldRedirect form_fields={action === "edit" ? form_fields : undefined}/>
                        </AccordionDetails>
                  </Accordion>
                }
                </div>
                <div className="manage-inputs-footer">
                    <button className="button-cancel-grey" onClick={() => handleClose()}>{t("Cancel")}</button>
                    <StyledLoadingButton
                        style={{paddingLeft: loadingBtn && "30px"}}
                        onClick={() => action === "edit" ? editForm() : buildForm()}
                        loading={loadingBtn}
                    >
                        {t("Save Changes")}
                    </StyledLoadingButton>
                </div>
            </div>
        </Modal>
    )
}
 export default AddFields