import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { paths } from "./components/Admin/helpers/sidebarElements";
import i18n from "./i18n";
//My Components
import AccreditationSignUp from "./components/Accredtiation/accreditation-signup";
import Home from "./components/Home/Home";
import Category from "./components/Categories/Category";
import About from "./components/About/About";
import PageNotFound from "./components/PageNotFound";
import ScrollToTop from "./components/Helpers/scrollToTop";
import SignUp from "./components/SignUp/SignUp";
import LocationForm from "./components/SignUp/locationForm";
//Admin
import AutopilotAi from "./components/Admin/Components/AutoPilot/AutopilotAi";
import SettingsAutopilot from "./components/Admin/Components/AutoPilot/SettingsAutopilot";
import Admin from "./components/Admin/Components/Dashboard/admin";
import Reviews from "./components/Admin/Components/ManageReviews/reviews";
import Tags from "./components/Admin/Components/tags";
import Sources from "./components/Admin/Components/source";
import Review from "./components/Admin/Components/ReviewDetails/review";
import Account from "./components/Admin/Components/Account/account";
import userManagement from "./components/Admin/Components/Account/userManagement";
import ReviewPage from "./components/Admin/Components/Tools/Reviews/ReviewPage";
import Reputation from "./components/Admin/Components/Locations/Reputation/Reputation"
import Competitor from "./components/Admin/Components/Competitors/competitor";
import AnalysisReport from "./components/Admin/Components/Analysis_Report/AnalysisReport";
import QrCode from "./components/Admin/Components/Tools/QrCode/QrCode";
import Invoices from "./components/Admin/Components/Account/Invoices/invoices";
import Subscription from "./components/Admin/Components/Subscription/subscription";
//Survey
import Forms from "./components/Survey/forms";
import FormDetails from "./components/Survey/form-details";
import HomeOrigin from "./components/HomeOrigin/HomeOrigin";
import Form from "./components/Survey/Client/form";
import ThankYou from "./components/Survey/Client/thankYouPage";
import FindKeywords from "./components/FindEngine/FindKeywords";
import TokenControl from "./components/TokenControl/TokenControl";
import UnAuthorize from "./components/UnAuthorized";
import ProtectedRoute from "./components/Helpers/protectedRoute";
import Navbar from "./components/Admin/Navbar";
import Sidebar from "./components/Admin/Sidebar";
import Frame from "./components/Admin/Components/Dashboard/frame";
import UserManagement from "./components/Admin/Components/Account/userManagement";
import Notifications from "./components/Admin/Components/Account/notifications";
import AccountAddUser from "./components/Admin/Components/Account/accountAddUser";
import AddUser from "./components/Admin/Components/Account/AddUser";
import LocationSettings from "./components/Admin/Components/Locations/LocationInformation/LocationSettings";
import LocationSettingsEdit from "./components/Admin/Components/Locations/LocationInformation/LocationSettingsEdit";
import Listing from "./components/Admin/Components/Locations/Listing/Listing";
import SourceScore from "./components/Admin/Components/Locations/Listing/SourceScore";
import CouponList from "./components/Admin/Components/Tools/Coupons/CouponList";
import Banner from "./components/Admin/parts/banner";
import Connections from './components/Admin/Components/Connections/Connections';
import ConnectionsSettings from './components/Admin/Components/Connections/ConnectionsSettings';
import { useSelector, useDispatch } from "react-redux";
import { checkDemo, checkCancel, fetchPermissions, clearFetchPermissions } from "./redux/actions/admin-index";
import RevuShare from "./components/Admin/Components/Tools/RevuShareWeb/RevuShare";
import GroupPage from "./components/Admin/Components/Tools/Group-Page/GroupPage";
import QuickReplies from "./components/Admin/Components/Tools/QuickReplies/QuickReplies";
import Support from "./components/Admin/Components/Support/Support";

import { Login } from "./components/Login";
import LogOut from "./components/Logout";
import Social from "./components/Admin/Social/Social";
import MyDevCrm from "./components/Admin/Components/IncludedPlatforms/MyDevCrm";
import SendBat from "./components/Admin/Components/IncludedPlatforms/Sendbat";
import Urless from "./components/Admin/Components/IncludedPlatforms/Urless";
import Morsix from "./components/Admin/Components/IncludedPlatforms/Morsix";
import Claritask from "./components/Admin/Components/IncludedPlatforms/Claritask";
import Claritick from "./components/Admin/Components/IncludedPlatforms/Claritick";
import Convosio from "./components/Admin/Components/IncludedPlatforms/Convosio";
import Ipaymer from "./components/Admin/Components/IncludedPlatforms/Ipaymer";
import MyDevSite from "./components/Admin/Components/IncludedPlatforms/MyDevSite";
import Banner2 from "./components/Admin/parts/banner2";
import GroupDetails from "./components/Admin/Components/Tools/Group-Page/GroupDetails";
import GroupList from "./components/Admin/Components/Tools/Group-Page/GroupList";
import AddGroupDetails from "./components/Admin/Components/Tools/Group-Page/AddGroupPage";
import ThankYouMsg from "./components/Helpers/ThankYouMsg";
import GamifyReviews from "./components/Admin/Components/GamifyReviews/GamifyReviews";
import TermsConditions from "./components/TermsConditions";
import PageVisits from "./components/Admin/Components/Tools/Page-Visits/pageVisits";
import IreviewFooter from "./components/IreviewFooter";
import Flagging from "./components/Admin/Flagging/Flagging";
import Downloads from "./components/Admin/Components/Account/Downloads/downloads";


const App = () => {
  const divRef = useRef(null);
  const dispatch = useDispatch()

  const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)
  const demoReducer = useSelector(state => state.demoReducer.demo)
  const cancelReducer = useSelector(state => state.cancelReducer.cancel)

  const [height, setHeight] = useState(0)
  const [days, setDays] = useState()
  const user = useSelector(state => state.getUserInfo.user)
  const location = useSelector(state => state.locationSelected.location)
  const groupSelected = useSelector(state => state.groupSelected.group)

  useEffect(() => {
    if(location || groupSelected){
      dispatch(clearFetchPermissions())
      dispatch(fetchPermissions(location, groupSelected))
    }
  }, [location, groupSelected])

  //This checks if user location is demo or not
  useEffect(() => {
    if (user) {
      let location_details = user && user.user.locations.filter(loc => loc.id == location)
      if(location === "all"  && user.user.primary_location){
        let locationPrimary = user && user.user.locations.filter(loc => loc.id == user.user.primary_location)
          if(locationPrimary[0]?.is_demo === 1){
            dispatch(checkDemo(true))
            localStorage.setItem("demo", true)
            setDays(locationPrimary[0].demo_days)
          } else {
            dispatch(checkDemo(false))
            localStorage.setItem("demo", false)
          }
      } else {
        if(location_details[0] && location_details[0].is_demo === 1 && location_details[0].acc_status === 1){
          dispatch(checkDemo(true))
          dispatch(checkCancel(false))

          localStorage.setItem("demo", true)
          localStorage.setItem("cancel", false)

          setDays(location_details[0].demo_days)

        } else if (location_details[0] && (location_details[0].acc_status === 0 && location_details[0].is_demo === 0)){
          dispatch(checkDemo(false))
          dispatch(checkCancel(true))

          localStorage.setItem("demo", false)
          localStorage.setItem("cancel", true)
        } else {
          
          dispatch(checkDemo(false))
          dispatch(checkCancel(false))
          localStorage.setItem("demo", false)
          localStorage.setItem("cancel", false)
        }
      }
      if(user.user.role !== "user"){
        window.location.href = "https://accounts.kore.co/login?platform=o4124n1on4c1oc4n124nc1adsdlwoooq"
      }
    }
  }, [user, location])

  return (
    <div className={`App ${(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) ? "banner" : ""}`}>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomeOrigin />} />
            <Route path="/mainhome" element={<Home />} />
            <Route path="/create" element={<SignUp />} />
            <Route path="/create/location" element={<LocationForm />} />
            <Route path="/mainhome" element={<Home />} />
            <Route path="/categories/:id" element={<Category />} />
            <Route path="/find/:search_text" element={<FindKeywords />} />
            <Route path="/company/:name" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/terms" element={ <TermsConditions />}/>
            <Route path="/logout" element={<ProtectedRoute><LogOut /></ProtectedRoute>} />
            <Route path="/accreditation" element={ <AccreditationSignUp />}/>
            <Route path="/flag" element={<Flagging />} />
            <Route path="app/admin" element={
              <ProtectedRoute title="Dashboard" permissions={"dashboard"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer))  && user && <div ref={divRef} id="banner">
                      <Banner setHeight={setHeight} days={days} />
                      {/* <Banner2 setHeight={setHeight} /> */}
                    </div>
                  }
                  <Navbar height={height} title="Dashboard" all={true} groups={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Admin />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/reviews/list" element={
              <ProtectedRoute title="Manage Reviews" permissions={"manageReviews"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Manage Reviews" all={true} groups={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Reviews />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/autopilot/details" element={
              <ProtectedRoute title="Autopilot AI" permissions={"manageAutoPilot"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Manage Reviews" all={true} groups={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <AutopilotAi/>
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/autopilot/settings" element={
              <ProtectedRoute title="Autopilot Settings" permissions={"settingsAutoPilot"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Manage Reviews" all={true} groups={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <SettingsAutopilot/>
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/reviews/tags" element={
              <ProtectedRoute title="Tags" permissions={"manageTags"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Manage Tags" all={true} groups={true} />
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Tags />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/reviews/sources" element={
              <ProtectedRoute title="Sources" permissions={"manageSources"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Review Sources" all={true} groups={true}  />
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Sources />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/reviews/connections" element={
              <ProtectedRoute title="Connections" permissions={"manageConnections"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Connections" all={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Connections />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/reviews/connections/:id" element={
              <ProtectedRoute title="Connection Settings" permissions={"manageConnections"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Connections" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <ConnectionsSettings />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/reviews/list/:id" element={
              <ProtectedRoute title="Review Details" permissions={"manageReviews"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Manage Reviews" all={true} />
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Review />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/tools/coupons" element={
              <ProtectedRoute title="Coupons" permissions={"couponsOptIns"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Manage Reviews" all={true} />
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <CouponList />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />

            <Route path="/app/admin/tools/review" element={
              <ProtectedRoute title="Review Page" permissions={"reviewPage"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Review Page" all={true} groups={true} />
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <ReviewPage />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/tools/share" element={
              <ProtectedRoute title="RevuShare" permissions={"revuShare"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="RevuShare - Web" all={true} groups={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <RevuShare />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/tools/qrcode" element={
              <ProtectedRoute title="QrCode" permissions={"qrCode"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Qr Code" all={true} groups={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <QrCode />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/tools/group-page" element={
              <ProtectedRoute title="Group List" permissions={"groupPage"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Group Page" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <GroupList />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/tools/group-page/add" element={
              <ProtectedRoute title="Group List"  permissions={"groupPage"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Group Page" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <AddGroupDetails />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/tools/group-page/:id" element={
              <ProtectedRoute title="Group Page"  permissions={"groupPage"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Group Page" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <GroupPage />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/tools/quick-replies" element={
              <ProtectedRoute title="Quick Replies"  permissions={"quickReplies"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Quick Replies" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <QuickReplies />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/tools/page-visits" element={
              <ProtectedRoute title="Page Visits"  permissions={"pageVisits"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Quick Replies" all={true} groups={true} disable_location={false}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <PageVisits />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/gamify" element={
                <ProtectedRoute title="Gamify Reviews" permissions={"gamifyReviews"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Connect Automation" all={false} disable_location={false}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <GamifyReviews />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route path="/app/admin/support" element={
              <ProtectedRoute title="Support" permissions={"support"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Support" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Support />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/locations/reputation" element={
              <ProtectedRoute title="Reputation" permissions={"reputation"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Reputation" all={true} groups={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Reputation />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/locations/listing" element={
              <ProtectedRoute title="Listing" permissions={"locationListings"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Listings" all={true} groups={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Listing />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/locations/listing/:id" element={
              <ProtectedRoute title="Source Score" permissions={"locationListings"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Listing" all={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <SourceScore />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/locations/information" element={
              <ProtectedRoute title="Location Settings" permissions={"locationsInformation"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Location Information" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <LocationSettings />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/locations/information/:id" element={
              <ProtectedRoute title="Location Settings Edit" permissions={"locationsInformation"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Location Information" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <LocationSettingsEdit />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/app/admin/analysis_report" element={
              <ProtectedRoute title="Analysis Report" permissions={"analysis_report"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Analysis Report" all={true} groups={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <AnalysisReport />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />

            <Route path="/app/admin/competitor_analysis" element={
              <ProtectedRoute title="Competitor Analysis" permissions={"competitor_analysis"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Competitor Analysis" all={true} groups={true} />
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Competitor />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/dashboard/frame" element={<Frame />} />

            <Route path="/forms/:id" element={<Form />} />
            <Route
              path="/app/admin/tools/forms"
              element={
                <ProtectedRoute title="Forms"  permissions={"formsSurveys"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Forms" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <Forms />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/forms"
              element={
                <ProtectedRoute permissions={"formsSurveys"}>
                  <Forms />
                  <IreviewFooter/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/forms/:id"
              element={
                <ProtectedRoute permissions={"formsSurveys"}>
                  <FormDetails />
                  <IreviewFooter/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/tools/forms/:id"
              element={
                <ProtectedRoute title="Form Details" permissions={"formsSurveys"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Forms" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <FormDetails />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            {/* <Route
                  path="/survey"
                  element={
                    <ProtectedRoute user={getLocalUser()?.token}>
                      <SurveyDashboard/>
                    </ProtectedRoute>
                  }
                /> */}
            <Route path="app/admin/account/settings" element={
              <ProtectedRoute title="Account" permissions={"myAccount"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="My Account" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Account />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/login/admin/account/settings" element={<TokenControl />} />

            <Route path="app/admin/account/management" element={
              <ProtectedRoute title="User Management" permissions={"userManagement"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} all={true} groups={true} title="User Management"/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <UserManagement />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />

            <Route path="app/admin/account/management/:id" element={
              <ProtectedRoute title="Add Account User" permissions={"userManagement"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height}  title="User Management" all={true} groups={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <AccountAddUser />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/account/management/addUser" element={
              <ProtectedRoute title="Add user" permissions={"userManagement"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height}  title="Add User" all={true} groups={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <AddUser />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="/login/admin/account/addUser" element={<TokenControl />} />

            <Route path="app/admin/account/notifications" element={
              <ProtectedRoute title="Notifications" permissions={"notifications"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Notifications" all={true} groups={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Notifications />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/account/invoices" element={
              <ProtectedRoute title="Invoices" permissions={"invoices"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} all={true} title="Invoices" disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Invoices />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/account/downloads" element={
              <ProtectedRoute title="Downloads" permissions={"downloads"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} all={true} title="Downloads" disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Downloads />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />
            <Route path="app/admin/subscription" element={
              <ProtectedRoute title="Subscription" permissions={"subscription"}>
                <>
                  {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                  <Navbar height={height} title="Subscription" all={true} disable_location={true}/>
                  <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                    <Subscription />
                    <IreviewFooter/>
                  </div>
                  <Sidebar height={height} />
                </>
              </ProtectedRoute>
            }
            />

            <Route
              path="/app/admin/goto/:id"
              element={
                <ProtectedRoute title="Social Media Automation" permissions={"social_automation"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Social" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <Social />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />

            <Route
              path="/app/admin/goto/crm"
              element={
                <ProtectedRoute permissions={"included_platform"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="MyDev CRM" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <MyDevCrm />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/goto/usender"
              element={
                <ProtectedRoute permissions={"included_platform"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Sendbat" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <SendBat />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/goto/urless"
              element={
                <ProtectedRoute permissions={"included_platform"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Urless" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <Urless />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/goto/morsix"
              element={
                <ProtectedRoute permissions={"included_platform"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Morsix" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <Morsix />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/goto/claritask"
              element={
                <ProtectedRoute permissions={"included_platform"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Claritask" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <Claritask />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/goto/claritick"
              element={
                <ProtectedRoute permissions={"included_platform"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Claritick" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <Claritick />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/goto/convosio"
              element={
                <ProtectedRoute permissions={"included_platform"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Convosio" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <Convosio />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/goto/ipaymer"
              element={
                <ProtectedRoute permissions={"included_platform"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="Ipaymer" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <Ipaymer />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/admin/goto/site"
              element={
                <ProtectedRoute permissions={"included_platform"}>
                  <>
                    {(JSON.parse(impersonateReducer) || JSON.parse(demoReducer) || JSON.parse(cancelReducer)) && user && <div ref={divRef} id="banner"><Banner setHeight={setHeight} days={days} /></div>}
                    <Navbar height={height} title="MyDev Site" all={true} disable_location={true}/>
                    <div className="main-container" style={{ minHeight: `calc(100vh - ${height + 100}px)` }} >
                      <MyDevSite />
                      <IreviewFooter/>
                    </div>
                    <Sidebar height={height} />
                  </>
                </ProtectedRoute>
              }
            />

            <Route path="/login/admin/account/notifications" element={<TokenControl />} />
            <Route path="/thank-you" element={<ThankYou />} />
            {/* <Route path="/thank-you" element={<ThankYouMsg />} /> */}

            <Route path="/admin/login" element={<TokenControl />} />

            <Route path="/login/admin" element={<TokenControl />} />
            <Route path="/login/admin/reviews" element={<TokenControl />} />
            <Route path="/login/admin/reviews/list/:id" element={<TokenControl />} />
            <Route path="/login/admin/reviews/tags" element={<TokenControl />} />
            <Route path="/login/admin/reviews/sources" element={<TokenControl />} />

            <Route path="/login/admin/locations/reputation" element={<TokenControl />} />
            <Route path="/login/admin/location_information" element={<TokenControl />} />
            <Route path="/login/admin/locations/listing" element={<TokenControl />} />
            <Route path="/login/admin/locations/listing/:id" element={<TokenControl />} />

            <Route path="/login/admin/reviews/sources" element={<TokenControl />} />

            <Route path="/login/admin/analysis_report" element={<TokenControl />} />

            <Route path="/login/admin/tools/review" element={<TokenControl />} />
            <Route path="/login/admin/account/management" element={<TokenControl />} />
            <Route path="/login/admin/subscription" element={<TokenControl />} />

            <Route path="/401" element={<UnAuthorize />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
