import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { createTheme, ThemeProvider, Dialog, DialogTitle  } from "@mui/material";
import Main from "../Main";
import AddTag from "../modals/tags/add-tag";
import EditTag from "../modals/tags/edit-tag";
import { LoadingButton } from "@mui/lab";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchLocationTags, loadingTables } from "../../../redux/actions/admin-index";
import Loading from "../../TokenControl/parts/Loading";
import AlertComponent from "../../Helpers/Alert";
import { getLocalUser } from "../../TokenControl/parts/useLocalUser";
import axios from "axios";
import { baseUrlDev } from "../../Helpers/baseUrl";
import { showAlert } from "../../../redux/actions";
import Loader from "../helpers/loader";
import { showYoutubeContent } from "../../../redux/actions/admin-index";
import Header from "../../Helpers/header";
import { useTranslation } from "react-i18next";

const TagsTable = ({open, setOpen}) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [tag, setTag] = useState()
    const [tagId, setTagId] = useState()
    const [deleteLogging, setDeleteLogging] = useState(false)
    const [tags, setTags] = useState()

    const location = useSelector(state => state.locationSelected.location)
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    const tagsReducer = useSelector(state => state.getLocationTags.tags)
    const loading = useSelector(state => state.getLocationTags.loading)

    let varib = 1
    useEffect(() => {
        dispatch(loadingTables())
        if(selected && (!location || location == "all")){
            dispatch(fetchLocationTags(selected))
        }
    }, [selected])

    useEffect(() => {
        dispatch(loadingTables())
        if (location && location !== "all") {
            dispatch(fetchLocationTags(location))
        }
    }, [location])

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ROTS4l8tqiM?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        if(tagsReducer){
            setTags(tagsReducer.data)
        }
    }, [tagsReducer])


    if(tags === undefined){
        return(
            <Loader absoluteFlex={true}/>
        )
    }

    const getMuiTheme = () =>
        createTheme({
        components: {
        }
    });

    const deleteFunc = (loc) => {
        setDeleteLogging(true)
        axios.post(`${baseUrlDev}locations/${loc}/tags/delete`, {tag_id: tagId}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            setDeleteLogging(false)
            setOpenConfirmation(false)
            setTags(tags.filter(el => el.id !== tagId))
            dispatch(showAlert(true, "Tag Deleted Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        })
    }

    const deleteTag = (loc) => {
        if(selected && (!location || location == "all")){
            deleteFunc(selected)
        }
        if (location && location !== "all") {
            deleteFunc(location)
        }
    }
    const MAX_ITEMS_TO_DISPLAY = 5;

    const columns = [
        { 
            name: "Id", 
            options: {
                filter: false,
                viewColumns: false,
                display: false,
                sort: false,
            }
        },
        { 
            name: t("Name"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Color"), 
            options: { 
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <span className="tag-color" style={{backgroundColor: value}}></span>
                ),
                customHeadLabelRender: (columnMeta) => {
                   return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Keywords"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let items = value.length > 0 && (value.length > MAX_ITEMS_TO_DISPLAY ? value.slice(0, MAX_ITEMS_TO_DISPLAY).join(" , ") + ", ..." : value.join(' , '))
                    return <p className="tags-keywords-table">{items}</p>
                },
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Created At"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return  <p className="custom-text-datatable">{tableMeta.rowData[5] ? "N/A" : value}</p>
                },
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: "", 
            options: {
                filter: false,
                viewColumns: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if(!value){
                        return (
                            <div className="mydev-datatable-actions">
                                <button className="edit-lead-btn" onClick={() => {
                                    setTag(tableMeta.rowData)
                                    setOpenEdit(true)
                                }}>
                                    <span className="icon-mydevicon-edit-bordered"></span>
                                    {t("Edit")}
                                </button>
                                <button className="remove-lead" onClick={() => {
                                        setTagId(tableMeta.rowData[0])
                                        setOpenConfirmation(true)
                                    } }>
                                    <span className="icon-rounded-trash"></span>
                                    {t("Delete")}
                                </button>
                            </div>
                        )
                    }
                }
            }
        },
    ];
    const data = tags.map(tag => {
        return [tag.id, tag.name, tag.color,  tag.keywords, tag.created_at, tag.system]
    }).reverse()

    const options = {
        search: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        download: true,
        print: true,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: "checkbox",     
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        downloadOptions: {
            filterOptions: {
              useDisplayedColumnsOnly: true,
            },
        },
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onRowsDelete: (rowsDeleted) => {
            const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex][0]); // array of all ids to to be deleted
           console.log(idsToDelete)
        }
    };


    return(
        <>
            <Dialog onClose={() => setOpenConfirmation(false)} open={openConfirmation}>
                <div style={{padding: "20px"}}>
                    <DialogTitle>{t("Are you sure you want to delete this tag")}?</DialogTitle>
                </div>
                <div className="flex-btns">
                    <LoadingButton
                        style={{paddingLeft: loading && "30px"}}
                        className="delete-btn"
                        onClick={() => deleteTag()}
                        loadingIndicator={t("Deleting") + "..."}
                        loading={deleteLogging}
                    >
                        {!deleteLogging && t("Delete")}
                    </LoadingButton>
                    <button onClick={() => {
                        setOpenConfirmation(false)
                        setDeleteLogging(false)
                    }}>{t("Cancel")}</button>
                </div>
            </Dialog>
            <AlertComponent/>
            <AddTag open={open} setOpen={setOpen} location={location}/>
            <EditTag openEdit={openEdit} setOpenEdit={setOpenEdit} tag={tag} location={location} tags={tags} setTags={setTags}/>
            <div className="new-datatable">
                {loading && <Loader/>}
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
        </>
      )
}
export default TagsTable