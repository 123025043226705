import React from "react"
import { Skeleton, Grid } from "@mui/material"

const LocationInformationSkeleton = () => {
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="Skeleton-text flex-header-container-title">
                        <h1>Locations</h1>                    
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: "40px"}}>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: "40px"}}>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "50%", height: "100px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "50%", height: "100px", transform: "unset"}} />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop: "40px"}}>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton style={{width: "100%", height: "44px", transform: "unset"}} />
                </Grid>
            </Grid>
        </div>
    )
}
export default LocationInformationSkeleton