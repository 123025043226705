import React, { useState } from 'react';

import { Grid, FormControl, MenuItem, Select } from '@mui/material';
import MySelect from '../../../helpers/mySelect';
import {IoIosRemoveCircleOutline} from "react-icons/io";
import {AiOutlineClose} from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
// import RemoveIcon from '../../qrCode-images/remove-white-thin.svg';
// import Image from '../../images/information-logo.svg';

const Step2 = ({ handleNext, handleBack, activeStep, handleFileChange, handleFilesChange, handleDeleteFile, formData, logoPrev, errors, setFormData }) => {
    const {t} = useTranslation()
    const handleImageProps = (e) => {
        let {value, name} = e.target
        setFormData({...formData, [name]: value})
    }
    return (
        <div className="form-step">
            <div className="steps-content">
                <div className="steps-content-details">
                    <div>
                        <label htmlFor="">{t("Logo")}</label>
                        {/* <input type="file" id='logo1' name='image' className='hidden' onChange={(e) => handleFileChange(e, setLogo)} /> */}
                        <input type="file" id='logo1' className='hidden' onChange={handleFileChange} />
                        <label htmlFor="logo1" className='larg-mids-input image-upload text-center'>
                            {
                                logoPrev ? <img src={logoPrev} style={{ height: '120px', objectFit: 'contain', maxWidth: '100%' }} /> : t('Add new image')
                            }
                        </label>
                        <p className='step_error_text'>{errors?.image}</p>
                    </div>

                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={6}>
                            <label htmlFor="">{t("Size")}</label>
                            <FormControl fullWidth>
                                <Select
                                    id="input-type-one-select"
                                    label="position"
                                    name="logo_size"
                                    value={formData?.logo_size}
                                    onChange={(e) => handleImageProps(e) }
                                    input={<MySelect />}
                                >
                                    <MenuItem value={'25'}>25%</MenuItem>
                                    <MenuItem value={'50'}>50%</MenuItem>
                                    <MenuItem value={'100'}>100%</MenuItem>
                                    <MenuItem value={'125'}>125%</MenuItem>
                                    <MenuItem value={'150'}>150%</MenuItem>
                                    <MenuItem value={'200'}>200%</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} xl={6}>
                            <label htmlFor="">{t("Align")}</label>
                            <FormControl fullWidth>
                                <Select
                                    id="input-type-one-select"
                                    label="position"
                                    name="logo_align"
                                    value={formData?.logo_align}
                                    onChange={(e) =>  handleImageProps(e) }
                                    input={<MySelect />}
                                >
                                    <MenuItem value={'left'}>{t("Left")}</MenuItem>
                                    <MenuItem value={'center'}>{t("Center")}</MenuItem>
                                    <MenuItem value={'right'}>{t("Right")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>

                <div className="steps-content-details">
                    <div>
                        <label htmlFor="">{t("Insert Image")} ({formData?.images?.length} of 3)</label>
                        <input id='filesUpload' type="file" className='hidden' multiple onChange={handleFilesChange} />
                        <label htmlFor="filesUpload" className='larg-mids-input image-upload' style={{textAlign: 'center'}}>{t('Add new image')}</label>
                    </div>
                    <div id='outputImg'>
                        {
                            formData?.images?.map((file, index) => (
                                <div className="oneimgCoin" key={index}>
                                    <span className="removeimage">
                                        <AiOutlineClose onClick={() => handleDeleteFile(index)}/>
                                    </span>
                                    <img src={URL.createObjectURL(file)} className='oneimg' alt="" />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className="btns-group d-flex justify-content-between">
                <button
                    className='btn btn-prev'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    {t("Back")}
                </button>
                <button className='btn btn-next' onClick={handleNext}>{t("Next")}</button>
            </div>
        </div>
    )
}

export default Step2
