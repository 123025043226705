import React, {useState} from "react"
import { Modal, Slide, Alert } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { fetchAccountInvoices } from "../../../../redux/actions/admin-index"
import { useDispatch } from "react-redux"
import axios from "axios"
import { useTranslation } from "react-i18next"

let initialText = "We're excited to offer you a discount on your next purchase. Simply use our coupon code at checkout"

const Coupon = ({open, setOpen, id, invoice}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [subject, setSubject] = useState('')
    const [code, setCode] = useState('')
    const [message, setMessage] = useState(initialText)
    const [errMessage, setErrMessage] = useState()

    const handleClose = () => {
        setOpen(false)
        setMessage(initialText)
        setSubject('')
        setCode('')
        setLoading(false)
        setErrMessage('')
    }

    const sendCoupon = () => {
        let data = {
            subject: subject,
            code: code,
            message
        }
        setLoading(true)
        axios.post(`${baseUrlDev}reviews/share/${id}/coupon`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }}).then(() => {
            setLoading(false)
            if(invoice){
                dispatch(fetchAccountInvoices())
            }
            handleClose()
        }).catch(err => {
            setLoading(false)
            let status = err.response.status
            if(status === 422){
                setErrMessage(err.response.data.message)
            }
        })

    }
    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-coupon-title"
            aria-describedby="modal-coupon-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className='custom-modal-dialog'>
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>{t("Share Coupon")}</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-div-container custom-scrollbar-vertical">
                                <div className="field-container">
                                    <label>{t("Subject")}</label>
                                    <input type="text" placeholder={t("Subject")} onChange={(e) => setSubject(e.target.value)}/>
                                </div>
                                <div className="field-container">
                                    <label>{t("Coupon Code")}</label>
                                    <input type="text" placeholder={t("Coupon Code")} onChange={(e) => setCode(e.target.value)}/>
                                </div>
                                <div className="field-container">
                                    <label>{t("Message")}</label>
                                    <textarea className="custom-scrollbar-vertical" value={message} placeholder={t("Message")} onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>  
                                <div className="couponPreview field-container">
                                    <div>
                                        <h6>{subject}</h6>
                                        <h6>{message}</h6>
                                        <h6>Your Coupon Code is <span>{code ? code : "__"}</span></h6>
                                    </div>
                                </div>
                                {errMessage && 
                                    <div style={{padding: "20px"}}>
                                        <Alert variant="outlined" severity="error" className="custom-error-alert">
                                            {errMessage}
                                        </Alert>
                                    </div> 
                                }
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <LoadingButton
                                style={{paddingLeft: loading && "30px"}}
                                className="save-btn"
                                onClick={() => sendCoupon()}
                                loading={loading}
                            >
                                {t("Send Coupon")}
                            </LoadingButton>
                            <button className="close-btn" onClick={() => handleClose()}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default Coupon