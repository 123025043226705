import '../../../../../assets/information.css';
import React, {useEffect} from 'react';
import LocationDatatable from './LocationDatatable';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLocationsInformation, showYoutubeContent } from "../../../../../redux/actions/admin-index"
import Header from '../../../../Helpers/header';
import { useTranslation } from 'react-i18next';
import useHandleDisable from '../../../helpers/disableSelect';

const LocationSettings = () => {
    const handleDisable = useHandleDisable()
    const dispatch = useDispatch()
    const [t] = useTranslation()

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/gW477mY0p4M?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        dispatch(fetchLocationsInformation())
        handleDisable()
    }, [])

    const locations = useSelector(state => state.getLocationsInformation.location_informations)
    return (
        <div className='location_settings_page'>
            <Header title="Locations" subtitle="Location Settings"/>
            <div>
                <LocationDatatable locations={locations}/>
            </div>
        </div>
    )
}

export default LocationSettings;


