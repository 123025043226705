import { styled, InputBase} from "@mui/material";

const TimeSelect = styled(InputBase)(({ theme }) => ({
    width: "150px",
    '& label': {
        backgroundColor: "#fff",
    },
    '& .MuiInputBase-input': {
        borderRadius: '4px',
        position: 'relative',
        backgroundColor: "#fff",
        border: `1px solid rgba(166,178,205,.25)`,
        fontSize: 14,
        height: "45px",
        boxSizing: "border-box",
        fontWeight: 400,
        fontFamily: "Poppins-Regular",
        padding: '0 26px 0px 12px',
        color: "#252C32",
        lineHeight: "3",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
    '& .MuiSvgIcon-root' :{
        color: "#252C32"
    },
    '& .MuiInputLabel-formControl':{
        backgroundColor: "#fff",
    }
}));
export default TimeSelect