import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { IoMdTrash } from "react-icons/io"
import { fetchForms, fetchFormDetails, formDetailsClear } from "../../redux/actions"
import { Skeleton } from "@mui/material"
import EditForm from "./modal/edit-form-modal"
import { FiEdit3 } from "react-icons/fi"
import DeleteFormDialog from "./Confirmations/delete_form_dialog"
import { useTranslation } from "react-i18next"

const FormList = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [form_id, setFormId] = useState()
    const [f_id, setId] = useState()
    const {t} = useTranslation()
    useEffect(() => {
        dispatch(fetchForms())
    }, [])

    const forms = useSelector(state => state.getForms.forms)

    const deleteForm = (id) => {
        setFormId(id)
        setOpen(true)
    }

    const openEdit = (id) => {
        dispatch(formDetailsClear())
        dispatch(fetchFormDetails(id))
        setId(id)
        setShowEdit(true)
    }

    let oldIrevu = localStorage.getItem('oldIrevu')
    let hostname = window.location.hostname

    return (
        <div className="form-list">
            <EditForm showEdit={showEdit} setShowEdit={setShowEdit} form_id={f_id} />
            <DeleteFormDialog open={open} setOpen={setOpen} form_id={form_id} />
            {forms === undefined ?
                <>
                    <Skeleton height={100} width="100%" style={{ marginBottom: "10px" }} />
                    <Skeleton height={100} width="100%" style={{ marginBottom: "10px" }} />
                    <Skeleton height={100} width="100%" style={{ marginBottom: "10px" }} />
                </>
                :
                forms.length > 0 ?
                    forms.map(form => {
                        return (
                            <div className="form-list-item" key={`form${form.id}`}>
                                <div>
                                    <Link to={JSON.parse(oldIrevu) ? `/app/admin/forms/${form.id}` : `/app/admin/tools/forms/${form.id}`}>{form.name}</Link>
                                </div>
                                <div className="form-actions">
                                    <button className="edit-lead-btn form_list_edit" onClick={() => openEdit(form.id)}>
                                        <span className="icon-mydevicon-edit-bordered"></span>
                                    </button>
                                    {/* <FiEdit3 onClick={() => openEdit(form.id)}/> */}
                                    {/* <IoMdTrash onClick={() => deleteForm(form.id)}/> */}

                                    <button className="remove-lead form_list_remove" onClick={() => deleteForm(form.id)}>
                                        <span className="icon-rounded-trash"></span>
                                    </button>

                                </div>
                            </div>
                        )
                    })
                    : <div className="nothing-to-prev">{t("Nothing to show")}</div>
            }

        </div>
    )
}
export default FormList