import React from "react"
import { Skeleton, IconButton, Rating } from "@mui/material"
import { IoMdInformationCircleOutline } from "react-icons/io"
import LightTooltip from "../../helpers/lightTooltip"
import { CustomLinearProgressAiBlue } from "../../helpers/customLinearProgress"
import { useTranslation } from "react-i18next"

const NewRatingBreakdownPercentage = ({breakdown}) => {
    const {t} = useTranslation()
    return(
        <div className="rating-breakdown-ai">
            <div>
                <span>5 <img src={"/admin-images/filled-icon.svg"} /></span>
                <div style={{flex: "auto"}}>
                    <CustomLinearProgressAiBlue variant="determinate" value={parseInt(Object.entries(breakdown)[0][1].percentage)}/>
                </div>
                <h6>{Object.entries(breakdown)[0][1].count}</h6>
            </div>
            <div>
                <span>4 <img src={"/admin-images/filled-icon.svg"} /></span>
                <div style={{flex: "auto"}}>
                    <CustomLinearProgressAiBlue variant="determinate" value={parseInt(Object.entries(breakdown)[1][1].percentage)}/>
                </div>
                    <h6>{Object.entries(breakdown)[1][1].count}</h6>
            </div>
            <div>
                <span>3 <img src={"/admin-images/filled-icon.svg"} /></span>
                <div style={{flex: "auto"}}>
                    <CustomLinearProgressAiBlue variant="determinate" value={parseInt(Object.entries(breakdown)[2][1].percentage)}/>
                </div>
                    <h6>{Object.entries(breakdown)[2][1].count}</h6>
            </div>
            <div>
                <span>2 <img src={"/admin-images/filled-icon.svg"} /></span>
                <div style={{flex: "auto"}}>
                    <CustomLinearProgressAiBlue variant="determinate" value={parseInt(Object.entries(breakdown)[3][1].percentage)}/>
                </div>
                    <h6>{Object.entries(breakdown)[3][1].count}</h6>
            </div>
            <div>
                <span>1 <img src={"/admin-images/filled-icon.svg"} /></span>
                <div style={{flex: "auto"}}>
                    <CustomLinearProgressAiBlue variant="determinate" value={parseInt(Object.entries(breakdown)[4][1].percentage)}/>
                </div>
                <h6>{Object.entries(breakdown)[4][1].count}</h6>
            </div>
        </div>
    )
}
export default NewRatingBreakdownPercentage