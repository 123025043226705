import React, {useState, useEffect} from "react"
import MySelect from "../../Helpers/MySelect"
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { AiFillStar } from "react-icons/ai"
import {addRedirectLinks} from "../../../redux/actions"
import {useDispatch, useSelector} from "react-redux"
import { useTranslation } from "react-i18next"

let redirectarray = [
    {rating: 'one', type: 'thank you', input: ''},
    {rating: 'two', type: 'thank you', input: ''},
    {rating: 'three', type: 'thank you', input: ''},
    {rating: 'four', type: 'thank you', input: ''},
    {rating: 'five', type: 'thank you', input: ''}
]

const FieldRedirect = ({requiredInputs, form_fields}) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        one: form_fields && form_fields.data && form_fields.data.redirect_link ?  form_fields.data.redirect_link[0].type : 'thank you',
        two: form_fields && form_fields.data && form_fields.data.redirect_link ?  form_fields.data.redirect_link[1].type : 'thank you',
        three: form_fields && form_fields.data && form_fields.data.redirect_link ? form_fields.data.redirect_link[2].type : 'thank you',
        four: form_fields && form_fields.data && form_fields.data.redirect_link ? form_fields.data.redirect_link[3].type : 'thank you',
        five: form_fields && form_fields.data && form_fields.data.redirect_link ? form_fields.data.redirect_link[4].type : 'thank you',
    })
    const {t} = useTranslation()
    const [showInp, setShowInp] = useState(true)
    useEffect(() => {
        if(form_fields){
            dispatch(addRedirectLinks(JSON.parse
                (JSON.stringify(form_fields && form_fields.data && form_fields.data.redirect_link))
            ))
            redirectarray = (JSON.parse
                (JSON.stringify(form_fields && form_fields.data && form_fields.data.redirect_link))
            )
        } else {
            dispatch(addRedirectLinks(JSON.parse(JSON.stringify(redirectarray))))
        }
    }, [form_fields])

    const r = useSelector(state => state.FormFieldAdded.redirect_link)
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setState({
            ...state,
            [name]: value
        })
        let newArray = [...redirectarray]
        newArray.find(el => el.rating === name).type = value
        redirectarray = newArray
        dispatch(addRedirectLinks(JSON.parse(JSON.stringify(redirectarray))))
    }
    const handleChangeInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        let newArray = [...redirectarray]
        newArray.find(el => el.rating === name).input = value
        redirectarray = newArray
        dispatch(addRedirectLinks(JSON.parse(JSON.stringify(redirectarray))))
    }
    return(
        <div className="field-container-redirect">
            <h6>{t("Choose submit action based on rating")}</h6>
            <div>
                <label><AiFillStar style={{color: '#fdcc0d'}}/></label>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="input-type-one">{t("Select Action")}</InputLabel>
                        <Select
                            name="one"
                            labelId="input-type-one"
                            id="input-type-one-select"
                            value={state.one}
                            label="position"
                            input={<MySelect/>}
                            onChange={handleChange}
                        >
                            <MenuItem value={'thank you'}>{t("Thank You")}</MenuItem>
                            <MenuItem value={'redirect'}>{t("Redirect")}</MenuItem>
                            <MenuItem value={'google-reviews'}>{t("Send to Google Reviews")}</MenuItem>
                            <MenuItem value={'yelp-reviews'}>{t("Send to Yelp Reviews")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {(state.one !== "google-reviews" &&  state.one !== "yelp-reviews") && <input 
                    defaultValue={form_fields && form_fields.data && form_fields.data.redirect_link ?  form_fields.data.redirect_link[0].input : ''}
                    name="one" 
                    type="text" 
                    placeholder={state.one === "redirect" ? t("Redirect Link") : t("Thank you message")} 
                    onChange={(e) => handleChangeInput(e)}
                />}
            </div>
            <div>
                <label>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                </label>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="input-type-two">{t("Select Action")}</InputLabel>
                        <Select
                            name="two"
                            labelId="input-type-two"
                            id="input-type-two-select"
                            value={state.two}
                            label="position"
                            input={<MySelect/>}
                            onChange={handleChange}
                        >
                            <MenuItem value={'thank you'}>{t("Thank You")}</MenuItem>
                            <MenuItem value={'redirect'}>{t("Redirect")}</MenuItem>
                            <MenuItem value={'google-reviews'}>{t("Send to Google Reviews")}</MenuItem>
                            <MenuItem value={'yelp-reviews'}>{t("Send to Yelp Reviews")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {(state.two !== "google-reviews" &&  state.two !== "yelp-reviews") && <input 
                    defaultValue={form_fields && form_fields.data && form_fields.data.redirect_link ?  form_fields.data.redirect_link[1].input : ''}
                    name="two" 
                    type="text" 
                    placeholder={state.two === "redirect" ? t("Redirect Link") : t("Thank you message")} 
                    onChange={(e) => handleChangeInput(e)}
                />}
            </div>
            <div>
                <label>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                </label>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="input-type-three">{t("Select Action")}</InputLabel>
                        <Select
                            name="three"
                            labelId="input-type-three"
                            id="input-type-three-select"
                            value={state.three}
                            label="position"
                            input={<MySelect/>}
                            onChange={handleChange}
                        >
                            <MenuItem value={'thank you'}>{t("Thank You")}</MenuItem>
                            <MenuItem value={'redirect'}>{t("Redirect")}</MenuItem>
                            <MenuItem value={'google-reviews'}>{t("Send to Google Reviews")}</MenuItem>
                            <MenuItem value={'yelp-reviews'}>{t("Send to Yelp Reviews")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {(state.three !== "google-reviews" &&  state.three !== "yelp-reviews") && 
                    <input  
                        defaultValue={form_fields && form_fields.data && form_fields.data.redirect_link ?  form_fields.data.redirect_link[2].input : ''}
                        name="three" 
                        type="text" 
                        placeholder={state.three === "redirect" ? t("Redirect Link") : t("Thank you message")} 
                        onChange={(e) => handleChangeInput(e)}
                    />
                }
            </div>
            <div>
                <label>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                </label>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="input-type-four">{t("Select Action")}</InputLabel>
                        <Select
                            name="four"
                            labelId="input-type-four"
                            id="input-type-four-select"
                            value={state.four}
                            label="position"
                            input={<MySelect/>}
                            onChange={handleChange}
                        >
                            <MenuItem value={'thank you'}>{t("Thank You")}</MenuItem>
                            <MenuItem value={'redirect'}>{t("Redirect")}</MenuItem>
                            <MenuItem value={'google-reviews'}>{t("Send to Google Reviews")}</MenuItem>
                            <MenuItem value={'yelp-reviews'}>{t("Send to Yelp Reviews")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {(state.four !== "google-reviews" &&  state.four !== "yelp-reviews") && <input 
                    defaultValue={form_fields && form_fields.data && form_fields.data.redirect_link ?  form_fields.data.redirect_link[3].input : ''}
                    name="four" 
                    type="text" 
                    placeholder={state.four === "redirect" ? t("Redirect Link") : t("Thank you message")} 
                    onChange={(e) => handleChangeInput(e)}
                />}
            </div>
            <div>
                <label>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                    <AiFillStar style={{color: '#fdcc0d'}}/>
                </label>
                <div>
                    <FormControl fullWidth>
                        <InputLabel id="input-type-five">{t("Select Action")}</InputLabel>
                        <Select
                            name="five"
                            labelId="input-type-five"
                            id="input-type-five-select"
                            value={state.five}
                            label="position"
                            input={<MySelect/>}
                            onChange={handleChange}
                        >
                            <MenuItem value={'thank you'}>{t("Thank You")}</MenuItem>
                            <MenuItem value={'redirect'}>{t("Redirect")}</MenuItem>
                            <MenuItem value={'google-reviews'}>{t("Send to Google Reviews")}</MenuItem>
                            <MenuItem value={'yelp-reviews'}>{t("Send to Yelp Reviews")}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {(state.five !== "google-reviews" &&  state.five !== "yelp-reviews") && <input 
                    defaultValue={form_fields && form_fields.data && form_fields.data.redirect_link ?  form_fields.data.redirect_link[4].input : ''}
                    name="five" 
                    type="text" 
                    placeholder={state.five === "redirect" ? t("Redirect Link") : t("Thank you message")} 
                    onChange={(e) => handleChangeInput(e)}
                />}
            </div>
        </div>
    )
}
export default FieldRedirect