import React from "react"
import { IconButton } from "@mui/material"
import { IoMdInformationCircleOutline } from "react-icons/io"
import LightTooltip from "../../helpers/lightTooltip"
import { useTranslation } from "react-i18next"


const AddCompetitorBox = ({setOpenCompetitor}) => {
    const {t} = useTranslation()
    return(
        <div className="competitor-box-item">
            <LightTooltip title={t("You can add your competitor here")}>
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                </IconButton>
            </LightTooltip>
            <div className="no-competitor-box-item">
                <img src={"/admin-images/tools/search-select-competitor.svg"} alt="add"/>
                <p 
                      dangerouslySetInnerHTML={{
                        __html: t("Click here search & select your competitor for analysis")
                    }}
                ></p>
                <button onClick={() => setOpenCompetitor(true)}>
                <img src="/admin-icons/plus.svg" alt="plus icon" />
                    {t("Add Competitor")}
                </button>
            </div>
        </div>
    )
}
export default AddCompetitorBox