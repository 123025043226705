import React from "react"
import OnlyLocationWrapper from "../../../parts/OnlyLocationWrapper"
import RevuShareWrapper from "./RevuShareWrapper"
import Header from "../../../../Helpers/header"
import InnerDropdownContainer from "../../../parts/InnerDropdownContainer"

const RevuShare = () => {
    return(
        <OnlyLocationWrapper>
            <div className="table-header-container">
                <Header title="Tools" subtitle="Website Widget" />
            </div>
            <InnerDropdownContainer/>
            <RevuShareWrapper/>
        </OnlyLocationWrapper>
    )
}
export default RevuShare