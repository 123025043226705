import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchGroups, clearGroups, showYoutubeContent } from "../../../../../redux/actions/admin-index"
import { createTheme, ThemeProvider, Skeleton } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import CreateGroupPage from "./CreateGroupPage";
import useHandleDisable from "../../../helpers/disableSelect";
import { useTranslation } from "react-i18next";

import './style.css';
import Header from "../../../../Helpers/header";

const GroupList = () => {
    const dispatch = useDispatch()
    const handleDisable = useHandleDisable()
    const [open, setOpen] = useState(false)
    const [id, setId] = useState();
    const groups = useSelector(state => state.getGroups.groups)
    const location = useSelector(state => state.locationSelected.location);
    const user = useSelector(state => state.getUserInfo.user)
    let locations = user && user.user.locations
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ROTS4l8tqiM?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        handleDisable()
    }, [])

    useEffect(() => {
        dispatch(clearGroups())
        dispatch(fetchGroups())
    }, [location, dispatch])

    const data = groups?.data?.map(group => {
        return [group?.id, group?.name, group?.description,  group?.locations];
    }).reverse();

    const columns = [
        {
            name: "Id",
            options: {
                filter: false,
                viewColumns: false,
                display: false,
                sort: false,
            }
        },
        {
            name: t("Name"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Description"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
            }
        },
        {
            name: t("Action"),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const isOwnerLocation = locations && locations.find(location => location.id == tableMeta?.rowData[3][0].id)
                    return(
                        isOwnerLocation && isOwnerLocation.is_owner &&  
                        <div className="mydev-datatable-actions justify-content-start">
                            <Link to={`/app/admin/tools/group-page/${tableMeta?.rowData[0]}`} className="custom-edit-btn-datatable">
                                <button className="edit-lead-btn">
                                    <span className="icon-mydevicon-edit-bordered"></span>
                                    {t("Edit")}
                                </button>
                            </Link>
                        </div>
                    )
                },
            }
        },
    ];

    const options = {
        search: true,
        download: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        print: true,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: "checkbox",
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onRowsDelete: (rowsDeleted) => {
            const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex][5]); // array of all ids to to be deleted
            console.log(idsToDelete)
        }
    };

    const getMuiTheme = () =>
        createTheme({
            components: {}
        });

    if (groups === undefined) {
        return (
            <Skeleton style={{ transform: "unset", width: "100%", height: "100px", borderRadius: "5px", marginBottom: "15px" }} />
        )
    }


    return (
        <div>
            {/* <EditGroup open={open} setOpen={setOpen} id={id} /> */}
            {/* <div className="group-list-wrapper">
                <table>
                    <thead>
                        <th>Group Name</th>
                        <th>Group Description</th>
                        <th></th>
                    </thead>
                    <tbody>
                        {
                            groups && groups.data && groups.data.length > 0 && groups.data.map(el => {
                                return (
                                    <tr key={`group_list_${el.id}`}>
                                        <td>{el.name}</td>
                                        <td>{el.description}</td>
                                        <td>
                                            <button onClick={() => {
                                                setId(el.id)
                                                setOpen(true)
                                            }}>
                                                <span className="icon-mydevicon-edit-bordered"></span>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })

                        }
                    </tbody>
                </table>
            </div> */}
            <div className="table-header-container">
                <Header title="Group Page" subtitle="Manage Group Page" />
                {/* <button onClick={() => setOpen(true)}>
                    <img src={"/admin-icons/plus.svg"} alt="edit" />
                    {t("Add new group")}
                </button> */}
                <Link to="/app/admin/tools/group-page/add" className="link-asbtn-darkblue">
                    <img src={"/admin-icons/plus.svg"} alt="edit" /> 
                    {t("Add new group")}
                </Link>
            </div>
            <CreateGroupPage open={open} setOpen={setOpen} />
            <div className="new-datatable">
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>

        </div>
    )

}
export default GroupList