import React, {useEffect, useState, useRef} from "react"
import { useDispatch } from "react-redux"
import { IoMdTrash } from "react-icons/io"
import {FiEdit3} from "react-icons/fi"
import {FaWpforms} from "react-icons/fa"
import { baseUrl } from "../Helpers/baseUrl"
import { Skeleton } from "@mui/material"
import axios from "axios"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom"
import {MdDragIndicator} from "react-icons/md"


//our imports
import AddFields from "./modal/add-fields-modal"
import { fetchFormDetailsFields, formDetailsClearFields, fieldLabelName, fetchFormDetails, formBuilderAction } from "../../redux/actions"
import { getHeaderLocalUser } from "../TokenControl/parts/useLocalUser"

const EditForm = ({form}) => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const bottomRef = useRef(null);
    const [show, setShow] = useState(false)
    const [system, setSystem] = useState(false)
    const [detailsId, setDetailsId] = useState()
    const [formList, setFormList] = useState([])
    
    
    useEffect(() => {
        if(detailsId){
            dispatch(fetchFormDetailsFields(detailsId))
        }
        return() => {
            dispatch(formDetailsClearFields())
        }
    }, [detailsId])

    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
        if(form && form.data && form.data.formBuilderInputs && form.data.formBuilderInputs.length > 0){
            setFormList(form.data.formBuilderInputs)
        }
    }, [form])

    const openModal= (id, sys) => {
        setDetailsId(id)
        setSystem(sys)
        setShow(true)
    }

    const deleteFormField = (field_id) => {
        axios.delete(`${baseUrl}form-builder/${field_id}`,{
            headers: {...getHeaderLocalUser()}
        }).then(res => {
            dispatch(fetchFormDetails(id))
        })
    }

    
    const handleDragEnd = result => {
        if (!result.destination) {
          return;
        }
    
        const items = Array.from(formList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
    
        setFormList(items);
        let orderArray = items && items.length > 0 && items.map((el, i) => {
            return{
                fieldId: el.id,
                order: i
            }
        })
        axios.post(`${baseUrl}form-builder/update-order/${id}`,{order: orderArray}, {
            headers: {...getHeaderLocalUser()}
        })
    };
    return(
        <div className="maxHeight-40 custom-scrollbar-vertical" style={{padding: "20px"}}>
            <AddFields show={show} setShow={setShow} detailsId={detailsId} system={system}/>
            {form == undefined ? 
                <>
                    <Skeleton width={'100%'} height={40}/>
                    <Skeleton width={'100%'} height={40}/>
                    <Skeleton width={'100%'} height={40}/>
                    <Skeleton width={'100%'} height={40}/>  
                </>    
                :
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="button-list">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {formList && formList.length > 0 &&
                                        formList.map((field, index) => {
                                            return(
                                                <Draggable key={field.id} draggableId={field.id.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div    
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="drag-drop-field"
                                                        >
                                                            <MdDragIndicator/>
                                                            <div className="form-element" key={`field${field.id}`}>
                                                                <div>
                                                                    <div>
                                                                        <h4>{field.name}</h4>
                                                                        <FaWpforms/>
                                                                    </div>
                                                                    {form.data && form.data.optspot === 1 ? 
                                                                        ((field.field_input === "first_name" || field.field_input === "last_name" || field.field_input === "rating" || field.field_input === "location" ||      field.field_type === "rating" ||
                                                                        field.field_type === "location") ? 
                                                                            <span>System Field! Only label can be customized</span> : '')
                                                                        : ''
                                                                    }
                                                                </div>
                                                        
                                                                <div className="form-element-actions">
                                                                    {form.data && form.data.optspot === 1 ? 
                                                                        ((field.field_input === "first_name" || 
                                                                        field.field_input === "last_name" || 
                                                                        field.field_input === "rating" || 
                                                                        field.field_input === "location" ||
                                                                        field.field_type === "rating" ||
                                                                        field.field_type === "location"
                                                                        ) ? 

                                                                        <span className="icon-mydevicon-edit-bordered" onClick={() => 
                                                                                {
                                                                                    openModal(field.id, true)
                                                                                    dispatch(formBuilderAction("edit"))
                                                                                }
                                                                            }
                                                                        /> :
                                                                        <>
                                                                            <span className="icon-mydevicon-edit-bordered" onClick={() => {
                                                                                openModal(field.id, false)
                                                                                dispatch(formBuilderAction("edit"))
                                                                            }}/>
                                                                            <span className="icon-rounded-trash" onClick={() => deleteFormField(field.id)}/>
                                                                        </>)
                                                                    :
                                                                    <>
                                                                        <span className="icon-mydevicon-edit-bordered" onClick={() => {
                                                                            openModal(field.id, false)
                                                                            dispatch(formBuilderAction("edit"))
                                                                        }}/>
                                                                        <span className="icon-rounded-trash" onClick={() => deleteFormField(field.id)}/>
                                                                    </>
                                                                    }
                                                            
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    )}
                                                </Draggable>
                                            )
                                        }
                                    )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
            <div ref={bottomRef}></div>
        </div>
    )
}
export default EditForm