import React, { useState, useEffect } from "react"
import { Grid, Collapse, Fade, FormControl, IconButton, Tooltip, Alert } from "@mui/material"
import { ValidationTextFieldNoLabel } from "../../../../Helpers/ValidationTextField"
import { IoCopyOutline } from "react-icons/io5"
import { openVideoModal } from "../../../../../redux/actions/admin-index"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

const ReviewDetails = ({ expanded, handleChangeAccordion, landing, reviewPageFields, setReviewPageFields, setLanding }) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const { review_send_to, heading, phrase, sub_heading, confirmation_text, irevu_button_text } = reviewPageFields
    // const { review_send_to, heading, phrase, sub_heading, confirmation_text, irevu_button_text } = reviewData;
    // const { landing_url } = reviewData?.location ? reviewData?.location : reviewData;
    const [showAlert, setShowAlert] = useState(false)
    // Remove any trailing slashes from the landing URL
    const trimmedLanding = landing?.replace(/\/+$/, '');
    // Extract the value after the last forward slash
    const landingValue = trimmedLanding?.split('/').pop();
    const updateReviewFields = (e) => {
        const { name, value } = e.target;
        setReviewPageFields(prevState => ({
          ...prevState,
          [name]: value
        }));
    }
    const dotAfterAtRegex = /@.*\./;

    const copyReviewUrl = (url) => {
        navigator.clipboard.writeText(`https://irevu.com/${url}`);
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
        }, 1000)
    }

    // useEffect(() => {
    //     if(landing){
    //         if(phrase?.length > 0){
    //             const url = new URL(landing);
    //             url.searchParams.set('phrase', phrase);
    //             setReviewPageFields({ ...reviewPageFields, ["landing_url"]: url.toString().substring(18) })
    //         } else {
    //             setReviewPageFields({ ...reviewPageFields, ["landing_url"]: landing.substring(18) })

    //         }
    //     }
    //   }, [phrase, landing]);


    return (
        <>
            <div className="content-card-header" onClick={() => handleChangeAccordion("panel1")}>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className="d-flex align-items-center">
                        <div>
                            <img src={"/admin-images/tools/images/review-details-small.svg"} alt="" />
                        </div>
                        <h3 className="title">{t("Review Details")}</h3>
                    </div>
                    <div>
                        <img src={"/admin-images/tools/images/small-down-arrow.svg"} className={`cursor-pointer arrow ${expanded?.find(e => e === 'panel1') ? 'rotate' : ''}`} alt="" />
                    </div>
                </div>
            </div>

            <Collapse in={expanded?.find(e => e === 'panel1') ? true : false}>
                <div id="review_details" className='collapse_card_review'>
                    <div className="content-card-review">
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>
                                        {t("Review URL")} -
                                        <span className='text-danger'>irevu.com</span>
                                    </label>
                                    <div className="review_page_url">
                                        <span>https://irevu.com/</span>
                                        <Tooltip title="Copy">
                                            <IconButton className="copy-review-url" onClick={() => copyReviewUrl(landingValue)}>
                                                <IoCopyOutline />
                                            </IconButton>
                                        </Tooltip>
                                        {/* <input type="text" value={landing.split('/').pop()} name="landing_url" className='review-input' onChange={(e) => updateReviewFields(e)} style={{ paddingRight: "35px" }} /> */}
                                        <input type="text" value={landingValue} name="landing_url" className='review-input' onChange={(e) => setLanding(e.target.value)}  style={{ paddingRight: "35px" }} />
                                        <Fade in={showAlert}>
                                            <div style={{ position: "absolute", top: "-55px", right: 0 }}>
                                                <Alert severity="success">{t("Copied")}!</Alert>
                                            </div>
                                        </Fade>
                                    </div>
                                    <div className="review_details_info_wrapper">
                                        <img src="/admin-images/tools/blue-info-icon.svg" alt="info icon" />
                                        <span>{t("Every change on this box will change all the links before")}.</span>
                                    </div>
                                </div>
                            </Grid>
                            {/* <Grid item md={6} xs={12}>
                                <div className="form-group position-relative">
                                    <label htmlFor="" className='review_page_label'>
                                        Phrase
                                    </label>
                                    <LightTooltip style={{position: "absolute", top: "-10px"}} title="Adding phrase to landing url collects reviews based on phrase">
                                        <IconButton className="">
                                            <IoMdInformationCircleOutline style={{ opacity: "0.4" }} />
                                        </IconButton>
                                    </LightTooltip>
                                    <input type="text" name="phrase" className='review-input' placeholder='Add url phrase' value={phrase} onChange={(e) => updateReviewFields(e)} />
                                </div>
                            </Grid> */}
                            <Grid item md={6} xs={12}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>
                                        {t("Heading")}
                                    </label>
                                    <input type="text" name="heading" className='review-input' placeholder={t("How was your experience")} value={heading} onChange={(e) => updateReviewFields(e)} />
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>
                                        {t("Send Review to")}
                                    </label>
                                    <FormControl sx={{ width: '100%' }}>
                                        <ValidationTextFieldNoLabel
                                            type="email"
                                            onChange={(e) => updateReviewFields(e)}
                                            name="review_send_to"
                                            value={review_send_to}
                                            InputProps={{
                                                inputProps: {
                                                    pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
                                                },
                                            }}
                                            error={review_send_to && !review_send_to.includes("@") || !dotAfterAtRegex.test(review_send_to)}
                                            helperText={review_send_to && (!review_send_to.includes("@") || !dotAfterAtRegex.test(review_send_to) ? "Invalid Email" : "")}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>
                                        {t("Sub-Heading")}
                                    </label>
                                    <input type="text" name="sub_heading" className='review-input' placeholder='' value={sub_heading} onChange={(e) => updateReviewFields(e)} />
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>
                                        {t("Sub buttons text")}
                                    </label>
                                    <input type="text" name="irevu_button_text" className='review-input' placeholder='' value={irevu_button_text} onChange={(e) => updateReviewFields(e)} />
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className="form-group">
                                    <label htmlFor="" className='review_page_label'>
                                        {t("Confirmation Text")}
                                    </label>
                                    <input type="text" name="confirmation_text" className='review-input' placeholder='' value={confirmation_text} onChange={(e) => updateReviewFields(e)} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className='content-card-footer card-footer'>
                        <div className='d-flex justify-content-between align-items-center footer-flex-wrap'>
                            <div className='d-flex align-items-center left-wrap'></div>
                            <div className='d-flex align-items-center right-wrap pointer' onClick={() => dispatch(openVideoModal())}>
                                <div>
                                    <img src={"/admin-images/tools/play-icon.svg"} alt="" />
                                </div>
                                <p className='mb-0 mt-0'>{t("Learn how to add review details")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>

        </>
    )
}
export default ReviewDetails