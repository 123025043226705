import React from "react"
import { Grid} from "@mui/material"
import Analytics from "../Dashboard/analytics";
import LatestResponses from "./LatestResponses";
import RatingBreakdownPercentage from "./RatingBreakdownPercentage";
import RatingBreakdown from "./RatingBreakdown";

const BottomBoxes = ({breakdown,breakdown_p, latest_responses, analytics}) => {
    return(
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <RatingBreakdown breakdown={breakdown}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <Analytics analytics={analytics} />
            </Grid>
            <Grid item xs={12} md={4}>
                {/* <LatestResponses latest_responses={latest_responses} /> */}
                <RatingBreakdownPercentage breakdown={breakdown_p}/>
            </Grid>
        </Grid>
    )
}
export default BottomBoxes