import React, {useState} from "react"
import InnerDropdownContainer from "../../../parts/InnerDropdownContainer"
import OnlyLocationWrapper from "../../../parts/OnlyLocationWrapper"
import Header from "../../../../Helpers/header"
import CouponListWrapper from "./CouponListWrapper"
import { useSelector } from "react-redux"

const CouponList = () => {
    const [showSetUp, setShowSetUp] = useState(false)
    const [activeStep, setActiveStep] = useState(1)
    const location = useSelector(state => state.locationSelected.location);
    const user = useSelector(state => state.getUserInfo.user)
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    let location_details;
    if(selected && (!location || location == "all")){
        location_details = user && user.user.locations.filter(loc => loc.id == selected)
    }
    if (location && location !== "all") {
        location_details = user && user.user.locations.filter(loc => loc.id == location)
    }  

    const openModal = () => {
        if (!location_details[0]?.sendbat_identifier) {
            setShowSetUp(true);
            setActiveStep(1);
        } else {
            setShowSetUp(true);
            setActiveStep(3)
        }
    }

    return(
        <OnlyLocationWrapper>
            <div className="table-header-container">
                <Header title="Review Coupons" subtitle="Manage Review Coupons" />
                <div className="activate-feature-toggle">
                    <button type='button' className='btn connect_btn' onClick={openModal}>{location_details && !location_details[0]?.sendbat_identifier ? 'Connect with Sendbat' : 'Connected with Sendbat'}</button>
                </div>
            </div>
            <InnerDropdownContainer/>
            <CouponListWrapper showSetUp={showSetUp} setShowSetUp={setShowSetUp} activeStep={activeStep} setActiveStep={setActiveStep} location_details={location_details}/>
        </OnlyLocationWrapper>
    )
}
export default CouponList