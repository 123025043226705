import React, {Suspense, useState} from "react"
import { IconButton, Skeleton, Alert } from "@mui/material"
import { IoMdInformationCircleOutline } from "react-icons/io"
import LightTooltip from "../../helpers/lightTooltip"
import { AiFillStar } from "react-icons/ai"
import axios from "axios"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { useSelector, useDispatch } from "react-redux"
import { fetchCompetitors } from "../../../../redux/actions/admin-index"
import { showAlert } from "../../../../redux/actions"
import { useTranslation } from "react-i18next"
const SemiCircleCompetitor = React.lazy(() => import("./semiCircle"))

const CompetitorBox = ({item, setOpen, setId, setLoc, competitor, setAlert}) => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState("")
    const [refreshingId, setRefreshingId] = useState("")
    const location = useSelector(state => state.locationSelected.location)

    const triggerModal = (loc) => {
        if(search){
            setId(search)
            setLoc(loc)
            setOpen(true)
        }
    }
    const {t} = useTranslation()
    let rating = item.reviews.average && parseFloat(item.reviews.average);

    const refreshCompetitorData = (id) => {
        setRefreshingId(id)
        axios.get(`${baseUrlDev}locations/competitors/nextRefresh/${id}`,{headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            setRefreshingId("")
            dispatch(fetchCompetitors(location))
            setAlert(true)
            setTimeout(()=>{
                setAlert(false)
            },5000);  
    
        })
        .catch(err => {
            setRefreshingId("")
        })
    }

    if(item.reviews.total > 0){
        return(
            <div className="competitor-box-item">
                <h1>{item.location.name}</h1>
                <h6>{item.location.address}</h6>
                <LightTooltip title={`${t("Reviews details with top keywords, positive, negative, neutral")}.`}>
                    <IconButton className="tooltip-btn-holder">
                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                    </IconButton>
                </LightTooltip>
                <div className="competitor-box-item-chart">
                    <Suspense fallback={<Skeleton className="half-circle" variant="circular" width={100} height={100}/>}>
                        <SemiCircleCompetitor value={item.online_reputation}/>
                    </Suspense>
                </div>
                <div className="competitor-box-item-award">
                    <img src={"/admin-images/tools/award.svg"} alt="award" />
                    <div>
                        <span>{item.reviews?.total}</span>
                        <h6>{t("Total reviews")}</h6>
                    </div>
                </div>
                <div className="competitor-box-item-rating">
                    <AiFillStar/>
                    <div>
                        <p>{item.reviews.total} {t("Reviews")} / </p>
                        <p>{rating % 1 == 0 ? rating.toFixed(0) : rating.toFixed(1)} {t("average").slice(0, 3)}.{t("Rating")}</p>
                    </div>
                </div>
                <div className="competitor-box-item-keywords">
                    <h4>{t("Review Details")}</h4>
                    {competitor && 
                    <div className="competitor-box-item-keywords-search">
                        <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t("Search Keywords")}/>
                        <button onClick={() => triggerModal(item.location.id)}>{t("Search")}</button>
                    </div>
                    }
                    <div>
                        {/* {item?.keywords?.positive_keywords?.length > 0  && item?.keywords?.positive_keywords?.map((key, i) => {
                            return(
                                <span key={`${key.keyword}-competitor-${i}`} onClick={() => {
                                    setId(key.keyword)
                                    setLoc(item.location.id)
                                    setOpen(true)
                                }}>{key.keyword}</span>
                            )
                        })} */}
                        {item?.keywords?.list?.length > 0  && item?.keywords?.list?.map((key, i) => {
                            return(
                                <span key={`${key.keyword}-competitor-${i}`} onClick={() => {
                                    setId(key.keyword)
                                    setLoc(item.location.id)
                                    setOpen(true)
                                }}>{key.keyword}</span>
                            )
                        })}
                    </div>
                </div>
                <div className="competitor-box-item-bottom">
                    <div>
                        <span>{item.reviews.positive}</span>
                        <p>{t("positive")}</p>
                        <span className="positive"></span>
                    </div>
                    <div>
                        <span>{item.reviews.negative}</span>
                        <p>{t("negative")}</p>
                        <span className="negative"></span>
                    </div>
                    <div>
                        <span>{item.reviews.neutral}</span>
                        <p>{t("neutral")}</p>
                        <span className="neutral"></span>
                    </div>
                </div>
                {competitor && 
                (item.location.refresh == 0 ? 
                    <button onClick={() => refreshCompetitorData(item.location.id)}
                        className={`refresh-competitor ${refreshingId == item.location.id ? "refresh-competitor-spinner" : ""}`}>
                        <img src="/admin-icons/refresh-spinner.svg" />
                        {refreshingId == item.location.id ?  `${t("Refreshing")}...` : t("Refresh")}
                    </button>
                   :
                    <button className="refresh-competitor" style={{cursor: "initial"}}>
                        <img src="/admin-icons/refresh-spinner.svg" />
                        {t("Next Refresh")} {item.location.next_refresh_date}
                    </button>
                )
                }
            </div>
        )
    } else {
        return(
            <div className="competitor-box-item">
                <h1>{item.location.name}</h1>
                <h6>{item.location.address}</h6>
                <LightTooltip title={t("The average star rating for your business incorporating all monitored review sites")}>
                    <IconButton className="tooltip-btn-holder">
                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                    </IconButton>
                </LightTooltip>
                <div className="no-review-container">
                    <img src={"/admin-images/tools/no-rev-first.svg"} alt="ilustration"/>
                    <p>{t("We don't have review data on this company at this point")}. <span>{t("Please check back soon")}</span></p>
                </div>
                <div className="no-review-container">
                    <LightTooltip title={`${t("To collect all review data usually take 24H")} ${t("Please check back soon")}.`}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <img src={"/admin-images/tools/no-rev-sec.svg"} alt="ilustration"/>
                    <p>{t("We will get all reviews details soon")}</p>
                </div>
                {competitor && 
                (item.location.refresh == 0 ? 
                    <button onClick={() => refreshCompetitorData(item.location.id)}
                        className={`refresh-competitor ${refreshingId == item.location.id ? "refresh-competitor-spinner" : ""}`}>
                        <img src="/admin-icons/refresh-spinner.svg" />
                        {refreshingId == item.location.id ? `${t("Refreshing")}...` : t("Refresh")}
                    </button>
                   :
                    <button className="refresh-competitor" style={{cursor: "initial"}}>
                        <img src="/admin-icons/refresh-spinner.svg" />
                        {t("Next Refresh")} {item.location.next_refresh_date}
                    </button>
                )
                }
            </div>
        )
    }

 
}
export default CompetitorBox