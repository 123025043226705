import React, { useEffect, useState } from "react"
import MUIDataTable from "mui-datatables";
import "../../../../assets/admin.css";
import { Button } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../../../Helpers/baseUrl";
import { createTheme, ThemeProvider, Dialog, DialogTitle} from '@mui/material';
import Loader from "../../helpers/loader";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserManagement, fethUsersManagement, getLocationSelected, getGroupSelected, clearUsesrManagement, loadingTables } from "../../../../redux/actions/admin-index";
import Loading from "../../../TokenControl/parts/Loading";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { showAlert } from "../../../../redux/actions";
import AlertComponent from "../../../Helpers/Alert";
import Header from "../../../Helpers/header";
import { useTranslation } from "react-i18next";
import { showYoutubeContent } from "../../../../redux/actions/admin-index";

const UserManagementWrapper = () => {
    const dispatch = useDispatch()
    const location = useSelector(state => state.locationSelected.location)
    const loading = useSelector(state => state.getUserInfo.loading)
    const [deleteLogging, setDeleteLogging] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const[userId, setUserId] = useState()
    const users = useSelector(state => state.getUsersManagement.users_management)
    const [user, setUser] = useState()
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/pfS1RZGbnZg?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        dispatch(clearUsesrManagement())
        if(selected && (!location || location == "all")){
            dispatch(fethUsersManagement(selected))
        }
    }, [selected])

    useEffect(() => {
        dispatch(clearUsesrManagement())
        if (location && location !== "all") {
            dispatch(fethUsersManagement(location))
        }
    }, [location])

    if (users === undefined) {
        return (
            <Loader/>
        )
    }

    
    const deleteUser = () => {
        setDeleteLogging(true)
        axios.post(`${baseUrlDev}users/${userId}/remove`, {}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            setDeleteLogging(false)
            setOpenConfirmation(false)
            dispatch(loadingTables())
            dispatch(fethUsersManagement(location))
            dispatch(showAlert(true, "User Deleted Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        })
    }

    const getMuiTheme = () =>
        createTheme({
            components: {
            }
        });

    const columns = [
        {
            name: "Id",
            options: {
                filter: false,
                viewColumns: false,
                display: false,
                sort: false,
            }
        },
        {
            name: t("Name"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Email"),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Role"),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: t("Phone"),
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                viewColumns: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (!value) {
                        return (
                            <div className="mydev-datatable-actions">
                                <Link to={`/app/admin/account/management/${tableMeta.rowData[0]}`} style={{textDecoration: "none"}}>
                                    <button className="edit-lead-btn" onClick={() => {
                                        setUser(tableMeta.rowData)
                                    }}>
                                        <span className="icon-mydevicon-edit-bordered"></span>
                                        {t("Edit")}
                                    </button>
                                </Link>
                                {(tableMeta.rowData && tableMeta.rowData[3] !== "Owner") && <button className="remove-lead" onClick={() => {
                                        setUserId(tableMeta.rowData[0])
                                        setOpenConfirmation(true)
                                    } }>
                                    <span className="icon-rounded-trash"></span>
                                    {t("Delete")}
                                </button>}
                            </div>
                        )
                    }
                }
            }
        },
    ];
    const data = users.data.map(user => {
        return [user.id, user.name, user.email, user.type, user.phone]
    }).reverse()

    const options = {
        search: true,
        download: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        print: true,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: "checkbox",
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onRowsDelete: (rowsDeleted) => {
            // const idsToDelete = rowsDeleted.data.map(d => data[d.dataIndex][0]); // array of all ids to to be deleted
            // console.log(idsToDelete)
        }
    };
    return (
        <>
            <Dialog onClose={() => setOpenConfirmation(false)} open={openConfirmation}>
                <div style={{padding: "20px"}}>
                    <DialogTitle>{t("Are you sure you want to delete this user")}?</DialogTitle>
                </div>
                <div className="flex-btns">
                    <LoadingButton
                        style={{paddingLeft: loading && "30px"}}
                        className="delete-btn"
                        onClick={() => deleteUser()}
                        loadingIndicator={t("Deleting") + "..."}
                        loading={deleteLogging}
                    >
                        {!deleteLogging && t("Delete")}
                    </LoadingButton>
                    <button onClick={() => {
                        setOpenConfirmation(false)
                        setDeleteLogging(false)
                    }}>{t("Cancel")}</button>
                </div>
            </Dialog>
            <AlertComponent/>
            <div className="account-settings-container">
                <div>
                    <div className="new-datatable">
                        {loading && <Loader />}
                        <ThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </ThemeProvider>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserManagementWrapper;