import { useState } from "react"
import { AntSwitch2 } from "../../../../Helpers/AntSwitch2"
import MySelect from "../../../helpers/mySelect"
import { FormControlLabel, Select, FormControl, ListSubheader, TextField, MenuItem, Skeleton } from "@mui/material";
import { LoadingButton } from "@mui/lab"
import axios from "axios";
import { baseUrlDev } from "../../../../Helpers/baseUrl";
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../../redux/actions";
import AlertComponent from "../../../../Helpers/Alert";
import { fetchReviewsCoupon, fetchUserInfo } from "../../../../../redux/actions/admin-index";
import { useEffect } from "react";

const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const SenderSetup = ({ handleClose, setSearch, search, sellers, setSellers, campaigns, setCampaigns, selectedWorkspace, setSelectedWorkspace }) => {
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [deactivateLoading, setDeactivateLoading] = useState(false);
    const [selectedSellerId, setSelectedSellerId] = useState('');
    const [selectedCampaignId, setSelectedCampaignId] = useState('');

    const location = useSelector(state => state.locationSelected.location);
    const user = useSelector(state => state.getUserInfo.user)
    let location_details = user && user.user.locations.filter(loc => loc.id == location)

    const dispatch = useDispatch();

    const displayedCampaigns = campaigns.filter(campaign => containsText(campaign.name, search));
    const displayedSellers = sellers.filter(seller => containsText(seller.name, search));

    const handleChange = (e) => {
        const { value, name } = e.target;

        if (name === 'seller') {
            setSelectedSellerId(e.target.value);
        } else {
            setSelectedCampaignId(e.target.value);
        }
    }

    useEffect(() => {
        dispatch(fetchUserInfo());
    }, [])

    const handleSubmit = () => {
        const requestBody = {
            'workspace_id': selectedWorkspace,
            'location_id': location,
            'seller_id': selectedSellerId,
            'campaign_id': selectedCampaignId
        };

        setLoading(true);
        axios.post(`${baseUrlDev}reviews/coupon/save-feature`, requestBody, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setLoading(false);
                dispatch(fetchReviewsCoupon(location))
                dispatch(fetchUserInfo());
                dispatch(showAlert(true, "Success."))
                setTimeout(() => {
                    dispatch(showAlert(false, ""))
                }, 5000)
                handleClose();
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const handleDeactivate = () => {
        setDeactivateLoading(true);
        axios.post(`${baseUrlDev}reviews/coupon/deactivate`, { location: location }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setDeactivateLoading(false);
                dispatch(showAlert(true, "Connection with sendBat deactivated successfully."))
                setTimeout(() => {
                    dispatch(showAlert(false, ""))
                }, 5000)
                dispatch(fetchReviewsCoupon(location));
                dispatch(fetchUserInfo());
                handleClose();
            })
            .catch(error => {
                setDeactivateLoading(false);
            })
    }

    useEffect(() => {
        if (location_details[0]?.sendbat_identifier) {
            setDataLoading(true);
            axios.get(`${baseUrlDev}reviews/coupon/retrieve-workspace?location_id=${location}`, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
                .then(res => {
                    setSelectedWorkspace(res.data.workspace_id);
                    axios.post(`${baseUrlDev}reviews/coupon/feature-workspace-details`, { workspace_id: res.data.workspace_id }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
                        .then(res => {
                            setSellers(res.data.data.sellers);
                            setCampaigns(res.data.data.campaigns);

                            axios.get(`${baseUrlDev}reviews/coupon/retrieve-selected-coupon-data?location_id=${location}`, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
                                .then(res => {
                                    setSelectedCampaignId(res.data.campaign_id);
                                    setSelectedSellerId(res.data.seller_id);
                                    setDataLoading(false);
                                })
                        })
                })

        }
    }, [location]);

    return (
        <>
            <AlertComponent />
            <div className="wrapper-setup-header">
                <img src={"/admin-icons/sendbat.svg"} alt="sender" />
            </div>
            <div className="wrapper-setup-body custom-scrollbar-vertical sender_workspace_body">
                {/* <div className="d-flex align-items-center justify-content-between">
                    <h6>Activate this feature</h6>
                    <FormControlLabel
                        className='hours-small-label'
                        control={<AntSwitch2 checked={false} />}
                    />
                </div> */}
                <div className="field-container" style={{ paddingLeft: "0", paddingRight: "0" }}>
                    <label>Choose the Seller</label>
                    <FormControl fullWidth>
                        {
                            dataLoading ? <Skeleton style={{ transform: "unset", width: "100%", height: "40px" }} /> : <Select
                                name="seller"
                                labelId="input-type-one"
                                id="input-type-one-select"
                                value={selectedSellerId ? selectedSellerId : "select"}
                                onChange={handleChange}
                                label="Seller"
                                input={<MySelect />}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                            >
                                <ListSubheader>
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder="Search Seller"
                                        fullWidth
                                        onChange={(e) => setSearch(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== 'Escape') {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                </ListSubheader>
                                <MenuItem disabled value="select">
                                    <em>Please Select Seller</em>
                                </MenuItem>
                                {displayedSellers?.map(user => {
                                    return (
                                        <MenuItem key={`seller${user.id}`} value={user.id}>{user.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        }
                    </FormControl>
                </div>
                <div className="field-container" style={{ paddingLeft: "0", paddingRight: "0" }}>
                    <label>Choose the Campaign</label>
                    <FormControl fullWidth>
                        {dataLoading ? <Skeleton style={{ transform: "unset", width: "100%", height: "40px" }} /> : <Select
                            name="campaign"
                            labelId="input-type-one"
                            id="input-type-one-select"
                            value={selectedCampaignId ? selectedCampaignId : "select"}
                            onChange={handleChange}
                            label="Campaign"
                            input={<MySelect />}
                            MenuProps={{
                                classes: {
                                    paper: "breake-option-words custom-selected",
                                },
                                autoFocus: false, PaperProps: {
                                    sx: { maxHeight: 300 },
                                    style: {
                                        maxWidth: 300,
                                    },
                                }
                            }}
                        >
                            <ListSubheader>
                                <TextField
                                    size="small"
                                    autoFocus
                                    placeholder="Search Campaign"
                                    fullWidth
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key !== 'Escape') {
                                            e.stopPropagation();
                                        }
                                    }}
                                />
                            </ListSubheader>
                            <MenuItem disabled value="select">
                                <em>Please Select Campaign</em>
                            </MenuItem>
                            {displayedCampaigns?.map(campaign => {
                                return (
                                    <MenuItem key={`seller${campaign.id}`} value={campaign.id}>{campaign.name}</MenuItem>
                                )
                            })}
                        </Select>}
                    </FormControl>
                </div>
            </div>
            <div className="custom-modal-footer">
                <LoadingButton
                    style={{ paddingLeft: deactivateLoading && "30px", float: 'left' }}
                    loading={deactivateLoading}
                    className="close-btn"
                    onClick={handleDeactivate}
                >
                    Deactivate
                </LoadingButton>

                <LoadingButton
                    style={{ paddingLeft: loading && "30px" }}
                    loading={loading}
                    className="save-btn"
                    onClick={handleSubmit}
                >
                    Save
                </LoadingButton>
                <button onClick={() => handleClose()} className="close-btn">Cancel</button>
            </div>
        </>
    )
}
export default SenderSetup