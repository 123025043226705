import React, {useState, useEffect} from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider, Dialog, DialogTitle  } from "@mui/material";
import { useTranslation } from "react-i18next";
import PageVisitsHeader from "./pageVisitsHeader";
import { fetchPageVisits, clearPageVisits, loadingTables, showYoutubeContent } from "../../../../../redux/actions/admin-index";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../helpers/loader";
import ExportedAlert from "../../../modals/exportedAlert";

const PageVisitsWrapper = ({from, to}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()


    const groupSelected = useSelector(state => state.groupSelected.group)
    const location = useSelector(state => state.locationSelected.location)
    const page_visits = useSelector(state => state.PageVisits.page_visits)
    const loading = useSelector(state => state.PageVisits.loading)
    const selected = useSelector(state => state.groupLocationSelected.group_location)


    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ROTS4l8tqiM?autoplay=1&mute=0&rel=0'))
    }, [])
    
    useEffect(() => {
        dispatch(loadingTables())
        if(selected && (!location || location == "all")){
            dispatch(fetchPageVisits(selected, from, to))
        }
    }, [selected, from, to])

    useEffect(() => {
         dispatch(loadingTables())
        if (location && location !== "all") {
            dispatch(fetchPageVisits(location, from, to))
        }
    }, [location, from, to,])


    const columns = [
        { 
            name: "Id", 
            options: {
                filter: false,
                viewColumns: false,
                display: false,
                sort: false,
            }
        },
        { 
            name: t("Date"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Total Visits"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: t("Total Clicks"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
    ]

    if(page_visits && page_visits.data.length > 0 ){
        Object.keys(page_visits.data[0][Object.keys(page_visits.data[0])[0]].platforms) && 
        Object.keys(page_visits.data[0][Object.keys(page_visits.data[0])[0]].platforms).length > 0 &&
        Object.keys(page_visits.data[0][Object.keys(page_visits.data[0])[0]].platforms).map(platform => {
            columns.push(
                { 
                    name: platform, 
                    options: { 
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <p className="custom-text-datatable">{value}</p>
                        ),
                        customHeadLabelRender: (columnMeta) => (
                            <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                        )
                    } 
                }
            )
        })
    }


    const transformedArray = page_visits && page_visits.data?.length > 0 && page_visits.data.map((item, i) => {
        const date = Object.keys(item)[0];
        const data = item[date];
        const { visits, clicks } = data;
        const platformsValues = Object.values(data.platforms);
    
        return [i + 1, date, visits, clicks, ...platformsValues];
    });
 
    const data = transformedArray
    const options = {
        search: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        download: true,
        print: true,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: "checkbox",     
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        downloadOptions: {
            filterOptions: {
              useDisplayedColumnsOnly: true,
            },
        },
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        )
    };

    const getMuiTheme = () =>
        createTheme({
        components: {
        }
    });
    return(
        <div className="new-datatable">
            <ExportedAlert/>
            {loading && <Loader/>}
            {page_visits && page_visits.data?.length > 0 ?
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={data}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
            : page_visits && page_visits.data?.length == 0 ? <div className="nothing-to-show padding-20">No Visits Yet</div>
            : ""
        }
        </div>
    )
}
export default PageVisitsWrapper