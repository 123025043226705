import React, {useState, useEffect} from "react"
import "../../../../../src/assets/accreditation.css"
import { Grid, Checkbox,IconButton, Skeleton, Collapse} from "@mui/material";
import LightTooltip from "../../helpers/lightTooltip";
import {IoMdInformationCircleOutline, IoMdArrowDropdown} from "react-icons/io"
import {AiFillCheckCircle, AiFillMinusCircle} from "react-icons/ai"
import {MdCancel} from "react-icons/md"
import { useSelector } from "react-redux";
import axios from "axios";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { useTranslation } from "react-i18next";

const AccreditatedCompany = ({showMsg, collapsed}) => {
    const {t} = useTranslation()
    const [show, setShow] = useState(true)
    
    useEffect(() => {
        console.log(collapsed)
        setShow(collapsed == 1 ? true : false)
    }, [collapsed])

    const getProgressColor = (percentage) => {
        if (percentage >= 90) {
          return '#06D49D'; 
        } else if (percentage >= 50) {
          return '#FFC107'; 
        } else if (percentage == 0){
            return "#d5d7db"
        } else {
          return '#ED476E'; 
        }
    };
    const getProgressText = (percentage) => {
        if(percentage < 50){
            return "Not Eligible"
        } else if (percentage >= 50 && percentage < 90){
            return "Almost There"
        } else if (percentage >= 90){
            return "Ready to be"
        }
    };
    const accreditated_company = useSelector(state => state.accreditationReducer.accreditated_company)
    const location = useSelector(state => state.locationSelected.location)

    const changeAccreditationProgress = () => {
        setShow(!show)
        axios.post(`${baseUrlDev}dashboard/update-accreditation-box-display`, {location:  location}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })

    }

    return(
        <div className="accreditated-company-wrapper-dashboard">
            <LightTooltip title={t("Accreditation_tooltip")}>
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                </IconButton>
            </LightTooltip>
            <div className="accreditated-company-header">
                <h6 className="d-flex">            
                    <IoMdArrowDropdown onClick={() => changeAccreditationProgress()} style={{cursor: "pointer",  color: "#B5B8BB", fontSize: "25px", transform: show ? "rotate(0deg)" : "rotate(180deg)"}}/>
                    {t("Accreditation Progress for")}
                </h6>
                <h2>{accreditated_company === undefined ? <Skeleton style={{width: "200px", height: "40px", textTransform: "unset"}}/> : accreditated_company.accreditation?.companyName}</h2>
            </div>
            <div className="accreditated-company-progress">
                <span> 
                    {getProgressText(accreditated_company?.total_percentage) && 
                        <div>
                            <LightTooltip title={"iReview guidelines to ensure that your business gains Accreditation on Online Reputation. We're certain that your business will be able to reach and even surpass the iReview guidelines to become one of the most wanted locations for customers to visit, after their online search. Here below you can see the progress, but we need your help to complete the remaining guidelines."}>
                                <div className="acc-status-holder">
                                    {t(`${getProgressText(accreditated_company.total_percentage)} for Marketing Materials`)}
                                </div>
                            </LightTooltip>
                        </div>
                    }
            
                </span>
                {accreditated_company === undefined ? <Skeleton style={{width: "100%", height: "40px", textTransform: "unset"}}/>
                :
                    <div className="custom-linear-progress">
                        <div className="custom-linear-progress-outer">
                            <div
                                className="custom-linear-progress-inner" 
                                style={{
                                    width: `${accreditated_company.total_percentage}%`,
                                    backgroundColor: getProgressColor(accreditated_company.total_percentage),
                                    borderTopRightRadius: (accreditated_company.total_percentage < 100 && accreditated_company.total_percentage != 0) ? "5px" : "25px",
                                    borderBottomRightRadius: (accreditated_company.total_percentage < 100 && accreditated_company.total_percentage != 0) ? "5px" : "25px"
                                }}
                            >
                                {/* {(accreditated_company.total_percentage < 100 )&& <span className="animate-progress"></span>} */}
                                {accreditated_company.total_percentage > 0 && <span>{accreditated_company.total_percentage}%</span>}
                                {/* {getProgressText(accreditated_company.total_percentage) && 
                                    <div>
                                        {getProgressText(accreditated_company.total_percentage)}
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Collapse in={show}>
                <div className="accreditated-company-list">
                    <Grid container style={{alignItems: "center"}}>
                        <Grid item xs={12} md={6} lg={4}>
                            <div className="accreditated-company-list-item justify-content-start">
                                {accreditated_company === undefined ? <Skeleton style={{width: "100px", transform: "unset"}}/> : 
                                    <>
                                        <div className="d-flex align-items-center">
                                            {accreditated_company.accreditation?.business_validation == 1 ? 
                                                <AiFillCheckCircle 
                                                    style={{color: "#01ba98", fontSize: "22px", padding: "9px"}}
                                                /> 
                                                : 
                                                <MdCancel style={{color: "rgb(237, 71, 110)", fontSize: "22px", padding: "9px"}} />
                                            }
                                            <h6>{t("Business Validation")}</h6>
                                            <LightTooltip title={t("Business Validation Tooltip")}>
                                                <IconButton>
                                                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                                </IconButton>
                                            </LightTooltip>
                                        </div>
                                    </>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <div className="accreditated-company-list-item justify-content-center">
                                {accreditated_company === undefined ? <Skeleton style={{width: "100px", transform: "unset"}}/> : 
                                    <>
                                        <div>
                                            {accreditated_company.reputation && accreditated_company.reputation > 0 ?
                                                <span>Currently {accreditated_company.reputation}</span> : ""
                                            }
                                            <div className="d-flex align-items-center">
                                                {
                                                    accreditated_company?.reputation < 3.5  ? 
                                                    <MdCancel style={{color: "rgb(237, 71, 110)", fontSize: "22px", padding: "9px"}} /> :
                                                    accreditated_company?.reputation >= 3.5 &&  accreditated_company?.reputation <=  4.5 ?
                                                    <AiFillMinusCircle
                                                        style={{color: "#faaf00", fontSize: "22px", padding: "9px"}}
                                                    /> 
                                                    :
                                                    <AiFillCheckCircle 
                                                        style={{color: "#01ba98", fontSize: "22px", padding: "9px"}}
                                                    /> 
                                                }
                                                <h6 className="double-div-parent">{t("Reputation Above")} 4.5</h6>
                                                <LightTooltip title={t("Reputation Tooltip")}>
                                                    <IconButton>
                                                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                                    </IconButton>
                                                </LightTooltip>
                                            </div>
                                            
                                        </div>
                                    </>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <div className="accreditated-company-list-item justify-content-end">
                                {accreditated_company === undefined ? <Skeleton style={{width: "100px", transform: "unset"}}/> :
                                    <>
                                        <div>
                                            {accreditated_company.reviews_response_percentage && accreditated_company.reviews_response_percentage > 0 ?
                                                <span>Currently at {accreditated_company.reviews_response_percentage}%</span> : ""
                                            }
                                            <div className="d-flex align-items-center">
                                                {
                                                    accreditated_company?.reviews_response_percentage < 30  ? 
                                                        <MdCancel style={{color: "rgb(237, 71, 110)", fontSize: "22px", padding: "9px"}} /> :
                                                    accreditated_company?.reviews_response_percentage >= 30 &&  accreditated_company?.reviews_response_percentage <=  80 ?
                                                    <AiFillMinusCircle
                                                        style={{color: "#faaf00", fontSize: "22px", padding: "9px"}}
                                                    /> 
                                                    :
                                                    <AiFillCheckCircle 
                                                        style={{color: "#01ba98", fontSize: "22px", padding: "9px"}}
                                                    /> 
                                                    
                                                }
                                                <h6 className="double-div-parent">{t("Reviews Respond Rate Above")} 80%</h6>
                                                <LightTooltip title={t("Reviews Respond Rate Tooltip")}>
                                                    <IconButton>
                                                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                                    </IconButton>
                                                </LightTooltip>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <div className="accreditated-company-list-item justify-content-start">
                                {accreditated_company === undefined ? <Skeleton style={{width: "100px", transform: "unset"}}/> :
                                    <>
                                        <div className="d-flex align-items-center">
                                            {accreditated_company.accreditation?.location_validation == 1 ? 
                                                <AiFillCheckCircle 
                                                    style={{color: "#01ba98", fontSize: "22px", padding: "9px"}}
                                                /> 
                                                : 
                                                <MdCancel style={{color: "rgb(237, 71, 110)", fontSize: "22px", padding: "9px"}} />
                                            }
                                            <h6>{t("Location Validation")}</h6>
                                            <LightTooltip title={t("Location Validation Tooltip")}>
                                                <IconButton>
                                                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                                </IconButton>
                                            </LightTooltip>
                                        </div>
                                    </>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <div className="accreditated-company-list-item justify-content-center">
                                {accreditated_company === undefined ? <Skeleton style={{width: "100px", transform: "unset"}}/> :      
                                    <>
                                        <div className="d-flex align-items-center">
                                            {accreditated_company.accreditation?.listing_validation == 1 ? 
                                                <AiFillCheckCircle 
                                                    style={{color: "#01ba98", fontSize: "22px", padding: "9px"}}
                                                /> 
                                                : 
                                                <MdCancel style={{color: "rgb(237, 71, 110)", fontSize: "22px", padding: "9px"}} />
                                            }
                                            <h6>{t("Listing Validation")}</h6>
                                            <LightTooltip title={t("Listing Validation Tooltip")}>
                                                <IconButton>
                                                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                                </IconButton>
                                            </LightTooltip>
                                        </div>
                                    </>
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <div className="accreditated-company-list-item justify-content-end">
                                {accreditated_company === undefined ? <Skeleton style={{width: "100px", transform: "unset"}}/> :
                                    <>
                                        <div>
                                            {accreditated_company.reviews_total && accreditated_company.reviews_total > 0 ?
                                                <span>Currently at {accreditated_company.reviews_total}</span> : ""
                                            }
                                            <div className="d-flex align-items-center">
                                                {
                                                    accreditated_company?.reviews_total < 300  ? 
                                                        <MdCancel style={{color: "rgb(237, 71, 110)", fontSize: "22px", padding: "9px"}} /> :
                                                    accreditated_company?.reviews_total >= 300 &&  accreditated_company?.reviews_total <=  500 ?
                                                    <AiFillMinusCircle
                                                        style={{color: "#faaf00", fontSize: "22px", padding: "9px"}}
                                                    /> 
                                                    :
                                                    <AiFillCheckCircle 
                                                        style={{color: "#01ba98", fontSize: "22px", padding: "9px"}}
                                                    /> 
                                                    
                                                }
                                                <h6 className="double-div-parent">{t("Total Reviews Above")} 500</h6>
                                                <LightTooltip title={t("Total Reviews Above 500 Tooltip")}>
                                                    <IconButton>
                                                        <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                                                    </IconButton>
                                                </LightTooltip>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Collapse>
            {showMsg && 
                <div>
                    <p className="acc-disclaimer">*Give us 24 hours to get all the information about your bussiness. We are in process to complete your current data.</p>
                </div>
            }
        </div>
    )
}
export default AccreditatedCompany