import React, {useState, useEffect} from "react"
import QuickRepliesList from "./QuickRepliesList"
import Header from "../../../../Helpers/header"
import AddQuickReply from "./AddQuickReply"
import QuickRepliesFilter from "./QuickRepliesFilters"
import { useDispatch } from "react-redux"
import { getLocationSelected, getGroupSelected, showYoutubeContent } from "../../../../../redux/actions/admin-index"
import useHandleDisable from "../../../helpers/disableSelect"
import { useTranslation } from "react-i18next"

const QuickReplies = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const handleDisable = useHandleDisable()
    const [open, setOpen] = useState(false)
    const [added, setAdded] = useState(false)

    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/ROTS4l8tqiM?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        handleDisable()
    }, [])

    return(
        <div className="q-quick-reply">
            <AddQuickReply open={open} setOpen={setOpen} setAdded={setAdded}/>
            <div className="table-header-container">
                <Header title="Quick Replies" subtitle="Manage Quick Replies"/>
                <button onClick={() => setOpen(true)}>
                    <img src={"/admin-icons/plus.svg"} alt="edit"/>
                    {t("Add Quick Reply")}
                </button>
            </div>
            <QuickRepliesFilter added={added}/>
            <QuickRepliesList open={open} setOpen={setOpen}/>
        </div>
       
    )
}
export default QuickReplies