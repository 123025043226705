import React, {useState} from "react"
import ListingWrapper from "./ListingWrapper"
import { CircularProgress } from "@mui/material"
import InnerDropdownContainer from "../../../parts/InnerDropdownContainer"
import OnlyLocationWrapper from "../../../parts/OnlyLocationWrapper"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import Header from "../../../../Helpers/header"
import { BiExport } from "react-icons/bi"
import axios from "axios"
import { baseUrlDev } from "../../../../Helpers/baseUrl"
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser"
import { exportTable } from "../../../helpers/export"
import ExportedAlert from "../../../modals/exportedAlert"
import { openExportedAlert } from "../../../../../redux/actions/admin-index"

const Listing = () => {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    const location = useSelector(state => state.locationSelected.location)
    const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)

    const exportFunc = (id) => {
        setLoading(true)
        exportTable(impersonateReducer,`${baseUrlDev}locations/listing-score/export`, {location: id}, 
        (resData) => {
            dispatch(openExportedAlert(true, resData.message));
            setLoading(false)
        }, 
        (error) => {
            setLoading(false)
        });
    }
    const exportTableFunc = () => {
        if(selected && (!location || location == "all")){
            exportFunc(selected)
        }
        if (location && location !== "all") {
            exportFunc(location)
        }
    }

    return(
        <OnlyLocationWrapper>
           <ExportedAlert/>
           <div className="flex-header-container table-header-container">
                <Header title="Locations" subtitle="Location Listings"/>
                <div className="flex-header-actions">
                    <button
                        style={{ marginLeft: "auto" }} className="competitor-export"
                        onClick={() => exportTableFunc()}
                        disabled={loading}
                    >
                        {loading ?
                            <>
                            <CircularProgress
                                size={15}
                                style={{ marginRight: "10px" }}
                                thickness={4}
                            />
                            {t("Export to Excel")}</>
                        : <> <BiExport /> {t("Export to Excel")}</>}
                    </button>
                </div>
            </div>
            <InnerDropdownContainer/>
            <ListingWrapper />
        </OnlyLocationWrapper>
    )
}
export default Listing