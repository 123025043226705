import React, { useEffect } from 'react'
import {Box, TextField, Modal } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import {MdClear} from 'react-icons/md';
import {BiXCircle} from 'react-icons/bi';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: "100%",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    p:3,
    pt:2,
    borderRadius: "5px",
    padding: "0px"
};
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
const ModalSubmissionView = ({ modalData, setModalData }) => {
    const { open, data } = modalData;
    const handleOpen = () => setModalData({ open: true, data });
    const handleClose = () => setModalData({ open: false, data });
    const [location, setLocation] = React.useState("");
    const {t} = useTranslation()
    useEffect(() => {
        setLocation("")
        getLocation();
    }, [modalData]);

    function getLocation(){
       if(!data?.ip) return;
        axios({
            method: 'GET',
            url: 'https://api.ipgeolocation.io/ipgeo?apiKey=477955365a174f46bdc13ce79555d5d7&ip='+data?.ip,
            headers:{
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            //console.log("geoRepsonse:",response.data.country_name);
            var part1 = response?.data?.country_name || "";
            var part2 = response?.data?.city || "";
      
            setLocation(part2+(part1 !== "" ? ", "+part1 : "") || "");
          });
    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{padding: "10px 20px", fontFamily: "Poppins-Medium", display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                        <span>{t("Submission")} #{data.id}</span>
                        <span className="c-pointer" onClick={handleClose}><BiXCircle size="25" color="#939393" /></span>
                    </Box>
                    <div className='custom-scrollbar-vertical' style={{maxHeight: "70vh", padding: "10px 20px", overflowY: "auto"}}>
                        {Object.keys(data?.fields ?? {}).map((key, index) => {
                            if(key === "description"){
                                return (
                                    <TextField 
                                        className="custom-scrollbar-vertical-mui"
                                        multiline
                                        maxRows={4}
                                        key={key} 
                                        InputProps={{
                                            readOnly: true,
                                        }} 
                                        sx={{ width: "100%",marginBottom:"20px",padding:"0px" }} 
                                        defaultValue={data?.fields[key] ?? " "} 
                                        label={capitalizeFirstLetter(key)} 
                                        variant="standard" 
                                    />
                                )
                            } else if (key === "rating" || key === "rating field"){
                                return (
                                    <TextField 
                                        key={key} InputProps={{
                                            readOnly: true,
                                        }} 
                                        sx={{ width: "100%",marginBottom:"20px",padding:"0px" }} 
                                        defaultValue={data?.fields[key] > 0 ? data?.fields[key] : "No Rating "} 
                                        label={capitalizeFirstLetter(key)} 
                                        variant="standard" 
                                    />
                                )
                            } else {
                                return (
                                    <TextField 
                                        key={key} InputProps={{
                                            readOnly: true,
                                        }} 
                                        sx={{ width: "100%",marginBottom:"20px",padding:"0px" }} 
                                        defaultValue={data?.fields[key] ?? " "} 
                                        label={capitalizeFirstLetter(key)} 
                                        variant="standard" 
                                    />
                                )
                            }
                        
                        })}
                        {location !== "" && typeof data?.fields?.location === "undefined" && <TextField InputProps={{
                            readOnly: true,
                        }} sx={{ width: "100%",marginBottom:"20px",padding:"0px" }} defaultValue={location} label={"Location"} variant="standard" />}
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ModalSubmissionView