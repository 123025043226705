import React, {useState} from "react"
import OnlyLocationWrapper from "../../parts/OnlyLocationWrapper"
import UserManagementWrapper from "./UserManagementWrapper"
import InnerDropdownContainer from "../../parts/InnerDropdownContainer"
import { useTranslation } from "react-i18next"
import Header from "../../../Helpers/header"
import { Link } from "react-router-dom"

const UserManagement = () => {
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()

    return(
        <OnlyLocationWrapper>
           <div className="flex-header-container table-header-container">
                <Header title="Account" subtitle="User Management / Manage User" />
                <Link to={"addUser"} style={{textDecoration: "none"}}>
                    <button>
                        <img src={"/admin-icons/plus.svg"} alt="edit" />
                        {t("Add new user")}
                    </button>
                </Link>
            </div>
            <InnerDropdownContainer/>
            <UserManagementWrapper/>
        </OnlyLocationWrapper>
    )
}
export default UserManagement