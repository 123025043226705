import React from 'react';
import { useTranslation } from 'react-i18next';

const Platforms = ({ handleActiveButton, platforms, activePlatform }) => {
    const {t}  = useTranslation()
    return (
        <div className="platforms_wrapper">
            <label htmlFor="" className='label_widget_setup'>{t("Platforms")}</label>
            <div>
                {
                    platforms?.map((platform, i) => (
                        <button type='button' onClick={() => handleActiveButton(platform?.name)} className={activePlatform?.includes(platform.name) ? "active" : ""} key={`${platform.id}-${i}`}>
                            <div className='d-flex align-items-center justify-content-center gap-8'>
                                <img src={platform?.logo} alt="" />
                                {platform?.name}
                            </div>
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

export default Platforms;
