import React, {useState} from "react"
import OnlyLocationWrapper from "../../parts/OnlyLocationWrapper"
import AnalysisWrapper from "./AnalysisWrapper"
import AnalysisHeader from "./analysisHeader"
import InnerDropdownContainer from "../../parts/InnerDropdownContainer"

const AnalysisReport = () => {
    const [from, setFrom] = useState()
    const [to, setTo] = useState()
    const [openModal, setOpenModal] = useState(false);

    return(
        <OnlyLocationWrapper>
            <div className='analysis_page'>
                <AnalysisHeader setOpenModal={setOpenModal} setFrom={setFrom} setTo={setTo}/>
            </div>
            <InnerDropdownContainer/>
            <AnalysisWrapper 
                from={from}
                to={to}
                openModal={openModal} 
                setOpenModal={setOpenModal}
            />
        </OnlyLocationWrapper>
    )
}
export default AnalysisReport