import React from 'react';
import { Grid, IconButton } from '@mui/material';
import LightTooltip from '../../helpers/lightTooltip';
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useTranslation } from 'react-i18next';

const AnalysisBox = ({ title, children, xs, md, lg, xl, tooltip }) => {
    const {t} = useTranslation()

    return (
        <Grid item xs={xs} md={md} lg={lg} xl={xl} className="d-flex align-items-stretch">
            <div className="analysis_box position-relative">
                {
                    tooltip && <LightTooltip
                        title={t(tooltip)}
                    >
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{ opacity: "0.4" }} />
                        </IconButton>
                    </LightTooltip>
                }
                <h2>{t(title)}</h2>
                {children}
            </div>
        </Grid>
    )
}

export default AnalysisBox;
