import { styled } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";

export const StyledLoadingButton = styled(LoadingButton)({
    color: '#fff',
    position: 'relative',
    backgroundColor: '#00B4E5',
    textTransform: "capitalize",
    fontSize: "14px",
    borderRadius: '3px',
    padding: '8px 15px',
    fontFamily: "Poppins-Regular",
    '&:hover':{
        backgroundColor: '#00B4E5',
    },
    '&.MuiLoadingButton-loading': {
        color: '#fff',
    },
    '&.MuiLoadingButton-loadingIndicator':{
        color: '#fff',
        left: "15px"
    }
});