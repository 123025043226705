import React, {useState} from "react"
import { AntSwitch3 } from "../../../Helpers/AntSwitch2"
import { FormControlLabel, Dialog, DialogTitle, Collapse, Alert } from "@mui/material"
import { useSelector } from "react-redux"
import { LoadingButton } from "@mui/lab"
import axios from "axios"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import AutoPilotModal from "./autoPilotModal"
import { useTranslation } from "react-i18next"

const AutoPilotToggle = ({checked, setChecked, from, showWarning, setShowWarning, setDisabled}) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [show, setShow] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const {t} = useTranslation()
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const user = useSelector(state => state.getUserInfo.user)
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    let location_details;
    if(selected && (!location || location == "all")){
        location_details = user && user.user.locations.filter(loc => loc.id == selected)
    }
    if (location && location !== "all") {
        location_details = user && user.user.locations.filter(loc => loc.id == location)
    }  

    const stopResponses = (check) => {
        if(selected && (!location || location == "all")){
            apiRequest(selected, check)
        }
        if (location && location !== "all") {
            apiRequest(location, check)
        }  
    }

    const apiRequest = (id, check) => {
        axios.post(`${baseUrlDev}locations/toggleAiResponse/${id}`, {toggle_ai_response: check ? 1 : 0}, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
        .then(res => {
            setLoading(false)
            setChecked(check)
            check == 1 ? setShowWarning(false) : setShowWarning(true)
            setConfirmation(false)
            if(from === "settings"){
                check == 1 ? setDisabled(false) : setDisabled(true)
            }
        }).catch(err => {
            err.response.data.errors ?
            Object.keys(err.response.data.errors).map(key => {
                setErrors(errors => [...errors, err.response.data.errors[key][0]])
            })
            :
            setErrors(["Something Went Wrong"])
            setTimeout(() => {
                setErrors([])
            }, 5000)
            setLoading(false)
            setChecked(false)
            setConfirmation(false)
        })
    }

    const togglePlan = (e) => {
        if(e.target.checked){
            if(location_details && location_details[0]?.plan != 3 && (location_details[0]?.plan != 4)){
                setShow(true)
            } else {
                setChecked(true)
                stopResponses(true)
            }
        } else {
            setConfirmation(true)
        }
    }

    const cancelPlan = () => {
        setLoading(true)
        setChecked(false)
        stopResponses(false)  
    }
    return(
        <>
            <AutoPilotModal show={show} setShow={setShow}/>
            <Dialog onClose={() => {
                setConfirmation(false)
                setLoading(false)
            }} open={confirmation}>
                <div style={{padding: "20px"}}>
                    <DialogTitle>
                        {t("Dialog_first_content")}
                        <br/>
                        {t("Dialog_sec_content")}
                    </DialogTitle>
                </div>
                <div className="flex-btns">
                    <LoadingButton
                        style={{paddingLeft: loading && "30px"}}
                        className="delete-btn"
                        onClick={() => cancelPlan()}
                        loadingIndicator="Canceling..."
                        loading={loading}
                    >
                        {!loading && t("Yes")}
                    </LoadingButton>
                    <button onClick={() => {
                        setConfirmation(false)
                    }}>{t("No")}</button>
                </div>
            </Dialog>
            <Collapse in={showWarning && from === "settings"}>
                <Alert style={{marginBottom: "20px"}} severity="warning" icon={<div><img src="/admin-icons/warning.svg" /></div>}>
                    <p className="warning-alert-p"> 
                        {t("Autopilot_first_alert")}               
                    </p>
                    <p className="warning-alert-p">
                        {t("Autopilot_sec_alert")}
                    </p>
                </Alert>
            </Collapse>
            {(from == "details" && location_details && (location_details[0]?.plan == 3 || (location_details[0]?.plan == 4 && location_details[0]?.la_carte && JSON.parse(location_details[0]?.la_carte).includes("4")))) &&
                <div className="autopilot-wrapper">
                    <div>
                        <h5>AI Autopilot</h5>
                        <p dangerouslySetInnerHTML={{__html: t("AI-Disclaimer")}}></p>
                    </div>
                </div>
            }
            {(from == "details" && (location_details && (location_details[0]?.plan === 0 || location_details[0]?.plan === 1 || location_details[0]?.plan === 2))) &&
                <div className="autopilot-wrapper">
                    <div>
                        <h5>AI Autopilot</h5>
                        <p dangerouslySetInnerHTML={{__html: t("AI-Disclaimer")}}></p>
                    </div>
                    <div className="autopilot-div-container">
                        <FormControlLabel
                            className='hours-small-label'
                            control={<AntSwitch3 checked={checked} onChange={(e) => togglePlan(e)}/>}
                        />
                        <span>{t("ON")}/{t("OFF")}</span>
                    </div>
                </div>
            }
            {from == "settings" && location_details && (location_details[0]?.plan == 3 || (location_details[0]?.plan == 4 && location_details[0]?.la_carte && JSON.parse(location_details[0]?.la_carte).includes("4"))) &&
                <div className="autopilot-wrapper" style={{width: "300px", maxWidth: "100%", marginBottom:"20px"}}>
                    <div>
                        <h5>AI Autopilot</h5>
                    </div>
                    <div className="autopilot-div-container">
                        <FormControlLabel
                            className='hours-small-label'
                            control={<AntSwitch3 checked={checked} onChange={(e) => togglePlan(e)}/>}
                        />
                        {/* <span>ON/OFF</span> */}
                    </div>
                </div>
            }
        </>
    )
}
export default AutoPilotToggle