import React, {useState, useRef, useEffect} from "react"
import { Collapse } from "@mui/material"
import {FiEdit} from "react-icons/fi"
import axios from "axios"
import { useDispatch } from "react-redux"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { showAlert } from "../../../../redux/actions"
import { fetchReviewsHistory } from "../../../../redux/actions/admin-index"
import { LoadingButton } from "@mui/lab"

const EditableReply = ({rev, logs, setLogs, id}) => {
    const dispatch = useDispatch()
    const textareaRef = useRef(null);
    const [changedReply, setchangedReply] = useState("")
    const [replyContent, setReplyContent] = useState("")
    const [saveLoading, setSaveLoading] = useState("")
    const [confirmLoading, setConfirmLoading] = useState("")

    
    const cancelReply = (replyId) => {
        axios.post(`${baseUrlDev}reviews/${replyId}/reply/cancel`, {} ,{headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then((res) => {
            dispatch(fetchReviewsHistory(id))
            dispatch(showAlert(true, "Reply cancelled successfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        })
    }

    const confirmReply = (replyId) => {
        setConfirmLoading(replyId)
        axios.post(`${baseUrlDev}reviews/${replyId}/reply/confirm`, {} ,{headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then((res) => {
           const replyIndex = logs.findIndex(item => item.id === replyId)
           const updatedReplies = [...logs]
           updatedReplies[replyIndex].type = "reply"
           setLogs(updatedReplies)
           setConfirmLoading("")
            dispatch(showAlert(true, "Reply confirmed successfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => {
            setConfirmLoading("")
        })
    }

    const changeReply = (replyId) => {
        setSaveLoading(replyId)
        axios.post(`${baseUrlDev}reviews/${replyId}/reply/update`, {comment: replyContent} ,{headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then((res) => {
            setSaveLoading("")
            setchangedReply("")
            const replyIndex = logs.findIndex(item => item.id === replyId)
            const updatedReplies = [...logs]
            updatedReplies[replyIndex].content = replyContent
            setLogs(updatedReplies)

            dispatch(showAlert(true, "Reply edited successfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => {
            setSaveLoading("")
            setchangedReply("")
        })
    }

    useEffect(() => {
        adjustTextareaHeight()

      }, [replyContent]);

      const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      };

    return(
        <div className="reply-history" key={`history${rev.id}`}>
            <div className="d-flex" style={{alignItems: 'flex-start'}} >
                <div className={`d-flex space-nowrap ${rev.user?.name === "Owner From AI" ? "align-items-end" : "align-items-center"}`}>
                    {rev.user ? 
                        <img src={rev.user.avatar ? rev.user.avatar : 
                        rev.user.name !== "Owner From AI" ? "https://irevu.com/images/avatar_default.svg" : 
                        "/admin-icons/robot-ai.svg"} 
                        alt="user"
                    />

                    :
                        <img src="https://irevu.com/images/avatar_default.svg" alt="user"/>
                    }
                    <h6>{rev.user ? rev.user.name : 'User'}</h6>
                </div>
                <span>-</span> 
                <div>
                    <p className="reply-p-title">is requesting a response <b>{rev.date}</b></p>
                    <Collapse in={rev.id !== changedReply}>
                        <div style={{marginTop: "10px"}}>
                            <p>{rev.content}
                                <FiEdit className="pointer" style={{color: "#1872cd", display: "inline-block", position: "relative", bottom: "-3px", marginLeft: "10px"}} onClick={() => {
                                    setReplyContent(rev.content)
                                    setchangedReply(rev.id)
                                }
                                }/>
                            </p>
                        </div>
                    </Collapse>
                    <Collapse in={rev.id === changedReply}>
                        <div className="change-reply-content">
                            <textarea ref={textareaRef} className="custom-horizontal-scrollbar" type="text" value={replyContent} onChange={(e) => setReplyContent(e.target.value)}>{replyContent}</textarea>
                            <div>
                                <button onClick={() => {
                                    setReplyContent(rev.content)
                                    setchangedReply("")
                                }}>Cancel</button>
                                    <LoadingButton
                                        style={{paddingLeft: saveLoading === rev.id && "30px", textTransform: "capitalize"}}
                                        className="delete-btn"
                                        onClick={() => changeReply(rev.id)}
                                        loadingIndicator="Saving..."
                                        loading={saveLoading == rev.id}
                                    >
                                        {saveLoading != rev.id && "Save"}
                                    </LoadingButton>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
            <div className="confirm-reply-buttons">
                <button onClick={() => cancelReply(rev.id)}>Cancel</button>
                <LoadingButton
                    style={{paddingLeft: confirmLoading == rev.id && "25px", textTransform: "capitalize"}}
                    className="save-btn-confirm"
                    onClick={() => confirmReply(rev.id)}
                    loading={confirmLoading == rev.id}
                >
                    Confirm
                </LoadingButton>
            </div>
        </div>
    )
}
export default EditableReply