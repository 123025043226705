import React, {useState} from "react"
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const Rating = ({inputName, handleAvg}) => {
    const [hover, setHover] = useState(0);
    const [rating, setRating] = useState(0)

    const handleChange = (index) => {
        setRating(index)
        handleAvg(index, inputName)
    }

    return(
        <div>
            {[...Array(5)].map((star, index) => {
            index += 1;
                return (
                    <>
                        <label htmlFor={inputName + index}
                            key={inputName + index}
                                id={inputName}
                                onClick={() => {
                                    handleChange(index)
                                }}
                                onMouseEnter={() => {
                                    setHover(index)
                                }}
                                onMouseLeave={() => {
                                    setHover(rating)
                                }}
                                onDoubleClick={() => {
                                    setRating(0);
                                    setHover(0);
                                    handleAvg(0, inputName)
                                }}
                            >
                            <span>
                                {index <= (hover || rating) ? <AiFillStar style={{color: "rgba(241 170 9 / 85%)"}}/> : <AiOutlineStar style={{color: "#80808070"}}/>}
                            </span>
                        </label>
                    </>
                );
            })}
        </div>
    )
}
export default Rating