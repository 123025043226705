import React, {useEffect, useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import MUIDataTable from "mui-datatables";
import { deleteDownload, fetchDownloads } from "../../../../../redux/actions/admin-index";
import { showAlert } from "../../../../../redux/actions";
import { createTheme, ThemeProvider, Dialog, DialogTitle, Select, FormControl, MenuItem, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "../../../helpers/loader";
import Header from "../../../../Helpers/header";
import axios from "axios";
import { baseUrlDev } from "../../../../Helpers/baseUrl";
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser";
import { LoadingButton } from "@mui/lab";
import AlertComponent from "../../../../Helpers/Alert";
import { AiOutlineCloudDownload } from "react-icons/ai";

const Downloads = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [sortingColumnName, setSortingColumnName] = useState("Due Date");
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [deleteLogging, setDeleteLogging] = useState(false)
    const [fileId, setFileId] = useState()

    useEffect(() => {
        dispatch(fetchDownloads())
    }, [])

    const downloads = useSelector(state => state.getDownloadsReducer.downloads)

    
    if(downloads === undefined){
        return(
            <Loader absoluteFlex={true}/>
        )
    }

    const handleDownload = (path) => {
        const link = document.createElement('a');
        link.setAttribute('href', path);
        link.setAttribute('_target', 'blank');
        document.body.appendChild(link);
        link.click();
    }

    const handleDelete = () => {
        setDeleteLogging(true)
        axios.post(`${baseUrlDev}user/files/destroy/${fileId}`, {}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            setDeleteLogging(false)
            setOpenConfirmation(false)
            dispatch(deleteDownload(fileId))
            dispatch(showAlert(true, "File Deleted Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        })
    }

    const columns = [
        { 
            name: t("Filename"), 
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            }
        },
        { 
            name: t("Date"), 
            options: { 
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <p className="custom-text-datatable">{value}</p>
                ),
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
        { 
            name: "", 
            options: {  
                filter: true,
                viewColumns: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className="mydev-datatable-actions">
                            <button className="remove-lead" onClick={() => {
                                setFileId(value[0])
                                setOpenConfirmation(true)
                            }}>
                                <span className="icon-rounded-trash"></span>
                                {t("Delete")}
                            </button>
                            <button className="edit-lead-btn" onClick={() => handleDownload(value[1])}>
                                <AiOutlineCloudDownload className="download-icon-table"/>
                                {t("Download")}
                            </button>
                        </div>
                    )
                },
                customHeadLabelRender: (columnMeta) => {
                    return <h4 className="custom-label-datatable">{columnMeta.name}</h4>
                }
            } 
        },
    ];

        
    const data = downloads.data.length > 0 ? downloads.data.map(file => {
        return [file.filename,  file.created_at, [file.id, file.path]]
    }) : []

    const options = {
        search: true,
        selectableRows: "none",
        selectableRowsHideCheckboxes: false,
        download: true,
        print: true,
        delete: false,
        viewColumns: true,
        filter: true,
        filterType: "checkbox",     
        responsive: "simple",
        sortThirdClickReset: true,
        draggableColumns: {
            enabled: true
        },
        // customToolbar: () => (
        //     <>
        //       <FormControl>
        //         <Select
        //           value={sortingColumnName !== null ? sortingColumnName : ''}
        //           onChange={handleSortingChange}
        //           displayEmpty
        //         >
        //           <MenuItem value='' disabled>Sort By</MenuItem>
        //           {columns.map((column, index) => (
        //             <MenuItem key={column.name} value={column.name}>{column.name}</MenuItem>
        //           ))}
        //         </Select>
        //       </FormControl>
        //       <FormControl>
        //         <Select
        //           value={sortingDirection}
        //           onChange={handleSortingDirectionChange}
        //         >
        //           <MenuItem value='asc'>Ascending</MenuItem>
        //           <MenuItem value='desc'>Descending</MenuItem>
        //         </Select>
        //       </FormControl>
        //     </>
        //   ),
        textLabels: {
            body: {
              noMatch: t("Sorry, no matching records found"),
              toolTip: t("Sort"),
              columnHeaderTooltip: column => `${t("Sort for")} ${column.label}`
            },
            pagination: {
              next: t("Next Page"),
              previous: t("Previous Page"),
              rowsPerPage: t("Rows per page"),
              displayRows: t("of"),
            },
            toolbar: {
              search: t("Search"),
              downloadCsv: t("Download CSV"),
              print: t("Print"),
              viewColumns: t("View Columns"),
              filterTable: t("Filter Table"),
            },
            filter: {
              all: t("All"),
              title: t("Filtres"),
              reset: "Reset",
            },
            viewColumns: {
              title: t("Show Columns"),
              titleAria: t("Show/Hide Table Columns"),
            },
            selectedRows: {
              text: t("row(s) selected"),
              delete: t("Delete"),
              deleteAria: t("Delete Selected Rows"),
            },
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
        ),
        onTableChange: (action, tableState) => {
            if (action === 'sort') {
              const { dataIndex, sortOrder } = tableState;
              setSortingColumnName(dataIndex);
              setSortingDirection(sortOrder);
            }
          },
        sortOrder: {
            name: sortingColumnName,
            direction: sortingDirection
        },
        onRowsDelete: (rowsDeleted) => {
  
        }
    };

    const getMuiTheme = () =>
        createTheme({
            components: {
        }
    });

    return(
        <>
            <Dialog onClose={() => setOpenConfirmation(false)} open={openConfirmation}>
                <div style={{padding: "20px"}}>
                    <DialogTitle>{t("Are you sure you want to delete this file")}?</DialogTitle>
                </div>
                <div className="flex-btns">
                    <LoadingButton
                        style={{paddingLeft: loading && "30px"}}
                        className="delete-btn"
                        onClick={() => handleDelete()}
                        loadingIndicator={t("Deleting") + "..."}
                        loading={deleteLogging}
                    >
                        {!deleteLogging && t("Delete")}
                    </LoadingButton>
                    <button onClick={() => {
                        setOpenConfirmation(false)
                        setDeleteLogging(false)
                    }}>{t("Cancel")}</button>
                </div>
            </Dialog>
            <AlertComponent/>
            <div className="table-header-container">
                <Header title={t("Account")} subtitle={t("Downloads")} />
            </div>
            <div className="new-datatable nowrap-table position-relative">
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
        </>
    )
}
export default Downloads