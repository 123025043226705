import React, {Suspense, useState} from "react"
import { Modal, Slide, Autocomplete, TextField, Chip } from "@mui/material";
import {LoadingButton } from "@mui/lab"
import { useEffect } from "react";
import axios from "axios";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { fetchLocationTags, loadingTables } from "../../../../redux/actions/admin-index";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { showAlert } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {Alert} from "@mui/material";
import { useTranslation } from "react-i18next";
const SketchPicker = React.lazy(() => import("./Colorpicker"));

const EditTag = ({openEdit, setOpenEdit, tag, location, tags, setTags}) => {
    const dispatch = useDispatch()
    const [name, setName] = useState()
    const [color, setColor] = useState()
    const [keywords, setKeywords] = useState([])
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([])
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    const [t] = useTranslation()
    const handleClose = () => {
        setOpenEdit(false)
        setName('')
        setColor('#fff')
        setErrors([])
    }
    useEffect(() => {
        if(tag){
            setName(tag[1])
            setColor(tag[2])
            setKeywords(tag[3])
        }
    }, [tag])

    const submitTag = () => {
        if(selected && (!location || location == "all")){
            apiRequest(selected)
        }
        if (location && location !== "all") {
            apiRequest(location)
        }
    }

    const apiRequest = (id) => {
        if(name.length < 4 || name.length > 35){
            setErrors([...[], "Tag name must be between 4-35 chars"])
            return
        }
        setLoading(true)
        let data = {name, color,  keywords: typeof(keywords) == "string" ? [] :  keywords, tag_id: tag[0]}
        axios.post(`${baseUrlDev}locations/${id}/tags/update`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            const updatedItems = tags.map(item => {
                if (item.id == tag[0]) {
                  return {
                    ...item,
                    name,
                    color,
                    keywords: typeof(keywords) == "string" ? [] :  keywords, tag_id: tag[0]
                  };
                }
                return item;
            });
            setTags(updatedItems);
        }).then(res => {
            setLoading(false)
            setOpenEdit(false)
            dispatch(showAlert(true, "Tag Edited Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => {
            Object.keys(err.response.data.errors).map(key => {
                setErrors([...errors, err.response.data.errors[key][0]])
            })
        })
    }
    const handleKeywords = (event, values) => {
        setKeywords(values)
    }

    return(
        <Modal 
            open={openEdit}
            onClose={handleClose}
            aria-labelledby="modal-tag-title"
            aria-describedby="modal-tag-description"
        >
            <Slide direction="left" in={openEdit} {...({ timeout: 500 })}>
                <div className={`custom-modal-dialog`}>
                    {errors && errors.map(err => {
                        return(
                            <div className="alert-inside-modal" key={err}>
                                <Alert severity="error" className="custom-error-alert">{err}</Alert>
                            </div>
                        )
                    })}
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>Edit Tag</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-div-container custom-scrollbar-vertical">
                                <div className="field-container">
                                    <label>{t("Tag Name")}</label>
                                    <input type="text" minLength={4} maxLength={35} defaultValue={tag && tag[1]} placeholder={t("Add tag name")} onChange={(e) => {
                                        setName(e.target.value)
                                        setErrors([])
                                    }}/>
                                </div>
                                <div className="field-container">
                                    <label>{t("Keywords")}</label>
                                    <Autocomplete
                                        multiple
                                        id="tags-filled"
                                        options={[]}
                                        defaultValue={tag && tag[3]}
                                        freeSolo
                                        onChange={handleKeywords}
                                        renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t("Enter to submit keywords")}
                                                className="custom-autocomplete"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    maxLength: 100
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="field-container fit-content-w">
                                    <label>{t("Background Color")}</label>
                                    <Suspense fallback={"Loading"}>
                                        <SketchPicker setColor={setColor} color={color}/>
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <LoadingButton
                                style={{paddingLeft: loading && "30px"}}
                                className="save-btn"
                                onClick={() => submitTag()}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                            <button className="close-btn" onClick={() => setOpenEdit(false)}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default EditTag