import React, {useEffect} from "react"
import { useDispatch, useSelector } from "react-redux"
import { getGroupLocation } from "../../../redux/actions/admin-index"

const OnlyLocationWrapper = (props) => {
    return(
        <>
           {props.children}
        </>
    )
}
export default OnlyLocationWrapper