import React from "react"
import { useTranslation } from "react-i18next"

const Header = ({title, subtitle}) => {
    const { t, i18n } = useTranslation();
    return(
        <div className="flex-header-container-title">
            <h1>{t(title)}</h1>
            <h6>{t(subtitle)}</h6>
        </div>
    )
}
export default Header