import React, {useState, useEffect} from "react"
import { LoadingButton } from "@mui/lab"
import { Collapse, Fade} from "@mui/material"
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import QuickReplyModal from "../../modals/reviews/quickReply"
import { useSelector, useDispatch } from "react-redux"
import { fetchReviewsHistory, reviewHistoryLoading } from "../../../../redux/actions/admin-index"
import {HiOutlineReply} from "react-icons/hi"
import {CustomLoadingButton} from "../../helpers/LoadingButtonCustom"
import FullServiceRequired from "./fullServiceRequired"
import { useTranslation } from "react-i18next"
import ConnectPopup from "./connect_popup"

const Reply = ({show, setShow, id, source, rev}) => {
    const dispatch = useDispatch()
    const [suggestedReply, setSuggestedReply] = useState(false)
    const [text, setText] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingReply, setLoadingReply] = useState(false)
    const [open, setOpen] = useState(false)
    const [count, setCount] = useState(0)
    const [replyError, setReplyError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [openDialog, setOpenDialog] = useState(false)
    const [openConnect, setOpenConnect] = useState(false)
    const user = useSelector(state => state.getUserInfo.user)
    const {t} = useTranslation()

    const writeReply = (e) =>  {
        setText(e.target.value)
        setCount(e.target.value.length)
    }

    const quickReplyText = (words) => {
        setText(words)
    }
    useEffect(() => {
        if(rev && rev.content){
            setSuggestedReply(rev.ai_suggest == 1 ? true : false)
            setText(rev.ai_response)
        } else {
            setSuggestedReply(true)
        }
    }, [rev])

    const getReplyFromChatgpt = () => {
        if(!suggestedReply){
            setLoadingReply(true)
            axios.post(`${baseUrlDev}reviews/${id}/reply/suggest`, {}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
            .then((res) => {
            setSuggestedReply(true)
            setLoadingReply(false)
            setText(res.data.message)
            }).catch(err => {
                setErrorText(err.response.data?.message)
                setSuggestedReply(false)
                setLoadingReply(false)
                setReplyError(true)
                setTimeout(() => {
                    setReplyError(false)
                }, 5000)
            })
        }
    }

    const submitReply = () => {
        setLoading(true)
        let data = {
            comment: text,
            commenter_name: user.user.first_name,
            commenter_email: user.user.email
        }
        axios.post(`${baseUrlDev}reviews/${id}/reply`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            setText('')
            setLoading(false)
            setShow(false)
            dispatch(reviewHistoryLoading())
            dispatch(fetchReviewsHistory(id))
        })
    }

    return(
        <Collapse in={show}>
            <QuickReplyModal open={open} setOpen={setOpen} quickReplyText={quickReplyText} source={source}/>
            <FullServiceRequired open={openDialog} setOpen={setOpenDialog}/>
            <ConnectPopup open={openConnect} setOpen={setOpenConnect} submitReply={submitReply} source={rev.source?.name}/>
            <div className="reply-box-container">
                <div className="text-note-container field-container">
                    <label>{t("Reply")}</label>
                    {(rev.connected == null && rev.source?.name != "iRevU") && <span className="text-alert-source">*Your reply wont show up on {rev.source?.name}</span>}
                    <textarea  maxLength={source && source.name === "OptSpot" ? 160 : ""} className="custom-scrollbar-vertical" onChange={(e) => writeReply(e)} value={text}></textarea>
                    {source && source.name === "OptSpot" && <h6 className="chars-left">{count} / <span>160</span></h6>}
                </div>
                <div>
            
                    <div className="box-container-replies">
                        <button onClick={() => setOpen(true)}><HiOutlineReply/> {t("Quick Replies")}</button>
                        {rev.plan !== 2 ? 
                            <button onClick={() => setOpenDialog(true)}>{t("Suggest me a reply")}</button>
                        :
                        <CustomLoadingButton
                                style={{paddingLeft: loadingReply && "30px", paddingTop: "5px", paddingBottom: "5px"}}
                                loading={loadingReply}
                                onClick={() => getReplyFromChatgpt()}
                                disabled={suggestedReply}
                            >
                                {t("Suggest me a reply")}
                        </CustomLoadingButton>
                       }
                       <Fade in={replyError}>
                            <span>{errorText ? errorText : 'Something went Wrong!'}</span>
                       </Fade>
                    </div>
                    
                    <div className="reply-box-container-btns">
                        <button className="close-btn" onClick={() => {
                            setShow(false)
                            setLoading(false)
                        }}>{t("Close")}</button>
                        {(rev.connected == false && rev.source?.name != "iRevU") ?
                            <LoadingButton
                                style={{paddingLeft: loading && "30px"}}
                                className="save-btn save-btn-white"
                                onClick={() => setOpenConnect(true)}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                            :
                            <LoadingButton
                                style={{paddingLeft: loading && "30px"}}
                                className="save-btn save-btn-white"
                                onClick={() => submitReply()}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                        }
                    </div>
                </div>
            </div>
        </Collapse>
    )
}
export default Reply