import React from "react"
import  ReactApexChart  from "react-apexcharts"
import { useTranslation } from "react-i18next"

const KeywordChart = ({data}) => {
    const {t} = useTranslation()
    let used = Object.values(data)
    const chart =  {
        options: {
            chart: {
                type: 'bar',
                toolbar: {
                    show: false
                },
                stacked: true,
                animations: {
                    enabled: true,
                    easing: 'linear',
                    speed: 600,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                }
            },
            colors: ["#66C2FF", "#3E95CD"],
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'right', 
                fontSize: '14px',
                fontFamily: 'Poppins-Regular',
            },
            
            stroke: {
                width: [2, 2, 2, 2],
                curve: 'smooth'
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [3]
            },
            legend:{
                show: true,
                position: 'bottom',
                horizontalAlign: 'center', 
                fontSize: '14px',
                fontFamily: 'Poppins-Regular',
            },
            noData: {
                text: "There's no data",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0
            },
            labels: [t("January"), t("February"), t("Mars"), t("April"), t("May"), t("June"), t("July"), t("August"), t("September"), t("October"), t("November"), t("December")]
        }
    }
    const series = [
        {
            name: t("Keyword Used bar"),
            type: 'bar',
            data: used
        },
        {
            name: t("Keyword Used line"),
            type: 'line',
            data: used
        }
    ]
    return(
        <div>
            <ReactApexChart
                options={chart.options}
                series={series}
                width="100%"
                height={450}
            />
        </div>
    )
}

export default KeywordChart