import React, {useState, useEffect} from "react"
import { FormControl, Select, InputLabel, MenuItem, FormControlLabel, Checkbox } from "@mui/material"
import { useDispatch } from "react-redux"
import MySelect from "../../Helpers/MySelect"
import { useSelector } from "react-redux"
import { fieldLabelName, fieldLabelPosition, fieldLabelVisbility } from "../../../redux/actions"
import { useTranslation } from "react-i18next"

const FieldLabel = ({form_fields}) => {
    const dispatch = useDispatch()
    const [position, setPosition] = useState('top')
    const {t} = useTranslation()

    const show = useSelector(state => state.FormFieldAdded.visibility)

    const handleChange = (e) => {
        setPosition(e.target.value)
        dispatch(fieldLabelPosition(e.target.value))
    }

    useEffect(() => {
        if(form_fields){
            dispatch(fieldLabelName(form_fields.data.name))
            dispatch(fieldLabelVisbility(form_fields.data.visibility == 0 ? false : true))
        } else {
            dispatch(fieldLabelName(''))
            dispatch(fieldLabelVisbility(true))
        }
    }, [form_fields])

    const handleVisbilityChange = (e) => {
        dispatch(fieldLabelVisbility(e.target.checked))
    }

    return(
        <div className="label-field-container">
            <div>
                <label>{t("Form Field Label")}</label>
                <input type="text" defaultValue={form_fields && form_fields.data && form_fields.data.name} name="name" placeholder={t("Name")} onChange={(e) => dispatch(fieldLabelName(e.target.value))}/>
                <span>{t("Please enter a label for the field")}</span>
            </div>
            {/* <div>
                <label>Form Field Label Position</label>
                <FormControl fullWidth>
                    <InputLabel id="label-position">Label Position</InputLabel>
                    <Select
                        labelId="label-position"
                        id="label-position-select"
                        value={position}
                        label="position"
                        input={<MySelect/>}
                        onChange={handleChange}
                    >
                        <MenuItem value={'top'}>top</MenuItem>
                        <MenuItem value={'right'}>right</MenuItem>
                        <MenuItem value={'left'}>left</MenuItem>
                        <MenuItem value={'bottom'}>bottom</MenuItem>
                    </Select>
                </FormControl>
                <span>Where would you like your label to appear</span>
            </div> */}
            <div>
                <FormControlLabel
                    control={
                        <Checkbox 
                            checked={show} 
                            onChange={(e) => handleVisbilityChange(e)} name="show label" 
                            sx={{
                                color: "#00B4E5!important",
                                '&.Mui-checked': {
                                    color: '#00B4E5',
                                },
                            }}
                        />
                    }
                    label={t("Show Label")}
                />
                <span>{t("Display the label or not")}</span>
            </div>
        </div>
    )
}
export default FieldLabel