import React, { useState } from 'react';
import { Grid } from "@mui/material"
import AnalysisBox from '../Analysis_Report/AnalysisBox';
import SetupConnectionCard from './SetupConnectionCard';
import SetupSuggestionsChart from './SetupSuggestionsChart';
import WelcomeSection from './WelcomeSection';
import NotValid from './NotValid';
import { baseUrlDev } from '../../../Helpers/baseUrl';
import { getLocalUser } from '../../../TokenControl/parts/useLocalUser';
import axios from 'axios';
import SetupSuggestionModal from './SetupSuggestionModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SetupSuggestions = ({ data, location }) => {
    const [show, setShow] = useState(data?.setupSuggestions?.show);
    const [address_not_valid, setAddressNotValid] = useState(data?.setupSuggestions?.address_not_valid);
    const [modalProps, setModalProps] = useState({});
    const [openSetupModal, setOpenSetupModal] = useState(false);
    const groupSelected = useSelector(state => state.groupSelected.group)

    const [t] = useTranslation()
    const handleRequest = () => {
        setShow(false);
        if(!data?.setupSuggestions.lists.some(el => !el.complete)){
            axios.post(`${baseUrlDev}dashboard/hideSetupSuggestions/`, { location }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setShow(res?.data?.status);
            });
        }
    }

    const handleSetupCardClick = (item) => {
       if(item) {
        setOpenSetupModal(true);
        setModalProps(item);
       }
    }
    return (
        <Grid container spacing={2}>
            <SetupSuggestionModal open={openSetupModal} setOpen={setOpenSetupModal} modalProps = {modalProps} />
            {
                show &&
                <Grid item sm={12} lg={6} className={`d-flex 
                    ${((data?.newLocationMessage?.show === false) && (!address_not_valid || (location && location === "all") || groupSelected))  && 'full-width'}
                `}>
                    <AnalysisBox title={t("Setup Suggestions")} xs={12} tooltip={t("Complete site setup suggestion to use iRevu on the right way")}>
                        <div className="suggestion_wrapper">
                            <div className="chart_wrap">
                                <SetupSuggestionsChart completed={data?.setupSuggestions?.completed} />
                                {
                                    data?.setupSuggestions?.can_hide ? '' : <div className='d-flex justify-content-center'>
                                        <button className='btn hide_btn' onClick={handleRequest}>{t("Don't show this to me")}</button>
                                    </div>
                                }
                            </div>
                            <div className='w-100 connection_cards_wrapper'>
                                {
                                    data?.setupSuggestions?.lists?.map((item, index) => (
                                        <SetupConnectionCard handleClick={handleSetupCardClick} apps={item.apps} title={item?.name} complete={item?.complete} link={item?.link} key={index} />
                                    ))
                                }
                            </div>
                        </div>
                    </AnalysisBox>
                </Grid>
            }
            {
                data?.newLocationMessage?.show && !address_not_valid &&
                <Grid item sm={12} lg={6} className={`d-flex ${show === false && 'full-width'}`}>
                    <WelcomeSection data={data?.newLocationMessage} />
                </Grid>
            }
            {
                address_not_valid && (location && location !== "all") && !groupSelected &&
                <Grid item sm={12} lg={6} className={`d-flex`}>
                    <NotValid setAddressNotValid={setAddressNotValid} addressSuggestion={data.addressSuggestion}/>
                </Grid>
            }
        </Grid>
    )
}

export default SetupSuggestions;