import React from 'react';
import { Grid } from '@mui/material';
import {BiUpload} from "react-icons/bi"
import { useTranslation } from 'react-i18next';
import {IoIosRemoveCircleOutline} from "react-icons/io"

const MAX_IMAGES = 3
const AdditionalBusinessInfo = ({ handleFileChange, formData, setFormData }) => {
    const {t} = useTranslation()
    const handleBusinessPhotos = (e) => {
        let files = [...e.target.files]
        e.target.value = ''
        Object.keys(files).forEach(file => {
            setFormData(prevState => ({ ...prevState, business_photos: [...prevState.business_photos, files[file]].slice(0, MAX_IMAGES)}));
        });

    }
    const handleDeleteFile = (index) => {
        let newFiles = [...formData?.business_photos];
        newFiles.splice(index, 1);
        setFormData({ ...formData, business_photos: newFiles });
    }

    return (
        <div>
            <div className="additional-business-information location_settings_card">
                <Grid container space={3}>
                    <Grid item xs={12}>
                        <div>
                            <h5 className='title'>{t("Additional Business Information")} ({t("optional")})</h5>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                            <label htmlFor="">{t("Logo")} ({t("recommended")})</label>
                            <div className="d-flex align-items-center flex-wrap gap-10">
                                <div className="image-uploaded">
                                    {formData && formData.logo && <img src={typeof(formData.logo) === 'object' ? URL.createObjectURL(formData?.logo) : formData?.logo} alt="Logo" />}
                                </div>
                                <div className="position-relative">
                                    <input type="file" name="" id="" accept='image/png, image/jpg, image/jpeg' onChange={(e) => handleFileChange(e, setFormData, 'logo')} />
                                    <button className='btn upload-btn'>{t("Upload Logo")}</button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className="form-group">
                            <label htmlFor="">{t("Business photo")}</label>
                            <div className="d-flex align-items-center flex-wrap gap-10">
                                <div className="position-relative">
                                    <input type="file" multiple name="" accept='image/png, image/jpg, image/jpeg' id="business_photos" onChange={e => handleBusinessPhotos(e)} />
                                    <label className='business-photos-label'  htmlFor="business_photos">
                                        {t("Upload Business Photos")}
                                        <BiUpload/>
                                    </label>
                                </div>
                            </div>
                       
                            <div className={`${formData?.business_photos && formData?.business_photos.length > 0 && "item"} image-uploaded-multiple`}>
                                {formData?.business_photos && formData.business_photos.map((img, i) => {
                                    return(
                                        <div key={`imagebusiness${i}}`}>
                                            <IoIosRemoveCircleOutline onClick={() => handleDeleteFile(i)}/>
                                            <img src={typeof(img) === 'object' ? URL.createObjectURL(img) : img} alt="Logo" />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="show-uploaded-text">
                                <span> {formData?.business_photos && formData?.business_photos.length}/3</span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default AdditionalBusinessInfo