import React from "react";
import PropTypes from 'prop-types';
import { styled, StepConnector , stepConnectorClasses } from '@mui/material';
import {MdSubscriptions, MdPayment} from "react-icons/md"

export const SubscriptionStepper = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 25,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#00B2E3"
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderBottom: "1px dashed",
        borderTopWidth: 0,
        borderColor: "#B0B7C3"
    },
}));

const SubscriptionStepIconRoot = styled('div')(() => ({
    backgroundColor: "transparent",
    zIndex: 1,
    color: '#ccc',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: "pointer"
}));

export function SubscriptionStepIcon(props) {
    const { active, completed, className } = props;
  
    const icons = {
      1: "/admin-icons/plan_selection_active.svg",
      2: "/admin-icons/payment.svg"
    };

    const completedIcons = {
      1: "/admin-icons/plan_selection_active.svg",
      2: "/admin-icons/payment_done.svg"
    }
  
    return (
      <SubscriptionStepIconRoot ownerState={{ completed, active }} className={className}>
        {active || completed ? <img src={completedIcons[props.icon]}/> : <img src={icons[props.icon]} />}
      </SubscriptionStepIconRoot>
    );
  }
  
  SubscriptionStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};