import React, {useState, useEffect, useMemo} from "react"
import { LoadingButton } from "@mui/lab";
import { Modal, Slide, FormControl, Select, MenuItem, Skeleton, TextField, ListSubheader, Alert } from "@mui/material";
import MySelect from "../../helpers/mySelect";
import {useSelector, useDispatch} from "react-redux"
import { fetchLocAvaliableSources, loadingTables, fetchLocationSources } from "../../../../redux/actions/admin-index";
import axios from "axios";
import { showAlert } from "../../../../redux/actions";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { useTranslation } from "react-i18next";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
 
const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const AddSource = ({open, setOpen, location}) => {
    const dispatch = useDispatch()
    const [source, setSource] = useState("select")
    const [search, setSearch] = useState("")
    const [url, setUrl] = useState("")
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [t] = useTranslation()
    const selected = useSelector(state => state.groupLocationSelected.group_location)

    useEffect(() => {
        dispatch(fetchLocAvaliableSources())
    }, [])

    const sources = useSelector(state => state.getLocationAvaliableSources.sources)
    const displayedSources = useMemo(() => sources && sources.data.filter(source => containsText(source.name, search)), [search, sources]);


    const handleClose = () => {
        setOpen(false)
        setLoading(false)
        setErrors([])
    }

    const handleChange = (e) => {
        let value = e.target.value
        setSource(value)
    }

    const submitSource = () => {
        if(selected && (!location || location == "all")){
            apiRequest(selected)
        }
        if (location && location !== "all") {
            apiRequest(location)
        }
    
    }

    const apiRequest = (id) => {
        setLoading(true)
        let data = {source, url}
        axios.post(`${baseUrlDev}locations/${id}/sources/add`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            setTimeout(() => {
                dispatch(loadingTables())
            }, 500)
            dispatch(fetchLocationSources(id))
        }).then(res => {
            setLoading(false)
            setOpen(false)
            dispatch(showAlert(true, res.data.message ? res.data.message : "Source Added Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => {
            setLoading(false)
            if(!err.response.data.errors){
                err.response.data.message && setErrors([...errors, err.response.data.message])
            } else {
                Object.keys(err.response.data.errors).map(key => {
                    setErrors([...errors, err.response.data.errors[key][0]])
                })
            } 
            setTimeout(() => {
                setErrors([])
            }, 5000)
        })
    }

    return(
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-tag-title"
            aria-describedby="modal-tag-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className={`custom-modal-dialog`}>
                    {errors && errors.map(err => {
                        return(
                            <div className="alert-inside-modal" key={err}>
                                <Alert severity="error" className="custom-error-alert">{err}</Alert>
                            </div>
                        )
                    })}
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>{t("Add Source")}</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-div-container custom-scrollbar-vertical">
                                <div className="field-container">
                                    <label>{t("Source")}</label>
                                   
                                    <FormControl fullWidth>
                                    {sources ? 
                                        <Select
                                            name="zindex-dropdown"
                                            labelId="input-type-one"
                                            id="input-type-one-select"
                                            value={source}
                                            label="position"
                                            input={<MySelect/>}
                                            onChange={handleChange}
                                            MenuProps={{ PaperProps: { sx: { maxHeight: 300} }}}
                                            renderValue={value => {
                                                if(value === "select"){
                                                    return <em>{t("Please Select Source")}</em>
                                                }
                                                const item = sources.data.find((el) => el.id == value ); 
                                                return item.name
                                            }}
                                    >
                                    <ListSubheader>
                                        <TextField
                                            size="small"
                                            autoFocus
                                            placeholder={t("Search Source")}
                                            fullWidth
                                            onChange={(e) => setSearch(e.target.value)}
                                            onKeyDown={(e) => {
                                                if(e.key !== 'Escape'){
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                        <MenuItem disabled value="select">
                                            <em>{t("Please Select Source")}</em>
                                        </MenuItem>
                                        {
                                            displayedSources.map(source => {
                                                return(
                                                    <MenuItem key={source.id} value={source.id}>{source.name}</MenuItem>
                                                )
                                            })
                                        }
                                            
                                        </Select>
                                         : <Skeleton style={{height: "45px", transform: 'unset'}}/>}
                                    </FormControl>
                               
                                </div>
                                <div className="field-container">
                                    <label>URL</label>
                                    <input type="text" placeholder={t("Add Source URL")} onChange={(e) => setUrl(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                            <LoadingButton
                                style={{paddingLeft: loading && "30px"}}
                                className="save-btn"
                                onClick={() => submitSource()}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                            <button className="close-btn" onClick={() => handleClose()}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default AddSource