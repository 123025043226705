import React, {useState} from "react"
import Quagga from "quagga";
import { useEffect } from "react";
import config from "./config.json";
import { ClickAwayListener } from "@mui/material";


const BarcodeReaderComponent = (props) => {
    const [showBg, setShowBg] = useState(true)
    const { onDetected, inputRef, setScan } = props;
    useEffect(() => {
        Quagga.init(config, err => {
                if (err) {
                    setShowBg(false)
                    inputRef.current.focus()
                }
            Quagga.start();
            return () => {
                Quagga.stop()
            }
        });
        Quagga.onDetected(detected);
    }, []);

      const detected = result => {
        onDetected(result[0].codeResult.code);
        setScan(false)
        Quagga.stop()
      };

      const closeOnOutside = () => {
        setScan(false)
        setShowBg(false)
        Quagga.stop()
      }

      if(showBg){
        return(
            <div className="custom-scanner">
                <ClickAwayListener onClickAway={() => closeOnOutside()}>
                    <div id="interactive" className="viewport" />
                </ClickAwayListener>
            </div>
          )
        
          
      }
}
export default BarcodeReaderComponent