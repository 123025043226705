import React, { useState, useEffect } from "react"
import { ClickAwayListener, Skeleton, Badge, Fade, CircularProgress, Collapse  } from "@mui/material"
import LocationSelectSearch from "./parts/SelectSearch"
import LocAndGroup from "./parts/LocAndGroup"
import { useSelector, useDispatch } from "react-redux"
import { useLocation, Link, useNavigate } from "react-router-dom"
import { fetchNotificationsCount, fetchTopNavData, clearTopNav, openVideoModal, irevuMultiLanguage, fetchWallet } from "../../redux/actions/admin-index"
import YoutubeVideo from "./modals/Information/youtubeVideo"
import BlueTooltip from "./helpers/blueTooltip"
import AddLocation from "./modals/locations/add_locations"
import Notification from "./Notification/Notification"
import { baseUrlDev } from "../Helpers/baseUrl"
import { getLocalUser } from "../TokenControl/parts/useLocalUser"
import axios from "axios"
import UserDropdown from "./UserDropdown"
import { useTranslation } from "react-i18next"
import Wallet from "./modals/wallet/wallet"
import CreateCard from "./modals/wallet/createCard"
import i18n from "../../i18n"
import { Helmet } from "react-helmet"
//Images

const languages = [
    {name: "English (US)", lng: "en"},
    {name: "Franchais (France)", lng: "fr"},
    {name: "Italian (Italy)", lng: "it"},
    {name: "Español (Spain)", lng: "es"},
    {name: "Deutsch (Germany)", lng: "de"}
]


const Navbar = ({ height, title, all, groups, disable_location }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [openSearch, setOpenSearch] = useState(false)
    const [openResult, setOpenResult] = useState(false)
    const [searching, setSearching] = useState(false)
    const [openUserDetails, setOpenUserDetails] = useState(false)
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)
    const [showLoc, setShowLoc] = useState(false);
    const [openPlatformModal, setOpenPlatformModal] = useState(false);
    const [loading, setLoading] = useState()
    const [showLng, setShowLng] = useState(false)
    const [showWallet, setShowWallet] = useState(false)

    const { t, i18n } = useTranslation();

    const onSearch = (e) => {
        if (e.target.value.length >= 3) {
            setSearching(true)
            dispatch(fetchTopNavData(e.target.value))
            setOpenResult(true)
        } else {
            setSearching(false)
            setOpenResult(false)
            dispatch(clearTopNav())
        }
    }

    const activeOnMobNav = useSelector(state => state.showNavbarReducer.show)
    const user = useSelector(state => state.getUserInfo.user)
    const count = useSelector(state => state.getNotificationsCount.notifications_count)
    const results = useSelector(state => state.topNavSearch.res)
    const language = useSelector(state => state.getLanguage.language)
    const wallet = useSelector(state => state.getWalletReducer.wallet)

    useEffect(() => {
        dispatch(fetchNotificationsCount())
        i18n.changeLanguage(user?.user?.lang ? user?.user?.lang : "en")
        dispatch(irevuMultiLanguage(user?.user?.lang ? user?.user?.lang : "en"))
    }, [user])

    useEffect(() => {
        if (results) {
            setSearching(false)
        }
    }, [results])

    useEffect(() => {
        dispatch(fetchWallet())
        setTimeout(() => {
            dispatch(fetchNotificationsCount())
        }, 60000)
    }, [])

    const logout = () => {
        setLoading(true)
        setOpenUserDetails(false)
        axios.post(`${baseUrlDev}logout`, {} , {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            setLoading(false)
            window.location.href = "https://irevu.com"
            localStorage.clear()
        })
        .catch(() => {
            setLoading(false) 
        })
    }

    const changeLanguage = (lng) => {
        axios.post(`${baseUrlDev}language`, {lang: lng}, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            i18n.changeLanguage(lng);
            dispatch(irevuMultiLanguage(lng))
            localStorage.setItem("language", lng)
        })
    }
    return (
        <div className={`search-user-holder ${activeOnMobNav ? "active" : ""}`} style={{ marginTop: height ? height : 0 }}>
            <Helmet>
                <title>{title} - iReview</title>
            </Helmet>
            <Notification open={open} setOpen={setOpen} />
            <YoutubeVideo />
            <AddLocation open={showLoc} setOpen={setShowLoc} title={"Location"} />
            <Wallet open={showWallet} setOpen={setShowWallet}/>
            {loading && <div className="logout-div"><img src={"/admin-images/loading_ireview.gif"} alt="loader" /></div>}
            <div className="search-user-container">
                <div className="d-flex align-items-center">
                    <LocAndGroup all={all} groups={groups} disable_location={disable_location}/>
                    <button className="Location-add" onClick={() => setShowLoc(true)}>{t("Add Location")}</button>
                </div>
                <div className="navbar-right-side">
                    {user &&  user.user && user.user.locations.filter(loc => loc.is_owner == true).length > 0 &&
                        <div className="wallet-container">
                            <div className="balance-wrapper">
                                <small>{t("Balance")}</small>
                                {wallet === undefined ? <Skeleton style={{transform: "unset", height: "15px"}}/> : <span>$ {wallet?.wallet?.balance ? wallet?.wallet.balance : 0}</span>}
                            </div>
                            {wallet ?
                            <div className="wallet-wrapper" onClick={() => setShowWallet(true)}>
                                    <img src={"/admin-icons/wallet.svg"} alt="wallet"/>
                                    <span>{t("Wallet")}</span>
                                </div>
                            :      
                                <div className="wallet-wrapper" style={{opacity: 0.5, cursor: "initial"}}>
                                    <img src={"/admin-icons/wallet.svg"} alt="wallet"/>
                                    <span>{t("Wallet")}</span>
                                </div>
                            }
                        </div>
                    }
                    <button onClick={() => dispatch(openVideoModal())} className="info-btn-container">
                        <BlueTooltip arrow
                            title={`${t("How it works")} - ${t(title)}`}>
                            <div className="video-holder-div">
                                <img src={"/admin-icons/fluent_video.svg"} alt="info" />
                                <h5>{t("Video Tutorial")}</h5>
                            </div>
                        </BlueTooltip>
                    </button>
                    <ClickAwayListener onClickAway={() => {
                        setOpenSearch(false)
                        setOpenResult(false)
                    }
                    }>
                        <div className={`search-input-holder open`}>
                            <input type="search" name="search" className="searchbox-adm-input" placeholder={t("Search Review Here")} onKeyUp={(e) => onSearch(e)} />
                            <span className="searchbox-adm-icon" onClick={() => setOpenSearch(!openSearch)}></span>

                            <Fade in={openResult}>
                                <div className="searchbox-adm-results">
                                    {results && results.data && Object.keys(results.data).length > 0 ?
                                        Object.keys(results.data).map((key, i) => {
                                            return (
                                                <div>
                                                    <h6>{key}</h6>
                                                    <ul>
                                                        {results.data[key].map(item => {
                                                            return (
                                                                <li>
                                                                    <Link to={item.url}>{item.title}</Link>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            )
                                        })

                                        : <div className="search-general">{searching ? 'searching...' : 'Nothing found'}</div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </ClickAwayListener>

                    <ClickAwayListener onClickAway={() => {
                        setOpenPlatformModal(false)
                    }}>
                        <div className="platforms-holder">
                            <span onClick={() => setOpenPlatformModal(!openPlatformModal)}>
                                <img src={"/admin-icons/show_sys_logo.svg"} alt="platforms" />
                            </span>

                            <div className={`platforms-dropdown-holder ${openPlatformModal ? 'show' : ''}`}>
                                <div>
                                    <label>{t("Platforms")}</label>
                                </div>
                                <div className="scrollable-div-container custom-scrollbar-vertical">
                                    <Link to="/app/admin/goto/crm" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/mydev.svg" alt="" />
                                        <span>{t("Customer Relationship Management")}<span>
                                        </span></span>
                                    </Link>
                                    <Link to="/app/admin/goto/usender" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/sendbat.svg" alt="" />
                                        <span>{t("SMS and Email Automation")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/urless" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/urless.png" alt="" />
                                        <span>{t("Short URL & QR Code Generator")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/morsix" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/morsix.svg" alt="" />
                                        <span>{t("Inventory Management")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/claritask" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/claritask.svg" alt="" />
                                        <span>{t("Project Management")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/claritick" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/claritick.svg" alt="" />
                                        <span>{t("Ticketing System")}</span>
                                    </Link>
                                    <Link to="/app/admin/goto/convosio" className="platform-holder-icon">
                                        <img src="https://storage.googleapis.com/mydev-storage/mydev-master/applications/convosio.svg" alt="" />
                                        <span>{t("Chat System")}</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>

                    <div className="admin-notification-holder" onClick={() => setOpen(true)}>
                        {count === undefined ?
                            <>
                                <img src={"/admin-icons/new-noty-bell.svg"} alt="notification" />
                                <span className="adm-notification-number-skeleton"></span>
                            </>
                            :
                            <Badge badgeContent={count.notifications} max={999}>
                                <img src={"/admin-icons/new-noty-bell.svg"} alt="notification" />
                            </Badge>
                        }
                    </div>
                    <div className="language-wrapper">
                        <ClickAwayListener onClickAway={() => setShowLng(false)}>
                            <img src={language ? `/admin-icons/flags/${language}.svg` : `/admin-icons/flags/language-icon-black.svg`} onClick={() => setShowLng(!showLng)}/>
                        </ClickAwayListener>
                        <Collapse in={showLng}>
                            <div className="language-dropdown">
                                {languages.map(lng => {
                                    return(
                                        <div onClick={() => changeLanguage(lng.lng)} key={`language-${lng.name}`}>
                                            <img src={`/admin-icons/flags/${lng.lng}.svg`} /> {lng.name}
                                        </div>
                                    )
                                })}
                            </div>
                        </Collapse>
                    </div>
                    <UserDropdown user={user} setLoading={setLoading} />
                </div>
            </div>
        </div>
    )
}
export default Navbar