import React, { useEffect, useState } from "react"
import { Grid, Alert } from "@mui/material"
import { LoadingButton } from "@mui/lab";
import '../../../../../assets/reviews.css'
import ReviewPagePreview from "./ReviewPagePreview";
import ReviewDetails from "./ReviewDetails";
import ReviewButton from "./ReviewButton";
import ReviewCoupon from "./ReviewCoupon";
import ReviewInformation from "./ReviewInformation";
import { useSelector, useDispatch } from "react-redux";
import { showAlert } from "../../../../../redux/actions/index";
import { fetchReviewPage, fetchLocAvaliableSources, clearReviewPage, showYoutubeContent } from "../../../../../redux/actions/admin-index";
import ReviewPageSkeleton from "../../../helpers/reviewPageSkeleton";
import axios from "axios";
import { baseUrlDev } from "../../../../Helpers/baseUrl";
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser";
import AlertComponent from "../../../../Helpers/Alert";
import { useTranslation } from "react-i18next";

const resetFields = {
    heading: 'How was your experience? ',
    sub_heading: 'Your feedback is highly appreciated and responses help us to serve you better.',
    confirmation_text: 'Thank you! A representative will respond shortly.',
    irevu_button_text: 'Had a bad experience?',
    cover_image: '',
    logo: '',
    phone: '',
    email: '',
    review_send_to: '',
    button_color: '#EE2247',
    button_logo: 'unlike',
    button_text: 'Contact Us Directly',
    only_feedback: 0,
    coupon: 0,
    coupon_text: '',
    hide_contact_button: 0,
    buttons: [],
    landing_url: '',
    phrase: ""
}
const dotAfterAtRegex = /@.*\./;
const ReviewPageWrapper = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [expanded, setExpanded] = useState(['panel1', 'panel2', 'panel3', 'panel4']);
    const [errors, setErrors] = useState([])
    const [reviewPageFields, setReviewPageFields] = useState({
        heading: 'How was your experience? ',
        sub_heading: 'Your feedback is highly appreciated and responses help us to serve you better.',
        confirmation_text: 'Thank you! A representative will respond shortly.',
        irevu_button_text: '',
        cover_image: '',
        logo: '',
        phone: '',
        email: '',
        irevu_button_text: 'Had a bad experience?',
        review_send_to: '',
        button_color: '#EE2247',
        button_logo: 'unlike',
        button_text: 'Contact Us Directly',
        only_feedback: 0,
        coupon: 0,
        coupon_text: '',
        hide_contact_button: 0,
        buttons: [],
        landing_url: '',
        phrase: "",
        coupon_code: ""
    })

    const [logoPrev, setLogoPrev] = useState('')
    const location = useSelector(state => state.locationSelected.location)
    const review_page = useSelector(state => state.getReviewPage.review_page)
    const landingUrl = review_page?.data?.landing_url ?? review_page?.data?.location?.landing_url;
    const [landing, setLanding] = useState('');
    const selected = useSelector(state => state.groupLocationSelected.group_location)


    const user = useSelector(state => state.getUserInfo.user)
    let location_details = user && user.user.locations.filter(loc => loc.id == location)
    const {t} = useTranslation()
    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/2YopjkbaRV8?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        dispatch(clearReviewPage())
        if(selected && (!location || location == "all")){
            dispatch(fetchReviewPage(selected))
        }
    }, [selected, dispatch])

    useEffect(() => {
        dispatch(clearReviewPage())
        if (location && location !== "all") {
            dispatch(fetchReviewPage(location))
        }
    }, [location, dispatch])

    useEffect(() => {
        setErrors([])
    }, [reviewPageFields])

    useEffect(() => {
        dispatch(fetchLocAvaliableSources())
    }, [])

    // useEffect(() => {
    //     let result = {};
    // 	if(review_page && review_page.data){
    // 		Object.keys(reviewPageFields).map((item) => {
    //             if(item === "logo"){
    //                 result["logo"] =  review_page.data.location?.logo
    //             } else if (item === "landing_url"){
    //                 result["landing_url"] =  review_page.data.location?.landing_url.substring(18)
    //             } else {
    //                 result[item] = review_page.data[item]
    //             }
    // 		})
    // 		setReviewPageFields({...result})
    // 	} else {
    //         setReviewPageFields({...resetFields})
    //     }
    // }, [review_page])

    useEffect(() => {
        if (review_page && review_page.data && review_page.data?.location) {
            setReviewPageFields({ ...review_page.data });
            setLanding(landingUrl);
        } else {
            setReviewPageFields({ ...resetFields });
        }
    }, [review_page, landingUrl]);

    function handleChangeAccordion(panel) {
        if (expanded.includes(panel)) {
            setExpanded((prev) => prev.filter(item => item !== panel));
        } else {
            setExpanded([...expanded, panel]);
        }
    }

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }

    const submitReviewPageFunc = (id) => {
        let data = Object.assign(reviewPageFields, { location: id });
        let formData = new FormData();

        if(landing === '') {
            setErrors(errors => [...errors, 'Landing URL is required.']);
            setTimeout(() => {
                setErrors([])
            }, 5000)
            return;
        }

        if (reviewPageFields.email) {
            if ((!dotAfterAtRegex.test(reviewPageFields.email) || !reviewPageFields.email.includes("@")) || (!dotAfterAtRegex.test(reviewPageFields.review_send_to) || !reviewPageFields.review_send_to.includes("@"))) {
                setErrors(errors => [...errors, "Email Invalid"])
                return false
            }
        }

        Object.keys(data).map(key => {
            if (key === "buttons") {
                formData.append(key, JSON.stringify(reviewPageFields[key].map((el, index) => { return { ...el, id: "0" } })))
            } else if (key === "landing_url") {
                // formData.append(key, `https://irevu.com/${reviewPageFields[key]}`)
                formData.set(key, `https://irevu.com/${landing}`)
                // if(reviewPageFields[key].charAt(reviewPageFields[key].length - 1) === "/"){
                //     formData.append(key, `https://irevu.com/${reviewPageFields[key]}`)
                // } else {
                //     formData.append(key, `https://irevu.com/${reviewPageFields[key]}/`)
                // }
            } else {
                if (reviewPageFields[key] !== null) {
                    formData.append(key, reviewPageFields[key]);
                }
            }
        });
        if (isValidHttpUrl(landing)) {
            formData.set('landing_url', `${landing}`);
        } else {
            formData.set('landing_url', `https://irevu.com/${landing}`);
        }
        //formData.set('landing_url', `https://irevu.com/${landing}`);
        // let arr = reviewPageFields.buttons.map((el, index) => { return {...el , id: "0", index: index}})
        setLoading(true)
        axios.post(`${baseUrlDev}locations/reviewpage`, formData, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then(res => {
                setErrors([])
                setLoading(false)
                dispatch(showAlert(true, "Review Page updated successfully"))
                setTimeout(() => {
                    dispatch(showAlert(false, ""))
                }, 5000)
            }).catch((err) => {
                setLoading(false)
                Object.keys(err.response.data.errors).map(key => {
                    console.log(err);
                    setErrors(errors => [...errors, err.response.data.errors[key][0]])
                })
                setTimeout(() => {
                    setErrors([])
                }, 5000)
            })
    }

    const submitReviewPage = () => {
        if(selected && (!location || location == "all")){
            submitReviewPageFunc(selected)
        }
        if (location && location !== "all") {
            submitReviewPageFunc(location)
        }
    }

    if (review_page === undefined) {
        return (
            <ReviewPageSkeleton />
        )
    }

    return (
        <div className="review-page-wrapper">
            <AlertComponent />
            <div className="alert-inside-page">
                {errors && errors.map(err => {
                    return (
                        <Alert key={err} severity="error" className="custom-error-alert">{err}</Alert>
                    )
                })}
            </div>
            <div className="review-page_header">
                <Grid container spacing={3}>
                    <Grid item xl={3} sm={6} xs={12}>
                        <div className="card accordion blue" onClick={() => handleChangeAccordion('panel1')}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <img src={"/admin-images/tools/review-details-logo.svg"} alt="" />
                                    <h3 className="title">{t("Review Details")}</h3>
                                </div>
                                <div className="arrow-wrap">
                                    <img src={"/admin-images/tools/down-arrow.svg"} height="11" className={`${expanded?.find(e => e === 'panel1') ? 'rotate' : ''}`} alt="" />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={12}>
                        <div className="card accordion light-blue" onClick={() => handleChangeAccordion('panel2')}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <img src={"/admin-images/tools/review-button-logo.svg"} alt="" />
                                    <h3 className="title review_button">{t("Review Button")}</h3>
                                </div>
                                <div className="arrow-wrap">
                                    <img src={"/admin-images/tools/down-arrow.svg"} height="11" className={`${expanded?.find(e => e === 'panel2') ? 'rotate' : ''}`} alt="" />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={12}>
                        <div className="card accordion light-green" onClick={() => handleChangeAccordion('panel3')}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <img src={"/admin-images/tools/coupon-logo.svg"} alt="" />
                                    <h3 className="title coupon">{t("Coupon")}</h3>
                                </div>
                                <div className="arrow-wrap">
                                    <img src={"/admin-images/tools/down-arrow.svg"} height="11" className={`${expanded?.find(e => e === 'panel3') ? 'rotate' : ''}`} alt="" />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl={3} sm={6} xs={12}>
                        <div className="card accordion light-yellow" onClick={() => handleChangeAccordion('panel4')} >
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className="d-flex align-items-center">
                                    <img src={"/admin-images/tools/information-logo.svg"} alt="" />
                                    <h3 className="title information">{t("Information")}</h3>
                                </div>
                                <div className="arrow-wrap">
                                    <img src={"/admin-images/tools/down-arrow.svg"} height="11" className={`${expanded?.find(e => e === 'panel4') ? 'rotate' : ''}`} alt="" />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="review-page-content">
                <Grid container spacing={3}>
                    <Grid item xl={8} md={12}>
                        <div>
                            <div className={`card accordion review-details-card`}>
                                <ReviewDetails
                                    expanded={expanded}
                                    handleChangeAccordion={handleChangeAccordion}
                                    reviewPageFields={reviewPageFields}
                                    setReviewPageFields={setReviewPageFields}
                                    landing={landing}
                                    // landing={review_page.data.location?.landing_url}
                                    reviewData={review_page?.data}
                                    setLanding={setLanding}
                                />
                            </div>

                            <div className={`card accordion review-button-card ${expanded?.find(e => e === 'panel2') ? 'close' : ''}`}>
                                <ReviewButton
                                    location={location}
                                    expanded={expanded}
                                    handleChangeAccordion={handleChangeAccordion}
                                    buttons={reviewPageFields.buttons}
                                    reviewPageFields={reviewPageFields}
                                    setReviewPageFields={setReviewPageFields}
                                    review_page={review_page}
                                />
                            </div>

                            <div className={`card accordion coupon-card ${expanded?.find(e => e === 'panel3') ? 'close' : ''}`}>
                                <ReviewCoupon
                                    expanded={expanded}
                                    handleChangeAccordion={handleChangeAccordion}
                                    reviewPageFields={reviewPageFields}
                                    setReviewPageFields={setReviewPageFields}
                                    review_page={review_page?.data}
                                // review_page={review_page}
                                />
                            </div>

                            <div className={`card accordion information-card mb-0 ${expanded?.find(e => e === 'panel4') ? 'close' : ''}`}>
                                <ReviewInformation
                                    expanded={expanded}
                                    handleChangeAccordion={handleChangeAccordion}
                                    reviewPageFields={{ ...reviewPageFields }}
                                    setReviewPageFields={setReviewPageFields}
                                    setLogoPrev={setLogoPrev}
                                    reviewData={review_page?.data}
                                />
                            </div>
                        </div>
                        <div className="review-page-foot">
                            <LoadingButton
                                style={{ paddingLeft: loading && "30px" }}
                                className="btn btn-review-page"
                                onClick={() => submitReviewPage()}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                        </div>
                    </Grid>

                    <Grid item xl={4} md={12} className='w-100'>
                        <ReviewPagePreview logoPrev={logoPrev} reviewPageFields={reviewPageFields} review_page={review_page} location_details={location_details} />
                    </Grid>
                </Grid>

            </div>
        </div>
    )
}
export default ReviewPageWrapper