import React, {useState, useEffect} from "react"
import { Container } from "@mui/system"
import { Grid } from "@mui/material"

//Our imports
import FormList from "./form-list"
import AddForm from "./modal/add-form-modal"
import AlertComponent from "../Helpers/Alert"
import { showYoutubeContent } from "../../redux/actions/admin-index"
import {useDispatch} from "react-redux"
import useHandleDisable from "../Admin/helpers/disableSelect"
import { useTranslation } from "react-i18next"

const Forms = () => {
    const handleDisable = useHandleDisable()
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const {t} = useTranslation()
    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/dL6Rx1nr3MI?autoplay=1&mute=0&rel=0'))
        handleDisable()
    }, [])
    
    return(
        <Container maxWidth="lg" className="mt-100">
            <AddForm show={show} setShow={setShow}/>
            <Grid container spacing={2}  alignItems="center">
                <Grid item xs={12} sm={6}>
                    <div className="forms-header">
                        <h1>{t("Forms")}</h1>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div className="forms-header">
                        <button onClick={() => setShow(true)}><img src="/admin-icons/plus.svg" alt="add" />{t("Create New Form")}</button>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <FormList/>
                </Grid>
            </Grid>
        </Container>
    )
}
export default Forms