import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import NotyItem from "./NotyItem";
import { useSelector } from "react-redux";

const NotificationBody = ({ notifications, handleLoadMore }) => {
    const loading = useSelector((state) => state.getNotifications.loading);
    const load_more = useSelector(state => state.getNotifications?.load_more) //if this is false we should remove load more button

    const modalRef = React.useRef(null);

    const scrollListNotifications = (e) => {
        //e.target.scrollHeight - height i scrollbar(custom-scrollbar-vertical)
        if (modalRef.current.scrollTop + modalRef.current.clientHeight === modalRef.current.scrollHeight && !load_more) {
            handleLoadMore();
        }
    }

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.addEventListener('scroll', scrollListNotifications);
        }

        return () => {
            if (modalRef.current) {
                modalRef.current.removeEventListener('scroll', scrollListNotifications);
            }
        }

    }, [notifications])

    if (notifications === undefined) {
        return (
            <div className="max-height-noty custom-scrollbar-vertical">
                {
                    [...Array(5)].map((e, i) => {
                        return (
                            <div style={{ marginBottom: "10px" }} key={`noty-skelet${i}`}>
                                <div className="noty-item">
                                    <a>
                                        <div>
                                            <h4><Skeleton style={{ transform: 'unset', width: "90%", height: "10px" }} /></h4>
                                            <p><Skeleton style={{ transform: 'unset', width: "80%", height: "10px" }} /></p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    if (notifications.length === 0) {
        return (
            <div className="max-height-noty custom-scrollbar-vertical">
                <div className="noty-item" style={{ textAlign: "center" }}>
                    <h4>Nothing to Show</h4>
                </div>
            </div>
        )
    }

    return (
        <div className="max-height-noty custom-scrollbar-vertical" ref={modalRef}>
            {notifications.map((notification, index) => {
                return (
                    <NotyItem notification={notification} key={index} />
                )
            })}
            {
                loading && <div style={{ marginBottom: "10px" }}>
                    <div className="noty-item">
                        <a>
                            <div>
                                <h4><Skeleton style={{ transform: 'unset', width: "90%", height: "10px" }} /></h4>
                                <p><Skeleton style={{ transform: 'unset', width: "80%", height: "10px" }} /></p>
                            </div>
                        </a>
                    </div>
                </div>
            }
        </div>
    )
}
export default NotificationBody