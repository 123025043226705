import React, {useState, useEffect} from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchCompetitors, clearCompetitors, showYoutubeContent, openExportedAlert } from "../../../../redux/actions/admin-index"
import CompetitorBox from "./CompetitorBox"
import { Skeleton, Grid, Alert, Collapse, CircularProgress } from "@mui/material"
import CompetitorSkeleton from "../../helpers/competitorSkeleton"
import KeywordStatsModal from "../../modals/analysis/KeywordsModal"
import AddCompetitorBox from "./addCompetitorBox"
import AddLocation from "../../modals/locations/add_locations"
import Unlock from "../../Unlock"
import {BsFileLockFill} from "react-icons/bs"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import html2canvas from "html2canvas"
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { AiOutlineFilePdf } from "react-icons/ai"
import ExportedAlert from "../../modals/exportedAlert"
import { exportTable } from "../../helpers/export"

const CompetitorWrapper = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [alert, setAlert] = useState(false)
    const [open, setOpen] = useState(false)
    const [openCompetitor, setOpenCompetitor] = useState(false)
    const [id, setId] = useState(false)
    const [loc, setLoc] = useState()
    const [locked, setLocked] = useState(false)
    const [loading, setloading] = useState(false)
    const location = useSelector(state => state.locationSelected.location)
    const competitors = useSelector(state => state.competitorsReducer.competitors)
    const user = useSelector(state => state.getUserInfo.user)
    const selected = useSelector(state => state.groupLocationSelected.group_location)
    const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)


    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/pULjXCc_UW8?autoplay=1&mute=0&rel=0'))
    }, [])

    useEffect(() => {
        if(selected && (!location || location == "all")){
            dispatch(clearCompetitors())
            dispatch(fetchCompetitors(selected))
        }
    }, [selected])

    useEffect(() => {
        if (location && location !== "all") {
            dispatch(clearCompetitors())
            dispatch(fetchCompetitors(location))
        }
    }, [location])


    useEffect(() => {
        if(location){
            let location_details = user && user.user.locations.filter(loc => loc.id == location)
            if(location_details && location_details[0] && location_details[0].is_demo){
                setLocked(true)
            } else {
                setLocked(false)
            }
        }
    }, [location, user])

    if(locked){
        return <Unlock/>
    }


    if(competitors === undefined){
        return(
            <CompetitorSkeleton/>
        )
    }
    
    const exportFunc = (id) => {
        setloading(true)
        exportTable(impersonateReducer,`${baseUrlDev}locations/generate-competitor-pdf`, {location: id}, 
        (resData) => {
            dispatch(openExportedAlert(true, resData.message));
            setloading(false)
        }, 
        (error) => {
            setloading(false)
        });
    }

    const exportPdf = (id) => {
        if(selected && (!location || location == "all")){
            exportFunc(selected)
        }
        if (location && location !== "all") {
            exportFunc(location)
        }
    }

    return(
        <div className="competitors-boxes">
            <ExportedAlert/>
            {/* <div className="blured">
                <BsFileLockFill/>
                <h5>This feature is only available to our paid members</h5>
                <h5><Link to="/subscription">Upgrade</Link> your account to enjoy this and many more exclusive features</h5>
            </div> */}
            <Collapse in={alert}>
                <div className="alert-inside-page" style={{marginBottom: "15px"}}>
                    <Alert severity="warning">Data Refresh in Progress! The data is being updated. This process may take several hours. Please check back later.</Alert>
                </div>
            </Collapse>       
            <AddLocation open={openCompetitor} setOpen={setOpenCompetitor} title="Competitor"/>
            <KeywordStatsModal open={open} id={id} setOpen={setOpen} loc={loc}/>
            <div style={{marginBottom: "20px"}}>
                <button 
                    style={{marginLeft: "auto"}} className="competitor-export" 
                    onClick={() => exportPdf()}
                    disabled={loading}
                >
                    {loading ?
                     <>    
                        <CircularProgress 
                            size={15}
                            style={{ marginRight: "5px"}}
                            thickness={4}
                        />
                        Export to PDF</> 
                    : <> <AiOutlineFilePdf  style={{ marginRight: "5px"}}/> {t("Export to PDF")}</>}
                </button>
            </div>
            <Grid container spacing={3} id="competitor_id">
                <Grid item xs={12} md={6} lg={4} xl={3} className="my-loc">
                    <CompetitorBox item={competitors.data} setOpen={setOpen} setId={setId} setLoc={setLoc}/>
                </Grid>
                {competitors.data.competitors.length > 0 && competitors.data.competitors.map(competitor => {
                    return(
                        <Grid item xs={12} md={6} lg={4} xl={3} key={competitor.location.id}>
                            <CompetitorBox item={competitor} setOpen={setOpen} setId={setId} setLoc={setLoc} competitor={true} setAlert={setAlert}/>
                        </Grid>
                    )
                })}
                {
                    competitors.data.limits.left > 0 &&  [...Array(competitors.data.limits.left)].map((item, i) =>{
                        return(
                            <Grid item xs={12} md={6} lg={4} xl={3} key={`add-comp${i}`} className="remove-div-pdf">
                                <AddCompetitorBox setOpenCompetitor={setOpenCompetitor}/>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    )
}
export default CompetitorWrapper