import React, {useEffect} from "react"
import LightTooltip from "../../helpers/lightTooltip"
import { IconButton, Grid, Skeleton } from "@mui/material"
import {IoMdInformationCircleOutline} from "react-icons/io"
import { fetchReviewsTopBoxes, clearTopBoxes } from "../../../../redux/actions/admin-index"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const ReviewBoxes = () => {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const keyword = useSelector(state => state.reviewKeyword.keywords)
    const sources = useSelector(state => state.reviewSourceFilter.source)
    const status = useSelector(state => state.reviewStatusFilter.status)
    const rating = useSelector(state => state.reviewRatingFilter.rating)
    const from = useSelector(state => state.reviewRangeFilter.dateFrom)
    const to = useSelector(state => state.reviewRangeFilter.dateTo)
    const page = useSelector(state => state.paginationReviews.pagination)
    const orderState = useSelector(state => state.reviewSortOrder.order)
    const tagsFilter = useSelector(state => state.reviewTagsFilter.tags)

    useEffect(() => {
        dispatch(clearTopBoxes())
        dispatch(fetchReviewsTopBoxes(location, keyword, sources, status, rating, from, to, tagsFilter, orderState, groupSelected))
    }, [location, keyword, sources, status, rating, to, tagsFilter, orderState, groupSelected])

    const boxes = useSelector(state => state.getReviewsBoxes.reviews_boxes)
    return(
        <Grid container spacing={2} className="box-containers">
            <Grid item xs={12} md={6} lg>
                <div>
                    <LightTooltip title={t("The total amount of reviews for your business")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span>{boxes ? boxes.reviews : <Skeleton style={{width: "50px", height: "30px", transform: 'unset'}}/>}</span>
                    <h6>{t("Reviews")}</h6>
                </div>
            </Grid>
            <Grid item xs={12} md={6} lg>
                <div>
                    <LightTooltip title={t("The total amount of replied reviews")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span>{boxes ? boxes.responded : <Skeleton style={{width: "50px", height: "30px", transform: 'unset'}}/>}</span>
                    <h6>{t("Responded")}</h6>
                </div>
            </Grid>
            <Grid item xs={12} md={6} lg>
                <div>
                    <LightTooltip title={t("The total amount of not replied reviews")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span>{boxes ? boxes.not_responded : <Skeleton style={{width: "50px", height: "30px", transform: 'unset'}}/>}</span>
                    <h6>{t("Not")} {t("Responded")}</h6>
                </div>
            </Grid>
            <Grid item xs={12} md={6} lg>
                <div>
                    <LightTooltip title={t("The total amount of pinned reviews")}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                        </IconButton>
                    </LightTooltip>
                    <span>{boxes ? boxes.pinned : <Skeleton style={{width: "50px", height: "30px", transform: 'unset'}}/>}</span>
                    <h6>{t("Pinned")}</h6>
                </div>
            </Grid>   
        </Grid>

    )
}
export default ReviewBoxes