import React, {useState, useMemo, useEffect} from "react"
import {Modal, Slide, Skeleton, Select, FormControl, TextField, MenuItem, ListSubheader, Alert} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useSelector, useDispatch } from "react-redux"
import { fetchQuickReplyDetails, clearQuickReplyDetails } from "../../../../../redux/actions/admin-index"
import MySelect from "../../../../Helpers/MySelect"
import axios from "axios"
import { getLocalUser } from "../../../../TokenControl/parts/useLocalUser"
import { baseUrlDev } from "../../../../Helpers/baseUrl"
import { showAlert } from "../../../../../redux/actions"
import { useTranslation } from "react-i18next"

const containsText = (text, search) => text.toLowerCase().indexOf(search.toLowerCase()) > -1;

const EditQuickReply = ({open, setOpen, setList, list, id}) => {
    const dispatch = useDispatch()
    const [errors, setErrors] = useState([])
    const [search, setSearch] = useState("")
    const [locationsReplies, setLocationsReplies] = useState([])
    const [reply, setReply] = useState("")
    const [type, setType] = useState("positive")
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const user = useSelector(state => state.getUserInfo.user)
    const replyRedux = useSelector(state => state.getQuickReply.reply)
    let locations = user && user.user.locations

    const displayedLocation = useMemo(() => locations  && locations.filter(loc => containsText(loc.full_name, search)), [search, user]);


    useEffect(() => {
       dispatch(clearQuickReplyDetails())
       if(open && id){
        dispatch(fetchQuickReplyDetails(id))
       }
    }, [id, open])

    useEffect(() => {
       if(replyRedux){
        setReply(replyRedux.data?.quick_reply[0]?.content)
        setType(replyRedux.data?.quick_reply[0]?.type)
        setLocationsReplies(replyRedux.data?.quick_reply[0]?.accounts.map(ac => ac.account_id))
       }
    }, [replyRedux])
    
    const handleChange = (e) => {
        let value = e.target.value
        if(value.includes("all")){
            if(locationsReplies.length === (locations && locations.length)){
                setLocationsReplies([])
            } else {
                setLocationsReplies(locations && locations.map(el => el.id))
            }
        } else {
            setLocationsReplies(value.filter(el => el !== "all"))
        } 
    }

    const handleClose = () => {
        setOpen(false)
    }

    const submitQuickReplies = () => {
        if(locations.length === 0){
            setErrors([...[], "Please select at least one location to create a quick reply. If you want to select all locations, please choose 'All Locations'."])
            return
        } else if (reply.length == 0){
            setErrors([...[], "Please add quick reply'."])
        }
        setLoading(true)
        let data = {
            location_ids: locationsReplies,
            title: "",
            content: reply,
            type: type
        }
        axios.post(`${baseUrlDev}reviews/quick-replies/${id}/update`, data, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(res => {
            setLoading(false)
            setOpen(false)
            const updatedList = list.map(item => {
                if(item.quick_reply_id == id){
                    return{
                        ...item,
                        replies: {
                            ...item.replies,
                            content: data.content,
                            type: data.type
                        }
                    }
                }
                else {
                    return item;
                }
            })
            setList(updatedList);
            dispatch(showAlert(true, "Reply Created Succesfully!"))
            setTimeout(() => {
                dispatch(showAlert(false, ""))
            }, 5000)
        }).catch(err => {
            setLoading(false)
            Object.keys(err.response.data.errors).map(key => {
                setErrors([...errors, err.response.data.errors[key][0]])
            })
        })
    }

    return(      
        <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-quickReply-title"
            aria-describedby="modal-quickReply-description"
        >
            <Slide direction="left" in={open} {...({ timeout: 500 })}>
                <div className={`custom-modal-dialog`}>
                    <div className="notification-modal-body">
                        <div className="custom-modal-header">
                            <h5>{t("Edit Quick Reply")}</h5>
                        </div>
                        <div className="custom-modal-content">
                            <div className="scrollable-div-container custom-scrollbar-vertical">
                                <div className="field-container">
                                    <label>{t("Choose Type")}</label>
                                    <div className="quick-reply-types">
                                        {replyRedux === undefined ? 
                                            <div>
                                                <Skeleton style={{width: "96px", height: "31px", borderRadius: "20px", marginRight: "5px", transform: "unset", display: "inline-block"}} />
                                                <Skeleton style={{width: "96px", height: "31px", borderRadius: "20px", transform: "unset", display: "inline-block"}} />
                                            </div>    
                                        :
                                            <>
                                                <div>
                                                    <input type="radio" name="quick-reply-type" id="positive-quick-reply" value="positive" checked={type === "positive"} onChange={() => setType("positive")}/>
                                                    <label htmlFor="positive-quick-reply" className="positive">{t("positive")}</label>
                                                </div>
                                                <div>
                                                <input type="radio" name="quick-reply-type" id="negative-quick-reply" value="negative" checked={type === "negative"} onChange={() => setType("negative")}/>
                                                <label htmlFor="negative-quick-reply" className="negative">{t("negative")}</label>
                                            </div>
                                           </>
                                        }
                                    </div>
                                </div>
                                <div className="field-container">
                                    <label>{t("Choose Locations")}</label>
                                    {(locations === undefined || replyRedux === undefined) ? <Skeleton style={{height: '41px', transform: 'unset'}}/> :
                                        <FormControl className="" fullWidth>
                                            <Select
                                                displayEmpty
                                                value={locationsReplies}
                                                multiple
                                                onChange={handleChange}
                                                onClose={() => setSearch("")}
                                                input={<MySelect/>}
                                                MenuProps={{
                                                    classes: {
                                                        paper: "breake-option-words custom-selected",
                                                    },
                                                    autoFocus: false, PaperProps: { sx: { maxHeight: 300} , 
                                                    style: {
                                                        maxWidth: 300,
                                                    }, 
                                                }}}
                                                renderValue={value => {
                                                    if(value.length === 0){
                                                        return t("Select Location");
                                                    } else if (value.length === (locations && locations.length)){
                                                        return t("All Locations")
                                                    } else {
                                                        let arr = value.map(element => {
                                                            return locations && locations.find(el => el.id == element)
                                                        })
                                                        return arr.map(el => el && el.full_name).join(', ')
                                                    }
                                                }}
                                            >
                                                <ListSubheader>
                                                    <TextField
                                                        size="small"
                                                        autoFocus
                                                        placeholder={t("Search Location")}
                                                        fullWidth
                                                        onChange={(e) => setSearch(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if(e.key !== 'Escape'){
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </ListSubheader>
                                                <MenuItem value={"all"}>{t("All Locations")}</MenuItem>
                                                {displayedLocation && displayedLocation.map(loc => {
                                                    return(
                                                        <MenuItem key={loc.id} value={loc.id}>{loc.full_name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <div className="field-container">
                                    <label>{t("Quick Reply")}</label>
                                    {replyRedux === undefined ?
                                    <Skeleton style={{width: "100%", height: "100px", borderRadius: "5px", marginRight: "5px", transform: "unset"}} />
                                    :<textarea className="custom-scrollbar-vertical" onChange={(e) => setReply(e.target.value)} placeholder={t("Quick Reply")} value={reply}></textarea>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="custom-modal-footer">
                           <LoadingButton
                                style={{paddingLeft: loading && "30px"}}
                                className="save-btn"
                                onClick={() => submitQuickReplies()}
                                loading={loading}
                            >
                                {t("Save")}
                            </LoadingButton>
                            <button className="close-btn" onClick={() => setOpen(false)}>{t("Close")}</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
    )
}
export default EditQuickReply