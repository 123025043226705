import axios from 'axios';
import { getLocalUser } from '../../TokenControl/parts/useLocalUser';

export const exportTable = (impersonate, url, payload, onSuccess, onError) => {
    const headers = {
        'Authorization': `Bearer ${getLocalUser().token}`,
        'APP-VERSION': 'react'
    }
    if (JSON.parse(impersonate)) {
        headers['impersonate'] = impersonate;
    }
    axios.post(url, payload, {
        headers: headers
    }).then(res => {
        onSuccess(res.data);
    }).catch(error => {
        onError(error);
    });
};