import React from 'react';
import ConnectionsTable from './ConnectionsTable';
import Header from '../../../Helpers/header';
import { useTranslation } from 'react-i18next';
import OnlyLocationWrapper from '../../parts/OnlyLocationWrapper';
import InnerDropdownContainer from '../../parts/InnerDropdownContainer';

const Connections = () => {
    const [t] = useTranslation()

    return (
        <>
            <div className='table-header-container'>
                <Header title="Review Connections" subtitle="Manage Review Connections"/>
            </div>
            <InnerDropdownContainer/>
            <OnlyLocationWrapper>
                <div className='connections_page'>
                    <div>
                        <ConnectionsTable/>
                    </div>
                </div>
            </OnlyLocationWrapper>
        </>
    )
}

export default Connections;