import React from "react"
import LightTooltip from "../../helpers/lightTooltip"
import { IconButton } from "@mui/material"
import { IoMdInformationCircleOutline } from "react-icons/io"
import { AiFillStar } from "react-icons/ai"
import { useTranslation } from "react-i18next"

const Phrases = ({phrases, analysis}) => {
    let positivePhrases = phrases ? phrases.positive : []
    let negativePhrases = phrases ? phrases.negative : []
    const [t] = useTranslation()
    return(
        <div className="dashboard-box-containers">
            <h2>{analysis ? "Trending Phrases" : t("Top positive & negative phrases")}</h2>
            <LightTooltip title={t("Top positive & negative phrases")}>
                <IconButton className="tooltip-btn-holder">
                    <IoMdInformationCircleOutline style={{opacity: "0.4"}}/>
                </IconButton>
            </LightTooltip>
            {positivePhrases.length == 0 && negativePhrases.length == 0 ? <div className="nothing-to-show padding-20">{t("Nothing to Show")}</div> :
                <div className="scrollable-box-sm-table custom-scrollbar-vertical tables-main-container">
                    {
                    (positivePhrases.length == 0 && negativePhrases.length == 0) &&
                     <div className="nothing-to-show padding-20">{t("Nothing to Show")}</div>
                    }
                    {positivePhrases.length > 0 &&
                        <div>
                            <table className="phrases-table phrases-table-positive">
                                <thead>
                                    <tr>
                                        <th>{t("Positive Phrases")} <span></span></th>
                                        <th>{t("Rating")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {positivePhrases && positivePhrases.length > 0 && 
                                        positivePhrases.map(phrase => {
                                            return(
                                                <tr>
                                                    <td>{phrase.text}</td>
                                                    <td><div>{phrase.rating} <AiFillStar/></div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                    {negativePhrases.length > 0 &&
                        <div>
                            <table className="phrases-table phrases-table-negative">
                                <thead>
                                    <tr>
                                        <th>{t("Negative Phrases")} <span></span></th>
                                        <th>{t("Rating")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {negativePhrases && negativePhrases.length > 0 && 
                                        negativePhrases.map(phrase => {
                                            return(
                                                <tr>
                                                    <td>{phrase.text}</td>
                                                    <td><div>{phrase.rating} <AiFillStar/></div></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
        </div>
    )
}
export default Phrases