import React, {useState} from "react"
import { Modal } from "@mui/material"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import axios from "axios"
import { baseUrlDev } from "../../../Helpers/baseUrl"
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser"
import { fetchDashboardAutopilot, clearDashboardAutopilot } from "../../../../redux/actions/admin-index"
import { useDispatch } from "react-redux"
import {CustomLoadingButtonBlue} from "../../helpers/LoadingButtonCustom"

const AutoPilotModal = ({show, setShow}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const handleClose = () => {
        setShow(false)
    }
    const location = useSelector(state => state.locationSelected.location)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const user = useSelector(state => state.getUserInfo.user)
    let location_details = user && user.user.locations.filter(loc => loc.id == location)

    const upgradePlan = () => {
        let locations = [location]
        setLoading(true)
        axios.post(`${baseUrlDev}locations/upgrade-subscription`, locations, {headers:{'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' }})
        .then(() => {
            setLoading(false)
            dispatch(clearDashboardAutopilot())
            dispatch(fetchDashboardAutopilot(location));
            setShow(false)
        })
        .catch(err => {
            setLoading(false)
            setShow(false)
        })
    }

    return(
        <Modal 
            open={show}
            onClose={handleClose}
            aria-labelledby="autopilot-modal-title"
            aria-describedby="autopilot-modal-description"
        >
            {(location_details && location_details[0]?.plan == 1) ? 
            <div className="wrapper-setup-ai">
               <div className="wrapper-setup-header" style={{flexDirection: "column", marginLeft: "auto"}}>
                    <img src="/admin-icons/blue-robot.svg"  alt="robot"/>
                </div>
                <div className="wrapper-setup-body wrapper-setup-body-ai ">
                    <div>
                        <h4>Add <span>$20</span></h4>
                        <h4>to your package</h4>
                    </div>
                </div>
                {/* <div className="wrapper-setup-footer">
                    <button className="w-100" onClick={() => upgradePlan()}><img src="/admin-icons/stars.svg" alt="stars"/>Upgrade</button>
                </div> */}
                <div className="wrapper-setup-footer">
                    <CustomLoadingButtonBlue
                            style={{paddingLeft: loading && "30px"}}
                            loading={loading}
                            onClick={() => upgradePlan()}
                            disabled={loading}
                        >
                            {!loading && <img src="/admin-icons/stars.svg" alt="stars"/>}Upgrade
                    </CustomLoadingButtonBlue>
                </div>
            </div>
            : 
            <div className="wrapper-setup">
                <div className="wrapper-setup-header" style={{flexDirection: "column"}}>
                    <img src="/admin-icons/robot-line-icon.svg"  alt="robot"/>
                    <h3>Autopilot AI</h3>
                </div>
                <div className="wrapper-setup-body text-center">
                    <h3>
                        To perform the desired action, you need to upgrade to <span>AI Service</span> plan. 
                    </h3>
                    <p>
                        This upgrade will revolutionize how you manage and engage with customer feedback, saving you time and ensuring prompt responses to reviews.
                    </p>
                    <p>
                        As you consider upgrading, please keep in mind that with this feature, the responsibility of generating and sending review responses will be handled by AI system.
                    </p>
                </div>
                <div className="wrapper-setup-footer">
                    <Link to="/app/admin/subscription?ai=true"><img src="/admin-icons/stars.svg" alt="stars"/>Upgrade</Link>
                </div>
            </div>
            }
        </Modal>
    )
}
export default AutoPilotModal