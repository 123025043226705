import React, {useState, useEffect,  Suspense} from "react";
import { useSelector } from "react-redux";
import GroupLocationWrapper from "./groupLocationsWrapper";
import { Skeleton } from "@mui/material";

const GroupLocations = ({columns, setColumns, edit}) => {
    const user = useSelector(state => state.getUserInfo.user);
    const groups = user && user.user.groups;
    const groupPage = useSelector(state => state.getGroupPage.groupPage);
    const [locations, setLocations] = useState([])

    useEffect(() => {
        const allLocations = groups?.reduce((accumulator, group) => {
            return accumulator.concat(group.locations.map(location => ({
              ...location,
              group_name: group.name
            })));
        }, []);
        setLocations(allLocations)
                
    }, [groups])

    useEffect(() => {
        console.log(locations)
        if(locations){
            const newColumns = {
                ...columns,
                notAllowed: {
                    items: locations.map(loc => {
                        return{
                            id: loc.id,
                            full_name: loc.full_name,
                            group_name: loc.group_name
                        }
                    })
                },
                allowed: {
                    items: []
                },
            }
            setColumns(newColumns)
        }
    },[locations])

        
    useEffect(() => {
        if(groupPage && locations && edit){
            let includedLocations = groupPage?.data?.locations.map(item => item.id)

            let notIncluded = locations?.filter(loc => !includedLocations.includes(loc.id))
            let included = locations?.filter(loc => includedLocations.includes(loc.id))
            setColumns({ ...columns, notAllowed: { ...columns.notAllowed, items: notIncluded }, allowed: { ...columns.allowed, items: included } })

        }
  
        
    }, [groupPage, locations])

    return(
        <div
            style={{ display: "flex", margin: "24px 0px",  width: "100%", justifyContent: "space-between", columnGap: "30px", height: "inherit" }}>
                <Suspense fallback={<Skeleton style={{ height: "400px", width: "100%", transform: "unset", display: "block" }} />}>
                    <GroupLocationWrapper columns={columns} setColumns={setColumns}/>
                </Suspense>
        </div>
    )


}
export default GroupLocations