import React, { useState } from "react"
import { Grid } from "@mui/material"
import { Collapse } from "@mui/material"
import { useTranslation } from "react-i18next"

const ReplyTime = ({ data }) => {
    const [active, setActive] = useState(1)
    const {t} = useTranslation()

    const convertSec = (number) => {
        if (number) {
            if (number >= 60 && number < 3600) {
                return `${Math.floor(number / 60)} min`
            } else if (number < 60) {
                return `${number} sec`
            } else if (number >= 3600) {
                return `${Math.floor(number / 3600)}h`
            }
        } else {
            return '0 sec'
        }
    }

    return (
        <div className="reply-time-ai-container">
            <div className="reply-time-ai-header">
                <h6 className={active == 1 ? "active" : ""} onClick={() => setActive(1)}>
                    {t("AI VS Self Replies")}
                </h6>
                <h6 className={active == 2 ? "active" : ""} onClick={() => setActive(2)}>
                    {t("Average Reply Time")}
                </h6>
            </div>
            <Collapse in={active == 1}>
                <div>
                    <div className="replies-vs-ai">
                        <Grid container style={{ alignItems: "center" }}>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className="replies-vs-ai-first">
                                    <img src={"/admin-icons/robot-ai.svg"} alt={"robot"} />
                                    <h5>{t("AI Replies")}</h5>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className="replies-vs-ai-second">
                                    <div>
                                        <div>
                                            <img src={"/admin-icons/blue-star.svg"} alt={"star"} />
                                            {t("All Replies")}
                                        </div>
                                        <span>{data.aiResponsesCount}</span>
                                    </div>

                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className="replies-vs-ai-third">
                                    <div>
                                        <img src={"/admin-icons/clock-ai.svg"} alt={"star"} />
                                        {t("Average Time")}
                                    </div>
                                    {data.totalAverageAiReplyTime ?
                                        <span>{data.totalAverageAiReplyTime} {t("sec")}</span>
                                        : <span style={{fontSize: "14px", opacity: "0.6", marginLeft: "10px"}}> Not Avaliable</span>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className="replies-vs-ai-fourth">
                                    <img src={"/admin-icons/blue-cup.svg"} alt={"star"} />
                                    <div>
                                        <div><h6>{data.aiRating}</h6><span>{t("Avg Rate")}</span></div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="replies-vs-ai">
                        <Grid container style={{ alignItems: "center" }}>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className="replies-vs-ai-first">
                                    <img src={"/admin-icons/yellow-user.svg"} alt={"robot"} />
                                    <h5>{t("Self Replies")}</h5>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className="replies-vs-ai-second">
                                    <div>
                                        <div>
                                            <img src={"/admin-icons/yellow-star.svg"} alt={"star"} />
                                            {t("All Replies")}
                                        </div>
                                        <span>{data.countSelfReplies}</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className="replies-vs-ai-third">
                                    <div>
                                        <img src={"/admin-icons/clock-ai.svg"} alt={"star"} />
                                        {t("Average Time")}
                                    </div>
                                    {data.totalAverageSelfReplyTimeSeconds ?
                                        <span>{data.totalAverageSelfReplyTimeSeconds} {t("sec")}</span>
                                        : <span style={{fontSize: "14px", opacity: "0.6", marginLeft: "10px"}}> Not Avaliable</span>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className="replies-vs-ai-fourth">
                                    <img src={"/admin-icons/yellow-cup.svg"} alt={"star"} />
                                    <div>
                                        <div><h6>{data.selfReplyRating}</h6><span>{t("Avg Rate")}</span></div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Collapse>
            <Collapse in={active == 2}>
                <div>
                    <div className="replies-vs-ai">
                        <Grid container style={{ alignItems: "center" }}>
                            <Grid item xs={12} md={6} lg={4}>
                                <div className="replies-vs-ai-first">
                                    <img src={"/admin-icons/robot-ai.svg"} alt={"robot"} />
                                    <h5>{t("AI Replies")}</h5>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <div className="avg-reply-time">
                                    <div>
                                        <div>
                                            1 <img src={"/admin-icons/blue-star.svg"} alt={"star"} />
                                        </div>
                                        {/* <span>{convertSec(data.aiReplyTimesInSeconds["1"])}</span> */}
                                        <span>{data.aiReplyTimesInSeconds["1"] ? data.aiReplyTimesInSeconds["1"] : '00:00:00'}</span>
                                    </div>
                                    <div>
                                        <div>
                                            2 <img src={"/admin-icons/blue-star.svg"} alt={"star"} />
                                        </div>
                                        <span>{data.aiReplyTimesInSeconds["2"] ? data.aiReplyTimesInSeconds["2"] : '00:00:00'}</span>
                                    </div>
                                    <div>
                                        <div>
                                            3 <img src={"/admin-icons/blue-star.svg"} alt={"star"} />
                                        </div>
                                        <span>{data.aiReplyTimesInSeconds["3"] ? data.aiReplyTimesInSeconds["3"] : '00:00:00'}</span>
                                    </div>
                                    <div>
                                        <div>
                                            4 <img src={"/admin-icons/blue-star.svg"} alt={"star"} />
                                        </div>
                                        <span>{data.aiReplyTimesInSeconds["4"] ? data.aiReplyTimesInSeconds["4"] : '00:00:00'}</span>
                                    </div>
                                    <div>
                                        <div>
                                            5 <img src={"/admin-icons/blue-star.svg"} alt={"star"} />
                                        </div>
                                        <span>{data.aiReplyTimesInSeconds["5"] ? data.aiReplyTimesInSeconds["5"] : '00:00:00'}</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <div className="replies-avg-ai-third">
                                    <div>
                                        <div>
                                            <img src={"/admin-icons/clock-ai.svg"} alt={"star"} />
                                            {t("Average Time")}
                                        </div>
                                        {data.totalAverageAiReplyTime ?
                                            <h6>{data.totalAverageAiReplyTime} <span>{t("sec")}</span></h6>
                                            : <h5 style={{fontSize: "14px", fontFamily: "Poppins-Regular", opacity: 0.6, marginRight: "15px"}}> Not Avaliable</h5>
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="replies-vs-ai">
                        <Grid container style={{ alignItems: "center" }}>
                            <Grid item xs={12} md={6} lg={4}>
                                <div className="replies-vs-ai-first">
                                    <img src={"/admin-icons/yellow-user.svg"} alt={"robot"} />
                                    <h5>{t("Self Replies")}</h5>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <div className="avg-reply-time">
                                    <div>
                                        <div>
                                            1 <img src={"/admin-icons/yellow-star.svg"} alt={"star"} />
                                        </div>
                                        {/* <span>{convertSec(data.selfRepliesTimesInSeconds["1"])}</span> */}
                                        <span>{data.selfRepliesTimesInSeconds["1"] ? data.selfRepliesTimesInSeconds["1"] : '00:00:00'}</span>
                                    </div>
                                    <div>
                                        <div>
                                            2 <img src={"/admin-icons/yellow-star.svg"} alt={"star"} />
                                        </div>
                                        <span>{data.selfRepliesTimesInSeconds["2"] ? data.selfRepliesTimesInSeconds["2"] : '00:00:00'}</span>                                 
                                    </div>
                                    <div>
                                        <div>
                                            3 <img src={"/admin-icons/yellow-star.svg"} alt={"star"} />
                                        </div>
                                        <span>{data.selfRepliesTimesInSeconds["3"] ? data.selfRepliesTimesInSeconds["3"] : '00:00:00'}</span>
                                    </div>
                                    <div>
                                        <div>
                                            4 <img src={"/admin-icons/yellow-star.svg"} alt={"star"} />
                                        </div>
                                        <span>{data.selfRepliesTimesInSeconds["4"] ? data.selfRepliesTimesInSeconds["4"] : '00:00:00'}</span>                                 
                                    </div>
                                    <div>
                                        <div>
                                            5 <img src={"/admin-icons/yellow-star.svg"} alt={"star"} />
                                        </div>
                                        <span>{data.selfRepliesTimesInSeconds["5"] ? data.selfRepliesTimesInSeconds["5"] : '00:00:00'}</span>                                   
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <div className="replies-avg-self-third">
                                    <div>
                                        <div>
                                            <img src={"/admin-icons/clock-ai.svg"} alt={"star"} />
                                            {t("Average Time")}
                                        </div>
                                        {data.totalAverageSelfReplyTimeSeconds ?
                                            <h6>{data.totalAverageSelfReplyTimeSeconds} <span>{t("sec")}</span></h6>
                                            : <h5 style={{fontSize: "14px", fontFamily: "Poppins-Regular", opacity: 0.6, marginRight: "15px"}}> Not Avaliable</h5>
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}
export default ReplyTime