import React, {useState} from "react"
import { Modal} from "@mui/material"
import { Link } from "react-router-dom"
import {AiOutlineClose} from "react-icons/ai"
import { useSelector } from "react-redux"

const ConnectPopup = ({open, setOpen, submitReply, source}) => {
    const user = useSelector(state => state.getUserInfo.user)

    const handleClose = () => {
        setOpen(false)
    }

    const submit = () => {
        handleClose()
        submitReply()
    }
    

    return(
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-tag-title"
            aria-describedby="modal-tag-description"
        >
            <div className="connect-popup-container">
                <AiOutlineClose onClick={handleClose}/>
                <img src="/admin-icons/connection.svg" alt="connection"/>
                <p>
                    To make your reply visible on {source},
                </p>
                <p>
                    connect your account with us.
                </p>
                <Link to="/app/admin/reviews/connections">Connect {source}</Link>
                <button onClick={() => submit()}>Continue without connecting</button>
            </div>
        </Modal>
    )
}
export default ConnectPopup