import React from 'react';
import { AiFillStar } from 'react-icons/ai';
import { Rating } from '@mui/material';

const ReviewsBox = ({item}) => {
    return (
        <div className='reviews_box'>
            <div className="manage_reviews_header">
                <div className="manage_reviews_source">
                    <img src={item.logo} className="logo" alt="logo" />
                    <div className='manage_reviews_name'>
                        <h5>{item.reviewer}<span>{item.source}</span></h5>
                        <div>
                            <Rating icon={<img src={"/admin-images/filled-icon.svg"} alt="filled-star" className="custom-rating-box-sm"/>} emptyIcon={<img src={"/admin-images/empty-icon.svg"} alt="empty-star" className="custom-rating-box-sm"/>} value={item.rating} readOnly />
                        </div>
                    </div>
                </div>
                <div className="manage_reviews_date">
                    <span>{item.created_at}</span>
                </div>
            </div>

            <div className="manage_reviews_content">
                <p dangerouslySetInnerHTML={{__html: item.content}}></p>
            </div>
        </div>
    )
}

export default ReviewsBox;