import React from "react"
import LightTooltip from "../../helpers/lightTooltip"
import { IconButton, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { IoMdInformationCircleOutline } from "react-icons/io"
import {HiOutlineReply} from "react-icons/hi"

const LatestResponses = ({latest_responses}) => {
    const [t] = useTranslation()
    if(latest_responses.length > 0 ){
        return(
            <div className="latest-response-container">
                <div className="latest-response-header">
                    <LightTooltip title={"Latest Responses shows last four reviews that have been responded by AI."}>
                        <IconButton className="tooltip-btn-holder">
                            <IoMdInformationCircleOutline style={{ opacity: "0.4" }} />
                        </IconButton> 
                    </LightTooltip>
                    <h2>{t("Latest Responses")}</h2>
                </div>
                <Grid container spacing={2}>
                    {latest_responses.map((response, i) => {
                        return(
                            <Grid item xs={12} md={6} lg={3} key={`latest_response-${i}`}>
                                <div className="latest-response-wrapper">
                                    <div className="latest-response-header">
                                        <div>
                                            <img src={response?.source?.logo_src} alt="source"/>
                                            <h5>{response.reviewerName}</h5>
                                        </div>
                                        <span>{response.rating} <img src={"/admin-images/filled-icon.svg"} /></span>
                                    </div>
                                    {/* <div className="latest-response-user"><HiOutlineReply/>AI Replies</div> */}
                                    <div className="latest-response-comment">
                                        <HiOutlineReply/>
                                        <p>{response.comment}</p>
                                    </div>
                                    <Link to={`/app/admin/reviews/list/${response.review_id}`}>{t("View")}</Link>
                                </div>
                            </Grid> 
                        )
                    })}
                </Grid>
            </div>
        )
    } else {
        return(
            <div className="latest-response-wrapper" style={{textAlign: "center", marginTop: "50px"}}>
                <img src={"/admin-icons/no-ai-replies.svg"} className="no-image"/>
                <p>Currently, there have been no AI-generated responses received </p>
                <p>or generated at this moment.</p>
            </div>
        )
    }
}
export default LatestResponses