import React from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { formatDateUS } from '../../helpers/formatDate';
import { useSelector } from 'react-redux';


const SubscriptionContract = ({ open, handleClose , total, plan, period}) => {
    const user = useSelector(state => state.getUserInfo.user)
    const subscriptions = useSelector(state => state.subscriptionsReducer.subscriptions);
    let full = subscriptions && subscriptions.plans.filter(pl => pl.plan_id === plan)

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="autopilot-modal-title"
                aria-describedby="autopilot-modal-description"
                keepMounted // because we want when modal is not open, to not remove from the dom, because we need to access this id:wrapper_setup_subscription_contract
            >
                <div className="wrapper-setup-ai wrapper_setup_subscription_contract" id='wrapper_setup_subscription_contract'>
                    <div className="wrapper-setup-header">
                        <h5>CUSTOMER SUBSCRIPTION AGREEMENT</h5>
                    </div>
                    <div className="wrapper-setup-body wrapper-setup-body-ai ">
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <span>Customer</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>{user?.user.first_name} {user?.user.last_name} - <b>{user?.user.street}, {user?.user.city}, {user?.user.zip}</b></span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Service Provider</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>iREVIEW, a platform of Mydev ecosystem, a company of The Social Plus Holding LLC</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Date of Agreement:</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>{formatDateUS(new Date())}</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Term:</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>{period} year subscription, renewable automatically as described below</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Subscription Package:</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>{full && full[0]?.name}</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Annual Price:</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>${total} may be paid in monthly installments</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <span>Relationship Management</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Platform Included (“Services”):</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Access to all Mydev platforms. Initial subscription: iREVIEW</span>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className='bold_text_wrapper'>
                                        <p>SEE AGREEMENT DETAILS BELOW. ALL SUBSCRIPTIONS ARE SUBJECT TO OUR TERMS AND
                                            CONDITIONS, AS AMENDED FROM TIME TO TIME AND BY ACCEPTING THE SERVICE, YOU
                                            CONSENT TO BE BOUND BY THESE TERMS
                                        </p>
                                    </div>
                                    <p>This Customer Agreement (the “Agreement”) is effective on the acceptance date (the “Effective
                                        Date”), by and between The Social Plus Holding LLC – DBA IREVU, an Illinois limited liability
                                        company, (“iREVIEW”) and <b>{user?.user.first_name} {user?.user.last_name}</b>,
                                        (individually each a “Party, collectively the “Parties”). Customer hereby subscribes to the Services,
                                        as further defined below, pursuant to the terms of this Agreement. Customer agrees that it will be
                                        concurrently bound by all Terms &amp; Conditions which can be found at www.iREVIEW.com
                                    </p>
                                    <p>Customer agrees to pay the Annual Price for access and use of the iREVIEW online platform. All
                                        Fees and other amounts payable by Customer under this Agreement are exclusive of taxes. During
                                        the Term, iREVIEW will provide Customer access to the iREVIEW online platform and customer
                                        support as needed to ensure access according to the subscription level selected by Customer.
                                    </p>
                                    <p>iReview, a platform of MyDev ecosystem, a company of The Social Plus Holding LLC, reserves the
                                        right to make changes to the Terms &amp; Conditions at any time.
                                    </p>
                                    <p>Subject to Customer’s payment of the Annual Fee and compliance with the terms of this
                                        Agreement, Terms &amp; Conditions and Privacy Policy, iREVIEW hereby authorizes and grants to
                                        Customer the right to access and use the iREVIEW Online Platform solely for use of the Services for
                                        the benefit of Customer, for Customer’s internal business operations during the Term of this
                                        Agreement and in accordance with the Terms &amp; Conditions and Privacy Policy. This authorization
                                        and grant is non-exclusive and non-transferable. In the event of any contradiction between this
                                        Agreement and a provision in the Terms &amp; Conditions or Privacy Policy, the terms of this
                                        Agreement shall prevail.
                                    </p>
                                    <p>Customer agrees that iREVIEW™ is a registered trademark and that iREVIEW has and will retain
                                        sole control over the operation, provision, maintenance, and management of the Services,
                                        including the: (i) selection, deployment, modification, and replacement of the Online Platforms;
                                        and (ii) performance of Service maintenance, upgrades, corrections, and repairs. This is not a
                                        license and iREVIEW expressly reserves and retains any and all Intellectual Property Rights.
                                    </p>
                                    <p>The initial Term of this Agreement commences as of the Effective Date and, unless terminated
                                        earlier as provided in the Terms &amp; Conditions, will continue in effect for the number of years
                                        indicated above (“Term”). After the end of the initial Term, this Agreement will automatically
                                        renew for successive one (1) year terms unless earlier terminated by written notice of non-
                                        renewal at least thirty (30) days prior to the expiration of the then-current term.
                                    </p>
                                    <p>The validity, construction, and performance of this Agreement shall be governed by the
                                        substantive laws of the State of Illinois. The Parties further agree that, in the event of a dispute
                                        arising hereunder, the jurisdiction and venue of any proceeding shall be proper solely in the
                                        County of Cook, State of Illinois.
                                    </p>
                                    <p>Notice. Any notice to iREVIEW shall be delivered to:</p>
                                    <p style={{margin: "auto", width: "fit-content"}}>
                                        Attn: Ornis Mala <br/>
                                        2150 E. Lake Cook Rd., Suite 730 <br/>
                                        Buffalo Grove, IL 60089 <br/>
                                        Ph.: (847) 719 - 9060 <br/>
                                        Em.: ornis@thesocialplus.com <br/>
                                    </p>
                                    <p>In the event that iREVIEW brings any action, suit, or other legal or administrative proceeding
                                        arising out of or related to this Agreement, iREVIEW shall be entitled to recover reasonable
                                        attorneys&#39; fees and court costs in addition to any other available relief.
                                    </p>
                                    <p>IN WITNESS WHEREOF, the Parties hereto have executed this Agreement as of the date written
                                        below.
                                    </p>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>{user?.user.first_name} {user?.user.last_name}</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>The Social Plus Holding LLC – DBA IREVU,an Illinois company</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>By: {user?.user.first_name} {user?.user.last_name}</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span style={{display: "flex", alignItems: "center"}}>By: <img style={{width: "200px", marginLeft: "20px", objectFit: "contain"}} src={"/admin-images/signature.png"} /></span>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <span>Name:</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Name:</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Title:</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span>Title:</span>
                                </Grid> */}
                                <Grid item xs={12}>
                                    <span>Accepted and Agreed to on:</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <span><b>Customer Notice Information:</b></span>
                                    <div>
                                        <p>{user?.user.first_name} {user?.user.last_name}</p>
                                        <p>Attn: Legal Department</p>
                                        <p>{user?.user.street}, {user?.user.city}, {user?.user.zip}</p>
                                        <p>{user?.user.city}, {user?.user.country} , {user?.user.zip}</p>
                                        <p>{user?.user.phone}</p>
                                        <p>{user?.user.email}</p>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default SubscriptionContract
