import React from "react"
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#173151',
        boxShadow: theme.shadows[1],
        fontSize: '0.8em',
        lineHeight: "25px",
        padding: "7px 20px",
        fontFamily: "Poppins-Medium",
        color: '#fff'
    },
}));

export default LightTooltip