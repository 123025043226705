import React, { useEffect, useState } from "react"
import "../../../../assets/admin.css"
import { BiExport } from "react-icons/bi";
import {AiOutlineFilePdf} from "react-icons/ai"
import ReviewBoxes from "./review-boxes";
import FilterBoxes from "./filter-boxes";
import Tags from "./tags";
import ReviewList from "./reviewList";
import axios from "axios";
import { baseUrlDev } from "../../../Helpers/baseUrl";
import { getLocalUser } from "../../../TokenControl/parts/useLocalUser";
import { showYoutubeContent, openExportedAlert } from "../../../../redux/actions/admin-index";
import { useDispatch, useSelector } from "react-redux";
import AlertComponent from "../../../Helpers/Alert";
import { Link } from "react-router-dom";
import { RiCoupon2Line } from "react-icons/ri"
import Header from "../../../Helpers/header";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import ExportedAlert from "../../modals/exportedAlert";
import { exportTable } from "../../helpers/export";

const Reviews = () => {
    const [showCheckboxes, setShowCheckboxes] = useState(false)
    const [disableBtn, setDisabledBtn] = useState(true)
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [loadingCsv, setLoadingCsv] = useState(false)

    const location = useSelector(state => state.locationSelected.location)
    const reviews = useSelector(state => state.getReviews.reviews);
    const keyword = useSelector(state => state.reviewKeyword.keywords)
    const sourcesFilter = useSelector(state => state.reviewSourceFilter.source)
    const status = useSelector(state => state.reviewStatusFilter.status)
    const rating = useSelector(state => state.reviewRatingFilter.rating)
    const orderState = useSelector(state => state.reviewSortOrder.order)
    const from = useSelector(state => state.reviewRangeFilter.dateFrom)
    const to = useSelector(state => state.reviewRangeFilter.dateTo)
    const page = useSelector(state => state.paginationReviews.pagination)
    const groupSelected = useSelector(state => state.groupSelected.group)
    const tagsFilter = useSelector(state => state.reviewTagsFilter.tags)
    const impersonateReducer = useSelector(state => state.impersonateReducer.impersonate)
    const [t] = useTranslation()

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(showYoutubeContent('https://www.youtube.com/embed/cgxCjiUwhw4?autoplay=1&mute=0&rel=0'))
    }, [])

    const exportTableFunc = () => {
        setLoadingCsv(true)
        exportTable(impersonateReducer,`${baseUrlDev}reviews/export`, {
            group_id: groupSelected,
            location: location === "all" ? "" : location,
            keyword,
            source: sourcesFilter,
            status,
            rating,
            "date-from": from,
            "date-to": to,
            page
        }, 
        (resData) => {
            dispatch(openExportedAlert(true, resData.message));
            setLoadingCsv(false)
        }, 
        (error) => {
            setLoadingCsv(false)
        });
        
    }

    
    const exportTablePDF = () => {
        setLoadingPdf(true)
        exportTable(impersonateReducer,`${baseUrlDev}reviews/generate-to-pdf`, {
            group_id: groupSelected,
            location: location === "all" ? "" : location,
            keyword,
            source: sourcesFilter,
            status,
            rating,
            "date-from": from,
            "date-to": to,
            page
        }, 
        (resData) => {
            dispatch(openExportedAlert(true, resData.message));
            setLoadingPdf(false)
        }, 
        (error) => {
            setLoadingPdf(false)
        });
    }
    
    const exportCouponsTable = () => {
        axios.post(`${baseUrlDev}reviews/coupons/export`, {}, { responseType: 'arraybuffer', headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'reviews.csv');
                document.body.appendChild(link);
                link.click();
            });
    }

    useEffect(() => {
        if (reviews) {
            setDisabledBtn(false)
        }
    }, [reviews])

    return (
        <>
            <ExportedAlert/>
            <div>
                <AlertComponent />
                <div className="flex-header-container">
                    <Header title="Reviews" subtitle="Manage Reviews" />
                    <div className="reviews-header-actions">
                        <button 
                            disabled={disableBtn} 
                            className="btn button-disabled competitor-export" 
                            onClick={() => exportTablePDF()}
                            style={{opacity: loadingPdf ? 0.5 : 1, cursor: loadingPdf ? 'initial' : 'pointer'}}
                        >
                        {loadingPdf ?
                        <>
                            <CircularProgress
                                size={15}
                                style={{ marginRight: "10px" }}
                                thickness={4}
                            />
                            Export to PDF</>
                        : <> <AiOutlineFilePdf style={{ marginRight: "10px" }} /> {t("Export to PDF")}</>}
                        </button>
                        <button 
                            disabled={disableBtn} 
                            className="btn button-disabled competitor-export" 
                            onClick={() => exportTableFunc()}
                            style={{opacity: loadingCsv ? 0.5 : 1, cursor: loadingCsv ? 'initial' : 'pointer'}}
                        >
                            {loadingCsv ? 
                            <>
                              <CircularProgress
                                    size={15}
                                    style={{ marginRight: "10px" }}
                                    thickness={4}
                                />
                                Export to Excel
                            </>    
                          : <><BiExport /> {t("Export to Excel")}</>}
                        </button>
                    </div>
                </div>
                <ReviewBoxes />
                <FilterBoxes setShowCheckboxes={setShowCheckboxes} showCheckboxes={showCheckboxes} />
                <Tags />
                <ReviewList showCheckboxes={showCheckboxes} setShowCheckboxes={setShowCheckboxes} />
            </div>
        </>
    )
}
export default Reviews